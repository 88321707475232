import React, { Fragment } from 'react';
import { Skeleton as AntdSkeleton, Divider } from 'antd';
import { Card } from 'react-bootstrap';
const { Input: AntInputSkeleton } = AntdSkeleton;
export function Skeleton({ className }) {
  return (
    <React.Fragment>
      <div className="">
        <AntdSkeleton.Input
          style={{
            width: '100%',
          }}
          block
          size="large"
          active
          className={`skeletonNftGotix w-full ${className}`}
        />
      </div>
    </React.Fragment>
  );
}
export function SkeletonAvatar({
  avatar = true,
  className,
  rows = 4,
  parentDivClass,
}) {
  return (
    <React.Fragment>
      <div
        className={`dark:bg-neutral-900 bg-lightTheme-70 overflow-hidden p-3 ${
          parentDivClass ? parentDivClass : 'rounded-lg w-full h-full'
        }`}
      >
        <div className="p-2">
          <AntdSkeleton
            avatar={avatar}
            paragraph={{
              rows: rows,
            }}
            size={'large'}
            loading={true}
            active
            className={`skeletonNftGotixAvatar w-full ${className}`}
            shape={'square'}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export function NormalSkeleton({ className, rows, avatar = false }) {
  return (
    <React.Fragment>
      <div className="w-full h-full rounded-lg overflow-hidden p-2">
        <AntdSkeleton
          avatar={avatar ? avatar : false}
          paragraph={{
            rows: rows ? rows : 4,
          }}
          size={'large'}
          loading={true}
          active
          className={`w-full ${className}`}
          shape={'square'}
        />
      </div>
    </React.Fragment>
  );
}

export function FullScreenSkeleton({ className, rows, avatar = false }) {
  return (
    <React.Fragment>
      <AntdSkeleton.Input
        className="flex w-full h-full absolute inset-0"
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          borderRadius: '10px',
          // margin: "0 10px",
        }}
        active
      />
    </React.Fragment>
  );
}

export function EventDetailTopSkeleton({ className, rows, avatar = false }) {
  return (
    <React.Fragment>
      <div className="container-fluid  max-w-[2048px]">
        <div className="py-7 px-md-3">
          <div className="flex flex-col md:flex-col lg:flex-row relative mb-0 md:mb-5 gap-4 top-[130px] items-end justify-end">
            <div className="lg:max-w-[20%] shrink-0 w-full">
              <div className="shrink-0 h-[280px] md:h-[280px] lg:h-[480px] xl:h-[310px] 2xl:h-[310px] w-full rounded-[20px] border-1 dark:border-neutral-800 border-zinc-400 border-opacity-25 overflow-hidden dark:bg-zinc-950 bg-whiteNew-0 bg-opacity-10 backdrop-blur-lg">
                <AntdSkeleton.Input
                  className="flex w-full h-full absolute inset-0"
                  style={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    borderRadius: '10px',
                  }}
                  active
                />
              </div>
            </div>

            <div className="lg:w-[80%] flex flex-col gap-2.5 bg-zinc-100 dark:bg-zinc-950 bg-opacity-30 dark:bg-opacity-60 p-3 rounded-lg backdrop-blur-sm">
              <div className="flex">
                <div className="lg:flex flex-row items-end relative w-full gap-2">
                  <div className="flex items-center relative h-[40px] w-[10%] dark:bg-zinc-950 bg-zinc-200 bg-opacity-30 backdrop-blur-lg rounded-lg overflow-hidden">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      active
                    />
                  </div>

                  <div className="flex items-center relative h-[40px] w-[80%] dark:bg-zinc-950 bg-zinc-200 bg-opacity-30 backdrop-blur-lg rounded-lg mt-2 overflow-hidden">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      active
                    />
                  </div>

                  <div className="flex items-center relative h-[40px] w-[5%] dark:bg-zinc-950 bg-zinc-200 bg-opacity-30 backdrop-blur-lg rounded-lg mt-2 overflow-hidden">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      active
                    />
                  </div>

                  <div className="flex items-center relative h-[40px] w-[5%] dark:bg-zinc-950 bg-zinc-200 bg-opacity-30 backdrop-blur-lg rounded-lg mt-2 overflow-hidden">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      active
                    />
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="lg:flex flex-row items-end relative w-full gap-2">
                  <div className="flex items-center relative h-[40px] w-[100%] dark:bg-zinc-950 bg-zinc-200 bg-opacity-30 backdrop-blur-lg rounded-lg overflow-hidden">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      active
                    />
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="lg:flex flex-row items-end relative w-full gap-2">
                  <div className="flex items-center relative h-[40px] w-[5%] dark:bg-zinc-950 bg-zinc-200 bg-opacity-30 backdrop-blur-lg rounded-lg overflow-hidden">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      active
                    />
                  </div>

                  <div className="flex items-center relative h-[40px] w-[20%] dark:bg-zinc-950 bg-zinc-200 bg-opacity-30 backdrop-blur-lg rounded-lg overflow-hidden">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      active
                    />
                  </div>

                  <div className="flex items-center relative h-[40px] w-[5%] dark:bg-zinc-950 bg-zinc-200 bg-opacity-30 backdrop-blur-lg rounded-lg overflow-hidden">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      active
                    />
                  </div>

                  <div className="flex items-center relative h-[40px] w-[20%] dark:bg-zinc-950 bg-zinc-200 bg-opacity-30 backdrop-blur-lg rounded-lg overflow-hidden">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      active
                    />
                  </div>

                  <div className="flex items-center relative h-[40px] w-[5%] dark:bg-zinc-950 bg-zinc-200 bg-opacity-30 backdrop-blur-lg rounded-lg overflow-hidden">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      active
                    />
                  </div>

                  <div className="flex items-center relative h-[40px] w-[20%] dark:bg-zinc-950 bg-zinc-200 bg-opacity-30 backdrop-blur-lg rounded-lg overflow-hidden">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      active
                    />
                  </div>

                  <div className="flex items-center relative h-[40px] w-[5%] dark:bg-zinc-950 bg-zinc-200 bg-opacity-30 backdrop-blur-lg rounded-lg overflow-hidden">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      active
                    />
                  </div>

                  <div className="flex items-center relative h-[40px] w-[20%] dark:bg-zinc-950 bg-zinc-200 bg-opacity-30 backdrop-blur-lg rounded-lg overflow-hidden">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      active
                    />
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="lg:flex flex-row items-end relative w-full gap-2">
                  <div className="flex items-center relative h-[40px] w-[30%] dark:bg-zinc-950 bg-zinc-200 bg-opacity-30 backdrop-blur-lg rounded-lg overflow-hidden">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      active
                    />
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="lg:flex flex-row items-end relative w-full gap-2">
                  <div className="flex items-center relative h-[40px] w-[10%] dark:bg-zinc-950 bg-zinc-200 bg-opacity-30 backdrop-blur-lg rounded-lg overflow-hidden">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      active
                    />
                  </div>

                  <div className="flex items-center relative h-[40px] w-[10%] dark:bg-zinc-950 bg-zinc-200 bg-opacity-30 backdrop-blur-lg rounded-lg overflow-hidden">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      active
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export function ArtistInfoSkeleton({ className, rows, avatar = false }) {
  return (
    <React.Fragment>
      <div className="flex gap-3 basis-1/3 mb-2 md:mb-0">
        <div className="w-[15%]">
          <div className="h-[80px] w-[80px] rounded-full relative overflow-hidden bg-zinc-300 bg-opacity-20 backdrop-blur-lg">
            <AntdSkeleton.Input
              className="flex w-full h-full absolute inset-0"
              style={{
                display: 'flex',
                height: '100%',
                width: '100%',
                borderRadius: '10px',
                // margin: "0 10px",
              }}
              active
            />
          </div>
        </div>
        <div className="flex flex-col gap-2 w-[85%]">
          <div className="flex flex-col justify-center gap-1.5 relative h-[35px] w-full overflow-hidden bg-zinc-300 bg-opacity-20 backdrop-blur-lg rounded-lg">
            <AntdSkeleton.Input
              className="flex w-full h-full absolute inset-0"
              style={{
                display: 'flex',
                height: '100%',
                width: '100%',
                borderRadius: '10px',
                // margin: "0 10px",
              }}
              active
            />
          </div>
          <div className="flex flex-col justify-center gap-1.5 relative h-[35px] w-[75%] overflow-hidden bg-zinc-300 bg-opacity-20 backdrop-blur-lg rounded-lg">
            <AntdSkeleton.Input
              className="flex w-full h-full absolute inset-0"
              style={{
                display: 'flex',
                height: '100%',
                width: '100%',
                borderRadius: '10px',
                // margin: "0 10px",
              }}
              active
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export function EventCardSkeleton({ className, rows, avatar = false }) {
  return (
    <React.Fragment>
      <div className="parentCardHover">
        <Card className="border-0 rounded-[8px] overflow-hidden shadow-sm hover:shadow-lg w-full h-full relative bg-transparent childCardHover">
          <Card.Body className="p-0 h-full">
            <div className="relative w-full max-h-[290px] dark:bg-zinc-900 bg-lightTheme-90 bg-opacity-50 p-1">
              <div className="inset-0 w-full overflow-hidden rounded-b-none relative h-[230px] bg-zinc-600 bg-opacity-10 backdrop-blur-lg rounded-lg">
                <AntdSkeleton.Input
                  className="flex w-full h-full absolute inset-0"
                  style={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    borderRadius: '10px',
                    // margin: "0 10px",
                  }}
                  active
                />
              </div>

              <div className="inset-0 w-full overflow-hidden rounded-b-none relative h-[35px]  dark:bg-zinc-700  bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg">
                <AntdSkeleton.Input
                  className="flex w-full h-full absolute inset-0"
                  style={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    borderRadius: '10px',
                    // margin: "0 10px",
                  }}
                  active
                />
              </div>
            </div>

            <div className="dark:bg-zinc-900 bg-lightTheme-90 p-1">
              <div className="flex flex-row gap-2 justify-between">
                <div className="flex w-[50%] gap-2 shrink-0">
                  <div className="overflow-hidden relative h-[28px] w-[28px] dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-full">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>
                  <div className="overflow-hidden relative h-[28px] w-[100px] dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-lg">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="overflow-hidden relative h-[28px] w-[28px] dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-full">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>
                  <div className="overflow-hidden relative h-[28px] w-[28px] dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-full">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row gap-2 justify-between mt-2">
                <div className="flex w-[50%] gap-2 shrink-0">
                  <div className="overflow-hidden relative h-[28px] w-[28px] dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-full">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>
                  <div className="overflow-hidden relative h-[28px] w-[135px] dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-lg">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row gap-2 justify-between mt-2">
                <div className="flex w-[50%] gap-2 shrink-0">
                  <div className="overflow-hidden relative h-[28px] w-[28px] dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-full">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>
                  <div className="overflow-hidden relative h-[28px] w-[165px] dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-lg">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row gap-2 justify-between mt-2">
                <div className="flex w-full gap-2 shrink-0">
                  <div className="overflow-hidden relative h-[28px] w-full dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-lg">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row gap-2 justify-between mt-4">
                <div className="flex w-full gap-2 shrink-0">
                  <div className="overflow-hidden relative h-[35px] w-full dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-lg">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}

export function ArtistCardSkeleton({ className, rows, avatar = false }) {
  return (
    <React.Fragment>
      <div className="parentCardHover">
        <Card className="border-0 rounded-[8px] overflow-hidden shadow-sm hover:shadow-lg w-full h-full relative bg-transparent childCardHover">
          <Card.Body className="p-0 h-full">
            <div className="relative w-full max-h-[250px] dark:bg-zinc-900 bg-lightTheme-90 bg-opacity-50 p-1">
              <div className="inset-0 w-full overflow-hidden rounded-b-none relative h-[200px]  dark:bg-zinc-600 bg-lightTheme-90 bg-opacity-10 backdrop-blur-lg rounded-lg">
                <AntdSkeleton.Input
                  className="flex w-full h-full absolute inset-0"
                  style={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    borderRadius: '10px',
                    // margin: "0 10px",
                  }}
                  active
                />
              </div>
            </div>

            <div className="dark:bg-zinc-900 bg-lightTheme-90 p-1">
              <div className="flex flex-row gap-2 justify-between">
                <div className="flex w-full flex-col gap-2 shrink-0">
                  <div className="overflow-hidden relative h-[28px] w-full dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-lg">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>

                  <div className="overflow-hidden relative h-[28px] w-[75%] dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-lg">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row gap-2 justify-between mt-2">
                <div className="flex w-[50%] gap-2 shrink-0">
                  <div className="overflow-hidden relative h-[28px] w-[120px] dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-lg">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>

                  <div className="overflow-hidden relative h-[28px] w-[34px] dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-full">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>

                  <div className="overflow-hidden relative h-[28px] w-[34px] dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-full">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>

                  <div className="overflow-hidden relative h-[28px] w-[34px] dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-full">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>

                  <div className="overflow-hidden relative h-[28px] w-[34px] dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-full">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row gap-2 justify-between mt-3">
                <div className="flex w-full gap-2 shrink-0">
                  <div className="overflow-hidden relative h-[35px] w-full dark:bg-zinc-700 bg-lightTheme-90 bg-opacity-30 backdrop-blur-lg rounded-lg">
                    <AntdSkeleton.Input
                      className="flex w-full h-full absolute inset-0"
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: '10px',
                        // margin: "0 10px",
                      }}
                      active
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}

export function LandingPageSliderSkeleton({ className, rows, avatar = false }) {
  return (
    <React.Fragment>
      <div className="w-full h-[570px] bg-whiteNew-0 bg-opacity-90 dark:bg-neutral-900 rounded-3xl overflow-hidden bannerSkeleton">
        <div className="absolute inset-0 w-full h-full bg-color-base-1 bg-opacity-0 dark:bg-color-base-2 z-0">
          <AntdSkeleton.Input
            className="flex w-full h-full absolute inset-0"
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              borderRadius: '10px',
              // margin: "0 10px",
            }}
            active
          />
        </div>
        <div className="flex p-9 flex-col relative h-full z-10">
          {/* <div className="flex w-[350px] h-[42px] rounded-full dark:bg-zinc-950 bg-lightTheme-70 bg-opacity-60">
            <AntdSkeleton.Input
              className="flex w-full h-full absolute inset-0"
              style={{
                display: 'flex',
                height: '100%',
                width: '100%',
                borderRadius: '10px',
                // margin: "0 10px",
              }}
              active
            />
          </div> */}
          <div className="flex mt-3 gap-3">
            <div className="flex h-[40px] w-[40px] rounded-full dark:bg-zinc-950 bg-lightTheme-70 dark:bg-opacity-60">
              <AntdSkeleton.Input
                className="flex w-full h-full absolute inset-0"
                style={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  borderRadius: '10px',
                  // margin: "0 10px",
                }}
                active
              />
            </div>

            <div className="flex h-[40px] w-[110px] rounded-full dark:bg-zinc-950 bg-lightTheme-70 bg-opacity-60">
              <AntdSkeleton.Input
                className="flex w-full h-full absolute inset-0"
                style={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  borderRadius: '10px',
                  // margin: "0 10px",
                }}
                active
              />
            </div>

            <div className="flex h-[40px] w-[40px] rounded-full dark:bg-zinc-950 bg-lightTheme-70 bg-opacity-60">
              <AntdSkeleton.Input
                className="flex w-full h-full absolute inset-0"
                style={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  borderRadius: '10px',
                  // margin: "0 10px",
                }}
                active
              />
            </div>

            <div className="flex h-[40px] w-[110px] rounded-full dark:bg-zinc-950 bg-lightTheme-90 bg-opacity-60">
              <AntdSkeleton.Input
                className="flex w-full h-full absolute inset-0"
                style={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  borderRadius: '10px',
                  // margin: "0 10px",
                }}
                active
              />
            </div>
          </div>

          <div className="flex mt-20">
            <div className="flex h-[40px] w-4/5 rounded-full dark:bg-zinc-950 bg-lightTheme-90 bg-opacity-60">
              <AntdSkeleton.Input
                className="flex w-full h-full absolute inset-0"
                style={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  borderRadius: '10px',
                  // margin: "0 10px",
                }}
                active
              />
            </div>
          </div>

          <div className="flex mt-3">
            <div className="flex h-[40px] w-3/4 rounded-full dark:bg-zinc-950 bg-lightTheme-90 bg-opacity-60">
              <AntdSkeleton.Input
                className="flex w-full h-full absolute inset-0"
                style={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  borderRadius: '10px',
                  // margin: "0 10px",
                }}
                active
              />
            </div>
          </div>

          <div className="flex mt-32">
            <div className="flex h-[40px] w-[150px] rounded-full dark:bg-zinc-950 bg-lightTheme-90 bg-opacity-60">
              <AntdSkeleton.Input
                className="flex w-full h-full absolute inset-0"
                style={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  borderRadius: '10px',
                  // margin: "0 10px",
                }}
                active
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export function SkeletonTableListView({ key }) {
  return (
    <Fragment key={key}>
      <div className="flex pointer-events-none justify-around items-center gap-2 text-left text-whiteNew-0 py-[8px] px-2.5">
        <div className="shrink-0 max-w-[300px] w-full">
          <AntInputSkeleton
            className="w-full min-h-[25px] inset-0 rounded-lg dark:bg-neutral-900 bg-transparent mt-2"
            active
            block
            size="large"
          />
        </div>
        <div className="truncate shrink-0 w-full max-w-[150px]">
          <AntInputSkeleton
            className="w-full min-h-[25px] inset-0 rounded-lg dark:bg-neutral-900 bg-transparent mt-2"
            active
            block
            size="large"
          />
        </div>
        {/* <div className="truncate w-full">
        <span>
          {getEventTicketStatus
            ? message.current
            : moment(
                moment.utc(getSalesStartDate(category)).local(),
                'YYYY-MM-DD hh:mm a',
              ).format('MM/DD/YY - HH:mm') ?? '-'}
        </span>
      </div> */}
        {/* <div className="truncate w-full">
        <span>Allegiant Stadium</span>
      </div> */}
        <div className="truncate shrink-0 w-full max-w-[200px]">
          <AntInputSkeleton
            className="w-full min-h-[25px] inset-0 rounded-lg dark:bg-neutral-900 bg-transparent mt-2"
            active
            block
            size="large"
          />
        </div>
        <div className="truncate shrink-0 w-full max-w-[200px]">
          <AntInputSkeleton
            className="w-full min-h-[25px] inset-0 rounded-lg dark:bg-neutral-900 bg-transparent mt-2"
            active
            block
            size="large"
          />
        </div>
        <div className="truncate shrink-0 w-full max-w-[200px]">
          <AntInputSkeleton
            className="w-full min-h-[25px] inset-0 rounded-lg dark:bg-neutral-900 bg-transparent mt-2"
            active
            block
            size="large"
          />
        </div>
        <div className="truncate shrink-0 w-full max-w-[100px]">
          <AntInputSkeleton
            className="w-full min-h-[25px] inset-0 rounded-lg dark:bg-neutral-900 bg-transparent mt-2"
            active
            block
            size="large"
          />
        </div>
      </div>
      <Divider className="bg-neutral-700 m-0" />
    </Fragment>
  );
}
