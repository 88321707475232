import React from 'react';
import { Navigate } from 'react-router-dom';
import { history } from './history';
import useDecodedToken from '../../hooks/useDecodedToken';

function PrivateRoute({ children }) {
  const decodedToken = useDecodedToken();
  return decodedToken ? (
    children
  ) : (
    <Navigate to={`/user/login`} state={{ from: history.location }} />
  );
}

export default PrivateRoute;
