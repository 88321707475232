import React from 'react';

import SidebarMenus from './SidebarMenus';
export default function LeftSideBar({
  title,
  menu,
  primarySidebar,
  secondarySidebar,
  activePrimaryMenu,
  openAccordActive,
  accordOnClick,
}) {
  // const navigate = useNavigate();
  // const handleNavigation = (href) => {
  //   navigate(href);
  // };

  return (
    <React.Fragment>
      <div className={`relative `}>
        {/* style={{ height: "calc(100vh - 70px)" }} */}
        <SidebarMenus
          title={title}
          menu={menu}
          openAccordActive={openAccordActive}
          accordOnClick={accordOnClick}
          primarySidebar={primarySidebar}
          secondarySidebar={secondarySidebar}
          activePrimaryMenu={activePrimaryMenu}
        />
      </div>
    </React.Fragment>
  );
}
