import React from 'react';
import DashboardVersion2 from './DashboardVersion2';

export default function index() {
  return (
    <React.Fragment>
      <DashboardVersion2 />
    </React.Fragment>
  );
}
