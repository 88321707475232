// export const formClass =
//   'placeholder:text-slate-400 font-normal py-[4px] focus:outline-none text-sm focus:border-sky-500 focus:ring-sky-500 focus:ring-0 disabled:bg-gray-100 disabled:font-normal disabled:text-gray-400 disabled:border-gray-300 placeholder:font-normal';
// export const formClass =
//   'placeholder:text-slate-400 bg-whiteNew-0 dark:bg-color-base-3 text-color-primary focus:shadow-soft focus:text-color-primary focus-within:text-color-primary font-normal focus:outline-none text-sm focus:ring-1 focus:border-sky-600 dark:focus:border-sky-600/10 dark:ring-sky-600 disabled:bg-component-gray-1 dark:disabled:bg-gray-2/20 disabled:font-normal disabled:text-gray-1 dark:focus:bg-component-gray-1 dark:focus-within:bg-component-gray-1 disabled:border-component-gray-1 placeholder:font-normal flex w-full items-center rounded-lg p-1.5 border-1 border-level-1 hover:border-level-2 focus-within:shadow-soft focus-within:hover:shadow-soft focus-within:border-level-2 duration-0 disabled:cursor-not-allowed';
export const formClass =
  'placeholder:text-slate-400 bg-whiteNew-0 dark:bg-color-gray-100 text-color-primary focus:shadow-soft focus:text-color-primary focus-within:text-color-primary font-normal focus:outline-none text-sm focus:ring-1 focus:border-sky-600 dark:focus:border-color-gray-100 dark:ring-color-gray-200 disabled:bg-component-gray-1 dark:disabled:bg-gray-2/20 disabled:font-normal disabled:text-gray-1 dark:focus:bg-color-gray-200 dark:focus-within:bg-color-gray-200 disabled:border-component-gray-1 placeholder:font-normal flex w-full items-center rounded-md p-1.5 py-[0.5rem] border-1 border-level-1 dark:border-color-gray-100 hover:border-level-2 focus-within:shadow-soft focus-within:hover:shadow-soft focus-within:border-level-2 dark:focus-within:border-color-gray-200 duration-0 disabled:cursor-not-allowed text-color-primary darkInputPassword';
export const formClass2 =
  'dark:bg-neutral-800 bg-whiteNew-0 placeholder:text-neutral-400 font-normal text-sm py-1.5 border-[1px] border-zinc-200 dark:border-zinc-700 focus:outline-none focus:border-neutral-200 dark:focus:border-neutral-700 focus:ring-neutral-100 dark:focus:ring-neutral-700 focus:ring-1 focus-within:ring-0 dark:focus-within:ring-1 focus-within:ring-neutral-700 focus:bg-neutral-100 dark:focus:bg-neutral-800 disabled:bg-zinc-100 dark:disabled:bg-zinc-800 disabled:bg-zinc-200 disabled:text-zinc-800 dark:text-whiteNew-0 text-zinc-900 dark:focus:text-whiteNew-0 focus:text-zinc-900 disabled:font-normal placeholder:font-normal duration-0 darkInputPassword';
export const formFloatClass =
  'border-t-0 border-x-0 rounded-b-none shadow-transparent focus:shadow';
export const formFloatClassDark =
  'bg-neutral-800 bg-opacity-50 text-whiteNew-0 focus:text-whiteNew-0 placeholder:text-gray-500 focus:bg-neutral-800 focus:bg-opacity-50 border-gray-600 disabled:bg-neutral-600 disabled:bg-opacity-70';

export const btnPrimaryClass =
  'relative flex justify-center items-center gap-1 bg-gradient-to-r from-color-primaryColor1 to-color-primaryColor2 backdrop-blur-lg hover:bg-sky-700 shadow-slate-600 duration-200 ease-in p-3.5 py-1.5 rounded-md text-whiteNew-0 hover:shadow-xs hover:shadow-slate-200 disabled:opacity-80 disabled:text-opacity-70 hover:disabled:bg-inherit cursor-pointer min-h-[34px]';
export const btnPrimaryClassStep3 =
  'relative flex justify-center items-center gap-1 bg-sky-600 backdrop-blur-lg hover:bg-sky-700 shadow-slate-600 duration-200 ease-in p-2.5 py-1.5 rounded-md text-whiteNew-0 hover:shadow-xs hover:shadow-slate-200 border-1 border-sky-600 disabled:opacity-80 disabled:text-opacity-70 hover:disabled:bg-sky-600';
export const btnSecondayClass =
  'inline-flex items-center justify-center border-1 border-component-gray-1 whitespace-nowrap transition duration-200 text-md leading-md font-medium bg-component-gray-1 dark:bg-component-gray-1 hover:bg-component-gray-3 dark:hover:bg-component-gray-2 gap-1.5 rounded-[6px] disabled:pointer-events-none  relative text-neutral-950 dark:text-whiteNew-0 py-1.5 px-3.5 disabled:opacity-80 disabled:text-opacity-70 hover:disabled:bg-inherit';

export const lightBlueBtnClass =
  'border-1 border-sky-200 bg-sky-200 dark:border-component-gray-1 dark:bg-component-gray-2 dark:hover:bg-component-gray-3 dark:text-whiteNew-0 hover:bg-opacity-80 hover:bg-sky-200 rounded-md p-[4px] flex items-center justify-center px-2.5 gap-1.5 text-neutral-950';

export const btnPrimaryClass2 =
  'relative flex justify-center items-center gap-1 bg-slate-100 text-sky-600 hover:bg-sky-100 shadow-slate-600 duration-200 ease-in p-2.5 py-1.5 rounded-md hover:shadow-xs hover:shadow-slate-200 disabled:opacity-80 disabled:text-opacity-70 hover:disabled:bg-sky-600 cursor-pointer disabled:cursor-wait';
export const btnPrimaryDark = `bg-landingPurple-60 text-gray-50 hover:bg-landingPurple-70 p-1.5 px-2.5 rounded-lg duration-200 ease-in`;
export const btnSecondaryDark = `bg-neutral-700 bg-opacity-50 text-gray-50 hover:bg-neutral-700 hover:bg-opacity-70 duration-200 ease-in shadow-lg shadow-gray-200 p-1.5 px-2.5 rounded-lg flex justify-center items-center font-normal`;
// New version 2 classes
export const durationAnimate = `duration-200 ease-in`;
// export const afterBorder = `after:absolute after:content-[''] dark:after:bg-zinc-700 after:bg-indigo-300 after:bottom-0 after:left-0 after:h-1 after:w-full after:flex after:z-10 after:bg-color-base-2`;
export const afterBorder = `after:absolute after:content-['']  after:bottom-0 after:left-0 after:h-1 after:w-full after:flex after:z-10 after:bg-level-2`;
export const afterBorder2 = `after:absolute after:content-[''] after:bottom-0 after:left-0 after:h-1 after:w-full after:flex after:z-10`;
export const afterBorderLeft = `relative after:absolute after:content-[''] after:bottom-0 after:-left-0 after:w-1 after:h-full after:flex after:z-10 after:bg-level-2 relative`;
export const afterBorderRight = `relative after:absolute relative after:content-[''] after:bg-level-2 after:bottom-0 after:-right-0 after:w-1 after:h-full after:flex after:z-10`;
export const afterBorderTop = `after:absolute after:content-[''] relative after:bg-level-2 after:top-0 after:left-0 after:h-1 after:w-full after:flex after:z-10`;
export const afterBorderTop2 = `after:absolute after:content-[''] after:top-0 after:left-0 after:h-1 after:w-full after:flex after:z-10`;

export const primaryBtnClass =
  'relative flex justify-center items-center gap-1 bg-bluev2-600 hover:bg-bluev2-600/90 shadow-sm shadow-slate-600 duration-200 ease-in p-2.5 py-1.5 rounded-lg text-whiteNew-0 hover:shadow-lg hover:shadow-bluev2-600 border-1 border-transparent disabled:opacity-70 cursor-pointer focus:outline-none focus-visible:outline-none focus:ring-0 focus-visible:ring-0';
export const primaryClassBtn =
  'relative flex justify-center items-center gap-1 rounded-md text-whiteNew-0 bg-gradient-to-r from-color-primaryColor1 to-color-primaryColor2 duration-200 ease-in p-2.5 py-1.5 disabled:opacity-70 cursor-pointer focus:outline-none focus-visible:outline-none focus:ring-0 focus-visible:ring-0';
export const secondaryBtnClass =
  'relative flex justify-center items-center gap-2 rounded-full bg-whiteNew-0 hover:bg-slate-100 shadow-sm shadow-slate-600 duration-200 ease-in p-2.5 py-1.5 disabled:opacity-70 cursor-pointer';

// export const primaryBtnClassGradient =
//   'p-1.5 bg-gradient-to-r from-purplev2-800 to-purplev2-900 hover:bg-gradient-to-l hover:from-purplev2-800 hover:to-purplev2-900 text-gray-50 rounded-md duration-300 ease-in flex justify-center items-center';
// export const primaryBtnClassGradient =
//   'p-1.5 bg-gradient-to-r from-purplev2-800 to-purplev2-900 text-whiteNew-0 rounded-[6px] duration-300 ease-in flex justify-center items-center disabled:opacity-70';
export const primaryBtnClassGradient =
  'p-1.5 bg-gradient-to-r dark:from-[#454c9a] from-slate-100 hover:from-sky-600 dark:to-violet-600 to-slate-100 hover:to-sky-600 rounded-[6px] duration-100 ease-in flex justify-center items-center dark:disabled:opacity-70 disabled:opacity-50 font-normal dark:font-normal hover:bg-lightTheme-80 border-0 border-sky-100 hover:border-sky-600 dark:border-1 dark:border-zinc-200 dark:border-transparent text-sky-600 dark:text-whiteNew-0 hover:text-whiteNew-0';

export const primaryLoginBtnClassGradient =
  'p-1.5 bg-gradient-to-r dark:from-[#454c9a] from-sky-600 hover:from-sky-600 dark:to-violet-600 to-sky-600 hover:to-sky-600 rounded-[6px] duration-100 ease-in flex justify-center items-center dark:disabled:opacity-70 disabled:opacity-50 font-normal dark:font-normal hover:bg-lightTheme-80 border-0 border-sky-100 hover:border-sky-600 dark:border-1 dark:border-zinc-200 dark:border-transparent text-sky-600 dark:text-whiteNew-0 hover:text-whiteNew-0 text-white';

export const primaryBtnClassGradientSec =
  'p-1.5 bg-gradient-to-r dark:from-[#454c9a] from-sky-600 hover:from-sky-700 dark:to-violet-600 to-sky-600 hover:to-sky-700 !text-whiteNew-0 rounded-[6px] duration-300 ease-in flex justify-center items-center dark:disabled:opacity-70 disabled:opacity-40 font-normal dark:font-normal hover:bg-lightTheme-80 border-zinc-200 dark:border-transparent text-zinc-800 dark:text-whiteNew-0 hover:text-whiteNew-0';

export const primaryBtnClassGradientHead =
  'p-1.5 bg-gradient-to-r dark:from-[#454c9a] from-lightTheme-70 dark:to-violet-600 to-lightTheme-70 hover:from-lightTheme-80 hover:to-lightTheme-80 dark:text-whiteNew-0 text-zinc-800 rounded-[6px] duration-300 ease-in flex justify-center items-center disabled:opacity-70 font-medium dark:font-normal hover:bg-lightTheme-80';

export const darkFormInput =
  'dark:bg-neutral-900 bg-transparent dark:text-white text-zinc-800 py-1.5 border-1 dark:border-zinc-800 border-neutral-300  dark:hover:border-zinc-700 hover:border-neutral-300 placeholder:text-[#818181] text-sm md:text-base dark:focus:border-zinc-700 focus:border-neutral-400 focus:ring-0';

export const darkFormInputs =
  'bg-gray-6 dark:text-white text-zinc-800 py-1.5 border-1 dark:border-zinc-800 border-neutral-300  dark:hover:border-zinc-700 hover:border-neutral-300 placeholder:text-[#818181] text-sm md:text-base dark:focus:border-zinc-700 focus:border-neutral-400 focus:ring-0';
export const darkFormSelect =
  'w-full cstm-drpdwn text-whiteNew-0 placeholder:text-[#818181] text-sm rounded-md cursor-pointer bg-gray-6 !border-none h-[38px] hover:ring-0 hover:border-none';
export const iconBtnSize = 'w-9 h-9';
export const formClassV2 =
  'w-full placeholder:text-slate-400 placeholder:text-base font-normal text-base focus:outline-none focus:border-bluev2-600 focus:ring-bluev2-600 focus:ring-0 hover:ring-1 hover:ring-bluev2-600 disabled:bg-gray-100 disabled:font-light disabled:text-gray-400 disabled:border-gray-300 placeholder:font-light rounded-md focus:shadow-md focus:shadow-bluev2-600/20 duration-200 ease-in';
// Horizontal tabs Start

export const horizontalTabClass =
  'shrink-0 px-3 py-2.5 text-sm font-medium leading-5 ring-white ring-opacity-60 ring-offset-1 ring-offset-blue-400 focus:outline-none focus:ring-0 focus:ring-offset-0 border-b-[3px] dark:text-white border-t-0 border-l-0 border-r-0';

export const horizontalTabSelectedClass =
  'bg-whiteNew-0 dark:bg-gray-1 dark:bg-opacity-0 shadow-none border-b-[3px] border-b-sky-600 dark:text-whiteNew-0 dark:border-b-color-primaryColor2 font-medium text-sky-600 py-3.5';

export const horizontalTabUnselectedClass =
  'text-neutral-900 hover:text-sky-600 border-b-white hover:bg-transparent dark:hover:text-whiteNew-0 dark:text-opacity-60 dark:border-b-transparent border-b-[3px]';

export const horizontalTabClassDark =
  'shrink-0 px-3 py-2.5 text-sm font-medium leading-5 ring-white ring-opacity-60 ring-offset-1 ring-offset-blue-400 focus:outline-none focus:ring-0 focus:ring-offset-0';

export const horizontalTabSelectedClassDark =
  'bg-whiteNew-0/[0.12] shadow-none font-medium text-whiteNew-0 py-3.5';

export const horizontalTabUnselectedClassDark =
  'text-neutral-400 hover:bg-whiteNew-0/[0.12] hover:text-whiteNew-0';

export const TabOutlineClass =
  'ring-white ring-opacity-60 ring-offset-0 ring-offset-blue-400 focus:outline-none focus:ring-0';

// Horizontal tabs End

export const verticalTabClass =
  'px-2 py-2 shrink-0 text-lg xl:text-xl font-medium leading-5 relative w-10 h-10 text-left ring-white rounded-xl ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 flex justify-center items-center';

export const verticalTabSelectedClass =
  'bg-slate-100 dark:bg-component-gray-1 dark:text-white shadow-none text-sky-600 font-medium';

export const verticalTabUnselectedClass =
  'text-neutral-900 hover:bg-whiteNew-0/[0.12] dark:text-color-secondary dark:hover:text-white hover:text-sky-600';

export const pillTabClass =
  'px-3 py-1.5 font-medium text-sm leading-5 rounded-md ring-white ring-opacity-60 ring-offset-0 ring-offset-blue-50 focus:outline-none duration-200 ease-in border-0 focus:ring-0 dark:text-white';

export const pillTabActiveClass =
  'bg-slate-100 font-medium shadow-none text-sky-600 dark:bg-component-gray-3 shadow-elevation-1';

export const pillTabNotActiveClass =
  'text-neutral-900 hover:bg-slate-100/[0.25] hover:text-sky-600 dark:hover:bg-component-gray-1 dark:text-opacity-60 dark:hover:text-opacity-100';

export const pillTabClass2 =
  'px-3 py-1.5 font-medium text-sm leading-5 rounded-tl-md rounded-tr-md ring-white border-b-4 ring-opacity-60 ring-offset-2 border-sky-500 ring-offset-blue-400 focus:outline-none duration-200 ease-in';

export const pillTabActiveClass2 =
  'bg-slate-100 font-medium shadow-none text-sky-600 shadow-md';

export const pillTabNotActiveClass2 =
  'text-neutral-900 hover:bg-slate-100/[0.25] hover:text-sky-600 border-b-whiteNew-0';

export const antCustomSelect =
  'dark:bg-[#262626] bg-[#f2f2f2] dark:text-whiteNew-0 text-zinc-800 dark:zinc-800 hover:border-zinc-200 placeholder:text-[#818181]';

export const btnShadow = 'shadow-sm';

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const bgPurple = 'bg-tourTab-200';
export const textPurple = 'text-purple-700';
export const borderPurple = 'border-tourTab-200';

export const sliderButton = 'bg-zinc-950';
