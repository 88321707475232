import { Disclosure, Transition } from '@headlessui/react';
import React from 'react';
import { CaretRightIcon, CaretRightIconNew } from '../util/CustomIcons';

export default function SidebarAccordion({
  children,
  title,
  // opendis = true,
  setOpenDis = () => {},
}) {
  // const [opendis, setOpenDis] = useState(false);
  return (
    <React.Fragment>
      <div>
        <Disclosure
          as="div"
          className="dark:px-2.5 px-1.5"
          // defaultOpen="false"
          // onClick={() => {
          //   handelEdit(ticketsCat._id);
          // }}
          // key={0}
        >
          {({ open }) => (
            <>
              <div className={`w-full rounded-lg  ${open ? '' : ''}`}>
                <Disclosure.Button
                  // onClick={() => setOpenDis(!open)}
                  as={'button'}
                  type="button"
                  className={`bg-color-base-1 backdrop-blur-lg flex w-full justify-between items-center flex-wrap gap-3 dark:rounded-sm rounded-lg pl-2 pr-2 md:px-3 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75`}
                >
                  <div className="w-full">
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="textMuted text-sm 2xl:text-base text-color-secondary">
                          {title}
                        </p>
                      </div>
                      <div className="">
                        <div className="flex justify-end gap-3">
                          <CaretRightIcon
                            className={`${
                              open && 'rotate-90'
                            } duration-200 ease-in text-neutral-600 text-[10px]`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="px-2 pt-2.5 text-sm text-rose-500">
                    <div>{children}</div>
                  </Disclosure.Panel>
                </Transition>
              </div>
            </>
          )}
        </Disclosure>
      </div>
    </React.Fragment>
  );
}
