import React from 'react';
import ArtistPageV3 from './ArtistPageV3';

export default function index() {
  return (
    <>
      {/* <ArtistPageV2 /> */}
      <ArtistPageV3 />
    </>
  );
}
