import { Listbox, Transition } from '@headlessui/react';
import React from 'react';
import { Fragment } from 'react';
import { BsCheck2, BsChevronDown } from 'react-icons/bs';
import { CountryCodes } from '../../utils/CountryCodes';
import { GetCountry } from '../../utils/util';
import {
  formClass,
  formClassV2,
  formFloatClass,
  formFloatClassDark,
} from '../common/DefaultClasses';
import { MdCheck, MdOutlineKeyboardArrowDown } from 'react-icons/md';

function SelectCountryCheckout({
  control,
  _selected = null,
  name = null,
  setSelectedCountryCode = null,
  setSelectedCountry = null,
  from,
  formClass2,
  onlyCountryName,
  darkTheme,
  onlyCode = false,
  disabled,
  height,
  maxWidth,
  version2,
  lastKey,
  relativeToParent,
  noFlag,
  onlyCountryCode,
}) {
  const [selected, setSelected] = React.useState(
    onlyCountryName
      ? _selected !== null
        ? _selected
        : 'US'
      : _selected !== null
      ? _selected.split('+')?.length > 1
        ? _selected
        : 'US+1'
      : undefined,
  );

  React.useEffect(() => {
    setSelected(
      onlyCountryName
        ? _selected !== null
          ? _selected
          : 'US'
        : _selected !== null
        ? _selected.split('+')?.length > 1
          ? _selected
          : 'US+1'
        : undefined,
    );
  }, [_selected]);

  return (
    <React.Fragment>
      {onlyCode ? (
        <div className="w-full">
          <Listbox
            value={selected}
            disabled={disabled}
            onChange={(val) => {
              setSelected(val);
              if (setSelectedCountryCode !== null) {
                setSelectedCountryCode(val);
              }
              if (setSelectedCountry !== null) {
                onlyCountryName && setSelectedCountry('country', val);
              }
            }}
          >
            <div
              className={`${relativeToParent ? relativeToParent : 'relative'}`}
            >
              <div
                // className="form-floating"
                className={`${
                  darkTheme ? 'dark-floating-label' : ''
                } mb-3 form-floating w-full`}
              >
                <Listbox.Button
                  className={`${
                    darkTheme ? formFloatClassDark : ''
                  } ${formFloatClass}  relative form-control mySelect w-full cursor-default rounded-md flex items-center focus-visible:ring-gray-700`}
                  disabled
                >
                  <span className="inline-block truncate h-7 w-full">
                    <span className="flex w-full items-center pt-2">
                      {`+` + selected?.split('+')[1]}
                    </span>
                  </span>
                </Listbox.Button>
                {!disabled ? (
                  <>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <BsChevronDown
                        className="h-5 w-5 text-gray-400 mt-3.5"
                        aria-hidden="true"
                      />
                    </span>
                  </>
                ) : (
                  <></>
                )}

                <label for="phoneNum">Country Code</label>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                {/* <Listbox.Options
                  className={`absolute z-20 mt-1 max-h-40 w-full overflow-auto rounded-md 0 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm scrollCss ${
                    darkTheme
                      ? 'bg-neutral-800 backdrop-blur-md bg-opacity-80'
                      : 'bg-whiteNew-0'
                  } 
                  bg-color-base-2 ${lastKey ? 'bottom-8' : ''}`}
                > */}
                <Listbox.Options
                  className={`absolute z-20 mt-1 max-h-40 w-full overflow-auto rounded-md 0 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm scrollCss 
                  bg-color-base-2 ${lastKey ? 'bottom-8' : ''}`}
                >
                  {CountryCodes.map((country, idx) => (
                    <>
                      <Listbox.Option
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-7 pr-2 ${
                            active
                              ? `  ${
                                  darkTheme
                                    ? 'bg-neutral-700 text-whiteNew-0'
                                    : 'bg-sky-100 text-sky-900'
                                }`
                              : `${
                                  darkTheme
                                    ? 'text-whiteNew-0'
                                    : 'text-gray-900'
                                }`
                          }`
                        }
                        value={
                          onlyCountryName
                            ? `${country?.code}`
                            : `${country?.code}+${country?.phone}`
                        }
                        key={idx}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`flex items-center gap-2 ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              <span className="flex w-7 top-2 left-3">
                                <img
                                  style={{
                                    width: '25px',
                                    height: '20px',
                                  }}
                                  src={`https://flagcdn.com/w20/${String(
                                    `${country?.code}+${country?.phone}`,
                                  )
                                    .split('+')[0]
                                    .toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${String(
                                    `${country?.code}+${country?.phone}`,
                                  )
                                    .split('+')[0]
                                    .toLowerCase()}.png 2x`}
                                  alt="flag"
                                />
                              </span>
                              <span className="truncate">
                                {onlyCountryName
                                  ? ` ${country?.label}`
                                  : `${country?.code}+${country?.phone} ${
                                      from === 'about' ? '' : country?.label
                                    }`}
                              </span>
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-1 flex items-center text-sky-500">
                                <MdCheck
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    </>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      ) : (
        <div>
          <Listbox
            value={selected}
            disabled={disabled}
            onChange={(val) => {
              setSelected(val);
              if (setSelectedCountryCode !== null) {
                setSelectedCountryCode(val);
              }
              if (setSelectedCountry !== null) {
                onlyCountryName &&
                  from !== 'bank' &&
                  setSelectedCountry('country', val);
                onlyCountryName && from === 'bank' && setSelectedCountry(val);
              }
            }}
          >
            <div
              className={`${relativeToParent ? relativeToParent : 'relative'}`}
            >
              <Listbox.Button
                className={`${
                  noFlag
                    ? `relative w-full cursor-default rounded-md p-1 flex justify-between min-w-[70px]`
                    : `relative w-full cursor-default rounded-md ${
                        darkTheme
                          ? // 'bg-neutral-800 disabled:bg-gray-700 text-whiteNew-0 focus-visible:ring-gray-700 border-0 border-neutral-600  focus:border-2 focus:border-neutral-700 focus:ring-neutral-700 focus:ring-0 focus-visible:ring-0 py-1.5'
                            `${formClass}`
                          : `${
                              !version2
                                ? // 'bg-whiteNew-0 disabled:bg-gray-100 disabled:border-gray-300 focus-visible:ring-white focus-visible:ring-offset-sky-500 focus:ring-sky-500 focus:border-sky-500 focus-visible:border-indigo-500 focus:ring-1 focus-visible:ring-0 py-1 text-sm'
                                  `${formClass}`
                                : `${formClassV2} py-1.5 text-base`
                            }`
                      } pl-3 pr-6 text-left focus:outline-none focus-visible:ring-opacity-75 focus-visible:ring-offset-0 ${
                        from === 'about' ? 'py-1.5' : ''
                      }`
                } `}
                disabled
              >
                {noFlag ? (
                  <>
                    <span className="flex items-center truncate w-full -mt-[1px]">
                      <span className="flex items-center h-full w-full left-3 gap-2">
                        {onlyCountryName ? GetCountry(selected) : selected}
                      </span>
                    </span>
                  </>
                ) : (
                  <span className="flex items-center truncate">
                    <span className="flex items-center h-full left-3 gap-2">
                      <img
                        style={{
                          width: '25px',
                          height: '20px',
                        }}
                        className="w-5"
                        src={`https://flagcdn.com/w20/${String(selected)
                          .split('+')[0]
                          .toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${String(selected)
                          .split('+')[0]
                          .toLowerCase()}.png 2x`}
                        alt="flag"
                        // className="object-cover h-full w-full"
                      />
                      <span>
                        {onlyCountryName ? GetCountry(selected) : selected}
                      </span>
                    </span>
                  </span>
                )}

                <span className="pointer-events-none absolute inset-y-0 right-1 flex items-center shrink-0">
                  <MdOutlineKeyboardArrowDown
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className={'shadow-soft'}
              >
                {/* <Listbox.Options
                  className={`absolute z-20 mt-1 w-full overflow-auto rounded-md py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm scrollCss ${
                    darkTheme
                      ? 'bg-neutral-800 backdrop-blur-md bg-opacity-50'
                      : 'bg-whiteNew-0'
                  } ${height ? height : 'max-h-40'} ${
                    lastKey ? 'bottom-8' : ''
                  }${maxWidth ? maxWidth : ''}`}
                > */}

                <Listbox.Options
                  className={`absolute z-20 mt-1 w-full overflow-auto rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm scrollCss bg-color-base-2 ${
                    height ? height : 'max-h-40'
                  } ${lastKey ? 'bottom-8' : ''}${maxWidth ? maxWidth : ''}`}
                >
                  {CountryCodes.map((country, idx) => (
                    <>
                      <Listbox.Option
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-11 pr-4 ${
                            active
                              ? ` bg-color-base-1 text-color-primaryColor2`
                              : ``
                          }`
                        }
                        value={
                          onlyCountryName
                            ? `${country?.code}`
                            : onlyCountryCode
                            ? `+${country?.phone}`
                            : `${country?.code}+${country?.phone}`
                        }
                        key={idx}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              <span className="flex w-7 h-7 absolute top-2 left-3">
                                <img
                                  style={{
                                    width: '25px',
                                    height: '20px',
                                  }}
                                  src={`https://flagcdn.com/w20/${String(
                                    `${country?.code}+${country?.phone}`,
                                  )
                                    .split('+')[0]
                                    .toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${String(
                                    `${country?.code}+${country?.phone}`,
                                  )
                                    .split('+')[0]
                                    .toLowerCase()}.png 2x`}
                                  alt="flag"
                                  // className="object-cover h-full w-full"
                                />
                              </span>
                              {onlyCountryName
                                ? ` ${country?.label}`
                                : `${country?.code}+${country?.phone} ${
                                    from === 'about' ? '' : country?.label
                                  }`}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-sky-600">
                                <BsCheck2
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                      {idx === 0 && (
                        <span
                          style={{ height: '1px' }}
                          className={`flex w-full bg-level-1`}
                        ></span>
                      )}
                    </>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      )}
    </React.Fragment>
  );
}

export default SelectCountryCheckout;
