import {
  getPaginationSize,
  updatePageSize,
} from '../helpers/api/page_size/pageSize';
import { useMutation, useQuery } from '@tanstack/react-query';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { updatePaginationSize } from '../features/business_user/dashboard/dashboard.slice';
import { useOnceCall } from './useOnlyOnce';
import { useApiHeader } from './useDecodedToken';

export function usePageSize() {
  const dispatch = useDispatch();
  const { json_header } = useApiHeader();

  const getData = () => {
    return getPaginationSize(json_header);
  };

  const getAllPageSize = useMutation(getData, {
    onSuccess: async (res) => {
      dispatch(updatePaginationSize(res?.data?.data?.pagination_list));
    },
    onError: (err) => {
      message.error(err?.response?.data?.message);
    },
  });

  const insertNote = (payload) => {
    return updatePageSize(payload);
  };

  const updatePagination = useMutation(insertNote, {
    onSuccess: async (res) => {
      getAllPageSize?.mutate();
    },
    onError: (err) => {
      message.error(err?.response?.data?.message);
    },
  });

  return { getAllPageSize, updatePagination };
}

export function useGetAllPaginationSize() {
  const dispatch = useDispatch();
  const { json_header } = useApiHeader();
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['getPageSize'],
    queryFn: async () => {
      return await getPaginationSize(json_header);
    },
    onSuccess: async (res) => {
      if (localStorage.getItem('token')) {
        dispatch(updatePaginationSize(res?.data?.data?.pagination_list));
      }
    },
    refetchOnWindowFocus: true,
    keepPreviousData: true,
  });
  return { data, refetch, isLoading };
}
