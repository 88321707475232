import React from 'react';

export default function StatusPill({ status, children }) {
  return (
    <>
      <span
        className={`flex border-1 py-0.5 px-2 rounded-full ${
          status === 'error'
            ? 'border-red-600 bg-red-100 text-red-600'
            : status === 'progress'
            ? 'border-yellow-600 bg-yellow-100 text-yellow-600'
            : status === 'success'
            ? 'border-green-600 bg-green-100 text-green-600'
            : 'border-bluev2-600 bg-blue-100 text-bluev2-600'
        }`}
      >
        {children}
      </span>
    </>
  );
}
