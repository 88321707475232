import React from 'react';
import HeaderFillTop from '../HeaderFillTop';
import RightSideSectionWrapper from '../RightSideSectionWrapper';
import SecurityDefaultCompo from './SecurityDefaultCompo';

export default function SecurityDefault() {
  return (
    <React.Fragment>
      <div>
        <div className="">
          <HeaderFillTop
            title={'Security Default Settings'}
            extraPills={<></>}
            extra={
              <>
                {/* <span className="flex gap-2 items-center">
                  <Tooltip
                    title={!toggle ? 'Enable Edit' : 'Disable Edit'}
                    placement={'left'}
                  >
                    <span className="flex">
                      <ToogleSwitch value={toggle} toggleValue={toggleValue} />
                    </span>
                  </Tooltip>
                </span> */}
              </>
            }
          />
        </div>
        <RightSideSectionWrapper>
          <section>
            <div className="container-fluid">
              <div className="p-3">
                <SecurityDefaultCompo />
              </div>
            </div>
          </section>
        </RightSideSectionWrapper>
      </div>
    </React.Fragment>
  );
}
