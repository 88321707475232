import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sample_policy: [],
  user_policy: [],
  selected_policy: {},
};

const EventPolicies = {
  selectedRefundPolicy: '',
  selectedResalePolicy: '',
  selectedcancelledPolicy: '',
  selectedDelayPolicy: '',
  selectedTransferPolicy: '',
};

const slice = createSlice({
  name: 'policy',
  initialState,
  reducers: {
    updateSamplePolicy: (state, { payload }) => {
      state.sample_policy = payload;
    },
    updateUserPolicy: (state, { payload }) => {
      state.user_policy = payload;
    },
    update_selected_policy: (state, { payload }) => {
      state.selected_policy = payload;
    },
  },
});

const EventPolicyslice = createSlice({
  name: 'EventPolicyslice',
  initialState: EventPolicies,
  reducers: {
    updateRefundPolicy: (state, { payload }) => {
      state.selectedRefundPolicy = payload;
    },
    updateResalePolicy: (state, { payload }) => {
      state.selectedResalePolicy = payload;
    },
    updateCancelledPolicy: (state, { payload }) => {
      state.selectedcancelledPolicy = payload;
    },
    updateDelayPolicy: (state, { payload }) => {
      state.selectedDelayPolicy = payload;
    },
    updateTransferPolicy: (state, { payload }) => {
      state.selectedTransferPolicy = payload;
    },
    resetAllPolicies: (state, { payload }) => {

      state.selectedRefundPolicy = '';
      state.selectedResalePolicy = '';
      state.selectedcancelledPolicy = '';
      state.selectedDelayPolicy = '';
      state.selectedTransferPolicy = '';
    },
  },
});

let policyReducer = slice.reducer;
let EventPolicysliceReducer = EventPolicyslice.reducer;

export const { updateSamplePolicy, updateUserPolicy, update_selected_policy } =
  slice.actions;

export const {
  updateRefundPolicy,
  updateResalePolicy,
  updateCancelledPolicy,
  updateDelayPolicy,
  updateTransferPolicy,
  resetAllPolicies,
} = EventPolicyslice.actions;

const getAllPolicy = (state) => state.policy;
const getAllEventPolicy = (state) => state.EventPolicy;

export {
  policyReducer,
  getAllPolicy,
  EventPolicysliceReducer,
  getAllEventPolicy,
};
