import { useQuery } from '@tanstack/react-query';
import { getArtistDetails } from '../../helpers/api/artists/artist_detail.js';

export function useArtistDetails(username, enabled = true) {
    const artistDetails = useQuery({
        queryKey: ['artist_name', username],
        queryFn: async () => {
            if (username !== '' && username !== undefined) {
                return await getArtistDetails(username);
            } else {
                return {};
            }
        },
        enabled: enabled
    });

    const { data, refetch, isLoading } = artistDetails;

    return { data, refetch, isLoading };
}

