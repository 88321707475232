import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
// import Button from '../../common/Button';
import SelectCountryDrawer from './country_drawer/SelectCountryDrawer';
import {
  primaryBtnClassGradient,
  primaryBtnClassGradientHead,
} from '../../common/DefaultClasses';
// import { CartIcon } from '../../util/CustomIcons';
import UserDetailPopover from './UserDetailPopover';
import CartDrawer from './cart_drawer/CartDrawer';
import useDecodedToken from '../../../hooks/useDecodedToken';
import { DeleteAllSeats } from '../../../features/seatmap/seatmap.slice';
import { DeleteAllCart } from '../../../features/cart/cart.slice';
import { useDispatch } from 'react-redux';
import ThemeToggle from './ThemeToggle';
import NotificationDropdown from '../../util/header_components/NotificationDropdown';
import { resetPageLimit } from '../../../features/home_page/homepage.slice';
// import { useTrendingEventHooks } from '../../../pages/landing/home_page/hooks/useHomePageHooks';
// import { useSelector } from 'react-redux';

export default function HeaderRightSideNavigation({
  hideCompo,
  darkTheme,
  demo,
}) {
  const decodedToken = useDecodedToken();
  const dispatch = useDispatch();
  //to get the Trending list if cart have data
  // let page_limit = useSelector((state) => state?.homePageEvents);

  // const { trendingEventdetails } = useTrendingEventHooks(
  //   page_limit?.page,
  //   page_limit?.limit,
  //   true,
  // );
  const clearCartOnlyOnRedux = useCallback(() => {
    dispatch(DeleteAllSeats());
    dispatch(DeleteAllCart());
    // dispatch(resetPageLimit());
  }, [dispatch]);

  return (
    <div className="flex items-center gap-2 w-full justify-end">
      {hideCompo && (
        <>
          {decodedToken && (
            <>
              <div className="hidden lg:flex">
                <SelectCountryDrawer />
              </div>
              <div className="py-0.5 hidden lg:flex">
                <span className="flex w-1 h-7 bg-level-2"></span>
              </div>
            </>
          )}

          {!demo && (
            <div>
              <Link
                to={'/demo'}
                className={`${primaryBtnClassGradientHead} p-1.5 lg:min-w-[100px]`}
              >
                Demo
              </Link>
            </div>
          )}
        </>
      )}

      {!decodedToken ? (
        <>
          <div>
            <Link
              to={'/user/signup'}
              className={`${primaryBtnClassGradientHead} p-1.5 lg:min-w-[100px]`}
              onClick={clearCartOnlyOnRedux}
            >
              Sign Up
            </Link>
          </div>
          <div>
            <Link
              to={'/user/login'}
              className={`${primaryBtnClassGradientHead} p-1.5 lg:min-w-[100px]`}
              onClick={clearCartOnlyOnRedux}
            >
              Log In
            </Link>
          </div>
        </>
      ) : null}
      <ThemeToggle />
      <div>
        <CartDrawer darkTheme={darkTheme} />
      </div>
      {/* <div>
        <NotificationDropdown />
      </div> */}
      {decodedToken ? (
        <>
          {hideCompo && !demo && (
            <div className="py-0.5 hidden lg:flex">
              <span className="flex w-1 h-7 bg-level-2"></span>
            </div>
          )}
          <div>
            <UserDetailPopover darkTheme={darkTheme} hideCompo={hideCompo} />
          </div>
        </>
      ) : null}
    </div>
  );
}
