import React, { useCallback, useMemo } from 'react';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { authActions } from '../../../features/auth.slice';
import {
  BuyerIcon,
  EveneOrganizerIcon,
  LogoGoTix,
  SpinnerBtn,
} from '../../util/CustomIcons';
import {
  antCustomSelect,
  formClass,
  formClass2,
  primaryBtnClassGradient,
  primaryLoginBtnClassGradient,
} from '../../common/DefaultClasses';
import { MdOutlineStadium } from 'react-icons/md';
import Tooltip from '../../common/Tooltip';
import { Checkbox, Form, Input, Select } from 'antd';
import Image from '../../common/Image';
import { BsChevronDown } from 'react-icons/bs';
import NoDataFound from '../../common/NoDataFound';
import { RiListCheck2 } from 'react-icons/ri';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { EMAIL_REG } from '../../../utils/util';
import { useLogin } from './hooks/useLogin';
import { useUserDeviceLocation } from '../../../pages/stream_lounge/hooks/useStreamLounge';
import { useMutation } from '@tanstack/react-query';
import { resend_email } from '../../../helpers/api/auth/auth';

export default function LoginV2({ darkTheme }) {
  const dispatch = useDispatch();
  const {
    loginState,
    isError,
    isLoading,
    errored,
    form,
    phones,
    isRegisteredEmailFn,
    isRegisteredPhoneFn,
    toggleCrudeStep,
    resetAll,
  } = useLogin();

  const { state } = useLocation();
  const navigate = useNavigate();
  const creds = Form.useWatch('credential', form);
  const phone_code = Form.useWatch('phone_code', form);
  const deviceData = useUserDeviceLocation();

  const isPhoneNumber = useMemo(
    () => Boolean(creds && /^\d+$/.test(creds)),
    [creds],
  );
  const { isCrudeStep, showPasswordField } = loginState;

  const handleSubmit = useCallback(() => {
    resetAll();
    // localStorage.setItem('auth', creds);
    if (isCrudeStep) {
      if (isPhoneNumber) return isRegisteredPhoneFn({ creds, phone_code });
      return isRegisteredEmailFn(creds);
    }

    const { password, remember } = form.getFieldsValue();
    if (remember) {
      if (isPhoneNumber) {
        localStorage.setItem('phone_code', phone_code);
      }
      localStorage.setItem('auth', creds);
    }

    if (isPhoneNumber) {
      return dispatch(
        authActions.phone_login({
          contact: creds,
          password,
          country_code: phone_code,
          device_info: deviceData,
        }),
      );
    }
    localStorage.setItem('authEmail', creds);
    return dispatch(
      authActions.email_login({
        email: creds,
        password,
        device_info: deviceData,
      }),
    );
  }, [
    creds,
    phone_code,
    deviceData,
    dispatch,
    isCrudeStep,
    form,
    isRegisteredEmailFn,
    isRegisteredPhoneFn,
    isPhoneNumber,
    resetAll,
  ]);

  const mutation = useMutation(insertNote, {
    onSuccess: () => {
      navigate(`/auth/email-confiramtion?email=${creds}`);
    },
  });

  function insertNote() {
    return resend_email({
      email: creds,
    });
  }
  const onSubmit = (data) => {
    if (creds) {
      mutation.mutate();
    }
  };

  const is_from_checkout = useMemo(
    () => String(state?.pathname).includes('checkout'),
    [state],
  );

  return (
    <React.Fragment>
      <div className="flex justify-between w-full items-center">
        <Link to="/" className="flex justify-center w-[90px] md:w-[170px]">
          <LogoGoTix
            className={`w-full max-w-sm h-10 dark:text-whiteNew-0 text-zinc-900`}
          />
        </Link>
        <div className="flex items-center gap-2">
          <Link
            to={'..'}
            className={`relative justify-center items-center gap-2 dark:bg-zinc-900 bg-lightTheme-70 dark:hover:bg-zinc-800 hover:bg-lightTheme-80 border-1 dark:border-zinc-800 border-zinc-200 shadow-sm shadow-slate-600 duration-200 ease-in  py-1.5 disabled:opacity-70 cursor-pointer dark:text-whiteNew-0 text-zinc-600 flex rounded-[8px] p-1.5 px-2.5 text-xs md:text-base`}
            onClick={() => {
              if (is_from_checkout) {
                navigate(-1);
              } else {
                navigate('/');
              }
              dispatch(authActions.resetState());
              // navigate('/');
            }}
          >
            {is_from_checkout ? 'Continue as guest' : 'Explore as guest'}
          </Link>

          {!is_from_checkout ? (
            <>
              <div className="h-full flex items-center">
                <span
                  className="flex h-4 bg-gray-600"
                  style={{ width: '1px' }}
                ></span>
              </div>
              <Tooltip placement={'top'} title={'Demo Stadium'}>
                <Link
                  to="/demo"
                  className={`relative justify-center items-center gap-2 dark:bg-zinc-900 bg-lightTheme-70 dark:hover:bg-zinc-800 hover:bg-lightTheme-80 border-1 dark:border-zinc-800 border-zinc-200 shadow-sm shadow-slate-600 duration-200 ease-in disabled:opacity-70 cursor-pointer dark:text-whiteNew-0 text-zinc-900 flex rounded-[8px] p-[9px] text-xs md:text-base`}
                  onClick={() => {
                    dispatch(authActions.resetState());
                  }}
                >
                  <MdOutlineStadium />
                </Link>
              </Tooltip>
            </>
          ) : null}
        </div>
      </div>
      <div className="flex justify-center items-start flex-col pt-6 pb-3.5">
        <h1
          className={`dark:text-whiteNew-0 text-zinc-900 font-medium text-lg 2xl:text-xl `}
        >
          Welcome back!
        </h1>
        <p className="text-gray-500 text-sm 2xl:text-base">
          Please enter your login details.
        </p>
      </div>
      <div className="space-y-4 max-w-screen-lg w-full">
        {isError && (
          <span className="block fw-bold text-center mb-2 text-danger">
            {errored}
          </span>
        )}
        <div className="mt-3.5">
          <Form
            initialValues={{
              phone_code:
                localStorage.getItem('phone_code') || phones[0]?.value,
              credential: localStorage.getItem('auth'),
            }}
            form={form}
            onFinish={handleSubmit}
            disabled={isLoading}
          >
            {showPasswordField ? (
              <div className="flex flex-col my-3.5 gap-3 w-full">
                <div className="flex">
                  <div className="flex items-center gap-2.5 border-1 dark:border-zinc-700 border-zinc-200 rounded-md dark:text-whiteNew-0 dark:bg-zinc-900 bg-lightTheme-80 p-2.5">
                    <div>
                      <span>
                        <span className="flex gap-2">
                          <span
                            hidden={!isPhoneNumber}
                            className="flex  items-center gap-2 dark:text-whiteNew-0 text-zinc-900"
                          >
                            <Image
                              className="h-4"
                              src={`https://flagcdn.com/w20/${String(
                                String(form.getFieldValue('phone_code')).split(
                                  '+',
                                )[0],
                              ).toLowerCase()}.png`}
                            />
                            +
                            {
                              String(form.getFieldValue('phone_code')).split(
                                '+',
                              )[1]
                            }{' '}
                          </span>

                          <span>{creds}</span>
                        </span>
                      </span>
                    </div>
                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          form.resetFields(['password']);
                          toggleCrudeStep();
                        }}
                        className="px-0.5 text-sky-600"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div
              hidden={!isCrudeStep}
              className="flex justify-between items-center gap-2.5"
            >
              <div className="w-full relative">
                {isPhoneNumber ? (
                  <div className="absolute left-0 top-0 z-10 w-full max-w-[125px] shrink-0">
                    <Form.Item
                      className="w-full mb-3"
                      name="phone_code"
                      rules={[
                        {
                          required: true,
                          message: 'Please select country code.',
                        },
                      ]}
                      dependencies={['credential']}
                    >
                      <Select
                        placeholder={
                          <span className="text-[#818181]">Select</span>
                        }
                        // popupClassName={`${antCustomSelect} antcstm`}
                        className="w-full min-h-[40px] h-[40px] rounded-lg"
                        options={phones}
                        showSearch
                        autoClearSearchValue
                        suffixIcon={
                          <>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                              <BsChevronDown
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </>
                        }
                        notFoundContent={
                          <div className="w-full">
                            <NoDataFound
                              title={
                                <span className="text-xs text-whiteNew-0">
                                  Not Available
                                </span>
                              }
                              minHeight={'min-h-[80px]'}
                              icon={<RiListCheck2 className="text-sm" />}
                            />
                          </div>
                        }
                      />
                    </Form.Item>
                  </div>
                ) : null}

                <Form.Item
                  className={`${darkTheme ? 'dark-floating-label' : ''} mb-3`}
                  name="credential"
                  dependencies={['phone_code']}
                  rules={[
                    {
                      required: true,
                      message: 'Email or phone is required.',
                    },
                    ({ getFieldValue }) => ({
                      validator: (_, value) => {
                        if (
                          isPhoneNumber &&
                          (!value ||
                            isValidPhoneNumber(
                              value,
                              String(getFieldValue('phone_code')).split('+')[0],
                            ))
                        ) {
                          return Promise.resolve();
                        }
                        if (
                          !isPhoneNumber &&
                          (!value || EMAIL_REG.test(creds))
                        ) {
                          return Promise.resolve();
                        }
                        resetAll();
                        return Promise.reject(
                          new Error(
                            isPhoneNumber
                              ? 'Invalid phone number.'
                              : 'Invalid email.',
                          ),
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder="Email/Phone Number"
                    className={`${darkTheme ? formClass : ''} ${formClass}  ${
                      isPhoneNumber ? 'pl-[128px] bg-neutral-100' : ''
                    }`}
                  />
                </Form.Item>
                {loginState?.is_password_set === false ? (
                  <span className="text-red-1 text-sm">
                    Password has not been set.{' '}
                    <Link
                      to={`/auth/reset-password?token=${loginState?.token}&ref=login&email=${creds}`}
                      className="text-blue-3 hover:underline hover:text-blue-3"
                    >
                      {' '}
                      Click here to set your password
                    </Link>
                  </span>
                ) : null}
                {loginState?.email_verified === false ? (
                  <span className="text-red-1 text-sm">
                    Email is not verified yet.{' '}
                    <span
                      onClick={() => onSubmit()}
                      className="text-blue-3 hover:underline hover:text-blue-3"
                    >
                      {' '}
                      Click here to send verification link
                    </span>
                  </span>
                ) : null}
              </div>
            </div>

            {showPasswordField ? (
              <>
                <div className="relative mb-0">
                  <Form.Item
                    className={`${
                      darkTheme ? 'dark-floating-label' : ''
                    } w-full relative mb-1`}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter password.',
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      className={`${
                        darkTheme ? formClass : ''
                      } ${formClass} py-2.5 darkInputPassword`}
                      iconRender={(visible) =>
                        visible ? (
                          <span>
                            <FaRegEyeSlash className="cursor-pointer dark:text-whiteNew-0 text-zinc-900" />
                          </span>
                        ) : (
                          <span>
                            <FaRegEye className="cursor-pointer dark:text-whiteNew-0 text-zinc-900" />
                          </span>
                        )
                      }
                    />
                  </Form.Item>
                </div>

                <div>
                  <div className="flex justify-between items-center mt-0">
                    <div>
                      <Form.Item
                        className={`${darkTheme ? 'text-whiteNew-0' : ''} mb-0`}
                        name="remember"
                        valuePropName="checked"
                      >
                        <Checkbox className="mb-0 items-center ">
                          <span className="dark:text-whiteNew-0 text-zinc-900">
                            Remember Me
                          </span>
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <Link
                      to={'/user/reset-password'}
                      state={{
                        creds,
                        phone_code,
                      }}
                      className="bg-transparent p-0 dark:text-whiteNew-0 text-sky-600"
                      onClick={() => {
                        dispatch(authActions.resetState());
                      }}
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>
              </>
            ) : null}

            <div className="flex justify-center items-center mt-6">
              <button
                type="submit"
                disabled={isLoading}
                className={`${
                  darkTheme
                    ? `${primaryLoginBtnClassGradient}`
                    : 'bg-sky-600 text-gray-50 hover:bg-sky-700'
                } p-2.5 ${
                  isLoading ? 'rounded-full w-auto' : 'w-full rounded-[8px]'
                } duration-200 ease-in`}
              >
                {isLoading ? (
                  <SpinnerBtn className={'text-xl'} />
                ) : isCrudeStep ? (
                  'Next'
                ) : (
                  'Login'
                )}
              </button>
            </div>
          </Form>
          <div className="relative mt-3.5 flex justify-center">
            <div className=" text-center after:absolute after:content-[''] dark:after:bg-zinc-600 after:bg-zinc-200 after:top-1/2 after:left-0 after:h-[1px] after:w-full after:flex after:z-0">
              <span className="dark:bg-zinc-900 bg-whiteNew-0 dark:text-whiteNew-0 text-zinc-900 relative z-10 flex px-1.5">
                Or
              </span>
            </div>
          </div>

          {/* OTP Login Form */}

          {/* <Form>
            <FloatingLabel
              controlId="floatingInput"
              label="Email/ Phone"
              className="mb-0"
            >
              <Form.Control
                type="email"
                placeholder="Email/ Phone"
                className="border-t-0 border-x-0 rounded-b-none shadow-transparent focus:shadow"
              />
            </FloatingLabel>
            <div>
              <div className="flex justify-between items-center mt-0">
                <div></div>
                <button className="btn btn-link p-0 no-underline fw-medium">
                  Resend OTP
                </button>
              </div>
            </div>

            <div className="flex flex-col justify-center items-center mt-6  ">
              <div className="text-center max-w-md">
                <span className="flex text-xl textMuted">
                  An OTP has been sent to your mobile number please verify it
                  below
                </span>
              </div>
              <div>
                <div className="mt-3 verifyOtpBox">
                  <ReactInputVerificationCode
                    value={otp}
                    placeholder="-"
                    length={5}
                    autoFocus={true}
                    onChange={setOtp}
                    className="text-dark"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center mt-5">
              <button className="btn btnPrimary rounded-xl w-full">
                Login
              </button>
            </div>
          </Form> */}
        </div>

        <div className="row justify-content-center mt-2">
          <div className="col-12 text-center">
            <div className="flex justify-center items-center gap-2">
              <span
                className={`dark:text-whiteNew-0 text-zinc-900 mb-0 font-normal`}
              >
                Don't have an account?
              </span>
              <Link
                to={'/user/signup'}
                type="button"
                className="dark:text-landingPurple-60 text-sky-600 dark:hover:text-landingPurple-70 hover:underline text-sm hover:text-sky-700"
                onClick={() => {
                  dispatch(authActions.resetState());
                  dispatch(authActions.setUtm());
                }}
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
