import React from 'react';
import HelmetComponent from '../../../utils/HelmateComponent';
import { Link } from 'react-router-dom';
import { LogoGoTixN } from '../../../components/util/CustomIcons';
// import SignUpV2 from '../../../components/auth/signup/SignUpV2';
import TFA from '../../../components/auth/login/TFA';

// import TwoFactorAuth from '../../../../components/auth/login/TwoFactorAuth';

function TwoFactorAuth({ compo, darkTheme, showLogo }) {
  return (
    <>
      <HelmetComponent title={'NFTGOTIX | 2FA'} />
      {showLogo ? (
        <div className="w-full flex justify-center">
          <Link to="/" className="">
            <LogoGoTixN
              className={`w-full max-w-sm h-10 ${
                darkTheme ? 'text-whiteNew-0' : ''
              }`}
            />
          </Link>
        </div>
      ) : null}
      <div className="w-full flex justify-center">
        <figure
          className={`flex justify-center items-center w-full border-1 rounded-2xl overflow-hidden dark:bg-color-base-1 max-w-2xl border-level-4`}
        >
          <div
            className={`w-full p-3.5 md:px-8 py-10 dark:bg-color-base-1 bg-whiteNew-0`}
          >
            <div>
              {/* {compo === 'TFA' && <TFA darkTheme={darkTheme} />} */}
              <TFA />
            </div>
          </div>
        </figure>
      </div>
    </>
  );
}

export default TwoFactorAuth;
