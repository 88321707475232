import React, { memo, useMemo, useRef } from 'react';
import Image from '../../../components/common/Image';
import {
  InPersonIcon2,
  VirtualIcon2,
} from '../../../components/util/CustomIcons';
import { primaryBtnClassGradient } from '../../../components/common/DefaultClasses';
import {
  DATEFORMAT,
  checkEventStartOrEnd,
  formatUsd,
  getLowestPrice,
} from '../../../utils/util';
import { Card } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Divider } from 'antd';
import { SkeletonTableListView } from '../../../components/util/Skeleton';
import EventImageVideo from '../../../components/common/EventImageVideo';

export default function EventTableListView({ props }) {
  const {
    all_event,
    // exploreEventdetails,
    loadMore,
    hasMore,
    filter_loading,
  } = props;
  const all_events = useMemo(() => all_event, [all_event]);
  return (
    <>
      <div className="darkListViewTable">
        {/* <s
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: 1200 }}
        /> */}

        <div className="bg-transparent overflow-auto scrollCss">
          <Card className="bg-transparent min-w-[100%] w-[1220px]">
            <Card.Header className="bg-transparent border-level-2 py-2.5">
              <div className="flex justify-around items-center gap-2 text-left">
                <div className="shrink-0 max-w-[300px] w-full">
                  <span className="dark:text-neutral-400 text-zinc-900 text-sm">
                    Event Name
                  </span>
                </div>
                <div className="truncate shrink-0 w-full max-w-[150px]">
                  <span className="dark:text-neutral-400 text-zinc-900 text-sm">
                    Start Date
                  </span>
                </div>
                {/* <div className="truncate w-full">
                  <span className="text-neutral-400 text-xs">Sales On</span>
                </div> */}
                {/* <div className="truncate w-full">
                  <span className="text-neutral-400 text-xs">Location</span>
                </div> */}
                <div className="truncate shrink-0 w-full max-w-[200px]">
                  <span className="dark:text-neutral-400 text-zinc-900 text-sm">
                    Event Type
                  </span>
                </div>

                <div className="truncate shrink-0 w-full max-w-[200px]">
                  <span className="dark:text-neutral-400 text-zinc-900 text-sm">
                    Status
                  </span>
                </div>
                <div className="truncate shrink-0 w-full max-w-[200px]">
                  <span className="dark:text-neutral-400 text-zinc-800 text-sm">
                    Price Start
                  </span>
                </div>
                <div className="truncate shrink-0 w-full max-w-[100px]">
                  <span className="dark:text-neutral-400 text-zinc-900 text-sm">
                    Action
                  </span>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="p-0 max">
              <div className="divide-y dark:divide-level-2 divide-level-2">
                {filter_loading ? (
                  [...Array(8)].map((_, index) => (
                    <SkeletonTableListView key={_} />
                  ))
                ) : (
                  <InfiniteScroll
                    dataLength={all_event?.length}
                    next={loadMore}
                    hasMore={hasMore}
                    className="overflow-x-hidden"
                    loader={[...Array(5)].map((_, index) => (
                      <SkeletonTableListView key={_} />
                    ))}
                  >
                    {all_events?.map((event) => {
                      return <EventCardMemo key={event?._id} event={event} />;
                    })}
                  </InfiniteScroll>
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

const EventType = ({ type }) => {
  if (type === 'both') {
    return (
      <div className="flex items-center dark:text-whiteNew-0 text-zinc-900 gap-1 dark:bg-brown-700 bg-lightTheme-90 px-1 rounded-[5px] min-h-[20px]">
        <div className="flex items-center gap-1 leading-none shrink-0">
          <span>
            <InPersonIcon2 className={'text-lg 2xl:text-xl'} />
          </span>
          <span className="flex text-xs font-normal">In Person</span>
        </div>
        <div className="flex items-center">
          <span className="flex h-[12px] w-[1px] bg-whiteNew-0"></span>
        </div>
        <div className="flex items-center gap-1 shrink-0">
          <span>
            <VirtualIcon2 className={'text-xs 2xl:text-sm'} />
          </span>
          <span className="flex text-xs font-normal">Virtual</span>
        </div>
      </div>
    );
  }
  if (type === 'Virtual') {
    return (
      <div className="flex items-center dark:text-whiteNew-0 text-zinc-900 gap-1 dark:bg-brown-700 bg-lightTheme-90 px-1 rounded-[5px] min-h-[20px]">
        <div className="flex items-center gap-1 shrink-0">
          <span>
            <VirtualIcon2 className={'text-xs 2xl:text-sm'} />
          </span>
          <span className="flex text-xs font-normal">Virtual</span>
        </div>
      </div>
    );
  }

  if (type === 'Physical') {
    return (
      <div className="flex items-center dark:text-whiteNew-0 text-zinc-900 gap-1 dark:bg-brown-700 bg-lightTheme-90 px-1 rounded-[5px] min-h-[20px]">
        <div className="flex items-center gap-1 leading-none shrink-0">
          <span>
            <InPersonIcon2 className={'text-lg 2xl:text-xl'} />
          </span>
          <span className="flex text-xs font-normal">In Person</span>
        </div>
      </div>
    );
  }
};

const EventTypeMemo = memo(EventType);

const EventCard = ({ event, key }) => {
  const { profile_image, name, start_date_time, category, event_type, slug } =
    event;
  // console.log('profile_image', profile_image);
  const message = useRef(null);
  const getEventTicketStatus = useMemo(() => {
    if (Array.isArray(category) && !category.length) return null;
    return category?.every((item) => {
      if (Boolean(Number(item?.remaining_tickets) <= 0)) {
        message.current = 'Sold Out';
        return true;
      }

      if (Boolean(Date.parse(moment(item?.end_date)) < Date.parse(moment()))) {
        message.current = 'Sale ended';
        return true;
      }
      if (
        Boolean(Number(item?.remaining_tickets) <= 0) ||
        Boolean(Date.parse(moment(item?.end_date)) < Date.parse(moment()))
      ) {
        message.current = 'No Tickets Available';
        return true;
      }
      return false;
    });
  }, [category]);
  return (
    <>
      <div
        key={key}
        className="flex justify-around items-center gap-2 text-left dark:text-whiteNew-0 text-zinc-800 py-[8px] px-2.5 dark:hover:bg-neutral-900 hover:bg-zinc-100"
      >
        <div className="shrink-0 max-w-[300px] w-full">
          <Link
            to={`/event-detail/${slug}`}
            className="flex items-center gap-1.5 font-semibold truncate hover:text-landingPurple-60"
          >
            <span className="flex w-7 h-7 rounded-md overflow-hidden shrink-0">
              <EventImageVideo
                data={profile_image?.[0] || profile_image}
                alt="profile picture"
                className={'w-full h-full object-cover'}
                loading="lazy"
                // width="384px"
                // height="512px"
              />
              {/* <Image
                src={profile_image?.url}
                className={'w-full h-full object-cover'}
              /> */}
            </span>
            <span title={name} className="truncate cursor-pointer">
              {name}
            </span>
          </Link>
        </div>
        <div className="truncate shrink-0 w-full max-w-[150px]">
          <span>
            {moment(
              moment.utc(start_date_time).local(),
              'YYYY-MM-DD hh:mm a',
            ).format(DATEFORMAT) ?? '-'}
          </span>
        </div>
        {/* <div className="truncate w-full">
        <span>
          {getEventTicketStatus
            ? message.current
            : moment(
                moment.utc(getSalesStartDate(category)).local(),
                'YYYY-MM-DD hh:mm a',
              ).format('MM/DD/YY - HH:mm') ?? '-'}
        </span>
      </div> */}
        {/* <div className="truncate w-full">
        <span>Allegiant Stadium</span>
      </div> */}
        <div className="truncate shrink-0 w-full max-w-[200px]">
          <div className="flex shrink-0">
            <EventTypeMemo type={event_type} />
          </div>
        </div>
        <div className="truncate shrink-0 w-full max-w-[200px]">
          <div className="flex">
            <div className="text-xs">{checkEventStartOrEnd(event) ?? '-'}</div>
          </div>
        </div>
        <div className="truncate shrink-0 w-full max-w-[200px]">
          <span className="font-semibold">
            {getLowestPrice(category)
              ? `${formatUsd(getLowestPrice(category) ?? 0)}+`
              : '-'}
          </span>
        </div>
        <div className="truncate shrink-0 w-full max-w-[100px]">
          <Link
            to={`/event-detail/${slug}`}
            className={`${primaryBtnClassGradient}  text-gray-50 rounded-[0px] duration-200 ease-in text-xs`}
          >
            <span className="font-medium items-center">
              {getEventTicketStatus ? 'VIEW EVENT' : 'Buy Now'}
            </span>
          </Link>
        </div>
      </div>
      <Divider className="dark:bg-neutral-700 bg-lightTheme-90 m-0" />
    </>
  );
};

const EventCardMemo = memo(EventCard);
