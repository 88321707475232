import { useState } from 'react';

export default function usePage(defaultValue) {
  const [value, setValue] = useState(defaultValue);

  function changeValue(value) {
    setValue(value);
  }

  return [value, changeValue];
}
