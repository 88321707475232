import React from 'react';

export default function RightSideSectionWrapper({ children }) {
  return (
    <React.Fragment>
      {/* <div className="w-full flex-grow relative flex flex-col h-full"> */}
      <div className="w-full flex-grow relative flex flex-col">
        <div className="min-h-[60vh] md:max-h-[61vh] 2xl:max-h-[73vh] scrollCss overflow-x-hidden overflow-y-auto">
          {children}
        </div>
      </div>
    </React.Fragment>
  );
}
