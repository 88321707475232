import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function GoToTop() {
  const routePath = useLocation();
  // const onTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth',
  //   });
  // };
  useEffect(() => {
    // setTimeout(onTop(), 1000);
    // if (!routePath.hash) {
    //   onTop();
    // }
    // onTop();
    // window.scrollTo(0, 0);
  }, [routePath]);

  return null;
}
