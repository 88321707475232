import { Suspense, lazy } from 'react';

export function createLazyComponent(loadPromise, params) {
  const { fallback = null } = params || {};
  const LazyComp = lazy(loadPromise);
  return (props) => (
    <>
      <Suspense fallback={fallback}>
        <LazyComp {...props} />
      </Suspense>
    </>
  );
}
