import React, { memo, useCallback, useMemo } from 'react';
import { Input, ConfigProvider, Tooltip, message } from 'antd';
import { btnPrimaryClass, formClass } from '../common/DefaultClasses';
import {
  useCheckoutActions,
  useCheckoutBilling,
  useCheckoutTicket,
} from '../../pages/checkout/hooks/useCheckout';
import moment from 'moment';
import { formatUsd } from '../../utils/util';
import ConfirmPaymentModeModel from '../common/notification_modal/ConfirPaymentModeModel';
import AlertModal from '../common/notification_modal/AlertModal';
import PoliciesModel from '../common/notification_modal/PoliciesModel';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateCheckoutBillingSummary } from '../../features/checkout/checkout.slice';
import useDecodedToken from '../../hooks/useDecodedToken';
import EventImageVideo from '../common/EventImageVideo';

function CheckoutOrderSummary({ form }) {
  const {
    checkout_event,
    userDetails,
    PaymentDetails,
    btnStatus,
    checkout_billing_summary,
  } = useCheckoutActions();

  const dispatch = useDispatch();

  const { ticket_category, ticket_price_for_category, sub_total_price } =
    useCheckoutTicket();

  const { tickets, isVirtual } = ticket_category;
  const {
    handleSaveAndPayment,
    getTicketCancelDisableTime,
    billingModalState,
    setBillingModalState,
  } = useCheckoutBilling();
  const { total, grand_total } = sub_total_price;
  const { name, start_date_time, venue, profile_image, event_type } =
    checkout_event;
  const decodedToken = useDecodedToken();
  const [searchParams] = useSearchParams();

  const show_name = useMemo(() => {
    if (event_type === 'Virtual') return 'Virtual Event';
    if (event_type === 'both' || event_type === 'Physical') {
      if (Array.isArray(venue) && venue.length) {
        return `${String(venue[0]?.address)}, ${String(
          venue[0]?.state,
        )}, ${String(venue[0]?.country)}`;
      }
    }
  }, [event_type, venue]);

  const ShowTicketList = () => {
    if (isVirtual) {
      return (
        <>
          {Object.keys(tickets)?.map((ticket) => (
            <div
              key={ticket}
              className="flex flex-col md:flex-row justify-between text-base md:text-lg"
            >
              <span className="dark:text-gray-200 text-zinc-600">
                {ticket} - {tickets[ticket]?.length}
                {/* Section: 110, Row: 8, Seat: 5 */}
              </span>
              <span className="font-semibold dark:text-gray-200 text-zinc-800">
                {formatUsd(ticket_price_for_category(tickets[ticket]) ?? 0)}
              </span>
            </div>
          ))}
        </>
      );
    }

    return (
      <>
        {tickets?.map((ticket, index) => (
          <div
            key={index}
            className="flex flex-col md:flex-row justify-between text-base md:text-lg"
          >
            <span className="dark:text-gray-200 text-zinc-800 flex flex-col">
              <span>{ticket?.category}</span>
              <span className="font-light text-sm">
                {` - Section: ${ticket?.section}`} - {`Row: ${ticket?.row}`} -{' '}
                {`Seat: ${ticket?.seat_number}`}
              </span>
              {/* Section: 110, Row: 8, Seat: 5 */}
            </span>
            <span className="font-semibold dark:text-gray-200 text-zinc-800">
              {formatUsd(
                Number(ticket?.resale_price) || Number(ticket?.price) || 0,
              )}
            </span>
          </div>
        ))}
      </>
    );
  };

  const ShowTicketListMemo = memo(ShowTicketList);

  const checkoutSubmit = useCallback(() => {
    setBillingModalState({
      ...billingModalState,
      payment_modal: true,
    });
    if (!decodedToken) {
      return dispatch(
        updateCheckoutBillingSummary({
          ...checkout_billing_summary,
          billing_address: form.getFieldsValue(),
          sub_total_price,
          ticket_category,
        }),
      );
    }

    dispatch(
      updateCheckoutBillingSummary({
        ...checkout_billing_summary,
        sub_total_price,
        ticket_category,
      }),
    );
  }, [
    billingModalState,
    checkout_billing_summary,
    dispatch,
    setBillingModalState,
    sub_total_price,
    ticket_category,
    decodedToken,
    form,
  ]);
  const handleCheckoutButtonClick = useCallback(() => {
    if (!decodedToken) {
      form.submit();
      try {
        form.validateFields({ validateOnly: true }).then(
          () => {
            return checkoutSubmit();
          },
          () => {
            return message.error('Please complete the form');
          },
        );
      } catch (err) {
        return message.error('Please complete the form');
      }
      if (!form.getFieldValue('email')) {
        return message.error('Please provide email and phone number');
      }
    } else {
      return checkoutSubmit();
    }
  }, [checkoutSubmit, decodedToken, form]);

  const profile_img = useMemo(() => {
    if (profile_image?.url) {
      return profile_image;
    }
    return profile_image?.[0];
  }, [profile_image]);

  return (
    <>
      <div className="w-full flex flex-col">
        <div className="p-3.5 md:p-7 md:pe-16">
          <div className="flex flex-col mb-3">
            <h2 className="text-color-secondary text-lg md:text-2xl">
              Booking Summary
            </h2>
          </div>
          <div className="flex flex-row gap-2 md:gap-1">
            <div className="md:basis-1/4">
              <div className="h-[90px] md:h-[100px] w-[90px] md:w-[120px] md:max-w-full bg-neutral-800 rounded-md overflow-hidden border-1 border-neutral-700">
                <EventImageVideo
                  data={profile_img}
                  alt={'Event Picture'}
                  className={'!h-[100px] object-cover w-full'}
                />
              </div>
            </div>
            <div className="md:basis-3/4">
              <div className="flex flex-col">
                <h2 className="text-sm md:text-lg dark:text-gray-200 text-zinc-500 font-semibold md:mb-2">
                  {name ?? ''}
                </h2>
                <span className="text-xs md:text-base dark:text-gray-400 text-gray-600">
                  {moment(moment.utc(start_date_time).local())?.format(
                    'MM/DD/YY - HH:mm',
                  )}{' '}
                </span>
                {PaymentDetails?.eventType === 'Virtual' ? (
                  <span className="text-xs md:text-base dark:text-gray-400 text-gray-600">
                    {PaymentDetails?.eventType ?? ''}
                  </span>
                ) : (
                  <>
                    <Tooltip title={show_name}>
                      <span className="text-xs md:text-base text-gray-400">
                        {Array.isArray(venue) && venue?.length
                          ? venue[0]?.name?.split('-')[0]
                          : 'Virtual'}{' '}
                      </span>
                    </Tooltip>
                    <span className="text-xs md:text-base dark:text-gray-400 text-zinc-800">
                      {'In-Person'}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="border-t border-b border-level-2 py-2">
          <div className="text-sm md:text-base flex flex-row items-center justify-start ps-3 md:ps-[0px] md:justify-end gap-2 pe-16">
            {/* <span className="h-4 w-1 bg-gray-400 flex"></span> */}
            <span
              onClick={() =>
                setBillingModalState({
                  ...billingModalState,
                  payment_policy_modal: true,
                })
              }
              className="dark:text-landingPurple-60 text-sky-600 cursor-pointer"
            >
              Ticket Policy
            </span>
          </div>
        </div>

        <div className="border-b border-level-2 py-2 mt-0">
          <div className="flex flex-col gap-1 px-3.5 md:px-7 py-2 pe-7 md:pe-16">
            <div className="dark:text-gray-400 text-zinc-800 text-sm md:text-base">
              Ticket Information
            </div>
            <ShowTicketListMemo />
          </div>
        </div>

        <div className="border-b border-level-2 py-2 mt-0">
          <div className="flex flex-row gap-1 px-3.5 md:px-7 py-2 md:pe-16">
            <div className="flex flex-row justify-between w-full text-lg">
              <div className="dark:text-gray-200 text-zinc-800 text-lg">
                Subtotal
              </div>
              <div className="font-semibold dark:text-gray-200 text-zinc-800">
                {formatUsd(total ?? 0)}
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-1 px-3.5 md:px-7 py-2 md:pe-16">
            <div className="flex flex-row justify-between w-full">
              <div className="dark:text-gray-400 text-md text-zinc-800">
                + Convenience
              </div>
              <div className="font-normal dark:text-gray-200 text-zinc-800 text-md">
                {formatUsd(0.52)}
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-1 px-3.5 md:px-7 py-2 md:pe-16">
            <div className="flex flex-row w-full gap-2.5">
              <div className="basis-3/4">
                <ConfigProvider
                  theme={{
                    token: {
                      fontFamily: 'Poppins',
                    },
                  }}
                >
                  <Input
                    className={`${formClass}`}
                    placeholder="Discount code or gift card"
                  />
                </ConfigProvider>
              </div>
              <div className="basis-1/4">
                <button className={`${btnPrimaryClass}  w-full h-10 md:h-10`}>
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-level-2 py-2 mt-0">
          <div className="flex flex-row justify-between px-3.5 md:px-7 py-2 md:pe-16">
            <span className="text-base md:text-lg font-semibold dark:text-gray-200 text-zinc-800">
              Amount Payable
            </span>
            <span className="text-lg md:text-lg font-semibold dark:text-gray-200 text-zinc-800">
              {formatUsd(grand_total ?? 0)}
            </span>
          </div>
        </div>

        <div className="border-b border-level-2 py-2 mt-0">
          <div className="flex flex-col px-3.5 md:px-7 py-2 gap-2 md:pe-16">
            <div className="flex flex-row">
              <span className="text-base md:text-lg dark:text-gray-200 text-zinc-800">
                Selected Payment Method :
                <span className="text-base dark:text-gray-400 text-gray-500 ml-2">
                  {decodedToken ? 'USD Wallet' : 'Payment Gateway (Razorpay)'}
                </span>
              </span>
            </div>

            {/* <div className="flex flex-row w-full justify-between"> */}
            {/* <div className="text-base text-gray-400">
                {decodedToken ? 'USD Wallet' : 'Payment Gateway (Razorpay)'}
              </div> */}
            {/* <div className="flex flex-col items-end">
              <span className="text-gray-400 text-md">Account no.</span>
              <span className="text-gray-400 text-md">**** **** **** 1111</span>
            </div> */}
            {/* </div> */}
          </div>
        </div>

        <div className="py-2 mt-0">
          <div className="flex flex-col px-3.5 md:px-7 py-2 gap-2 md:pe-16">
            <button
              onClick={handleCheckoutButtonClick}
              // className={`bg-gradient-to-r dark:from-purplev2-800 from-sky-600 dark:to-purplev2-900 to-sky-600 w-full text-base text-white p-2.5 md:p-3 rounded-lg`}
              className={`${btnPrimaryClass} text-base p-2.5 py-3 w-full h-10 md:h-10`}
            >
              {btnStatus === 'Checkout' ? 'Continue to Payment' : btnStatus}
              {/*  */}
            </button>
          </div>
        </div>
      </div>
      <ConfirmPaymentModeModel
        isOpen={billingModalState.payment_modal}
        setIsOpen={(val) =>
          setBillingModalState({ ...billingModalState, payment_modal: val })
        }
        handleSaveAndPayment={handleSaveAndPayment}
        userDetails={userDetails}
        totalAmount={grand_total}
        darkTheme={true}
      />
      <AlertModal
        isOpen={billingModalState.payment_alert_modal}
        setIsOpen={(val) =>
          setBillingModalState({
            ...billingModalState,
            payment_alert_modal: val,
          })
        }
        from="buyer"
        navigateBack
        slug={searchParams.get('slug')}
        darkTheme={true}
      />
      <PoliciesModel
        isOpen={billingModalState.payment_policy_modal}
        setIsOpen={(val) =>
          setBillingModalState({
            ...billingModalState,
            payment_policy_modal: val,
          })
        }
        policies={PaymentDetails?.policies}
        event={{
          start_time: PaymentDetails?.start_date_time,
          end_time: PaymentDetails?.start_date_time,
        }}
        darkTheme={true}
        event_type={
          searchParams?.get('type') === 'virtual'
            ? 'Virtual'
            : searchParams?.get('type')
        }
        getTicketCancelDisableTime={getTicketCancelDisableTime}
      />
      {/* <AlertReloadModal
        setIsOpen={setReload}
        isOpen={reload}
        darkTheme={true}
        // setEventType={setEventType}
        // eventType={eventdetails?.data?.data?.event_type}
      /> */}
    </>
  );
}

export default CheckoutOrderSummary;
