import React from 'react';
// import CheckoutPage from './CheckoutPage';
import CheckoutPageV2 from './CheckoutPageV2';

export default function index() {
  return (
    <>
      <CheckoutPageV2 />
      {/* <CheckoutPage /> */}
    </>
  );
}
