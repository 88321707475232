import axios from 'axios';
import config from '../../../api/Config';

export async function getPaginationSize(headers) {
  if (!localStorage.getItem('token')) {
    return null;
  }
  const res = axios.get(
    `${config.baseUrl + config.endpoints.getPaginationSize}`,
    headers,
  );

  return res;
}

export async function updatePageSize(body) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.updatePaginationSize,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}
