import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { useWallet } from '@solana/wallet-adapter-react';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Web3 from 'web3';
import { ArtchiveWalletAdapter, ArtchiveWalletName } from '../contexts/artchive/adapter';
import { useConnectionConfig } from '../contexts/ConnectionProvider';
import useMetaMask, { getMetamaskWallet } from '../contexts/MetamaskProvider';
import { useWalletModal } from '../contexts/WalletProvider';
import { CHAINS } from '../utils/blockchain/ethereum/Chains';
import { isValidSolanaAddress } from '../utils/blockchain/solana/publicKeys';
import { message } from 'antd';

export function useWalletHooks() {
  const { selectedWallet, refreshFunds, txError, bal } = useWalletModal();
  const [isConnecting, setIsConnecting] = useState(false);
  const [showBlockchainTab, setShowBlockchainTab] = useState(false)
  const {
    isActive,
    connect: metaConnect,
    disconnect: metaDisconnect,
    refreshBalance,
    accounts,
    chainId,
    error,
    setError,
    balances,
  } = useMetaMask();

  const { env } = useConnectionConfig();
  const {
    select: solConnect,
    disconnect: solDisconnect,
    connected,
    publicKey,
    wallet,
  } = useWallet();

  const handleDisconnectWallet = useCallback(async () => {
    if (isActive) {
      await metaDisconnect();
    } else {
      await solDisconnect();
    }
  }, [isActive, metaDisconnect, solDisconnect]);

  const handleConnectWallet = useCallback(async (wallet) => {
    handleDisconnectWallet();
    setIsConnecting(true);
    try {
      const _selected = selectedWallet?.name || wallet;
      localStorage.setItem("wallet_name", _selected)
      if (_selected === 'Metamask') {
        await metaConnect(false, true);
      } if (_selected === ArtchiveWalletName) {
        return
      } else {
        await solConnect(_selected);
      }
    } catch(err) {
      message.error(err)
    } 
    finally {
      setIsConnecting(false)
    }
    
  }, [handleDisconnectWallet, metaConnect, selectedWallet, solConnect]);


  const handleRefreshBalance = useCallback(async () => {
    if (selectedWallet?.name === 'Metamask') {
      await refreshBalance();
    } else {
      refreshFunds();
    }
  }, [refreshBalance, refreshFunds, selectedWallet]);

  const walletConnected = useMemo(() => {
    return connected || isActive
  }, [connected, isActive]);
  const walletBalance = useMemo(() => {
    return connected
      ? bal
      : isActive
      ? balances / 1e18
      : 0;
  }, [bal, balances, isActive, connected])
    

  useEffect(() => {
    if (walletConnected) {
      setIsConnecting(false);
    }
    if (error || txError) {
      setIsConnecting(false);
    }
  }, [walletConnected, error, txError]);

  // if(walletConnected){
  //   setIsConnecting(false)
  // }
  const walletname = useMemo(() =>
    connected
    ? wallet?.adapter?.name
    : localStorage.getItem("wallet_name")
    ? localStorage.getItem("wallet_name")
    : isActive && 'Metamask'
  , [connected, wallet, isActive]) ;

  const blockchainselected = useMemo(() => 
    connected
      ? 'solana'
      : isActive && 'ethereum'
    , [connected, isActive])

  const walletAddress = useMemo(() =>
    connected
      ? publicKey?.toBase58()
      : isActive && accounts[0]
  , [accounts, connected, publicKey, isActive]) 

  const network = useMemo(() => isActive ? CHAINS[chainId]?.label : env, [chainId, env, isActive]);

  function getWalletImage(wallet) {
    switch (wallet) {
      case 'phantom':
        return { icon: new PhantomWalletAdapter()?.icon, blockchain: 'solana' };
      case 'solflare':
        return {
          icon: new SolflareWalletAdapter()?.icon,
          blockchain: 'solana',
        };
      case 'artc wallet':
        return {
          icon: new ArtchiveWalletAdapter()?.icon,
          blockchain: blockchainselected,
        };
      case 'metamask':
        return { icon: getMetamaskWallet()?.icon, blockchain: 'ethereum' };
      default:
        return;
    }
  }

  return {
    handleConnectWallet,
    handleDisconnectWallet,
    handleRefreshBalance,
    walletConnected,
    walletAddress,
    network,
    walletname,
    isConnecting: walletConnected ? false : isConnecting,
    error: error,
    setError: setError,
    blockchainselected,
    getWalletImage,
    walletBalance,
    showBlockchainTab,
    setShowBlockchainTab
  };
}

export function solanaExplorer(address, network) {
  switch (network) {
    case 'mainnet-beta':
      return `https://explorer.solana.com/address/${address}`;
    case 'devnet':
      return `https://explorer.solana.com/address/${address}?cluster=devnet`;
    case 'testnet':
      return `https://explorer.solana.com/address/${address}?cluster=testnet`;
    default:
      return `https://explorer.solana.com/address/${address}`;
  }
}

export function ethPolyExplorer(address, network) {
  switch (network) {
    case 'polygon-mainnet':
      return `https://polygonscan.com/address/${address}`;
    case 'mainnet-testnet':
      return `https://mumbai.polygonscan.com/address/${address}`;
    case 'mainnet':
      return `https://etherscan.io/address/${address}`;
    default:
      return `https://mumbai.polygonscan.com/address/${address}`;
  }
}

export function explorerAddressLink(address, network) {
  const expl_link = isValidSolanaAddress(address)
    ? solanaExplorer(address, network)
    : Web3.utils.isAddress(address) && ethPolyExplorer(address, network);

  return expl_link;
}

export function solanaSigExplorer(trx, network) {
  switch (network) {
    case 'mainnet-beta':
      return `https://explorer.solana.com/tx/${trx}`;
    case 'devnet':
      return `https://explorer.solana.com/tx/${trx}?cluster=devnet`;
    case 'testnet':
      return `https://explorer.solana.com/tx/${trx}?cluster=testnet`;
    default:
      return `https://explorer.solana.com/tx/${trx}`;
  }
}

export function ethPolySigExplorer(trx, network) {
  switch (network) {
    case 'polygon-mainnet':
      return `https://polygonscan.com/tx/${trx}`;
    case 'mainnet-testnet':
      return `https://mumbai.polygonscan.com/tx/${trx}`;
    case 'mainnet':
      return `https://etherscan.io/tx/${trx}`;
    default:
      return `https://mumbai.polygonscan.com/tx/${trx}`;
  }
}

export function explorerSignatureLink(trx, network) {
  // const network = useWalletConnectedNetwork()
  const expl_link = Web3.utils.isHex(trx)
    ? ethPolySigExplorer(trx, network)
    : solanaSigExplorer(trx, network);

  return expl_link;
}
