import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchable_dropdown_selected_value: {
    artist: '',
    policy: '',
    ticketType: '',
    ticketDesign: '',
  },
  searchable_dropdown_search_key: '',
  message: ''
};

const slice = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    updateSearchableDropdownSelectedValue: (state, { payload }) => {
      state.searchable_dropdown_selected_value = payload;
    },
    updateSearchableDropdownSearchKey: (state, { payload }) => {
      state.searchable_dropdown_search_key = payload;
    },
    updateStatusMessage: (state, { payload }) => {
      state.message = payload
    }
  },
});

let utilsReducer = slice.reducer;

export const {
  updateSearchableDropdownSelectedValue,
  updateSearchableDropdownSearchKey,
  updateStatusMessage
} = slice.actions;
const getUtils = (state) => state.utils;

export { utilsReducer, getUtils };
