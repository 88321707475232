import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectCoverflow } from 'swiper';
import { LandingPageSliderSkeleton } from '../../util/Skeleton';
import { CalenderIcon2 } from '../../util/CustomIcons';
import {
  primaryBtnClassGradient,
  sliderButton,
} from '../../common/DefaultClasses';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import moment from 'moment';
import { DATEFORMAT, getDurtion, truncate } from '../../../utils/util';
import useAllEventList from '../../../hooks/event/useAllEventList';
import EventImageVideo from '../../common/EventImageVideo';

export default function ArtistBanner({ eventdetails, userdetails }) {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const { bannerEventdetails } = useAllEventList();
  // const prevto = () => {
  //   swiper.slidePrev();
  // };
  // const nexto = () => {
  //   swiper.slideNext();
  // };

  const filterData = () => {
    // let upcomingEvents = eventdetails?.data?.filter((data, index) => {
    //   if (
    //     Date.parse(moment(moment.utc(data?.start_date_time).local())) >
    //     Date.parse(moment())
    //   ) {
    //     return data;
    //   }
    // });
    return eventdetails?.data || bannerEventdetails?.data?.data;
  };
  return (
    <>
      <div className="relative banner_height banner_height_v2">
        <div className="container-fluid px-3.5 max-w-[1800px] relative">
          <div className="relative">
            <div className="relative flex justify-center">
              <>
                <Swiper
                  className="banner_swiper pagination-overlay h-full w-full relative max-w-[100%] flex justify-center pb-3"
                  hashNavigation={{
                    watchState: true,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  effect="coverflow"
                  // lazy={'true'}
                  // pagination={true}
                  spaceBetween={0}
                  loop={true}
                  slidesPerView={'auto'}
                  centeredSlides={true}
                  freeMode={true}
                  centeredSlidesBounds={true}
                  // touchStartPreventDefault={false}
                  // thumbs={{ swiper: thumbsSwiper }}
                  // slideToClickedSlide={true}
                  // allowTouchMove={true}
                  // swiperSlideSize={true}
                  // effect={'fade'}
                  navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  }}
                  onBeforeInit={(swiper) => {
                    navigationPrevRef.current = swiper.params.navigation.prevEl;
                    navigationNextRef.current = swiper.params.navigation.nextEl;
                  }}
                  // onSlideChange={(swiperCore, slideTo) => {
                  //   const { activeIndex, snapIndex, previousIndex, realIndex } =
                  //     swiperCore;

                  //     activeIndex,
                  //     snapIndex,
                  //     previousIndex,
                  //     realIndex,
                  //   });
                  // }}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  coverflowEffect={{
                    rotate: 0,
                    stretch: 54,
                    depth: 10,
                    modifier: 1,
                    slideShadows: false,
                  }}
                  modules={[Autoplay, Pagination, Navigation, EffectCoverflow]}
                >
                  <>
                    {!filterData() ? (
                      <>
                        <SwiperSlide className="relative h-full w-full lg:max-w-[98%]">
                          <LandingPageSliderSkeleton />
                        </SwiperSlide>
                      </>
                    ) : (
                      <>
                        {filterData()?.length > 0 ? (
                          filterData()?.map(
                            (data, index) =>
                              index <= 2 && (
                                <SwiperSlide
                                  key={index}
                                  className="relative h-full w-full lg:max-w-[98%]"
                                >
                                  <div className="relative h-full w-full flex justify-center z-0">
                                    <div className="relative h-full banner_height banner_height_v2 w-full lg:max-w-[95%] rounded-3xl bg-black">
                                      <div className="dark:bg-black bg-lightTheme-70 rounded-3xl absolute inset-0 z-0"></div>
                                      <div className="absolute inset-0 z-0 rounded-3xl overflow-hidden border-1 border-brown-800">
                                        <EventImageVideo
                                          data={data?.banner_image}
                                          alt="banner"
                                          className={
                                            'w-full h-full object-cover'
                                          }
                                          loading="lazy"
                                          // width="384px"
                                          // height="512px"
                                        />
                                        {/* {checkFileType(data?.banner_image) ===
                                        'video' ? (
                                          <Video
                                            className="w-full h-full object-cover"
                                            src={data?.banner_image?.url}
                                            removeControls={true}
                                          />
                                        ) : (
                                          <Image
                                            // loading="lazy"
                                            src={
                                              data?.banner_image?.url
                                                ? data?.banner_image?.url
                                                : profileUrl
                                            }
                                            alt="banner"
                                            className="w-full h-full object-cover"
                                          />
                                        )} */}
                                        <div className="bg-neutral-900 absolute inset-0 bg-opacity-50"></div>
                                      </div>
                                      <div className="container-fluid lg:max-w-[95%] xl:max-w-[97%] h-full relative pl-7 xl-pl-0">
                                        <div className="flex flex-col justify-between space-y-6 relative z-7 text-gray-50 h-full max-w-6xl 2xl:max-w-7xl pt-10 pb-24 lg:py-10">
                                          <div className="flex flex-col justify-start space-y-6">
                                            <div className="flex">
                                              <span className="bg-brown-800 rounded-full p-1.5 px-3 text-lg">
                                                Duration :{' '}
                                                {getDurtion(
                                                  moment
                                                    .utc(data?.start_date_time)
                                                    .local(),
                                                  moment
                                                    .utc(data?.end_date_time)
                                                    .local(),
                                                )}
                                              </span>
                                            </div>
                                            {/* <div className="flex gap-2 items-center">
                                              <span className="flex w-9 h-9 rounded-full overflow-hidden">
                                                <Image
                                                  src={
                                                    data?.creator?.image?.url
                                                  }
                                                  alt={'Event Organiser'}
                                                  className={
                                                    'w-full h-full object-cover'
                                                  }
                                                />
                                              </span>
                                              <span>
                                                {' '}
                                                {data?.creator?.username}
                                              </span>
                                            </div> */}
                                            <div className="flex items-center">
                                              <div className="flex items-center gap-2">
                                                <div className="flex gap-1.5">
                                                  <span className="text-xl flex mt-0.5">
                                                    <CalenderIcon2 />
                                                  </span>
                                                  <div className="flex flex-col">
                                                    <span>
                                                      Start Date & Time
                                                    </span>
                                                    <span className="font-medium">
                                                      {moment(
                                                        data?.start_date_time,
                                                      )?.format(DATEFORMAT)}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="flex">
                                                  <span className="flex h-[30px] w-[1px] bg-slate-400"></span>
                                                </div>
                                                <div className="flex gap-1.5">
                                                  <span className="text-xl flex mt-0.5">
                                                    <CalenderIcon2 />
                                                  </span>
                                                  <div className="flex flex-col">
                                                    <span>End Date & Time</span>
                                                    <span className="font-medium">
                                                      {moment(
                                                        data?.end_date_time,
                                                      )?.format(DATEFORMAT)}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-full max-w-6xl">
                                              <div
                                                className="w-full"
                                                style={{ cursor: 'pointer' }}
                                              >
                                                <h2 className="text-2xl md:text-5xl font-bold mt-[40px]">
                                                  {data?.name}
                                                </h2>
                                              </div>
                                              <h5 className="text-sm md:text-lg">
                                                {/* {truncate(data?.description, 100) || '-'} */}
                                                {/* Lorem Ipsum is simply dummy text
                                                of the printing */}
                                                {truncate(
                                                  data?.description,
                                                  100,
                                                ) || '-'}
                                              </h5>
                                            </div>
                                          </div>
                                          <div className="flex pb-8">
                                            <Link
                                              to={`/event-detail/${data?.slug}`}
                                              className={`${primaryBtnClassGradient} lg:min-w-[150px] text-lg`}
                                            >
                                              Buy Now
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              ),
                          )
                        ) : (
                          <>
                            <SwiperSlide className="relative h-full w-full lg:max-w-[98%]"></SwiperSlide>
                          </>
                        )}
                      </>
                    )}
                  </>
                </Swiper>
              </>
              <div className="md:flex justify-content-end align-items-center gap-2">
                <div className="absolute -left-3 lg:left-8 top-1/2 z-10 -translate-y-1/2">
                  <button
                    ref={navigationPrevRef}
                    // onClick={prevto}
                    className={`relative w-8 h-8 rounded-full dark:bg-landingPurple-60 disabled:bg-neutral-700 text-gray-50 disabled:opacity-30 ${sliderButton}`}
                  >
                    <MdKeyboardArrowLeft className="text-md md:text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                  </button>
                </div>
                <div className="absolute -right-3 lg:right-8 top-1/2 z-10 -translate-y-1/2">
                  <button
                    ref={navigationNextRef}
                    // onClick={nexto}
                    className={`relative w-8 h-8 rounded-full dark:bg-landingPurple-60 disabled:bg-neutral-700 text-gray-50 disabled:opacity-30 ${sliderButton}`}
                  >
                    <MdKeyboardArrowRight className="text-md md:text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
