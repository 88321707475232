import React, { useState } from 'react';
import TicketTypeCard from '../../../landing_page_v2/seat_booking_v2/TicketTypeCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Pagination } from 'swiper';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

export default function SeatBookingSelectTicketStrip({
  leftSidebarShow,
  setLeftSidebarShow,
  eventAllDetails,
  setRowSectionData,
  selectedCardId,
  handleSelectedID,
  handleSectionLoader,
}) {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const [swiper, setSwiper] = React.useState(null);
  const currentDate = new Date();

  const filterTicketsByDate = (ticket) => {
    const startDate = new Date(ticket.start_date);
    const endDate = new Date(ticket.end_date);
    return startDate <= currentDate && endDate >= currentDate;
  };

  const filteredTickets = eventAllDetails
    ? eventAllDetails
        .filter((item) => item?.event_ticket_category !== 'Virtual')
        .filter((item) => filterTicketsByDate(item))
    : [];

  // console.log('eventAllDetails', eventAllDetails);
  return (
    <>
      <div className="py-2.5 px-1.5 md:px-2.5 pb-1">
        <div className="container-fluid px-0">
          <div className="relative px-1.5 md:px-0">
            <Swiper
              className=""
              hashNavigation={{
                watchState: true,
              }}
              // allowTouchMove={true}
              // allowSlideNext={true}
              // allowSlidePrev={true}
              // lazy={true}
              slidesPerView={3}
              allowTouchMove={false}
              freeMode={true}
              effect="fade"
              spaceBetween={1}
              touchStartPreventDefault={false}
              //   pagination={pagination}
              // effect="fade"
              grabCursor={false}
              loop={false}
              // sticky={true}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onSwiper={(s) => {
                setSwiper(s);
              }}
              onBeforeInit={(swiper) => {
                navigationPrevRef.current = swiper.params.navigation.prevEl;
                navigationNextRef.current = swiper.params.navigation.nextEl;
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Pagination, Navigation, FreeMode]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                576: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                // 900: {
                //   slidesPerView: 3,
                //   spaceBetween: 20,
                // },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },

                1600: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                // 1920: {
                //   slidesPerView: 5,
                //   spaceBetween: 20,
                // },
                2500: {
                  slidesPerView: 6,
                  spaceBetween: 20,
                },
                // 3750: {
                //   slidesPerView: 7,
                //   spaceBetween: 20,
                // },
              }}
            >
              {filteredTickets?.map((item) => (
                <SwiperSlide key={item._id} className="relative">
                  <div className="relative h-full px-1.5 pb-1">
                    <TicketTypeCard
                      leftSidebarShow={leftSidebarShow}
                      setLeftSidebarShow={setLeftSidebarShow}
                      active
                      item={item}
                      selectedCardId={selectedCardId}
                      setRowSectionData={setRowSectionData}
                      handleSelectedID={handleSelectedID}
                      handleSectionLoader={handleSectionLoader}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="md:flex justify-content-end align-items-center gap-2">
              <div className="absolute -left-3 lg:-left-3 top-1/2 z-10 -translate-y-1/2">
                <button
                  ref={navigationPrevRef}
                  // onClick={prevto}
                  className="relative w-8 h-8 rounded-full dark:bg-landingPurple-60 bg-zinc-900 disabled:bg-neutral-700 text-gray-50 disabled:opacity-30"
                >
                  <MdKeyboardArrowLeft className="text-md md:text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                </button>
              </div>
              <div className="absolute -right-3 lg:-right-3 top-1/2 z-10 -translate-y-1/2">
                <button
                  ref={navigationNextRef}
                  // onClick={nexto}
                  className="relative w-8 h-8 rounded-full dark:bg-landingPurple-60 bg-zinc-900 disabled:bg-neutral-700 text-gray-50 disabled:opacity-30"
                >
                  <MdKeyboardArrowRight className="text-md md:text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
