import React, { useState, useEffect, useCallback } from 'react';
import { MdOutlineDarkMode, MdOutlineLightMode } from 'react-icons/md';
import useThemeStore from '../../../store/useThemeStore';
const ThemeToggle = () => {
  // Check if a dark mode preference is stored in localStorage
  // const storedDarkMode = localStorage.getItem('darkMode') === 'true';
  // const [darkMode, setDarkMode] = useState(storedDarkMode);
  // useEffect(() => {
  //   const body = document.querySelector('body');
  //   darkMode ? body.classList.add('dark') : body.classList.remove('dark');
  //   // Store the dark mode preference in localStorage
  //   localStorage.setItem('darkMode', darkMode);
  // }, [darkMode]);
  // const toggleDarkMode = () => {
  //   setDarkMode(!darkMode);
  // };

  // Check if a dark mode preference is stored in localStorage
  const [darkMode, setDarkMode] = useThemeStore();
  const toggleDarkMode = useCallback(
    () => setDarkMode(!darkMode),
    [darkMode, setDarkMode],
  );
  return (
    <button
      className=" dark:bg-component-gray-1 bg-lightTheme-70 hover:bg-lightTheme-80 dark:hover:bg-component-gray-2 dark:hover:bg-opacity-90 text-lg dark:border-brown-800 rounded-lg dark:text-white min-h-[33px] min-w-[33px] flex justify-center items-center"
      onClick={toggleDarkMode}
      // rotateBtn
    >
      {darkMode ? (
        <MdOutlineLightMode className="" />
      ) : (
        // rotateIcon
        <MdOutlineDarkMode className="" />
        // rotateIcon
      )}
    </button>
  );
};
export default ThemeToggle;
