import { useState } from 'react';

export function useDisplayImage() {
  const [venuePolygon, setVenuePolygon] = useState();
  const [venuePolygonIMG, setVenuePolygonIMG] = useState('');
  const [venueBG, setVenueBG] = useState();
  const [venueBGImg, setVenueBGImg] = useState('');
  const [result, setResult] = useState('');
  const [banner, setBanner] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [profileImg, setProfileImg] = useState('');
  const [thumbnailImg, setThumbnailImg] = useState('');
  const [bannerImg, setBannerImg] = useState('');
  const [imgError, setImgError] = useState({
    result: '',
    banner: '',
    thumbnail: '',
    profileImageFormat: '',
    thumbnailImageFormat: '',
    bannerImageFormat: '',
    tourImages: '',
    tourImageFormat: '',
  });

  //tours

  const [tourImages, setTourImages] = useState([]);
  const [tourIMGFiles, setTourIMGFiles] = useState([]);

  // const [videoResult, setVideoResult] = useState();
  // const [videoThum, setvideoThum] = useState('');

  function uploader(e, type = null) {
    e.preventDefault();
    const imgFile = e.target.files[0];
    const multipleImage = Object.values(e.target.files);
    if (multipleImage?.length > 1) {
      var images = [];
      var ImageResult = [];
      multipleImage?.forEach((data, i) => {
        const imgFile = multipleImage[i];
        const reader = new FileReader();
        reader.readAsDataURL(multipleImage[i]);
        reader.addEventListener('load', (e) => {
          e.preventDefault();
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            if (type === 'TourImg') {
              if (imgFile.size > 52428800) {
                setImgError({
                  ...imgError,
                  tourImages: '',
                  tourImageFormat:
                    'Tour images should not be greater than 50MB',
                });
                return;
              }
              images.push(imgFile);
              ImageResult.push(e.target.result);
              if (
                imgFile?.type.split('/')[1] !== 'jpeg' &&
                imgFile?.type.split('/')[1] !== 'jpg' &&
                imgFile?.type.split('/')[1] !== 'png'
              ) {
                setImgError({
                  ...imgError,
                  tourImages: '',
                  tourImageFormat:
                    'Only *png, *jpg and *jpeg image files are accepted',
                });
              } else {
                setImgError({
                  ...imgError,
                  tourImages: '',
                  tourImageFormat: '',
                });
              }
            }
            setTourIMGFiles([...tourIMGFiles, ...images]);
            setTourImages([...tourImages, ...ImageResult]);
          };
        });
      });
      images = [];
      ImageResult = [];
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(imgFile);
      reader.addEventListener('load', (e) => {
        e.preventDefault();
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          if (type === 'banner') {
            if (imgFile.size > 52428800) {
              setImgError({
                ...imgError,
                banner: '',
                result: '',
                bannerImageFormat:
                  'Banner image should not be greater than 50MB',
              });
              return;
            }
            setBannerImg(imgFile ? imgFile : '');
            setBanner(e.target.result);
            if (
              imgFile?.type.split('/')[1] !== 'jpeg' &&
              imgFile?.type.split('/')[1] !== 'jpg' &&
              imgFile?.type.split('/')[1] !== 'png'
            ) {
              setImgError({
                ...imgError,
                banner: '',
                result: '',
                bannerImageFormat:
                  'Only *png, *jpg and *jpeg image files are accepted',
              });
            } else {
              setImgError({
                ...imgError,
                banner: '',
                result: '',
                bannerImageFormat: '',
              });
            }
          } else if (type === 'thumbnail') {
            if (imgFile.size > 52428800) {
              setImgError({
                ...imgError,
                thumbnail: '',
                thumbnailImageFormat:
                  'Thumbnail image should not be greater than 50MB',
              });
              return;
            }
            setThumbnailImg(imgFile ? imgFile : '');
            setThumbnail(e.target.result);
            if (
              imgFile?.type.split('/')[1] !== 'jpeg' &&
              imgFile?.type.split('/')[1] !== 'jpg' &&
              imgFile?.type.split('/')[1] !== 'png'
            ) {
              setImgError({
                ...imgError,
                thumbnail: '',
                thumbnailImageFormat:
                  'Only *png, *jpg and *jpeg image files are accepted',
              });
            } else {
              setImgError({
                ...imgError,
                thumbnail: '',
                thumbnailImageFormat: '',
              });
            }
          } else if (type === 'TourImg') {
            // setVenueBGImg(imgFile ? imgFile : '');
            if (imgFile.size > 52428800) {
              setImgError({
                ...imgError,
                tourImages: '',
                tourImageFormat: 'Tour images should not be greater than 50MB',
              });
              return;
            }
            setTourIMGFiles([...tourIMGFiles, imgFile ? imgFile : '']);
            setTourImages([...tourImages, e.target.result]);
            if (
              imgFile?.type.split('/')[1] !== 'jpeg' &&
              imgFile?.type.split('/')[1] !== 'jpg' &&
              imgFile?.type.split('/')[1] !== 'png'
            ) {
              setImgError({
                ...imgError,
                tourImages: '',
                tourImageFormat:
                  'Only *png, *jpg and *jpeg image files are accepted',
              });
            } else {
              setImgError({
                ...imgError,
                tourImages: '',
                tourImageFormat: '',
              });
            }
          } else if (type === 'venueBG') {
            // setVenueBGImg(imgFile ? imgFile : '');
            setVenueBG(e.target.result);
            setVenueBGImg(imgFile);
          } else if (type === 'venuePolygon') {
            setVenuePolygon(e.target.result);
            // setVenuePolygonIMG(imgFile ? imgFile : '');
            setVenuePolygonIMG(imgFile);
          } else {
            if (imgFile.size > 52428800) {
              setImgError({
                ...imgError,
                result: '',
                profileImageFormat:
                  'Profile image should not be greater than 50MB',
              });
              return;
            }
            setProfileImg(imgFile ? imgFile : '');
            setResult(e.target.result);
            if (
              imgFile?.type.split('/')[1] !== 'jpeg' &&
              imgFile?.type.split('/')[1] !== 'jpg' &&
              imgFile?.type.split('/')[1] !== 'png'
            ) {
              setImgError({
                ...imgError,
                result: '',
                profileImageFormat:
                  'Only *png, *jpg and *jpeg image files are accepted',
              });
            } else {
              setImgError({
                ...imgError,
                result: '',
                profileImageFormat: '',
              });
            }
          }
        };
      });
    }
  }

  return {
    result,
    banner,
    thumbnail,
    venueBG,
    venuePolygon,
    venueBGImg,
    venuePolygonIMG,
    profileImg,
    thumbnailImg,
    bannerImg,
    imgError,
    uploader,
    setResult,
    setBanner,
    setThumbnail,
    setVenueBG,
    setVenuePolygon,
    setVenueBGImg,
    setVenuePolygonIMG,
    setImgError,
    setBannerImg,
    setProfileImg,
    tourImages,
    setTourImages,
    tourIMGFiles,
    setTourIMGFiles,
  };
}
