import React from 'react';
import { Helmet } from 'react-helmet';
import { portalTexts } from './LoaderMessage';

function HelmetComponent({ title, description, tags, image }) {
  return (
    <Helmet>
      <meta charset="UTF-8" />

      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={tags?.toString()} />
      <meta
        property="og:title"
        content={`${title} - ${portalTexts?.nTkt} TICKETING`}
        data-react-helmet="true"
      />
      <meta
        property="og:description"
        content={description}
        data-react-helmet="true"
      />
      <meta property="og:type" content={description} data-react-helmet="true" />
      <meta
        property="og:image"
        key="og:image"
        content={image || 'image'}
        data-react-helmet="true"
      />
    </Helmet>
  );
}
export default HelmetComponent;
