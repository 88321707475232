import React, { useEffect } from 'react';
import { MdArrowUpward } from 'react-icons/md';
// import { useLocation } from 'react-router-dom';
import Tooltip from '../../components/common/Tooltip';
import { useLocation } from 'react-router-dom';
// import { animateScroll } from 'react-scroll';
// import { useLocation } from 'react-router-dom';
export default function ScrollToTop() {
  const { pathname } = useLocation();
  // useLayoutEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);
  // $(document).ready(function () {
  //   $(window).scrollTop(0);
  // });
  // function hello() {
  //   $(window).scrollTop(0);
  // }
  // setTimeout(hello, 500);
  // const routePath = useLocation();
  // const routePath = window.location.pathname.split('/')?.[1];

  const onTop = () => {
    // window.scroll(0, 0);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // var access = document.getElementById('topShow');
    // access.scrollIntoView({ behavior: 'smooth' }, true);
  };
  useEffect(() => {
    setTimeout(() => onTop(), 500);
    // animateScroll.scrollToTop();
  }, [pathname]);

  // $(window).scroll(function () {
  //   if ($(window).scrollTop() >= 200) {
  //     $('.fixedBottomToTop').addClass('block');
  //     $('.fixedBottomToTop').removeClass('hidden');
  //   } else {
  //     $('.fixedBottomToTop').addClass('hidden');
  //     $('.fixedBottomToTop').removeClass('block');
  //   }
  // });
  return (
    <React.Fragment>
      <button
        className="bg-gray-600 text-whiteNew-0 rounded-full fixed right-6 bottom-6 text-xl p-1 fixedBottomToTop hidden z-50"
        onClick={() => {
          onTop();
        }}
      >
        <Tooltip title={'Scroll To Top'} className={'text-white'}>
          <MdArrowUpward />
        </Tooltip>
      </button>
    </React.Fragment>
  );
}
