import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
// import HeadingTitle from '../../../components/landing_page_v2/HeadingTitle';
import TopOrganiserSlider from '../../../components/landing_page_v2/TopOrganiserSlider';
import useDecodedToken from '../../../hooks/useDecodedToken';
import { useVenueType } from '../../../hooks/event/venues/venues';
import moment from 'moment';
import { useUserDetails } from '../../../hooks/user/useUserDetails';
import TrendingSlider from '../../../components/landing_page_v2/TrendingSlider';
import HelmetComponent from '../../../utils/HelmateComponent';
import { Link, useLocation } from 'react-router-dom';
import PageDarkLoader from '../../../components/util/PageDarkLoader';
import { useOnceCall } from '../../../hooks/useOnlyOnce';
import GoToTop from '../../../components/common/GoToTop';
import {
  useBannerEventHooks,
  useExploreEventHooks,
  useOngoingEventHooks,
  useTopArtistHooks,
  useTrendingEventHooks,
} from './hooks/useHomePageHooks';
import { useDispatch, useSelector } from 'react-redux';
import { EMAIL_REG, updateDataCountByViewport } from '../../../utils/util';
import { AddPage } from '../../../features/home_page/homepage.slice';
import BannerSliderV2 from '../../../components/landing_page_v2/BannerSliderV2';
import SliderFilterDropdown from '../../../components/landing_page_v2/SliderFilterDropdown';
// import { MdKeyboardArrowRight, MdOutlineArrowRightAlt } from 'react-icons/md';
import { BiFilterAlt } from 'react-icons/bi';
import { MdClose, MdKeyboardArrowRight } from 'react-icons/md';
import { Form, Input, Tooltip } from 'antd';
import {
  RiFacebookCircleLine,
  RiInstagramLine,
  RiLinkedinBoxLine,
  RiTwitterLine,
} from 'react-icons/ri';
// import BannerSlider from '../../../components/landing_page_v2/BannerSlider';
import { BsCalendar2Event } from 'react-icons/bs';
import {
  CustomArtistIcon,
  // CustomExploreIcon,
  CustomMailIcon,
  // CustomTeamIcon,
  FireIcon,
  SpinnerBtn,
} from '../../../components/util/CustomIcons';
// import { Dialog } from '@headlessui/react';
// import ConfirmationModalNewsLetter from './ConfirmationModalNewsLetter';

export const HomePageLanding = () => {
  let [isLoading, setIsLoading] = useState(false);
  const decodedToken = useDecodedToken();
  console.log('decodedToken: ', decodedToken);
  let dispatch = useDispatch();
  // let navigate = useNavigate();
  // used to get the url location.
  const location = useLocation();
  // notify if user is ot logged in.
  let notLoggedUser = JSON.parse(localStorage.getItem('notLoggedUser')) || {};
  const [viewMore, setViewMore] = React.useState(0);
  const [loadFirst, setLoadFirst] = React.useState(true);
  // const [open, setIsOpen] = React.useState(false);
  // display the loader if user not logged in.
  const [displayLoader, setdisplayLoader] = React.useState(true);
  if (Object.keys(notLoggedUser).length > 0) {
    window.location.href = notLoggedUser?.url;
  }
  //API used to get the event list and then the top 10 artist list to show on homepage.
  let page_limit = useSelector((state) => state?.homePageEvents);
  let PublishedEvents = useSelector((state) => state?.publishedEvents);
  const { ongoing, trending, explore, venueType, artist } = PublishedEvents;

  //Involking all the API from homePage starts here.

  const { trendingEventdetails } = useTrendingEventHooks(
    page_limit?.page,
    page_limit?.limit,
  );
  const { topartist } = useTopArtistHooks(page_limit?.page, page_limit?.limit);

  // console.log('topartist', topartist?.isLoading);
  // console.log('isValidating', topartist?.isValidating);

  const { bannerEventdetails } = useBannerEventHooks(
    page_limit?.page,
    page_limit?.limit,
  );
  const { ongoingEventdetails } = useOngoingEventHooks(
    page_limit?.page,
    page_limit?.limit,
  );
  const { exploreEventdetails } = useExploreEventHooks(
    page_limit?.page,
    page_limit?.limit,
  );

  const { isLoading: venueTypeLoading } = useVenueType();

  //Involking all the API for homePage ended.

  // useEffect is used to set the loader to run for 3seconds once mounted.
  useOnceCall(() => {
    const timeout = setTimeout(() => {
      setdisplayLoader(false);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  /// API used to get the logged in user details.
  const {
    userdetails: { data },
    isLoading: isLoadingUser,
    subscribeToNewsLetter,
    refetch,
  } = useUserDetails(decodedToken?.username);

  // once we received the data from user then we are storing it in the localstorage for further use.
  if (data) {
    localStorage.setItem(
      'UserDetails',
      JSON.stringify({
        email: data?.data?.email,
        userName: data?.data?.username,
        contact: data?.data?.contact,
      }),
    );
  }

  // filtering the trending events from the eventList.

  const filterTrending = useMemo(() => {
    const filteredData = trending?.data?.filter(
      (item) =>
        Date.parse(
          moment(moment.utc(item?.end_date_time).local(), 'YYYY-MM-DD hh:mm a'),
        ) > Date.parse(moment()),
    );
    return filteredData;
  }, [trending?.data]);

  // filtering the Exploring events from the eventList.
  const filterExplore = useMemo(() => {
    const filteredData = explore?.data?.filter(
      (item) =>
        Date.parse(
          moment(moment(item?.end_date_time).local(), 'YYYY-MM-DD hh:mm a'),
        ) > Date.parse(moment()),
    );
    return filteredData;
  }, [explore?.data]);

  console.log('loadFirst', loadFirst);
  useEffect(() => {
    setLoadFirst(false);
  }, []);

  const loading = useMemo(() => {
    if (
      (topartist.isLoading && loadFirst) ||
      (ongoingEventdetails?.isLoading && loadFirst) ||
      (trendingEventdetails?.isLoading && loadFirst) ||
      (exploreEventdetails?.isLoading && loadFirst) ||
      (bannerEventdetails?.isLoading && loadFirst) ||
      isLoadingUser ||
      venueTypeLoading ||
      displayLoader
    ) {
      return true;
    }
  }, [
    loadFirst,
    topartist,
    isLoadingUser,
    venueTypeLoading,
    displayLoader,
    exploreEventdetails?.isLoading,
    ongoingEventdetails?.isLoading,
    trendingEventdetails?.isLoading,
    bannerEventdetails?.isLoading,
  ]);

  // if (loading) {
  //   return <PageDarkLoader />;
  // }

  // const onLoadData = useCallback(() => {
  //   setViewMore(viewMore + updateDataCountByViewport());
  // }, [viewMore]);

  React.useEffect(() => {
    viewMore >= explore?.data?.length &&
      explore?.data?.length < explore?.totalCount &&
      dispatch(
        AddPage({
          ...page_limit,
          page: { ...page_limit?.page, explore: page_limit?.page?.explore + 1 },
          limit: { ...page_limit?.limit, explore: updateDataCountByViewport() },
        }),
      );
  }, [viewMore]);

  React.useEffect(() => {
    const updateCount = () => {
      setViewMore(updateDataCountByViewport());
    };
    // initial update
    updateCount();

    // update count when window is resized
    window.addEventListener('resize', updateCount);

    // cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateCount);
    };
  }, []);

  const [tabs, setTabs] = React.useState('All');

  // filtering the event list related to explore, upcoming, current and all eventlist.
  const filterTabs = useCallback(
    (data) => {
      var filtereddata = [];
      if (tabs === 'All') {
        ongoing?.data?.forEach((data, index) => {
          if (
            Date.parse(
              moment(
                moment.utc(data?.end_date_time).local(),
                'YYYY-MM-DD hh:mm a',
              ),
            ) > Date.parse(moment())
          ) {
            filtereddata.push(data);
          }
        });
      } else {
        ongoing?.data?.forEach((data, index) => {
          let cat = data?.event_category;

          if (
            cat !== undefined &&
            cat !== null &&
            tabs?.toLocaleLowerCase() === cat?.toLocaleLowerCase()
          ) {
            if (
              Date.parse(
                moment(
                  moment.utc(data?.end_date_time).local(),
                  'YYYY-MM-DD hh:mm a',
                ),
              ) > Date.parse(moment())
            ) {
              filtereddata.push(data);
            }
          }
        });
      }
      return filtereddata;
    },
    [tabs, ongoing],
  );

  const [isToggled, setToggle] = useState(false);

  const [formValues, setFormValues] = useState({ email: '' });
  const [form] = Form.useForm();
  const [newsletterMessage, setNewsletterMessage] = useState({
    status: true,
    message: '',
  });

  React.useEffect(() => {
    form.setFieldsValue(formValues);
  }, [form, formValues]);
  const toggle = async () => {
    if (!isToggled) {
      refetch();
    } else {
      setToggle((prevState) => !prevState);
      setFormValues({ email: '' });
      refetch();
    }
  };

  // React.useEffect(() => {
  //   // Clear data from Redux store when leaving the home page
  //   return () => {
  //     dispatch(resetEvents());
  //     dispatch(resetPageLimit());
  //   };
  // }, [dispatch]);

  /// Added temporarily need to replace it
  // useOnceCall(() => {
  //   window.scrollTo(0, 0);
  // });
  useOnceCall(() => {
    localStorage.removeItem('refreshFlag');
  }, []);

  console.log('bannerEventdetails', bannerEventdetails);
  if (loading && !location?.state?.redirected) return <PageDarkLoader />;

  return (
    <React.Fragment>
      <div className="bg-color-base-1">
        <HelmetComponent title={'NFTGOTIX | Home page'} />
        <section className="">
          <div className="relative z-0">
            <div className="w-full py-4 bg-color-base-2 dark:bg-color-base-3">
              {/* {data && PublishedEvents?.ongoing && (
                <BannerSliderV2
                  eventdetails={PublishedEvents?.ongoing}
                  userdetails={data}
                />
              )} */}
              <BannerSliderV2 isLoading={bannerEventdetails?.isValidating} />
              {/* <BannerSlider /> */}
            </div>
          </div>
        </section>
        <section className="bg-color-base-1">
          <div className="pb-0 flex justify-center ">
            <div className="w-full max-w-[1800px]">
              <div className="py-4">
                <div className="container-fluid px-1.5 lg:px-3.5 max-w-[1800px]">
                  <Row className="px-3">
                    <Col lg={12} xxl={12}>
                      {/* <div className="flex flex-row w-full bg-color-base-2 rounded-lg border-zinc-200 dark:border-zinc-700 border-[1px] overflow-hidden">
                        <div className="flex flex-col w-[25%] p-3 rounded-s-lg relative after:w-8 after:h-full after:absolute after:right-[-10px] after:top-0 after:bg-gradient-to-r from-[#ddd] dark:from-[#111] to-transparent dark:to-transparent">
                          <div className="flex justify-between items-start pb-3 mx-3.5 w-full flex-col">
                            <h2 className="text-3xl dark:text-gray-50 text-zinc-900 flex flex-col gap-2 items-start w-full font-semibold mt-24">
                              <BsCalendar2Event className="text-zinc-300 dark:text-zinc-600 text-[45px]" />
                              <span className="mt-3">Ongoing Events</span>
                            </h2>
                            <h3 className="text-base mt-2.5 mb-2.5 dark:text-zinc-400">
                              A Celebration of Art and Community
                            </h3>
                            <span className="h-[2px] w-14 bg-zinc-800"></span>
                          </div>
                          <div className="mx-3.5 flex flex-row">
                            <div className="flex items-center gap-2 shrink-0">
                              <div className="">
                                <SliderFilterDropdown
                                  options={venueType?.data}
                                  setTabs={setTabs}
                                  tabs={tabs}
                                  noDefault={false}
                                  extra_props={{
                                    icon: (
                                      <>
                                        {tabs !== 'All' && (
                                          <span className="flex w-3 h-3 bg-green-600 rounded-full -top-1 -right-1 absolute"></span>
                                        )}
                                        <BiFilterAlt className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm" />
                                      </>
                                    ),
                                    all_mode: true,
                                    classname: `dark:text-whiteNew-0 text-zinc-800 dark:bg-brown-800 bg-whiteNew-0 backdrop-blur-lg dark:hover:bg-brown-700 hover:bg-brown-600 hover:bg-opacity-90 rounded-lg flex gap-1 items-center justify-center w-[38px] h-[38px] text-base leading-tight duration-200 ease-in max-w-[40px] min-w-[38px] relative border-[1px] border-zinc-200 dark:border-zinc-700 shadow-sm ${
                                      tabs !== 'All'
                                        ? 'border-green-700 border-1'
                                        : ''
                                    }`,
                                  }}
                                />
                              </div>
                              <div>
                                <Link
                                  to="/events"
                                  // className="hover:text-landingPurple-60 text-base font-semibold text-whiteNew-0 underline p-2.5 py-[7px] flex justify-between items-center gap-0.5 rounded-md bg-opacity-90 duration-200 ease-in"
                                  className={
                                    'p-2.5 px-[15px] dark:text-whiteNew-0 text-zinc-800 dark:bg-brown-800 bg-whiteNew-0 backdrop-blur-lg dark:hover:bg-brown-700 hover:bg-opacity-90 rounded-lg flex gap-1 items-center justify-center lg:min-w-[100px] text-sm md:text-base leading-tight duration-200 ease-in hover:bg-brown-600 shadow-sm border-zinc-200 dark:border-zinc-700 border-[1px]'
                                  }
                                >
                                  <span>All Events</span>
                                  <MdKeyboardArrowRight />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col w-[75%] relative after:w-6 after:h-full after:absolute after:right-0 after:top-0 after:bg-gradient-to-r from-transparent dark:from-transparent to-[#ddd] dark:to-[#111] pe-6">
                          <div className="mt-2 w-full flex flex-col">
                            <TrendingSlider
                              // eventdetails={eventdetails}
                              data={filterTabs(tabs)}
                              isLoading={ongoingEventdetails.isValidating}
                              from="ongoing"
                              tabs={tabs}
                            />
                          </div>
                        </div>
                      </div> */}
                      <div className="flex flex-col rounded-xl px-0 border-[1px] border-level-4 overflow-hidden shadow-md shadow-level-4">
                        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center border-b-[1px] border-level-4 bg-color-base-2 dark:bg-color-base-3 py-2.5 lg:px-4 px-4 gap-1.5 lg:gap-0">
                          <div className="flex flex-row gap-3.5 items-center">
                            <div>
                              <BsCalendar2Event className="text-zinc-400 dark:text-zinc-500 text-3xl" />
                            </div>
                            <div className="flex flex-col">
                              <h2 className="text-lg font-normal dark:text-gray-50 text-zinc-900 flex flex-row items-center gap-2.5">
                                {/* <BsCalendar2Event className="text-zinc-900 dark:text-zinc-100 text-2xl" /> */}
                                <span className="font-semibold">
                                  Ongoing Events
                                </span>
                              </h2>
                              {/* <span className="w-10 h-[2px] bg-zinc-500 my-1.5"></span> */}
                              <span className="dark:text-zinc-200 text-[11px] mt-[2px]">
                                A Celebration of Art and Community
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center gap-2 shrink-0">
                            {/* <div>
                              <Link
                                to="/events"
                                className="hover:text-landingPurple-60 text-lg font-semibold text-whiteNew-0 underline p-2.5 py-[7px] flex justify-between items-center gap-0.5 rounded-md bg-opacity-90 duration-200 ease-in"
                              >
                                <span>
                                  All{' '}
                                  <span className="hidden md:inline-block">
                                    Events
                                  </span>
                                </span>{' '}
                                <MdKeyboardArrowRight />
                              </Link>
                            </div> */}

                            <div>
                              <Link
                                to="/events"
                                // className="hover:text-landingPurple-60 text-base font-semibold text-whiteNew-0 underline p-2.5 py-[7px] flex justify-between items-center gap-0.5 rounded-md bg-opacity-90 duration-200 ease-in"
                                className={
                                  'p-2.5 px-[15px] dark:text-whiteNew-0 text-zinc-800 dark:bg-brown-800 bg-whiteNew-0 shadow-sm backdrop-blur-lg dark:hover:bg-brown-700 hover:bg-opacity-90 rounded-lg flex gap-1 items-center justify-center lg:min-w-[100px] text-sm md:text-base leading-tight duration-100 hover:bg-brown-600 hover:bg-gradient-to-r hover:dark:from-[#454c9a] hover:from-sky-600 hover:dark:to-violet-600 hover:to-sky-600 ease-in hover:text-whiteNew-0'
                                }
                              >
                                <span>All Events</span>
                                <MdKeyboardArrowRight />
                              </Link>
                            </div>
                            <div className="">
                              <SliderFilterDropdown
                                options={venueType?.data}
                                setTabs={setTabs}
                                tabs={tabs}
                                noDefault={false}
                                extra_props={{
                                  icon: (
                                    <>
                                      {tabs !== 'All' && (
                                        <span className="flex w-3 h-3 bg-green-600 rounded-full -top-1 -right-1 absolute"></span>
                                      )}
                                      <BiFilterAlt className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                                    </>
                                  ),
                                  all_mode: true,
                                  classname: `dark:text-whiteNew-0 text-zinc-800 dark:bg-brown-800 bg-whiteNew-0 backdrop-blur-lg dark:hover:bg-brown-700 hover:bg-brown-600 hover:bg-opacity-90 rounded-lg flex gap-1 items-center justify-center w-[40px] h-[40px] text-base leading-tight duration-200 ease-in max-w-[40px] min-w-[40px] relative shadow-sm hover:bg-brown-600 hover:bg-gradient-to-r hover:dark:from-[#454c9a] hover:from-sky-600 hover:dark:to-violet-600 hover:to-sky-600 ease-in hover:text-whiteNew-0 ${
                                    tabs !== 'All'
                                      ? 'border-green-700 border-1'
                                      : ''
                                  }`,
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="py-3.5 px-3.5 bg-color-base-3 dark:bg-color-base-2">
                          <TrendingSlider
                            // eventdetails={eventdetails}
                            data={filterTabs(tabs)}
                            isLoading={ongoingEventdetails.isValidating}
                            from="ongoing"
                            tabs={tabs}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="py-3">
                <div className="container-fluid px-1.5 lg:px-3.5 max-w-[1800px]">
                  <Row className="px-3">
                    <Col lg={12} xxl={12}>
                      <div className="flex flex-col lg:flex-row w-full bg-color-base-2 dark:bg-color-base-3 rounded-lg border-level-4 border-[1px] shadow-md shadow-level-4 overflow-hidden">
                        <div className="flex flex-col w-[100%] lg:w-[25%] p-1.5 lg:p-3.5 rounded-s-lg relative to-transparent dark:to-transparent justify-center">
                          <div className="flex flex-col">
                            <div className="flex justify-between items-start pb-3 mx-3.5 w-full flex-col">
                              <h2 className="dark:text-gray-50 text-zinc-900 flex flex-col gap-2 items-start w-full font-semibold">
                                <FireIcon className="text-zinc-400 dark:text-zinc-500 text-3xl" />
                                <span className="mt-1 lg:mt-3 text-lg">
                                  Trending Events
                                </span>
                              </h2>
                              <h3 className="text-[12px] mt-1 mb-2.5 dark:text-zinc-200">
                                Stay in the Know with What's Hot!
                              </h3>
                              <span className="h-[2px] w-14 bg-zinc-300 dark:bg-zinc-200"></span>
                            </div>
                            <div className="mx-3.5 flex flex-row">
                              <div className="flex items-center gap-2 shrink-0">
                                <div>
                                  <Link
                                    to="/events"
                                    // className="hover:text-landingPurple-60 text-base font-semibold text-whiteNew-0 underline p-2.5 py-[7px] flex justify-between items-center gap-0.5 rounded-md bg-opacity-90 duration-200 ease-in"
                                    className={
                                      'p-2.5 px-[15px] dark:text-whiteNew-0 text-zinc-800 dark:bg-brown-800 backdrop-blur-lg dark:hover:bg-brown-700 hover:bg-opacity-90 rounded-lg flex gap-1 items-center justify-center lg:min-w-[100px] text-sm md:text-base leading-tight duration-100 hover:bg-brown-600 shadow-sm border-zinc-200 dark:border-zinc-700 border-[1px] hover:bg-gradient-to-r hover:dark:from-[#454c9a] hover:from-sky-600 hover:dark:to-violet-600 hover:to-sky-600 ease-in hover:text-whiteNew-0'
                                    }
                                  >
                                    <span>All Events</span>
                                    <MdKeyboardArrowRight />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          // className="flex flex-col w-[100%] lg:w-[75%] relative after:w-6 after:h-full after:absolute after:right-0 after:top-0 after:bg-gradient-to-r from-transparent dark:from-transparent to-transparent lg:to-[#ddd] dark:to-[#111] pe-[0px] lg:pe-6"
                          className="flex flex-col w-[100%] lg:w-[75%] relative after:w-6 after:h-full after:absolute after:left-[0px] after:top-0 after:bg-gradient-to-r after:from-transparent lg:after:from-[#ddd] dark:after:from-[#111] after:to-transparent dark:after:to-transparent before:w-8 before:h-full before:absolute before:right-[0px] before:top-0 before:bg-gradient-to-r before:from-transparent dark:before:from-transparent before:to-transparent lg:before:to-[#ddd] dark:before:to-[#111] overflow-visible lg:overflow-hidden order-[2] lg:order-[1] pr-[0px] lg:pr-3.5 bg-color-base-3 dark:bg-color-base-2"
                        >
                          <div className="py-2.5 w-full flex flex-col px-3.5 lg:pr-0 pl-3.5">
                            <TrendingSlider
                              eventdetails={trending?.data}
                              isLoading={trendingEventdetails.isValidating}
                              data={filterTrending}
                              from="trending"
                              small={true}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="">
                        <div className="flex justify-between items-center mx-3.5 border-b-[1px] pb-3 dark:border-zinc-800 border-zinc-200">
                          <HeadingTitle>
                            <h2 className="text-2xl font-normal dark:text-gray-50 text-zinc-900">
                              Trending Events
                            </h2>
                          </HeadingTitle>
                          <div className="flex items-center gap-2 shrink-0">
                            <div>
                              <Link
                                to="/events"
                                // className="hover:text-landingPurple-60 text-base font-semibold text-whiteNew-0 underline p-2.5 py-[7px] flex justify-between items-center gap-0.5 rounded-md bg-opacity-90 duration-200 ease-in"
                                className={
                                  'p-2.5 px-[15px] dark:text-whiteNew-0 text-zinc-800 dark:bg-brown-800 bg-lightTheme-80 backdrop-blur-lg dark:hover:bg-brown-700 hover:bg-brown-600 hover:bg-opacity-90 rounded-lg flex gap-1 items-center justify-center lg:min-w-[100px] text-sm md:text-base leading-tight duration-200 ease-in'
                                }
                              >
                                <span>All Events</span>
                                <MdKeyboardArrowRight />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2">
                          <TrendingSlider
                            eventdetails={trending?.data}
                            isLoading={trendingEventdetails.isValidating}
                            data={filterTrending}
                            from="trending"
                          />
                        </div>
                      </div> */}
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="py-3">
                <div className="container-fluid px-1.5 lg:px-3.5 max-w-[1800px]">
                  <Row className="px-3">
                    <Col lg={12} xxl={12}>
                      <div className="flex flex-col lg:flex-row w-full bg-color-base-2 dark:bg-color-base-3 rounded-lg border-level-4 border-[1px] overflow-hidden  shadow-md shadow-level-4">
                        <div className="flex flex-col w-[100%] lg:w-[76%] relative after:w-6 after:h-full after:absolute after:left-[0px] after:top-0 after:bg-gradient-to-r after:from-transparent lg:after:from-[#ddd] dark:after:from-[#111] after:to-transparent dark:after:to-transparent  before:w-8 before:h-full before:absolute before:right-[0px] before:top-0 before:bg-gradient-to-r before:from-transparent dark:before:from-transparent before:to-transparent lg:before:to-[#ddd] dark:before:to-[#111] pl-[0px] lg:pl-3.5 overflow-visible lg:overflow-hidden order-[2] lg:order-[1] bg-color-base-3 dark:bg-color-base-2">
                          <div className="py-2.5 w-full flex flex-col px-3.5 lg:pl-0 pr-3.5">
                            <TopOrganiserSlider
                              data={artist?.data}
                              isLoading={topartist?.isValidating}
                              // eventdetails={eventdetails}
                              type="artist"
                              refetch={topartist.mutate}
                              small={true}
                              refetchUserDetails={refetch}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col w-[100%] lg:w-[25%] p-1.5 lg:p-3.5 rounded-s-lg relative order-1 lg:order-2 justify-center">
                          <div className="flex flex-col">
                            <div className="flex justify-between items-start pb-3 mx-3.5 w-full flex-col">
                              <h2 className="dark:text-gray-50 text-zinc-900 flex flex-col gap-2 items-start w-full font-semibold">
                                {/* <CustomTeamIcon className="text-zinc-900 dark:text-zinc-600 text-4xl" /> */}
                                <CustomArtistIcon className="text-zinc-400 dark:text-zinc-500 text-4xl" />
                                <span className="mt-[1px] text-lg">
                                  Trending Artists/ Teams
                                </span>
                              </h2>
                              <h3 className="text-[12px] mt-2.5 mb-2.5 dark:text-zinc-200">
                                Discover the Artists and Teams Making Waves!
                              </h3>
                              <span className="h-[2px] w-14 bg-zinc-300 dark:bg-zinc-100"></span>
                            </div>
                            <div className="mx-3.5 flex flex-row">
                              <div className="flex items-center gap-2 shrink-0">
                                <div>
                                  <Link
                                    to="/artists"
                                    // className="hover:text-landingPurple-60 text-base font-semibold text-whiteNew-0 underline p-2.5 py-[7px] flex justify-between items-center gap-0.5 rounded-md bg-opacity-90 duration-200 ease-in"
                                    className={
                                      'p-2.5 px-[15px] dark:text-whiteNew-0 text-zinc-800 dark:bg-brown-800 bg-whiteNew-0 backdrop-blur-lg dark:hover:bg-brown-700 hover:bg-opacity-90 rounded-lg flex gap-1 items-center justify-center lg:min-w-[100px] text-sm md:text-base leading-tight duration-200 ease-in hover:bg-brown-600 shadow-sm border-zinc-200 dark:border-zinc-700 border-[1px] hover:bg-gradient-to-r hover:dark:from-[#454c9a] hover:from-sky-600 hover:dark:to-violet-600 hover:to-sky-600 hover:text-whiteNew-0'
                                    }
                                  >
                                    <span>All Artists/Teams</span>
                                    <MdKeyboardArrowRight />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="">
                        <div className="flex justify-between items-center mx-3.5 border-b-[1px] pb-3 dark:border-zinc-800 border-zinc-200">
                          <HeadingTitle>
                            <h2 className="text-2xl font-normal dark:text-gray-50 text-zinc-900">
                              Trending Artists/ Teams
                            </h2>
                          </HeadingTitle>
                          <div className="flex items-center gap-2 shrink-0">
                            <div>
                              <Link
                                to="/artists"
                                className={
                                  'p-2.5 px-[15px] dark:text-whiteNew-0 dark:bg-brown-800 bg-lightTheme-80 backdrop-blur-lg dark:hover:bg-brown-700 hover:bg-brown-600 hover:bg-opacity-90 rounded-lg flex gap-1 items-center justify-center lg:min-w-[100px] text-sm md:text-base leading-tight duration-200 ease-in'
                                }
                              >
                                <span>All Artists/Teams</span>
                                <MdKeyboardArrowRight />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2">
                          <TopOrganiserSlider
                            data={artist?.data}
                            isLoading={topartist?.isLoading}
                            // eventdetails={eventdetails}
                            type="artist"
                            refetch={topartist.mutate}
                          />
                        </div>
                      </div> */}
                    </Col>
                  </Row>
                </div>
              </div>
              {/* ) : null} */}

              <div className="py-3">
                <div className="container-fluid px-1.5 lg:px-3.5 max-w-[1800px]">
                  <Row className="px-3">
                    <Col lg={12} xxl={12}>
                      {/* <div className="flex flex-row w-full bg-color-base-2 rounded-lg border-zinc-200 dark:border-zinc-700 border-[1px]">
                        <div className="flex flex-col w-[25%] p-3 rounded-s-lg relative after:w-8 after:h-full after:absolute after:right-[-10px] after:top-0 after:bg-gradient-to-r from-[#ddd] dark:from-[#111] to-transparent dark:to-transparent">
                          <div className="flex justify-between items-start pb-3 mx-3.5 w-full flex-col">
                            <h2 className="text-3xl dark:text-gray-50 text-zinc-900 flex flex-col gap-2 items-start w-full font-semibold mt-24">
                              <CustomExploreIcon className="text-zinc-300 dark:text-zinc-600 text-[45px]" />
                              <span className="mt-3">Explore Events</span>
                            </h2>
                            <h3 className="text-base mt-2.5 mb-2.5 dark:text-zinc-400">
                              Find Your Next Adventure!
                            </h3>
                            <span className="h-[2px] w-14 bg-zinc-800"></span>
                          </div>
                          <div className="mx-3.5 flex flex-row">
                            <div className="flex items-center gap-2 shrink-0">
                              <div>
                                <Link
                                  to="/events"
                                  // className="hover:text-landingPurple-60 text-base font-semibold text-whiteNew-0 underline p-2.5 py-[7px] flex justify-between items-center gap-0.5 rounded-md bg-opacity-90 duration-200 ease-in"
                                  className={
                                    'p-2.5 px-[15px] dark:text-whiteNew-0 text-zinc-800 dark:bg-brown-800 bg-whiteNew-0 backdrop-blur-lg dark:hover:bg-brown-700 hover:bg-opacity-90 rounded-lg flex gap-1 items-center justify-center lg:min-w-[100px] text-sm md:text-base leading-tight duration-200 ease-in hover:bg-brown-600 shadow-sm border-zinc-200 dark:border-zinc-700 border-[1px]'
                                  }
                                >
                                  <span>All Events</span>
                                  <MdKeyboardArrowRight />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col w-[75%] relative after:w-6 after:h-full after:absolute after:right-0 after:top-0 after:bg-gradient-to-r from-transparent dark:from-transparent to-[#ddd] dark:to-[#111] pe-6">
                          <div className="mt-2 w-full flex flex-col">
                            <TrendingSlider
                              eventdetails={explore?.data}
                              isLoading={exploreEventdetails?.isValidating}
                              data={filterExplore}
                              from="explore"
                            />
                          </div>
                        </div>
                      </div> */}
                      <div className="flex flex-col rounded-xl px-0 border-[1px] border-level-4 overflow-hidden shadow-md shadow-level-4">
                        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center border-b-[1px] border-level-4 py-2.5 px-4 lg:px-4 bg-color-base-2 dark:bg-color-base-3 gap-1.5 lg:gap-0">
                          <div className="flex flex-row gap-3.5 items-center">
                            <div>
                              <BsCalendar2Event className="text-zinc-400 dark:text-zinc-500 text-3xl" />
                            </div>
                            <div className="flex flex-col">
                              <h2 className="text-lg font-normal dark:text-gray-50 text-zinc-900 flex flex-row gap-2">
                                {/* <BsCalendar2Event className="text-zinc-900 dark:text-zinc-100 text-2xl" /> */}
                                <span className="font-medium">
                                  Explore Events
                                </span>
                              </h2>
                              {/* <span className="w-10 h-[2px] bg-zinc-500 my-1.5"></span> */}
                              <span className="dark:text-zinc-400 text-[11px] mt-[1px]">
                                Find Your Next Adventure!
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center gap-2 shrink-0">
                            <div>
                              <Link
                                to="/events"
                                className={
                                  'p-2.5 px-[15px] dark:text-whiteNew-0 text-zinc-800 dark:bg-brown-800 bg-whiteNew-0 backdrop-blur-lg dark:hover:bg-brown-700 hover:bg-brown-600 hover:bg-opacity-90 rounded-lg flex gap-1 items-center justify-center lg:min-w-[100px] text-sm md:text-base leading-tight duration-100 ease-in shadow-sm hover:bg-brown-600 hover:bg-gradient-to-r hover:dark:from-[#454c9a] hover:from-sky-600 hover:dark:to-violet-600 hover:to-sky-600 hover:text-whiteNew-0'
                                }
                              >
                                <span>All Events</span>
                                <MdKeyboardArrowRight />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="py-3.5 px-3.5 bg-color-base-3 dark:bg-color-base-2">
                          <TrendingSlider
                            eventdetails={explore?.data}
                            isLoading={exploreEventdetails?.isValidating}
                            data={filterExplore}
                            from="explore"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="py-3 mb-2">
                <div className="container-fluid px-1.5 lg:px-3.5 max-w-[1800px]">
                  <Row className="px-3">
                    <Col lg={12} xxl={12}>
                      <div className="bg-color-base-2 p-4 rounded-lg flex-col lg:flex-row flex gap-3.5 px-2.5">
                        <div className="w-[100%] lg:w-[40%] flex flex-col">
                          {/* <MdOutlineEmail className="text-4xl mb-2" /> */}
                          <CustomMailIcon className="text-4xl mb-2 text-zinc-400 dark:text-zinc-500" />
                          <h2 className="text-[12px] lg:text-sm text-zinc-800 dark:text-zinc-200">
                            Want to hear about the best things to do in your
                            city?
                          </h2>
                          <h1 className="text-lg font-semibold mb-2 mt-3">
                            Subscribe to our newsletter!
                          </h1>
                          <span className="w-16 h-[2px] bg-zinc-300 dark:bg-zinc-400"></span>
                          <p className="text-[12px] lg:text-sm mt-2 text-zinc-800 dark:text-zinc-200">
                            Join our mailing list and stay up to date with the
                            latest events around you
                          </p>
                        </div>
                        <div className="w-[100%] lg:w-[60%] flex flex-col">
                          {!isToggled ? (
                            <div
                              className={`flex ${
                                decodedToken?.id
                                  ? 'flex-row gap-2.5'
                                  : 'flex-col'
                              } items-start justify-start`}
                            >
                              {decodedToken?.id ? (
                                <button
                                  type="button"
                                  className="bg-gradient-to-r from-color-primaryColor1 to-color-primaryColor2 text-whiteNew-0  rounded-md text-sm flex flex-row gap-1.5 items-center justify-center p-2 h-[42px]"
                                  disabled={
                                    data?.subscribeNewsletterData?.filter(
                                      (data) => data?.type === 'occasional',
                                    )?.[0]?.status
                                  }
                                  onClick={async () => {
                                    setIsLoading(true);
                                    let res = await subscribeToNewsLetter(
                                      decodedToken.email,
                                      'occasional',
                                    );
                                    if (res?.status) {
                                      setToggle((prevState) => !prevState);
                                      setIsLoading(false);

                                      setNewsletterMessage({
                                        status: res?.status,
                                        message:
                                          "Thank you for subscribing to our newsletter! We're thrilled to have you on board and look forward to sharing exciting updates.",
                                      });
                                    } else {
                                      setToggle((prevState) => !prevState);
                                      setIsLoading(false);
                                      setNewsletterMessage({
                                        status: res?.status,
                                        message:
                                          'The newsletter is already subscribed with this email id.',
                                      });
                                    }
                                  }}
                                >
                                  <span className="ps-1">
                                    {data?.subscribeNewsletterData?.filter(
                                      (data) => data?.type === 'occasional',
                                    )?.[0]?.status
                                      ? 'Subscribed'
                                      : 'Subscribe'}
                                  </span>
                                  {isLoading ? (
                                    <SpinnerBtn
                                      className={'text-sm'}
                                    ></SpinnerBtn>
                                  ) : (
                                    <MdKeyboardArrowRight className="text-base" />
                                  )}
                                </button>
                              ) : (
                                <Form
                                  form={form}
                                  layout="vertical"
                                  name="form_in_dashboard"
                                  initialValues={formValues}
                                  onFinish={async () => {
                                    if (!isToggled) {
                                      setIsLoading(true);
                                      let res = await subscribeToNewsLetter(
                                        formValues.email,
                                        'occasional',
                                      );
                                      if (res?.status) {
                                        setIsLoading(false);
                                        setToggle((prevState) => !prevState);
                                        setFormValues({ email: '' });
                                        setNewsletterMessage({
                                          status: res?.status,
                                          message:
                                            "Thank you for subscribing to our newsletter! We're thrilled to have you on board and look forward to sharing exciting updates.",
                                        });
                                      } else {
                                        setIsLoading(false);
                                        setNewsletterMessage({
                                          status: res?.status,
                                          message:
                                            'The newsletter is already subscribed with this email id.',
                                        });
                                        setToggle((prevState) => !prevState);
                                      }
                                    } else {
                                      setToggle((prevState) => !prevState);
                                      setFormValues({ email: '' });
                                    }
                                    form.resetFields();
                                  }}
                                  className="flex flex-row gap-2 justify-start items-start mb-2.5"
                                >
                                  <Form.Item
                                    name="email"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Email is required!',
                                      },
                                      {
                                        pattern: EMAIL_REG,
                                        message: 'Invalid email',
                                      },
                                    ]}
                                    className="mb-[0px]"
                                  >
                                    <Input
                                      className="w-full lg:w-[400px] dark:bg-zinc-800 dark:border-zinc-700 dark:text-zinc-200 dark:placeholder:text-zinc-500 placeholder:font-medium h-[42px] mb-[0px]"
                                      placeholder="Enter your Email"
                                      onChange={(e) => {
                                        console.log('e: ', e);
                                        let obj = {
                                          ...formValues,
                                          email: e.target.value,
                                        };
                                        form.setFieldValue(
                                          'email',
                                          e.target.value,
                                        );
                                        setFormValues(obj);
                                      }}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    style={{ marginBottom: 0 }}
                                    className="bg-gradient-to-r from-color-primaryColor1 to-color-primaryColor2 text-whiteNew-0  rounded-md text-sm flex flex-row gap-1.5 items-center justify-center"
                                  >
                                    <button
                                      type="submit"
                                      className="flex flex-row p-2 gap-2 items-center h-[40px]"
                                    >
                                      <span className="ps-1">Subscribe</span>
                                      {isLoading ? (
                                        <SpinnerBtn
                                          className={'text-sm'}
                                        ></SpinnerBtn>
                                      ) : (
                                        <MdKeyboardArrowRight className="text-base" />
                                      )}
                                    </button>
                                  </Form.Item>
                                </Form>
                              )}

                              <p className="mb-2.5 text-[12px] lg:text-sm text-zinc-800 dark:text-zinc-200 text-left">
                                By clicking subscribe you'll receive occasional
                                emails from us. You always have the choice to
                                unsubscribe within every email you receive.
                              </p>
                            </div>
                          ) : (
                            <div
                              className={`${
                                newsletterMessage?.status
                                  ? 'bg-green-200 dark:bg-green-600'
                                  : 'bg-red-200 dark:bg-red-900'
                              } p-3.5 rounded-md flex flex-row justify-between items-center gap-2 mb-2 text-left`}
                            >
                              <span>
                                {/* Thank you for subscribing to our newsletter!
                                We're thrilled to have you on board and look
                                forward to sharing exciting updates. */}
                                {newsletterMessage?.message}
                              </span>
                              <button
                                className={`${
                                  newsletterMessage?.status
                                    ? 'bg-green-500 text-whiteNew-0'
                                    : 'bg-rose-700 text-whiteNew-0'
                                } p-2 rounded-full h-8 w-8 shrink-0 flex items-center justify-center`}
                                onClick={() => toggle()}
                              >
                                <MdClose />
                              </button>
                            </div>
                          )}
                          <span className="flex h-[1px] w-[100%] bg-zinc-200 dark:bg-zinc-600 mb-2"></span>

                          <div>
                            {/* <span className="bg-zinc-200 dark:bg-zinc-800 h-[1px] w-full flex mb-3"></span> */}
                            <div className="flex flex-row justify-start items-start">
                              <span className="flex flex-row gap-2.5 items-center w-full">
                                <span className="text-[12px] lg:text-sm font-normal text-zinc-800 dark:text-zinc-200">
                                  For latest updates follow us on :
                                </span>
                                <Tooltip title="Facebook" color="">
                                  <button className="flex flex-row border-[1px] border-zinc-200 dark:border-zinc-600 rounded-lg p-1.5 items-center gap-2 text-sm bg-whiteNew-0 dark:bg-zinc-700 shadow-md">
                                    <RiFacebookCircleLine className="text-lg text-sky-600 dark:text-whiteNew-0" />
                                    {/* <span>Facebook</span> */}
                                  </button>
                                </Tooltip>
                                <Tooltip title="Instagram" color="">
                                  <button className="flex flex-row border-[1px] border-zinc-200 dark:border-zinc-600 rounded-lg p-1.5 items-center gap-2 text-sm bg-whiteNew-0 dark:bg-zinc-700 shadow-md">
                                    <RiInstagramLine className="text-lg text-sky-600 dark:text-whiteNew-0" />
                                    {/* <span>Instagram</span> */}
                                  </button>
                                </Tooltip>
                                <Tooltip title="Twitter" color="">
                                  <button className="flex flex-row border-[1px] border-zinc-200 dark:border-zinc-600 rounded-lg p-1.5 items-center gap-2 text-sm bg-whiteNew-0 dark:bg-zinc-700 shadow-md">
                                    <RiTwitterLine className="text-lg text-sky-600 dark:text-whiteNew-0" />
                                    {/* <span>Twitter</span> */}
                                  </button>
                                </Tooltip>
                                <Tooltip title="Linkedin" color="">
                                  <button className="flex flex-row border-[1px] border-zinc-200 dark:border-zinc-600 rounded-lg p-1.5 items-center gap-2 text-sm bg-whiteNew-0 dark:bg-zinc-700 shadow-md">
                                    <RiLinkedinBoxLine className="text-lg text-sky-600 dark:text-whiteNew-0" />
                                    {/* <span>Linkedin</span> */}
                                  </button>
                                </Tooltip>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <ConfirmationModalNewsLetter isOpen={open} setIsOpen={setIsOpen} /> */}
      {/* <GoToTop /> */}
    </React.Fragment>
  );
};
