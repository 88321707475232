import React, { useMemo } from 'react';
import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md';
import Modal from '../../common/Modal';
import CurrencyFormat from 'react-currency-format';
import { Tab } from '@headlessui/react';
import $ from 'jquery';
import {
  TabOutlineClass,
  afterBorder,
  btnPrimaryClass,
  btnSecondayClass,
  classNames,
  horizontalTabClass,
  horizontalTabSelectedClass,
  horizontalTabUnselectedClass,
} from '../DefaultClasses';
import { useCallback } from 'react';
import { getMomentAgoTime } from '../../../utils/EventUtil';
import moment from 'moment';
import { IoTicketOutline } from 'react-icons/io5';
import { DATEFORMAT } from '../../../utils/util';

export default function PoliciesModel({
  isOpen,
  setIsOpen,
  darkTheme,
  policies,
  getTicketCancelDisableTime,
  resaleModeOnly = false,
  refundModeOnly = false,
  setIsOpenResale = () => {},
  setIsOpenRefund = () => {},
  event = null,
  event_type = '',
}) {
  const [savedpolicies, setSavedPolicies] = React.useState({});
  const { is_virtual, is_both } = useMemo(
    () => ({
      is_virtual: event_type === 'Virtual',
      is_inperson: event_type === 'Physical',
      is_both: event_type === 'both',
    }),
    [event_type],
  );

  /// UseEffect is used to grouping all the policies once policies data came.
  React.useEffect(() => {
    const groupByPolicies = policies?.reduce((group, policy) => {
      const { sub_policy_type } = policy; //take the field
      group[sub_policy_type] = group[sub_policy_type] ?? [];
      group[sub_policy_type].push(policy);
      return group;
    }, {});

    if (groupByPolicies) {
      let newObj = { ...groupByPolicies };
      if (!('Refund Policies' in groupByPolicies)) {
        newObj = { ...newObj, 'Refund Policies': [] };
      }
      if (!('Resale Policies' in groupByPolicies)) {
        newObj = { ...newObj, 'Resale Policies': [] };
      }
      if (!('Shared And Transfer Policies' in groupByPolicies)) {
        newObj = { ...newObj, 'Shared And Transfer Policies': [] };
      }
      if (!('Cancelled Event' in groupByPolicies)) {
        newObj = { ...newObj, 'Cancelled Event': [] };
      }
      if (!('Delayed Event' in groupByPolicies)) {
        newObj = { ...newObj, 'Delayed Event': [] };
      }
      setSavedPolicies(newObj);
    }
  }, [policies]);
  const policiesTab = {
    'Refund Policy': [
      {
        id: 1,
        component: (
          <>
            <div className={`${resaleModeOnly ? 'hidden' : ''}`}>
              {/* <div className="mb-3 flex">
                <div
                  className={`${
                    darkTheme ? 'border-landingPurple-60' : ' border-sky-600'
                  } border-b-2`}
                >
                  <h5
                    className={`text-lg ${
                      darkTheme ? 'text-whiteNew-0' : 'text-dark'
                    }`}
                  >
                    Refund Policy
                  </h5>
                </div>
              </div> */}
              <span
                className={`dark:text-whiteNew-0 text-zinc-800 pl-4 space-y-1 text-sm`}
              >
                The event starts on{' '}
                {moment(event?.start_time)?.format(DATEFORMAT)}. The following
                policies shall be valid:-
              </span>
              <ul className="list-disc pl-4 space-y-1 text-sm">
                {savedpolicies['Refund Policies']?.length > 0 &&
                  savedpolicies['Refund Policies']
                    ?.filter(
                      (pol) => pol?.currently_in_use && !pol?.default_values,
                    )[0] //ADDED default_values CHECK
                    ?.values?.map((policy, index) => {
                      return (
                        <li
                          className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-800`}
                          key={policy?.id + index}
                        >
                          <span
                            className={`${
                              getMomentAgoTime(policy, event)?.remainingTime < 0
                                ? 'dark:text-gray-400 text-gray-400'
                                : 'dark:text-gray-200 font-normal text-gray-200'
                            }`}
                          >
                            <IoTicketOutline />
                          </span>
                          <span
                            className={`${
                              getMomentAgoTime(policy, event)?.remainingTime < 0
                                ? 'dark:text-gray-400 text-gray-400'
                                : 'dark:text-gray-200 font-normal text-gray-200'
                            }`}
                          >
                            {
                              <CurrencyFormat
                                thousandSeparator
                                displayType={'text'}
                                // prefix="$"
                                value={policy?.percent || 0}
                                // decimalScale={2}
                                fixedDecimalScale
                                className={`font-normal`}
                              />
                            }
                            {/* % Refund, if cancelled {policy.timeframeValue}{' '}
                          {policy.timeframeSelect === 'Hour'
                            ? 'Hours'
                            : policy.timeframeSelect}{' '}
                          before{' '}
                          {moment(event?.start_time)?.format(
                            'MM/DD/YY - HH:mm',
                          )}
                          . */}
                            % Refund, if cancelled before{' '}
                            {getMomentAgoTime(policy, event)?.timevalue}.
                          </span>
                        </li>
                      );
                    })}
                {/* {savedpolicies['Refund Policies']?.length > 0 &&  */}
                {savedpolicies['Refund Policies']?.filter(
                  (pol) => pol?.currently_in_use && !pol?.default_values,
                )?.length > 0 && (
                  <li
                    className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-600`}
                    key={6}
                  >
                    {' '}
                    {/* {`Ticket cannot be cancelled when ${getTicketCancelDisableTime()} left for the event. `} */}
                    {/* {`0% Refund, if cancelled less than ${getTicketCancelDisableTime()} of ${moment(
                      event?.start_time,
                    )?.format('MM/DD/YY - HH:mm')}. `} */}
                    <span
                      className={`${
                        getMomentAgoTime('static', event)?.remainingTime < 0
                          ? 'text-gray-400'
                          : 'font-normal'
                      }`}
                    >
                      <IoTicketOutline />
                    </span>
                    <span
                      className={`${
                        getMomentAgoTime('static', event)?.remainingTime < 0
                          ? 'text-gray-400'
                          : 'font-normal'
                      }`}
                    >
                      {`0% Refund, if cancelled after ${
                        getMomentAgoTime(
                          savedpolicies['Refund Policies']
                            ?.filter(
                              (pol) =>
                                pol?.currently_in_use && !pol?.default_values,
                            )[0]
                            ?.values?.slice(-1)[0],
                          event,
                        )?.timevalue
                      } `}
                    </span>
                  </li>
                )}
              </ul>
              {/* {savedpolicies['Refund Policies']?.length <= 0 && ( */}
              {savedpolicies['Refund Policies']?.filter(
                (pol) => pol?.currently_in_use && pol?.default_values,
              )?.length > 0 && (
                <span className={`text-color-primary`}>
                  <ul className="list-disc pl-4 space-y-1 text-sm">
                    <li
                      className={`flex gap-1 items-center text-color-primary`}
                    >
                      <span>
                        <IoTicketOutline />
                      </span>
                      <span>Ticket is not Refundable.</span>
                    </li>
                  </ul>
                </span>
              )}
              {/* <div
                className={`${
                  darkTheme ? 'bg-neutral-700' : 'bg-gray-200'
                } w-full h-1 my-3`}
              ></div> */}
            </div>
          </>
        ),
      },
    ],
    'Resale Policy': [
      {
        id: 2,
        component: (
          <>
            {is_virtual ? (
              <span className={`dark:text-whiteNew-0 text-zinc-600`}>
                <ul className="pl-4 space-y-1 text-sm">
                  <li className="flex gap-1 items-center">
                    <span>
                      <IoTicketOutline />
                    </span>
                    <span>
                      Resale of tickets for virtual events are not allowed.
                    </span>
                  </li>
                </ul>
              </span>
            ) : (
              <div
              // className={`${refundModeOnly ? 'hidden' : ''}`}
              >
                {/* <div className="mb-3 flex">
                <div
                  className={`${
                    darkTheme ? 'border-landingPurple-60' : ' border-sky-600'
                  } border-b-2`}
                >
                  <h5
                    className={`text-lg ${
                      darkTheme ? 'text-whiteNew-0' : 'text-dark'
                    }`}
                  >
                    Resale Policy
                  </h5>
                </div>
              </div> */}
                <span
                  className={`dark:text-whiteNew-0 text-zinc-800 pl-4 space-y-1 text-sm`}
                >
                  The event starts on{' '}
                  {moment(event?.start_time)?.format(DATEFORMAT)}. The following
                  policies shall be valid:-
                </span>
                <ul className="list-disc pl-4 space-y-1 text-sm">
                  {savedpolicies['Resale Policies']?.length > 0 &&
                    savedpolicies['Resale Policies']?.filter(
                      (pol) => pol?.currently_in_use,
                    )[0]?.values[0]?.selected_resale_times_limit !==
                      'not_allowed' &&
                    savedpolicies['Resale Policies']
                      ?.filter(
                        (pol) => pol?.currently_in_use && !pol?.default_values,
                      )[0]
                      ?.values?.map((policy, index) => {
                        return (
                          <>
                            {policy?.selected_resale_times_limit !==
                              'not_allowed' && (
                              <li
                                className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-600`}
                                key={policy?.id + index}
                              >
                                <span>
                                  <IoTicketOutline />
                                </span>
                                {policy?.selected === 'unlimited' &&
                                  `Unlimited Resale price of
                                  Original Ticket Price.`}

                                {policy?.selected === 'max_resale' &&
                                  policy?.selected_resale_times_limit !==
                                    'not_allowed' && (
                                    <span>
                                      Max Resale Limit{' '}
                                      <CurrencyFormat
                                        thousandSeparator
                                        displayType={'text'}
                                        // prefix="$"
                                        value={policy?.max_resale_limit || 0}
                                        // decimalScale={2}
                                        fixedDecimalScale
                                        className={`font-normal`}
                                      />
                                      % of Original Ticket Price.
                                    </span>
                                  )}
                                {policy?.selected === 'resale_limit' &&
                                  policy?.selected_resale_times_limit !==
                                    'not_allowed' && (
                                    <span>
                                      {/* Max Resal Limit{' '} */}
                                      <CurrencyFormat
                                        thousandSeparator
                                        displayType={'text'}
                                        prefix="$"
                                        value={policy?.resale_limit || 0}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        className={`font-normal`}
                                      />{' '}
                                      Limit for Resale ticket.
                                    </span>
                                  )}
                              </li>
                            )}

                            {policy?.royalty !== '' &&
                              policy?.selected_resale_times_limit !==
                                'not_allowed' && (
                                <li
                                  className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-600`}
                                  key={policy?.id + index}
                                >
                                  <span>
                                    <IoTicketOutline />
                                  </span>
                                  <span>
                                    <CurrencyFormat
                                      thousandSeparator
                                      displayType={'text'}
                                      // prefix="$"
                                      value={policy?.royalty || 0}
                                      // decimalScale={2}
                                      fixedDecimalScale
                                      className={`font-normal`}
                                    />
                                    % Royalty to Event Organizer of profits
                                    above original price.
                                  </span>
                                </li>
                              )}
                            <li
                              className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-600`}
                              key={policy?.id + index}
                            >
                              <span>
                                <IoTicketOutline />
                              </span>
                              <span>
                                {policy?.selected_resale_times_limit ===
                                'allowed_once'
                                  ? 'Resale Once Allowed on original tickets only'
                                  : policy?.selected_resale_times_limit ===
                                    'allowed_multiple'
                                  ? 'Resale Multiple Times Allowed'
                                  : 'Resale Not Allowed.'}
                              </span>
                            </li>
                          </>
                        );
                      })}
                  {savedpolicies['Resale Policies']?.length > 0 &&
                    savedpolicies['Resale Policies']?.filter(
                      (pol) => pol?.currently_in_use,
                    )[0]?.values[0]?.selected_resale_times_limit ===
                      'not_allowed' && (
                      <li
                        className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-600`}
                        key={Math.random() * 100}
                      >
                        <span>
                          <IoTicketOutline />
                        </span>
                        <span>{'Resale Not Allowed.'}</span>
                      </li>
                    )}
                  {savedpolicies['Resale Policies']?.filter(
                    (pol) => pol?.currently_in_use && pol?.default_values,
                  )?.length > 0 && (
                    <li className="flex gap-1 items-center dark:text-whiteNew-0 text-zinc-600">
                      <span>
                        <IoTicketOutline />
                      </span>
                      <span>Ticket cannot be Resold.</span>
                    </li>
                  )}
                  {(is_both || is_virtual) &&
                  savedpolicies['Resale Policies']?.length > 0 &&
                  savedpolicies['Resale Policies']?.filter(
                    (pol) => pol?.currently_in_use,
                  )[0]?.values[0]?.selected_resale_times_limit !==
                    'not_allowed' ? (
                    <li className="flex gap-1 items-center dark:text-whiteNew-0 text-zinc-600">
                      <span>
                        <IoTicketOutline />
                      </span>
                      <span>
                        Resale of tickets for virtual events are not allowed.
                      </span>
                    </li>
                  ) : null}
                </ul>

                {/* <div
                className={`${
                  darkTheme ? 'bg-neutral-700' : 'bg-gray-200'
                } w-full h-1 my-3`}
              ></div> */}
              </div>
            )}
          </>
        ),
      },
    ],
    'Share and Transfer policy': [
      {
        id: 3,
        component: (
          <>
            <div
              className={`${resaleModeOnly || refundModeOnly ? 'hidden' : ''}`}
            >
              {!savedpolicies['Shared And Transfer Policies']?.[0]
                ?.default_values &&
                !savedpolicies['Shared And Transfer Policies']?.[0]
                  ?.shared_default_values && (
                  <span
                    className={`pl-4 space-y-1 text-sm dark:text-whiteNew-0 text-zinc-800`}
                  >
                    The event starts on{' '}
                    {moment(event?.start_time)?.format(DATEFORMAT)}. The
                    following policies shall be valid:-
                  </span>
                )}
              <ul className="list-disc pl-4 space-y-1 text-sm mt-0.5">
                {savedpolicies['Shared And Transfer Policies']?.length > 0 &&
                  savedpolicies['Shared And Transfer Policies']?.map(
                    (pol, index) => (
                      <span
                        key={index}
                        className={`dark:text-whiteNew-0 text-zinc-800`}
                      >
                        {pol?.currently_in_use && (
                          <>
                            {pol?.default_values ? (
                              <li
                                className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-800`}
                                key={pol?._id + index}
                              >
                                {' '}
                                <span>
                                  <IoTicketOutline />
                                </span>
                                {`Tickets are non-transferable.`}
                              </li>
                            ) : (
                              <li
                                className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-600`}
                                key={pol?._id + index}
                              >
                                <span>
                                  <IoTicketOutline />
                                </span>
                                <span>
                                  {' '}
                                  <CurrencyFormat
                                    thousandSeparator
                                    displayType={'text'}
                                    prefix="$"
                                    value={pol?.values?.[0]?.price || 0}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    className={`font-normal`}
                                  />{' '}
                                  {`Fees Charged for Transfer.`}
                                </span>
                              </li>
                            )}
                            {pol?.shared_default_values ? (
                              <li
                                className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-600`}
                                key={pol?._id + index}
                              >
                                {' '}
                                <span>
                                  <IoTicketOutline />
                                </span>
                                {`Tickets are non-shareable.`}
                              </li>
                            ) : (
                              <li
                                className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-600`}
                                key={pol?._id + index}
                              >
                                <span>
                                  <IoTicketOutline />
                                </span>
                                <span>
                                  {' '}
                                  <CurrencyFormat
                                    thousandSeparator
                                    displayType={'text'}
                                    prefix="$"
                                    value={pol?.values?.[0]?.share_price || 0}
                                    // decimalScale={2}s
                                    fixedDecimalScale
                                    className={`font-normal`}
                                  />{' '}
                                  {`Fees Charged for Share.`}
                                </span>
                              </li>
                            )}
                          </>
                        )}
                      </span>
                    ),
                  )}
              </ul>
            </div>
          </>
        ),
      },
    ],
    'Cancelled Event Policy': [
      {
        id: 4,
        component: (
          <>
            <div
              className={`${resaleModeOnly || refundModeOnly ? 'hidden' : ''}`}
            >
              {/* <div className="mb-3 flex">
                <div
                  className={`${
                    darkTheme ? 'border-landingPurple-60' : ' border-sky-600'
                  } border-b-2`}
                >
                  <h5
                    className={`text-lg ${
                      darkTheme ? 'text-whiteNew-0' : 'text-dark'
                    }`}
                  >
                    Cancelled Event Policy
                  </h5>
                </div>
              </div> */}
              <span
                className={`pl-4 space-y-1 text-sm dark:text-whiteNew-0 text-zinc-800`}
              >
                The event starts on{' '}
                {moment(event?.start_time)?.format(DATEFORMAT)}. The following
                policies shall be valid:-
              </span>
              <ul className="list-disc pl-4 space-y-1 text-sm">
                {savedpolicies['Cancelled Event']?.length > 0 &&
                  savedpolicies['Cancelled Event']
                    ?.filter((pol) => pol?.currently_in_use)[0]
                    ?.values?.map((policy, index) => {
                      return (
                        <>
                          <li
                            className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-600`}
                            key={policy?.id + index}
                          >
                            <span>
                              <IoTicketOutline />
                            </span>
                            <span>
                              Buyer will get{' '}
                              <CurrencyFormat
                                thousandSeparator
                                displayType={'text'}
                                // prefix="$"
                                value={policy?.percent || 100}
                                // decimalScale={2}
                                fixedDecimalScale
                                className={`font-normal`}
                              />
                              % Refund if event is Cancelled.
                            </span>
                          </li>
                        </>
                      );
                      // }
                    })}
              </ul>
              {/* {savedpolicies['Cancelled Event']?.length <= 0 && ( */}
              {savedpolicies['Cancelled Event']?.filter(
                (pol) => pol?.currently_in_use,
              )?.length <= 0 && (
                <span className={`dark:text-whiteNew-0 text-zinc-800`}>
                  <ul className="list-disc pl-4 space-y-1 text-sm">
                    <li
                      className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-800`}
                    >
                      <span>
                        <IoTicketOutline />
                      </span>
                      100 % of Ticket value will be Refundable.
                    </li>
                  </ul>
                </span>
              )}
              {/* <div
                className={`${
                  darkTheme ? 'bg-neutral-700' : 'bg-gray-200'
                } w-full h-1 my-3`}
              ></div> */}
            </div>
          </>
        ),
      },
    ],
    'Delayed Event Policy': [
      {
        id: 5,
        component: (
          <>
            <div
              className={`${resaleModeOnly || refundModeOnly ? 'hidden' : ''}`}
            >
              {/* <div className="mb-3 flex">
                <div
                  className={`${
                    darkTheme ? 'border-landingPurple-60' : ' border-sky-600'
                  } border-b-2`}
                >
                  <h5
                    className={`text-lg ${
                      darkTheme ? 'text-whiteNew-0' : 'text-dark'
                    }`}
                  >
                    Delayed Event Policy
                  </h5>
                </div>
              </div> */}
              <span
                className={`pl-4 space-y-1 text-sm dark:text-whiteNew-0 text-zinc-800`}
              >
                The event starts on{' '}
                {moment(event?.start_time)?.format(DATEFORMAT)}. The following
                policies shall be valid:-
              </span>
              <ul className="list-disc pl-4 space-y-1 text-sm">
                {savedpolicies['Delayed Event']?.length > 0 &&
                  savedpolicies['Delayed Event']
                    ?.filter((pol) => pol?.currently_in_use)[0]
                    ?.values?.map((policy, index) => {
                      return (
                        <>
                          <li
                            className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-600`}
                            key={policy?.id + index}
                          >
                            <span>
                              <IoTicketOutline />
                            </span>
                            <span>
                              Buyer will get{' '}
                              <CurrencyFormat
                                thousandSeparator
                                displayType={'text'}
                                // prefix="$"
                                value={policy?.percent || 0}
                                // decimalScale={2}
                                fixedDecimalScale
                                className={`font-normal`}
                              />
                              % Refund if event is postponed.
                            </span>
                          </li>
                        </>
                      );
                      // }
                    })}
              </ul>
              {/* {savedpolicies['Delayed Event']?.length <= 0 && ( */}
              {savedpolicies['Delayed Event']?.filter(
                (pol) => pol?.currently_in_use,
              )?.length <= 0 && (
                // <span
                //   className={` ${darkTheme ? 'text-whiteNew-0' : 'text-dark'}`}
                // >
                <ul className="list-disc pl-4 space-y-1 text-sm">
                  <li
                    className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-800`}
                  >
                    <span>
                      <IoTicketOutline />
                    </span>{' '}
                    Ticket is not Refundable.
                  </li>
                </ul>
                // </span>
              )}
              {/* <div
                className={`${
                  darkTheme ? 'bg-neutral-700' : 'bg-gray-200'
                } w-full h-1 my-3`}
              ></div> */}
            </div>
          </>
        ),
      },
    ],
  };

  // !TODO
  function buttonRight() {
    $('.scrollClass').animate(
      {
        scrollLeft: '+=100',
      },
      500,
    );
  }

  // !TODO
  function buttonLeft() {
    $('.scrollClass').animate(
      {
        scrollLeft: '-=100',
      },
      500,
    );
  }

  // useCallback added to all the onchange and onClick function in optimisations.

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const continueToResale = useCallback(() => {
    setIsOpen(false);
    setIsOpenResale(true);
  }, [setIsOpen, setIsOpenResale]);
  const continueToRefund = useCallback(() => {
    setIsOpen(false);
    setIsOpenRefund(true);
  }, [setIsOpen, setIsOpenRefund]);

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        setIsOpen={closeModal}
        maxWidth={`max-w-xl`}
        backdrop={'backdrop-blur-sm'}
        // bgColor={
        //   darkTheme
        //     ? 'dark:bg-neutral-800 bg-zinc-100 bg-opacity-100 backdrop-blur-sm bg-center'
        //     : null
        // }
        header={
          <div className="flex flex-col justify-center items-center py-2">
            <div className="w-full">
              <h3
                className={`dark:text-whiteNew-0 text-zinc-900 text-lg font-medium leading-6`}
              >
                Ticket Policies
              </h3>
            </div>
          </div>
        }
        // headerColor={
        //   darkTheme
        //     ? 'dark:bg-neutral-900 bg-zinc-100 dark:border-b-neutral-800 border-b-neutral-300'
        //     : ''
        // }
        bodyClass={'w-full pb-2 pt-2'}
        bodyHeight={'67vh'}
        // darkTheme={darkTheme}
        // footerColor={
        //   darkTheme
        //     ? 'dark:bg-neutral-900 bg-zinc-100 bg-opacity-100 backdrop-blur-sm bg-center border-0 p-2'
        //     : 'bg-gray-100'
        // }
        footer={
          <>
            <div
              className={`${
                resaleModeOnly || refundModeOnly ? 'hidden' : ''
              } flex justify-center mt-0.5 gap-2 gap-md-3`}
            >
              <button
                type="submit"
                onClick={closeModal}
                className={
                  darkTheme
                    ? `dark:bg-landingPurple-60 bg-sky-600 text-gray-50 dark:hover:bg-landingPurple-70 hover:bg-sky-700 p-2 rounded-lg duration-200 ease-in w-full max-w-[120px]`
                    : `${btnPrimaryClass}`
                }
              >
                Ok
              </button>
            </div>
            <div
              className={`${
                resaleModeOnly ? '' : 'hidden'
              } flex justify-center mt-2 gap-2 gap-md-3`}
            >
              <button
                type="submit"
                onClick={continueToResale}
                className={
                  darkTheme
                    ? `bg-landingPurple-60 text-gray-50 hover:bg-landingPurple-70 p-2 rounded-lg duration-200 ease-in w-full max-w-[120px]`
                    : `${btnPrimaryClass}`
                }
              >
                Continue to Resale
              </button>
            </div>
            <div
              className={`${
                refundModeOnly ? '' : 'hidden'
              } flex justify-center mt-2 gap-2 gap-md-3`}
            >
              <button
                type="submit"
                onClick={continueToRefund}
                className={
                  darkTheme
                    ? `bg-landingPurple-60 text-gray-50 hover:bg-landingPurple-70 p-2 rounded-lg duration-200 ease-in w-full max-w-[120px]`
                    : `${btnPrimaryClass}`
                }
              >
                Continue to Cancel
              </button>
            </div>
          </>
        }
      >
        <div>
          <Tab.Group>
            <div className="relative mx-1.5">
              <div className={`overflow-hidden relative px-3.5 ${afterBorder}`}>
                <Tab.List
                  className={`flex space-x-1 overflow-x-auto p-1 border-b-1 pb-0 scrollCss scrollHide scrollClass`}
                >
                  {refundModeOnly &&
                    Object.keys(policiesTab).map((category, index) => {
                      if (category === 'Refund Policy') {
                        return (
                          <Tab
                            key={index + 30}
                            className={({ selected }) =>
                              classNames(
                                horizontalTabClass,
                                selected
                                  ? horizontalTabSelectedClass
                                  : horizontalTabUnselectedClass,
                              )
                            }
                          >
                            {category}
                          </Tab>
                        );
                      }
                      return null;
                    })}
                  {resaleModeOnly &&
                    Object.keys(policiesTab).map((category, index) => {
                      if (category === 'Resale Policy') {
                        return (
                          <Tab
                            key={index + 20}
                            className={({ selected }) =>
                              classNames(
                                horizontalTabClass,
                                selected
                                  ? horizontalTabSelectedClass
                                  : horizontalTabUnselectedClass,
                              )
                            }
                          >
                            {category}
                          </Tab>
                        );
                      }
                      return null;
                    })}
                  {!resaleModeOnly &&
                    !refundModeOnly &&
                    Object.keys(policiesTab).map((category, index) => {
                      return (
                        <Tab
                          key={index}
                          className={({ selected }) =>
                            classNames(
                              horizontalTabClass,
                              selected
                                ? horizontalTabSelectedClass
                                : horizontalTabUnselectedClass,
                            )
                          }
                        >
                          {category}
                        </Tab>
                      );
                    })}
                </Tab.List>
              </div>
              {!refundModeOnly && !resaleModeOnly && (
                <div>
                  <div className="absolute top-1/2 -translate-y-1/2 -left-4 text-base">
                    <button
                      // className="p-1 rounded-sm hover:shadow dark:bg-gray-100"
                      className={`${btnSecondayClass} w-[28px] h-[28px] relative`}
                      id="slideRight"
                      onClick={buttonLeft}
                    >
                      <MdOutlineChevronLeft className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                    </button>
                  </div>
                  <div className="absolute top-1/2 -translate-y-1/2 -right-4 text-base">
                    <button
                      // className="p-1 rounded-sm hover:shadow bg-gray-100"
                      className={`${btnSecondayClass} w-[28px] h-[28px] relative`}
                      id="slideRight"
                      onClick={buttonRight}
                    >
                      <MdOutlineChevronRight className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                    </button>
                  </div>
                </div>
              )}
            </div>
            <Tab.Panels className={`${TabOutlineClass} mt-2`}>
              {Object.entries(policiesTab).map(([key, value], index) => {
                if (key === 'Refund Policy' && !resaleModeOnly) {
                  return (
                    <Tab.Panel
                      key={index}
                      className={`${TabOutlineClass} mt-2`}
                    >
                      {value[0]?.component}
                    </Tab.Panel>
                  );
                } else if (key === 'Resale Policy' && !refundModeOnly) {
                  return (
                    <Tab.Panel
                      key={index}
                      className={`${TabOutlineClass} mt-2`}
                    >
                      <>{value[0]?.component}</>
                    </Tab.Panel>
                  );
                } else if (
                  key === 'Share and Transfer policy' &&
                  !refundModeOnly &&
                  !resaleModeOnly
                ) {
                  return (
                    <Tab.Panel
                      key={index}
                      className={`${TabOutlineClass} mt-2`}
                    >
                      {value[0]?.component}
                    </Tab.Panel>
                  );
                } else if (
                  key === 'Cancelled Event Policy' &&
                  !refundModeOnly &&
                  !resaleModeOnly
                ) {
                  return (
                    <Tab.Panel
                      key={index}
                      className={`${TabOutlineClass} mt-2`}
                    >
                      {value[0]?.component}
                    </Tab.Panel>
                  );
                } else if (
                  key === 'Delayed Event Policy' &&
                  !refundModeOnly &&
                  !resaleModeOnly
                ) {
                  return (
                    <Tab.Panel
                      key={index}
                      className={`${TabOutlineClass} mt-2`}
                    >
                      {value[0]?.component}
                    </Tab.Panel>
                  );
                }
                return null;
              })}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </Modal>
    </React.Fragment>
  );
}
