import React from 'react';
import { MdDelete } from 'react-icons/md';
import { MdOutlineChair } from 'react-icons/md';
import NoDataFound from '../../NoDataFound';

export default function SeactCategoryCardV2({
  seatData,
  addSeat,
  removeButtonID,
  removeSeats,
}) {
  if (!seatData || seatData.length === 0) {
    return (
      <NoDataFound
        title={
          <span className="text-sm dark:text-whiteNew-0 text-zinc-800">
            No Seats are available for the selected filter
          </span>
        }
        minHeight={'min-h-[500px]'}
        icon={<MdOutlineChair />}
      />
    );
  }
  return seatData?.map((item) => (
    <div className="flex justify-center flex-col border-b dark:border-b-brown-700 border-b-brown-100 py-2.5 px-1.5 relative">
      {item?.status === 'resale' && (
        <div className="flex mb-0.5">
          <span
            className="bg-resale-900 rounded-md text-resale-400 px-1.5"
            style={{ fontSize: '10px' }}
          >
            On Resale
          </span>
        </div>
      )}
      <div className="flex justify-between items-center gap-2">
        <div className="flex items-center gap-2 dark:text-whiteNew-0 text-zinc-800">
          <MdOutlineChair className={'text-lg'} />
          <span>
            Seat No. : <span className="font-medium">{item?.seat_no}</span>
          </span>
        </div>
        <div className="">
          <div className="flex justify-end items-center gap-2">
            <div>
              <span className="flex dark:text-[#A864FD] text-sky-500 text-xs font-medium">
                {item?.category_details?.currency}{' '}
                {item?.resale_price
                  ? item?.resale_price
                  : item?.category_details?.ticket_price}
              </span>
            </div>
            <div className="">
              {removeButtonID?.some((seat) => seat?.seat_id === item._id) ? (
                <button
                  className="bg-red-600 hover:bg-red-700 text-whiteNew-0 rounded-[4px] px-0.5 py-0.5 text-base"
                  onClick={() => {
                    removeSeats(item);
                  }}
                >
                  <MdDelete />
                </button>
              ) : (
                <button
                  className="dark:bg-landingPurple-60 bg-sky-600 hover:bg-sky-700 dark:hover:bg-landingPurple-70 rounded-[4px] px-2 py-0.5 text-whiteNew-0 text-xs"
                  onClick={() => {
                    addSeat(item, 'add');
                  }}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
}
