import { Transition } from '@headlessui/react';
import React, { useCallback, useMemo, useState } from 'react';
import DeviceTitle from './DeviceTitle';
import { useDispatch } from 'react-redux';
import { exit_from_stream } from '../../helpers/api/stream/stream';
import {
  updateActiveSessionList,
  updateStreamTicketDetails,
} from '../../features/stream_lounge/stream.slice';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { message } from 'antd';

export default function StreamingSessionList({
  ticket_data,
  onCloseSession,
  active_session_list,
  show_singleton_session,
  orders_mutate,
}) {
  const dispatch = useDispatch();
  const [opened, setIsopened] = useState({ panel_id: null, is_opened: false });
  // const { onCloseSession, handleToggleScreen } = useDrawerScreens();

  const handleExitFromStream = useCallback(
    (ticket_id) => {
      return exit_from_stream(ticket_id).then(async () => {
        message.success('Session Terminated Successfully');
        if (Array.isArray(active_session_list)) {
          const temp = [...active_session_list];
          const temp_ticket = [...ticket_data];
          const ticketIndex = temp.findIndex((e) => e._id === ticket_id);
          const index = temp_ticket.findIndex((e) => e._id === ticket_id);
          let spliced_ticket = temp_ticket.splice(index, 1)[0];
          temp.splice(ticketIndex, 1);
          spliced_ticket = {
            ...spliced_ticket,
            is_active: false,
            is_available: true,
          };
          temp_ticket.splice(ticketIndex, 0, { ...spliced_ticket });
          if (!temp.length) {
            onCloseSession();
          }
          // handleToggleScreen();
          // mutate(event_id + '/orders');
          orders_mutate();
          dispatch(updateActiveSessionList(temp));
          dispatch(updateStreamTicketDetails(temp_ticket));
        }
      });
    },
    [
      active_session_list,
      dispatch,
      onCloseSession,
      ticket_data,
      // event_id,
      // handleToggleScreen,
      orders_mutate,
    ],
  );

  const session_details = useMemo(() => {
    if (show_singleton_session?.ticket_id) {
      return (
        Array.isArray(active_session_list) &&
        active_session_list?.filter((session) =>
          Boolean(
            session?.ticket_access_code === show_singleton_session?.ticket_id,
          ),
        )
      );
    } else {
      return active_session_list;
    }
  }, [show_singleton_session, active_session_list]);
  return (
    <React.Fragment>
      <>
        {Array.isArray(session_details) &&
          session_details?.map(
            ({ _id, current_session, ticket_access_code }, index) => {
              const { device_info } = current_session;
              const isOpen = Boolean(
                opened.panel_id === _id && opened.is_opened,
              );
              return (
                <div key={_id}>
                  <button
                    className={`flex w-full justify-between px-3 py-3 text-left text-sm font-medium focus:outline-none focus-visible:ring-1 focus-visible:ring-opacity-75 duration-200 rounded-md focus-visible:ring-neutral-800 border-neutral-800 bg-stone-900 hover:bg-stone-900 hover:bg-opacity-90  text-gray-100 ${
                      isOpen && 'rounded-b-none'
                    }`}
                    onClick={() =>
                      setIsopened({
                        ...opened,
                        panel_id: _id,
                        is_opened:
                          opened.panel_id === _id ? !opened.is_opened : true,
                      })
                    }
                    aria-expanded={isOpen}
                    {...(isOpen && { 'aria-controls': _id })}
                  >
                    <span>
                      <span className="flex items-center">
                        <span className="flex bg-neutral-900 rounded-md p-1.5">
                          {isOpen ? (
                            <FaMinus size={10} />
                          ) : (
                            <FaPlus size={10} />
                          )}
                        </span>
                        <span className="ml-2">
                          Session | {ticket_access_code}
                        </span>
                      </span>
                    </span>
                    <div className="flex shrink-0 items-center gap-2">
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handleExitFromStream(_id);
                        }}
                        className="text-whiteNew-0 bg-rose-700 hover:bg-rose-800 px-2 rounded-md duration-200 ease-in cursor-pointer"
                      >
                        Terminate
                      </span>
                    </div>
                  </button>
                  <Transition
                    show={isOpen}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <div className="p-3 bg-neutral-900 rounded-b-md">
                      <DeviceTitle
                        current_session={current_session}
                        device={device_info}
                      />
                    </div>
                  </Transition>
                </div>
              );
            },
          )}
      </>
    </React.Fragment>
  );
}
