import React from 'react';

export const LivePill = ({ title, color }) => {
  return (
    <div className="px-1.5 rounded-md cursor-pointer">
      <span
        className={`cursor-text text-[9px] font-medium flex gap-2 items-center leading-3 ${
          color ? color : 'text-rose-700'
        }`}
      >
        <span className="relative flex h-[6px] w-[6px]">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-rose-600 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-[6px] w-[6px] bg-rose-700"></span>
        </span>
        {title !== undefined || title !== null || title !== '' ? title : 'LIVE'}
      </span>
    </div>
  );
};

export const ComingSoon = ({ title }) => {
  return (
    <div className="px-1.5 rounded-md">
      <span className="cursor-text text-[9px] font-medium flex gap-2 items-center leading-3 text-white">
        {/* <span className="relative flex h-[6px] w-[6px]">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-rose-600 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-[6px] w-[6px] bg-rose-700"></span>
        </span> */}
        {title !== undefined || title !== null || title !== '' ? title : 'LIVE'}
      </span>
    </div>
  );
};

export const Concluded = ({ title }) => {
  return (
    <div className="px-1.5 rounded-md">
      <span className="cursor-text text-[9px] font-medium flex gap-2 items-center leading-3 text-white">
        {/* <span className="relative flex h-[6px] w-[6px]">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-rose-600 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-[6px] w-[6px] bg-rose-700"></span>
        </span> */}
        {title || 'Ended'}
      </span>
    </div>
  );
};
