import React from 'react';
// import UserProfileDropdown from '../util/header_components/UserProfileDropdown';
import {
  BellIcon,
  CustomWalletIcon,
  LogoGoTix,
  profileUrl,
} from '../util/CustomIcons';
// import NotificationDropdown from '../util/header_components/NotificationDropdown';
import useDecodedToken from '../../hooks/useDecodedToken';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Tooltip from '../common/Tooltip';
import { AiOutlineClose } from 'react-icons/ai';

export default function HeaderDashboard({ onlyLogo }) {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const decodedToken = useDecodedToken();
  return (
    <React.Fragment>
      <div className="sticky z-50 top-0 left-0 w-full unified_top_header">
        <div className="container-fluid lg:max-w-[1920px] xl:max-w-[1920px]">
          <div className="flex justify-between py-1.5 items-center">
            <div>
              <div className="flex-shrink-0 relative h-7 w-28 sm:w-36 xl:w-36">
                <Link
                  to="/"
                  state={{ redirected: true }}
                  className="flex items-center gap-2"
                >
                  <LogoGoTix
                    className={'w-full text-white absolute inset-0 h-full'}
                  />
                </Link>
              </div>
            </div>
            <div className="shrink-0">
              {decodedToken && !onlyLogo ? (
                <div className="flex shrink-0 gap-2 items-center">
                  {/* {pathname !== '/demo' && (
                    <div>
                      <button
                        onClick={() => navigate('/demo')}
                        className="p-2 bg-landingPurple-60 text-gray-50 rounded-lg hover:bg-landingPurple-70 duration-200 ease-in md:w-24"
                      >
                        Demo
                      </button>
                    </div>
                  )} */}
                  <div>
                    {/* <NotificationDropdown /> */}
                    <span
                      className={`flex w-8 h-8 p-1.5 text-white relative hover:bg-gray-700 hover:bg-opacity-50 rounded-md text-base hover_animate`}
                    >
                      <BellIcon
                        className={`absolute top-1/2 left-1/2 translate-neg-50-50 object-cover w-5 h-5`}
                      />
                    </span>
                  </div>
                  <div>
                    <Tooltip title="Wallet" placement={'bottom'}>
                      <Link
                        to={`/user/${decodedToken?.username}/dashboard?tab=about&subtab=myaccount`}
                        className={`flex w-8 h-8 p-1.5 text-white relative hover:bg-gray-700 hover:bg-opacity-50 rounded-md text-base hover_animate`}
                      >
                        <CustomWalletIcon
                          className={`absolute top-1/2 left-1/2 translate-neg-50-50 object-cover w-5 h-5`}
                        />
                      </Link>
                    </Tooltip>
                  </div>
                  <div className="h-full">
                    <div className="h-6 w-1 bg-neutral-400"></div>
                  </div>
                  <div className="flex items-center gap-2 shrink-0">
                    <div className="">
                      <span className="text-white">username</span>
                    </div>
                    <div className="flex-shrink-0 relative p-0 border-0 rounded-full h-8 w-8">
                      <img
                        className="border-green-500 border-2 object-cover h-full w-full rounded-full"
                        src={
                          'https://nltmarketplace.s3.us-west-1.amazonaws.com/nftgotix/development/user-profile-image/64e492e150e1818c13579912.jpeg' ||
                          profileUrl
                        }
                        alt=""
                        loading="lazy"
                      />
                      {/* add green dot */}
                      {/* {decodedToken?.id && (
                              <span className="w-3 h-3 flex absolute top-0 left-0 bg-green-500 rounded-full border-2 border-neutral-900"></span>
                            )} */}
                    </div>
                    {/* <UserProfileDropdown /> */}
                  </div>
                </div>
              ) : !onlyLogo ? (
                <div className="flex shrink-0 gap-2">
                  {pathname !== '/demo' && (
                    <button
                      onClick={() => navigate('/demo')}
                      className="p-2 bg-landingPurple-60 text-gray-50 rounded-lg hover:bg-landingPurple-70 duration-200 ease-in md:w-24"
                    >
                      Demo
                    </button>
                  )}
                  <button
                    onClick={() => navigate('/user/signup')}
                    className="p-2 bg-landingPurple-60 text-gray-50 rounded-lg hover:bg-landingPurple-70 duration-200 ease-in md:w-24"
                  >
                    Sign Up
                  </button>
                  <button
                    onClick={() => navigate('/user/login')}
                    className="p-2 bg-landingPurple-60 text-gray-50 rounded-lg hover:bg-landingPurple-70 duration-200 ease-in md:w-24"
                  >
                    Log In
                  </button>
                </div>
              ) : (
                <div className="absolute top-4 right-4 z-10">
                  <button
                    type="button"
                    onClick={() => {
                      window.close();
                    }}
                    className="bg-landingPurple-60 border-2 border-landingPurple-60 hover:border-landingPurple-70 text-whiteNew-0 hover:shadow-xl shadow-slate-500 duration-150 ease-in w-8 h-8 height-auto min-auto flex gap-2 roundrem-49 relative p-0"
                  >
                    <AiOutlineClose className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover h-4 w-4" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
