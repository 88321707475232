import { useQuery } from '@tanstack/react-query';
import {
  getVenueType,
  getVenueCategory,
  getSuggestedVenue,
  getVenueCatDetails,
  getVenueSubCatDetails,
  getVenueSubCatDetailsByID,
  getLocationData,
} from '../../../helpers/api/event/venues/venues';
import useSWR from 'swr';
import { useDispatch, useSelector } from 'react-redux';
import { addVenueType } from '../../../features/home_page/homepage.slice';
import { useApiHeader } from '../../useDecodedToken';

export function useVenueType() {
  let { json_header } = useApiHeader();
  let dispatch = useDispatch();
  let PublishedEvents = useSelector((state) => state?.publishedEvents);
  let page_limit = useSelector((state) => state?.homePageEvents);
  let { page, limit } = page_limit;
  const { venueType } = PublishedEvents;

  const fetchVenueTypedata = async () => {
    if (
      (venueType?.data && venueType?.data?.length === 0) ||
      venueType?.needRefetch
    ) {
      return await getVenueType(1, json_header);
    } else {
      if (venueType?.data?.length < limit?.venueType) {
        if (venueType?.data?.length < venueType?.totalCount) {
          return await getVenueType(1, json_header);
        }
      }
    }
  };
  const { data, mutate, isLoading } = useSWR(
    ['venueType', page],
    fetchVenueTypedata,
    {
      // refreshInterval: 60 * 1000,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      keepPreviousData: true,
      onSuccess: (res) => {
        dispatch(
          addVenueType({
            ...PublishedEvents,
            venueType: {
              data: [...venueType?.data, ...res?.data],
              totalCount: res?.data?.length,
              prevPage: 1,
            },
          }),
        );
      },
      // onSuccess(data) {

      // },
    },
  );

  return { data, mutate, isLoading };
}
export function useGetLocation(headers) {
  const { data, refetch } = useQuery({
    queryKey: ['locationData'],
    queryFn: async () => await getLocationData(headers),
    keepPreviousData: true,
    // suspense: true,
  });

  return { data, refetch };
}

export function useVenueCatDetails(id, headers) {
  const { data, refetch } = useQuery({
    queryKey: ['venueType', id],
    queryFn: async () => {
      if (id !== undefined && id !== null && id !== '') {
        return await getVenueCatDetails(id, headers);
      } else {
        return null;
      }
    },
    keepPreviousData: true,
  });

  return { data, refetch };
}

export function useVenueSubCatDetailsById(id, headers) {
  const { data, refetch } = useQuery({
    queryKey: ['venueType', id],
    queryFn: async () => {
      if (id !== '' && id !== undefined) {
        return await getVenueSubCatDetailsByID(id, headers);
      } else {
        return [];
      }
    },
    keepPreviousData: true,
  });

  return { data, refetch };
}

export function useVenueSubCatDetails(id, headers) {
  const { data, refetch } = useQuery({
    queryKey: ['venueType', id],
    queryFn: async () => {
      if (id !== '' && id !== undefined) {
        return await getVenueSubCatDetails(id, headers);
      } else {
        return [];
      }
    },
    // keepPreviousData: true,
  });

  return { data, refetch };
}

export function useVenueCategory(id, page, headers) {
  const {
    data,
    refetch,
    isLoading: loading,
  } = useQuery({
    queryKey: ['venueCategory', id, page],
    queryFn: async () => {
      if (
        id !== undefined &&
        id !== '' &&
        id !== null &&
        page !== undefined &&
        page !== '' &&
        page !== null
      ) {
        return await getVenueCategory(id, page, headers);
      } else {
        return null;
      }
    },
    // keepPreviousData: true,
  });

  return { data, refetch, loading };
}

export function useSuggestedVenue(page1, headers) {
  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: ['suggestedVenue', page1],
    queryFn: async () => {
      return await getSuggestedVenue(page1, headers);
    },
    // suspense: true,
    keepPreviousData: true,
  });

  return { data, refetch, isLoading, isRefetching };
}
