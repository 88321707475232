import { Outlet } from 'react-router-dom';
import Footer from '../../components/common/landing_page_component/Footer';
// import DashboardHeader from '../../components/util/DashboardHeader';
import HeaderLandingPage from '../../components/landing_page_v2/HeaderLandingPage';
import FooterLandingPage from '../../components/landing_page_v2/FooterLandingPage';
// import $ from 'jquery';
import HeaderLandingPageV2 from '../../components/landing_page_v2/HeaderLandingPageV2';
// import HeaderBusinessDashboard from '../../components/landing_page_v2/HeaderBusinessDashboard';
// import { useMemo } from 'react';
// import { getIsVerticalLayout } from '../../utils/util';

export const LandingHeaderLayout = ({ noHeader, headerDark, onlyLogo }) => {
  // const isVerticalLayout = useMemo(() => getIsVerticalLayout(), []);
  // useEffect(() => {
  //   document.body.classList.remove('loader-bg');
  //   return () => {
  //     document.body.classList.remove('loader-bg');
  //   };
  // });
  // $(window).scroll(function () {
  //   if ($(window).scrollTop() >= 50) {
  //     $('.sticky-header').removeClass(
  //       ' relative bg-white top-0 bg-neutral-800 backdrop-blur-3xl bg-opacity-90 w-full',
  //     );
  //     $('.sticky-header').addClass('fixed bg-white top-0 w-full');
  //   } else {
  //     $('.sticky-header').removeClass(
  //       ' bg-white top-0 bg-neutral-800 backdrop-blur-3xl bg-opacity-90 w-full',
  //     );
  //     $('.sticky-header').addClass('relative w-full');
  //   }
  // });
  // $(window)
  //   .resize(function () {
  //     var mobileWidth =
  //       window.innerWidth > 0 ? window.innerWidth : window.width;
  //     var viewport =
  //       mobileWidth > 360
  //         ? 'width=device-width, initial-scale=1.0'
  //         : 'width=1200';
  //     $('meta[name=viewport]').attr('content', viewport);
  //   })
  //   .resize();

  // var viewMode = getCookie('view-mode');
  // const viewport = document.querySelector('meta[name=viewport]');
  // if (isVerticalLayout) {
  // viewport.setAttribute('content', 'width=1300');
  // } else {
  //   viewport.setAttribute(
  //     'content',
  //     'width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no',
  //   );
  // }
  return (
    <>
      {/* <DashboardHeader /> */}
      <div className="card_table">
        {!noHeader && (
          <HeaderLandingPageV2
            logoClassName={'text-white'}
            btnClassName={'btnDark'}
            landingPage={'transparent'}
            headerDark={headerDark}
            onlyLogo={onlyLogo}
          />
        )}
        <Outlet />
        <FooterLandingPage lightFooter />
      </div>

      {/* <FooterLandingPage /> */}
    </>
  );
};
export const LandingHeaderLayoutV2 = ({ noHeader, headerDark, onlyLogo }) => {
  // useEffect(() => {
  //   document.body.classList.add('loader-bg');
  //   return () => {
  //     document.body.classList.add('loader-bg');
  //   };
  // });

  // $(window).scroll(function () {
  //   if ($(window).scrollTop() >= 50) {
  //     $('.sticky-header').removeClass(
  //       ' bg-neutral-800 backdrop-blur-3xl bg-opacity-90 w-auto relative bg-white',
  //     );
  //     $('.sticky-header').addClass(
  //       'fixed bg-neutral-800 backdrop-blur-3xl bg-opacity-90 w-full',
  //     );
  //   } else {
  //     $('.sticky-header').removeClass(
  //       ' bg-neutral-800 backdrop-blur-3xl bg-opacity-90 w-auto relative bg-white',
  //     );
  //     $('.sticky-header').addClass('top-0');
  //   }
  // });

  // const viewport = document.querySelector('meta[name=viewport]');
  // viewport.setAttribute(
  //   'content',
  //   'width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no',
  // );
  return (
    <>
      <div className="tourActive">
        {!noHeader && (
          <HeaderLandingPage
            logoClassName={'text-white'}
            btnClassName={'btnDark'}
            landingPage={'transparent'}
            headerDark={headerDark}
            onlyLogo={onlyLogo}
          />
        )}
        <Outlet />
        <FooterLandingPage />
      </div>
    </>
  );
};

// export const HeaderBusinessDashbaordLayout = ({
//   noHeader,
//   headerDark,
//   onlyLogo,
//   demo = false,
// }) => {
//   // const viewport = document.querySelector('meta[name=viewport]');
//   // viewport.setAttribute(
//   //   'content',
//   //   'width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no',
//   // );
//   return (
//     <>
//       <div className="tourActive">
//         {!noHeader && (
//           <HeaderBusinessDashboard
//             logoClassName={'text-white'}
//             btnClassName={'btnDark'}
//             landingPage={'transparent'}
//             darkTheme={headerDark}
//             onlyLogo={onlyLogo}
//             hideCompo={true}
//             demo={demo}
//           />
//         )}
//         <Outlet />
//         <FooterLandingPage />
//       </div>
//     </>
//   );
// };

export const HeaderLandingPageLayout = ({
  noHeader,
  headerDark,
  onlyLogo,
  demo = false,
}) => {
  // const viewport = document.querySelector('meta[name=viewport]');
  // viewport.setAttribute(
  //   'content',
  //   'width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no',
  // );
  return (
    <>
      <div className="">
        {!noHeader && (
          <HeaderLandingPageV2
            logoClassName={'text-white'}
            btnClassName={'btnDark'}
            landingPage={'transparent'}
            darkTheme={headerDark}
            onlyLogo={onlyLogo}
            hideCompo={true}
            demo={demo}
          />
        )}
        <Outlet />
        <FooterLandingPage />
      </div>
    </>
  );
};
export const MainHeaderLayout = () => {
  // useEffect(() => {
  //   document.body.classList.remove('loader-bg');
  //   return () => {
  //     document.body.classList.remove('loader-bg');
  //   };
  // });

  // $(window).scroll(function () {
  //   if ($(window).scrollTop() >= 50) {
  //     $('.sticky-header').removeClass('relative');
  //     $('.sticky-header').addClass('fixed bg-white top-0');
  //   } else {
  //     $('.sticky-header').removeClass(
  //       'fixed bg-white top-0 bg-neutral-800 backdrop-blur-3xl bg-opacity-90 w-full',
  //     );
  //     $('.sticky-header').addClass('relative');
  //   }
  // });

  // const viewport = document.querySelector('meta[name=viewport]');
  // viewport.setAttribute(
  //   'content',
  //   'width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no',
  // );
  return (
    <>
      <div>
        {/* <DashboardHeader
          logoClassName={'text-white'}
          btnClassName={'btnDark'}
          landingPage={'transparent'}
        />{' '} */}
        <HeaderLandingPageV2
          logoClassName={'text-white'}
          btnClassName={'btnDark'}
          landingPage={'transparent'}
        />
        <Outlet />
        <Footer />
      </div>
    </>
  );
};
