import React, { memo, useCallback } from 'react';
// import Image from '../../../common/Image';
// import itm1 from '../../../../assets/images/background/cartItem-1.png';
import {
  CartIcon,
  CrownIcon,
  CustomCalanderIcon,
  // CustomLocationsIcon,
  LocationIcon,
} from '../../../util/CustomIcons';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '../../../common/Tooltip';
import {
  DATEFORMAT,
  getSelectedTicketPrice,
  truncate,
} from '../../../../utils/util';
import moment from 'moment';
import NoDataFound from '../../../common/NoDataFound';
import CurrencyFormat from 'react-currency-format';
// import { DeleteAllSeats } from '../../../../features/seatmap/seatmap.slice';
import { cartData } from '../../../../features/cart/cart.slice';
import { Link } from 'react-router-dom';
import { updateCartDrawer } from '../../../../features/home_page/homepage.slice';
import { MdDelete } from 'react-icons/md';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useMutation } from '@tanstack/react-query';
import { useApiHeader } from '../../../../hooks/useDecodedToken';
import {
  getSeatConfirmation,
  getVirtualConfirmation,
} from '../../../../hooks/event/seat_mapping/useSeatMapping';
import { useCartCheckOut } from '../../../common/landing_page_component/check_out/useCartCheckOut';
// import { AiOutlineIssuesClose } from 'react-icons/ai';
import { afterBorderTop2 } from '../../../common/DefaultClasses';
import { Transition } from '@headlessui/react';
import Image from '../../../common/Image';

function CartItemsCard({ darkTheme }) {
  let dispatch = useDispatch();
  // let selectedSeats = useSelector((state) => state.selectedSeats);
  const cartselectedSeats = useSelector(cartData);
  const [showTicket, setShowTickets] = React.useState(false);
  const { json_header } = useApiHeader();

  let { cartInpersonData, cartvirtualData, cartDataType, slug, selectedEvent } =
    cartselectedSeats;

  function virtualSeatConfirmation() {
    const categoryLengths = {};

    cartvirtualData?.[slug]?.virtualData[0]?.type?.forEach(({ category }) => {
      categoryLengths[category] = (categoryLengths[category] || 0) + 1;
    });

    const finalCategory = Object.entries(categoryLengths).map(
      ([category, quantity]) => ({
        category,
        quantity,
      }),
    );
    let event = cartvirtualData?.selectedEvent?._id;
    let user = cartvirtualData?.selectedEvent?.user_id;
    let body = {
      category: finalCategory,
      event_id: event,
      user_id: user,
      type: 'remove',
    };

    return getVirtualConfirmation(body, json_header);
  }

  const virtualConfirmMutation = useMutation(virtualSeatConfirmation, {
    onSuccess: (data) => {
      ClearCartData();
      dispatch(updateCartDrawer(false));
    },
    onError: (data) => {
      console.log(data);
    },
  });

  function seatConfirmation() {
    const seatIDs = cartInpersonData?.[slug].map((obj) => obj.seat_id);
    const venueID = selectedEvent?.venue_id;
    let body = {
      seat_id: seatIDs,
      venue_id: venueID,
      type: 'remove',
    };
    return getSeatConfirmation(body, json_header);
  }

  const seatConfirmMutation = useMutation(seatConfirmation, {
    onSuccess: (data) => {
      ClearCartData();
      dispatch(updateCartDrawer(false));
    },
    onError: (data) => {},
  });

  const getData = useCallback(() => {
    if (cartDataType === 'Virtual') {
      if (cartvirtualData.hasOwnProperty(slug)) {
        return cartvirtualData[slug]?.virtualData?.[0]?.type;
      }
    } else {
      if (cartInpersonData.hasOwnProperty(slug)) {
        return cartInpersonData[slug];
      }
    }
  }, [cartvirtualData, cartInpersonData, slug, cartDataType]);

  let { ClearCartData } = useCartCheckOut();

  const card = useCallback(() => {
    if (
      Object.entries(cartvirtualData)?.length > 0 ||
      Object.entries(cartInpersonData)?.length > 0
    ) {
      let {
        profile_image,
        // banner_image,
        name,
        start_date_time,
        venue,
        event_type,
      } = selectedEvent || {};

      return (
        <div
          className={`flex flex-col rounded-md overflow-hidden border-1 max-h-[100svh] dark:border-level-2 shadow-md shadow-level-4 dark:bg-color-base-1 bg-slate-100 border-gray-200`}
          style={{ maxHeight: 'calc(100svh - 150px)' }}
        >
          <div className="flex justify-between flex-wrap flex-md-nowrap p-2 sticky top-0 shrink-0">
            <div className="flex gap-3 max-w-[100%] md:max-w-[70%] shrink-0">
              <div className="shrink-0 h-[123px] w-20 rounded-md overflow-hidden">
                {/* <EventImageVideo
                  data={profile_image || banner_image}
                  alt="profile picture"
                  className={'w-full h-full object-cover'}
                  loading="lazy"
                  // width="384px"
                  // height="512px"
                /> */}
                <Image
                  src={
                    profile_image?.url
                      ? profile_image?.url
                      : profile_image?.[0]?.url
                  }
                  alt={'profile picture'}
                  className={'w-full h-full object-cover'}
                />
              </div>
              <div
                className={`flex flex-col justify-between gap-2 dark:text-whiteNew-0 text-neutral-950`}
              >
                <Link to={`/event-detail/${slug}`}>
                  <h2
                    className="font-semibold text-[14px] flex"
                    onClick={() => {
                      dispatch(updateCartDrawer(false));
                    }}
                  >
                    <Tooltip placement={'top'} title={name}>
                      {truncate(name, 18) || '-'}
                    </Tooltip>
                  </h2>
                </Link>

                <h2 className="font-semibold text-[14px]">
                  Quantity :{' '}
                  {cartDataType === 'Virtual'
                    ? cartvirtualData.hasOwnProperty(slug)
                      ? cartvirtualData[slug]?.virtualData?.[0]?.type?.length
                      : 0
                    : cartInpersonData.hasOwnProperty(slug)
                    ? cartInpersonData[slug]?.length
                    : 0}
                </h2>
                <div className="flex">
                  <span className="text-xs rounded-md bg-green-400 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 p-0.5 px-1.5">
                    {cartDataType !== 'Virtual' ? 'In-Person' : 'Virtual'}
                  </span>
                </div>
                <div>
                  <div className="flex pb-1">
                    <CustomCalanderIcon className={'flex-shrink-0'} />
                    <span className="text-xs ml-1">
                      {moment(
                        moment.utc(start_date_time).local(),
                        'YYYY-MM-DD hh:mm a',
                      ).format(DATEFORMAT) || '-'}
                    </span>
                  </div>
                  <div className="flex pb-1">
                    <LocationIcon className={'shrink-0'} />
                    <span className="text-xs ml-1">
                      {venue && event_type !== 'Virtual' ? (
                        <span className="flex flex-col">
                          <span>
                            {venue[0]?.name?.split('-')
                              ? truncate(venue[0]?.name?.split('-')?.[0], 25)
                              : truncate(venue[0]?.name, 25)}
                          </span>
                        </span>
                      ) : event_type === 'both' ? (
                        'In-Person & Virtual Event'
                      ) : event_type === 'Virtual' ? (
                        'Virtual'
                      ) : (
                        'N/A'
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full md:flex-col justify-between md:items-end mt-2.5 md:mt-0 px-2 md:px-0 items-center">
              <span
                className={`font-semibold text-[16px] dark:text-whiteNew-0 text-neutral-950`}
              >
                <CurrencyFormat
                  disabled={true}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  value={
                    getSelectedTicketPrice(
                      Object.values(cartvirtualData)?.[0] || {
                        data: Object.values(cartInpersonData)?.[0],
                      },
                    )?.toFixed(2) || 0
                  }
                  decimalScale={2}
                  fixedDecimalScale
                  className={`font-normal`}
                />
              </span>
              <div className="flex items-center justify-end gap-1.5">
                <Tooltip title={'View Ticket'}>
                  <Link
                    to={`#`}
                    onClick={() => {
                      // dispatch(updateCartDrawer(false));
                      setShowTickets(!showTicket);
                    }}
                    className="bg-landingPurple-60 hover:bg-landingPurple-70 text-whiteNew-0 duration-150 ease-in p-[4px] leading-tight rounded-[4px]"
                  >
                    {showTicket ? (
                      <FaRegEyeSlash className="text-whiteNew-0" />
                    ) : (
                      <FaRegEye className="text-whiteNew-0" />
                    )}

                    {/* Use this icon when details open */}
                    {/* <FaRegEyeSlash className="text-whiteNew-0" /> */}
                  </Link>
                </Tooltip>
                <Tooltip title={'Remove'}>
                  <button
                    disabled={
                      seatConfirmMutation.isLoading ||
                      virtualConfirmMutation.isLoading
                    }
                    className="bg-rose-600 hover:bg-rose-700 text-whiteNew-0 duration-150 ease-in p-[4px] leading-tight rounded-[4px]"
                    onClick={() => {
                      if (Object.keys(cartInpersonData).length > 0) {
                        seatConfirmMutation.mutate();
                      }
                      if (Object.keys(cartvirtualData).length > 0) {
                        virtualConfirmMutation.mutate();
                      }
                      // setTimeout(() => {

                      // }, 200);
                    }}
                  >
                    <MdDelete />
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>

          <Transition
            show={showTicket}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            className={'h-full'}
          >
            <div
              className={`${afterBorderTop2} shrink-0 overflow-y-auto scrollCss dark:after:bg-neutral-600 after:bg-neutral-200 dark:bg-neutral-900 bg-slate-100 w-full relative p-2`}
              style={{ maxHeight: 'calc(100svh - 310px)' }}
            >
              <div className="space-y-1.5 divide-y dark:divide-neutral-600 divide-neutral-200">
                {getData()?.map((item, idx) => (
                  <div
                    key={idx}
                    className="flex gap-2 justify-between items-center py-1.5"
                  >
                    <div className="w-full max-w-sm">
                      <p className="dark:text-gray-200 text-gray-800 text-sm font-light mb-0">
                        <span className="flex items-center gap-1">
                          <CrownIcon />
                          <strong>
                            {cartDataType === 'Virtual'
                              ? item?.name
                              : item?.category}
                          </strong>
                        </span>
                        {/* <span className="text-white">-</span> */}
                        {/* <span className="py-0.5 px-2 bg-resale-900 rounded-lg text-resale-800 text-xs ml-2 mb-1">
                      Resale
                    </span> */}
                      </p>

                      {/* <span className="text-neutral-400 text-xs">
                    <span className="block">Quantity: 10</span>
                  </span> */}
                      {cartDataType !== 'Virtual' && (
                        <div className="flex gap-2">
                          <div className="flex justify-center shrink-0">
                            <div className="flex items-center gap-1">
                              <span className="dark:text-gray-300 text-zinc-800">
                                Section:
                              </span>
                              <span className="flex dark:text-whiteNew-0 text-zinc-900">
                                <span className="font-medium">
                                  {item?.section}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="flex justify-center shrink-0">
                            <div className="flex items-center gap-1">
                              <span className="dark:text-gray-300 text-zinc-800">
                                Row No.:
                              </span>
                              <span className="flex dark:text-whiteNew-0 text-zinc-900">
                                <span className="font-medium">{item?.row}</span>
                              </span>
                            </div>
                          </div>
                          <div className="flex justify-center shrink-0">
                            <div className="flex items-center gap-1 shrink-0">
                              <span className="dark:text-gray-300 text-zinc-800">
                                Seat No.
                              </span>
                              <span className="flex dark:text-whiteNew-0 text-zinc-900">
                                <span className="font-medium">
                                  {item?.seat_number}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <h6 className="dark:text-whiteNew-0 text-zinc-800 text-base shrink-0">
                      <CurrencyFormat
                        disabled={true}
                        displayType="text"
                        thousandSeparator
                        prefix="$"
                        value={item?.resale_price || item?.price || 0}
                        decimalScale={2}
                        fixedDecimalScale
                        className={`font-normal`}
                      />
                    </h6>
                  </div>
                ))}
              </div>
            </div>
          </Transition>
        </div>
      );
    } else {
      return (
        <div className="mt-2 h-full flex items-center justify-center w-full">
          <NoDataFound title={'Your Cart is Empty.'} icon={<CartIcon />} />
        </div>
      );
    }
  }, [
    cartInpersonData,
    cartvirtualData,
    cartDataType,
    selectedEvent,
    showTicket,
    dispatch,
    seatConfirmMutation,
    slug,
    virtualConfirmMutation,
    getData,
  ]);

  const CartItem = memo(card);

  return (
    <>
      <CartItem />
    </>
  );
}

export default CartItemsCard;
