import { Drawer } from 'antd';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Image from '../../components/common/Image';
import { useDrawerScreens } from './hooks/useDrawer';
import { useSelector } from 'react-redux';
import { useStreamLounge } from './hooks/useStreamLounge';
import {
  getIsVerticalLayout,
  openUrlExplorer,
  openUrlExplorerAddress,
  openUrlExplorerHash,
  shortenAddress,
  truncate,
  updateAccUserName,
} from '../../utils/util';
import useDecodedToken from '../../hooks/useDecodedToken';
import moment from 'moment';
import { ExternalLink } from '../../components/util/CustomIcons';
import { getTicketStatusWithoutBadge } from '../../components/dashboard/order_ticket/DrawerUtils';

const NFTDetailDrawer = () => {
  const { portal_state, onCloseNFTDetails } = useDrawerScreens();
  // const { nft_data_blockchain } = useStreamAPI()
  const { event_details } = useStreamLounge();
  const { stream_nft_details } = useSelector((s) => s.stream);
  const decodedToken = useDecodedToken();
  const { nft_metadata, mint_img } = stream_nft_details;
  const isVerticalLayout = useMemo(() => getIsVerticalLayout(), []);

  const mint_details = useMemo(() => {
    return nft_metadata
      ? nft_metadata?.mint_details
      : stream_nft_details?.mint_details;
  }, [nft_metadata, stream_nft_details?.mint_details]);

  // const data = useMemo(() => {

  // }, [])
  return (
    <Drawer
      placement={isVerticalLayout ? 'bottom' : 'right'}
      onClose={onCloseNFTDetails}
      open={portal_state.nft_details_drawer}
      title={
        <>
          <div className="flex justify-between items-center gap-2 w-full">
            <span className="flex">
              <span className="flex w-1 min-h-[30px] bg-neutral-700"></span>
            </span>
            <div className="flex justify-between items-center shrink-0 w-full ">
              <span className="flex text-white font-medium">
                {truncate(event_details?.name) ?? ''}
              </span>
            </div>
          </div>
        </>
      }
      width="25%"
      height={'90%'}
      zIndex={9999}
      className="!bg-black text-white cstm-drwr"
      footer={
        <div className="w-full flex justify-end">
          <button
            onClick={() => openUrlExplorer(nft_metadata?.token_id)}
            className="text-gray-50 md:text-sm border-indigo-500 py-2 px-3.5 md:px-7 bg-brown-900 flex items-center gap-1 w-full lg:w-auto justify-center "
          >
            View More Info
            <ExternalLink />
          </button>
        </div>
      }
    >
      <div>
        <Row>
          <Col md={12}>
            <div>
              <div>
                <div className="relative w-full rounded-md overflow-hidden">
                  {/* <div className="cardOverlay"></div> */}
                  {/* <div className="absolute inset-0 w-full h-full overflow-hidden rounded-xl"> */}
                  <Image
                    loading="lazy"
                    src={mint_img?.url ?? ''}
                    alt="event-img"
                    className="w-full h-full object-contain childCardHover"
                  />
                  {/* </div> */}
                </div>
                <div className="pt-3 p-1.5">
                  <ul className="divide-y divide-slate-600 mt-3">
                    <article className="flex items-start py-2.5">
                      <div className="min-w-0 relative flex-auto">
                        <div className="text-base text-slate-100 font-medium">
                          Information
                        </div>
                      </div>
                    </article>
                    <article className="flex items-start py-2.5 w-full">
                      <div className="min-w-0 relative flex justify-between w-full">
                        <dl className="flex flex-wrap text-sm leading-6 text-slate-100">
                          <dd>Creator</dd>
                        </dl>
                        <dl className="flex flex-wrap text-sm leading-6 font-medium text-whiteNew-0">
                          <dd
                            onClick={() =>
                              openUrlExplorerAddress(mint_details?.from)
                            }
                            className="flex items-center gap-1 hover:cursor-pointer hover:text-landingPurple-60"
                          >
                            {shortenAddress(mint_details?.from, 7)}
                            <ExternalLink />
                          </dd>
                        </dl>
                      </div>
                    </article>
                    <article className="flex items-start py-2.5 w-full">
                      <div className="min-w-0 relative flex justify-between w-full">
                        <dl className="flex flex-wrap text-sm leading-6 text-slate-100">
                          <dd>Chain</dd>
                        </dl>
                        <dl className="flex flex-wrap text-sm leading-6 font-medium text-whiteNew-0">
                          <dd>ARTC Testnet</dd>
                        </dl>
                      </div>
                    </article>
                    <article className="flex items-start py-2.5 w-full">
                      <div className="min-w-0 relative flex justify-between w-full">
                        <dl className="flex flex-wrap text-sm leading-6 text-slate-100">
                          <dd>Owned By</dd>
                        </dl>
                        <dl className="flex flex-wrap text-sm leading-6 font-medium text-whiteNew-0">
                          <dd>
                            {updateAccUserName(
                              stream_nft_details?.nftCurrentUser,
                            ) ??
                              decodedToken?.username ??
                              ''}
                          </dd>
                        </dl>
                      </div>
                    </article>
                    <article className="flex items-start py-2.5 w-full">
                      <div className="min-w-0 relative flex justify-between w-full">
                        <dl className="flex flex-wrap text-sm leading-6 text-slate-100">
                          <dd>Hash</dd>
                        </dl>
                        <dl className="flex flex-wrap text-sm leading-6 font-medium text-whiteNew-0">
                          <dd
                            onClick={() =>
                              openUrlExplorerHash(mint_details?.hash)
                            }
                            className="flex items-center gap-1 hover:cursor-pointer hover:text-landingPurple-60"
                          >
                            {shortenAddress(mint_details?.hash, 7)}
                            <ExternalLink />
                          </dd>
                        </dl>
                      </div>
                    </article>
                    <article className="flex items-start py-2.5 w-full">
                      <div className="min-w-0 relative flex justify-between w-full">
                        <dl className="flex flex-wrap text-sm leading-6 text-slate-100">
                          <dd>Minted At</dd>
                        </dl>
                        <dl className="flex flex-wrap text-sm leading-6 font-medium text-whiteNew-0">
                          <dd>
                            {moment(
                              moment.utc(nft_metadata?.minted_at).local(),
                              'YYYY-MM-DD hh:mm a',
                            ).format('MM/DD/YY | HH:mm')}
                          </dd>
                        </dl>
                      </div>
                    </article>
                    <article className="flex items-start py-2.5 w-full">
                      <div className="min-w-0 relative flex justify-between w-full">
                        <dl className="flex flex-wrap text-sm leading-6 text-slate-100">
                          <dd>Status</dd>
                        </dl>
                        <dl className="flex flex-wrap text-sm leading-6 font-medium text-whiteNew-0">
                          <dd>
                            {getTicketStatusWithoutBadge(stream_nft_details)}
                          </dd>
                        </dl>
                      </div>
                    </article>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Drawer>
  );
};

export default NFTDetailDrawer;
