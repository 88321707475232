import { useDispatch, useSelector } from 'react-redux';
import {
  ARTIST_LIST,
  CATEGORIES,
  EVENT_TYPE,
  FILTER_CONST,
  STREAMING_STATUS,
  TOUR,
  initialFilterParams,
  updateAllEvent,
  updateAllEventsFilterCount,
  updateFilter,
  updateFilterLoading,
  updateFilterPills,
} from '../../../../features/event/allevent.slice';
import { useEffect, useMemo, useRef } from 'react';
import { useCallback } from 'react';
import {
  all_events_filter_count,
  all_events_list_filter,
} from '../../../../helpers/api/event/event';
import { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useOnceCall } from '../../../../hooks/useOnlyOnce';
import { useMutation } from '@tanstack/react-query';
import { Form, Select } from 'antd';
import {
  updateFilterCount,
  updateForm,
  updatePopover,
  updateSortingForm,
  updateSortingPopover,
  updateisFiltered,
  updateisSorted,
} from '../../../../features/event/event.slice';
import { BsChevronDown } from 'react-icons/bs';

export const useAllEventsFilter = () => {
  const dispatch = useDispatch();
  const {
    filterParams,
    // all_artist,
    all_categories,
    filter_pills,
    // all_event_filter_count
  } = useSelector((s) => s.allEventReducer);
  const {
    filter_mutation,
    fetchEvents,
    all_events_count_mutation,
    all_filter_count,
  } = useAllEventFilterAction();
  const [form] = Form.useForm();

  useOnceCall(async () => {
    await dispatch(updateFilter(initialFilterParams));
    await dispatch(updateFilterPills({}));
    await fetchEvents({});
    await all_events_count_mutation();
  });

  const getCountForEachFilter = useCallback(
    (key, count_for) => {
      if (key in all_filter_count) {
        if (key === 'Artists')
          return (
            all_filter_count[key]?.find((artist) =>
              Boolean(artist?._id === count_for),
            )?.total ?? 0
          );
        if (count_for in all_filter_count[key]) {
          return all_filter_count[key][count_for];
        }
        return 0;
      }
      return 0;
    },
    [all_filter_count],
  );

  const statusOptions = useMemo(() => {
    const status = [
      'Live',
      'Streaming Now',
      'Live/Streaming Now',
      'Concluded',
      'Coming Soon',
    ];
    return status?.map((item, index) => ({
      label: item === 'Concluded' ? 'Game Over/Ended' : item, // desc
      _id: index + 1,
      key: FILTER_CONST[STREAMING_STATUS],
      count: getCountForEachFilter('status', item),
      action: (checked, filteritem) => {
        const arr = filterParams.status;
        if (checked) {
          dispatch(
            updateFilter({
              ...filterParams,
              status: [...arr, filteritem],
              page: 1,
            }),
          );
        } else {
          const streamingstatus = filterParams.status?.filter(
            (item) => item !== filteritem,
          );
          dispatch(
            updateFilter({
              ...filterParams,
              status: [...streamingstatus],
              page: 1,
            }),
          );
        }
      },
    }));
  }, [dispatch, filterParams, getCountForEachFilter]);

  const eventOptions = useMemo(() => {
    const event_type = ['Physical', 'Virtual', 'Both'];
    return event_type?.map((item, index) => ({
      label: index === 0 ? 'In-Person' : item, // desc
      _id: index === 2 ? 'both' : item,
      key: FILTER_CONST[EVENT_TYPE],
      count: getCountForEachFilter(
        'Event Type',
        index === 0 ? 'In-Person' : item,
      ),
      action: (checked, filteritem) => {
        let arr = filterParams.event_type;
        if (checked) {
          dispatch(
            updateFilter({
              ...filterParams,
              event_type: [...arr, filteritem],
              page: 1,
            }),
          );
        } else {
          let event_type = filterParams.event_type?.filter(
            (item) => item !== filteritem,
          );
          dispatch(
            updateFilter({
              ...filterParams,
              event_type: [...event_type],
              page: 1,
            }),
          );
        }
      },
    }));
  }, [dispatch, filterParams, getCountForEachFilter]);

  const artistOptions = useMemo(() => {
    if (all_filter_count?.Artists?.length) {
      return all_filter_count?.Artists?.map((artist) => ({
        label: artist?.name,
        _id: artist?.slug, // desc
        key: FILTER_CONST[ARTIST_LIST],
        // count: getCountForEachFilter('Artists', artist?._id),
        count: artist?.total,
        action: (checked, filteritem) => {
          let arr = filterParams.artists;
          if (checked) {
            dispatch(
              updateFilter({
                ...filterParams,
                artists: [...arr, filteritem],
                page: 1,
              }),
            );
          } else {
            let artistarr = filterParams.artists?.filter(
              (item) => item !== filteritem,
            );
            dispatch(
              updateFilter({
                ...filterParams,
                artists: [...artistarr],
                page: 1,
              }),
            );
          }
        },
      }));
    }
  }, [dispatch, filterParams, all_filter_count]);

  const categoryOptions = useMemo(
    () =>
      [...all_categories]?.map((category) => ({
        label: category?.name, // desc
        _id: category?.name,
        key: FILTER_CONST[CATEGORIES],
        count: getCountForEachFilter('Categories', category?.name),
        action: (checked, filteritem) => {
          let arr = filterParams.event_category;
          if (checked) {
            dispatch(
              updateFilter({
                ...filterParams,
                event_category: [...arr, filteritem],
                page: 1,
              }),
            );
          } else {
            let categoriesarr = filterParams.event_category?.filter(
              (item) => item !== filteritem,
            );
            dispatch(
              updateFilter({
                ...filterParams,
                event_category: [...categoriesarr],
                page: 1,
              }),
            );
          }
        },
      })),
    [all_categories, dispatch, filterParams, getCountForEachFilter],
  );

  // const locationOptions = useMemo(() => [
  //   {
  //     label: 'New York', // desc
  //     action: () => { },
  //   },
  //   {
  //     label: 'Boston', // asc
  //     action: () => { },
  //   },
  // ], []);

  const priceOptions = useMemo(
    () => ({ label: 'Price', _id: 'Price', type: 'slider' }),
    [],
  );
  const filter_labels = useMemo(
    () => [
      'Status',
      'Event Type',
      'Artist List',
      'Categories',
      'Price',
      // 'Location'
    ],
    [],
  );

  const all_filters_pills = useMemo(() => {
    const filterPills = { ...filter_pills };
    delete filterPills['page'];
    delete filterPills['limit'];
    delete filterPills['search_key'];
    delete filterPills['sort_by'];
    Object.entries(filterPills).map(([key, value]) => {
      if (
        ['status', 'event_type', 'artists', 'event_category', 'tour'].includes(
          key,
        )
      ) {
        if (key === 'status') {
          filterPills[key] = statusOptions
            .filter((el) => String(value).split(',').includes(String(el._id)))
            .map((ele) => ({
              label: ele.label,
              type: key,
              id: String(ele._id),
            }));
        }
        if (key === 'event_type') {
          filterPills[key] = eventOptions
            .filter((el) => String(value).split(',').includes(String(el._id)))
            .map((ele) => ({
              label: ele.label,
              type: key,
              id: String(ele._id),
            }));
        }
        if (key === 'artists') {
          filterPills[key] = artistOptions
            .filter((el) => String(value).split(',').includes(String(el._id)))
            .map((ele) => ({
              label: ele.label,
              type: key,
              id: String(ele._id),
            }));
        }
        if (key === 'event_category') {
          filterPills[key] = categoryOptions
            .filter((el) => String(value).split(',').includes(String(el._id)))
            .map((ele) => ({
              label: ele.label,
              type: key,
              id: String(ele._id),
            }));
        }
        if (key === 'tour') {
          const tour_key = {
            1: 'Tour Events',
            2: 'Single Events',
          };
          filterPills[key] = [{ label: tour_key[value], type: key, id: value }];
        }
      }
      return false;
    });
    return filterPills;
  }, [
    filter_pills,
    artistOptions,
    statusOptions,
    eventOptions,
    categoryOptions,
  ]);

  const removeSinglePill = useCallback(
    async ({ _id, label, type }) => {
      if (type === FILTER_CONST[STREAMING_STATUS]) {
        const arr = filterParams[FILTER_CONST[STREAMING_STATUS]]?.filter(
          (item) => String(item) !== _id,
        );
        await dispatch(
          updateFilter({
            ...filterParams,
            status: [...arr],
            page: 1,
          }),
        );
        dispatch(updateFilterLoading(true));
        return filter_mutation.mutate();
      }

      if (type === FILTER_CONST[EVENT_TYPE]) {
        const arr = filterParams[FILTER_CONST[EVENT_TYPE]]?.filter(
          (item) => item !== _id,
        );
        dispatch(
          updateFilter({
            ...filterParams,
            event_type: [...arr],
            page: 1,
          }),
        );
        dispatch(updateFilterLoading(true));
        return filter_mutation.mutate();
      }

      if (type === FILTER_CONST[CATEGORIES]) {
        const arr = filterParams[FILTER_CONST[CATEGORIES]]?.filter(
          (item) => item !== _id,
        );
        dispatch(
          updateFilter({
            ...filterParams,
            event_category: [...arr],
            page: 1,
          }),
        );
        dispatch(updateFilterLoading(true));
        return filter_mutation.mutate();
      }

      if (type === FILTER_CONST[ARTIST_LIST]) {
        const arr = filterParams[FILTER_CONST[ARTIST_LIST]]?.filter(
          (item) => item !== _id,
        );
        dispatch(
          updateFilter({
            ...filterParams,
            artists: [...arr],
            page: 1,
          }),
        );
        dispatch(updateFilterLoading(true));
        return filter_mutation.mutate();
      }
      if (type === FILTER_CONST[TOUR]) {
        // const arr = filterParams[FILTER_CONST[TOUR]]?.filter( // always be single element array
        //   (item) => item !== _id,
        // );
        dispatch(updatePopover(false));
        dispatch(updateisFiltered(false));
        dispatch(updateForm(false));
        dispatch(
          updateFilter({
            ...filterParams,
            tour: undefined,
            page: 1,
          }),
        );
        dispatch(updateFilterLoading(true));
        return filter_mutation.mutate();
      }
    },
    [dispatch, filterParams, filter_mutation],
  );

  const filter_options = useMemo(
    () => [
      statusOptions,
      eventOptions,
      artistOptions,
      categoryOptions,
      priceOptions,
      all_filters_pills,
      // locationOptions
    ],
    [
      statusOptions,
      eventOptions,
      artistOptions,
      categoryOptions,
      priceOptions,
      all_filters_pills,
    ],
  );

  const obj = useMemo(() => {
    return filter_labels.reduce((accumulator, key, index) => {
      return { ...accumulator, [key]: filter_options[index] };
    }, {});
  }, [filter_labels, filter_options]);

  return {
    obj,
    all_filters_pills,
    removeSinglePill,
    AntdForm: form,
  };
};

export function useAllEventFilterAction() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { filterParams, all_event, all_event_count } = useSelector(
    (state) => state.allEventReducer,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const limit_ref = useRef(null);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const valid_params = useMemo(() => {
    const temp_params = { ...filterParams };
    for (const key in temp_params) {
      if (
        temp_params[key] == null ||
        (Array.isArray(temp_params[key]) && !temp_params[key]?.length)
      ) {
        delete temp_params[key];
      }
    }
    return new URLSearchParams(temp_params).toString();
  }, [filterParams]);

  const filter_mutation = useMutation(all_events_list_filter, {
    onSuccess: (res) => {
      const { data, count } = res;
      dispatch(updateAllEvent({ array: data, count: count }));
      dispatch(
        updateFilterPills(
          Object.fromEntries(new URLSearchParams(location.search).entries()),
        ),
      );
      dispatch(updateFilterLoading(false));
    },
  });

  const { data: all_filter_count, mutate } = useMutation(
    all_events_filter_count,
    {
      onSuccess: (res) => {
        updateAllEventsFilterCount(res);
        // dispatch(update)
      },
    },
  );

  const fetchEvents = useCallback(
    async ({ should_paginate = false }) => {
      if (isLoading) return null;
      if (should_paginate) {
        setIsLoading(true);
        await dispatch(
          updateFilter({ ...filterParams, page: filterParams?.page + 1 }),
        );
        const { data, count } = await all_events_list_filter();
        if (Array.isArray(data)) {
          // if (data.length) {
          // const arr = all_event?.concat(data)

          const arr = [...all_event, ...data];
          await dispatch(updateAllEvent({ array: arr, count: count }));

          setIsLoading(false);
          setShowSkeleton(false);
          // }
        }
      } else {
        await dispatch(updateFilterLoading(true));
        filter_mutation.mutate();
      }
    },
    [dispatch, all_event, isLoading, filterParams, filter_mutation],
  );

  const clear_filter = useCallback(async () => {
    await dispatch(updateFilter(initialFilterParams));
    return await fetchEvents({});
  }, [dispatch, fetchEvents]);

  const reset_filter = useCallback(async () => {
    return await dispatch(updateFilter(initialFilterParams));
  }, [dispatch]);

  const apply_filter = useCallback(async () => {
    await dispatch(updateFilter({ ...filterParams, page: 1 }));
    return await fetchEvents({});
  }, [fetchEvents, dispatch, filterParams]);

  const isFilterTouched = useMemo(() => {
    const searchObj = Object.fromEntries(
      new URLSearchParams(location.search).entries(),
    );
    delete searchObj['page'];
    delete searchObj['limit'];
    delete searchObj['search_key'];
    delete searchObj['sort_by'];

    const temp = Object.keys(filterParams).some((key) => {
      return Boolean(key in searchObj);
    });

    return temp;
  }, [filterParams, location.search]);

  const loadMore = useCallback(async () => {
    if (isLoading || showSkeleton) return null;
    return setTimeout(
      async () => await fetchEvents({ should_paginate: true }),
      1000,
    );
  }, [fetchEvents, isLoading, showSkeleton]);

  const hasMore = useMemo(() => {
    return all_event?.length < all_event_count;
  }, [all_event?.length, all_event_count]);

  return useMemo(
    () => ({
      all_event,
      clear_filter,
      reset_filter,
      valid_params,
      fetchEvents,
      isLoading,
      apply_filter,
      searchParams,
      setSearchParams,
      isFilterTouched,
      limit: limit_ref.current,
      loadMore,
      hasMore,
      showSkeleton: filter_mutation.isLoading,
      filter_mutation,
      all_events_count_mutation: mutate,
      all_filter_count: all_filter_count ?? {},
    }),
    [
      all_event,
      clear_filter,
      reset_filter,
      valid_params,
      fetchEvents,
      isLoading,
      apply_filter,
      searchParams,
      setSearchParams,
      isFilterTouched,
      loadMore,
      hasMore,
      filter_mutation,
      mutate,
      all_filter_count,
    ],
  );
}

export const useAllEventsFilterSorting = (
  tourOptions,
  menuOptions,
  fetchEvents,
  handleSortBy,
  handleFilterTourEvents,
) => {
  const dispatch = useDispatch();
  const [filterform] = Form.useForm();
  const [sortform] = Form.useForm();
  const { isFormUpdate, isSortingFormUpdate } = useSelector(
    (state) => state?.Popover,
  );

  const { filterParams } = useSelector((s) => s.allEventReducer);
  const filterHeadings = useMemo(() => {
    return ['Tour Association', 'Sort by'];
  }, []);

  const lableValue = useCallback((lable, dropdownCount) => {
    return (
      <span className="flex justify-between items-center gap-2">
        <span>{lable}</span>
        {/* <span className="selectedValue bg-sky-600 text-white text-[11px] min-w-[1rem] h-[1rem] flex justify-center items-center px-0.5 rounded-[4px] leading-tight">
          {dropdownCount}
        </span> */}
      </span>
    );
  }, []);
  // console.log('filters', filters);
  const EventsOptions = useMemo(() => {
    return tourOptions?.map((item) => ({
      value: item?.value, // desc
      id: item?.id,
      label: lableValue(item?.name, 0),
      action: item?.action,
    }));
  }, [tourOptions, lableValue]);

  const sortingOptions = useMemo(() => {
    return menuOptions?.map((item) => ({
      value: item?.value, // desc
      id: item?.id,
      label: lableValue(item?.name, 0),
      action: item?.action,
    }));
  }, [menuOptions, lableValue]);

  const filtersList = useMemo(() => {
    const filtersdata = {
      filter1: (
        <Select
          // onDropdownVisibleChange={(value) => {
          //   console.log(value, 'value');
          // }}
          style={{ width: '100%' }}
          placeholder={'Select Tour Association'}
          // value={showVlaue(filters?.event_mode)}
          onChange={() => {
            // record?.action();
            dispatch(updateForm(true));
          }}
          // defaultValue={'All'}
          suffixIcon={
            <>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                <BsChevronDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </>
          }
          className={`rounded-lg filterDrop`}
          options={EventsOptions}
          size="middle"
        />
      ),
      filter2: (
        <Select
          style={{ width: '100%' }}
          placeholder={'Sort By'}
          onChange={(value, record) => {
            record?.action();
            dispatch(updateSortingForm(true));
            dispatch(updateisSorted(true));
            dispatch(updateSortingForm(true));
            dispatch(updateSortingPopover(false));
          }}
          // value={tourCat}
          // defaultValue={'New To Old'}
          suffixIcon={
            <>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                <BsChevronDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </>
          }
          className={`rounded-lg filterDrop`}
          options={sortingOptions}
          size="middle"
        />
      ),
    };
    return Object.values(filtersdata);
  }, [EventsOptions, sortingOptions, dispatch]);

  const onFilter = useCallback(async () => {
    dispatch(updatePopover(false));
    dispatch(updateisFiltered(true));
    dispatch(updateForm(true));
    await handleFilterTourEvents(filterform.getFieldValue('filter0'));
    await fetchEvents({});
  }, [dispatch, fetchEvents, filterform, handleFilterTourEvents]);

  // const onSort = useCallback(async () => {
  //   dispatch(updateSortingPopover(false));
  //   dispatch(updateisSorted(true));
  //   dispatch(updateSortingForm(true));
  //   await handleSortBy(sortform.getFieldValue('filter0'));
  //   await fetchEvents({});
  // }, [dispatch, fetchEvents, sortform, handleSortBy]);

  // const onClearSort = useCallback(async () => {
  //   dispatch(updateSortingPopover(false));
  //   dispatch(updateisSorted(false));
  //   dispatch(updateSortingForm(false));
  //   sortform.resetFields();
  //   await dispatch(
  //     updateFilter({ ...filterParams, sort_by: undefined, page: 1 }),
  //   );
  //   await fetchEvents({});
  // }, [dispatch, filterParams, fetchEvents, sortform]);

  const onClear = useCallback(async () => {
    dispatch(updatePopover(false));
    dispatch(updateisFiltered(false));
    dispatch(updateForm(false));
    await filterform.resetFields();
    await filterform.setFieldValue('filter0', null);
    await dispatch(updateFilter({ ...filterParams, tour: undefined, page: 1 }));
    await fetchEvents({});
  }, [dispatch, filterParams, fetchEvents, filterform]);

  const onClearll = useCallback(async () => {
    dispatch(updateisFiltered(false));
    dispatch(updateForm(false));
    await filterform.resetFields();
    await filterform.setFieldValue('filter0', null);
  }, [dispatch, filterform]);

  const getFilterData = useCallback(
    (isSorting) => {
      if (isSorting) {
        return {
          form: sortform,
          filtersList: [filtersList?.[1]],
          filterHeadings: [filterHeadings?.[1]],
          // buttons: {
          //   onClear: onClearSort,
          //   onFilter: onSort,
          // },
          isSorting: true,
        };
      } else {
        return {
          form: filterform,
          filtersList: [filtersList?.[0]],
          filterHeadings: [filterHeadings?.[0]],
          buttons: {
            onClear,
            onFilter,
          },
        };
      }
    },
    [
      filterHeadings,
      filtersList,
      filterform,
      sortform,
      onClear,
      onFilter,
      // onClearSort,
      // onSort,
    ],
  );

  useEffect(() => {
    if (!isFormUpdate) {
      filterform.resetFields();
    } else if (!isSortingFormUpdate) {
      sortform.resetFields();
    }
  }, [isFormUpdate, isSortingFormUpdate, filterform, sortform]);

  useEffect(() => {
    // dispatch(updateDefaultCount(1));
    dispatch(updateFilterCount(1));
    filterform.setFieldValue('filter0', null);
  }, [filterform, dispatch]);

  const value = useMemo(() => {
    return {
      getFilterData,
      onClearll,
      // onClearSort,
    };
  }, [getFilterData, onClearll]);

  return value;
};
