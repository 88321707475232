import { Pagination, Table } from 'antd';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import NoDataFound from './NoDataFound';
import { MdEventNote } from 'react-icons/md';
export default function TableComponentv2({
  columns,
  dataSource,
  pagination,
  bordered,
  total,
  currentPage,
  setCurrentPage,
  loading,
  rowSelection,
  scroll,
  xScroll,
  yScroll,
  length = 0,
  custom = false,
  dataTablesRow,
  position = 'bottomRight',
  size = 'medium',
  onChange = () => {},
  showSorterTooltip,
  icon = <MdEventNote />,
  pageSize = 4,
  setPageSize = () => {},
  showSizeChanger = false,
  pageSizeOptions = [10, 25, 50, 100, 200],
  expandable,
}) {
  return (
    <React.Fragment>
      <div className="w-full">
        <div className="relative block w-full max-w-[100vw]">
          <Table
            dataSource={dataSource}
            size={size}
            columns={columns}
            onChange={onChange}
            rowClassName={dataTablesRow ? dataTablesRow : 'dataTablesRow'}
            rowSelection={!rowSelection ? false : { ...rowSelection }}
            expandable={expandable}
            pagination={
              !pagination
                ? false
                : {
                    total: total,
                    showSizeChanger: showSizeChanger,
                    current: currentPage,
                    onChange: (page, pageSize) => {
                      setCurrentPage(page);
                      setPageSize(pageSize);
                    },
                    pageSizeOptions: pageSizeOptions,
                    pageSize: pageSize,
                    position: [position],
                  }
            }
            locale={{
              emptyText: dataSource && dataSource?.length === 0 && (
                <Row className="justify-content-center text-center">
                  <Col md={12} className="py-3">
                    <NoDataFound title={'No data found.'} icon={icon} />
                  </Col>
                </Row>
              ),
            }}
            loading={loading}
            className="dataTables dataTables_v2"
            bordered={bordered ? bordered : false}
            // scroll={
            //   scroll
            //     ? scroll
            //     : { x: xScroll ? xScroll : '', y: yScroll ? yScroll : '' }
            // }
            scroll={scroll}
            showSorterTooltip={showSorterTooltip}
          />
        </div>
        {custom && (
          <div className="w-full flex justify-end">
            <Pagination
              onChange={(page) => setCurrentPage(page)}
              total={length || 0}
              defaultCurrent={currentPage}
              pageSize={10} //! need to add it dynamically
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
