import React from 'react';
import DashboardTopInfoWrapper from './DashboardTopInfoWrapper';

export default function DashboardVersion2() {
  return (
    <React.Fragment>
      <div>
        <DashboardTopInfoWrapper />
      </div>
    </React.Fragment>
  );
}
