import React, { useState, useEffect, useDeferredValue } from 'react';
import { Dropdown } from 'antd';
import { BiFilterAlt } from 'react-icons/bi';
import SeactCategoryCardV2 from './SeactCategoryCardV2';
import { Card } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import {
  AddSeats,
  DeleteSeats,
} from '../../../../features/seatmap/seatmap.slice';
import useDecodedToken from '../../../../hooks/useDecodedToken';
// import { IoCloseCircle } from 'react-icons/io5';
// import { CloseSquareFilled } from '@ant-design/icons';
// import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { DataLoader } from '../../../common/Loaders';
import loaderMessage from '../../../../utils/LoaderMessage';
import { MdClose, MdKeyboardArrowDown, MdOutlineChair } from 'react-icons/md';
import Tooltip from '../../Tooltip';
import { cartData } from '../../../../features/cart/cart.slice';
import { FixedSizeList as List } from 'react-window';
import { maxiumSeatsBooked } from '../../../util/maxiumSeatsBooked';
import SeatBookingMaxiumModal from './SeatBookingMaxiumModal';

export default function SeatBookingLeftSide({
  rowData = true,
  className,
  rowSectionData,
  setLeftSidebarShow,
  eventAllDetails,
  // setOpenTicketAlertModal,
  setSelectedCardId,
  sectionLoader,
  handleSectionLoader,
  // selectedCardId,
}) {
  let selectedSeats = useSelector((state) => state.selectedSeats);
  const [removeButtonID, setRemoveButtonID] = useState();
  const [showSelection, setShowSelection] = useState(false);
  const [selectionName, setSelectionName] = useState('');

  const [maxLimitAlert, setMaxLimitAlert] = useState(false);
  const [showRow, setShowRow] = useState(false);
  const [rowName, setRowName] = useState('');
  const [selectionRowData, setSelectionRowData] = useState([]);
  const [availableSeats, setAvailableSeats] = useState([]);

  // let cartselectedSeats = useSelector(cartData);
  // let { cartInpersonData, slug, cartvirtualData } = cartselectedSeats;

  useEffect(() => {
    setRemoveButtonID(selectedSeats?.data);
  }, [selectedSeats?.data]);

  useEffect(() => {
    setShowSelection(false);
    setRowName('');
    setSelectionName('');
    setTimeout(() => {
      handleSectionLoader(false);
    }, 1000);

    // setSeatData(rowSectionData?.seats);
  }, [rowSectionData]);

  const decodedToken = useDecodedToken();
  let dispatch = useDispatch();

  // const checkCart = () => {
  //   if (
  //     Object.keys(cartInpersonData)?.length > 0 &&
  //     slug !== eventAllDetails?.data?.slug &&
  //     slug !== ''
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const checkSeats = (item) => {
    let result = selectedSeats?.data.find((seat) => seat.seat_id === item._id);
    if (!result) {
      addSeat(item);
    }
  };

  const addSeat = (item, action) => {
    // if (checkCart()) {
    //   setOpenTicketAlertModal(true);
    // } else {
    // if (Object.entries(cartvirtualData)?.length > 0) {
    //   setOpenTicketAlertModal(true);
    // } else {
    let maxSeats = maxiumSeatsBooked(
      item?.category_details?._id,
      item?.category_details?.maximum_per_order,
      selectedSeats,
    );

    if (maxSeats) {
      dispatch(
        AddSeats({
          event_id: item?.category_details?.event_id,
          row_id: item?.row_id,
          section_id: item?.section_id,
          category_id: item?.category_details?._id,
          seat_id: item?._id,
          id: item?._id,
          section: item?.section_name,
          row: item?.row_no,
          seat_number: item?.seat_no,
          price: `${item?.category_details?.ticket_price}`,
          category: item?.category_details?.name,
          user_id: decodedToken?.id,
          price_seatmap_id: null,
          isSelected: true,
          resale_price: item?.resale_price,
          reseller_id: item?.reseller_id,
          // eventAllDetails: eventAllDetails,
          eventType: 'Physical',
          category_maximum_per_order: item?.category_details?.maximum_per_order,
        }),
      );
      //added by surya
      window.seatSelections({
        event_id: item?.category_details?.event_id,
        row_id: item?.row_id,
        section_id: item?.section_id,
        category_id: item?.category_details?._id,
        seat_id: item?._id,
        id: item?._id,
        section: item?.section_name,
        row: item?.row_no,
        seat_number: item?.seat_no,
        price: `${item?.category_details?.ticket_price}`,
        category: item?.category_details?.name,
        user_id: decodedToken?.id,
        price_seatmap_id: null,
        isSelected: true,
        resale_price: item?.resale_price,
        reseller_id: item?.reseller_id,
        // eventAllDetails: eventAllDetails,
        eventType: 'Physical',
        category_maximum_per_order: item?.category_details?.maximum_per_order,
      });
    } else {
      setMaxLimitAlert(true);
    }
    // }
    // }
  };

  const removeSeats = (item) => {
    let filter = selectedSeats?.data?.filter((obj) => obj.id !== item._id);
    //added by surya
    let removeItem = {
      event_id: item?.category_details?.event_id,
      row_id: item?.row_id,
      section_id: item?.section_id,
      category_id: item?.category_details?._id,
      seat_id: item?._id,
      id: item?._id,
      section: item?.section_name,
      row: item?.row_no,
      seat_number: item?.seat_no,
      price: `${item?.category_details?.ticket_price}`,
      category: item?.category_details?.name,
      // user_id: decodedToken?.id,
      // price_seatmap_id: searchParams.get('seatPrice'),
      // isSelected: item?.isSelected,
      // resale_price: item?.seat?.resale_amount,
      // reseller_id: item?.seat?.reseller_id,
      // eventAllDetails: eventAllDetails,
      eventType: 'Physical',
      category_maximum_per_order:
        item?.category_details?.category_maximum_per_order,
    };
    window.removeSelections([removeItem]);
    //----------------------

    dispatch(DeleteSeats(filter));
  };

  const selectedRow = (section) => {
    setShowRow(true);
    setRowName(section.row_no);
    let selectedSeatData = window.sendSeatsData(
      section._id,
      section?.category?._id,
    );

    if (!selectedSeatData.status) {
      if (selectedSeatData.message === 'Seats are loading') {
        handleSectionLoader(true);
        const intervalId = setInterval(() => {
          const updatedSeatData = window.sendSeatsData(
            section._id,
            section?.category?._id,
          );

          if (updatedSeatData.status) {
            setTimeout(() => {
              handleSectionLoader(false);
            }, 1000);
            clearInterval(intervalId);
            setAvailableSeats(updatedSeatData.seats);
          }
        }, 1000);
      } else {
        setAvailableSeats([]);
      }
    } else {
      setAvailableSeats(selectedSeatData.seats);
    }
  };

  // useEffect(() => {
  //   setRowName('');
  // }, [selectionName]);

  return (
    <React.Fragment>
      <div className={` ${className ? className : 'bg-white'} h-full`}>
        <div className="h-full">
          <div
            className={`p-2.5 relative after:absolute after:content-[''] after:bottom-0 after:left-0 after:h-1 after:w-full after:flex after:z-10 dark:after:bg-zinc-700 after:bg-zinc-200`}
          >
            <div className="flex justify-between gap-2 items-center">
              <div className="flex gap-2.5 items-center">
                <div>
                  <Dropdown
                    trigger={['click']}
                    menu={{
                      items: rowSectionData?.sections?.map((section) => {
                        const isSelected = selectionName === section?.name;
                        return {
                          label: (
                            <span className="text-whiteNew-0 flex gap-1.5 w-full items-center">
                              <span className="flex justify-between items-center gap-1.5">
                                {isSelected ? (
                                  <span className="text-green-500 flex w-[14px] text-lg mt-[4px]">
                                    {/* <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 1024 1024"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path>
                                    </svg> */}
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 24 24"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill="none"
                                        d="M0 0h24v24H0V0z"
                                      ></path>
                                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
                                      <circle cx="12" cy="12" r="5"></circle>
                                    </svg>
                                  </span>
                                ) : (
                                  <span className="w-[14px] mt-[4px] text-zinc-800 dark:text-zinc-100 flex items-center">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 24 24"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill="none"
                                        d="M0 0h24v24H0V0z"
                                      ></path>
                                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
                                    </svg>
                                  </span>
                                )}

                                <span className="text-zinc-800 dark:text-whiteNew-0">
                                  Section {section?.name}
                                </span>
                              </span>
                            </span>
                          ),
                          key: section?.id,
                          onClick: () => {
                            setShowSelection(true);
                            setSelectionName(section.name);
                            setShowRow(false);
                            setRowName('');
                            setSelectionRowData(
                              window.sendRowData(section.id, section?.category)
                                .rows,
                            );
                          },
                        };
                      }),
                    }}
                    className={`dark:bg-brown-700 bg-zinc-200 dark:hover:bg-brown-700 hover:bg-zinc-300 hover:bg-opacity-75 px-1.5 py-1.5 rounded-[5px] border-1 dark:border-brown-800 border-brown-200 dark:hover:border-brown-900 hover:border-brown-300 dark:text-whiteNew-0 text-zinc-800 flex items-center justify-between gap-2 text-base relative duration-150 ease-in w-full max-w-[190px] cursor-pointer`}
                    placement="bottomRight"
                    overlayClassName={
                      'dropdownOverlay dowpdownDark max-h-[250px] scrollCss scrollBarThumb scrollBarPurpleColor overflow-y-auto [&_.ant-dropdown-menu]:bg-zinc-100'
                    }
                  >
                    <div className="flex justify-between gap-1 items-center">
                      <span className="dark:text-whiteNew-0 text-zinc-800 text-xs shrink-0 truncate max-w-[120px]">
                        {showSelection
                          ? `Section ${selectionName}`
                          : 'Select Section'}
                      </span>
                      <MdKeyboardArrowDown className="text-lg -ml-1" />
                    </div>
                  </Dropdown>
                </div>
                <div>
                  <span className="flex h-[25px] w-[1px] dark:bg-neutral-700 bg-zinc-200"></span>
                </div>

                {selectionName && selectionRowData && (
                  <div className="">
                    <Dropdown
                      trigger={['click']}
                      menu={{
                        items: selectionRowData?.map((section) => {
                          const isSelected = rowName === section?.row_no;

                          return {
                            label: (
                              <span className="dark:text-whiteNew-0 text-zinc-800 flex gap-1.5 w-full">
                                <span className="flex justify-between items-center gap-1.5">
                                  {isSelected ? (
                                    <span className="text-green-500 flex w-[14px] text-lg mt-[4px]">
                                      {/* <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 1024 1024"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path>
                                      </svg> */}
                                      <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        stroke-width="0"
                                        viewBox="0 0 24 24"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill="none"
                                          d="M0 0h24v24H0V0z"
                                        ></path>
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
                                        <circle cx="12" cy="12" r="5"></circle>
                                      </svg>
                                    </span>
                                  ) : (
                                    <span className="flex w-[14px] dark:text-zinc-100 text-zinc-800 mt-[4px]">
                                      <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        stroke-width="0"
                                        viewBox="0 0 24 24"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill="none"
                                          d="M0 0h24v24H0V0z"
                                        ></path>
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
                                      </svg>
                                    </span>
                                  )}
                                  <span className="flex items-center justify-center">
                                    <span>row</span>{' '}
                                    <span>{section?.row_no}</span>
                                  </span>
                                </span>
                              </span>
                            ),
                            key: section._id,
                            onClick: () => {
                              selectedRow(section);
                              // setShowRow(true);
                              // setRowName(section.row_no);
                              // setAvailableSeats(
                              //   window.sendSeatsData(
                              //     section._id,
                              //     section?.category?._id,
                              //   ).seats,
                              // );
                            },
                          };
                        }),
                      }}
                      className={`dark:bg-brown-700 bg-zinc-200 dark:hover:bg-brown-700 hover:bg-zinc-300 hover:bg-opacity-75 px-1.5 py-1.5 rounded-[5px] border-1 dark:border-brown-800 border-brown-200 dark:hover:border-brown-900 hover:border-brown-300 dark:text-whiteNew-0 text-zinc-800 flex items-center justify-between gap-2 text-base relative duration-150 ease-in w-full max-w-[190px] cursor-pointer`}
                      placement="bottomRight"
                      overlayClassName={
                        'dropdownOverlay dowpdownDark max-h-[250px] scrollCss scrollBarThumb scrollBarPurpleColor overflow-y-auto'
                      }
                    >
                      <div className="flex justify-between gap-1 items-center">
                        <span className="dark:text-whiteNew-0 text-zinc-800 text-xs">
                          {showRow ? `Row ${rowName}` : 'Select Row'}
                        </span>
                        <MdKeyboardArrowDown className="text-lg -ml-1" />
                      </div>
                    </Dropdown>
                  </div>
                )}
              </div>

              <div className="flex gap-2 align-items-center ">
                <Tooltip title={'Close Select Seat'}>
                  <button
                    className="p-0.5 rounded-md text-base dark:bg-brown-800 bg-zinc-200 dark:hover:bg-brown-700 hover:bg-brown-300 hover:bg-opacity-75 dark:text-whiteNew-0 text-zinc-800 px-1.5 py-1.5 border-1 dark:border-brown-800 border-brown-200"
                    onClick={() => {
                      setLeftSidebarShow(false);
                      setSelectedCardId(null);
                    }}
                  >
                    <MdClose />
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="h-full">
            <Card className="border-0 bg-transparent rounded-none  h-full min-h-[280px] pr-[1px]">
              <Card.Body
                className="dark:bg-zinc-800 bg-color-base-2 dark:bg-opacity-100 bg-opacity-100 px-0.5 py-1.5 max-h-[400px] lg:max-h-[100%] h-full scrollCss
              scrollBarThumb overflow-y-auto overflow-x-hidden pb-10"
                //   className="bg-[#33231A] bg-opacity-50 px-0.5 py-1.5 max-h-[400px] lg:max-h-[100%] h-full scrollCss
                // scrollBarThumb overflow-y-auto overflow-x-hidden lg:mb-[50px]"
              >
                {!rowName ? (
                  <div className="w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    {/* <div> */}
                    {/* <MdOutlineChair className="text-5xl text-gray-400" /> */}
                    <p className="dark:text-whiteNew-0 text-zinc-800 text-sm 2xl:text-sm text-center">
                      <br />
                      Please mark your selection for better
                      <br /> experience of seat map.
                    </p>
                    {/* </div> */}
                  </div>
                ) : sectionLoader ? (
                  <div className="w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <div>
                      <DataLoader
                        message={loaderMessage?.loading_seats}
                        minHeight={'min-h-[10px]'}
                        darkTheme={true}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="">
                    <SeactCategoryCardV2
                      seatData={availableSeats
                        ?.filter((item) => item.status !== 'booked')
                        .filter((item) => item.status !== 'reserved')}
                      addSeat={checkSeats}
                      removeSeats={removeSeats}
                      removeButtonID={removeButtonID}
                    />
                  </div>
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <SeatBookingMaxiumModal
        maxLimitAlert={maxLimitAlert}
        setMaxLimitAlert={setMaxLimitAlert}
      />
    </React.Fragment>
  );
}
