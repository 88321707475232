import React, { memo, useMemo } from 'react';
import useDecodedToken from '../../../hooks/useDecodedToken';
import { Link } from 'react-router-dom';
import {
  CustomFacebookIcon,
  CustomInstagramIcon,
  CustomLinkedIcon,
  CustomTwitterIcon,
} from '../../../components/util/CustomIcons';
import Tooltip from '../../../components/common/Tooltip';
import { SkeletonTableListView } from '../../../components/util/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Divider } from 'antd';
import EventImageVideo from '../../../components/common/EventImageVideo';
import { truncate } from '../../../utils/util';
import { useArtistFollow } from '../../../hooks/event/artist/artist';

export default function AllArtistListView({ props }) {
  const { all_artist, loadMore, hasMore, filter_loading } = props;
  const all_artists = useMemo(() => all_artist, [all_artist]);
  const decodedToken = useDecodedToken();
  const { FollowMutation } = useArtistFollow(all_artist, 'allArtist');

  return (
    <div className="bg-color-base-3 mt-3 border-zinc-200 dark:border-zinc-800 border-t-[1px]">
      <div className="hidden md:flex md:flex-row px-3.5 py-2.5 justify-between bg-color-base-3">
        <div className="px-2 flex flex-col text-zinc-800 dark:text-whiteNew-0 text-sm font-medium w-[15%]">
          Artist/Team Name
        </div>
        <div
          className={`px-2 flex flex-col text-zinc-800 dark:text-whiteNew-0 text-sm font-medium ${
            decodedToken?.id ? 'md:w-[60%]' : 'md:w-[65%]'
          }`}
        >
          Bio
        </div>
        <div className="px-2 flex flex-col text-zinc-800 dark:text-whiteNew-0 text-sm font-medium w-[20%]">
          Social Media
        </div>

        {decodedToken?.id && (
          <div className="px-2 flex flex-col text-gray-500 dark:text-whiteNew-0 text-base font-semibold w-[15%]">
            Action
          </div>
        )}
      </div>

      <div className="border-b-[1px] dark:border-zinc-800 border-zinc-200 last:border-none bg-zinc-950 rounded-lg">
        {filter_loading ? (
          [...Array(8)].map((_, index) => <SkeletonTableListView key={_} />)
        ) : (
          <InfiniteScroll
            dataLength={all_artist?.length}
            next={loadMore}
            hasMore={hasMore}
            className="overflow-x-hidden"
            loader={[...Array(5)].map((_, index) => (
              <SkeletonTableListView key={_} />
            ))}
          >
            {all_artists?.map((artist) => {
              return (
                <ArtistCardMemo
                  key={artist?._id}
                  artist={artist}
                  FollowMutation={FollowMutation}
                />
              );
            })}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
}

const ArtistCard = ({ artist, key, FollowMutation }) => {
  const { profile_image, socialmedia_urls, name, _id, description } =
    artist || {};

  const { facebook_url, instagram_url, twitter_url, telegram_url } =
    socialmedia_urls || {};
  const decodedToken = useDecodedToken();

  return (
    <>
      <div
        key={key}
        className="flex flex-col justify-between md:flex-row md:items-center p-1.5 md:p-4 bg-whiteNew-0 dark:bg-transparent mt-[10px] md:mt-[0px]"
      >
        <div className="flex dark:text-whiteNew-0 text-zinc-800 flex-row w-[100%] md:w-[15%] gap-3 items-center px-2">
          <div className="shrink-0 w-12 h-12 rounded-full overflow-hidden">
            <EventImageVideo
              data={profile_image?.[0] || profile_image}
              alt="profile picture"
              className={'w-full h-full object-cover'}
              loading="lazy"
              // width="384px"
              // height="512px"
            />
            {/* <Image
              src={profile_image}
              className=" object-cover w-full h-full"
              loading="lazy"
            /> */}
          </div>
          <Link
            to={`/artist/${_id}`}
            className="hover:text-landingPurple-60 duration-150 ease-in text-sm cursor-pointer "
          >
            {name}
          </Link>
        </div>
        <div
          className={`flex text-white flex-col w-[100%] ${
            decodedToken?.id ? 'md:w-[60%]' : 'md:w-[65%]'
          } px-2 mt-3.5 md:mt-[0px] mb-3.5 md:mb-[0px]`}
        >
          <span className="text-[14px] text-gray-500 dark:text-zinc-400">
            {decodedToken?.id
              ? truncate(description, 80)
              : truncate(description, 150)}
          </span>
        </div>
        <div className="flex dark:text-whiteNew-0 text-zinc-800 flex-col w-[100%] md:w-[20%] px-2 mt-3.5 md:mt-[0px] mb-3.5 md:mb-[0px]">
          <div className="flex gap-3">
            {facebook_url && (
              <Tooltip title={facebook_url}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="flex"
                  href={facebook_url}
                >
                  <button className="relative text-xl dark:text-whiteNew-0 text-zinc-800">
                    <CustomFacebookIcon />
                  </button>
                </a>
              </Tooltip>
            )}
            {instagram_url && (
              <Tooltip title={instagram_url}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="flex"
                  href={instagram_url}
                >
                  <button className="relative text-xl dark:text-whiteNew-0 text-zinc-800">
                    <CustomInstagramIcon />
                  </button>
                </a>
              </Tooltip>
            )}
            {twitter_url && (
              <Tooltip title={twitter_url}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="flex"
                  href={twitter_url}
                >
                  <button className="relative text-xl dark:text-whiteNew-0 text-zinc-800">
                    <CustomTwitterIcon />
                  </button>
                </a>
              </Tooltip>
            )}
            {telegram_url && (
              <Tooltip title={telegram_url}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="flex"
                  href={telegram_url}
                >
                  <button className="relative text-xl dark:text-whiteNew-0 text-zinc-800">
                    <CustomLinkedIcon />
                  </button>
                </a>
              </Tooltip>
            )}
          </div>
        </div>

        {decodedToken?.id && (
          <div className="text-white flex flex-col w-[15%] px-2">
            {artist?.followers &&
            artist?.followers?.includes(decodedToken?.id) ? (
              <button
                className="p-1.5 bg-gradient-to-r dark:from-purplev2-800 from-sky-600 dark:to-purplev2-900 to-sky-600 text-gray-50 rounded-md duration-300 ease-in flex justify-center items-center lg:min-w-[150px] text-center text-gray-50duration-200 w-24 max-w-[100px]"
                onClick={() => {
                  FollowMutation.mutate({
                    method: 'unfollow',
                    artistID: artist?._id,
                  });
                }}
              >
                UNFOLLOW
              </button>
            ) : (
              <button
                className="p-1.5 bg-gradient-to-r dark:from-purplev2-800 from-sky-600 dark:to-purplev2-900 to-sky-600 text-gray-50 rounded-md duration-300 ease-in flex justify-center items-center lg:min-w-[150px] text-center text-gray-50duration-200 w-24 max-w-[100px]"
                onClick={() => {
                  FollowMutation.mutate({
                    method: 'follow',
                    artistID: artist?._id,
                  });
                }}
              >
                FOLLOW
              </button>
            )}
          </div>
        )}
      </div>
      <Divider className="dark:bg-neutral-700 bg-lightTheme-90 m-0" />
    </>
  );
};

const ArtistCardMemo = memo(ArtistCard);
