import React from 'react';
import { Form } from 'react-bootstrap';

export default function EmailPreferenceCheckbox({ title, subTitle, checked }) {
  return (
    <React.Fragment>
      <div className="flex justify-between  gap-5">
        <div className="">
          <div className="flex lg:gap-9 w-full">
            <Form.Check
              type={'checkbox'}
              //   checked={checked}
              bsPrefix="form-check"
              label={
                <span className="flex flex-col -mt-3 ml-4 relative -top-1">
                  <span
                    className={` ${
                      checked
                        ? 'font-medium text-color-primary'
                        : 'text-slate-400'
                    } text-sm`}
                  >
                    {title}
                  </span>
                  <span
                    className={`  ${
                      checked ? 'text-color-secondary' : 'text-slate-400'
                    }`}
                  >
                    {subTitle}
                  </span>
                </span>
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
