import React, { Fragment } from 'react';
import { CaretRightIcon } from '../../util/CustomIcons';
import { Popover, Transition } from '@headlessui/react';
import Tooltip from '../../common/Tooltip';
import { HiOutlineUserCircle } from 'react-icons/hi2';
import { AiOutlineClose } from 'react-icons/ai';
import UserProfileDropdownCard from './UserProfileDropdownCard';
import Button from '../../common/Button';

export default function UserDetailPopover({ darkTheme, hideCompo }) {
  return (
    <>
      <div>
        <Popover className="relative">
          {({ open, close }) => (
            <>
              <Tooltip title={'Profile'} placement={'bottom'}>
                <Popover.Button
                  className={`p-1.5 dark:bg-brown-800 bg-transparent text-whiteNew-0 hover:bg-opacity-90 rounded-full border-2 flex gap-1 items-center justify-between text-base leading-tight relative min-w-[55px] h-[31px] duration-0 ease-in outline-none ring-0 focus:outline-none focus:ring-0 focus-within:outline-none focus-visible:outline-none focus-within:ring-0 focus-visible:ring-0 ${
                    open
                      ? `border-green-600 text-green-700 border-2`
                      : ` border-green-600`
                    //${darkTheme ? 'border-brown-800' : 'border-gray-300'} -- old one
                    // for signed out user class: "border-rose-600"
                  }
                `}
                >
                  <HiOutlineUserCircle
                    className={`text-xl dark:text-whiteNew-0 text-zinc-900 duration-200 ease-in ${
                      open
                        ? `${darkTheme ? 'text-green-700' : 'text-green-700'}`
                        : 'text-green-700'
                    }`}
                  />
                  <CaretRightIcon
                    className={`text-[9px] duration-200 ease-in text-green-700 ${
                      open ? 'rotate-90' : ''
                    }`}
                  />
                </Popover.Button>
              </Tooltip>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute right-0 sm:right-0 z-50 mt-2 w-80 max-w-sm transform sm:px-0 lg:max-w-lg">
                  <div className="">
                    <div
                      className={`border-1 dark:border-neutral-800 border-level-2 bg-color-base-1 dark:bg-color-base-4 backdrop-blur-3xl bg-opacity-100 p-2 min-h-[250px] max-h-sm overflow-hidden rounded-lg`}
                    >
                      <Button
                        className={`text-color-primary p-1.5 absolute top-2 right-2 `}
                        onClick={() => {
                          close();
                        }}
                      >
                        <AiOutlineClose />
                      </Button>
                      <div className="p-3.5">
                        <UserProfileDropdownCard
                          darkTheme={darkTheme}
                          hideCompo={hideCompo}
                          close={close}
                        />
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </>
  );
}
