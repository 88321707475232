import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
  openTimerAttemptModal,
  setOpenTimerattemptModal,
  updateTotalAttempt,
} from '../../../features/cart/cart.slice';
import useDecodedToken from '../../../hooks/useDecodedToken';
import { NotificationControllerContext } from '../../../contexts/NotificationProvider';
import { useCookie } from '../../../hooks/cookie';

export default function AddTimerModal({ darkTheme = false }) {
  const dispatch = useDispatch();
  const openModal = useSelector(openTimerAttemptModal);
  const { cookies, setCookie } = useCookie();
  const decodedToken = useDecodedToken();
  const IsGuestUser = localStorage.getItem('GuestUser')
    ? Boolean(JSON.parse(localStorage.getItem('GuestUser')))
    : false;

  // console.log('openModal', openModal);

  const closeModal = useCallback(() => {
    dispatch(setOpenTimerattemptModal(false));
  }, [dispatch]);

  const { openNotification, setIsopened, isOpened, api } = useContext(
    NotificationControllerContext,
  );

  // useCallback added to all the onchange function in optimisations.

  const onCancel = useCallback(() => {
    setCookie(
      `TotalAttempt${decodedToken ? decodedToken?.id : 'GuestUser'}`,
      Number(3),
      {
        maxAge: 240,
      },
    );
    dispatch(updateTotalAttempt(3));
    closeModal();
    setIsopened(false);
    // setTimeout(() => {
    // }, 2000);
  }, [decodedToken, closeModal, setCookie, dispatch, setIsopened]);

  // console.log('isOpened', isOpened);

  const getSessionTime = useCallback(() => {
    if (IsGuestUser) {
      return sessionStorage.getItem(`timer${'GuestUser'}`)
        ? JSON.parse(sessionStorage.getItem(`timer${'GuestUser'}`))
        : '';
    } else {
      return sessionStorage.getItem(
        `timer${decodedToken && !IsGuestUser ? decodedToken?.id : 'GuestUser'}`,
      )
        ? JSON.parse(
            sessionStorage.getItem(
              `timer${decodedToken ? decodedToken?.id : 'GuestUser'}`,
            ),
          )
        : '';
    }
  }, [decodedToken, IsGuestUser]);

  const onSubmit = useCallback(() => {
    const currentTime = Math.floor(Date.now() / 1000);
    // const elapsedTime =
    //   currentTime -
    //   Number(
    //     cookies?.[`timer${decodedToken ? decodedToken?.id : 'GuestUser'}`],
    //   );
    // let remainingTime = 180 - elapsedTime;
    const sessionTime = getSessionTime()?.split(':');
    const convertMinuteToSec = (minutes, seconds) => {
      return minutes * 60 + seconds + 120;
    };
    const convertSecondsToMinutes = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return { minutes, seconds: remainingSeconds };
    };

    let convertminutetosec = convertMinuteToSec(
      Number(sessionTime?.[0]),
      Number(sessionTime?.[1]),
    );

    sessionStorage.setItem(
      `addedTime${decodedToken ? decodedToken?.id : 'GuestUser'}`,
      JSON.stringify(convertminutetosec),
    );

    let totalAttempt = Number(openModal?.triedAttempt);
    setCookie(
      `TotalAttempt${decodedToken ? decodedToken?.id : 'GuestUser'}`,
      Number(totalAttempt || 0) + 1,
      {
        maxAge: convertminutetosec,
      },
    );
    // console.log(remainingTime + 120, 'remainingTime + 120');
    dispatch(updateTotalAttempt(Number(totalAttempt || 0) + 1));
    setCookie(
      `timer${decodedToken ? decodedToken?.id : 'GuestUser'}`,
      currentTime,
      {
        maxAge: convertminutetosec,
      },
    );
    sessionStorage.setItem(
      `timer${decodedToken ? decodedToken?.id : 'GuestUser'}`,
      JSON.stringify(
        `${convertSecondsToMinutes(convertminutetosec)?.minutes}:${
          convertSecondsToMinutes(convertminutetosec)?.seconds
        } `,
      ),
    );
    setIsopened(false);
    closeModal();
  }, [
    decodedToken,
    setCookie,
    closeModal,
    dispatch,
    openModal?.triedAttempt,
    setIsopened,
    getSessionTime,
  ]);

  if (openModal?.openTimerAttemptModal) {
    return (
      !isOpened &&
      openNotification({
        message: null,
        description: (
          <>
            <span className="text-white">
              The order in your cart is about to expire. Do you want to extend
              it by 2minutes? if not, you will be redirect to the event page.
            </span>
          </>
        ),
        duration: null,
        className: 'bg-neutral-900 rounded-lg',
        primaryButtonOnclick: onSubmit,
        secondaryButtonOnClick: onCancel,
      })
    );
  } else if (!openModal?.openTimerAttemptModal && isOpened) {
    setIsopened(false);
    api.destroy();
  }

  // return (
  //   <React.Fragment>
  //     <Modal
  //       isOpen={openModal?.openTimerAttemptModal}
  //       setIsOpen={closeModal}
  //       maxWidth="max-w-md"
  //       backdrop={darkTheme ? 'backdrop-blur-sm' : null}
  //       bgColor={
  //         darkTheme
  //           ? 'bg-neutral-800 bg-opacity-100 backdrop-blur-sm bg-center'
  //           : null
  //       }
  //     >
  //       <div className="mt-4 mt-md-0">
  //         <Row className="items-center">
  //           <Col xs={12} md={12}>
  //             <div className="flex justify-center flex-col text-center">
  //               <div className="text-6xl text-yellow-400 flex justify-center">
  //                 <MdInfoOutline />
  //               </div>
  //               <div className="my-2">
  //                 <h4 className={darkTheme ? 'text-whiteNew-0' : 'text-dark'}>
  //                   The order in your cart is about to expire. Do you want to
  //                   extend it by 2minutes?
  //                 </h4>
  //               </div>
  //             </div>
  //             <div className="flex justify-center mt-4 gap-2 gap-md-3">
  //               <button
  //                 onClick={onCancel}
  //                 type="button"
  //                 className={
  //                   darkTheme
  //                     ? `bg-neutral-700 bg-opacity-50 text-gray-50 hover:bg-neutral-700 hover:bg-opacity-70 duration-200 ease-in shadow-lg shadow-gray-200 p-2 rounded-lg  flex justify-center items-center w-full max-w-[120px]`
  //                     : btnSecondayClass
  //                 }
  //               >
  //                 Cancel
  //               </button>
  //               <button
  //                 type="submit"
  //                 onClick={onSubmit}
  //                 // disabled={
  //                 //   isPhoneAvailable === false || isUsernameAvailable === false
  //                 // }
  //                 className={
  //                   darkTheme
  //                     ? `bg-landingPurple-60 text-gray-50 hover:bg-landingPurple-70 p-2 rounded-lg duration-200 ease-in w-full max-w-[120px]`
  //                     : btnPrimaryClass
  //                 }
  //               >
  //                 Confirm
  //               </button>
  //             </div>
  //           </Col>
  //         </Row>
  //       </div>
  //     </Modal>
  //   </React.Fragment>
  // );
}
