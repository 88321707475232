import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sample_ticket_design: [],
  user_ticket_design: [],
  selected_ticket_design: {},
};

const slice = createSlice({
  name: 'ticket_design',
  initialState,
  reducers: {
    updateSampleTicketDesign: (state, { payload }) => {
      state.sample_ticket_design = payload;
    },
    updateUserTicketDesign: (state, { payload }) => {
      state.user_ticket_design = payload;
    },
    update_selected_TicketDesign: (state, { payload }) => {
      state.selected_ticket_design = payload;
    },
  },
});

let ticketDesignReducer = slice.reducer;

export const {
  updateSampleTicketDesign,
  updateUserTicketDesign,
  update_selected_TicketDesign,
} = slice.actions;
const getAllTicketDesign = (state) => state.ticket_design;

export { ticketDesignReducer, getAllTicketDesign };
