import React from 'react';
import GotixWalletInfo from './GotixWalletInfo';
import CryptoWalletInfo from './CryptoWalletInfo';
import CreditCardInfo from './CreditCardInfo';
import BankDetailInfo from './BankDetailInfo';
import AddressDetailInfo from './AddressDetailInfo';

export default function MyAccountOverview({
  activeSection,
  setActiveSection,
  setActiveSubSection,
  activeSubSection,
}) {
  return (
    <React.Fragment>
      {activeSection === 'gotix_wallet' &&
        activeSubSection === 'usd_wallet' && (
          <>
            <GotixWalletInfo />
          </>
        )}
      {activeSection === 'gotix_wallet' &&
        activeSubSection === 'crpto_wallet' && (
          <>
            <CryptoWalletInfo />
          </>
        )}
      {activeSection === 'credit_card' && (
        <>
          <CreditCardInfo />
        </>
      )}
      {activeSection === 'bank_info' && (
        <>
          <BankDetailInfo />
        </>
      )}
      {activeSection === 'address_info' && (
        <>
          <AddressDetailInfo />
        </>
      )}
    </React.Fragment>
  );
}
