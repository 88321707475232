import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  all_artist_filter_count,
  all_artists_list_filter,
} from '../../../../helpers/api/event/artist/artist';
import { useDispatch, useSelector } from 'react-redux';
import { useOnceCall } from '../../../../hooks/useOnlyOnce';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import {
  ARTIST_SUB_TYPE,
  ARTIST_TYPE,
  EVENT_LIST,
  FILTER_CONST,
  TOUR,
  initialFilterParams,
  updateAllArtist,
  updateAllArtistsFilterCount,
  updateFilter,
  updateFilterLoading,
  updateFilterPills,
} from '../../../../features/event/allartist.slice';
import { Form, Select } from 'antd';
import { capitalizeFirstLetter } from '../../../../utils/util';
import {
  updateFilterCount,
  updateForm,
  updatePopover,
  updateSortingForm,
  updateSortingPopover,
  updateisFiltered,
  updateisSorted,
} from '../../../../features/event/event.slice';
import { BsChevronDown } from 'react-icons/bs';

// function useArtist() {
//   const { all_artist_page, all_artist_limit, all_artist_list } = useSelector(
//     (s) => s.artists,
//   );
//   const dispatch = useDispatch();
//   const [loadingArtist, setLoadingArtist] = useState(false);
//   useOnceCall(() => {
//     dispatch(setAllArtistPage(1));
//     dispatch(setAllArtistList({}));
//   });
//   const allArtistData = useSWR(
//     'sample_artist_data',
//     () => getSampleArtistList(all_artist_page, '', all_artist_limit),
//     {
//       revalidateOnMount: true,
//       revalidateOnFocus: false,
//       shouldRetryOnError: false,
//       onSuccess: (data) => {
//         if (all_artist_page > 1) {
//           // let obj = { data: [...artistList, ...data?.data?.data] ,count:data?.data?.count}
//           let obj = {
//             data: [...all_artist_list?.data, ...data?.data?.data],
//             count: data?.data?.dataCount,
//           };
//           dispatch(setAllArtistList(obj));
//           // setArtistList([...artistList, ...data?.data?.data]);
//         } else {
//           let obj = {
//             data: [...data?.data?.data],
//             count: data?.data?.dataCount,
//           };
//           dispatch(setAllArtistList(obj));
//           // setArtistList([...data?.data?.data]);
//         }
//       },
//     },
//   );

//   const fetchArtist = useCallback(
//     async ({ is_refetching = false, should_paginate = false }) => {
//       if (is_refetching) {
//         dispatch(setAllArtistPage(1));
//         const list = await getSampleArtistList(
//           1,
//           '',
//           all_artist_list?.data?.length,
//         );
//         // setArtistList([...list?.data?.data]);
//         let obj = { data: [...list?.data?.data], count: list?.data?.dataCount };
//         dispatch(setAllArtistList(obj));
//         setLoadingArtist(false);
//       } else {
//         setLoadingArtist(true);
//         if (should_paginate) {
//           dispatch(setAllArtistPage(all_artist_page + 1));
//         }
//         const index = should_paginate ? all_artist_page + 1 : 1;
//         const list = await getSampleArtistList(index, '', all_artist_limit);
//         // setArtistList([...list?.data?.data]);
//         let obj = {
//           data: [...all_artist_list?.data, ...list?.data?.data],
//           count: list?.data?.dataCount,
//         };
//         dispatch(setAllArtistList(obj));
//         setLoadingArtist(false);
//       }
//     },
//     [dispatch, all_artist_page, all_artist_list?.data, all_artist_limit],
//   );

//   const loadMore = React.useCallback(() => {
//     setTimeout(async () => await fetchArtist({ should_paginate: true }), 1000);
//   }, [fetchArtist]);

//   const values = React.useMemo(
//     () => ({
//       fetchArtist,
//       loadMore,
//       allArtistData,
//       loadingArtist,
//     }),
//     [fetchArtist, loadMore, allArtistData, loadingArtist],
//   );

//   return values;
// }

// export default useArtist;

export const useAllArtistsFilter = () => {
  const dispatch = useDispatch();
  const {
    filterParams,
    filter_pills,
    // all_event_filter_count
  } = useSelector((s) => s.allArtistReducer);
  const {
    filter_mutation,
    fetchArtists,
    all_artists_count_mutation,
    all_filter_count,
  } = useAllArtistFilterAction();
  const [form] = Form.useForm();

  useOnceCall(async () => {
    await dispatch(updateFilter(initialFilterParams));
    await dispatch(updateFilterPills({}));
    await fetchArtists({});
    await all_artists_count_mutation();
  });

  const getCountForEachFilter = useCallback(
    (key, count_for) => {
      if (key in all_filter_count) {
        if (key === 'event_list')
          return (
            all_filter_count[key]?.find((artist) =>
              Boolean(artist?._id === count_for?._id),
            )?.count ?? 0
          );
        if (key === 'artist_sub_type')
          return (
            all_filter_count[key]?.find((artist) =>
              Boolean(artist?.type === count_for?.type),
            )?.count ?? 0
          );
        if (key === 'artist_type') return all_filter_count[key]?.[count_for];
        // if (count_for in all_filter_count[key]) {
        //   return all_filter_count[key][count_for];
        // }
        return 0;
      }
      return 0;
    },
    [all_filter_count],
  );

  const eventListOptions = useMemo(() => {
    const event_list = all_filter_count?.event_list;
    return event_list?.map((item, index) => ({
      label: item?.name,
      _id: item?._id,
      key: FILTER_CONST[EVENT_LIST],
      count: getCountForEachFilter('event_list', item),
      action: (checked, filteritem) => {
        let arr = filterParams.event_list;
        // console.log('arr', arr, filteritem);
        if (checked) {
          // console.log('checked', checked);
          dispatch(
            updateFilter({
              ...filterParams,
              event_list: [...arr, filteritem],
              page: 1,
            }),
          );
        } else {
          // console.log('checked', checked);
          let event_list = filterParams.event_list?.filter(
            (item) => item !== filteritem,
          );
          // console.log('event_list', event_list);
          dispatch(
            updateFilter({
              ...filterParams,
              event_list: [...event_list],
              page: 1,
            }),
          );
        }
      },
    }));
  }, [
    dispatch,
    filterParams,
    all_filter_count?.event_list,
    getCountForEachFilter,
  ]);

  const artistTypeOptions = useMemo(() => {
    const artist_type = ['entertainment', 'sports'];
    return artist_type?.map((item, index) => ({
      label: capitalizeFirstLetter(item),
      _id: item,
      key: FILTER_CONST[ARTIST_TYPE],
      count: getCountForEachFilter('artist_type', capitalizeFirstLetter(item)),
      action: (checked, filteritem) => {
        let arr = filterParams.artist_type;
        if (checked) {
          dispatch(
            updateFilter({
              ...filterParams,
              artist_type: [...arr, filteritem],
              page: 1,
            }),
          );
        } else {
          let artist_type = filterParams.artist_type?.filter(
            (item) => item !== filteritem,
          );
          dispatch(
            updateFilter({
              ...filterParams,
              artist_type: [...artist_type],
              page: 1,
            }),
          );
        }
      },
    }));
  }, [dispatch, filterParams, getCountForEachFilter]);

  const artistSubTypeOptions = useMemo(() => {
    const artist_sub_type = all_filter_count?.artist_sub_type;
    return artist_sub_type?.map((item, index) => ({
      label: item?.type,
      _id: item?.type,
      key: FILTER_CONST[artist_sub_type],
      count: getCountForEachFilter('artist_sub_type', item),
      action: (checked, filteritem) => {
        let arr = filterParams.artist_sub_type;
        if (checked) {
          dispatch(
            updateFilter({
              ...filterParams,
              artist_sub_type: [...arr, filteritem],
              page: 1,
            }),
          );
        } else {
          let artist_sub_type = filterParams.artist_sub_type?.filter(
            (item) => item !== filteritem,
          );
          dispatch(
            updateFilter({
              ...filterParams,
              artist_sub_type: [...artist_sub_type],
              page: 1,
            }),
          );
        }
      },
    }));
  }, [
    dispatch,
    filterParams,
    all_filter_count?.artist_sub_type,
    getCountForEachFilter,
  ]);

  const filter_labels = useMemo(
    () => [
      'Event List',
      'Artist Type',
      'Artist Sub Type',
      // 'Location'
    ],
    [],
  );

  const all_filters_pills = useMemo(() => {
    const filterPills = { ...filter_pills };
    delete filterPills['page'];
    delete filterPills['limit'];
    delete filterPills['search_key'];
    delete filterPills['sort_by'];
    Object.entries(filterPills).map(([key, value]) => {
      console.log('key', key, filterPills);
      console.log('eventListOptions');
      if (
        ['event_list', 'artist_type', 'artist_sub_type', 'tour'].includes(key)
      ) {
        if (key === 'event_list') {
          filterPills[key] = eventListOptions
            .filter((el) => String(value).split(',').includes(String(el._id)))
            .map((ele) => ({
              label: ele.label,
              type: key,
              id: String(ele._id),
            }));
        }
        if (key === 'artist_type') {
          filterPills[key] = artistTypeOptions
            .filter((el) => String(value).split(',').includes(String(el._id)))
            .map((ele) => ({
              label: ele.label,
              type: key,
              id: String(ele._id),
            }));
        }
        if (key === 'artist_sub_type') {
          filterPills[key] = artistSubTypeOptions
            .filter((el) => String(value).split(',').includes(String(el._id)))
            .map((ele) => ({
              label: ele.label,
              type: key,
              id: String(ele._id),
            }));
        }

        if (key === 'tour') {
          const tour_key = {
            1: 'Individual',
            2: 'Team',
          };
          filterPills[key] = [
            {
              label: tour_key[value === 'team' ? 2 : 1],
              type: key,
              id: value,
            },
          ];
        }
      }
      return false;
    });
    return filterPills;
  }, [filter_pills, artistSubTypeOptions, artistTypeOptions, eventListOptions]);

  const removeSinglePill = useCallback(
    async ({ _id, label, type }) => {
      if (type === FILTER_CONST[EVENT_LIST]) {
        const arr = filterParams[FILTER_CONST[EVENT_LIST]]?.filter(
          (item) => item !== _id,
        );
        dispatch(
          updateFilter({
            ...filterParams,
            event_list: [...arr],
            page: 1,
          }),
        );
        dispatch(updateFilterLoading(true));
        return filter_mutation.mutate();
      }

      if (type === FILTER_CONST[ARTIST_TYPE]) {
        const arr = filterParams[FILTER_CONST[ARTIST_TYPE]]?.filter(
          (item) => item !== _id,
        );

        dispatch(
          updateFilter({
            ...filterParams,
            artist_type: [...arr],
            page: 1,
          }),
        );
        dispatch(updateFilterLoading(true));
        return filter_mutation.mutate();
      }

      if (type === FILTER_CONST[ARTIST_SUB_TYPE]) {
        const arr = filterParams[FILTER_CONST[ARTIST_SUB_TYPE]]?.filter(
          (item) => item !== _id,
        );
        dispatch(
          updateFilter({
            ...filterParams,
            artist_sub_type: [...arr],
            page: 1,
          }),
        );
        dispatch(updateFilterLoading(true));
        return filter_mutation.mutate();
      }
      if (type === FILTER_CONST[TOUR]) {
        // const arr = filterParams[FILTER_CONST[TOUR]]?.filter( // always be single element array
        //   (item) => item !== _id,
        // );
        dispatch(updatePopover(false));
        dispatch(updateisFiltered(false));
        dispatch(updateForm(false));
        dispatch(
          updateFilter({
            ...filterParams,
            tour: undefined,
            page: 1,
          }),
        );
        dispatch(updateFilterLoading(true));
        return filter_mutation.mutate();
      }
    },
    [dispatch, filterParams, filter_mutation],
  );

  const removeZeroCount = useCallback((artistData) => {
    return artistData?.filter((item) => item?.count !== 0);
  }, []);

  const filter_options = useMemo(
    () => [
      eventListOptions,
      removeZeroCount(artistTypeOptions),
      artistSubTypeOptions,
    ],
    [
      eventListOptions,
      artistTypeOptions,
      artistSubTypeOptions,
      removeZeroCount,
    ],
  );

  const obj = useMemo(() => {
    return filter_labels.reduce((accumulator, key, index) => {
      return { ...accumulator, [key]: filter_options[index] };
    }, {});
  }, [filter_labels, filter_options]);

  return {
    obj,
    all_filters_pills,
    removeSinglePill,
    AntdForm: form,
  };
};

export function useAllArtistFilterAction() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { filterParams, all_artist, all_artist_count } = useSelector(
    (state) => state.allArtistReducer,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const limit_ref = useRef(null);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const valid_params = useMemo(() => {
    const temp_params = { ...filterParams };
    for (const key in temp_params) {
      if (
        temp_params[key] == null ||
        (Array.isArray(temp_params[key]) && !temp_params[key]?.length)
      ) {
        delete temp_params[key];
      }
    }
    return new URLSearchParams(temp_params).toString();
  }, [filterParams]);

  const filter_mutation = useMutation(all_artists_list_filter, {
    onSuccess: (res) => {
      const { data, count } = res;
      dispatch(updateAllArtist({ array: data, count: count }));
      dispatch(
        updateFilterPills(
          Object.fromEntries(new URLSearchParams(location.search).entries()),
        ),
      );
      dispatch(updateFilterLoading(false));
    },
  });

  const { data: all_filter_count, mutate } = useMutation(
    all_artist_filter_count,
    {
      onSuccess: (res) => {
        updateAllArtistsFilterCount(res);
        // dispatch(update)
      },
    },
  );

  // console.log('all_filter_count', all_filter_count);

  const fetchArtists = useCallback(
    async ({ should_paginate = false }) => {
      // console.log('here1111', isLoading);

      if (isLoading) return null;
      if (should_paginate) {
        setIsLoading(true);
        await dispatch(
          updateFilter({ ...filterParams, page: filterParams?.page + 1 }),
        );
        const { data, count } = await all_artists_list_filter();
        if (Array.isArray(data)) {
          // if (data.length) {
          // const arr = all_event?.concat(data)
          // console.log('here');
          const arr = [...all_artist, ...data];
          await dispatch(updateAllArtist({ array: arr, count: count }));

          setIsLoading(false);
          setShowSkeleton(false);
          // }
        }
      } else {
        await dispatch(updateFilterLoading(true));
        filter_mutation.mutate();
      }
    },
    [dispatch, all_artist, isLoading, filterParams, filter_mutation],
  );

  const clear_filter = useCallback(async () => {
    await dispatch(updateFilter(initialFilterParams));
    return await fetchArtists({});
  }, [dispatch, fetchArtists]);

  const reset_filter = useCallback(async () => {
    return await dispatch(updateFilter(initialFilterParams));
  }, [dispatch]);

  const apply_filter = useCallback(async () => {
    await dispatch(updateFilter({ ...filterParams, page: 1 }));
    return await fetchArtists({});
  }, [fetchArtists, dispatch, filterParams]);

  const isFilterTouched = useMemo(() => {
    const searchObj = Object.fromEntries(
      new URLSearchParams(location.search).entries(),
    );
    delete searchObj['page'];
    delete searchObj['limit'];
    delete searchObj['search_key'];
    delete searchObj['sort_by'];

    const temp = Object.keys(filterParams).some((key) => {
      return Boolean(key in searchObj);
    });

    return temp;
  }, [filterParams, location.search]);

  // console.log('isFilterTouched', isFilterTouched);

  const loadMore = useCallback(async () => {
    if (isLoading || showSkeleton) return null;
    return setTimeout(
      async () => await fetchArtists({ should_paginate: true }),
      1000,
    );
  }, [fetchArtists, isLoading, showSkeleton]);

  const hasMore = useMemo(() => {
    return all_artist?.length < all_artist_count;
  }, [all_artist?.length, all_artist_count]);

  return useMemo(
    () => ({
      all_artist,
      clear_filter,
      reset_filter,
      valid_params,
      fetchArtists,
      isLoading,
      apply_filter,
      searchParams,
      setSearchParams,
      isFilterTouched,
      limit: limit_ref.current,
      loadMore,
      hasMore,
      showSkeleton: filter_mutation.isLoading,
      filter_mutation,
      all_artists_count_mutation: mutate,
      all_filter_count: all_filter_count ?? {},
    }),
    [
      all_artist,
      clear_filter,
      reset_filter,
      valid_params,
      fetchArtists,
      isLoading,
      apply_filter,
      searchParams,
      setSearchParams,
      isFilterTouched,
      loadMore,
      hasMore,
      filter_mutation,
      mutate,
      all_filter_count,
    ],
  );
}

export const useAllArtistsFilterSorting = (
  tourOptions,
  menuOptions,
  fetchArtists,
  handleSortBy,
  handleFilterTourEvents,
) => {
  const dispatch = useDispatch();
  const [filterform] = Form.useForm();
  const [sortform] = Form.useForm();
  const { isFormUpdate, isSortingFormUpdate } = useSelector(
    (state) => state?.Popover,
  );

  const { filterParams } = useSelector((s) => s.allEventReducer);
  const filterHeadings = useMemo(() => {
    return ['Artists/Teams', 'Sort by alphabetically'];
  }, []);

  const lableValue = useCallback((lable, dropdownCount) => {
    return (
      <span className="flex justify-between items-center gap-2">
        <span>{lable}</span>
        {/* <span className="selectedValue bg-sky-600 text-white text-[11px] min-w-[1rem] h-[1rem] flex justify-center items-center px-0.5 rounded-[4px] leading-tight">
          {dropdownCount}
        </span> */}
      </span>
    );
  }, []);
  // console.log('filters', filters);
  const EventsOptions = useMemo(() => {
    return tourOptions?.map((item) => ({
      value: item?.value, // desc
      id: item?.id,
      label: lableValue(item?.name, 0),
      action: item?.action,
    }));
  }, [tourOptions, lableValue]);

  const sortingOptions = useMemo(() => {
    return menuOptions?.map((item) => ({
      value: item?.value, // desc
      id: item?.id,
      label: lableValue(item?.name, 0),
      action: item?.action,
    }));
  }, [menuOptions, lableValue]);

  const filtersList = useMemo(() => {
    const filtersdata = {
      filter1: (
        <Select
          // onDropdownVisibleChange={(value) => {
          //   console.log(value, 'value');
          // }}
          style={{ width: '100%' }}
          placeholder={'Select Tour Association'}
          // value={showVlaue(filters?.event_mode)}
          onChange={(value, record) => {
            // record?.action();
            dispatch(updateForm(true));
          }}
          defaultValue={'All'}
          suffixIcon={
            <>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                <BsChevronDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </>
          }
          className={`rounded-lg filterDrop`}
          options={EventsOptions}
          size="middle"
        />
      ),
      filter2: (
        <Select
          style={{ width: '100%' }}
          placeholder={'Sort By'}
          onChange={(value, record) => {
            record?.action();
            dispatch(updateSortingForm(true));
            dispatch(updateSortingPopover(false));
            dispatch(updateisSorted(true));
            dispatch(updateSortingForm(true));
          }}
          // value={tourCat}
          // defaultValue={'New To Old'}
          suffixIcon={
            <>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                <BsChevronDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </>
          }
          className={`rounded-lg filterDrop`}
          options={sortingOptions}
          size="middle"
        />
      ),
    };
    return Object.values(filtersdata);
  }, [EventsOptions, sortingOptions, dispatch]);

  const onFilter = useCallback(async () => {
    dispatch(updatePopover(false));
    dispatch(updateisFiltered(true));
    dispatch(updateForm(true));
    await handleFilterTourEvents(filterform.getFieldValue('filter0'));
    await fetchArtists({});
  }, [dispatch, fetchArtists, filterform, handleFilterTourEvents]);

  // const onSort = useCallback(async () => {
  //   dispatch(updateSortingPopover(false));
  //   dispatch(updateisSorted(true));
  //   dispatch(updateSortingForm(true));
  //   await handleSortBy(sortform.getFieldValue('filter0'));
  //   await fetchArtists({});
  // }, [dispatch, fetchArtists, sortform, handleSortBy]);

  // const onClearSort = useCallback(async () => {
  //   dispatch(updateSortingPopover(false));
  //   dispatch(updateisSorted(false));
  //   dispatch(updateSortingForm(false));
  //   sortform.resetFields();
  //   await dispatch(
  //     updateFilter({ ...filterParams, sort_by: undefined, page: 1 }),
  //   );
  //   await fetchArtists({});
  // }, [dispatch, filterParams, fetchArtists, sortform]);

  const onClear = useCallback(async () => {
    dispatch(updatePopover(false));
    dispatch(updateisFiltered(false));
    dispatch(updateForm(false));
    await filterform.resetFields();
    await filterform.setFieldValue('filter0', null);
    await dispatch(updateFilter({ ...filterParams, tour: undefined, page: 1 }));
    await fetchArtists({});
  }, [dispatch, filterParams, fetchArtists, filterform]);

  const onClearAll = useCallback(async () => {
    dispatch(updateisFiltered(false));
    dispatch(updateForm(false));
    await filterform.resetFields();
    await filterform.setFieldValue('filter0', null);
  }, [dispatch, filterform]);

  const getFilterData = useCallback(
    (isSorting) => {
      if (isSorting) {
        return {
          form: sortform,
          filtersList: [filtersList?.[1]],
          filterHeadings: [filterHeadings?.[1]],
          // buttons: {
          //   onClear: onClearSort,
          //   onFilter: onSort,
          // },
          isSorting: true,
        };
      } else {
        return {
          form: filterform,
          filtersList: [filtersList?.[0]],
          filterHeadings: [filterHeadings?.[0]],
          buttons: {
            onClear,
            onFilter,
          },
        };
      }
    },
    [
      filterHeadings,
      filtersList,
      filterform,
      sortform,
      onClear,
      onFilter,
      // onClearSort,
      // onSort,
    ],
  );

  useEffect(() => {
    if (!isFormUpdate) {
      filterform.resetFields();
    } else if (!isSortingFormUpdate) {
      sortform.resetFields();
    }
  }, [isFormUpdate, isSortingFormUpdate, filterform, sortform]);

  useEffect(() => {
    // dispatch(updateDefaultCount(1));
    dispatch(updateFilterCount(1));
    filterform.setFieldValue('filter0', null);
  }, [filterform, dispatch]);

  const value = useMemo(() => {
    return {
      getFilterData,
      onClearAll,
      // onClearSort,
    };
  }, [getFilterData, onClearAll]);

  return value;
};
