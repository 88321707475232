import React from 'react';
import { Link } from 'react-router-dom';
import { LogoGoTix } from '../util/CustomIcons';
import useDecodedToken from '../../hooks/useDecodedToken';
import HeaderRightSideNavigation from './header_compo/HeaderRightSideNavigation';
import SelectCountryDrawer from './header_compo/country_drawer/SelectCountryDrawer';
import { useUserDetails } from '../../hooks/user/useUserDetails';
import { useDispatch, useSelector } from 'react-redux';
import { viewSingleNFT } from '../../features/nft/nft.slice';
import SearchBarHeader from './header_compo/header_search/SearchBarHeader';

function HeaderLandingPageV2({ btnClassName, darkTheme, hideCompo, demo }) {
  // let navigate = useNavigate();
  // const { pathname } = useLocation();
  const decodedToken = useDecodedToken();
  const dispatch = useDispatch();

  useUserDetails(decodedToken?.username || decodedToken?.id);

  const showDetailNFTView = useSelector((state) => state?.ShowSinglenft);
  // const decodedToken = useDecodedToken();

  // $(document).ready(function () {
  //   $(window).scrollTop(0);
  // });
  // function hello() {
  //   $(window).scrollTop(0);
  // }
  // hello();
  // setTimeout(hello, 500);
  return (
    <React.Fragment>
      <div
        className={`sticky z-50 top-0 left-0 w-full bg-color-base-1 dark:bg-color-base-header-1 backdrop-blur-2xl`}
        //  ${afterBorder}
        // bg-opacity-20 backdrop-blur-2xl ${afterBorder}
        //  bg-opacity-40 backdrop-blur-lg
      >
        <div className="container-fluid max-w-[1800px]">
          <div className="flex flex-col gap-2 py-2.5 lg:px-3">
            <div className="flex justify-between items-center gap-3">
              <div>
                <div className="flex-shrink-0 relative h-12 w-20 md:w-24 lg:w-36">
                  <Link
                    to={'/'}
                    state={{ redirected: true }}
                    className="flex items-center gap-2"
                    onClick={() => {
                      showDetailNFTView?.status &&
                        dispatch(
                          viewSingleNFT({
                            status: false,
                            NFTID: '',
                          }),
                        );
                    }}
                  >
                    <LogoGoTix
                      className={`w-full absolute inset-0 h-full text-neutral-950 dark:text-white`}
                    />
                  </Link>
                </div>
              </div>
              {hideCompo && (
                <div className="w-full max-w-lg hidden lg:flex">
                  {/* <HeaderSearchBar /> */}
                  <SearchBarHeader />
                </div>
              )}

              <div className="md:shrink-0 w-full max-w-md">
                <HeaderRightSideNavigation
                  hideCompo={hideCompo}
                  // darkTheme={darkTheme}
                  demo={demo}
                />
                {/* {decodedToken && !onlyLogo ? (
                <div className="flex shrink-0 gap-2.5">
                  {pathname !== '/demo' && (
                    <div>
                      <button
                        onClick={() => navigate('/demo')}
                        className="p-2 bg-landingPurple-60 text-gray-50 rounded-lg hover:bg-landingPurple-70 duration-200 ease-in md:w-24"
                      >
                        Demo
                      </button>
                    </div>
                  )}
                  <div>
                    <NotificationDropdown
                      btnClassName={btnClassName}
                      headerDark={headerDark}
                    />
                  </div>

                  <div>
                    <Tooltip title="Wallet" placement={'bottom'}>
                      <Link
                        to={`/user/${decodedToken?.username}/dashboard?tab=about&subtab=myaccount`}
                        className={`${
                          headerDark
                            ? 'flex text-opacity-90 w-[35px] h-[35px] bg-landingPurple-60 text-gray-50 shadow-none font-normal hover:bg-landingPurple-70 hover:text-white rounded-full btnDark relative'
                            : ''
                        }`}
                      >
                        <CustomWalletIcon
                          className={`absolute top-1/2 left-1/2 translate-neg-50-50 object-cover h-5 w-5 btnDark`}
                        />
                      </Link>
                    </Tooltip>
                  </div>
                  <div>
                    <UserProfileDropdown
                      btnClassName={btnClassName}
                      headerDark={headerDark}
                    />
                  </div>
                </div>
              ) : !onlyLogo ? (
                <div className="flex shrink-0 gap-2">
                  {pathname !== '/demo' && (
                    <button
                      onClick={() => navigate('/demo')}
                      className="p-2 bg-landingPurple-60 text-gray-50 rounded-lg hover:bg-landingPurple-70 duration-200 ease-in md:w-24"
                    >
                      Demo
                    </button>
                  )}
                  <button
                    onClick={() => navigate('/user/signup')}
                    className="p-2 bg-landingPurple-60 text-gray-50 rounded-lg hover:bg-landingPurple-70 duration-200 ease-in md:w-24"
                  >
                    Sign Up
                  </button>
                  <button
                    onClick={() => navigate('/user/login')}
                    className="p-2 bg-landingPurple-60 text-gray-50 rounded-lg hover:bg-landingPurple-70 duration-200 ease-in md:w-24"
                  >
                    Log In
                  </button>
                </div>
              ) : (
                <div className="absolute top-4 right-4 z-10">
                  <button
                    type="button"
                    onClick={() => {
                      window.close();
                    }}
                    className="bg-landingPurple-60 border-2 border-landingPurple-60 hover:border-landingPurple-70 text-whiteNew-0 hover:shadow-xl shadow-slate-500 duration-150 ease-in w-8 h-8 height-auto min-auto flex gap-2 roundrem-49 relative p-0"
                  >
                    <AiOutlineClose className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover h-4 w-4" />
                  </button>
                </div>
              )} */}
              </div>
            </div>
            {hideCompo && (
              <div className="w-full flex justify-between gap-2 lg:hidden">
                {/* <HeaderSearchBar /> */}
                <SearchBarHeader />
                <SelectCountryDrawer />
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default React.memo(HeaderLandingPageV2);
