import axios from 'axios';
import config from '../../../../api/Config';

export async function createTickets(body, headers) {
  // setLoading(true);
  const res = await axios.post(
    config.baseUrl + config.endpoints.create_ticket,
    body,
    headers,
  );

  // if (res.data) {
  //   setLoading(false);
  // }
  return res.data;
}

export async function getTicketList(id, page, headers) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.ticket_list
    }/${id}?limit=100&page=${page}`,
    headers,
  );
  return res.data;
}

export async function getAllTicketList(page, headers, limit, event_id) {
  const res = await axios.get(
    `${config.baseUrl + config.endpoints.create_ticket}?limit=${
      limit ? limit : 100
    }&page=${page}${
      event_id === 'empty'
        ? '&event_id=empty'
        : event_id === 'event_specific'
        ? '&event_id=event_specific'
        : '&event_id=all'
    }`,
    headers,
  );
  return res.data;
}

export async function getBuyerDetails(event_id, cat_id, headers) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.view_buyer_details
    }?event_id=${event_id}&category_id=${cat_id}`,
    headers,
  );

  return res.data;
}

export async function deleteTicket(payload, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.delete_ticket,
    payload,
    headers,
  );
  return res.data;
}

export async function cancelTicket(payload, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.cancel_ticket,
    payload,
    headers,
  );
  return res.data;
}

export async function shareVirtualTicket(payload, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.share_virtual_ticket,
    payload,
    headers,
  );
  return res.data;
}

export async function shareOrderOnEmail(payload, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.share_order_on_email,
    payload,
    headers,
  );
  return res.data;
}
export async function resaleTicket(payload, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.resale_ticket,
    payload,
    headers,
  );
  return res.data;
}
export async function cancelResaleTicket(payload, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.cancel_resale_ticket,
    payload,
    headers,
  );
  return res.data;
}

export async function getSingleTicket(id, headers) {
  const res = await axios.get(
    config.baseUrl + config.endpoints.ticket_details + id,
    headers,
  );

  return res.data;
}

export async function updateTickets(body, headers) {
  // setLoading(true);
  const res = await axios.put(
    config.baseUrl + config.endpoints.update_tickets,
    body,
    headers,
  );
  // if (res.data) {
  //   setLoading(false);
  // }

  return res.data;
}

export async function bookTickets(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.book_ticket,
    body,
    headers,
  );

  return res.data;
}
export async function updateCartSeatsDetails(body, headers) {
  const res = await axios.put(
    config.baseUrl + config.endpoints.updateCartSeatsDetails,
    body,
    headers,
  );

  return res.data;
}

export async function bookedTicketList(
  orderPage,
  orderLimit,
  event_type,
  event_status,
  order_type,
  event_slug = undefined,
  streaming = false,
) {
  let url =
    config.baseUrl +
    config.endpoints.booked_ticket_list +
    `?page=${orderPage}&limit=${orderLimit}&event_type=${event_type}&event_status=${event_status}&sort_type=${order_type}
    ${streaming ? `&streaming=${streaming}` : ''}`;
  // &streaming=${streaming}`;
  if (event_slug) {
    url = String(url).concat(`&event_slug=${event_slug}`);
  }
  const res = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  return res.data;
}
export async function bookedTicketLogs(
  ticket_id = undefined,
  order_id = undefined,
) {
  let url = ticket_id
    ? config.baseUrl + config.endpoints.getLogs + `?ticket_id=${ticket_id}`
    : config.baseUrl + config.endpoints.getLogs + `?order_id=${order_id}`;
  const res = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  return res.data;
}

export async function bookedTicketListByUserByEvent(
  event_id,
  user_id,
  headers,
) {
  const url = `/${event_id}/${user_id}`;
  const res = await axios.get(
    config.baseUrl + config.endpoints.booked_ticket_event_user + url,
    headers,
  );

  return res.data;
}

export async function locations(headers, famousVenue) {
  const res = await axios.get(
    `${config.baseUrl + config.endpoints.get_location}${
      famousVenue ? '?famous_venue=true' : ''
    }`,
    headers,
  );

  return res.data;
}

export async function venue_filterList(headers) {
  const res = await axios.get(
    config.baseUrl + config.endpoints.get_venue_filter,
    headers,
  );

  return res.data;
}

export async function getTicketTemplate(page, headers, limit, event_id) {
  const res = await axios.get(
    `${config.baseUrl + config.endpoints.ticket_template + page}&limit=${
      limit ? limit : 100
    }${event_id === 'empty' ? '&event_id=empty' : ''}`,
    headers,
  );
  return res.data;
}

export async function getTicketTemplateType(page, headers, limit, event_id) {
  console.log(event_id);
  const res = await axios.get(
    `${config.baseUrl + config.endpoints.category_User + page}&limit=${
      limit ? limit : 100
    }${
      event_id === 'empty'
        ? '&event_id=empty'
        : event_id === 'event_specific'
        ? '&event_id=event_specific'
        : '&event_id=all'
    }`,
    headers,
  );
  return res.data;
}

export async function getTicketType(page, headers, limit) {
  const res = await axios.get(
    `${config.baseUrl + config.endpoints.ticket_type + page}&limit=${
      limit ? limit : 100
    }`,
    headers,
  );
  return res.data;
}

export async function CheckTicketTypeName(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.category_name,
    body,
    headers,
  );

  return res.data;
}

export async function CheckTicketDesignName(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.check_name,
    body,
    headers,
  );

  return res.data;
}

export async function CheckTicketTypeNFTName(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.nft_name,
    body,
    headers,
  );

  return res.data;
}

export async function CancelSharedTicket(body, headers) {
  const res = await axios.put(
    config.baseUrl + config.endpoints.cancel_shared_ticket,
    body,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    },
  );

  return res.data;
}
