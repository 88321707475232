import React, { useEffect, useState } from 'react';
import { CopyIcon } from '../../components/util/CustomIcons';
import { MdOutlineCheck } from 'react-icons/md';
// import useSnackbarJS from './useSnackbarJS';
// import { CopyIcon } from '../components/LandingPages/Icons/CustomIcon'

// const CopyIcon = () => <i className="fa fa-clone" aria-hidden="true"></i>;

const Checkmark = ({ className }) => (
  <span className={className}>
    <MdOutlineCheck />
  </span>
);

export const CopyToClipboard = ({ copyText, className, copyIcon = null }) => {
  const [clicked, setClicked] = useState(false);

  // const { snackbar } = useSnackbarJS();
  useEffect(() => {
    const timer = setTimeout(() => {
      setClicked(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [clicked]);

  const onClick = () => {
    navigator.clipboard.writeText(copyText);
    setClicked(true);
    // snackbar('Copied...', {
    //   variant: 'info',
    // });
  };

  return (
    <div
      onClick={onClick}
      type={'button'}
      className="flex flex-row items-center py-0"
    >
      <span>
        {clicked ? (
          <Checkmark className={` ${className}`} />
        ) : (
          <>
            {copyIcon ? copyIcon : <CopyIcon className={` ${className}`} />}

            {/* <span className="ms-2 text-neutral-900">Copy</span> */}
          </>
        )}
      </span>
    </div>
  );
};
