import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import mydecodedTokenFunction from '../../utils/DecodedToken';

const decodedToken = mydecodedTokenFunction(localStorage.getItem('token'));
// console.log('decodedToken', decodedToken);
const getDatasUserid = (from) => {
  if (from === 'cartvirtualData') {
    if (
      localStorage.getItem(
        `selectedVirtualTickets${
          decodedToken ? decodedToken?.id : 'GuestUser'
        }`,
      )
    ) {
      return JSON.parse(
        localStorage.getItem(
          `selectedVirtualTickets${
            decodedToken ? decodedToken?.id : 'GuestUser'
          }`,
        ),
      );
    } else {
      return {};
    }
  } else {
    if (
      localStorage.getItem(
        `selectedInPersonTickets${
          decodedToken ? decodedToken?.id : 'GuestUser'
        }`,
      )
    ) {
      return JSON.parse(
        localStorage.getItem(
          `selectedInPersonTickets${
            decodedToken ? decodedToken?.id : 'GuestUser'
          }`,
        ),
      );
    } else {
      return {};
    }
  }
};

const getSubDatasUserid = (subData) => {
  // console.log('decodedToken', decodedToken);
  if (
    localStorage.getItem(
      `selectedInPersonTickets${decodedToken ? decodedToken?.id : 'GuestUser'}`,
    ) ||
    localStorage.getItem(
      `selectedVirtualTickets${decodedToken ? decodedToken?.id : 'GuestUser'}`,
    )
  ) {
    return JSON.parse(
      localStorage.getItem(
        `selectedInPersonTickets${
          decodedToken ? decodedToken?.id : 'GuestUser'
        }`,
      ) ||
        localStorage.getItem(
          `selectedVirtualTickets${
            decodedToken ? decodedToken?.id : 'GuestUser'
          }`,
        ),
    )?.[subData];
  }
};
// console.log(getUserid());
const initialState = {
  cartInpersonData: getDatasUserid('cartInpersonData'),
  cartvirtualData: getDatasUserid('cartvirtualData'),
  cartDataType: getSubDatasUserid('eventType'),
  selectedEvent: getSubDatasUserid('selectedEvent'),
  slug: getSubDatasUserid('slug'),
};

const getTriedAttempt = (cookieName) => {
  var cookies = document.cookie.split(';');

  // Loop through the cookies to find the one with the specified name
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim(); // Remove leading and trailing spaces

    // Check if the cookie starts with the desired name
    if (cookie.indexOf(cookieName + '=') === 0) {
      // Extract and return the value of the cookie
      return cookie.substring(cookieName.length + 1, cookie.length);
    }
  }

  // Return null if the cookie is not found
  return 0;
};

const timer = {
  openTimerAttemptModal: false,
  triedAttempt: getTriedAttempt(
    `TotalAttempt${decodedToken ? decodedToken?.id : 'GuestUser'}`,
  ),
};

const CartError = {
  Virtual: false,
  Inperson: false,
};

const TimeExtended = {
  count: 0,
};

const slice = createSlice({
  name: 'SelectedSeats',
  initialState,
  reducers: {
    AddCartInpersonData: (state, { payload }) => {
      state.cartInpersonData = payload;
    },
    AddCartvirtualData: (state, { payload }) => {
      state.cartvirtualData = payload;
    },
    AddcartDataType: (state, { payload }) => {
      state.cartDataType = payload;
    },
    AddEventDetails: (state, { payload }) => {
      state.selectedEvent = payload;
    },
    AddSlug: (state, { payload }) => {
      state.slug = payload;
    },
    DeleteCartInpersonData: (state, { payload }) => {
      state.cartInpersonData = payload;
    },
    DeleteCartvirtualData: (state, { payload }) => {
      state.cartvirtualData = payload;
    },
    DeletecartDataType: (state, { payload }) => {
      state.cartDataType = payload;
    },
    DeleteEventDetails: (state, { payload }) => {
      state.selectedEvent = payload;
    },
    DeleteSlug: (state, { payload }) => {
      state.slug = payload;
    },
    DeleteAllCart: (state, { payload }) => {
      state.cartInpersonData = {};
      state.cartvirtualData = {};
      // state.cartDataType = '';
      state.selectedEvent = {};
      state.slug = '';
    },
  },
});
const Timerslice = createSlice({
  name: 'TimerAttemptModalSlice',
  initialState: timer,
  reducers: {
    setOpenTimerattemptModal: (state, { payload }) => {
      state.openTimerAttemptModal = payload; // Set timer to 60 seconds
    },
    updateTotalAttempt: (state, { payload }) => {
      console.log('payload', payload);
      state.triedAttempt = payload; // Set timer to 60 seconds
    },
  },
});

const CartErrorslice = createSlice({
  name: 'TimerSlice',
  initialState: CartError,
  reducers: {
    updateVirtualError: (state, { payload }) => {
      state.Virtual = payload;
    },
    updateInPersonError: (state, { payload }) => {
      state.Inperson = payload;
    },
  },
});

const TimeExtendedSlice = createSlice({
  name: 'TimeExtended',
  initialState: TimeExtended,
  reducers: {
    extendTime: (state, { payload }) => {
      state.count = payload;
    },
  },
});

const cartReducer = slice.reducer;
const cartTimerAttemptModalReducer = Timerslice.reducer;
const cartErrorReducer = CartErrorslice.reducer;
const ExtendTimeReducer = TimeExtendedSlice.reducer;

const cartData = (state) => state.cartDatas;
const openTimerAttemptModal = (state) => state.cartTimerAttemptModal;
const cartError = (state) => state.cartCheckoutError;
const ExtendCartTime = (state) => state.extendCartTime;

export const {
  AddCartInpersonData,
  AddCartvirtualData,
  AddcartDataType,
  AddEventDetails,
  AddSlug,
  DeleteCartInpersonData,
  DeleteCartvirtualData,
  DeletecartDataType,
  DeleteEventDetails,
  DeleteSlug,
  DeleteAllCart,
} = slice.actions;
export const { setOpenTimerattemptModal, updateTotalAttempt } =
  Timerslice.actions;
export const { updateVirtualError, updateInPersonError } =
  CartErrorslice.actions;
export const { extendTime } = TimeExtendedSlice.actions;

export {
  cartReducer,
  cartData,
  cartTimerAttemptModalReducer,
  openTimerAttemptModal,
  cartErrorReducer,
  cartError,
  ExtendTimeReducer,
  ExtendCartTime,
};
