import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isCropperModal: false,
  imageUrl: '',
};

const slice = createSlice({
  name: 'cropper',
  initialState,
  reducers: {
    setOpenCropper: (state, { payload }) => {
      state.isCropperModal = payload;
    },
    updateCropperImage: (state, { payload }) => {
      state.imageUrl = payload;
    },
  },
});

const cropperReducer = slice.reducer;

export const { setOpenCropper, updateCropperImage } = slice.actions;
const getCropperDetails = (state) => state.cropperDatas;

export { cropperReducer, getCropperDetails };
