import { RadioGroup } from '@headlessui/react';
import { useWallet } from '@solana/wallet-adapter-react';
import React from 'react';
import { ArtchiveWalletName } from '../../contexts/artchive/adapter';
import useMetaMask from '../../contexts/MetamaskProvider';
import { useWalletHooks } from '../../hooks/useWalletConnected';
import { Controller } from 'react-hook-form';

function BlockchainSelectRadioGroup({
  control,
  selectedBlockchain,
  setSelectedBlockchain,
}) {
  const { select } = useWallet();
  const { connect } = useMetaMask();
  const { walletConnected } = useWalletHooks();

  const mockData = [
    {
      name: 'Solana',
      icon: 'https://onekey-asset.com/assets/sol/sol.png',
      onclick: () => select(ArtchiveWalletName),
    },
    {
      name: 'EVM (ETH, Matic, AVAX, BNB)',
      icon: 'https://onekey-asset.com/assets/eth/eth.png',
      onclick: () => connect(true, false),
    },
  ];
  return (
    <div className="w-full pt-7 pb-3">
      <div className="mx-auto w-full max-w-lg">
        <Controller
          name="wallet"
          control={control}
          render={({ field: { onChange } }) => (
            <RadioGroup
              value={selectedBlockchain}
              onChange={(e) => {
                setSelectedBlockchain(e);
                onChange(e);
                // e?.onclick()
                localStorage.setItem('wallet_name', ArtchiveWalletName);
              }}
            >
              <RadioGroup.Label className="sr-only">option</RadioGroup.Label>
              <div className="space-y-4">
                {mockData.map((plan, idx) => (
                  <RadioGroup.Option
                    key={idx}
                    value={plan}
                    disabled={walletConnected}
                    className={({ active, checked }) =>
                      `${
                        selectedBlockchain?.name === plan?.name
                          ? 'ring-0 ring-white ring-opacity-60 ring-offset-0 border-1 border-sky-600'
                          : ' border-level-2'
                      }
                  ${
                    selectedBlockchain?.name === plan?.name
                      ? 'bg-transparent border-1 border-sky-600 bg-opacity-75'
                      : 'bg-color-base-1 border-level-2'
                  }
                    relative flex cursor-pointer rounded-lg px-5 py-3 focus:outline-none border-2`
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <div className="flex w-full items-center justify-between">
                          <div className="flex items-center">
                            <div className="text-sm">
                              <RadioGroup.Label
                                as="div"
                                className={`font-medium  ${
                                  checked ? 'text-white' : 'text-gray-900'
                                }`}
                              >
                                <React.Fragment>
                                  <div className="flex justify-center items-center gap-3">
                                    <div className="shrink-0">
                                      {
                                        <img
                                          alt={plan?.name}
                                          src={plan?.icon}
                                          width={40}
                                          height={40}
                                        />
                                      }
                                    </div>
                                    <div>
                                      <span className="flex mb-0 text-dark text-xl">
                                        {plan?.name}
                                      </span>
                                    </div>
                                  </div>
                                </React.Fragment>
                              </RadioGroup.Label>
                            </div>
                          </div>
                          <div className="absolute top-1/2 -translate-y-1/2 right-2 shrink-0 text-white">
                            {selectedBlockchain?.name === plan?.name ? (
                              <span className="flex w-5 h-5 border-2 border-transparent rounded-full bgPrimary"></span>
                            ) : (
                              <span className="flex w-5 h-5 border-2 border-gray-400 rounded-full"></span>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          )}
        />
      </div>
    </div>
  );
}

export default BlockchainSelectRadioGroup;
