import useSWR, { useSWRConfig } from 'swr';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateLocation,
  updatePopularStates,
} from '../../../../features/home_page/homepage.slice';
import {
  getPopularStates,
  getUserLocation,
} from '../../../../helpers/api/event/event';
import { convertTo2DArray } from '../../../../utils/util';

export const useHeaderHooks = (search) => {
  let dispatch = useDispatch();
  let popularStates = useSelector((state) => state?.popularStates);

  const PopularStatesdetails = useSWR(
    ['Popular_States', search],
    async () => {
      if (popularStates?.popular_states?.length >= 0 && search === '') {
        return await getPopularStates(search);
      } else if (popularStates?.popular_states?.length > 0 && search !== '') {
        return await getPopularStates(search);
      }
    },
    {
      // refreshInterval: 60 * 1000,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      keepPreviousData: true,
      onSuccess: (res) => {
        dispatch(updatePopularStates(convertTo2DArray(res?.data, 5, 2)));
      },
    },
  );

  return {
    PopularStatesdetails,
  };
};
