import React from 'react';
import NoDataFoundv2 from '../NoDataFoundv2';

export default function MessagingSection({
  activeSection,
  setActiveSection,
  setActiveSubSection,
  activeSubSection,
}) {
  return (
    <React.Fragment>
      <div className="relative min-h-[600px] max-h-[60vh] inset-0">
        <div className="h-full flex items-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <NoDataFoundv2>No Data Found</NoDataFoundv2>
        </div>
      </div>
    </React.Fragment>
  );
}
