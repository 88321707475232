import React from 'react';
import LeftSideBar from '../../common/LeftSideBar';
import { useNavigate } from 'react-router';

export default function NotificationEmailSidebar({
  activeSection,
  setActiveSection,
  setActiveSubSection,
  activeSubSection,
}) {
  const navigate = useNavigate();
  const handleNavigation = (href) => {
    navigate(href);
  };
  return (
    <React.Fragment>
      {/* <LeftSideBar
        title={
          <>
            <span className="flex items-center gap-2">
              <span>Notification</span>
            </span>
          </>
        }
        secondarySidebar={true}
        // openAccordActive={activeSection === 'contactInfo' ? true : false}
        openAccordActive={true}
        // accordOnClick={() => {
        //   setActiveSection('contactInfo');
        // }}
      />
      <LeftSideBar
        title={
          <>
            <span className="flex items-center gap-2">
              <span>Email</span>
            </span>
          </>
        }
        secondarySidebar={true}
        // openAccordActive={activeSection === 'contactInfo' ? true : false}
        // accordOnClick={() => {
        //   setActiveSection('contactInfo');
        // }}
      /> */}
      <LeftSideBar
        title={
          <>
            <span className="flex items-center gap-2">
              <span>Notification & Email</span>
            </span>
          </>
        }
        menu={[
          {
            name: 'Notification',
            href: '#',
            // icon: (
            //   <>
            //     <BsInfoCircle />
            //   </>
            // ),
            active:
              activeSection === 'notification_email' &&
              activeSubSection === 'notification'
                ? true
                : false,
            onClick: (e) => {
              // handleNavigation('#');
              e.stopPropagation();
              setActiveSubSection('notification');
            },
          },
          {
            name: 'Email',
            href: '#',
            // icon: (
            //   <>
            //     <MdOutlineEditLocation />
            //   </>
            // ),
            active:
              activeSection === 'notification_email' &&
              activeSubSection === 'email'
                ? true
                : false,
            onClick: (e) => {
              // handleNavigation('#');
              e.stopPropagation();
              setActiveSubSection('email');
            },
          },
        ]}
        secondarySidebar={true}
        openAccordActive={activeSection === 'notification_email' ? true : false}
        accordOnClick={() => {
          setActiveSection('notification_email');
          setActiveSubSection('notification');
        }}
      />
    </React.Fragment>
  );
}
