import { RadioGroup } from '@headlessui/react';
import { Checkbox, Form, Input, Select, message } from 'antd';
import { MdAdd, MdContentCopy, MdWallet } from 'react-icons/md';
import { BsChevronDown } from 'react-icons/bs';
import {
  antCustomSelect,
  btnPrimaryClass,
  btnSecondayClass,
  formClass,
} from '../common/DefaultClasses';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, memo, useMemo, useState } from 'react';
import {
  formatUsd,
  shortenAddress,
  stripeCardNumberValidation,
  stripeCardNumberValidationPayment,
} from '../../utils/util';
import { CopyToClipboard } from '../../utils/components/CopyToClipboard';
import { useModalActions } from './hooks/usePaymentOptions';
import Modal from '../common/Modal';
import { Col, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import Image from '../common/Image';
import { CountryCodes } from '../../utils/CountryCodes';
import { useMutation } from '@tanstack/react-query';
import { banks } from '../../helpers/banklist';
import { isValidPhoneNumber } from 'react-phone-number-input';
import useDecodedToken, { useApiHeader } from '../../hooks/useDecodedToken';
import { addAddress } from '../../helpers/api/user/user_details';
import { updateCheckoutBillingSummary } from '../../features/checkout/checkout.slice';
import { SpinnerBtn } from '../util/CustomIcons';
import { addBank, addCard, addMoney } from '../../helpers/api/card/card';
import {
  displayRazorpay,
  make_ticket_order,
} from '../../helpers/payment_gateway/payment';
import { useUserDetailsSWR } from '../../hooks/user/useUserDetails';
import moment from 'moment';
import NoDataFound from '../common/NoDataFound';
import { RiListCheck2 } from 'react-icons/ri';

export const animateClassName = String(
  'transition duration-100 ease-out transform scale-95 opacity-0 transform scale-100 opacity-100 transform scale-100 opacity-100 transform scale-95 opacity-0 transition duration-75 ease-out',
);

export const WalletSubmenus = () => {
  const { data: user, usd_money } = useSelector((s) => s.userDetails);
  const { data: userdetails } = user;
  const [selected, setSelected] = useState('USD Wallet');
  const { handleOpenMoneyModal, modalState, handleCloseMoneyModal } =
    useModalActions();

  const user_wallets = useMemo(
    () =>
      Array.isArray(userdetails?.wallet) ? userdetails?.wallet?.flat() : [],
    [userdetails?.wallet],
  );
  const internal_wallet = useMemo(
    () =>
      user_wallets?.filter((detail) => Boolean(detail?.type === 'internal')),
    [user_wallets],
  );
  const external_wallet = useMemo(
    () => user_wallets?.filter((detail) => detail?.type === 'external'),
    [user_wallets],
  );

  const WalletList = ({ wallet, type }) => {
    if (Array.isArray(wallet) && !wallet.length) {
      return (
        <div className="w-full">
          <div className="ms-3 py-2.5 w-full flex items-center">
            No {type} wallets found.
          </div>
        </div>
      );
    }
    return (
      <>
        {wallet.map((data, index) => (
          <div id={index} className="flex flex-row items-center">
            <div className="ms-3 h-8 w-8 text-lg flex items-center">
              <CopyToClipboard
                copyText={data?.wallet_address}
                copyIcon={<MdContentCopy />}
                className="text-whiteNew-0"
              />
              {/* <MdContentCopy /> */}
            </div>
            <div className="flex flex-col md:flex-row dark:bg-color-base-2 bg-zinc-300 bg-opacity-60 rounded-lg justify-between mt-1 md:mt-3 mb-2 md:mb-3 mx-3 w-full">
              <div className="gap-2 flex flex-row items-center font-normal dark:text-white text-zinc-800 p-2 text-xs md:text-base">
                <MdWallet className="rounded-full border-2 dark:border-gray-100 border-zinc-700 h-6 w-6 p-1" />
                {shortenAddress(data?.wallet_address, 5) ?? 'NA'}
                {data?.isPrimary ? (
                  <span className="px-3 py-1 bg-green-500 rounded-3xl text-xs text-black font-normal">
                    Primary
                  </span>
                ) : null}
              </div>
              <div className="p-2 text-base font-semibold">
                {Number(!isNaN(data?.balance) ? data?.balance : 0).toFixed(2) ??
                  0}{' '}
                ETH
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  const WalletListMemo = memo(WalletList);
  return (
    <>
      <div className="dark:text-white text-zinc-800 dark:bg-color-base-1 bg-color-base-1 rounded-lg rounded-t-none">
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Option value="USD Wallet">
            {({ checked }) => (
              <div className="border-b border-level-2 dark:border-level-1">
                <div className="flex flex-row p-2.5 md:p-3 gap-2">
                  <div className="flex flex-row items-center">
                    <div
                      className={
                        checked
                          ? 'bg-green-3 h-5 w-5 rounded-full border-2 border-green-3'
                          : 'bg-transparent h-5 w-5 rounded-full border-2 border-gray-500'
                      }
                    ></div>
                  </div>
                  <div
                    className={
                      checked
                        ? 'flex flex-row justify-between w-full'
                        : 'dark:bg-neutral-950 bg-zinc-200 bg-opacity-75 p-2.5 border-1 dark:border-neutral-800 border-neutral-300 w-full rounded-lg'
                    }
                  >
                    <div
                      className={
                        checked
                          ? 'text-sm md:text-lg font-semibold'
                          : 'text-sm md:text-base'
                      }
                    >
                      USD Wallet
                    </div>
                    <div className={checked ? 'flex' : 'hidden'}>
                      Available Bal.
                    </div>
                  </div>
                </div>
                <div className="">
                  {checked ? (
                    <div className="flex flex-row dark:bg-color-base-4 bg-color-base-2 rounded-lg justify-between mt-1 md:mt-3 mb-2 md:mb-3 mx-3">
                      <div
                        onClick={handleOpenMoneyModal}
                        className="gap-2 flex flex-row items-center font-normal dark:text-landingPurple-60 text-sky-600 p-2 text-sm md:text-base cursor-pointer"
                      >
                        <MdAdd />
                        Add balance
                      </div>
                      <div className="p-2 text-sm md:text-base font-semibold">
                        {formatUsd(usd_money)}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value="Internal Wallet">
            {({ checked }) => (
              <div className="border-b border-level-2 dark:border-level-1">
                <div className="flex flex-row p-2.5 md:p-3 gap-2">
                  <div className="flex flex-row items-center">
                    <div
                      className={
                        checked
                          ? 'bg-green-3 h-5 w-5 rounded-full border-2 border-green-3'
                          : 'bg-transparent h-5 w-5 rounded-full border-2 border-gray-500'
                      }
                    ></div>
                  </div>
                  <div
                    className={
                      checked
                        ? 'flex flex-row justify-between w-full'
                        : 'dark:bg-neutral-950 bg-zinc-200 border-1 dark:border-neutral-800 border-neutral-300 p-2.5 w-full rounded-lg'
                    }
                  >
                    <div
                      className={
                        checked
                          ? 'text-sm md:text-lg font-semibold'
                          : 'text-sm md:text-base'
                      }
                    >
                      Internal Wallets
                    </div>
                    <div className={checked ? 'flex' : 'hidden'}>
                      Available Bal.
                    </div>
                  </div>
                </div>
                <div>
                  {checked ? (
                    <WalletListMemo
                      wallet={internal_wallet}
                      type={'internal'}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value="External Wallets">
            {({ checked }) => (
              <div className="">
                <div className="flex flex-row p-2.5 md:p-3 gap-2">
                  <div className="flex flex-row items-center">
                    <div
                      className={
                        checked
                          ? 'bg-green-3 h-5 w-5 rounded-full border-2 border-green-3'
                          : 'bg-transparent h-5 w-5 rounded-full border-2 border-gray-500'
                      }
                    ></div>
                  </div>
                  <div
                    className={
                      checked
                        ? 'flex flex-row justify-between w-full'
                        : 'dark:bg-neutral-950 bg-zinc-200 border-1 dark:border-neutral-800 border-neutral-300 p-2.5 w-full rounded-lg'
                    }
                  >
                    <div
                      className={
                        checked
                          ? 'text-sm md:text-lg font-semibold'
                          : 'text-sm md:text-base'
                      }
                    >
                      External Wallets
                    </div>
                  </div>
                </div>
                <div>
                  {checked ? (
                    <WalletListMemo
                      wallet={external_wallet}
                      type={'external'}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            )}
          </RadioGroup.Option>
        </RadioGroup>
      </div>
      <AddMoneyBillingForm
        isOpen={modalState.add_money}
        setIsOpen={handleCloseMoneyModal}
      />
    </>
  );
};

export const FiatSubmenu = () => {
  const {
    handleOpenBillingModal,
    modalState,
    handleCloseBillingModal,
    handleOpenCardModal,
    handleCloseCardModal,
  } = useModalActions();
  const { user_address_list, data: user_details } = useSelector(
    (s) => s.userDetails,
  );
  const { data: user_data } = user_details;
  return (
    <>
      <div className="dark:text-whiteNew-0 text-zinc-800 dark:bg-color-base-4 bg-color-base-1 rounded-lg rounded-t-none">
        <div className="p-3">
          <div className="flex flex-row text-sm md:text-base justify-between mb-2">
            <span>Select Card</span>
            <span
              onClick={handleOpenCardModal}
              className="dark:text-landingPurple-60 text-sky-600 flex flex-row items-center gap-2 cursor-pointer"
            >
              <MdAdd /> Add New Card
            </span>
          </div>
          <div className="flex w-full">
            <Select
              placeholder={<span className="text-[#818181]">Choose Card</span>}
              // onChange={handleChange}
              // popupClassName={`${antCustomSelect} antcstm`}
              // className="w-full cstm-drpdwn h-10 md:h-11 border-1 dark:bg-zinc-900 bg-zinc-200 dark:!border-neutral-800 !border-neutral-300 dark:hover:border-neutral-700 hover:border-neutral-300 focus-within:!border-neutral-700 focus:!border-neutral-700 focus-within:!ring-0 placeholder:text-[#818181] text-base rounded-md [&_.ant-select-selector]:!bg-zinc-50 dark:[&_.ant-select-selector]:!bg-zinc-800"
              className={`rounded-lg w-full`}
              options={user_data?.card_detail?.map((data, index) => ({
                key: index,
                value: `${data?.card_nick_name} - ${String(
                  data?.card_number,
                )} ${data?.card_type}`,
                label: `${data?.card_nick_name} - XXXX XXXX XXXX ${String(
                  data?.card_number,
                ).slice(-4)}  ${data?.card_type}`,
              }))}
              suffixIcon={
                <>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                    <BsChevronDown
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </>
              }
              notFoundContent={
                <div className="w-full">
                  <NoDataFound
                    title={
                      <span className="text-sm text-whiteNew-0">
                        No Data Found
                      </span>
                    }
                    minHeight={'min-h-[150px]'}
                    icon={<RiListCheck2 />}
                  />
                </div>
              }
            />
          </div>

          <div className="flex flex-row text-sm md:text-base justify-between mb-2 mt-2">
            <span>Select Address</span>
            <span
              onClick={handleOpenBillingModal}
              className="dark:text-landingPurple-60 text-sky-600 hover:text-sky-600 dark:hover:text-landingPurple-70 flex flex-row items-center gap-2 cursor-pointer"
            >
              <MdAdd /> Add New Address
            </span>
          </div>
          <div className="flex w-full">
            <Select
              placeholder={
                <span className="text-[#818181]">Choose Address</span>
              }
              // onChange={handleChange}
              // popupClassName={`${antCustomSelect} antcstm`}
              // className="w-full cstm-drpdwn h-10 md:h-11 border-1 dark:!border-neutral-800 !border-neutral-300 hover:border-neutral-700 focus-within:!border-neutral-700 focus-within:!ring-0 placeholder:text-[#818181] text-base rounded-md [&_.ant-select-selector]:!bg-zinc-50 dark:[&_.ant-select-selector]:!bg-zinc-800"
              className={`rounded-lg w-full`}
              suffixIcon={
                <>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                    <BsChevronDown
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </>
              }
              options={user_address_list?.map((data, index) => ({
                key: index,
                value: `${data?.address_nick_name} - ${data?.street_address} ${data?.town}, ${data?.state}, ${data?.postcode} ${data?.country}`,
                label: `${data?.address_nick_name} - ${data?.street_address} ${data?.town}, ${data?.state}, ${data?.postcode} ${data?.country}`,
              }))}
              notFoundContent={
                <div className="w-full">
                  <NoDataFound
                    title={
                      <span className="text-sm dark:text-whiteNew-0 text-zinc-800">
                        No Data Found
                      </span>
                    }
                    minHeight={'min-h-[150px]'}
                    icon={<RiListCheck2 />}
                  />
                </div>
              }
            />
          </div>
        </div>
      </div>
      <AddBillingInfoForm
        isOpen={modalState?.billing_modal}
        setIsOpen={handleCloseBillingModal}
      />
      <AddCardBillingForm
        isOpen={modalState?.add_new_card}
        // isOpen={true}
        setIsOpen={handleCloseCardModal}
      />
    </>
  );
};

export const BankingSubmenu = () => {
  const {
    modalState,
    handleOpenBillingModal,
    handleCloseBillingModal,
    handleOpenBankModal,
    handleCloseBankModal,
    // refetch,
    // user_details,
    // refetch_user
  } = useModalActions();
  const { data: userdetails, user_address_list } = useSelector(
    (s) => s.userDetails,
  );
  const { data: user_data } = userdetails;

  return (
    <>
      <div className="text-white bg-neutral-900 rounded-lg rounded-t-none">
        <div className="p-3">
          <div className="flex flex-row text-sm md:text-base justify-between mb-2">
            <span>Select Bank</span>
            <span
              onClick={handleOpenBankModal}
              className="dark:text-landingPurple-60 text-sky-600 flex flex-row items-center gap-2 cursor-pointer"
            >
              <MdAdd /> Add New Bank
            </span>
          </div>
          <div className="flex flex-row">
            <Select
              placeholder={<span className="text-[#818181]">Choose Bank</span>}
              // onChange={handleChange}
              popupClassName={`${antCustomSelect} antcstm`}
              className="w-full cstm-drpdwn h-10 md:h-11 border-1 bg-black !border-neutral-800 hover:border-neutral-700 focus-within:!border-neutral-700 focus-within:!ring-0 placeholder:text-[#818181] text-base rounded-md"
              options={user_data?.bank_detail?.map((data, index) => ({
                key: index,
                value: `${data?.account_number} ${data?.account_name} ${data?.aba_number}`,
                label: `${data?.bank_nick_name} - ${data?.bank_name} ${data?.city}, ${data?.state}, ${data?.zip_code} ${data?.country}`,
              }))}
              notFoundContent={
                <div className="w-full">
                  <NoDataFound
                    title={
                      <span className="text-sm text-whiteNew-0">
                        No Data Found
                      </span>
                    }
                    minHeight={'min-h-[150px]'}
                    icon={<RiListCheck2 />}
                  />
                </div>
              }
            />
          </div>

          <div className="flex flex-row text-sm md:text-base justify-between mb-2 mt-2">
            <span>Select Address</span>
            <span
              onClick={handleOpenBillingModal}
              className="text-landingPurple-60 flex flex-row items-center gap-2 cursor-pointer"
            >
              <MdAdd /> Add New Address
            </span>
          </div>
          <div className="flex flex-row">
            <Select
              placeholder={
                <span className="text-[#818181]">Choose Address</span>
              }
              // onChange={handleChange}
              popupClassName={`${antCustomSelect} antcstm`}
              className="w-full cstm-drpdwn h-10 md:h-11 border-1 bg-black !border-neutral-800 hover:border-neutral-700 focus-within:!border-neutral-700 focus-within:!ring-0 placeholder:text-[#818181] text-base rounded-md"
              options={user_address_list?.map((data, index) => ({
                key: index,
                value: `${data?.address_nick_name} - ${data?.street_address} ${data?.town}, ${data?.state}, ${data?.postcode} ${data?.country}`,
                label: `${data?.address_nick_name} - ${data?.street_address} ${data?.town}, ${data?.state}, ${data?.postcode} ${data?.country}`,
              }))}
              notFoundContent={
                <div className="w-full">
                  <NoDataFound
                    title={
                      <span className="text-sm text-whiteNew-0">
                        No Data Found
                      </span>
                    }
                    minHeight={'min-h-[150px]'}
                    icon={<RiListCheck2 />}
                  />
                </div>
              }
            />
          </div>
        </div>
      </div>
      <AddBillingInfoForm
        isOpen={modalState?.billing_modal}
        setIsOpen={handleCloseBillingModal}
      />

      <AddBankBillingForm
        isOpen={modalState.add_bank}
        setIsOpen={handleCloseBankModal}
      />
    </>
  );
};

export function AddBillingInfoForm({ isOpen, setIsOpen }) {
  const [form] = Form.useForm();
  const decodedToken = useDecodedToken();
  const { json_header } = useApiHeader();
  const { checkout_billing_summary } = useSelector((s) => s.checkout);
  const dispatch = useDispatch();
  const { refetch } = useModalActions();
  // const { json_header } = useApiHeader();
  const insertNote = () => {
    const values = form.getFieldsValue();
    const body = {
      ...values,
      email: decodedToken?.email,
      address_nick_name: decodedToken?.username,
    };

    return addAddress(body, json_header);
  };

  const mutation = useMutation(insertNote, {
    onSuccess: async (data) => {
      message.success('Address added successfully...');
      dispatch(
        updateCheckoutBillingSummary({
          ...checkout_billing_summary,
          billing_address: form.getFieldsValue(),
        }),
      );
      await refetch.mutate();
      form.resetFields();
      setIsOpen(); // this will close the modal
    },
  });
  const { error, isError, isLoading, mutate } = mutation;
  const country_list = CountryCodes;
  const countries = country_list?.map(({ code, label: country_name }) => ({
    key: code,
    value: country_name,
    label: (
      <span className="flex items-center gap-2 dark:text-whiteNew-0 text-zinc-800">
        <Image
          className="h-4"
          src={`https://flagcdn.com/w20/${String(code).toLowerCase()}.png`}
        />
        {country_name}
      </span>
    ),
  }));

  const phones = country_list?.map(({ code, phone, label }) => ({
    key: code,
    value: `${code}+${phone}`,
    label: (
      <span className="flex items-center gap-2 dark:text-whiteNew-0 text-zinc-800">
        <Image
          className="h-4"
          src={`https://flagcdn.com/w20/${String(code).toLowerCase()}.png`}
        />
        {code} +{phone}
      </span>
    ),
  }));

  const handleSubmit = () => {
    return mutate();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={() => {
          form.resetFields();
          setIsOpen();
        }}
        header={
          <>
            <div className="flex flex-col justify-center items-center py-2">
              <div className="w-full">
                <h3 className="text-lg font-medium leading-6 text-color-primary">
                  Add Address
                </h3>
              </div>
            </div>
          </>
        }
        // bodyClass={'dark:bg-neutral-900 bg-whiteNew-0 p-6 px-3.5'}
        // headerColor={
        //   'text-lg font-medium leading-6 text-gray-900 flex flex-row justify-between border-b border-transparent dark:border-b-neutral-800 border-b-neutral-100  py-3 dark:bg-neutral-900 bg-lightTheme-70 px-6'
        // }
        maxWidth={'w-full max-w-xl'}
        preventClosingOutsideClick
      >
        <div className="cstm-modal">
          {/* <div className="flex mb-2.5">
            <h4 className="text-gray-400 text-lg">Billing Information</h4>
          </div> */}
          {isError ? (
            <div className="flex items-center justify-center mb-2.5">
              <span className="flex  text-rose-600 font-semibold">
                {error?.response?.data?.message ?? 'Unexpected error occurred'}
              </span>
            </div>
          ) : null}
          <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={{
              country: countries[0]?.value,
              phone_code: phones[0]?.value,
            }}
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please select country.',
                },
              ]}
              className="m-0"
              name="country"
            >
              <Select
                placeholder={
                  <>
                    <span className="text-[#818181]">Select Country</span>
                  </>
                }
                popupClassName={`${antCustomSelect} antcstm`}
                className="rounded-md cursor-pointer"
                options={countries}
                showSearch
                autoClearSearchValue
                suffixIcon={
                  <>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                      <BsChevronDown
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </>
                }
                notFoundContent={
                  <div className="w-full">
                    <NoDataFound
                      title={
                        <span className="text-sm text-whiteNew-0">
                          No Data Found
                        </span>
                      }
                      minHeight={'min-h-[150px]'}
                      icon={<RiListCheck2 />}
                    />
                  </div>
                }
              />
            </Form.Item>

            <div className="flex mt-3 gap-3">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'First name is required.',
                  },
                ]}
                className="m-0 basis-1/2"
                name="first_name"
              >
                <Input className={`${formClass}`} placeholder="First Name" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Last name is required.',
                  },
                ]}
                className="m-0 basis-1/2"
                name="last_name"
              >
                <Input className={`${formClass}`} placeholder="Last Name" />
              </Form.Item>
            </div>
            <Form.Item
              rules={[
                {
                  max: 50,
                  message: 'Max. 50 character allowed.',
                },
              ]}
              className="m-0"
              name="building"
            >
              <div className="flex mt-3 gap-3">
                <div className="flex w-full">
                  <Input
                    className={`${formClass}`}
                    placeholder="Apartment, suite, etc. (optional)"
                  />
                </div>
              </div>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Street address is required.',
                },
                {
                  max: 100,
                  message: 'Max. 100 characters allowed.',
                },
              ]}
              className="m-0"
              name="street_address"
            >
              <div className="flex mt-3 gap-3">
                <div className="flex w-full">
                  <Input className={`${formClass}`} placeholder="Address" />
                </div>
              </div>
            </Form.Item>

            <div className="flex flex-row mt-3">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'City address is required.',
                  },
                  {
                    max: 25,
                    message: 'Max. 25 characters allowed.',
                  },
                ]}
                className="m-0"
                name="town"
              >
                <div className="flex flex-col basis-1/3 pe-3">
                  <Input className={`${formClass}`} placeholder="City" />
                </div>
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'State address is required.',
                  },
                  {
                    max: 25,
                    message: 'Max. 25 characters allowed.',
                  },
                ]}
                className="m-0"
                name="state"
              >
                <div className="flex flex-col basis-1/3 pe-1.5 ps-1.5">
                  <Input className={`${formClass}`} placeholder="State" />
                </div>
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Zip Code is required.',
                  },
                  {
                    max: 10,
                    message: 'Max. 10 characters allowed.',
                  },
                ]}
                className="m-0"
                name="postcode"
              >
                <div className="flex flex-col basis-1/3 ps-3">
                  <Input
                    type="number"
                    className={`${formClass}`}
                    placeholder="Pin Code"
                  />
                </div>
              </Form.Item>
            </div>

            <div className="flex w-full mt-3 gap-3">
              <Form.Item
                className="flex m-0 flex-col w-1/3"
                name="phone_code"
                rules={[
                  {
                    required: true,
                    message: 'Please select country code.',
                  },
                ]}
                dependencies={['phone_no']}
              >
                <Select
                  placeholder={
                    <span className="text-[#818181]">Select phone code</span>
                  }
                  // popupClassName={`${antCustomSelect} antcstm`}
                  // className="w-full cstm-drpdwn h-10 border-1 hover:border-zinc-500 placeholder:text-[#818181] text-sm rounded-md cursor-pointer"
                  className=" rounded-md cursor-pointer"
                  options={phones}
                  showSearch
                  autoClearSearchValue
                  suffixIcon={
                    <>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                        <BsChevronDown
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </>
                  }
                  notFoundContent={
                    <div className="w-full">
                      <NoDataFound
                        title={
                          <span className="text-sm dark:text-whiteNew-0 text-zinc-800">
                            No Data Found
                          </span>
                        }
                        minHeight={'min-h-[150px]'}
                        icon={<RiListCheck2 />}
                      />
                    </div>
                  }
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Phone number is required.',
                  },
                  {
                    max: 15,
                    message: 'Max. 15 characters allowed.',
                  },
                  ({ getFieldValue }) => ({
                    validator: (_, value) => {
                      if (
                        !value ||
                        isValidPhoneNumber(
                          value,
                          String(getFieldValue('phone_code')).split('+')[0],
                        )
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Invalid phone number'));
                    },
                  }),
                ]}
                dependencies={['phone_code']}
                className="flex m-0 flex-col w-2/3"
                name="phone_no"
              >
                <Input className={`${formClass}`} placeholder="Phone" />
              </Form.Item>
            </div>
            <div>
              <div className="mt-3">
                <Form.Item
                  valuePropName="checked"
                  name="is_default"
                  className="m-0"
                >
                  <Checkbox className="text-[#818181] text-xs md:text-base">
                    Save it as your default address
                  </Checkbox>
                </Form.Item>
              </div>
            </div>
            <div className="flex flex-row items-center justify-end gap-2">
              <button
                type="reset"
                // className="text-white text-base min-w-[150px] md:min-w-[120px]"
                className={`${btnSecondayClass}`}
                onClick={setIsOpen}
              >
                Cancel
              </button>
              <button
                type="submit"
                // className="bg-gradient-to-r dark:from-purplev2-800 from-sky-600 to-sky-600 dark:to-purplev2-900 text-base text-white rounded-md px-2.5 py-1.5 min-w-[150px] md:min-w-[120px]"
                className={`${btnPrimaryClass}`}
              >
                {isLoading ? <SpinnerBtn /> : 'Save'}
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export function AddCardBillingForm({ isOpen, setIsOpen }) {
  const [form] = Form.useForm();
  const decodedToken = useDecodedToken();
  const { refetch_user_data } = useModalActions();
  const inserNote = (e) => {
    const values = form.getFieldsValue();
    const payload = {
      ...values,
      card_type: stripeCardNumberValidation(values?.card_number),
      user_id: decodedToken?.id,
    };

    return addCard(payload);
  };

  const mutation = useMutation(inserNote, {
    onSuccess: async () => {
      form.resetFields();
      message.success('Card Added Successfully');
      refetch_user_data.mutate();
      setIsOpen();
    },
    onError: (err) => {
      message.error(err?.response?.data?.message);
    },
  });

  const { isError, error, isLoading, mutate, reset } = mutation;

  const country_list = CountryCodes;
  const countries = country_list?.map(({ code, label: country_name }) => ({
    key: code,
    value: country_name,
    label: (
      <span className="flex items-center gap-2">
        <Image
          className="h-4"
          src={`https://flagcdn.com/w20/${String(code).toLowerCase()}.png`}
        />
        {country_name}
      </span>
    ),
  }));

  const handleSubmit = () => {
    mutate();
  };
  // const card_number = Form.useWatch(['card_number'], form);

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={() => {
          form.resetFields();
          reset();
          setIsOpen();
        }}
        header={
          <>
            <div className="flex flex-col justify-center items-center py-2">
              <div className="w-full">
                <h3 className="text-lg font-medium leading-6 text-color-primary">
                  Add Card
                </h3>
              </div>
            </div>
          </>
        }
        // bodyClass={'dark:bg-neutral-900 bg-whiteNew-0 p-6 px-3.5'}
        // headerColor={
        //   'text-lg font-medium leading-6 text-gray-900 flex flex-row justify-between border-b border-transparent dark:border-b-neutral-800 border-b-neutral-100  py-3 dark:bg-neutral-900 bg-lightTheme-70 px-6'
        // }
        maxWidth={'w-full max-w-lg'}
        preventClosingOutsideClick
      >
        <div className="">
          {/* <div className="flex mb-2.5">
            <h4 className="text-gray-400 text-lg">Billing Information</h4>
          </div> */}
          <div>
            <div className="">
              <Form
                form={form}
                onFinish={handleSubmit}
                initialValues={{
                  country: countries[0]?.value,
                }}
              >
                <Row className="g-3">
                  <Col lg={12}>
                    {isError ? (
                      <div className="flex items-center justify-center mb-2.5">
                        <span className="flex  text-rose-600 font-semibold">
                          {error?.response?.data?.message ??
                            'Unexpected error occurred'}
                        </span>
                      </div>
                    ) : null}
                    <div className="flex gap-3 flex-wrap2 md:flex-nowrap">
                      <div className="w-full">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Name is required.',
                            },
                          ]}
                          name={'card_name'}
                          className="mb-0"
                        >
                          <Input
                            className={`${formClass}`}
                            placeholder="Name On Card"
                          />
                        </Form.Item>
                      </div>
                      <div className="w-full">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Nick name is required.',
                            },
                          ]}
                          name="card_nick_name"
                          className="mb-0"
                        >
                          <Input
                            className={`${formClass}`}
                            placeholder="Card Nick Name"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="flex gap-3 flex-wrap2 md:flex-nowrap">
                      <div className="w-full">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Card number is required.',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  stripeCardNumberValidationPayment(value)
                                ) {
                                  const cardType =
                                    stripeCardNumberValidationPayment(value);
                                  form.setFieldValue('card_type', cardType);
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error('Enter valid card number'),
                                );
                              },
                            }),
                          ]}
                          name="card_number"
                          className="mb-0"
                        >
                          <CurrencyFormat
                            format="#### #### #### ####"
                            // mask="_"
                            placeholder="4111 1111 1111 1111"
                            // value={cardNumber}
                            className={`${formClass} w-full`}
                          />
                        </Form.Item>
                      </div>
                      <div className="w-full flex gap-3">
                        <div className="w-full">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: 'Expiration date is required.',
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const date = getFieldValue('expiration_date');
                                  const expirationMonth = Number(
                                    String(date).split('/')[0],
                                  );
                                  const expirationYear =
                                    Number(String(date).split('/')[1]) + 2000;

                                  const currentDate = new Date();
                                  const currentYear = currentDate.getFullYear();
                                  const currentMonth =
                                    currentDate.getMonth() + 1; // January is 0

                                  if (expirationYear > currentYear || !value) {
                                    return Promise.resolve();
                                  } else if (
                                    expirationYear === currentYear &&
                                    expirationMonth >= currentMonth
                                  ) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject(
                                    new Error('Invalid expire date'),
                                  );
                                },
                              }),
                            ]}
                            name="expiration_date"
                            className="mb-0"
                          >
                            <CurrencyFormat
                              format="##/##"
                              // mask="_"
                              placeholder="12/22"
                              // value={cardNumber}
                              className={`${formClass} w-full`}
                            />
                          </Form.Item>
                        </div>
                        <div className="w-full">
                          <Form.Item
                            rules={[
                              {
                                max: 3,
                                message: 'CVV is invalid.',
                              },
                              {
                                min: 3,
                                message: 'CVV is invalid.',
                              },
                              {
                                required: true,
                                message: 'CVV is required.',
                              },
                            ]}
                            name="cvv"
                            className="mb-0"
                          >
                            <Input
                              type="password"
                              className={`${formClass}`}
                              placeholder="CVV"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="gap-3 flex">
                      <div className="w-full basis-1/2">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Zip code is required.',
                            },
                            {
                              max: 10,
                              message: 'Postal code is invalid.',
                            },
                          ]}
                          name="zip_code"
                          className="mb-0"
                        >
                          <Input
                            className={`${formClass} w-full`}
                            placeholder="Zip Code"
                          />
                        </Form.Item>
                      </div>

                      <div className="w-full basis-1/2">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Please select country.',
                            },
                          ]}
                          name="country"
                          // className="mb-0 dark:[&_.ant-select-selector]:!bg-neutral-900"
                        >
                          <Select
                            placeholder={
                              <span className="text-[#818181]">
                                Select country
                              </span>
                            }
                            // popupClassName={`${antCustomSelect} antcstm`}
                            className="rounded-md"
                            options={countries}
                            showSearch
                            autoClearSearchValue
                            suffixIcon={
                              <>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                                  <BsChevronDown
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </>
                            }
                            notFoundContent={
                              <div className="w-full">
                                <NoDataFound
                                  title={
                                    <span className="text-sm text-whiteNew-0">
                                      No Data Found
                                    </span>
                                  }
                                  minHeight={'min-h-[150px]'}
                                  icon={<RiListCheck2 />}
                                />
                              </div>
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="flex flex-row items-center justify-end mt-3 gap-2">
                  <button
                    type="reset"
                    className={`${btnSecondayClass}`}
                    onClick={setIsOpen}
                  >
                    Cancel
                  </button>
                  <button type="submit" className={`${btnPrimaryClass}`}>
                    {isLoading ? <SpinnerBtn /> : 'Save'}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export function AddMoneyBillingForm({ isOpen, setIsOpen }) {
  const [form] = Form.useForm();
  const decodedToken = useDecodedToken();
  const { fetchUSDWallet } = useUserDetailsSWR();
  const [money, setMoney] = useState();
  const handleBooktickets = async (paymentId) => {
    let obj = {
      payment_id: paymentId,
      account_id: decodedToken?.id,
      date: moment(),
      amount: String(money), // cent
      status: true,
      currency: 'USD',
      customer_id: decodedToken?.id, //funds added on self
      type: 'credit',
      transaction_for: 'funds_added',
    };
    const response = await addMoney(obj);
    if (response?.status) {
      fetchUSDWallet({ is_refetching: true });
    } else {
      console.error('response:err ', response);
    }
  };

  const handelDeleteOnly = async (res) => {
    if (res) {
      let obj = {
        payment_id: res?.error?.metadata?.payment_id,
        account_id: decodedToken?.id,
        date: moment(),
        amount: String(money),
        status: false, //in case of failed payment
        customer_id: decodedToken?.id, //funds added on self
        currency: 'USD',
        type: 'credit',
        transaction_for: 'funds_added',
      };
      const response = await addMoney(obj);
      if (response?.status) {
        fetchUSDWallet({ is_refetching: true });
      } else {
        console.error('response:err ', response);
      }
    }
  };
  const mutateFn = async () => {
    const orders = await make_ticket_order({
      amount: money * 100,
      currency: 'USD',
      receipt: 'test_receipt',
    });
    await displayRazorpay(
      // need to optimize this function
      orders?.data?.data,
      () => null,
      {
        username: decodedToken?.username,
        email: decodedToken?.email,
        contact: decodedToken?.contact,
      },
      handleBooktickets,
      () => null,
      'orders',
      'slug',
      handelDeleteOnly,
      'funds_added',
      decodedToken?.id,
    );
  };
  const payment_mutate = useMutation(mutateFn, {
    onSuccess: () => {
      form.resetFields();
      message.success('Payment initiated...');
      setIsOpen();
    },
  });

  const { isError, error, reset, isLoading, mutate } = payment_mutate;
  const handleSubmit = () => {
    mutate();
  };
  // const order =
  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={() => {
          form.resetFields();
          setIsOpen();
          reset();
        }}
        header={
          <>
            <div className="flex flex-col justify-center items-center py-2">
              <div className="w-full">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Add Money
                </h3>
              </div>
            </div>
          </>
        }
        // bodyClass={'dark:bg-neutral-900 bg-whiteNew-0 p-6 px-3.5'}
        // headerColor={
        //   'text-lg font-medium leading-6 text-gray-900 flex flex-row justify-between border-b border-transparent dark:border-b-neutral-800 border-b-neutral-100  py-3 dark:bg-neutral-900 bg-lightTheme-70 px-6'
        // }
        maxWidth={'w-full max-w-sm'}
        // darkTheme
        // bgColor={'bg-neutral-900'}
        preventClosingOutsideClick
      >
        <div className="cstm-modal">
          {/* <div className="flex mb-2.5">
            <h4 className="text-gray-400 text-lg">Billing Information</h4>
          </div> */}
          <div>
            <div className="">
              <Form form={form} onFinish={handleSubmit}>
                <Row className="g-3">
                  <Col lg={12}>
                    {isError ? (
                      <div className="flex items-center justify-center mb-2.5">
                        <span className="flex  text-rose-600 font-semibold">
                          {error?.response?.data?.message ??
                            'Unexpected error occurred'}
                        </span>
                      </div>
                    ) : null}
                    <div className="flex gap-3 flex-wrap2 md:flex-nowrap">
                      <div className="w-full">
                        <Form.Item
                          name="money"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter amount.',
                            },
                            ({ getFieldValue }) => ({
                              validator: (_, value) => {
                                if (money < 6000 || !money) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  'Amount should be less than 6000',
                                );
                              },
                            }),
                          ]}
                          className="mb-0"
                        >
                          <CurrencyFormat
                            // format="#### #### #### ####"
                            // mask="_"
                            placeholder="$ 0.00"
                            className={`${formClass} w-full`}
                            thousandSeparator
                            decimalScale={2}
                            // isNumericString={true}
                            onValueChange={(e) => {
                              setMoney(Number(e.value));
                              form.setFieldValue('money', Number(e.value));
                            }}
                            // fixedDecimalScale
                            prefix="$ "
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="flex mt-3 flex-row items-center justify-end  gap-2">
                  <button
                    type="reset"
                    // className="text-white text-base min-w-[150px] md:min-w-[120px]"
                    className={`${btnSecondayClass}`}
                    onClick={setIsOpen}
                  >
                    Cancel
                  </button>
                  <button type="submit" className={`${btnPrimaryClass}`}>
                    {isLoading ? <SpinnerBtn /> : 'Add'}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export function AddBankBillingForm({ isOpen, setIsOpen }) {
  const [form] = Form.useForm();
  const decodedToken = useDecodedToken();
  const { refetch_user_data } = useModalActions();

  const insertNote = () => {
    const values = form.getFieldsValue();
    const payload = {
      ...values,
      user_id: decodedToken?.id,
    };
    return addBank(payload);
  };

  const mutation = useMutation(insertNote, {
    onSuccess: async () => {
      form.resetFields();
      message.success('Bank Details Added Successfully...');
      refetch_user_data.mutate();
      setIsOpen();
    },
    onError: (err) => {
      message.error(err?.response?.data?.message);
    },
  });

  const { isLoading, isError, error, reset, mutate } = mutation;
  const bank_list = [...banks]?.map(
    ({ Account_Name, BankAddress, City, State, Zip }, index) => ({
      key: index,
      value: Account_Name,
      label: Account_Name,
    }),
  );

  const country_list = CountryCodes;
  const countries = [...country_list]?.map(({ code, label: country_name }) => ({
    key: code,
    value: country_name,
    label: (
      <span className="flex items-center gap-2">
        <Image
          className="h-4"
          src={`https://flagcdn.com/w20/${String(code).toLowerCase()}.png`}
        />
        {country_name}
      </span>
    ),
  }));

  const handleSubmit = () => {
    return mutate();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={() => {
          form.resetFields();
          setIsOpen();
          reset();
        }}
        header={
          <>
            <h3 className="text-gray-100">Add New Bank Account</h3>
          </>
        }
        bodyClass={'bg-neutral-900 p-6 px-3.5'}
        headerColor={
          'text-lg font-medium leading-6 text-gray-900 flex flex-row justify-between border-b border-transparent border-b-neutral-800  py-3 bg-neutral-900 px-6'
        }
        maxWidth={'w-full max-w-lg border-1 border-neutral-800'}
        darkTheme
        bgColor={'bg-neutral-900'}
        preventClosingOutsideClick
      >
        <div className="cstm-modal">
          {/* <div className="flex mb-2.5">
            <h4 className="text-gray-400 text-lg">Billing Information</h4>
          </div> */}
          <div>
            <div className="">
              <Form
                initialValues={{
                  bank_name: bank_list[0]?.value,
                  country: countries[0]?.value,
                }}
                form={form}
                onFinish={handleSubmit}
              >
                <Row className="g-3">
                  <Col lg={12}>
                    {isError ? (
                      <div className="flex items-center justify-center mb-2.5">
                        <span className="flex  text-rose-600 font-semibold">
                          {error?.response?.data?.message ??
                            'Unexpected error occurred'}
                        </span>
                      </div>
                    ) : null}
                    <div className="flex gap-3 flex-wrap2 md:flex-nowrap">
                      <div className="w-full">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Please select bank.',
                            },
                          ]}
                          name="bank_name"
                          className="mb-0"
                        >
                          <Select
                            // defaultValue="State"
                            // onChange={handleChange}
                            placeholder={
                              <span className="text-[#818181]">
                                Select Bank
                              </span>
                            }
                            popupClassName={`${antCustomSelect} antcstm`}
                            className="w-full cstm-drpdwn h-10 border-1 bg-black !border-neutral-800 hover:border-zinc-500 placeholder:text-[#818181] text-sm rounded-md"
                            options={bank_list}
                            showSearch
                            autoClearSearchValue
                            suffixIcon={
                              <>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                                  <BsChevronDown
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </>
                            }
                            notFoundContent={
                              <div className="w-full">
                                <NoDataFound
                                  title={
                                    <span className="text-sm text-whiteNew-0">
                                      No Data Found
                                    </span>
                                  }
                                  minHeight={'min-h-[150px]'}
                                  icon={<RiListCheck2 />}
                                />
                              </div>
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="flex gap-3 flex-wrap2 md:flex-nowrap">
                      <div className="w-full">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Account name is required.',
                            },
                            {
                              max: 25,
                              message: 'Max. 25 characters allowed.',
                            },
                          ]}
                          name="account_name"
                          className="mb-0"
                        >
                          <Input
                            className={`${formClass}`}
                            placeholder="Account Name"
                          />
                        </Form.Item>
                      </div>
                      <div className="w-full">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Nick name code is required.',
                            },
                            {
                              max: 15,
                              message: 'Max. 15 characters allowed.',
                            },
                          ]}
                          name="bank_nick_name"
                          className="mb-0"
                        >
                          <Input
                            className={`${formClass}`}
                            placeholder="Bank Account Nick Name"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="flex gap-3 flex-wrap2 md:flex-nowrap">
                      <div className="w-full">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Account number is required.',
                            },
                            {
                              max: 25,
                              message: 'Invalid account number.',
                            },
                          ]}
                          name="account_number"
                          className="mb-0"
                        >
                          <Input
                            className={`${formClass}`}
                            placeholder="Account Number"
                          />
                        </Form.Item>
                      </div>
                      <div className="w-full">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'ABA code is required.',
                            },
                            {
                              max: 9,
                              message: 'Invalid ABA code.',
                            },
                            {
                              min: 9,
                              message: 'Invalid ABA code.',
                            },
                          ]}
                          name="aba_number"
                          className="mb-0"
                        >
                          <Input
                            className={`${formClass}`}
                            placeholder="ABA Code"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="flex gap-3 flex-wrap2 md:flex-nowrap">
                      <div className="w-full">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Bank address is required.',
                            },
                            {
                              max: 100,
                              message: 'Max. 100 characters allowed.',
                            },
                          ]}
                          name="address"
                          className="mb-0"
                        >
                          <Input
                            className={`${formClass}`}
                            placeholder="Bank Address"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="flex gap-3 flex-wrap2 md:flex-nowrap">
                      <div className="w-full">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'City is required.',
                            },
                          ]}
                          name="city"
                          className="mb-0"
                        >
                          <Input
                            className={`${formClass}`}
                            placeholder="Town/City"
                          />
                        </Form.Item>
                      </div>
                      <div className="w-full">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'State is required.',
                            },
                          ]}
                          name="state"
                          className="mb-0"
                        >
                          <Input
                            className={`${formClass}`}
                            placeholder="State"
                          />
                        </Form.Item>
                      </div>
                      <div className="w-full">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Postal code is required.',
                            },
                            {
                              max: 10,
                              message: 'Invalid postal code.',
                            },
                          ]}
                          name="zip_code"
                          className="mb-0"
                        >
                          <Input
                            className={`${formClass}`}
                            placeholder="Zip Code"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="gap-3 flex flex-wrap2 md:flex-nowrap">
                      <div className="w-full">
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Please select country.',
                            },
                          ]}
                          name="country"
                          className="mb-0"
                        >
                          <Select
                            // onChange={handleChange}
                            placeholder={
                              <span className="text-[#818181]">
                                Select country
                              </span>
                            }
                            popupClassName={`${antCustomSelect} antcstm`}
                            className="w-full cstm-drpdwn h-10 border-1 bg-black !border-neutral-800 hover:border-zinc-500 placeholder:text-[#818181] text-sm rounded-md"
                            options={countries}
                            showSearch
                            autoClearSearchValue
                            suffixIcon={
                              <>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                                  <BsChevronDown
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </>
                            }
                            notFoundContent={
                              <div className="w-full">
                                <NoDataFound
                                  title={
                                    <span className="text-sm text-whiteNew-0">
                                      No Data Found
                                    </span>
                                  }
                                  minHeight={'min-h-[150px]'}
                                  icon={<RiListCheck2 />}
                                />
                              </div>
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="flex flex-row items-center justify-end mt-3 gap-2">
                  <button
                    type="reset"
                    // className="text-white text-base min-w-[150px] md:min-w-[120px]"
                    className={`${btnSecondayClass}`}
                    onClick={setIsOpen}
                  >
                    Cancel
                  </button>
                  <button type="submit" className={`${btnPrimaryClass}`}>
                    {isLoading ? <SpinnerBtn /> : 'Save'}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
