import React from 'react';

export default function Badge({ children, className }) {
  return (
    <span className="flex">
      <span
        className={`${
          className
            ? className
            : 'bg-gray-200 dark:bg-color-base-2 text-color-primary badgeColor'
        } text-[11px] min-w-[1rem] flex justify-center items-center px-1.5 rounded-[4px]`}
      >
        {children}
      </span>
    </span>
  );
}
export function NumberIconBadge({ children, className }) {
  return (
    <React.Fragment>
      <div>
        <div
          className="text-bluev2-500 text-lg bg-color-base-2 dark:bg-color-base-1 border-1 border-level-1 flex p-1 rounded-[4px] shadow-soft"
          // style={{
          //   boxShadow:
          //     'rgb(199 198 198) 0px 0px 4px -1px',
          // }}
        >
          {children}
        </div>
      </div>
    </React.Fragment>
  );
}
