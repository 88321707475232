import React, { useMemo, memo, useCallback, useState, useRef } from 'react';
import { Button, Drawer, Form, Input } from 'antd';
// import bg from '../../assets/images/banner/bg.png';
import {
  AnimatePing,
  CalenderIcon2,
  LocationIcon,
  SpinnerBtn,
  StreamingPlayIcon,
  StreamingQR,
  profileUrl,
} from '../../components/util/CustomIcons';
import { Card, Col, Row } from 'react-bootstrap';
import FlipCountdown from '../../utils/components/flipcounter/FlipCounter';
import { HiArrowRight } from 'react-icons/hi2';
import {
  afterBorder,
  classNames,
} from '../../components/common/DefaultClasses';
import {
  useConsolidatedTicket,
  useShowWhichCard,
  useStreamAPI,
  // useStreamAPI,
  useStreamLounge,
} from './hooks/useStreamLounge';
import moment from 'moment';
import { history } from '../../helpers/router_templates/history';
import useDecodedToken from '../../hooks/useDecodedToken';
import { useDispatch, useSelector } from 'react-redux';
import { useUserDetails } from '../../hooks/user/useUserDetails';
import {
  MdKeyboardArrowDown,
  // MdOutlineFileUpload,
  // MdOutlineQrCodeScanner,
  MdStop,
} from 'react-icons/md';
import { Tab, Transition } from '@headlessui/react';

import {
  updateDisclosureList,
  updateDrawerScreen,
  updateQRScannedData,
  // updateQRTicketVerified,
  // updateShareAuthorization,
  // updateSharedTicketList,
  updateSingleNFTDetail,
  // updateStreamTicketDetails,
  // updateTicketAuthorization,
} from '../../features/stream_lounge/stream.slice';
// import {
//   // enter_stream,
//   get_all_shared_order,
//   validate_qr_ticket,
//   validate_shared_ticket,
// } from '../../helpers/api/stream/stream';
// import { useDeviceData } from 'react-device-detect';
// import { useMutation } from '@tanstack/react-query';
// import useSearchQuery from '../../hooks/useSearchQuery';
import { authActions } from '../../features/auth.slice';
import {
  DATEFORMAT,
  EMAIL_REG,
  getIsVerticalLayout,
  // sleep,
  truncate,
  // truncate3,
} from '../../utils/util';
import LiveStream from './LiveStream/LiveStream';
import BackgroundImage from './BackgroundImage';
// import { HashLoader, ClimbingBoxLoader } from 'react-spinners';

// import { useOnceCall } from '../../hooks/useOnlyOnce';
// import SessionAuthDrawer from '../../components/stream_lounge_components/SessionAuthDrawer';
import { QrReader } from 'react-qr-reader';
import Modal from '../../components/common/Modal';
import { useDrawerScreens } from './hooks/useDrawer';
import Image from '../../components/common/Image';
import NFTDetailDrawer from './NFTDetailDrawer';
// import { useEffect } from 'react';
import { BsTicketDetailed } from 'react-icons/bs';
// import { DataLoader } from '../../components/common/Loaders';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import loaderMessage from '../../utils/LoaderMessage';
import { AiOutlineArrowDown } from 'react-icons/ai';
import SliderFilterDropdown from '../../components/landing_page_v2/SliderFilterDropdown';
import LiveSessionDetails from './LiveSessionDetails';
import { portalTexts } from '../../utils/LoaderMessage';
// import { isEmptyObject } from 'jquery';

export default function StreamingPage() {
  const {
    event_details,
    event_started,
    event_ended,
    toShowAuthCard,
    isObjectEmpty,
    authorize_ticket,
    event_id,
    from_dashboard,
    fetch_paginated_nft,
    // refetchOrderData,
  } = useStreamLounge();
  const isVerticalLayout = useMemo(() => getIsVerticalLayout(), []);
  const {
    // active_session_list,
    ticket_data,
    shared_ticket_list,
    stream_nft_details,
    is_shared_authorized,
  } = useSelector((s) => s.stream);

  // const { disclosure_list } = useSelector((s) => s.stream);
  const { ticket_type } = useConsolidatedTicket();

  const {
    showAuthCards,
    showTicketListCards,
    // showSharedTicketList,
    // showTicketQR,
    // showQRSharedTicketList,
    // join_stream_direct,
  } = useShowWhichCard();

  const {
    disclosure_list,
    shared_disclosure_list,
    toggleLoginScreen,
    // toggleSessionScreen,
    toggleScreens,
    portal_state,
    joinIdPasscode,
    drawer_screen,
    openQRModal,
    onCloseDrawer,
    closeQRModal,
    // onCloseSession,
    onOpenSession,
    handleClick,
    handleShareClick,
    handleJoinStream,
    handleToggleScreen,
    handleToggleLoginScreen,
    onCloseNFT,
    onOpenNFT,
    onOpenNFTDetails,
    setDisClosureList,
    // showSharedTicketDrawer,
    // onCloseSharedTicketDrawer,
  } = useDrawerScreens();
  const { validate_qr_order_api, validate_qr_ticket_api } = useStreamAPI();

  const decodedToken = useDecodedToken();
  const dispatch = useDispatch();
  const { user } = useUserDetails(decodedToken?.username || decodedToken?.id);
  const {
    start_date_time,
    end_date_time,
    name,
    description,
    location,
    slug,
    event_type,
    // _id,
  } = event_details;

  const { is_authorized } = authorize_ticket;
  const [selectedIndex, setSelectedIndex] = useState(0);
  useMemo(() => {
    if (!isObjectEmpty(event_details)) {
      event_ended.current = Boolean(
        end_date_time &&
          moment.utc(end_date_time).local().isBefore(moment.now()),
      );
    }
  }, [end_date_time, event_details, event_ended, isObjectEmpty]);

  const ShowCountdown = () => {
    const isEventLoaded = isObjectEmpty(event_details);
    const delta_hour = useMemo(() => {
      const event_start = moment.utc(start_date_time).local();
      // const event_end = moment.utc(end_date_time).local();
      const diff = event_start.diff(moment(), 'days', true);
      return diff;
      // const hour_diff = event_end.diff(event_start, 'hours', true);
      // if (diff > 2) return String(parseInt(diff)) + ' Days - Multiday Event';
      // if (diff >= 1) return String(parseInt(diff)) + ' Day';
      // if (hour_diff < 1) return String(hour_diff * 60) + ' Mins';
      // if (hour_diff >= 1) return String(parseInt(hour_diff)) + ' Hrs';
    }, []);
    // if (!isEventLoaded) return <></>;
    if (!isEventLoaded && event_started.current) {
      return <></>;
    }

    if (!isEventLoaded && event_ended.current) {
      return <></>;
    }

    if (!isEventLoaded && delta_hour > 1) {
      const cardColor = 'bg-landingPurple-60 rounded-md overflow-hidden';
      return (
        <div
          className={`flip-countdown flex gap-2 flex-wrap lg:justify-end w-full theme-dark size-medium`}
        >
          <Card
            className={`${
              cardColor
                ? cardColor
                : 'text-center border-0 bg-transparent rounded-none'
            }`}
            style={{ minWidth: '2rem' }}
          >
            <Card.Body
              className={`p-2 bg-transparent border-purple-300 `}
              style={{
                minHeight: '42px',
              }}
            >
              <div className="w-ful flex justify-center text-white text-lg">
                <span className="flip-countdown-card  justify-center">
                  <span className="flip-countdown-card-sec one">
                    <span className="card__top">{parseInt(delta_hour)}</span>
                  </span>
                </span>
              </div>
              <div className="flex w-full justify-center">
                <span className="text-[11px] font-normal">
                  <span className="flip-countdown-title flex text-white">
                    Days
                  </span>
                </span>
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }

    if (!isEventLoaded && delta_hour <= 1) {
      return (
        <FlipCountdown
          size="medium"
          endAtZero
          titlePosition={'bottom'}
          endAt={start_date_time}
          className="lg:justify-end w-full"
          cardColor={'bg-landingPurple-60 rounded-md overflow-hidden'}
          cardColorBody={''}
        />
      );
    }
  };
  const ShowCountDownMemo = memo(ShowCountdown);

  const ShowDrawerHeader = () => {
    if (decodedToken) {
      const { username } = decodedToken;
      const { imageUrl } = user;
      return (
        <>
          <div className="flex items-center gap-1">
            <span className="hidden md:flex text-whiteNew-0 font-normal text-sm">
              {username}
            </span>
            {imageUrl ? (
              <Image
                alt="profile"
                src={imageUrl ? imageUrl : profileUrl}
                className="usr-dp object-cover"
              />
            ) : (
              <span className="usr-dp"></span>
            )}
          </div>
        </>
      );
    }
    if (
      !decodedToken &&
      !toggleLoginScreen
      // &&
      // !showSharedTicketList &&
      // !showTicketQR &&
      // !showQRSharedTicketList
    ) {
      return (
        <div className="flex items-center gap-1">
          <span className="text-whiteNew-0">
            Already have an account?{' '}
            <span
              className="cursor-pointer text-violet-500 hover:text-violet-400 font-semibold"
              onClick={handleToggleLoginScreen}
            >
              Login
            </span>
          </span>
        </div>
      );
    }

    return null;
  };
  const ShowDrawerHeaderMemo = memo(ShowDrawerHeader);
  const { scanned_qr_data } = useSelector((s) => s.stream);
  const JoinUsingTicketIdPasscode = () => {
    const handleSubmit = useCallback(() => {
      const joining_id = joinIdPasscode.getFieldValue('ticket_id');
      const passcode = joinIdPasscode.getFieldValue('passcode');

      if (String(joining_id).length > 12) {
        return validate_qr_order_api.mutate({
          access_id: joining_id,
          passcode: passcode,
          slug: event_id,
          share: scanned_qr_data?.share,
        });
      } else {
        return validate_qr_ticket_api.mutate({
          access_id: joining_id,
          passcode: passcode,
          slug: event_id,
          share: scanned_qr_data?.share,
        });
      }
    }, []);
    const {
      isLoading: qr_ticket_loading,
      isError: qr_ticket_iserror,
      error: qr_ticket_error,
      reset: qr_ticket_reset,
      mutate: qr_ticket_mutate,
    } = validate_qr_ticket_api;
    const {
      isLoading: qr_order_loading,
      isError: qr_order_iserror,
      error: qr_order_error,
      reset: qr_order_reset,
      mutate: qr_order_mutate,
    } = validate_qr_order_api;

    const { isLoading, isError, error, mutate } = useMemo(
      () => ({
        isLoading: qr_ticket_loading || qr_order_loading,
        isError: qr_ticket_iserror || qr_order_iserror,
        error: qr_ticket_error || qr_order_error,
        reset: qr_ticket_reset || qr_order_reset,
        mutate: qr_ticket_mutate || qr_order_mutate,
      }),
      [
        qr_ticket_loading,
        qr_order_loading,
        qr_ticket_iserror,
        qr_order_iserror,
        qr_ticket_error,
        qr_order_error,
        qr_ticket_reset,
        qr_order_reset,
        qr_ticket_mutate,
        qr_order_mutate,
      ],
    );
    return (
      <div>
        <h2 className=" text-center text-[18px] md:text-2xl font-semibold mb-10 text-white">
          Ready to Join
        </h2>
        <div className="w-full overflow-hidden">
          <Form
            name="join_ticket"
            form={joinIdPasscode}
            layout="vertical"
            autoComplete="off"
            onFinish={handleSubmit}
            scrollToFirstError
          >
            <Form.Item
              name="ticket_id"
              label={`Enter Joining Id/${portalTexts?.nTkt} Id`}
              rules={[
                {
                  required: true,
                  message: `Please enter Joining Id/${portalTexts?.nTkt} Id`,
                },
              ]}
            >
              <Input
                placeholder={`Enter Joining Id/${portalTexts?.nTkt} Id (Ex. 4667 - GTYU - 6789)`}
              />
            </Form.Item>

            <Form.Item
              name="passcode"
              label="Enter Passcode"
              rules={[
                {
                  required: true,
                  message: 'Please enter passcode',
                },
              ]}
            >
              <Input placeholder="Enter Passcode (Ex. 56678)" />
            </Form.Item>
            <div className="flex justify-end w-full -mt-6 mb-4">
              {isError ? (
                <>
                  <div className="w-full flex justify-between items-center gap-2">
                    <span className="block mb-2 text-rose-600">
                      {error?.response?.data?.message}
                    </span>
                    {!error?.response?.data?.is_active ? (
                      <span>
                        {Boolean(
                          error?.response?.data?.data?.in_use ||
                            error?.response?.data?.data?.is_expired,
                        ) ? (
                          <>
                            <span
                              onClick={() => {
                                joinIdPasscode.resetFields(['passcode']);
                                mutate({
                                  access_id:
                                    joinIdPasscode.getFieldValue('ticket_id'),
                                  slug: event_id,
                                });
                                validate_qr_order_api.reset();
                                validate_qr_ticket_api.reset();
                              }}
                              className="block text-right mb-2 text-landingPurple-60 hover:cursor-pointer hover:text-landingPurple-70"
                            >
                              {isLoading ? (
                                <SpinnerBtn />
                              ) : (
                                'Regenerate Passcode'
                              )}
                            </span>
                          </>
                        ) : null}
                      </span>
                    ) : null}
                  </div>
                </>
              ) : null}
            </div>
            <div className="or-sep-line text-white text-center">
              <span className="font-semibold">Or</span>
            </div>

            <div className="scn-sect text-center mb-6 pb-6">
              <span onClick={openQRModal} className="cursor-pointer">
                <StreamingQR />
                <span>Scan Ticket QR code</span>
              </span>
            </div>
            <div className="absolute bottom-0 bg-[#000] left-0 right-0">
              <div className="py-2.5 px-3.5">
                <Form.Item className="mb-0">
                  <div className="flex justify-end">
                    <Button
                      htmlType="reset"
                      className="btn-cancel text-white"
                      onClick={() => onCloseDrawer()}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={isLoading}
                      htmlType="submit"
                      className={`btn-join text-white disabled:cursor-not-allowed disabled:bg-landingPurple-60 rounded-md`}
                    >
                      {isLoading ? <SpinnerBtn /> : 'Submit'}
                    </Button>
                  </div>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  };
  const JoinUsingTicketIdPasscodeMemo = memo(JoinUsingTicketIdPasscode);

  const DrawerFooter = () => {
    if (from_dashboard && decodedToken) return null;
    if (is_shared_authorized) return null;

    if (is_shared_authorized && !decodedToken && toggleLoginScreen)
      return (
        <div className="flex justify-end items-center">
          <span
            className="flex items-center justify-center cursor-pointer text-violet-500 hover:text-violet-400 font-semibold text-sm"
            onClick={handleToggleLoginScreen}
          >
            View All Tickets
          </span>
        </div>
      );
    if (showTicketListCards && (ticket_type.length || is_shared_authorized)) {
      if (!toggleScreens) {
        return (
          <div className="flex justify-end items-center">
            <span
              className="flex items-center justify-center cursor-pointer text-violet-500 hover:text-violet-400 font-semibold text-sm"
              onClick={handleToggleScreen}
            >
              {/* <HiArrowLeft size={20} className="mr-2" /> */}
              <span>View All Tickets</span>
            </span>
          </div>
        );
      }
      return (
        <div className="flex justify-end items-center font-semibold">
          <span
            className="flex items-center justify-center cursor-pointer text-violet-500 hover:text-violet-400 text-sm"
            onClick={handleToggleScreen}
          >
            {/* <HiArrowLeft size={20} className="mr-2" /> */}
            <span>Join Using Shared Ticket</span>
          </span>
        </div>
      );
    }

    if (!decodedToken && showAuthCards) {
      if (toggleLoginScreen) {
        return (
          <div className="flex justify-end items-center">
            <span
              className="flex items-center justify-center cursor-pointer text-violet-500 hover:text-violet-400 font-semibold text-sm"
              onClick={handleToggleLoginScreen}
            >
              <span>
                {is_shared_authorized
                  ? 'View All Tickets'
                  : 'Join Using Shared Ticket'}
              </span>
            </span>
          </div>
        );
      }
    }
  };
  const DrawerFooterMemo = memo(DrawerFooter);
  const [tabs, setTabs] = useState('Newest To Oldest');
  const { all_orders } = useSelector((s) => s.orders);

  const TicketsPanel = () => {
    const { data: orders_data } = all_orders;
    const tablist = [
      {
        type: 'Owned Tickets',
        // isEmpty: ticket_data?.length === 0,
        isEmpty: orders_data?.length === 0,
        data: <ViewAllTicketsMemo list={disclosure_list} type="owned" />,
      },
      {
        type: 'Shared Tickets',
        isEmpty: shared_ticket_list?.length === 0,
        data: (
          <ViewAllTicketsMemo list={shared_disclosure_list} type="shared" />
        ),
      },
    ];
    const menuOptions = [
      {
        name: 'Newest To Oldest', // desc
        action: () => {
          const array = [...disclosure_list]?.sort(
            (a, b) =>
              Number(new Date(b.created_at)) - Number(new Date(a.created_at)),
          );
          setDisClosureList(array);
          dispatch(updateDisclosureList(array));

          // const array = [...disclosure_list]
          // const sortedArray = array.sort((a, b) => Number(new Date(b.created_at)) - Number(new Date(a.created_at)))

          // dispatch()
        },
      },
      {
        name: 'Oldest To Newest', // asc
        action: () => {
          const array = [...disclosure_list]?.sort(
            (a, b) =>
              Number(new Date(a.created_at)) - Number(new Date(b.created_at)),
          );
          setDisClosureList(array);
          dispatch(updateDisclosureList(array));
        },
      },
      {
        name: 'Max. To Min.', // desc
        action: () => {
          const array = [...disclosure_list]?.sort(
            (a, b) => Number(new Date(b.count)) - Number(new Date(a.count)),
          );
          setDisClosureList(array);
          dispatch(updateDisclosureList(array));

          // const array = [...disclosure_list]
          // const sortedArray = array.sort((a, b) => Number(new Date(b.created_at)) - Number(new Date(a.created_at)))

          // dispatch()
        },
      },
      {
        name: 'Min. To Max.', // asc
        action: () => {
          const array = [...disclosure_list]?.sort(
            (a, b) => Number(new Date(a.count)) - Number(new Date(b.count)),
          );
          setDisClosureList(array);
          dispatch(updateDisclosureList(array));
        },
      },
    ];
    return (
      // <div className="w-full max-w-md px-2 py-16 sm:px-0">
      <>
        <h2 className="text-center text-[18px] md:text-2xl font-medium mt-3.5 mb-[30px] text-white">
          Select Ticket Category
        </h2>
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <div className="flex justify-between items-center">
            <Tab.List>
              {tablist.map(({ type, isEmpty }, index) => {
                if (isEmpty) return null;
                return (
                  <Tab
                    key={index}
                    className={({ selected }) =>
                      classNames(
                        'px-3 py-1.5 font-medium text-sm leading-5 rounded-lg ring-white ring-opacity-60 ring-offset-0 ring-offset-blue-50 focus:outline-none duration-200 ease-in border-0 focus:ring-0 text-whiteNew-0',
                        selected
                          ? 'bg-zinc-800 font-medium shadow-none text-whiteNew-0'
                          : 'text-whiteNew-0  hover:text-landingPurple-60',
                      )
                    }
                  >
                    {type}
                  </Tab>
                );
              })}
            </Tab.List>
            <div className="max-w-[180px]">
              <SliderFilterDropdown
                options={menuOptions}
                setTabs={setTabs}
                tabs={tabs}
                extra_props={{
                  all_mode: false,
                  classname:
                    'bg-zinc-800 hover:bg-zinc-900 py-[2px] px-2 rounded-[5px] text-whiteNew-0',
                }}
              />
            </div>
          </div>

          <Tab.Panels className="mt-3">
            {tablist.map(({ data, isEmpty }, index) => {
              if (isEmpty) return null;
              return <Tab.Panel key={index}>{data}</Tab.Panel>;
            })}
          </Tab.Panels>
        </Tab.Group>
      </>

      // </div>
    );
  };

  const TicketsPanelMemo = memo(TicketsPanel);

  const ViewAllTickets = ({ list, type }) => {
    return (
      <div>
        {!list?.length ? (
          <div className="bg-neutral-900 mt-2 w-full py-8 rounded-md">
            <h2 className="text-center text-base font-medium text-white flex justify-center items-center flex-col">
              <BsTicketDetailed className="text-4xl mb-3 -rotate-45" />
              There is no available tickets to join stream.
            </h2>
          </div>
        ) : (
          <>
            <div className="selectTickets ">
              {list?.map(({ id, isOpen, lable, count, panel }, index) => (
                <React.Fragment key={index}>
                  <button
                    className="flex w-full justify-between px-3 py-3 text-left text-sm first:rounded-tl-lg first:rounded-tr-lg last:rounded-bl-lg last:rounded-br-lg font-medium focus:outline-none focus-visible:ring-1  focus-visible:ring-opacity-75 duration-200 
                focus-visible:ring-neutral-800 border-neutral-800 bg-stone-900 hover:bg-stone-900 hover:bg-opacity-90 text-gray-100 mt-1.5"
                    onClick={() => {
                      if (String(id).includes('shared')) {
                        return handleShareClick(id);
                      }
                      return handleClick(id);
                    }}
                    aria-expanded={isOpen}
                    {...(isOpen && { 'aria-controls': id })}
                  >
                    <span>{lable}</span>
                    <div className="flex shrink-0 items-center gap-2">
                      <div>
                        <span className="flex px-2.5 font-semibold">
                          {count}
                        </span>
                      </div>
                      <MdKeyboardArrowDown
                        className={`${
                          isOpen ? 'rotate-180 transform' : ''
                        } h-5 w-5 text-gray-50`}
                      />
                    </div>
                  </button>
                  {isOpen ? (
                    <Transition
                      show={isOpen}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      {panel}
                    </Transition>
                  ) : null}
                </React.Fragment>
              ))}
            </div>
            {type === 'owned' &&
            ticket_data?.some((ticket) => Boolean(ticket.is_active)) ? (
              <div className="flex justify-end mb-1">
                <button
                  className="flex items-center justify-center cursor-pointer text-violet-500 hover:text-violet-400 font-semibold text-sm hover:underline"
                  onClick={onOpenSession}
                >
                  View Sessions
                </button>
              </div>
            ) : null}
          </>
        )}
      </div>
    );
  };
  const ViewAllTicketsMemo = memo(ViewAllTickets);

  const ShowDrawerContent = () => {
    const [form] = Form.useForm();
    const { error, loading } = useSelector((x) => x.auth);
    const handleSubmit = useCallback(() => {
      dispatch(authActions.resetState());
      dispatch(authActions.email_login(form.getFieldsValue()));
      dispatch(
        updateDrawerScreen({
          ...drawer_screen,
          ticket_screen: true,
        }),
      );
    }, [form]);

    if (showTicketListCards) {
      if (toggleScreens && (is_shared_authorized || ticket_data?.length)) {
        return <TicketsPanelMemo />;
      }
      return <JoinUsingTicketIdPasscodeMemo />;
    }

    if (showAuthCards) {
      if (toggleLoginScreen) {
        return (
          <div>
            {/* <ShowDrawerHeaderMemo /> */}
            <h2 className=" text-center text-[18px] md:text-2xl font-semibold mb-10 text-white">
              Authenticate
            </h2>
            <div className="w-full overflow-hidden">
              <Form
                onFinish={handleSubmit}
                form={form}
                name="auth"
                layout="vertical"
                autoComplete="off"
              >
                <Form.Item
                  name="email"
                  label="Enter Email"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter email',
                    },
                    {
                      pattern: EMAIL_REG,
                      message: 'Please enter valid email',
                    },
                  ]}
                >
                  <Input placeholder="Enter email" />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Enter Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter password',
                    },
                  ]}
                >
                  <Input type="password" placeholder="Enter Password" />
                </Form.Item>

                <div className="or-sep-line text-white text-center">
                  <span className="font-semibold">Or</span>
                </div>

                <div className="scn-sect text-center">
                  <span onClick={openQRModal} className="cursor-pointer">
                    <StreamingQR />
                    <span>Scan Ticket QR code</span>
                  </span>
                </div>
                <div className="absolute bottom-0 bg-[#000] left-0 right-0">
                  <div className="py-2.5 px-3.5">
                    {error ? (
                      <span className="block fw-bold text-end  mb-2 text-rose-600">
                        {error?.message}
                      </span>
                    ) : null}
                    <Form.Item className="mb-0">
                      <div className="flex justify-end">
                        <Button
                          htmlType="reset"
                          className="btn-cancel text-white"
                          onClick={() => onCloseDrawer()}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={loading}
                          htmlType="submit"
                          className={`btn-join text-white disabled:cursor-not-allowed disabled:bg-landingPurple-60 rounded-md`}
                        >
                          {loading ? <SpinnerBtn /> : 'Login'}
                        </Button>
                      </div>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        );
      }
      if (is_shared_authorized) {
        return <TicketsPanelMemo />;
      }
      return <JoinUsingTicketIdPasscodeMemo />;
    }
  };
  const ShowDrawerContentMemo = memo(ShowDrawerContent);
  const page = useRef(1);
  const nftRef = useRef(null);
  // const ShowSharedTicketContent = () => {
  //   if (showTicketQR) {
  //     if (toggleScreens) {
  //       return <JoinUsingTicketIdPasscodeMemo />;
  //     }
  //     return <AuthenticatePasscodeMemo />;
  //   }
  //   if (showSharedTicketList || showQRSharedTicketList) {
  //     if (toggleScreens) {
  //       return <JoinUsingTicketIdPasscodeMemo />;
  //     }
  //     if (is_shared_authorized) {
  //       return (
  //         <div>
  //           {/* <ShowDrawerHeaderMemo /> */}
  //           <h2 className="text-center text-[18px] md:text-2xl font-medium mb-10 text-white">
  //             Select Ticket Category
  //           </h2>
  //           <div className="selectTickets ">
  //             {disclosure_list?.map(
  //               ({ id, isOpen, lable, count, panel }, index) => (
  //                 <React.Fragment key={index}>
  //                   <button
  //                     className="flex w-full justify-between px-3 py-3 text-left text-sm first:rounded-tl-lg first:rounded-tr-lg last:rounded-bl-lg last:rounded-br-lg font-medium focus:outline-none focus-visible:ring-1  focus-visible:ring-opacity-75 duration-200
  //               focus-visible:ring-neutral-800 border-neutral-800 bg-neutral-800 hover:bg-neutral-900 text-gray-100 mt-1.5"
  //                     onClick={() => handleClick(id)}
  //                     aria-expanded={isOpen}
  //                     {...(isOpen && { 'aria-controls': id })}
  //                   >
  //                     <span>{lable}</span>
  //                     <div className="flex shrink-0 items-center gap-2">
  //                       <div>
  //                         <span className="flex px-2.5 font-semibold">
  //                           {count}
  //                         </span>
  //                       </div>
  //                       <MdKeyboardArrowDown
  //                         className={`${
  //                           isOpen ? 'rotate-180 transform' : ''
  //                         } h-5 w-5 text-gray-50`}
  //                       />
  //                     </div>
  //                   </button>
  //                   {isOpen ? (
  //                     <Transition
  //                       show={isOpen}
  //                       enter="transition duration-100 ease-out"
  //                       enterFrom="transform scale-95 opacity-0"
  //                       enterTo="transform scale-100 opacity-100"
  //                       leave="transition duration-75 ease-out"
  //                       leaveFrom="transform scale-100 opacity-100"
  //                       leaveTo="transform scale-95 opacity-0"
  //                     >
  //                       {panel}
  //                     </Transition>
  //                   ) : null}
  //                 </React.Fragment>
  //               ),
  //             )}
  //           </div>
  //         </div>
  //       );
  //     }
  //     return <AuthenticateJoinIdPasscodeMemo />;
  //   }
  // };
  // const ShowSharedTicketContentMemo = memo(ShowSharedTicketContent);
  const ShowNFTDrawerContent = () => {
    const { stream_nfts } = useSelector((s) => s.stream);
    const { data: stream_nfts_data, total_count } = stream_nfts;
    const LIMIT = 8;
    const loadMore = React.useCallback(() => {
      page.current += 1;
      fetch_paginated_nft.mutate({ page: page.current, limit: LIMIT });
    }, []);

    const hasMore = React.useMemo(() => {
      return stream_nfts_data?.length < total_count;
    }, [stream_nfts_data?.length, total_count]);

    if (Array.isArray(stream_nfts_data) && !stream_nfts_data.length)
      return (
        <div className="bg-neutral-900 mt-2 w-full py-8 rounded-md">
          <h2 className="text-center text-base font-medium text-white flex justify-center items-center flex-col">
            <BsTicketDetailed className="text-4xl mb-3 -rotate-45" />
            There is no available {portalTexts?.nTkts}.
          </h2>
        </div>
      );

    return (
      <div id="scroll-div">
        <Row>
          <Col md={12}>
            <div ref={nftRef} id="scroll-section" className="space-y-2">
              {stream_nfts_data?.map((data, index) => {
                const { nft_metadata, ticket_access_code, mint_img } = data;
                const { minted_at } = nft_metadata;
                return (
                  <div
                    key={String(ticket_access_code ?? '') + index}
                    className={`flex w-full justify-between px-2.5 py-2.5 text-left duration-200 rounded-md border-neutral-800 bg-neutral-800  text-gray-100 `}
                  >
                    <span>
                      <span className="flex gap-2">
                        <span className="flex bg-neutral-900 rounded-md w-[40px] h-[40px] overflow-hidden">
                          <Image
                            src={mint_img?.url ?? ''}
                            className={'w-full h-full object-cover'}
                          />
                        </span>
                        <div className="flex flex-col items-start">
                          <span className="text-sm font-medium">
                            {truncate(name, 20) ?? ''}
                          </span>
                          <span className="text-xs text-neutral-400">
                            {moment(
                              moment.utc(minted_at).local(),
                              'YYYY-MM-DD hh:mm a',
                            ).format(DATEFORMAT)}
                          </span>
                        </div>
                      </span>
                    </span>
                    <div className="flex shrink-0 items-center gap-2">
                      <span
                        onClick={() => {
                          dispatch(updateSingleNFTDetail(data));
                          onOpenNFTDetails();
                        }}
                        className="text-whiteNew-0 bg-purple-600 hover:bg-purple-700 px-2 rounded-md duration-200 ease-in cursor-pointer"
                      >
                        View {portalTexts?.nTkt}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
          {hasMore ? (
            <div className="mt-2 w-full flex justify-end" md={12}>
              <button
                onClick={loadMore}
                className="text-gray-50 md:text-sm border-indigo-500 py-2 px-3.5 md:px-7 bg-zinc-800 flex items-center gap-1 w-full lg:w-auto justify-center "
              >
                View More
                <AiOutlineArrowDown />
              </button>
            </div>
          ) : null}
        </Row>
      </div>
    );
  };

  const ShowNFTDrawerContentMemo = memo(ShowNFTDrawerContent);

  const QRContent = () => {
    const { scanned_qr_data } = useSelector((s) => s.stream);
    // const {
    //   data: qr_data,
    //   is_success,
    //   is_error,
    //   error,
    //   is_loading,
    // } = scanned_qr_data;

    const handleQRSuccess = useCallback(
      async (result) => {
        const data = JSON.parse(result);
        dispatch(
          updateQRScannedData({
            ...scanned_qr_data,
            data: data,
            // is_loading: true,
          }),
        );
        const { joining_id, passcode } = data;
        joinIdPasscode.setFieldValue('ticket_id', joining_id);
        joinIdPasscode.setFieldValue('passcode', passcode);
        closeQRModal();
        joinIdPasscode.submit();
        // const { joining_id, passcode, type } = data;
        // try {
        //   if (type === 'ticket') {
        //     const resp = await validate_qr_ticket({
        //       access_id: joining_id,
        //       passcode,
        //       slug: event_id,
        //     });
        //     if (resp?.data?.status && resp?.status === 200) {
        //       message.info(resp?.data?.message);
        //       if (resp?.data?.ticket_id) {
        //         dispatch(
        //           updateQRScannedData({
        //             ...scanned_qr_data,
        //             data: data,
        //             is_loading: false,
        //             is_success: true,
        //           }),
        //         );
        //         join_stream_direct.mutate({
        //           ticket_id: resp?.data?.ticket_id,
        //         });
        //       }
        //       closeQRModal();
        //     }
        //   }
        //   if (type === 'order') {
        //     const resp = await validate_shared_ticket({
        //       access_id: joining_id,
        //       passcode,
        //       slug: event_id,
        //     });
        //     if (resp?.data?.status && resp?.status === 200) {
        //       if (Array.isArray(resp.data?.data)) {
        //         dispatch(
        //           updateQRScannedData({
        //             ...scanned_qr_data,
        //             data: data,
        //             is_loading: false,
        //             is_success: true,
        //           }),
        //         );
        //         dispatch(updateShareAuthorization(true));

        //         dispatch(updateSharedTicketList(resp.data?.data));
        //       }
        //       closeQRModal();
        //     }
        //   }
        // } catch (e) {
        //   if (e?.response?.status === 400) {
        //     dispatch(
        //       updateQRScannedData({
        //         ...scanned_qr_data,
        //         is_error: true,
        //         data: data,
        //         error: e?.response?.data,
        //         is_loading: false,
        //       }),
        //     );
        //   }
        // }
      },
      [scanned_qr_data],
    );

    // const handleResendPasscode = useCallback(async () => {
    //   if (qr_data?.type === 'order') {
    //     const res = await validate_shared_ticket({
    //       access_id: qr_data?.joining_id,
    //       slug: event_id,
    //     });
    //     if (res?.data?.status) {
    //       message.info(res?.data?.message);
    //       closeQRModal();
    //     }
    //   }
    //   if (qr_data?.type === 'ticket') {
    //     const res = await validate_qr_ticket({
    //       access_id: qr_data?.joining_id,
    //       slug: event_id,
    //     });
    //     if (res?.data?.status) {
    //       message.info(res?.data?.message);
    //       closeQRModal();
    //     }
    //   }
    // }, [qr_data?.joining_id, qr_data?.type]);
    // if (is_success || is_error) {

    //   return (
    //     <div className="flex-col items-center">
    //       {is_loading ? <HashLoader size={100} color="#fff" /> : null}
    //       <div className="py-2.5 px-3.5">
    //         {is_error ? (
    //           <span className="block fw-bold text-end mb-2 text-rose-600">
    //             {error?.message}
    //           </span>
    //         ) : null}
    //         {Boolean(error?.data?.in_use || error?.data?.is_expired) ? (
    //           <span
    //             onClick={async (e) => {
    //               e.preventDefault();
    //               handleResendPasscode();
    //             }}
    //             className="block fw-bold text-end mb-2 text-landingPurple-60 hover:cursor-pointer hover:text-landingPurple-80"
    //           >
    //             {'Regenerate Passcode'}
    //           </span>
    //         ) : null}
    //       </div>
    //     </div>
    //   );
    // }
    return (
      <>
        <div className="w-full h-full relative rounded-md overflow-hidden border-1 border-dashed border-slate-800">
          <QrReader
            scanDelay={2000}
            constraints={{ facingMode: 'environment' }}
            onResult={(result, error) => {
              if (result) {
                dispatch(
                  updateQRScannedData({
                    ...scanned_qr_data,
                    data: JSON.parse(result?.text),
                  }),
                );
                return handleQRSuccess(result?.text);
              }
            }}
            style={{ width: '100%' }}
            videoContainerStyle={{ paddingTop: '75%' }}
          />
          <div className="absolute inset-0 z-10 w-full h-full">
            <div className="relative w-full h-full p-3 border-[20px] border-neutral-900 border-opacity-20">
              <div className="border-red-600 border-1 overflow-hidden absolute inset-0 z-10 w-full h-full"></div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const QRContentMemo = memo(QRContent);

  if (is_authorized && localStorage.getItem('streaming_details')) {
    return (
      <>
        <LiveStream />
      </>
    );
  }

  // const button_text = () => {
  //   // if (!isObjectEmpty(event_details)) {
  //   if (event_ended.current) return 'Event Ended';
  //   // }
  //   return 'Click to Join';
  // };

  console.log(
    !isObjectEmpty(event_details),
    !event_started.current,
    !event_ended.current,
  );
  return (
    <>
      <div className="max-h-[100vh] overflow-x-hidden overflow-y-auto scroll-pb-9 pb-16 bg-neutral-900">
        {!event_ended.current &&
        !isObjectEmpty(event_details) &&
        event_started.current ? (
          <div className="absolute bottom-0 z-20 md:bottom-20 w-full">
            <div className="relative container mb-3 px-7">
              <div className="relative w-full">
                <div className=" h-[2px] rounded-full hover:scale-[1.005] duration-300 ease-in bg-white w-full relative bottom-2 z-40">
                  <div className="h-[2px] bg-rose-600 w-[100%]"></div>
                  <div className="h-5 w-5 bg-rose-800 absolute top-1/2 -translate-y-1/2 z-40 rounded-full left-[99.5%] -translate-x-1/2"></div>
                </div>
                <div className="my-1">
                  <div className="flex justify-between gap-2 items-center w-full">
                    <div>{/* <span></span> Staring Duration*/}</div>
                    <div>
                      <span className="font-semibold flex gap-2 items-center text-rose-700">
                        <span className="relative flex h-2 w-2">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-rose-600 opacity-75"></span>
                          <span className="relative inline-flex rounded-full h-2 w-2 bg-rose-700"></span>
                        </span>
                        Live
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="absolute bottom-0 w-full">
            <div className="container mx-auto px-7">
              <div className=" h-1 bg-white w-full relative bottom-10 z-40"></div>
              <div className="h-5 w-5 bg-indigo-500 absolute bottom-8 z-40 rounded-full"></div>
            </div>
          </div>
        )}

        <BackgroundImage user={user} />
        <div className="container mx-auto px-7 relative z-50">
          <Row className="gy-3.5 mt-3.5 lg:mt-6">
            <Col lg={6}>
              <div>
                <button
                  disabled={!toShowAuthCard || event_ended.current}
                  className={`rounded-3xl ps-0 pe-6 py-0 flex items-center gap-2 border-1 
                focus:ring-0 disabled:cursor-not-allowed focus-visible:ring-0 duration-200 ease-in ${
                  toShowAuthCard && !event_ended.current
                    ? `${
                        event_started.current
                          ? 'bg-green-600 border-whiteNew-0 hover:bg-green-600 hover:bg-opacity-90'
                          : 'bg-zinc-900 hover:bg-violet-600 border-whiteNew-0'
                      } cursor-pointer `
                    : event_ended.current
                    ? 'bg-rose-600 border-whiteNew-0 hover:bg-rose-600 hover:bg-opacity-90'
                    : 'bg-zinc-900'
                }`}
                  onClick={handleJoinStream}
                >
                  <span className="relative flex h-9 w-9 shrink-0">
                    {!isObjectEmpty(event_details) &&
                    event_started.current &&
                    !event_ended.current ? (
                      <>
                        <AnimatePing className={'bg-green-600 opacity-80'} />
                      </>
                    ) : null}

                    <span
                      className={`${
                        !isObjectEmpty(event_details) && event_ended.current
                          ? 'bg-rose-600'
                          : !isObjectEmpty(event_details) &&
                            event_started.current
                          ? 'bg-green-600'
                          : 'bg-violet-500'
                      } rounded-full w-9 h-9 flex items-center justify-center border-white border-4 relative`}
                    >
                      {!isObjectEmpty(event_details) && event_ended.current ? (
                        <MdStop className="text-white text-lg" />
                      ) : (
                        <StreamingPlayIcon />
                      )}
                    </span>
                  </span>

                  <span className="text-white text-lg">
                    {event_ended.current ? 'Event Ended' : 'Click To Join'}
                  </span>
                </button>
                <div className="inline-flex items-center mt-6 md:mt-8 cstm-icon">
                  <span className="me-3">
                    <CalenderIcon2 className={'text-white text-[25px]'} />
                  </span>
                  <span className="text-white text-sm sm:text-[16px] md:text-[18px] lg:text-xl">
                    {moment(
                      moment.utc(start_date_time).local(),
                      'YYYY-MM-DD hh:mm a',
                    ).format(DATEFORMAT)}
                    {' - '}
                    {moment(
                      moment.utc(end_date_time).local(),
                      'YYYY-MM-DD hh:mm a',
                    ).format(DATEFORMAT)}
                  </span>
                </div>
                {event_type !== 'Virtual' ? (
                  <div className="flex items-center mt-2 md:mt-5 cstm-icon">
                    <span className="me-3">
                      <LocationIcon className={'text-white text-[25px]'} />
                    </span>
                    <span className="text-white text-sm sm:text-[16px] md:text-[18px] lg:text-xl">
                      {location ?? ''}
                    </span>
                  </div>
                ) : null}
              </div>
            </Col>
            {!isObjectEmpty(event_details) &&
            !event_started.current &&
            !event_ended.current ? (
              <Col lg={6}>
                <div className="mt-[20px] lg:mt-0">
                  <div className="flex lg:justify-end mb-3">
                    <h5
                      className={`text-xl text-white uppercase ${afterBorder} relative pb-1.5`}
                    >
                      Streaming In
                    </h5>
                  </div>
                  <ShowCountDownMemo />
                </div>
              </Col>
            ) : null}
            {/* <Col lg={6}>
              <div className="mt-[20px] lg:mt-0">
                <div className="flex lg:justify-end mb-3">
                  <h5
                    className={`text-xl text-white uppercase ${afterBorder} relative pb-1.5`}
                  >
                    Streaming In
                  </h5>
                </div>
                <ShowCountDownMemo />
              </div>
            </Col> */}

            <Col lg={12}>
              <div>
                <div className="mt-6 md:mt-9">
                  <div className="w-full max-w-3xl">
                    <h2 className="text-white text-2xl font-semibold sm:text-[35px] md:text-[40px] lg:text-[44px]">
                      {name ?? ''}
                    </h2>
                  </div>

                  <div className="w-full max-w-7xl mt-[20px]">
                    <p className="text-white text-sm md:text-[15px] lg:text-lg">
                      {description ?? ''}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-3 mt-10">
                  <button
                    onClick={() => history.navigate(`/event-detail/${slug}`)}
                    className="text-white md:text-xl border-indigo-500 py-2 px-3.5 md:px-7 bg-zinc-800 flex items-center gap-1 w-full lg:w-auto justify-center"
                  >
                    View Event
                    <HiArrowRight />
                  </button>
                  {decodedToken ? (
                    <button
                      onClick={onOpenNFT}
                      className="text-white md:text-xl border-indigo-500 py-2 px-3.5 md:px-7 bg-zinc-800 flex items-center gap-1 w-full lg:w-auto justify-center"
                    >
                      View {portalTexts?.nTkts}
                      <HiArrowRight />
                    </button>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* Drawer for owned ticketlist */}
      <Drawer
        placement={isVerticalLayout ? 'bottom' : 'right'}
        onClose={onCloseDrawer}
        open={portal_state.ticket_drawer}
        destroyOnClose
        title={
          <>
            <div className="flex justify-between items-center gap-2 w-full">
              <span className="flex">
                <span className="flex w-1 min-h-[30px] bg-neutral-700"></span>
              </span>
              <div className="flex justify-between items-center shrink-0 w-full">
                <div>
                  <DrawerFooterMemo />
                </div>
                <div>
                  <ShowDrawerHeaderMemo />
                </div>
              </div>
            </div>
          </>
        }
        width="540px"
        height={'90%'}
        className="!bg-black text-white cstm-drwr"
      >
        <div>
          <Row>
            <Col md={12}>
              <div>
                <ShowDrawerContentMemo />
              </div>
            </Col>
          </Row>
        </div>
      </Drawer>

      {/* Drawer of session */}
      <LiveSessionDetails />
      {/* <Drawer
        placement={isVerticalLayout ? 'bottom' : 'right'}
        onClose={onCloseSharedTicketDrawer}
        open={portal_state.shared_ticket_drawer}
        destroyOnClose
        title={
          <>
            <div className="flex justify-between items-center gap-2 w-full">
              <span className="flex">
                <span className="flex w-1 min-h-[30px] bg-neutral-700"></span>
              </span>
              <div className="flex justify-between items-center shrink-0 w-full">
                <div>
                  <DrawerFooterMemo />
                </div>
                <div>
                  <ShowDrawerHeaderMemo />
                </div>
              </div>
            </div>
          </>
        }
        width="540px"
        height={'90%'}
        className="!bg-black text-white cstm-drwr"
      >
        <div>
          <Row>
            <Col md={12}>
              <div>
                <ShowSharedTicketContentMemo />
              </div>
            </Col>
          </Row>
        </div>
      </Drawer> */}

      {/* Drawer for NFT */}
      <Drawer
        placement={isVerticalLayout ? 'bottom' : 'right'}
        onClose={onCloseNFT}
        open={portal_state.nft_drawer}
        zIndex={400}
        title={
          <>
            <div className="flex">
              <div className="flex justify-between items-center gap-2 w-full">
                <span className="flex">
                  <span className="flex w-1 min-h-[30px] bg-neutral-700"></span>
                </span>
                <div className="flex justify-between items-center shrink-0 w-full">
                  <span className="flex text-white font-medium">
                    {portalTexts?.nTkt} List
                  </span>
                </div>
              </div>
            </div>
          </>
        }
        width="540px"
        height={'90%'}
        className="!bg-black text-white cstm-drwr"
      >
        <ShowNFTDrawerContentMemo />
      </Drawer>

      {/* View Single NFT details */}
      {decodedToken && !isObjectEmpty(stream_nft_details) ? (
        <NFTDetailDrawer />
      ) : null}
      {/* QR MODAL */}
      <Modal
        isOpen={portal_state.show_qrmodal}
        // centered
        backdrop={'backdrop-blur-sm'}
        bgColor={'bg-neutral-900 bg-opacity-100 backdrop-blur-sm bg-center'}
        headerColor={'bg-neutral-900 border-b-neutral-800'}
        setIsOpen={closeQRModal}
        // onOk={closeQRModal}
        destroyOnClose
        header={
          <>
            <div className="w-full font-semibold text-whiteNew-0 items-start py-1">
              <h5 className="text-lg font-medium leading-6">Scan Ticket</h5>
            </div>
          </>
        }
        footer={null}
        zIndex={99999}
        bodyClass={'p-0'}
      >
        <div className="w-full h-full space-y-2 min-h-[300px] flex items-center flex-col justify-center">
          {/* <div className="flex gap-2 items-center justify-center w-full">
            <button className="p-[20px] bg-neutral-800 hover:bg-opacity-75 duration-200 ease-in rounded-md text-whiteNew-0 flex justify-center items-center gap-1 flex-col w-full max-w-[120px]">
              <span className="flex text-xl">
                <MdOutlineFileUpload />
              </span>
              Upload
            </button>
            <button className="p-[20px] bg-neutral-800 hover:bg-opacity-75 duration-200 ease-in rounded-md text-whiteNew-0 flex justify-center items-center gap-1 flex-col w-full max-w-[120px]">
              <span className="flex text-xl">
                <MdOutlineQrCodeScanner />
              </span>
              Scan
            </button>
          </div> */}
          <QRContentMemo />
          {/* <div className="flex justify-end w-full">
            <button className="px-2 p-1 bg-neutral-950 hover:bg-opacity-75 duration-200 ease-in rounded-md text-whiteNew-0 flex justify-center items-center gap-1">
              <span className="flex text-xl">
                <MdOutlineFileUpload />
              </span>
              Upload
            </button>
          </div> */}
          {/* <Button
            htmlType="submit"
            className={`btn-join text-white disabled:cursor-not-allowed disabled:bg-landingPurple-60 rounded-md`}
          >
            Upload
          </Button> */}
        </div>
      </Modal>
    </>
  );
}
