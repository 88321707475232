import React from 'react';
import HeaderFillTop from '../HeaderFillTop';
import ToogleSwitch from '../../util/ToogleSwitch';
import RightSideSectionWrapper from '../RightSideSectionWrapper';
import {
  afterBorder,
  durationAnimate,
  primaryBtnClass,
} from '../../common/DefaultClasses';
import CurrencyFormat from 'react-currency-format';
import { CustomWalletIcon } from '../../util/CustomIcons';
import TableComponentv2 from '../../common/TableComponentv2';
import { BsPlus } from 'react-icons/bs';
import { FaCcVisa, FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import Tooltip from '../../common/Tooltip';

export default function CreditCardInfo() {
  const dataSource = [
    {
      key: '1',
      holder_name: (
        <>
          <div className="flex items-center flex-wrap gap-3">
            <div className="rounded-lg">
              <FaCcVisa size={30} />
            </div>

            <div className="min-w-0 2xl:flex-auto space-y-1 font-medium capitalize">
              <span className="text-bluev2-600 dark:text-slate-50 text-sm 2xl:text-base font-medium flex items-center gap-2">
                Jhon
              </span>
            </div>
          </div>
        </>
      ),
      card_no: (
        <>
          <span>****4568</span>
        </>
      ),
      expiry: (
        <>
          <span>08/23</span>
        </>
      ),
      nick_name: <span className="flex capitalize">John</span>,
      zip_code: (
        <>
          <span className="flex">70540</span>
        </>
      ),
      country: <span className="flex">United States</span>,
      action: (
        <>
          <span className="flex items-center">
            <Tooltip title={'Edit'} placement={'top'}>
              <button
                className={`p-1.5 text-gray-600 hover:text-bluev2-600 text-lg ${durationAnimate}`}
              >
                <FaRegEdit />
              </button>
            </Tooltip>
            <Tooltip title={'Delete'} placement={'top'}>
              <button
                className={`p-1.5 text-gray-600 hover:text-rose-700 text-lg ${durationAnimate}`}
              >
                <FaRegTrashAlt />
              </button>
            </Tooltip>
          </span>
        </>
      ),
    },
  ];

  const columns = [
    {
      title: <span className="text-gray-500">Card Holder Name</span>,
      dataIndex: 'holder_name',
      key: 'holder_name',
      width: '200px',
    },
    // {
    //   title: <span className="text-gray-500">Wallet Type</span>,
    //   dataIndex: 'wallet_type',
    //   key: 'wallet_type',
    //   width: '400',
    // },
    {
      title: <span className="text-gray-500">Card No.</span>,
      dataIndex: 'card_no',
      key: 'card_no',
      width: '150px',
    },
    {
      title: <span className="text-gray-500">Expiry</span>,
      dataIndex: 'expiry',
      key: 'expiry',
      width: '100px',
    },
    {
      title: <span className="text-gray-500 inline-block pr-8">Nick Name</span>,
      dataIndex: 'nick_name',
      key: 'nick_name',
      width: '150px',
    },
    {
      title: <span className="text-gray-500">Zip Code</span>,
      dataIndex: 'zip_code',
      key: 'zip_code',
      width: '200px',
    },
    // {
    //   title: <span className="text-gray-500">Mint Address</span>,
    //   dataIndex: 'mint_add',
    //   key: 'mint_add',
    //   width: '200',
    // },
    {
      title: <span className="text-gray-500">Country</span>,
      dataIndex: 'country',
      key: 'country',
      width: '200px',
    },
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: 'action',
      key: 'action',
      width: '100px',
    },
    // {
    //   title: <span className="text-gray-900">Action</span>,
    //   dataIndex: 'Action',
    //   key: 'Action',
    //   width: '50',
    // },
    // {
    //   title: <span className="text-gray-500">Invoice</span>,
    //   dataIndex: 'Invoice',
    //   key: 'Invoice',
    // },
  ];
  return (
    <React.Fragment>
      <div>
        <div className="">
          <HeaderFillTop
            title={'Credit Card'}
            extraPill={''}
            extra={
              <>
                <button className={`${primaryBtnClass}`}>
                  <span>
                    <BsPlus className="text-lg" />
                  </span>
                  Add New Card
                </button>
              </>
            }
          />
        </div>
        <RightSideSectionWrapper>
          <section>
            <div className="block overflow-hidden" style={{ flex: '0 0 75%' }}>
              <div className="w-full">
                <TableComponentv2 dataSource={dataSource} columns={columns} />
              </div>
            </div>
          </section>
        </RightSideSectionWrapper>
      </div>
    </React.Fragment>
  );
}
