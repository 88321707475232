import React, { useCallback, useState } from 'react';
// import { useRef } from 'react';
// import { Card } from 'react-bootstrap';
// import { useSelector } from 'react-redux';
// import { getStreamDetails } from '../../features/stream_lounge/stream.slice';
// import { useConsolidatedTicket } from '../../pages/stream_lounge/hooks/useStreamLounge';
import { useDispatch, useSelector } from 'react-redux';
// import { updateJoinRoom } from '../../features/socket.slice';
import {
  updateShowSingletonSession,
  updateSingleNFTDetail,
  // getStreamDetails,
  // updateStreamTicketDetails,
  updateTicketAuthorization,
} from '../../features/stream_lounge/stream.slice';
import { message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import {
  enter_stream,
  // exit_from_stream,
} from '../../helpers/api/stream/stream';
import useSearchQuery from '../../hooks/useSearchQuery';
import {
  useStreamLounge,
  useUserDeviceLocation,
} from '../../pages/stream_lounge/hooks/useStreamLounge';
import { BsImageFill, BsTicketPerforated } from 'react-icons/bs';
import Tooltip from '../common/Tooltip';
import { useDrawerScreens } from '../../pages/stream_lounge/hooks/useDrawer';
// import { bookedTicketListByUserByEvent } from '../../helpers/api/event/tickets/tickets';
import useDecodedToken from '../../hooks/useDecodedToken';
import { MdLiveTv } from 'react-icons/md';
import { shortenAddress } from '../../utils/util';
import NFTShowDrawer from '../dashboard/order_ticket/NFTShowDrawer';
import { portalTexts } from '../../utils/LoaderMessage';

export default function JoinLoungeCard({
  selectedTicketTypeData,
  isShared,
  onCloseDrawer,
  onOpenNFTDetails,
  onOpenSession,
  event_details,
  isObjectEmpty,
}) {
  // const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  // const { events } = useDraggable(ref);
  // const { selectedTicketTypeData } = useConsolidatedTicket();
  const [openSubDrawer, setOpenSubDrawer] = useState(false);
  const [changeLayout, setChangeLayout] = useState('horizontal');
  const [tabs, setTabs] = useState('design');
  const deviceData = useUserDeviceLocation();
  const decodedToken = useDecodedToken();
  // const { json_header } = useApiHeader();
  // const { ticket_data } = useSelector(getStreamDetails);
  const dispatch = useDispatch();
  const query = useSearchQuery();
  // const { event_details, isObjectEmpty } = useStreamLounge();
  // const { onCloseDrawer, onOpenNFTDetails, onOpenSession } = useDrawerScreens();
  const { _id } = event_details;
  const { show_singleton_session, stream_nft_details } = useSelector(
    (s) => s.stream,
  );

  const enter_stream_api = useCallback(
    (ticket_id) => {
      return enter_stream({
        ticket_id,
        event_id: _id,
        device_info: deviceData,
        user_info: {},
      });
    },
    [deviceData, _id],
  );

  const mutation = useMutation(enter_stream_api, {
    onSuccess: (data) => {
      const { data: resp } = data;
      const streamObj = {
        is_authorized: true,
        ticket_details: {
          event_name: query.get('evn'),
          event_id: _id,
          ticket_id: resp?.ticket_id,
          access_id: resp?.access_id,
          category: resp?.category,
          device_info: deviceData,
          user_info: {},
          session_id: resp?.session_id,
        },
      };
      localStorage.setItem('streaming_details', JSON.stringify(streamObj));
      dispatch(updateTicketAuthorization(streamObj));
      // message.success('Wallet updated successfully');
      // if (data?.data) setuserdetails(data?.data);
      // setIsOpen3(false);
    },
    onError: (error) => {
      message.error(error.response.data.message);
    },
  });

  const handleJoinNow = useCallback(
    (ticket) => {
      onCloseDrawer();
      dispatch(updateSingleNFTDetail(ticket));
      return mutation.mutate(ticket?._id);
    },
    [mutation, onCloseDrawer, dispatch],
  );

  return (
    <React.Fragment>
      <div className="w-full bg-neutral-700 bg-opacity-30 rounded-b-md">
        {/* <Card className="max-h-[110px] 2xl:max-h-[125px] min-h-[125px] bg-gradient-to-r from-neutral-800 via-neutral-800 to-neutral-900 border-0 border-neutral-800 w-full rounded-lg bg-opacity-10 overflow-hidden">
          <Card.Body className="p-2 "> */}
        <div
          className="max-h-[220px] 2xl:max-h-[220px] overflow-auto overflow-x-hidden scrollCss scroll_lounge divide-y divide-slate-600"
          // {...events}
          // ref={ref}
        >
          {selectedTicketTypeData?.map((ticket, index) => {
            const {
              ticket_access_code,
              is_active,
              is_available,
              is_shared,
              transfer_nft,
              is_minting,
              _id: ticket_id,
            } = ticket;
            const showButtonState = {
              name: is_active
                ? 'Joined'
                : Boolean(transfer_nft)
                ? 'Transferred'
                : Boolean(is_shared && !is_available)
                ? 'Shared'
                : is_available && !is_active && is_minting === 1
                ? 'Join'
                : is_minting === 0
                ? 'Processing'
                : null,
              isDisable: Boolean(
                is_active ||
                  (is_shared && !is_available) ||
                  transfer_nft ||
                  is_minting === 0,
              ),
              color: is_active
                ? 'bg-rose-700'
                : transfer_nft
                ? 'bg-sky-600 text-dark-200'
                : Boolean(is_shared && !is_available)
                ? 'bg-amber-900'
                : is_minting === 0
                ? 'bg-rose-500'
                : is_available && !is_active
                ? 'bg-landingPurple-60'
                : null,
            };
            // const ticket_name =
            //   selectedTicketTypeData.selectedType + ' ' + String(index);
            // +
            // '-'+
            // String(ticket_access_code || 'NA');
            return (
              <div
                key={ticket_access_code}
                className="select_screen_card px-2 py-2"
              >
                <div className="flex justify-between items-center gap-2">
                  <div className="flex items-center gap-2 dark:text-whiteNew-0 text-zinc-800 text-base 2xl:text-lg">
                    <Tooltip title={'View Ticket'}>
                      <span
                        onClick={() => {
                          dispatch(updateSingleNFTDetail(ticket));
                          // onOpenNFTDetails();
                          setOpenSubDrawer(true);
                        }}
                        className="hover:cursor-pointer hover:text-purplev2-800"
                      >
                        <span className="-rotate-45">🎫 </span>
                        <span>{shortenAddress(ticket_id) ?? 'NA'}</span>
                      </span>
                    </Tooltip>

                    <span>|</span>
                    {ticket?.nft_metadata &&
                    !isObjectEmpty(ticket?.nft_metadata) &&
                    decodedToken &&
                    !isShared &&
                    showButtonState.name ? (
                      <Tooltip title={`View ${portalTexts?.nTkt}`}>
                        <span
                          onClick={() => {
                            dispatch(updateSingleNFTDetail(ticket));
                            onOpenNFTDetails(true);
                          }}
                          className="hover:cursor-pointer hover:text-purplev2-800"
                        >
                          <span>🖼️ </span>
                          <span>
                            {shortenAddress(
                              String(ticket?.nft_metadata?.token_id),
                            ) ?? 'NA'}
                          </span>
                        </span>
                      </Tooltip>
                    ) : null}
                  </div>

                  {showButtonState.name ? (
                    <div className="flex align-center gap-2">
                      {is_active ? (
                        <Tooltip
                          title={
                            ticket?.event_type === 'Virtual'
                              ? 'View Sessions'
                              : 'On Virtual Only'
                          }
                        >
                          <button
                            type="button"
                            disabled={ticket?.event_type !== 'Virtual'}
                            className="text-whiteNew-0 hover:text-rose-600 rounded-md duration-200 ease-in disabled:opacity-30"
                            onClick={() => {
                              dispatch(
                                updateShowSingletonSession({
                                  ...show_singleton_session,
                                  ticket_id: ticket_access_code,
                                }),
                              );
                              onOpenSession();
                              // setOpenSessions(!openSessions);
                              // dispatch(setOpenSessions(true));
                              // // getLogs(ticket?._id);
                              // // dispatch(setLogsMode('single_ticket'));
                              // dispatch(setSelectedTicket(ticket));
                            }}
                          >
                            <MdLiveTv size={'21'} />
                          </button>
                        </Tooltip>
                      ) : null}

                      <button
                        disabled={
                          showButtonState.isDisable ||
                          mutation.isLoading ||
                          showButtonState.name === 'Joined'
                        }
                        onClick={() => handleJoinNow(ticket)}
                        // className="bg-landingPurple-60 disabled:cursor-not-allowed hover:bg-landingPurple-70 text-whiteNew-0 px-2 rounded-md duration-200 ease-in min-w-[60px]"
                        className={`${showButtonState.color} disabled:cursor-not-allowed hover:bg-opacity-80 text-whiteNew-0 px-2 rounded-md duration-200 ease-in min-w-[60px]`}
                      >
                        {showButtonState.name}
                      </button>
                      {/* bg-bluev2-500- shared, bg-amber-900 - tranfered, bg-rose-700 - joined,  bg-landingPurple-60 - Join */}
                      {/* {showButtonState.name === 'Joined' ? (
                        <button
                          disabled={mutation.isLoading}
                          onClick={() => handleExitFromStream(ticket_id)}
                          className={`${
                            mutation.isLoading
                              ? 'bg-rose-700 cursor-not-allowed'
                              : 'hover:bg-rose-800 bg-rose-700'
                          } text-whiteNew-0 px-2 rounded-md duration-200 ease-in`}
                        >
                          <MdClose />
                        </button>
                      ) : null} */}
                    </div>
                  ) : null}
                </div>
                {/* <div className="h-1 w-full bg-neutral-700 my-2 last_class"></div> */}
              </div>
            );
          })}
        </div>
        {/* </Card.Body>
        </Card> */}
      </div>
      <NFTShowDrawer
        setOpenSubDrawer={setOpenSubDrawer}
        openSubDrawer={openSubDrawer}
        drawertype={'design'}
        nftData={stream_nft_details}
        getTickets={[stream_nft_details]}
        setChangeLayout={setChangeLayout}
        changeLayout={changeLayout}
        setTabs={setTabs}
        darkTheme
      />
    </React.Fragment>
  );
}
