import screenfull from 'screenfull';
import { useStreamLounge } from './useStreamLounge';
import { useState } from 'react';
import { useRef } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';

export function usePlayer() {
  const { event_details } = useStreamLounge();
  const { banner_image } = event_details;

  const [state, setState] = useState({
    url:
      event_details?.event_link ??
      'https://www.youtube.com/watch?v=PasLhCLgwfw&t=5s' ??
      null,
    pip: false,
    playing: true,
    controls: false,
    light: banner_image?.url ?? false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    isShowVolumeSlider: false,
    isShowPlaybackPanel: false,
    isShowCustomControls: false,
  });

  const player = useRef(null);

  const load = useCallback(
    (url) => {
      setState({
        ...state,
        url,
        played: 0,
        loaded: 0,
        pip: false,
      });
    },
    [state],
  );

  const handlePlayPause = useCallback(() => {
    setState({ ...state, playing: !state.playing });
  }, [state]);

  const handleStop = useCallback(() => {
    setState({ ...state, url: null, playing: false });
  }, [state]);

  const handleToggleControls = useCallback(() => {
    const url = state.url;
    setState(
      {
        ...state,
        controls: !state.controls,
        url: null,
      },
      () => load(url),
    );
  }, [load, state]);

  const handleToggleLight = useCallback(() => {
    setState({ ...state, light: !state.light });
  }, [state]);

  const handleToggleShowCustomControls = useCallback(() => {
    setState({ ...state, isShowCustomControls: true });
  }, [state]);

  const handleToggleLoop = useCallback(() => {
    setState({ ...state, loop: !state.loop });
  }, [state]);

  const handleVolumeChange = useCallback(
    (e) => {
      setState({ ...state, volume: parseFloat(e.target.value) });
    },
    [state],
  );

  const handleToggleVolumeSlider = useCallback(
    (val) => {
      setState({ ...state, isShowVolumeSlider: val });
    },
    [state],
  );

  const handleTogglePlaybackPanel = useCallback(
    (val) => {
      setState({ ...state, isShowPlaybackPanel: val });
    },
    [state],
  );

  const handleToggleMuted = useCallback(() => {
    setState({ ...state, muted: !state.muted });
  }, [state]);

  const handleSetPlaybackRate = useCallback(
    (e) => {
      setState({ ...state, playbackRate: parseFloat(e.target.value) });
    },
    [state],
  );

  const handleOnPlaybackRateChange = useCallback(
    (speed) => {
      setState({ ...state, playbackRate: parseFloat(speed) });
    },
    [state],
  );

  const handleTogglePIP = useCallback(() => {
    setState({ ...state, pip: !state.pip });
  }, [state]);

  const handlePlay = useCallback(() => {
    setState({ ...state, playing: true, isShowCustomControls: true });
  }, [state]);

  const handleEnablePIP = useCallback(() => {
    setState({ ...state, pip: true });
  }, [state]);

  const handleDisablePIP = useCallback(() => {
    setState({ ...state, pip: false });
  }, [state]);

  const handlePause = useCallback(() => {
    setState({ ...state, playing: false });
  }, [state]);

  const handleSeekMouseDown = useCallback(
    (e) => {
      setState({ ...state, seeking: true });
    },
    [state],
  );

  const handleSeekChange = useCallback(
    (e) => {
      setState({ ...state, played: parseFloat(e.target.value) });
    },
    [state],
  );

  const handleSeekMouseUp = useCallback(
    (e) => {
      setState({ ...state, seeking: false });
      player.current.seekTo(parseFloat(e.target.value));
    },
    [state],
  );

  const handleProgress = useCallback(
    (stateIn) => {
      // We only want to update time slider if we are not currently seeking
      if (!state.seeking) {
        setState({ ...state, ...stateIn });
      }
    },
    [state],
  );

  const handleEnded = useCallback(() => {
    setState({ ...state, playing: state.loop });
  }, [state]);

  const handleDuration = useCallback(
    (duration) => {
      setState({ ...state, duration });
    },
    [state],
  );

  const handleClickFullscreen = useCallback(() => {
    screenfull.request(document.querySelector('.react-player'));
  }, []);

  const renderLoadButton = useCallback(
    (url, label) => {
      return <button onClick={() => load(url)}>{label}</button>;
    },
    [load],
  );

  const memoizedValue = useMemo(
    () => ({
      state,
      player,
      setState,
      load,
      handlePlayPause,
      handleStop,
      handleToggleControls,
      handleToggleLight,
      handleToggleLoop,
      handleVolumeChange,
      handleToggleMuted,
      handleSetPlaybackRate,
      handleOnPlaybackRateChange,
      handleTogglePIP,
      handlePlay,
      handleEnablePIP,
      handleDisablePIP,
      handlePause,
      handleSeekMouseDown,
      handleSeekChange,
      handleSeekMouseUp,
      handleProgress,
      handleEnded,
      handleDuration,
      handleClickFullscreen,
      renderLoadButton,
      handleToggleVolumeSlider,
      handleTogglePlaybackPanel,
      handleToggleShowCustomControls,
    }),
    [
      state,
      player,
      load,
      handlePlayPause,
      handleStop,
      handleToggleControls,
      handleToggleLight,
      handleToggleLoop,
      handleVolumeChange,
      handleToggleMuted,
      handleSetPlaybackRate,
      handleOnPlaybackRateChange,
      handleTogglePIP,
      handlePlay,
      handleEnablePIP,
      handleDisablePIP,
      handlePause,
      handleSeekMouseDown,
      handleSeekChange,
      handleSeekMouseUp,
      handleProgress,
      handleEnded,
      handleDuration,
      handleClickFullscreen,
      renderLoadButton,
      handleToggleVolumeSlider,
      handleTogglePlaybackPanel,
      handleToggleShowCustomControls,
    ],
  );

  return memoizedValue;
}
