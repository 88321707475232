import React from 'react';
import { Card } from 'react-bootstrap';
import { profileUrl } from '../util/CustomIcons';
import EventImageVideo from '../common/EventImageVideo';

export default function TourImageCard({ data }) {
  return (
    <React.Fragment>
      <Card className="border-0 rounded-xl overflow-hidden shadow-sm hover:shadow-lg w-full h-full relative bg-transparent parentCardHover card_rounded_box">
        <Card.Body className="p-0 h-full">
          <div className="relative w-full">
            <div className="cardOverlay"></div>
            <div className="absolute inset-0 w-full h-full overflow-hidden">
              <EventImageVideo
                data={data}
                alt="Artist gallery"
                className={'w-full h-full object-cover'}
                loading="lazy"
                // width="384px"
                // height="512px"
              />
              {/* <img
                src={data?.url ? data?.url : profileUrl}
                alt="event-img"
                // className="w-full h-full object-cover childCardHover"
                className="w-full h-full object-cover"
              /> */}
            </div>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
