import { useCallback, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getStreamDetails,
  init_authorize_ticket,
  updateActiveSessionList,
  updateDrawerScreen,
  updateShareAuthorization,
  updateSharedTicketList,
  updateStreamEventDetails,
  updateStreamNFTs,
  updateStreamTicketDetails,
  updateTicketAuthorization,
} from '../../../features/stream_lounge/stream.slice';
import useDecodedToken, { useApiHeader } from '../../../hooks/useDecodedToken';
import useSWR from 'swr';
import useSearchQuery from '../../../hooks/useSearchQuery';
import {
  bookedTicketList,
  bookedTicketListByUserByEvent,
} from '../../../helpers/api/event/tickets/tickets';
import { useMemo } from 'react';
import moment from 'moment';
import { deviceDetect } from 'react-device-detect';
import {
  enter_stream,
  exit_from_stream,
  get_all_active_sessions,
  get_all_nfts,
  get_current_ticket_status,
  validate_qr_ticket,
  validate_shared_ticket,
} from '../../../helpers/api/stream/stream';
import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';
import { getEventAllDetail } from '../../../helpers/api/event/seat_mapping/seat_mapping';
import { sleep } from '../../../utils/util';
import axios from 'axios';

import { updateAllOrders } from '../../../features/orders/orders.slice';

export function useStreamLounge(slug = null) {
  const [toShowAuthCard, setToShowAuthCard] = useState(false);
  const { ticket_data, event_details, authorize_ticket } =
    useSelector(getStreamDetails);
  const { json_header } = useApiHeader();
  const decodedToken = useDecodedToken();
  const query = useSearchQuery();
  const dispatch = useDispatch();
  const { stream_nfts } = useSelector((s) => s.stream);
  const { ticket_details } = authorize_ticket;
  const event_ended = useRef(null);
  const event_started = useRef(null);
  const user_id = query.get('uid');
  const order_id = query.get('oid');
  const shared_email = query.get('em');
  const from_dashboard = query.get('utm');

  const showSharedTicketList = Boolean(user_id && order_id && shared_email);

  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0;
  };

  const event_id = useMemo(() => query.get('evn') ?? slug, [query, slug]);

  const event_fetcher = () => {
    if (isObjectEmpty(event_details) || event_id !== event_details?.slug)
      return getEventAllDetail(event_id, json_header);
    return null;
  };

  // const fetcher = useCallback(() => {
  //   if (decodedToken && !showSharedTicketList && (!ticket_data?.length || event_id !== event_details?.slug))
  //     return bookedTicketListByUserByEvent(event_id, decodedToken?.id, json_header)
  //   return null;
  // }, [decodedToken, event_details?.slug, event_id, json_header, showSharedTicketList, ticket_data?.length])

  // const shared_ticket_list_fetcher = () => {
  //   if (showSharedTicketList && (!ticket_data?.length || event_id !== event_details?.slug)) {
  //     return get_all_shared_order({ user_id: user_id, shared_email: shared_email, order_id: order_id })
  //   }
  //   return null
  // }

  const nft_fetcher = useCallback(
    ({ page = 1, limit = 8 }) => {
      if (decodedToken) {
        return get_all_nfts({
          user_id: decodedToken?.id,
          event_id: event_id,
          page,
          limit,
        });
      }
      return null;
    },
    [decodedToken, event_id],
  );

  // const { data: ticket_data_from_api } = useSWR(event_id ? event_id + '/ticket' : null, fetcher, {
  //   revalidateOnFocus: false,
  //   revalidateOnMount: true,
  //   revalidateOnReconnect: true,
  // })

  const { data: event_data_from_api } = useSWR(event_id, event_fetcher, {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
  });

  // const { data: order_data_from_api } = useSWR(event_id + '/ticket_orders', orders_fetcher, {
  //   revalidateOnFocus: false,
  //   revalidateOnMount: true,
  //   revalidateOnReconnect: true
  // })

  // const { data: shared_ticket_data_from_api } = useSWR(event_id + '/shared-ticket', shared_ticket_list_fetcher, {
  //   revalidateOnFocus: false,
  //   revalidateOnMount: true,
  //   revalidateOnReconnect: true,
  // })

  const { data: nfts_from_api } = useSWR(
    '/stream/nfts' + decodedToken?.id,
    nft_fetcher,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    },
  );

  const getStreamingDetails = useCallback(async () => {
    let sessiondetails = await get_all_active_sessions({
      uid: decodedToken?.id,
      event_id: event_id,
      type: 'NO_AUTH',
    });
    dispatch(updateActiveSessionList(sessiondetails?.data));
  }, [event_id, decodedToken?.id, dispatch]);

  const refetchOrderData = useCallback(async () => {
    const { data, count, all_ticket_count } = await bookedTicketList(
      1,
      100,
      'Virtual',
      'ongoing',
      'all',
      event_id,
      true,
    );
    dispatch(updateAllOrders({ data, count, all_ticket_count }));
  }, [event_id, dispatch]);

  const refetchTicketsData = useCallback(async () => {
    if (
      decodedToken &&
      !showSharedTicketList &&
      (!ticket_data?.length || event_id !== event_details?.slug)
    ) {
      const { data: ticket_data_api, shared_ticket } =
        await bookedTicketListByUserByEvent(
          event_id,
          decodedToken?.id,
          json_header,
        );
      dispatch(updateStreamTicketDetails(ticket_data_api));
      if (from_dashboard) {
        dispatch(updateSharedTicketList(shared_ticket));
      }
    }

    return null;
  }, [
    decodedToken,
    dispatch,
    event_details?.slug,
    event_id,
    from_dashboard,
    json_header,
    showSharedTicketList,
    ticket_data?.length,
  ]);

  useEffect(() => {
    if (event_data_from_api) {
      const { data: event_details } = event_data_from_api;

      dispatch(updateStreamEventDetails(event_details));
    }
    // if (ticket_data_from_api) {
    //   const { data: ticket_data_api, shared_ticket } = ticket_data_from_api
    //   dispatch(updateStreamTicketDetails(ticket_data_api))
    //   if (from_dashboard) {
    //     dispatch(updateSharedTicketList(shared_ticket))
    //   }
    // }

    // if (order_data_from_api) {
    //   const { data: order_data_api, count } = order_data_from_api;
    //   dispatch(updateAllOrders({ data: order_data_api, count: count }));
    // }

    if (nfts_from_api) {
      const { data: nfts } = nfts_from_api;
      dispatch(
        updateStreamNFTs({ data: nfts?.data, total_count: nfts?.totalCount }),
      );
    }

    // if (shared_ticket_data_from_api) {
    //   const { data: shared_tickets } = shared_ticket_data_from_api
    //   dispatch(updateStreamTicketDetails(shared_tickets?.data))
    // }

    if (event_id !== ticket_details?.event_name) {
      localStorage.removeItem('streaming_details');
      dispatch(updateTicketAuthorization(init_authorize_ticket));
    }
  }, [
    event_data_from_api,
    dispatch,
    event_id,
    ticket_details?.event_name,
    nfts_from_api,
    from_dashboard,
    decodedToken?.id,
  ]);

  const timer = useRef(null);

  useEffect(() => {
    const { start_date_time, end_date_time } = event_details;
    const date = moment.utc(start_date_time).local();

    timer.current = setInterval(() => {
      if (!isObjectEmpty(event_details)) {
        event_started.current = Boolean(
          start_date_time &&
          moment.utc(start_date_time).local().isSameOrBefore(moment.now()),
        );
        event_ended.current = Boolean(
          end_date_time &&
          moment.utc(end_date_time).local().isBefore(moment.now()),
        );
      }
    }, 1000);
    // }, 1000);

    if (event_started.current && !event_ended.current) {
      setToShowAuthCard(true);
    } else if (event_ended.current) {
      clearInterval(timer.current);
    } else {
      const interval = setInterval(() => {
        if (
          !event_ended.current &&
          start_date_time &&
          date - Date.now() <= 2 * 60 * 1000
        ) {
          clearInterval(interval);
          setToShowAuthCard(true);
        }
      }, 1000);
    }
    // const date = new Date().setMinutes(new Date().getMinutes() + 3);
    // clearInterval(interval);
    return () => {
      if (timer) {
        clearInterval(timer.current);
        // interval.current = null;
      }
    };
  }, [event_details]);

  const fetch_paginated_nft = useMutation(nft_fetcher, {
    onSuccess: (data) => {
      const nfts = [...stream_nfts?.data, ...data?.data?.data];
      dispatch(
        updateStreamNFTs({ data: nfts, total_count: data.data?.totalCount }),
      );
    },
  });

  return {
    toShowAuthCard,
    event_details,
    ticket_data,
    isObjectEmpty,
    authorize_ticket,
    event_ended,
    setToShowAuthCard,
    event_started,
    event_id,
    from_dashboard,
    getStreamingDetails,
    fetch_paginated_nft,
    refetchOrderData,
    refetchTicketsData,
  };
}

export function useShowWhichCard() {
  const query = useSearchQuery();
  const decodedToken = useDecodedToken();
  const event_id = query.get('evn');
  const { event_details } = useStreamLounge();
  const { _id } = event_details;
  const ticket_id = query.get('id');
  const ticket_pass = query.get('vep');
  const user_id = query.get('uid');
  const order_id = query.get('oid');
  const shared_email = query.get('em');
  const from_email = query.get('shared_email');
  const qr = query.get('qr');
  const joining_id = query.get('joining_id');
  const deviceData = useUserDeviceLocation();
  const dispatch = useDispatch();

  const showIFrameDirectly = Boolean(event_id && ticket_id && ticket_pass);
  const showAuthCards = Boolean(event_id && !ticket_id && !ticket_pass);
  const showTicketListCards = Boolean(
    decodedToken && event_id && !ticket_id && !ticket_pass,
  );
  const showSharedTicketList = Boolean(
    event_id && user_id && order_id && shared_email,
  );
  const showQRSharedTicketList = Boolean(
    showSharedTicketList && joining_id && qr,
  );
  const showTicketQR = Boolean(
    event_id && ticket_id && ticket_pass && from_email && qr,
  );

  const enter_stream_api = useCallback(
    ({ ticket_id }) => {
      return enter_stream({
        ticket_id,
        event_id: _id,
        device_info: deviceData,
        user_info: {},
      });
    },
    [deviceData, _id],
  );

  const mutation = useMutation(enter_stream_api, {
    onSuccess: (data) => {
      const { data: resp } = data;
      const streamObj = {
        is_authorized: true,
        ticket_details: {
          event_name: query.get('evn'),
          event_id: _id,
          ticket_id: resp?.ticket_id,
          access_id: resp?.access_id,
          category: resp?.category,
          // passcode: ticket_pass,
          device_info: deviceData,
          user_info: {},
          session_id: resp?.session_id,
        },
      };
      localStorage.setItem('streaming_details', JSON.stringify(streamObj));
      dispatch(updateTicketAuthorization(streamObj));
      message.success('You have entered in to the event.');
      // if (data?.data) setuserdetails(data?.data);
      // setIsOpen3(false);
    },
    onError: (error) => {
      message.error(error.response.data.message);
    },
  });

  const value = useMemo(
    () => ({
      showIFrameDirectly,
      showAuthCards,
      showTicketListCards,
      join_stream_direct: mutation,
      showSharedTicketList,
      user_id,
      shared_email,
      order_id,
      qr,
      from_email,
      ticket_id,
      ticket_pass,
      showTicketQR,
      event_id,
      joining_id,
      showQRSharedTicketList,
      // get_shared_ticket: shared_ticket
      // get_shared_ticket
    }),
    [
      showIFrameDirectly,
      showAuthCards,
      showTicketListCards,
      mutation,
      showSharedTicketList,
      user_id,
      shared_email,
      order_id,
      showTicketQR,
      qr,
      from_email,
      ticket_id,
      ticket_pass,
      event_id,
      joining_id,
      showQRSharedTicketList,
      // shared_ticket,
      // get_shared_ticket
    ],
  );
  // const mounted = useRef(true)

  // useEffect(() => {
  //   if (mounted.current && showIFrameDirectly && event_started.current && !event_ended.current) {
  //     mounted.current = false
  //     mutation.mutate()
  //   }
  // }, [showIFrameDirectly, mutation, event_started, event_ended])

  return value;
}

export function useConsolidatedTicket() {
  const { ticket_data, shared_ticket_list } = useSelector(getStreamDetails);
  const { isObjectEmpty } = useStreamLounge();

  const ticket_data_memoized = useMemo(() => {
    return (
      Array.isArray(ticket_data) &&
      ticket_data.filter(
        ({ is_active, is_available, is_shared, ticket_access_code }) =>
          Boolean(ticket_access_code),
      )
    );
  }, [ticket_data]);

  const shared_ticket_data_memoized = useMemo(() => {
    return (
      Array.isArray(shared_ticket_list) &&
      shared_ticket_list.filter(
        ({ is_active, is_available, is_shared, ticket_access_code }) =>
          Boolean(!is_active && !is_shared && ticket_access_code),
      )
    );
  }, [shared_ticket_list]);

  const groupBy = useCallback((list, keyGetter) => {
    const map = new Map();
    if (!list?.length) return map;
    list?.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }, []);
  const consolidated_ticket = useMemo(
    () =>
      Object.fromEntries(
        groupBy(ticket_data_memoized, (item) => item.categoryDetails.name),
      ),
    [groupBy, ticket_data_memoized],
  );

  const consolidated_shared_ticket = useMemo(
    () =>
      Object.fromEntries(
        groupBy(
          shared_ticket_data_memoized,
          (item) => item?.categoryDetails?.name,
        ),
      ),
    [groupBy, shared_ticket_data_memoized],
  );
  const [selectedTicketTypeData, setSelectedTicketTypeData] = useState({
    selectedType: '',
    selectedTicketData: [],
  });

  const ticket_type = useMemo(() => {
    return Object.keys(consolidated_ticket).filter((empty) => {
      return Boolean(consolidated_ticket[empty].length);
    });
  }, [consolidated_ticket]);

  const ticket_data_all_type = useMemo(() => {
    return Object.values(consolidated_ticket);
  }, [consolidated_ticket]);
  const shared_ticket_type = useMemo(() => {
    return Object.keys(consolidated_shared_ticket).filter((empty) => {
      return Boolean(consolidated_shared_ticket[empty].length);
    });
  }, [consolidated_shared_ticket]);

  const shared_ticket_data_all_type = useMemo(() => {
    return Object.values(consolidated_shared_ticket);
  }, [consolidated_shared_ticket]);

  const isCalledRef = useRef(false);
  const handleUpdateRadio = useCallback(
    (index) => {
      return setSelectedTicketTypeData({
        ...selectedTicketTypeData,
        selectedType: ticket_type[index],
        selectedTicketData: ticket_data_all_type[index],
      });
    },
    [ticket_type, ticket_data_all_type, selectedTicketTypeData],
  );

  useEffect(() => {
    if (!isObjectEmpty(consolidated_ticket) && !isCalledRef.current) {
      isCalledRef.current = true;
      handleUpdateRadio(0);
    }
  }, [consolidated_ticket, handleUpdateRadio, isObjectEmpty]);

  const values = useMemo(
    () => ({
      ticket_type,
      ticket_data_all_type,
      handleUpdateRadio,
      selectedTicketTypeData,
      shared_ticket_type,
      shared_ticket_data_all_type,
      groupBy,
    }),
    [
      ticket_type,
      ticket_data_all_type,
      handleUpdateRadio,
      selectedTicketTypeData,
      shared_ticket_type,
      shared_ticket_data_all_type,
      groupBy,
    ],
  );

  return values;
}

export function useUserDeviceLocation() {
  const deviceData = deviceDetect();
  return deviceData;
}

export function useStreamAPI() {
  const { authorize_ticket } = useStreamLounge();
  const { ticket_details } = authorize_ticket;
  const dispatch = useDispatch();
  const { drawer_screen } = useSelector((s) => s.stream);
  const {
    // joining_id, shared_email, order_id, ticket_id, ticket_pass, qr, from_email, event_id,
    join_stream_direct,
  } = useShowWhichCard();

  const exit_stream_api = useCallback(() => {
    return exit_from_stream(ticket_details?.ticket_id);
  }, [ticket_details?.ticket_id]);

  const handleExitEvent = useCallback(() => {
    return exit_from_stream(ticket_details?.ticket_id).then(() => {
      localStorage.removeItem('streaming_details');
      dispatch(updateTicketAuthorization({}));
    });
  }, [dispatch, ticket_details?.ticket_id]);

  const { data: ticket_status } = useSWR(
    '/stream' + ticket_details?.ticket_id,
    () =>
      get_current_ticket_status(
        ticket_details?.ticket_id,
        ticket_details?.session_id,
      ),
    {
      refreshInterval: 10 * 1000,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      // revalidateIfStale: true
    },
  );

  const nft_data_blockchain = useCallback(async (url) => {
    await sleep(1000);
    const data = await axios.get(url);
    return data.data;
  }, []);

  // const mutation = useMutation('validate-shared-ticket', get_all_shared_order({ user_id, shared_email, order_id }), {
  //   onSuccess: (data) => {
  //     dispatch(updateStreamTicketDetails(data?.data?.data))
  //     dispatch(updateShareAuthorization(true))
  //   }
  // })

  const validate_qr_ticket_callback = useCallback(
    ({ access_id, passcode, slug }) => {
      return validate_qr_ticket({
        access_id,
        passcode,
        // encrypted_password: ticket_pass,
        // shared_email: from_email,
        slug,
        // qr: Number(qr),
      });
    },
    [],
  );

  const validate_qr_ticket_api = useMutation(validate_qr_ticket_callback, {
    onSuccess: (data) => {
      message.info(data?.data?.message);
      console.log(data?.data)
      // joinIdPasscode.resetFields(["ticket_id", "passcode"])
      if (data?.data?.ticket_id || data?.data?.data) {
        join_stream_direct.mutate({ ticket_id: data?.data?.ticket_id || data?.data?.data });
      }
    },
  });

  const validate_qr_order_callback = useCallback(
    ({ access_id, passcode, slug }) => {
      let payload = {
        access_id,
        passcode,
        slug,
        // access_id: joining_id,
        // order_id: order_id,
        // email: shared_email,
      };
      return validate_shared_ticket(payload);
    },
    [],
  );

  const validate_qr_order_api = useMutation(validate_qr_order_callback, {
    onSuccess: (data) => {
      message.info(data?.data?.message);
      // joinIdPasscode.resetFields(["ticket_id", "passcode"])
      if (data?.data?.status && Array.isArray(data?.data?.data)) {
        dispatch(updateShareAuthorization(true));
        dispatch(updateSharedTicketList(data?.data?.data));
        dispatch(
          updateDrawerScreen({
            ...drawer_screen,
            ticket_screen: true,
          }),
        );
      }
    },
  });

  return {
    exit_stream_api,
    ticket_status,
    handleExitEvent,
    nft_data_blockchain,
    validate_qr_ticket_api,
    validate_qr_order_api,
    validate_qr_order_callback,
  };
}
