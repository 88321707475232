import { Tab } from '@headlessui/react';
import React, { useState } from 'react';
import {
  TabOutlineClass,
  afterBorder,
  classNames,
} from '../../common/DefaultClasses';
import NotificationList from './NotificationList';
import { BellIcon } from '../CustomIcons';
import { AiOutlineSetting } from 'react-icons/ai';

export default function NotificationSection({ mobView = false }) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div
      // className={`${
      //   headerDark
      //     ? 'border-1 border-neutral-800 overflow-hidden bg-neutral-900 backdrop-blur-3xl  bg-opacity-100'
      //     : 'bg-gray-50'
      // } p-2 h-96 max-h-sm`}
      className="w-full"
    >
      {!mobView ? (
        <div className="flex items-center justify-between gap-2 px-3.5  mb-3.5">
          <div>
            <h6 className="text-color-primary text-lg font-semibold">
              Notifications
            </h6>
          </div>
          <div>
            <button className="text-color-primary underline text-xs font-medium">
              Mark all as read
            </button>
          </div>
        </div>
      ) : null}

      <div className={`relative w-full`}>
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <div
            className={`relative flex items-center gap-1.5 w-full ${afterBorder} sticky`}
          >
            <div className="w-full">
              <Tab.List className="flex gap-1.5 px-1 overflow-x-auto overflow-hidden scrollCss scrollHide">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      'shrink-0 px-2.5 py-1.5 text-sm font-medium leading-5 ring-white ring-opacity-60 ring-offset-1 ring-offset-blue-400 focus:outline-none focus:ring-0 focus:ring-offset-0 border-b-[3px] dark:text-white border-t-0 border-l-0 border-r-0',
                      selected
                        ? 'bg-whiteNew-0 dark:bg-gray-1 dark:bg-opacity-0 shadow-none border-b-[3px] border-b-sky-600 dark:text-whiteNew-0 dark:border-b-color-primaryColor2 font-medium text-sky-600 py-3.5'
                        : 'text-neutral-900 hover:text-sky-600 border-b-white hover:bg-transparent dark:hover:text-whiteNew-0 dark:text-opacity-60 dark:border-b-transparent border-b-[3px]',
                    )
                  }
                >
                  <span className="flex items-center gap-2.5">
                    {/* <span>
                      <BellIcon />
                    </span> */}
                    Alert
                    <span className="bg-color-primaryColor2 text-whiteNew-0 text-[11px] min-w-[1rem] flex justify-center items-center px-1.5 rounded-[4px]">
                      10
                    </span>
                  </span>
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      'shrink-0 px-2.5 py-1.5 text-sm font-medium leading-5 ring-white ring-opacity-60 ring-offset-1 ring-offset-blue-400 focus:outline-none focus:ring-0 focus:ring-offset-0 border-b-[3px] dark:text-white border-t-0 border-l-0 border-r-0',
                      selected
                        ? 'bg-whiteNew-0 dark:bg-gray-1 dark:bg-opacity-0 shadow-none border-b-[3px] border-b-sky-600 dark:text-whiteNew-0 dark:border-b-color-primaryColor2 font-medium text-sky-600 py-3.5'
                        : 'text-neutral-900 hover:text-sky-600 border-b-white hover:bg-transparent dark:hover:text-whiteNew-0 dark:text-opacity-60 dark:border-b-transparent border-b-[3px]',
                    )
                  }
                >
                  <span className="flex items-center gap-2.5">
                    {/* <span>
                      <AiOutlineNotification />
                    </span> */}
                    Announcement
                    <span className="bg-color-primaryColor2 text-whiteNew-0 text-[11px] min-w-[1rem] flex justify-center items-center px-1.5 rounded-[4px]">
                      10
                    </span>
                  </span>
                </Tab>
              </Tab.List>
            </div>
            <div className="pr-3.5">
              <button className="p-0 text-xl text-color-secondary hover:text-color-primary rounded-md">
                <AiOutlineSetting />
              </button>
            </div>
          </div>

          <div
            className={`${
              !mobView
                ? 'min-h-[500px] lg:max-h-[calc(100svh-320px)] overflow-y-auto overflow-x-hidden scrollCss h-full'
                : ''
            } `}
          >
            <Tab.Panels className="">
              <Tab.Panel
                className={classNames(
                  'rounded-xl bg-transparent',
                  TabOutlineClass,
                  // 'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                )}
              >
                <div className="p-0">
                  <NotificationList />
                  {/* <div
                  className={`flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                >
                  <div className="py-10">
                    <span className="flex items-center">
                        <span
                          className={`text-color-primary text-sm font-medium`}
                      >
                        No Notifications
                      </span>
                    </span>
                    <span
                      className={`text-color-primary text-sm font-medium flex`}
                    >
                      Have not revived any Notification yet
                    </span>
                  </div>
                </div> */}
                </div>
              </Tab.Panel>
              <Tab.Panel
                className={classNames(
                  'rounded-xl bg-transparent',
                  TabOutlineClass,
                  // 'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                )}
              >
                <div className="p-2 min-h-[500px] flex items-center justify-center">
                  <div
                    type="button"
                    className={`flex justify-center rounded-md px-2 py-2 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                  >
                    <div className="py-10 text-center space-y-2">
                      <span className="flex items-center justify-center">
                        <span
                          className={`text-color-primary text-3xl font-medium`}
                        >
                          <BellIcon />
                        </span>
                      </span>
                      <div className="flex flex-col">
                        <span className="flex items-center justify-center">
                          <span
                            className={`text-color-secondary text-sm font-medium`}
                          >
                            No Notifications
                          </span>
                        </span>
                        <span
                          className={`text-color-secondary text-sm font-medium flex`}
                        >
                          Have not revived any Notification yet
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </div>
  );
}
