import axios from 'axios';
import config from '../../../../api/Config';

export async function getTicketCategory(id, page, limit, headers) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.category_list
    }/${id}?limit=${100}&page=${page}`,
    headers,
  );

  return res.data;
}

export async function getExperienceBundle(headers) {
  const res = await axios.get(
    `${config.baseUrl + config.endpoints.experience_bundle}?limit=100`,
    headers,
  );

  return res.data;
}

export async function addExperienceBundles(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.experience_bundle,
    body,
    headers,
  );

  return res.data;
}

export async function createCategory(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.create_category,
    body,
    headers,
  );

  return res.data;
}

export async function getSingleTicketCategory(id, headers) {
  const res = await axios.get(
    config.baseUrl + config.endpoints.category_Details + id,
    headers,
  );

  return res.data;
}

export async function deleteTicketCat(payload, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.delete_category,
    payload,
    headers,
  );
  return res.data;
}

export async function updateCategory(body, headers) {
  const res = await axios.put(
    config.baseUrl + config.endpoints.update_category,
    body,
    headers,
  );

  return res.data;
}
