import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LogoGoTix, SpinnerBtn } from '../../util/CustomIcons';
import { primaryLoginBtnClassGradient } from '../../common/DefaultClasses';
import { Form } from 'antd';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useMutation } from '@tanstack/react-query';
import { useApiHeader } from '../../../hooks/useDecodedToken';
import {
  get2FAOTPBYEmail,
  resend2FAOTPBYEmail,
} from '../../../helpers/api/user/user_details';
import mydecodedTokenFunction from '../../../utils/DecodedToken';
import { history } from '../../../helpers/router_templates/history';
import { formatTime } from '../../../utils/util';

export default function TFA({ darkTheme }) {
  const [count, setCount] = useState(90);
  const [showMessage, setShowMessage] = useState(false);
  const [otp, setOtp] = useState();
  const { json_header } = useApiHeader();
  const [otpError, setOtpError] = useState('');
  const [totalAttempt, setTotalAttempt] = useState(3);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (count === 0) {
      setShowMessage(true);
    }
  }, [count]);

  // const dispatch = useDispatch();
  const [form] = Form.useForm();

  // const { state } = useLocation();
  // const navigate = useNavigate();

  // console.log(localStorage.getItem('auth'));

  const insertNote = (otp) => {
    const payload = {
      otp: otp,
      email: localStorage.getItem('authEmail'),
    };

    return get2FAOTPBYEmail(payload, json_header);
  };

  const navigateOnSuccess = useCallback((userData) => {
    // console.log('userData', userData);
    // console.log('history', history);

    if (userData?.first_login) {
      const { from } = {
        from: {
          pathname: `/user/${userData?.username || userData?.id}/register`,
        },
      };
      return history.navigate(from);
    } else if (window.location.search) {
      if (window.location.pathname === '/streaming') {
        const { from } = {
          from: {
            pathname: window.location.pathname,
            search: window.location.search,
          },
        };
        return history.navigate(from);
      } else {
        const { from } = {
          from: {
            pathname: new URLSearchParams(window.location.search).get(
              'utm_source',
            ),
          },
        };
        return history.navigate(from);
      }
    } else if (userData?.user_type === 'seller') {
      const { from } = {
        from: {
          pathname: `/user/${userData?.username || userData?.id}/dashboard`,
        },
      };
      return history.navigate(`${from.pathname}?eo=true`);
    } else if (history.location?.state) {
      return history.navigate(-1);
    } else {
      const { from } = {
        from: { pathname: `/` },
      };
      return history.navigate(from);
    }
  }, []);

  const mutation = useMutation(insertNote, {
    onSuccess: (user) => {
      localStorage.setItem('token', user?.access_token);
      const userData = mydecodedTokenFunction(user?.access_token);
      navigateOnSuccess(userData);
      localStorage.removeItem('authEmail');
      // localStorage.removeItem('2FATotalAttempt');
    },
    onError: () => {
      setOtpError('Invalid OTP');
    },
  });

  const insertResendNote = (otp) => {
    const payload = {
      email: localStorage.getItem('authEmail'),
    };

    return resend2FAOTPBYEmail(payload, json_header);
  };

  const resendOTP = useMutation(insertResendNote, {
    onSuccess: (user) => {
      // localStorage.setItem('token', user?.access_token);
      // const userData = mydecodedTokenFunction(user?.access_token);
      // navigateOnSuccess(userData);
      setShowMessage(false);
      setCount(90);
      if (totalAttempt > 0) {
        // localStorage.setItem('2FATotalAttempt', totalAttempt - 1);
        setTotalAttempt(totalAttempt - 1);
      }
    },
    onError: () => {
      // setOtpError('Invalid OTP');
    },
  });

  const handleSubmit = useCallback(() => {
    let testOTP = /^\d+$/.test(otp);
    // console.log('here');
    if (!testOTP) {
      setOtpError('OTP should be of 6 digit code');
    } else {
      mutation.mutate(otp);
    }
  }, [mutation, otp]);

  return (
    <React.Fragment>
      <div className="flex justify-between w-full items-center">
        <Link to="/" className="flex justify-center w-[90px] md:w-[170px]">
          <LogoGoTix
            className={`w-full max-w-sm h-10 dark:text-whiteNew-0 text-zinc-900`}
          />
        </Link>
        {/* <div className="flex items-center gap-2">
          <Link
            to={'..'}
            className={`relative justify-center items-center gap-2 dark:bg-zinc-900 bg-lightTheme-70 dark:hover:bg-zinc-800 hover:bg-lightTheme-80 border-1 dark:border-zinc-800 border-zinc-200 shadow-sm shadow-slate-600 duration-200 ease-in  py-1.5 disabled:opacity-70 cursor-pointer dark:text-whiteNew-0 text-zinc-600 flex rounded-[8px] p-1.5 px-2.5 text-xs md:text-base`}
            onClick={() => {
              if (is_from_checkout) {
                navigate(-1);
              } else {
                navigate('/');
              }
              dispatch(authActions.resetState());
              // navigate('/');
            }}
          >
            {is_from_checkout ? 'Continue as guest' : 'Explore as guest'}
          </Link>

          {!is_from_checkout ? (
            <>
              <div className="h-full flex items-center">
                <span
                  className="flex h-4 bg-gray-600"
                  style={{ width: '1px' }}
                ></span>
              </div>
              <Tooltip placement={'top'} title={'Demo Stadium'}>
                <Link
                  to="/demo"
                  className={`relative justify-center items-center gap-2 dark:bg-zinc-900 bg-lightTheme-70 dark:hover:bg-zinc-800 hover:bg-lightTheme-80 border-1 dark:border-zinc-800 border-zinc-200 shadow-sm shadow-slate-600 duration-200 ease-in disabled:opacity-70 cursor-pointer dark:text-whiteNew-0 text-zinc-900 flex rounded-[8px] p-[9px] text-xs md:text-base`}
                  onClick={() => {
                    dispatch(authActions.resetState());
                  }}
                >
                  <MdOutlineStadium />
                </Link>
              </Tooltip>
            </>
          ) : null}
        </div> */}
      </div>
      <div className="flex justify-center items-start flex-col pt-6 pb-3.5">
        <h1
          className={`dark:text-whiteNew-0 text-zinc-900 font-medium text-lg 2xl:text-xl `}
        >
          Authenticate your Login.
        </h1>
        <h3 className="text-gray-500 text-sm 2xl:text-base mt-2 mb-4">
          Please check your email for the 6-digit authentication code.
        </h3>
        <ReactInputVerificationCode
          value={otp}
          placeholder="-"
          length={6}
          autoFocus={true}
          onChange={setOtp}
          className="text-dar"
        />
        <div className="mt-3 w-full">
          <div className="flex justify-between flex-row items-center">
            {/* <h4>You can use maximum 3 attempt</h4> */}
            <span className="text-[12px] text-green-500 font-medium">
              {totalAttempt}/3 attempts remaining.
            </span>
          </div>
        </div>
        {/* {otpError !== '' && (
          <Form.Text className="text-danger">{otpError}</Form.Text>
        )} */}
      </div>
      <div className="space-y-4 max-w-screen-lg w-full">
        {otpError !== '' && (
          <span className="block fw-bold text-center mb-2 text-danger">
            {otpError}
          </span>
        )}
        <div className="mt-3.5">
          <Form
            // initialValues={{
            //   phone_code:
            //     localStorage.getItem('phone_code') || phones[0]?.value,
            //   credential: localStorage.getItem('auth'),
            // }}
            form={form}
            onFinish={handleSubmit}
            disabled={mutation?.isLoading || resendOTP?.isLoading}
          >
            <div className="flex flex-col w-full">
              {showMessage ? (
                <button
                  className={`${primaryLoginBtnClassGradient} p-2.5`}
                  onClick={(e) => {
                    e.preventDefault();
                    resendOTP.mutate();
                  }}
                  disabled={
                    mutation?.isLoading ||
                    resendOTP?.isLoading ||
                    totalAttempt === 0
                  }
                >
                  {resendOTP?.isLoading ? (
                    <SpinnerBtn className={'text-xl'} />
                  ) : (
                    'Resend OTP'
                  )}
                </button>
              ) : (
                <div className="bg-sky-50 dark:bg-zinc-800 rounded-[8px] p-2.5 text-center font-semibold text-sky-500 dark:text-landingPurple-60 border-[1px] border-sky-200 dark:border-zinc-600">
                  Resend OTP in {formatTime(count)} sec.
                </div>
              )}
            </div>
            <div className="flex justify-center items-center mt-2.5">
              <button
                type="submit"
                disabled={mutation?.isLoading || resendOTP?.isLoading}
                className={`${primaryLoginBtnClassGradient} p-2.5 ${
                  mutation?.isLoading
                    ? 'rounded-full w-auto'
                    : 'w-full rounded-[8px]'
                } duration-200 ease-in`}
              >
                {mutation?.isLoading ? (
                  <SpinnerBtn className={'text-xl'} />
                ) : (
                  'Verify Now'
                )}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}
