import axios from 'axios';
import config from '../../../api/Config';
import Axios from '../../router_templates/axios';

export async function getUserDetails(userid) {
  const res = await Axios.get(config.endpoints.user_details + userid);
  return res.data;
}

export async function getBusinessUserDetails(header) {
  try {
    const res = await axios.get(
      config.baseUrl + config.endpoints.get_business_user_details,
      header,
    );
    return res.data;
  } catch (err) {
    return null;
  }
}

export async function getWalletBalanceForBusinessUser(user_id, header) {
  try {
    const res = await axios.get(
      config.baseUrl +
        config.endpoints.check_balance_for_business +
        `?user_id=${user_id}`,
      header,
    );
    return res.data;
  } catch (err) {
    return null;
  }
}

export async function updateUserDetails(body, headers) {
  const res = await axios.put(
    config.baseUrl + config.endpoints.user_details,
    body,
    headers,
  );

  await axios
    .get(config.baseUrl + config.endpoints.refresh_token, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((res) => {
      localStorage.setItem('token', res.data.data);
    });
  return res.data;
}

/*================ Update Session Preference API: function updateSessionPreference  Open ================*/
export async function updateSessionPreference(body, headers) {
  const res = await axios.put(
    config.baseUrl + config.endpoints.user_details,
    body,
    headers,
  );

  // await axios
  //   .get(config.baseUrl + config.endpoints.refresh_token, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${localStorage.getItem('token')}`,
  //     },
  //   })
  //   .then((res) => {
  //     localStorage.setItem('token', res.data.data);
  //   });
  return res.data;
}
/*===================== Update Session Preference API: function updateSessionPreference Close ================*/
export async function addAddress(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.add_address,
    body,
    headers,
  );
  return res.data;
}
export async function followAndUnfollow(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.follow_unfollow,
    body,
    headers,
  );
  return res.data;
}
export async function updateAddress(body, headers) {
  const res = await axios.put(
    config.baseUrl + config.endpoints.update_address,
    body,
    headers,
  );
  return res.data;
}
export async function deleteAddress(id, headers) {
  const res = await axios.delete(
    config.baseUrl + config.endpoints.delete_address + id,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res.data;
}
export async function get_addresses() {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const res = await axios.get(config.baseUrl + config.endpoints.get_addresses, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return res.data;
}
export async function getAddressById(id, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.get_address_by_id + { id },
    headers,
  );
  return res.data;
}
export async function removeUserImages(body, headers) {
  const res = await axios.put(
    config.baseUrl + config.endpoints.remove_images,
    body,
    headers,
  );

  return res.data;
}

export async function addUpdateWallet(body) {
  if (!body?.wallet_address) return;
  const res = await axios.put(
    config.baseUrl + config.endpoints.add_update_wallet,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res.data;
}

export async function setWalletPrimary(body) {
  const res = await axios.put(
    config.baseUrl + config.endpoints.set_primary_wallet,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res.data;
}

export async function removeWallet(body) {
  const res = await axios.put(
    config.baseUrl + config.endpoints.remove_wallet,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res.data;
}

///2FA Authentication API.

export async function get2FAOTPBYEmail(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.get2FAOTP,
    body,
    headers,
  );
  return res.data;
}

export async function resend2FAOTPBYEmail(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.resendOTP2FA,
    body,
    headers,
  );
  return res.data;
}
