import React, { useState } from 'react';
import usePage from '../../hooks/event/page/usePage';
export const GetEventContext = React.createContext();

export const GetEventProvider = ({ children }) => {
  const [eventList, setEventList] = useState({
    data: [],
    dataCount: 0,
  });
  const [currentPage, setCurrentPage] = usePage(1);
  // const [pageSize, setPageSize] = usePage(10);

  return (
    <GetEventContext.Provider
      value={{
        eventList,
        setEventList,
        currentPage,
        setCurrentPage,
        // pageSize,
        // setPageSize,
      }}
    >
      {children}
    </GetEventContext.Provider>
  );
};

export default GetEventProvider;
