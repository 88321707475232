import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useDecodedToken from '../hooks/useDecodedToken';

const TwoMinuteTimer = ({
  setTimer,
  closeIntervalModal,
  logout,
  // sessionExpiredModal,
  // setSessionExpiredModal,
}) => {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(2);
  // const [sessionExpiredModal, setSessionExpiredModal] = useState(false);

  const decodedToken = useDecodedToken();

  useEffect(() => {
    let interval = null;

    interval = setInterval(() => {
      if (seconds === 0) {
        if (minutes === 0) {
          // setSessionExpiredModal(true);
          // closeIntervalModal();
          setTimer(true);

          clearInterval(interval);
          logout();
          // when the time is up we have to logout here
          // setTimeout(() => {
          //   setSessionExpiredModal(true);
          // }, 3000);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, minutes]);

  return (
    <>
      <div className="flex flex-col gap-1">
        <div>
          <span className="text-lg">You will be signed out in:</span>
        </div>
        <div>
          <span className="text-xl">
            {minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </span>
        </div>
        <div>
          <span className="text-sm text-gray-600 dark:text-amber-200">
            Your session has been inactive for some time.
          </span>
        </div>
        <div>
          <span className="text-sm">
            You may change your session inactivity preference in settings.{' '}
            <Link
              to={`/user/${decodedToken?.username}/dashboard?tab=about&subtab=security`}
              className="text-sm text-color-primaryColor2 font-medium hover:underline"
              onClick={closeIntervalModal}
            >
              Click Here!
            </Link>
          </span>
        </div>
        {/* <LogoutAlert
          sessionExpiredModal={sessionExpiredModal}
          setSessionExpiredModal={setSessionExpiredModal}
        /> */}
      </div>
    </>
  );
};

export default TwoMinuteTimer;
