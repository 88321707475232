import useSWR from 'swr';
import { getEventAllDetail } from '../../../../helpers/api/event/seat_mapping/seat_mapping';
import { useCallback } from 'react';
import moment from 'moment';
import { eventStatusMessage } from '../../../../utils/LoaderMessage';

export const useEventDetailPageHooks = (slug, json_header, page, limit) => {
  const eventdetails = useSWR(
    ['event_id', slug],
    (url) => {
      if (slug !== undefined && slug !== null && slug !== '') {
        return getEventAllDetail(slug, json_header);
      } else {
        return null;
      }
    },
    {
      // refreshInterval: 30 * 1000,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      keepPreviousData: true,
      // onSuccess(data) {
      // },
    },
  );

  return { eventdetails };
};

// export const useValidationHooks = (
//   ticketCategoryData,
//   setShowStrip,
//   eventType,
//   venue,
// ) => {
//   const isNoTicketsAvailable = useCallback(
//     (type) => {
//       const virtual_tickets = ticketCategoryData?.data?.filter((ticket) =>
//         Boolean(ticket?.event_ticket_category === type),
//       );
//       let result;
//       window.scrollTo(0, 0);
//       const checkExpired = (item) => {
//         if (
//           Boolean(Date.parse(moment(item?.end_date)) < Date.parse(moment()))
//         ) {
//           return true;
//         }
//       };

//       const toStartSale = (item) => {
//         if (
//           Boolean(Date.parse(moment(item?.start_date)) > Date.parse(moment()))
//         ) {
//           return true;
//         }
//         return false;
//       };
//       const checkTickets = (item) => {
//         let assignedSeat = venue && venue?.[0]?.assignedInfo?.assignedSeats;
//         let filter = assignedSeat?.filter(
//           (data) => data?.categories_id === item._id,
//         );

//         if (filter && filter?.length > 0) {
//           if (
//             Number(filter?.[0]?.totalSeats) ===
//               Number(item?.booked_tickets_count) ||
//             (item?.remaining_tickets !== '' &&
//               Boolean(Number(item?.remaining_tickets) <= 0)) ||
//             Boolean(Date.parse(moment(item?.end_date)) < Date.parse(moment()))
//           ) {
//             return true;
//           } else {
//             return false;
//           }
//         } else {
//           return true;
//         }
//       };

//       if (Array.isArray(virtual_tickets) && virtual_tickets?.length) {
//         return virtual_tickets?.every((item) => {
//           if (type === 'Physical') {
//             // console.log('comesHere');
//             if (toStartSale(item)) {
//               result = {
//                 message: `The sale of tickets will starts on  ${item?.start_date}.`,
//                 status: true,
//               };
//               setShowStrip(result);
//               return true;
//             } else if (checkExpired(item)) {
//               result = {
//                 message:
//                   eventType === 'both'
//                     ? 'The sale of tickets for in-person event has ended.'
//                     : 'The sale of tickets for this event has ended.',
//                 status: true,
//               };
//               setShowStrip(result);
//               return true;
//             } else if (checkTickets(item)) {
//               result = {
//                 message:
//                   eventType === 'both'
//                     ? 'In-person event tickets for this event are sold out.'
//                     : 'In-person event tickets for this event are sold out.',
//                 status: true,
//               };
//               setShowStrip(result);
//               return true;
//             }
//           } else if (type === 'Virtual') {
//             if (toStartSale(item)) {
//               result = {
//                 message: `The sale of tickets will starts on  ${item?.start_date}.`,
//                 status: true,
//               };
//               setShowStrip(result);
//               return true;
//             } else if (checkExpired(item)) {
//               result = {
//                 message:
//                   eventType === 'both'
//                     ? 'The sale of tickets for virtual event has ended.'
//                     : 'The sale of tickets for this event has ended.',
//                 status: true,
//               };
//               setShowStrip(result);
//               return true;
//             } else if (Boolean(Number(item?.remaining_tickets) <= 0)) {
//               result = {
//                 message:
//                   eventType === 'both'
//                     ? 'Virtual event tickets for this event are sold out.'
//                     : 'Virtual event tickets for this event are sold out.',
//                 status: true,
//               };
//               setShowStrip(result);
//               return true;
//             }
//           }
//           result = {
//             message: 'Ticket is available',
//             status: false,
//           };
//           setShowStrip(result);
//           return false;
//         });
//       }
//     },
//     [ticketCategoryData?.data, eventType, setShowStrip, venue],
//   );

//   return { isNoTicketsAvailable };
// };

export const checkInpersonTickets = (item, venue) => {
  let assignedSeat = venue && venue?.[0]?.assignedInfo?.assignedSeats;
  let filter = assignedSeat?.filter((data) => data?.categories_id === item._id);

  console.log('filter12', filter, assignedSeat);

  if (filter && filter?.length > 0) {
    if (
      Number(filter?.[0]?.totalSeats) === Number(item?.booked_tickets_count) ||
      (item?.remaining_tickets !== '' &&
        Boolean(Number(item?.remaining_tickets) <= 0))
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const checkExpired = (item) => {
  if (Boolean(Date.parse(moment(item?.end_date)) < Date.parse(moment()))) {
    return true;
  }
};

export const checkInPersonTicketStatus = (ticketData, venue) => {
  const InPersonTicket = ticketData?.data?.filter(
    (item) => item?.event_ticket_category === 'Physical',
  );

  console.log('InPersonTicket', InPersonTicket);

  const isTicketSoldOut = InPersonTicket?.every((ticket) => {
    if (checkInpersonTickets(ticket, venue)) {
      return true;
    } else {
      return false;
    }
  });

  const isTicketExipred = InPersonTicket?.every((ticket) => {
    if (checkExpired(ticket)) {
      return true;
    } else {
      return false;
    }
  });

  return { isTicketExipred, isTicketSoldOut };
};

export const checkVirtualTicketStatus = (ticketData, venue) => {
  const VirtualTicket = ticketData?.data?.filter(
    (item) => item?.event_ticket_category === 'Virtual',
  );

  const isTicketSoldOut = VirtualTicket?.every((ticket) => {
    if (Boolean(Number(ticket?.remaining_tickets) <= 0)) {
      return true;
    } else {
      return false;
    }
  });

  const isTicketExipred = VirtualTicket?.every((ticket) => {
    if (checkExpired(ticket)) {
      return true;
    } else {
      return false;
    }
  });

  return { isTicketExipred, isTicketSoldOut };
};

export const checksaleStart = (ticketData) => {
  const isTicketSoldStarted = ticketData?.data?.every((ticket) => {
    if (
      Boolean(Date.parse(moment(ticket?.start_date)) > Date.parse(moment()))
    ) {
      return true;
    }
    return false;
  });

  return { isTicketSoldStarted };
};

export const getTicketStatus = (ticketData, eventType, venue) => {
  if (checksaleStart(ticketData)?.isTicketSoldStarted) {
    return {
      saleNotStarted: checksaleStart(ticketData),
    };
  } else if (eventType === 'Physical') {
    return {
      inPerson: checkInPersonTicketStatus(ticketData, venue),
    };
  } else if (eventType === 'Virtual') {
    return {
      virtual: checkVirtualTicketStatus(ticketData),
    };
  } else if (eventType === 'both') {
    return {
      inPerson: checkInPersonTicketStatus(ticketData, venue),
      virtual: checkVirtualTicketStatus(ticketData),
    };
  }
};

export const setStatusForInPerson = (Ticketstatus) => {
  if (Ticketstatus?.inPerson?.isTicketSoldOut) {
    return {
      result: true,
      message: eventStatusMessage?.inPersonTicketSold,
    };
  } else if (
    Ticketstatus?.inPerson?.isTicketExipred &&
    Ticketstatus?.inPerson?.isTicketSoldOut === false
  ) {
    return {
      result: true,
      message: eventStatusMessage?.inPersonTicketEnded,
    };
  } else {
    return { result: false };
  }
};

export const setStatusForboth = (Ticketstatus) => {
  // console.log('Ticketstatus', Ticketstatus);
  if (
    Ticketstatus?.inPerson?.isTicketSoldOut &&
    Ticketstatus?.virtual?.isTicketSoldOut
  ) {
    return {
      result: true,
      message: eventStatusMessage?.bothTicketSoldOut,
    };
  } else if (
    Ticketstatus?.inPerson?.isTicketExipred &&
    Ticketstatus?.virtual?.isTicketExipred
  ) {
    return {
      result: true,
      message: eventStatusMessage?.bothTicketEnded,
    };
  } else if (
    setStatusForInPerson(Ticketstatus)?.result &&
    setStatusVirtual(Ticketstatus)?.result
  ) {
    return {
      result: true,
      message: `${
        setStatusForInPerson(Ticketstatus)?.message +
        setStatusVirtual(Ticketstatus)?.message
      }`,
    };
  } else if (setStatusForInPerson(Ticketstatus)?.result) {
    return {
      result: true,
      message: setStatusForInPerson(Ticketstatus)?.message,
    };
  } else if (setStatusVirtual(Ticketstatus)?.result) {
    return {
      result: true,
      message: setStatusVirtual(Ticketstatus)?.message,
    };
  } else {
    return { result: false };
  }
};

export const setStatusVirtual = (Ticketstatus) => {
  if (Ticketstatus?.virtual?.isTicketSoldOut) {
    return {
      result: true,
      message: eventStatusMessage?.virtualTicketSold,
    };
  } else if (
    Ticketstatus?.virtual?.isTicketExipred &&
    !Ticketstatus?.virtual?.isTicketSoldOut
  ) {
    return {
      result: true,
      message: eventStatusMessage?.virtualTicketEnded,
    };
  } else {
    return { result: false };
  }
};
