import React, { useCallback, useMemo } from 'react';
import Tooltip from '../../Tooltip';
import { DATEFORMAT, truncate } from '../../../../utils/util';
import moment from 'moment';
import { AiOutlineHome } from 'react-icons/ai';
import {
  CalenderIcon2,
  InPersonIcon2,
  VirtualIcon2,
} from '../../../util/CustomIcons';
import {
  MdArrowBackIosNew,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdOutlineStadium,
} from 'react-icons/md';
import CartDrawer from '../../../landing_page_v2/header_compo/cart_drawer/CartDrawer';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import { RiHome4Line } from 'react-icons/ri';
import { BsGrid } from 'react-icons/bs';
import EventImageVideo from '../../EventImageVideo';
import { btnSecondayClass } from '../../DefaultClasses';
// import { useEventCallback } from '@mui/material';

export default function SeatBookingEventDetailStrip({
  eventAllDetails,
  darkTheme,
  // type = 'live',
  categorySlider,
  setCategorySlider,
  setOpenmodal,
  setBackToHome,
  loader,
  slug,
  isLoading,
}) {
  const PaymentDetails =
    JSON.parse(localStorage.getItem('PurchasedTickets')) || {};
  const navigate = useNavigate();
  const selectedSeats = useSelector((state) => state.selectedSeats);

  const backToEvent = useCallback(() => {
    // localStorage.removeItem('isPageReloaded');
    if (selectedSeats?.data?.length > 0) {
      setOpenmodal(true);
    } else {
      navigate(`/event-detail/${slug}`);
    }
  }, [navigate, selectedSeats?.data?.length, setOpenmodal, slug]);

  const backToHome = useCallback(() => {
    // localStorage.removeItem('isPageReloaded');
    if (selectedSeats?.data?.length > 0) {
      setBackToHome(true);
    } else {
      navigate('/', { state: { redirected: true } });
    }
  }, [navigate, selectedSeats?.data?.length, setBackToHome]);

  const profile_img = useMemo(() => {
    if (eventAllDetails?.data?.profile_image?.url) {
      return eventAllDetails?.data?.profile_image;
    }
    return eventAllDetails?.data?.profile_image?.[0];
  }, [eventAllDetails?.data?.profile_image]);

  return (
    <>
      <section
        className={`flex justify-center ${
          isLoading ? 'dark:bg-color-base-1 ' : ''
        }`}
      >
        <div className="w-full py-1 bg-color-base-2 dark:bg-color-base-2 rounded-lg border-level-2 border-[1px]">
          <div className="container-fluid">
            <div className="flex justify-between gap-2.5 items-center relative">
              {isLoading ? (
                <>
                  <div className="">
                    <Skeleton.Input
                      className="w-24 h-20  p-0 rounded-lg"
                      active
                      style={{ width: '6rem', height: '100%' }}
                    />
                  </div>
                  <Skeleton
                    active={true}
                    className="py-[4px] rounded-lg"
                    paragraph={{
                      rows: 1,
                    }}
                  />
                </>
              ) : (
                <div className="flex flex-col lg:flex-row items-center gap-2 w-full relative">
                  <div className="flex dark:bg-landingPurple-60 bg-sky-600 justify-stretch absolute h-[33px] lg:h-[72px] top-[5px] lg:top-[-5px] left-[0px] lg:left-[-12px] px-2.5 rounded-full lg:rounded-s-lg lg:rounded-e-none">
                    <Tooltip title={`Back to event`}>
                      <button
                        className="text-whiteNew-0"
                        onClick={backToEvent}
                        disabled={loader ? true : false}
                      >
                        <MdArrowBackIosNew className="" />
                      </button>
                    </Tooltip>
                  </div>
                  <div className="flex items-start lg:items-center flex-row lg:flex-col gap-3 w-full ps-[0px] lg:ps-[32px] mt-[55px] lg:mt-[0px]">
                    <div className="w-16 h-[48px] lg:w-16 lg:h-[48px] bg-slate-300 rounded-md overflow-hidden shrink-0">
                      <EventImageVideo
                        data={profile_img}
                        alt={'Event Picture'}
                        className={
                          'flex-none bg-neutral-800 object-cover h-full w-full'
                        }
                        loading="lazy"
                      />
                      {/* {checkFileType(eventAllDetails?.data?.banner_image) ===
                      'video' ? (
                        <Video
                          className="w-full h-full object-cover"
                          src={eventAllDetails?.data?.banner_image?.url}
                          removeControls={true}
                        />
                      ) : (
                        <Image
                          src={eventAllDetails?.data?.banner_image?.url}
                          alt=""
                          className="flex-none bg-neutral-800 object-cover h-full w-full"
                          loading="lazy"
                        />
                      )} */}
                    </div>

                    <div className="w-full ">
                      <div className="min-w-0 space-y-1 font-semibold w-full">
                        <div>
                          <span
                            className={`text-zinc-800 dark:text-slate-50 text-lg lg:text-xl font-medium`}
                          >
                            {eventAllDetails?.data?.name}
                          </span>
                        </div>
                        <div className="flex flex-col lg:flex-row gap-1 lg:gap-2">
                          <div className="flex gap-2 items-center font-normal">
                            <div>
                              <span className="text-zinc-600 dark:text-gray-300">
                                <CalenderIcon2 />
                              </span>
                            </div>
                            <div>
                              <h6 className="text-zinc-600 dark:text-gray-300 leading-tight">
                                {moment(
                                  eventAllDetails?.data?.start_date_time,
                                ).format(DATEFORMAT)}
                              </h6>
                            </div>
                          </div>
                          <span className="dark:bg-zinc-700 bg-zinc-200 mx-1.5 h-5 w-[1px] hidden lg:flex"></span>
                          <div className="flex gap-2 items-center font-normal">
                            <div>
                              <span className="text-zinc-600 dark:text-gray-300">
                                <MdOutlineStadium />
                              </span>
                            </div>
                            <div>
                              <h6 className="text-zinc-600 dark:text-gray-300 leading-tight">
                                {
                                  <>
                                    {
                                      eventAllDetails?.data?.venue[0]?.name?.split(
                                        '-',
                                      )[0]
                                    }
                                    ,{' '}
                                    {PaymentDetails?.eventType !== 'Virtual'
                                      ? truncate(
                                          eventAllDetails?.data?.venue[0]?.location
                                            ?.split(',')[2]
                                            ?.trim() === 'US' ||
                                            eventAllDetails?.data?.venue[0]?.location
                                              ?.split(',')[2]
                                              ?.trim() === 'USA'
                                            ? `${
                                                eventAllDetails?.data?.venue[0]?.location?.split(
                                                  ',',
                                                )[0]
                                              }, ${
                                                eventAllDetails?.data?.venue[0]?.location?.split(
                                                  ',',
                                                )[1]
                                              }`
                                            : eventAllDetails?.data?.venue[0]
                                                ?.location || 'N/A',
                                          40,
                                        )
                                      : 'Virtual Event'}
                                  </>
                                }
                              </h6>
                            </div>
                          </div>
                          <span className="dark:bg-zinc-700 bg-zinc-200 mx-1.5 h-5 w-[1px] hidden lg:flex"></span>
                          <div className="flex gap-2 items-center">
                            <Tooltip
                              placement={'topRight'}
                              title={
                                PaymentDetails?.event_type === 'both'
                                  ? 'In-Person & Virtual Event'
                                  : PaymentDetails?.event_type === 'Virtual'
                                  ? 'Virtual'
                                  : 'In-Person'
                              }
                              // getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            >
                              {
                                <>
                                  {PaymentDetails?.event_type === 'both' ? (
                                    <>
                                      <div className="flex items-center text-zinc-600 dark:text-whiteNew-0 gap-1.5 bg-brown-700 px-1 rounded-[5px]">
                                        <div className="flex items-center gap-1 leading-tight">
                                          <span>
                                            <InPersonIcon2
                                              className={'text-xl'}
                                            />
                                          </span>
                                          <span className="text-xs font-normal">
                                            In Person
                                          </span>
                                        </div>
                                        <div className="flex items-center">
                                          <span className="flex h-[12px] w-[1px] bg-whiteNew-0"></span>
                                        </div>
                                        <div className="flex items-center gap-1 leading-tight">
                                          <span>
                                            <VirtualIcon2
                                              className={'text-xs'}
                                            />
                                          </span>
                                          <span className="text-xs font-normal">
                                            Virtual
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  ) : PaymentDetails?.event_type ===
                                    'Virtual' ? (
                                    <div className="flex items-center text-zinc-600 dark:text-whiteNew-0 gap-1.5 bg-lightTheme-80 dark:bg-brown-700 px-1 rounded-[5px]">
                                      <div className="flex items-center gap-1 leading-tight">
                                        <span>
                                          <VirtualIcon2 className={'text-xs'} />
                                        </span>
                                        <span className="text-xs font-normal">
                                          Virtual
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="flex items-center text-zinc-600 dark:text-whiteNew-0 gap-1.5 bg-lightTheme-80 dark:bg-brown-700 px-1 rounded-[5px]">
                                      <div className="flex items-center gap-1 leading-tight">
                                        <span>
                                          <InPersonIcon2
                                            className={'text-xl'}
                                          />
                                        </span>
                                        <span className="text-xs font-normal">
                                          In Person
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </>
                              }
                            </Tooltip>
                          </div>
                        </div>
                        {/* <span className="textMuted dark:text-slate-400 text-sm truncate font-normal w-auto max-w-md overflow-hidden">
                    {PaymentDetails?.eventType !== 'Virtual'
                      ? PaymentDetails?.eventType === 'both'
                        ? 'Virtual & In-Person'
                        : 'In-Person'
                      : ''}
                  </span> */}{' '}
                        <div className="w-full hidden lg:flex justify-between items-center mt-2">
                          {/* <div className="flex items-center gap-1">
                            <button
                              className="border-1 min-w-[80px] text-xs border-gray-300 dark:border-zinc-700 rounded-[5px] flex items-center justify-center gap-2 py-0.5 px-2 bg-zinc-100 hover:bg-whiteNew-0 hover:bg-opacity-80 dark:bg-brown-800 dark:hover:bg-zinc-900"
                              onClick={backToEvent}
                              disabled={loader ? true : false}
                            >
                              <MdArrowBackIosNew className="" />
                              Back to Event
                            </button>
                            <button
                              className="border-1 min-w-[80px] text-xs border-gray-300 dark:border-zinc-700 rounded-[5px] flex items-center justify-center gap-2 py-0.5 px-2 text-zinc-800 dark:text-whiteNew-0 dark:hover:bg-zinc-900 hover:bg-whiteNew-0 hover:bg-opacity-80 bg-zinc-100 dark:bg-brown-800"
                              onClick={backToHome}
                              disabled={loader ? true : false}
                            >
                              <AiOutlineHome className="" />
                              Go to Home
                            </button>
                          </div> */}
                          <div className="hidden">
                            {!loader && (
                              <div>
                                <button
                                  // className="min-w-[80px] text-xs  rounded-[5px] flex items-center justify-center gap-2 py-0.5 px-2 dark:text-whiteNew-0 text-zinc-800 dark:hover:text-landingPurple-60 hover:text-sky-600"
                                  className={`${btnSecondayClass}`}
                                  onClick={() => {
                                    setCategorySlider(!categorySlider);
                                  }}
                                  disabled={loader ? true : false}
                                >
                                  {/* <AiOutlineHome className="" /> */}
                                  {categorySlider
                                    ? 'Hide'
                                    : 'Show'} Category{' '}
                                  {categorySlider ? (
                                    <MdKeyboardArrowDown className="text-lg -ml-1" />
                                  ) : (
                                    <MdKeyboardArrowRight className="text-lg -ml-1" />
                                  )}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex-wrap gap-3.5 lg:gap-1 justify-between items-center mt-1.5 lg:mt-3 hidden">
                    <div className="flex items-center gap-1">
                      <button
                        className="border-1 min-w-[80px] text-xs border-gray-300 rounded-[5px] flex items-center justify-center gap-2 py-1.5 lg:py-0.5 px-2 bg-gray-100"
                        onClick={backToEvent}
                        disabled={loader ? true : false}
                      >
                        <MdArrowBackIosNew className="" />
                        Back to Event
                      </button>
                      <button
                        className="border-1 min-w-[80px] text-xs border-gray-300 rounded-[5px] flex items-center justify-center gap-2 py-1.5 lg:py-0.5 px-2 text-zinc-800"
                        onClick={backToHome}
                        disabled={loader ? true : false}
                      >
                        <AiOutlineHome className="" />
                        Go to Home
                      </button>
                    </div>
                    {!loader && (
                      <div>
                        <button
                          className="min-w-[80px] text-xs rounded-[5px] items-center justify-center gap-2 py-0.5 px-2 text-whiteNew-0 hover:text-landingPurple-60 hidden"
                          onClick={() => {
                            setCategorySlider(!categorySlider);
                          }}
                          disabled={loader ? true : false}
                        >
                          {categorySlider ? 'Hide' : 'Show'} Category{' '}
                          {categorySlider ? (
                            <MdKeyboardArrowDown className="text-lg -ml-1" />
                          ) : (
                            <MdKeyboardArrowRight className="text-lg -ml-1" />
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                  {!loader && (
                    <div className="absolute top-[5px] lg:top-4 right-0 lg:right-2 flex flex-row gap-2 items-center">
                      <div>
                        <button
                          className="text-[15px] min-w-[160px] border-1 border-component-gray-1 whitespace-nowrap transition duration-200 text-md leading-md font-medium bg-component-gray-1 dark:bg-component-gray-1 hover:bg-component-gray-3 dark:hover:bg-component-gray-2 p-1.5 rounded-[5px] flex items-center justify-center gap-2 dark:text-whiteNew-0"
                          onClick={() => {
                            setCategorySlider(!categorySlider);
                          }}
                          disabled={loader ? true : false}
                        >
                          {/* <AiOutlineHome className="" /> */}
                          <BsGrid />
                          <span className="text-[13px]">
                            {categorySlider ? 'Hide' : 'Show'} Category{' '}
                          </span>
                          {categorySlider ? (
                            <MdKeyboardArrowDown className="text-lg -ml-1" />
                          ) : (
                            <MdKeyboardArrowRight className="text-lg -ml-1" />
                          )}
                        </button>
                      </div>
                      <span className="dark:bg-zinc-700 bg-zinc-200 mx-2 h-6 w-[1px] hidden lg:flex"></span>

                      <Tooltip title={`Home`}>
                        <button
                          className={`${btnSecondayClass} p-2 min-w-[0px]`}
                          onClick={backToHome}
                          disabled={loader ? true : false}
                        >
                          <RiHome4Line />
                        </button>
                      </Tooltip>
                      <CartDrawer
                        darkTheme={darkTheme}
                        bg={`dark:!bg-zinc-700 !bg-zinc-200`}
                      />
                    </div>
                  )}
                </div>
              )}

              {/* <div>
                <Tooltip title={'Back to Home Page'} placement={'bottom'}>
                  <button
                    className="border-1 hpx-40 wpx-40 border-landingPurple-60 hover:border-landingPurple-70 rounded-full flex items-center justify-center gap-2 p-0 text-lg bg-landingPurple-60 hover:bg-landingPurple-70 duration-150 ease-linear"
                    onClick={() => {
                      // setBackToHome(true);
                    }}
                  >
                    <AiOutlineHome className="text-whiteNew-0" />
                  </button>
                </Tooltip>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
