import React from 'react';
import {
  LogoGoTix,
  StreamingPlayIconCircle,
  profileUrl,
} from '../../components/util/CustomIcons';
import { useStreamLounge } from './hooks/useStreamLounge';
import { Col, Row } from 'react-bootstrap';
import Image from '../../components/common/Image';
import useDecodedToken from '../../hooks/useDecodedToken';
// import useDecodedToken from '../../hooks/useDecodedToken';

const BackgroundImage = ({ fromExit = false, user }) => {
  // const decodedToken = useDecodedToken();
  const { event_details } = useStreamLounge();
  const { banner_image } = event_details;
  // const { username } = decodedToken;
  const { imageUrl } = user;
  const decodedToken = useDecodedToken();
  return (
    <>
      <div
        className="absolute h-full z-0 w-full bg-center bg-cover bg-no-repeat bg-neutral-950"
        style={{
          backgroundImage: `url(${banner_image?.url ? banner_image?.url : ''})`,
        }}
      >
        {/* <div className="absolute inset-0 w-full h-full">
          <Image src={banner_image?.url} className={'w-full h-full'} />
        </div> */}
        <div>
          <div className="absolute z-0 h-full w-full flex items-center justify-center">
            <span className="">
              <StreamingPlayIconCircle />
            </span>
          </div>
          <div className="bg-neutral-900 absolute inset-0 bg-opacity-70 w-full h-full"></div>
        </div>
      </div>
      {!fromExit ? (
        <>
          <header className="relative py-3.5 lg:py-7 z-5">
            <div className="container mx-auto px-7">
              <div className="">
                <Row>
                  <Col md={12}>
                    <div className="flex justify-between gap-2">
                      <div className="flex-shrink-0 relative h-12 w-28 sm:w-36 xl:w-56">
                        <LogoGoTix
                          className={
                            'w-full text-white absolute inset-0 h-full'
                          }
                        />
                      </div>
                      {decodedToken ? (
                        <div className="text-white">
                          <div className="">
                            <Image
                              alt="profile"
                              src={imageUrl ?? profileUrl}
                              className="usr-dp object-cover"
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </header>
        </>
      ) : null}
    </>
  );
};

export default BackgroundImage;
