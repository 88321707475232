import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode, Pagination } from 'swiper';
import TourImageCard from './TourImageCard';
import { Skeleton } from '../util/Skeleton';
import { updateDataCountByViewport } from '../../utils/util';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

// SwiperCore.use([EffectFade, FreeMode, Pagination, Navigation, Autoplay]);
export default function TourBannerSwiper({ images, isLoading }) {
  // const navigationPrevRef = React.useRef(null);
  // const navigationNextRef = React.useRef(null);

  return (
    <React.Fragment>
      <div className="relative px-0 min-h-[13rem]">
        <Swiper
          slidesPerView={'auto'}
          className="banner_swiper -mb-3 thumbSwiper mobHidePagination"
          loop={images?.length > updateDataCountByViewport() ? true : false}
          autoplay={{
            delay: 2980,
            disableOnInteraction: false,
          }}
          // simulateTouch={false}
          freeMode={true}
          // pagination={true}
          pagination={{
            clickable: true,
          }}
          // lazy={true}
          touchStartPreventDefault={false}
          modules={[Autoplay, FreeMode, Pagination]}
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            576: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            767: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 6,
              spaceBetween: 40,
            },

            1400: {
              slidesPerView: 7,
              spaceBetween: 40,
            },
            1920: {
              slidesPerView: 7,
              spaceBetween: 40,
            },
          }}
        >
          {images?.length > 0 ? (
            <>
              {images?.map((item, index) => (
                <SwiperSlide key={index} className="pb-3.5">
                  <div className="relative h-full mt-6">
                    <TourImageCard data={item} />
                  </div>
                </SwiperSlide>
              ))}
            </>
          ) : (
            // isLoading && (
            <>
              {[...Array(10)].map((_, idx) => (
                <SwiperSlide className="relative" key={idx}>
                  <div className="relative mt-6 h-full w-full tra">
                    <div className="cardOverlay"></div>
                    <div className="absolute inset-0 w-full h-full overflow-hidden">
                      <Skeleton
                        className={
                          'w-full h-full absolute inset-0 rounded-lg overflow-hidden'
                        }
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </>
            // )
          )}
        </Swiper>
        {/*<div className="md:flex justify-content-end align-items-center gap-2">
          <div className="absolute left-0 lg:-left-3 top-1/2 z-10 -translate-y-1/2">
            <button
              ref={navigationPrevRef}
              className="relative w-8 h-8 rounded-full bg-landingPurple-60 disabled:bg-neutral-700 text-gray-50 disabled:opacity-30"
            >
              <MdKeyboardArrowLeft className="text-md md:text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
            </button>
          </div>
          <div className="absolute right-0 lg:-right-3 top-1/2 z-10 -translate-y-1/2">
            <button
              ref={navigationNextRef}
              className="relative w-8 h-8 rounded-full bg-landingPurple-60 disabled:bg-neutral-700 text-gray-50 disabled:opacity-30"
            >
              <MdKeyboardArrowRight className="text-md md:text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
            </button>
          </div>
                      </div>*/}
      </div>
    </React.Fragment>
  );
}
