import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sample_artist: [],
  user_artist: [],
  selected_artist: {},
  all_artist_list: {},
  all_artist_page: 1,
  all_artist_limit: 6,
};

const slice = createSlice({
  name: 'artists',
  initialState,
  reducers: {
    updateSampleArtists: (state, { payload }) => {
      state.sample_artist = payload;
    },
    updateUserArtists: (state, { payload }) => {
      state.user_artist = payload;
    },
    update_selected_artist: (state, { payload }) => {
      state.selected_artist = payload;
    },
    setAllArtistPage: (state, { payload }) => {
      state.all_artist_page = payload
    },
    setAllArtistLimit: (state, { payload }) => {
      state.all_artist_limit = payload
    },
    setAllArtistList: (state, { payload }) => {
      state.all_artist_list = payload
    }
  },
});

let artistReducer = slice.reducer;

export const {
  updateSampleArtists,
  updateUserArtists,
  update_selected_artist, setAllArtistPage, setAllArtistLimit, setAllArtistList
} = slice.actions;
const getAllArtists = (state) => state.artists;

export { artistReducer, getAllArtists };
