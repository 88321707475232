import React from 'react';
import DashboardTopInfo from '../../components/dashboard_compo_v2/DashboardTopInfo';

export default function DashboardTopInfoWrapper() {
  return (
    <React.Fragment>
      <div className="">
        <section className="">
          <div className="w-full">
            <div>
              <DashboardTopInfo />
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
