import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data:
    JSON.parse(localStorage.getItem('selectedSeatData')) !== undefined &&
    JSON.parse(localStorage.getItem('selectedSeatData')) !== null
      ? JSON.parse(localStorage.getItem('selectedSeatData'))
      : [],
  virtualDataCounter:
    JSON.parse(localStorage.getItem('selectedVirtualTickets')) !== undefined &&
    JSON.parse(localStorage.getItem('selectedVirtualTickets')) !== null
      ? JSON.parse(localStorage.getItem('selectedVirtualTickets'))
          ?.virtualDataCounter
      : {},
  virtualDataID:
    JSON.parse(localStorage.getItem('selectedVirtualTickets')) !== undefined &&
    JSON.parse(localStorage.getItem('selectedVirtualTickets')) !== null
      ? JSON.parse(localStorage.getItem('selectedVirtualTickets'))
          ?.virtualDataID
      : {},
  virtualDataResaleCounter:
    JSON.parse(localStorage.getItem('selectedVirtualTickets')) !== undefined &&
    JSON.parse(localStorage.getItem('selectedVirtualTickets')) !== null
      ? JSON.parse(localStorage.getItem('selectedVirtualTickets'))
          ?.virtualDataResaleCounter
      : {},
  virtualData:
    JSON.parse(localStorage.getItem('selectedVirtualTickets')) !== undefined &&
    JSON.parse(localStorage.getItem('selectedVirtualTickets')) !== null
      ? JSON.parse(localStorage.getItem('selectedVirtualTickets'))?.virtualData
      : [],
  ticketType:
    JSON.parse(localStorage.getItem('selectedVirtualTickets')) !== undefined &&
    JSON.parse(localStorage.getItem('selectedVirtualTickets')) !== null
      ? JSON.parse(localStorage.getItem('selectedVirtualTickets'))?.ticketType
      : '',
};

const slice = createSlice({
  name: 'SelectedSeats',
  initialState,
  reducers: {
    AddSeats: (state, { payload }) => {
      state.data.push(payload);
    },
    AddTotalSeats: (state, { payload }) => {
      state.data = payload;
    },
    AddTicketType: (state, { payload }) => {
      state.ticketType = payload;
    },
    DeleteSeats: (state, { payload }) => {
      state.data = payload;
      state.allData = payload;
      // state.count = payload.dataCount;
      // state.items = payload.data;
      return;
    },
    DeleteVirtualSeats: (state, { payload }) => {
      state.virtualData = payload;
      // state.count = payload.dataCount;
      // state.items = payload.data;
    },
    DeleteAllSeats: (state, { payload }) => {
      state.data = payload || [];
      state.allData = payload || [];
      state.virtualDataCounter = {};
      state.ticketType = '';
      state.virtualData = [];
      state.virtualDataID = {};
      state.virtualDataResaleCounter = {};
    },
    AddVirtualSeats: (state, { payload }) => {
      state.virtualDataCounter = payload;
    },
    AddVirtualSeatsID: (state, { payload }) => {
      state.virtualDataID = payload;
    },
    AddVirtualResaleSeats: (state, { payload }) => {
      state.virtualDataResaleCounter = payload;
    },
    AddVirtualSeatData: (state, { payload }) => {
      state.virtualData = [payload];
    },
    DeleteAllVirtualSeats: (state, { payload }) => {
      state.virtualDataCounter = {};
      state.virtualData = [];
    },
  },
});

let seatmapReducer = slice.reducer;

export const {
  AddSeats,
  AddTotalSeats,
  DeleteSeats,
  DeleteAllSeats,
  DeleteVirtualSeats,
  AddVirtualSeats,
  AddVirtualSeatsID,
  AddVirtualResaleSeats,
  AddVirtualSeatData,
  DeleteAllVirtualSeats,
  AddTicketType,
} = slice.actions;

export default seatmapReducer;
