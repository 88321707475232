import React from 'react';
import { NoDataForndIcon } from '../util/CustomIcons';

export default function NoDataFoundv2({ icon, children }) {
  return (
    <React.Fragment>
      <div className="flex w-full items-center flex-col py-10 space-y-6">
        {icon ? (
          <></>
        ) : (
          <>
            <NoDataForndIcon className={'w-44'} />
          </>
        )}
        <div className="text-2xl text-slate-500">{children}</div>
      </div>
    </React.Fragment>
  );
}
