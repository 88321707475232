import React from 'react';
import { Link } from 'react-router-dom';

export function TermsLink({ className }) {
  return (
    <>
      <Link
        to={`/terms-of-use`}
        target="_blank"
        className={`${
          className ? className : 'text-sky-600'
        } font-medium hover:underline`}
      >
        Terms of Service
      </Link>
    </>
  );
}

export default function PrivacyLink({ className }) {
  return (
    <>
      <Link
        to="/privacy-policy"
        target="_blank"
        className={`${
          className ? className : 'text-sky-600'
        } font-medium hover:underline`}
      >
        Privacy Policy
      </Link>
    </>
  );
}

export function TermsConditionLink({ className, message }) {
  return (
    <>
      <Link
        to={`/terms-of-use`}
        target="_blank"
        className={`${
          className ? className : 'text-sky-600'
        } font-medium hover:underline`}
      >
        {` ${message} `}
      </Link>
    </>
  );
}
