import React from 'react';
import Modal from '../Modal';
import { AiOutlineFieldTime } from 'react-icons/ai';
import { btnPrimaryClass, btnSecondayClass } from '../DefaultClasses';
import { Col, Row } from 'react-bootstrap';
import TwoMinuteTimer from '../../../helpers/twoMinuteTimer';

function LogoutAlert({
  sessionExpiredModal,
  setSessionExpiredModal,
  timer,
  closeIntervalModal,
  logout,
  setTimer,
  isLoading,
}) {
  return (
    <Modal
      isOpen={sessionExpiredModal}
      setIsOpen={() => {
        setSessionExpiredModal(false);
        setTimeout(() => {
          setTimer(false);
        }, 2000);
      }}
      maxWidth={'max-w-lg'}
      zIndex={'9999999'}
    >
      <div className="mt-2.5">
        <Row className="items-center">
          <Col xs={12} md={12}>
            <div className="w-full flex justify-center">
              <span className="text-7xl text-yellow-500">
                {' '}
                <AiOutlineFieldTime />
              </span>
            </div>

            <div className="flex justify-center text-center px-3.5 mt-3.5">
              <div>
                <div className={'text-color-primary text-base font-light'}>
                  {timer ? (
                    'Your session has expired'
                  ) : (
                    <TwoMinuteTimer
                      closeIntervalModal={closeIntervalModal}
                      setTimer={setTimer}
                      logout={logout}
                    />
                  )}
                </div>
              </div>
            </div>
            {timer ? (
              <div className="flex justify-end mt-4 gap-2.5">
                <button
                  onClick={() => {
                    setSessionExpiredModal(false);
                    setTimeout(() => {
                      setTimer(false);
                    }, 2000);
                  }}
                  type="button"
                  className={`${btnSecondayClass} w-full`}
                >
                  OK
                </button>
              </div>
            ) : (
              <div className="flex justify-end mt-4 gap-2.5">
                <button
                  onClick={() => {
                    setSessionExpiredModal(false);
                    logout();
                  }}
                  type="button"
                  className={`${btnSecondayClass} w-full`}
                  disabled={isLoading}
                >
                  Logout
                </button>

                <button
                  onClick={() => {
                    setSessionExpiredModal(false);
                  }}
                  className={`${btnPrimaryClass} w-full`}
                >
                  Yes Continue!
                </button>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default React.memo(LogoutAlert);
