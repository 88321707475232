import { useMutation, useQuery } from '@tanstack/react-query';
import {
  followUnfollowArtist,
  getArtistID,
  getArtistListByUserID,
  getArtistType,
  getSampleArtistList,
} from '../../../helpers/api/event/artist/artist';
import useDecodedToken, { useApiHeader } from '../../useDecodedToken';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateArtistFollower } from '../../../utils/util';
import { addEvents } from '../../../features/home_page/homepage.slice';
import { updateAllArtist } from '../../../features/event/allartist.slice';

export function useArtists() {}

export function useSampleArtistList(page, key, limit) {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['SampleArtist', page],
    queryFn: async () => await getSampleArtistList(page, key, limit),
    // keepPreviousData: true,
    // suspense: true,
  });

  return { data, refetch, isLoading };
}

export function useArtistListByUser(page, key, limit, event_id) {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['ArtistListByUser', page, limit, event_id],
    queryFn: async () => {
      if (event_id === undefined || event_id === null) {
        return [];
      } else {
        return await getArtistListByUserID(page, key, limit, event_id);
      }
    },
    // keepPreviousData: true,
    // suspense: true,
  });

  return { data, refetch, isLoading };
}

export function useArtistType(artist) {
  console.log('artist', artist);
  const { data, refetch } = useQuery({
    queryKey: ['ArtistType', artist],
    queryFn: async () => await getArtistType(artist),
    // keepPreviousData: true,
    // suspense: true,
  });

  return { data, refetch };
}

export function useArtistByID(id) {
  const { data, refetch } = useQuery({
    queryKey: ['ArtistByID', id],
    queryFn: async () => {
      if (id !== undefined && id !== null && id !== '') {
        await getArtistID(id);
      } else {
        return null;
      }
    },
    keepPreviousData: true,
    // suspense: true,
  });

  return { data, refetch };
}

export const useArtistFollow = (artistData, from, refetch = () => {}) => {
  // API to follow and unFollow the Artist.
  let { json_header } = useApiHeader();
  let decodedToken = useDecodedToken();
  let PublishedEvents = useSelector((state) => state?.publishedEvents);
  const dispatch = useDispatch();
  const { artist } = PublishedEvents;

  const handleSave = (data) => {
    let { method, artistID } = data;

    if (method === 'follow') {
      let payload = {
        follower_id: artistID,
      };
      return followUnfollowArtist(payload, json_header);
    } else {
      let payload = {
        unfollow_id: artistID,
      };
      return followUnfollowArtist(payload, json_header);
    }
  };

  // Involke the update function

  const FollowMutation = useMutation(handleSave, {
    onSuccess: (res, data) => {
      let { method, artistID } = data;
      if (from === 'home') {
        dispatch(
          addEvents({
            ...PublishedEvents,
            artist: {
              data: updateArtistFollower(
                artist?.data,
                artistID,
                decodedToken?.id,
                method,
              ),
              totalCount: artist?.count,
            },
          }),
        );
      } else if (from === 'allArtist') {
        dispatch(
          updateAllArtist({
            array: updateArtistFollower(
              artistData,
              artistID,
              decodedToken?.id,
              method,
            ),
            // count: count,
          }),
        );
      } else {
        refetch();
      }
      // if (method === 'follow') {
      //     message.success('Followed Successfully.');
      // } else {
      //   message.success('UnFollowed Successfully.');
      // }
    },
    onError: () => {
      message.error('ERROR while saving');
    },
  });

  return { FollowMutation };
};
