import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tabIndex: 0,
  pagination: { page: 1, limit: 10 },
  history: { data: [], count: 0 }

};

const slice = createSlice({
  name: 'logs_history',
  initialState,
  reducers: {
    setTabIndex: (state, { payload }) => {
      state.tabIndex = payload
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload
    },
    setHistory: (state, { payload }) => {
      state.history = payload
    }
  },
});

let historyReducer = slice.reducer;

export const {
  setTabIndex, setPagination, setHistory
} = slice.actions;

export {
  historyReducer,
};
