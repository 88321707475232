import React from 'react';
import { useSelector } from 'react-redux';
import { useModalActions } from './hooks/usePaymentOptions';
import { AddBillingInfoForm } from './CheckoutPaymentOptionsSubmenu';
import useDecodedToken from '../../hooks/useDecodedToken';
import { ConfigProvider, Form, Input } from 'antd';
import { formClass } from '../common/DefaultClasses';
import { Link, useLocation } from 'react-router-dom';
import { EMAIL_REG } from '../../utils/util';
import { useCheckoutActions } from '../../pages/checkout/hooks/useCheckout';

function CheckoutAccountInfo({ data }) {
  const { data: userdata } = useSelector((s) => s.userDetails);
  const { modalState, handleCloseBillingModal } = useModalActions();
  const { PaymentDetails } = useCheckoutActions();
  const decodedToken = useDecodedToken();
  // const { checkout_billing_summary } = useSelector((s) => s.checkout);
  const { pathname, search } = useLocation();
  // const billing_info = useMemo(() => {
  //   if (decodedToken) {
  //     return checkout_billing_summary?.billing_address?.is_default &&
  //       Object.keys(checkout_billing_summary?.billing_address)?.length
  //       ? checkout_billing_summary?.billing_address
  //       : userdata?.data?.billing_info
  //       ? userdata?.data?.billing_info
  //       : false;
  //   }
  // }, [checkout_billing_summary, userdata, decodedToken]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Poppins',
        },
      }}
    >
      <div className="w-full bg-color-base-3 dark:bg-color-base-2 border-[1px] border-level-4 rounded-lg overflow-hidden">
        {/* To Do for Guest User */}
        {!decodedToken ? (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between p-2.5 items-center border-b-[1px] border-level-4 bg-color-base-2 dark:bg-color-base-3">
              <h2 className="text-color-secondary text-lg md:text-xl font-medium">
                Contact
              </h2>
              <div className="text-color-secondary text-xs md:text-base">
                Have an account?{' '}
                <Link
                  state={{ pathname, search }}
                  to={`/user/login`}
                  className="text-sky-600 dark:text-landingPurple-60"
                  onClick={() => {
                    localStorage.setItem('GuestUser', JSON.stringify(true));
                  }}
                >
                  Log in
                </Link>
              </div>
            </div>
            <div className="flex flex-col p-3.5">
              <Form.Item
                name="email"
                rules={[
                  {
                    pattern: EMAIL_REG,
                    message: 'Please provide valid email.',
                  },
                  { required: true, message: 'Please enter email.' },
                  ({ getFieldValue }) => ({
                    validator: (_, value) => {
                      if (!value || value !== PaymentDetails?.organiser_email) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'Tickets cannot be purchased from email used for creating events.',
                        ),
                      );
                    },
                  }),
                ]}
                className="mb-0"
              >
                <Input
                  type="email"
                  className={`${formClass} `}
                  placeholder="Email or mobile phone number"
                />
              </Form.Item>
            </div>
          </div>
        ) : (
          <div className="flex-col">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between p-2.5 items-center border-b-[1px] border-level-4 bg-color-base-2 dark:bg-color-base-2">
                <h2 className="text-color-primary text-lg md:text-xl font-medium">
                  Account
                </h2>
                <div className="text-white text-base">
                  {/* <a className="text-landingPurple-60 flex flex-row items-center gap-2">
                    Log out <MdNavigateNext className="text-lg" />
                  </a> */}
                </div>
              </div>
              <div className="flex flex-col p-3.5 bg-color-base-1">
                <span className="text-base text-gray-400 dark:bg-neutral-950 bg-[#e6e4e4] border-neutral-300 py-1.5 px-2.5 rounded-lg">
                  {userdata?.data?.account_name ||
                    userdata?.data?.username ||
                    userdata?.data?.fullname ||
                    '-'}
                </span>
              </div>
            </div>

            {/* <div className="flex flex-col mt-3">
              <div className="flex flex-row justify-between mb-2">
                <h2 className="text-gray-400 text-2xl">Bill to</h2>
                <div className="text-white text-base">
                  <span
                    onClick={handleOpenBillingModal}
                    className="text-landingPurple-60 hover:text-landingPurple-70 cursor-pointer flex flex-row items-center gap-2"
                  >
                    {userdata?.data?.billing_info
                      ? '+ Use a different address'
                      : '+ Add new address'}
                    <MdNavigateNext className="text-lg" />
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-base capitalize text-gray-400">
                  {billing_info
                    ? `${
                        billing_info?.address_nick_name ??
                        String(billing_info?.first_name) +
                          ' ' +
                          String(billing_info?.last_name) ??
                        ''
                      } - ${billing_info?.street_address ?? ''} ${
                        billing_info?.town ?? ''
                      }, ${billing_info?.state ?? ''}, ${
                        billing_info?.postcode ?? ''
                      } ${billing_info?.country ?? ''}`
                    : 'No billing address found.'}
                </span>
              </div>
            </div> */}
          </div>
        )}
      </div>
      <AddBillingInfoForm
        isOpen={modalState.billing_modal}
        setIsOpen={handleCloseBillingModal}
      />
    </ConfigProvider>
  );
}

export default CheckoutAccountInfo;
