import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Form } from 'react-bootstrap';
import { forget_password, resend_email } from '../../../helpers/api/auth/auth';
import { history } from '../../../helpers/router_templates/history';
import useSearchQuery from '../../../hooks/useSearchQuery';
import OTPTimer from '../../../utils/components/OTPTimer';
import { EmailIcon } from '../../util/CustomIcons';

export default function ConfirmationEmail({ darkTheme }) {
  const query = useSearchQuery();
  const mutation = useMutation(insertNote);

  function insertNote() {
    if (String(history.location.pathname).includes('reset-password')) {
      return forget_password({
        email: query.get('email'),
      });
    }
    return resend_email({
      email: query.get('email'),
    });
  }
  const onSubmit = (data) => {
    if (query.get('email')) {
      mutation.mutate();
    }
  };

  return (
    <React.Fragment>
      <div className="flex justify-center items-center flex-col text-center">
        <div className="mb-3">
          <EmailIcon className={'w-20 h-20 lg:w-28 lg:h-28 textSuccess'} />
        </div>
        <h1 className="fw-medium text-lg 2xl:text-xl dark:text-purple-500 text-sky-600">
          Check Your Email
        </h1>

        <p className="dark:text-gray-300 text-zinc-800 mt-3">
          {mutation.isLoading ? (
            <i className="fas text-center fa-circle-notch fa-spin text-2xl"></i>
          ) : mutation.isError ? (
            <Form.Text className="block fw-bold text-center mb-2 text-danger">
              {mutation.error.message
                ? mutation.error.message +
                  ` (${mutation.error.response?.data?.message})`
                : mutation.error}
            </Form.Text>
          ) : mutation?.data ? (
            <Form.Text className="block fw-bold text-center mb-2 text-success">
              {mutation?.data?.message}
            </Form.Text>
          ) : history.location.pathname === '/auth/email-confiramtion' ? (
            <>
              Email confirmation link has been sent on your register email.
              <br />
              Please verify to login.
            </>
          ) : (
            `Reset link has been sent on your register email.`
          )}
        </p>
      </div>
      <div className="space-y-4 max-w-screen-lg w-full py-3">
        <div className="mt-4">
          <div className="flex justify-center items-center flex-col gap-4 mt-4">
            {/* <Link
              to={'/user/login'}
              // className="btn flex items-center justify-center btnPrimary rounded-xl w-full"
              className={`${
                darkTheme
                  ? 'bg-landingPurple-60 text-gray-50 hover:bg-landingPurple-70'
                  : 'bg-sky-600 text-gray-50 hover:bg-sky-700'
              } p-2.5 rounded-lg duration-200 ease-in w-full flex items-center justify-center md:w-2/3`}
            >
              Let's start
            </Link> */}
            <OTPTimer
              minutes={1}
              seconds={30}
              text="Resend Email Link in"
              ButtonText="Resend Email"
              background="none"
              textColor="unset"
              buttonColor="#cc8411"
              className="text-dark"
              isError={mutation.isError}
              resend={() => {
                onSubmit();
              }}
              darkTheme={darkTheme}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <p className="text-xs text-gray-400">
          <i>
            In case the email is not recieved in the Inbox, please check the
            Spam folder.
          </i>
        </p>
      </div>
    </React.Fragment>
  );
}
