import React from 'react';
import SeatSelectionBoxV2 from './SeatSelectionBoxV2';

export default function SeatSelectionPage() {
  // const [displayLoader, setdisplayLoader] = React.useState(true);

  // useOnceCall(() => {
  //     const timeout = setTimeout(() => {
  //         setdisplayLoader(false);
  //     }, 6000);
  //     return () => {
  //         clearTimeout(timeout);
  //     };
  // }, []);

  // if (displayLoader) {
  //     return <PageDarkLoader />;
  // } else {
  return <SeatSelectionBoxV2 />;
  // }
}
