import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getEventLists,
  getEventDetails,
  getEventReportDetails,
  getEventAttendiDetails,
  getVirtualEventAttendiDetails,
  getEventReportDetailsByOrganizer,
  getAllEventAttendiDetails,
} from '../../helpers/api/event/event';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllInPersonAttendees,
  getAllVirtualAttendees,
  needRefetchInPersonAttendee,
  needRefetchVirtualAttendee,
  updateInPersonAttendees,
  updateVirtualAttendees,
  updateselectedTickets,
} from '../../features/event/event_attendees.slice';
import { checkPageLimit } from '../../utils/util';
import useSWR from 'swr';
import { useApiHeader } from '../useDecodedToken';
import { updateUserDetails } from '../../helpers/api/user/user_details';
import { AddUserDetail } from '../../features/user_detail.slice';
import { message } from 'antd';
import { useCallback } from 'react';
import { getTourList } from '../../helpers/api/event/Tour/tour';
import { updateTourLists } from '../../features/event/event.slice';

export function useEventList(page, headers, dispatch) {
  const EventList = useQuery({
    queryKey: ['EventList', page],
    queryFn: async () => await getEventLists(page, headers, dispatch),
    // keepPreviousData: true,
  });

  return { EventList };
}

export function useEventDetails(id, headers) {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['EventList', id],
    queryFn: async () => {
      if (id !== undefined && id !== null && id !== '') {
        return await getEventDetails(id, headers);
      } else {
        return null;
      }
    },
    // keepPreviousData: true,
  });
  return { data, refetch, isLoading };
}

export function useGetEventReportData(
  id,
  page,
  pageSize,
  headers,
  setReportData,
) {
  const { data, refetch, isLoading, isFetched, isSuccess } = useQuery({
    queryKey: ['ReportData', id, page, pageSize],
    queryFn: async () => {
      if (id !== undefined && id !== null && id !== '') {
        return await getEventReportDetails(id, page, pageSize, headers);
      } else {
        return null;
      }
    },
    onSuccess: (res) => {
      setReportData(res);
    },
    // keepPreviousData: true,
  });
  return {
    data,
    refetch,
    isLoading,
    isFetched,
    isSuccess,
  };
}
export function useGetEventsReportDataByOrganizer(
  page,
  pageSize,
  eventfilter,
  sorting,
  headers,
  setReportData,
) {
  const { data, refetch, isLoading, isFetched, isSuccess } = useQuery({
    queryKey: ['ReportData'],
    queryFn: async () => {
      return await getEventReportDetailsByOrganizer(
        page,
        pageSize,
        eventfilter,
        sorting,
        headers,
      );
    },
    onSuccess: (res) => {
      setReportData(res);
    },
    // keepPreviousData: true,
  });
  return {
    data,
    refetch,
    isLoading,
    isFetched,
    isSuccess,
  };
}

export function useGetEventAttendeeData(
  eventData,
  id,
  page,
  limit,
  tab,
  subTab,
  headers,
) {
  // let InPersonAttendees = useSelector(getAllInPersonAttendees);
  const selectedEvent = useSelector((state) => state.eventList);
  const showSummary = selectedEvent.Showsummary;
  let dispatch = useDispatch();

  const fetchInPersonEventAttendees = async () => {
    let key = `page=${page}limit=${limit}`;
    // if (InPersonAttendees?.event_id !== id) {
    //   if (id !== undefined && id !== null && id !== '') {
    //     return await getEventAttendiDetails(id, page, limit, headers);
    //   } else {
    //     return null;
    //   }
    // } else {
    if (
      showSummary &&
      tab === 2 &&
      (eventData?.data?.event_type === 'Physical' ||
        (eventData?.data?.event_type === 'both' &&
          (subTab === 0 || subTab === 1)))
    ) {
      if (id !== undefined && id !== null && id !== '') {
        return await getEventAttendiDetails(id, page, limit, headers);
      } else {
        return null;
      }
    } else {
      return null;
    }
    // }
  };

  const InPersonEventAttendees = useSWR(
    () =>
      showSummary &&
      tab === 2 &&
      (eventData?.data?.event_type === 'Physical' ||
        (eventData?.data?.event_type === 'both' &&
          (subTab === 0 || subTab === 1)))
        ? ['InPersonAttendeeData', id, page, limit]
        : null,
    fetchInPersonEventAttendees,
    {
      // refreshInterval: 60 * 1000,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      keepPreviousData: true,
      // onSuccess: (res) => {
      //   if (res?.data !== undefined && res?.data !== null) {
      //     let key = `page=${page}limit=${limit}`;
      //     if (
      //       !checkPageLimit(InPersonAttendees?.data, key) ||
      //       InPersonAttendees?.needRefetch ||
      //       InPersonAttendees?.event_id !== id
      //     ) {
      //       dispatch(
      //         updateInPersonAttendees({
      //           data: {
      //             ...InPersonAttendees?.data,
      //             [key]: res?.data,
      //           },
      //           dataCount: res?.countData,
      //           event_id: id,
      //         }),
      //       );
      //     }
      //     InPersonAttendees?.needRefetch &&
      //       dispatch(needRefetchInPersonAttendee(false));
      //   } else {
      //     return null;
      //   }
      // },
      // onSuccess(data) {

      // },
    },
  );

  return {
    InPersonEventAttendees,
  };
}

// export function useGetEventAttendeeData(id, page, headers) {
//   const { data, refetch, isLoading, isFetched, isSuccess } = useQuery({
//     queryKey: ['AttendeeData', id, page],
//     queryFn: async () => {
//       if (id !== undefined && id !== null && id !== '') {
//         return await getEventAttendiDetails(id, page, headers);
//       } else {
//         return null;
//       }
//     },
//     // keepPreviousData: true,
//   });

//   return {
//     data,
//     refetch,
//     isLoading,
//     isFetched,
//     isSuccess,
//   };
// }

export function useGetVirtualEventAttendee(
  eventData,
  id,
  page,
  limit,
  tab,
  subTab,
  headers,
) {
  // let VirtualAttendees = useSelector(getAllVirtualAttendees);
  const selectedEvent = useSelector((state) => state.eventList);
  const showSummary = selectedEvent.Showsummary;
  let dispatch = useDispatch();

  const fetchVirtualEventAttendees = async () => {
    // let key = `page=${page}limit=${limit}`;
    // if (VirtualAttendees?.event_id !== id) {
    //   if (id !== undefined && id !== null && id !== '') {
    //     return await getVirtualEventAttendiDetails(id, page, limit, headers);
    //   } else {
    //     return null;
    //   }
    // } else {
    if (
      showSummary &&
      tab === 2 &&
      (eventData?.data?.event_type === 'Virtual' ||
        (eventData?.data?.event_type === 'both' &&
          (subTab === 1 || subTab === 2)))
    ) {
      if (id !== undefined && id !== null && id !== '') {
        return await getVirtualEventAttendiDetails(id, page, limit, headers);
      } else {
        return null;
      }
    } else {
      return null;
    }
    // }
  };

  const VirtualEventAttendees = useSWR(
    () =>
      showSummary &&
      tab === 2 &&
      (eventData?.data?.event_type === 'Virtual' ||
        (eventData?.data?.event_type === 'both' &&
          (subTab === 1 || subTab === 2)))
        ? ['VirtualAttendeeData', id, page, limit]
        : null,
    fetchVirtualEventAttendees,
    {
      // refreshInterval: 60 * 1000,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      keepPreviousData: true,
      // onSuccess: (res) => {
      //   if (VirtualAttendees?.needRefetch) {
      //     res.data?.map((item) => {
      //       if (item?._id === VirtualAttendees?.selected_tickets?._id) {
      //         dispatch(updateselectedTickets(item));
      //       }
      //     });
      //   }
      //   if (res?.data !== undefined && res?.data !== null) {
      //     let key = `page=${page}limit=${limit}`;
      //     if (
      //       !checkPageLimit(VirtualAttendees?.data, key) ||
      //       VirtualAttendees?.needRefetch ||
      //       VirtualAttendees?.event_id !== id
      //     ) {
      //       dispatch(
      //         updateVirtualAttendees({
      //           data: {
      //             ...VirtualAttendees?.data,
      //             [key]: res?.data,
      //           },
      //           dataCount: res?.countData,
      //           event_id: id,
      //         }),
      //       );
      //     }
      //     VirtualAttendees?.needRefetch &&
      //       dispatch(needRefetchVirtualAttendee(false));
      //   } else {
      //     return null;
      //   }
      // },
      // onSuccess(data) {

      // },
    },
  );

  return {
    VirtualEventAttendees,
  };
}

export function useGetAllEventAttendee(
  eventData,
  id,
  page,
  limit,
  tab,
  subTab,
  headers,
  AllTab,
) {
  // let VirtualAttendees = useSelector(getAllVirtualAttendees);
  const selectedEvent = useSelector((state) => state.eventList);
  const showSummary = selectedEvent.Showsummary;

  const fetchVirtualEventAttendees = async () => {
    // let key = `page=${page}limit=${limit}`;
    // if (VirtualAttendees?.event_id !== id) {
    //   if (id !== undefined && id !== null && id !== '') {
    //     return await getVirtualEventAttendiDetails(id, page, limit, headers);
    //   } else {
    //     return null;
    //   }
    // } else {
    if (
      showSummary &&
      tab === 2 &&
      eventData?.data?.event_type === 'both' &&
      subTab === 0 &&
      AllTab
    ) {
      if (id !== undefined && id !== null && id !== '') {
        return await getAllEventAttendiDetails(id, page, limit, headers);
      } else {
        return null;
      }
    } else {
      return null;
    }
    // }
  };

  const AllEventAttendees = useSWR(
    () =>
      showSummary &&
      tab === 2 &&
      eventData?.data?.event_type === 'both' &&
      subTab === 0 &&
      AllTab
        ? ['AllAttendeeData', id, page, limit]
        : null,
    fetchVirtualEventAttendees,
    {
      // refreshInterval: 60 * 1000,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      keepPreviousData: true,
      // onSuccess: (res) => {
      //   if (VirtualAttendees?.needRefetch) {
      //     res.data?.map((item) => {
      //       if (item?._id === VirtualAttendees?.selected_tickets?._id) {
      //         dispatch(updateselectedTickets(item));
      //       }
      //     });
      //   }
      //   if (res?.data !== undefined && res?.data !== null) {
      //     let key = `page=${page}limit=${limit}`;
      //     if (
      //       !checkPageLimit(VirtualAttendees?.data, key) ||
      //       VirtualAttendees?.needRefetch ||
      //       VirtualAttendees?.event_id !== id
      //     ) {
      //       dispatch(
      //         updateVirtualAttendees({
      //           data: {
      //             ...VirtualAttendees?.data,
      //             [key]: res?.data,
      //           },
      //           dataCount: res?.countData,
      //           event_id: id,
      //         }),
      //       );
      //     }
      //     VirtualAttendees?.needRefetch &&
      //       dispatch(needRefetchVirtualAttendee(false));
      //   } else {
      //     return null;
      //   }
      // },
      // onSuccess(data) {

      // },
    },
  );

  return {
    AllEventAttendees,
  };
}

export const useGetallTourList = () => {
  const dispatch = useDispatch();
  const { json_header } = useApiHeader();

  const tour_list_mutation = useMutation(
    ({ page = 1, limit = 10 }) => {
      return getTourList(page, limit, json_header);
    },
    {
      onSuccess: (data) => {
        // console.log('Tourdata', data);
        dispatch(updateTourLists(data?.currentTourData));
      },
    },
  );

  return { tour_list_mutation };
};

export const useEventFavSave = (selectedEventID, refetch) => {
  // Update user Details function used to save the event.

  const userDetails = useSelector((state) => state.userDetails);
  let details = userDetails?.data?.data;
  let { form_header } = useApiHeader();
  let dispatch = useDispatch();

  const handleSave = (from) => {
    const formData = new FormData();

    if (from === 'favourite') {
      if (details?.favourite_event?.some((e) => e === selectedEventID)) {
        formData.append('remove_favourite_event', selectedEventID);
        return updateUserDetails(formData, form_header);
      } else {
        formData.append('favourite_event', selectedEventID);
        return updateUserDetails(formData, form_header);
      }
    } else {
      if (details?.saved_event?.some((e) => e === selectedEventID)) {
        formData.append('unsaved_event', selectedEventID);
        return updateUserDetails(formData, form_header);
      } else {
        formData.append('event', selectedEventID);
        return updateUserDetails(formData, form_header);
      }
    }
  };

  // Involke the update function

  const saveMutation = useMutation(handleSave, {
    onSuccess: (datas, from) => {
      // setDetails(datas?.data);

      dispatch(AddUserDetail({ data: datas }));

      if (from === 'favourite') {
        if (datas?.data?.favourite_event?.some((e) => e === selectedEventID)) {
          message.success('Favourited Successfully');
        } else {
          message.success('Removed Successfully');
        }
      } else {
        if (datas?.data?.saved_event?.some((e) => e === selectedEventID)) {
          message.success('Saved Successfully');
        } else {
          message.success('Removed Successfully');
        }
      }
    },
    onError: () => {
      message.error('ERROR while saving');
    },
  });

  const makeItFavourite = useCallback(() => {
    saveMutation.mutate('favourite');
  }, []);

  const saveEvent = useCallback(() => {
    saveMutation.mutate();
  }, []);

  return { makeItFavourite, saveEvent };
};
