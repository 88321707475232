import { useMutation } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { EmailIcon, LinkBroken } from '../../util/CustomIcons';
import { Link } from 'react-router-dom';
import { useOnceCall } from '../../../hooks/useOnlyOnce';
import { guest_nft_claim_verification } from '../../../helpers/api/auth/auth';
import { isExpired } from 'react-jwt';

export default function NFTMailVerification({ darkTheme }) {
  const query = useSearchQuery();
  const mutation = useMutation(() =>
    guest_nft_claim_verification({ token: query.get('token') }),
  );

  const onSubmit = (data) => {
    mutation.mutate();
  };

  const isTokenExpired = useMemo(() => {
    const token = query.get('token');

    return isExpired(token);
  }, [query]);

  useOnceCall(() => onSubmit());

  return (
    <React.Fragment>
      <div className="flex justify-center items-center flex-col text-center">
        <div className="mb-3">
          {mutation?.isSuccess ? (
            <EmailIcon className={'w-20 h-20 lg:w-28 lg:h-28 textSuccess'} />
          ) : mutation?.isError ? (
            <LinkBroken className={'w-20 h-20 lg:w-28 lg:h-28 textSuccess'} />
          ) : null}
        </div>
        <h1 className="fw-medium text-lg 2xl:text-xl text-purple-500">
          {mutation?.isSuccess
            ? 'Email is verfied successfully'
            : mutation?.isError
            ? 'Unexcepted Error occurred'
            : 'Authenticating'}
        </h1>

        <p className="text-gray-300 mt-3">
          {mutation.isLoading ? (
            <i className="fas text-center fa-circle-notch fa-spin text-2xl"></i>
          ) : (
            mutation.isError && (
              <Form.Text className="block fw-bold text-center mb-2 text-danger">
                {mutation.error.message
                  ? mutation.error.message +
                    ` (${mutation.error.response?.data?.message})`
                  : mutation.error}
              </Form.Text>
            )
          )}
        </p>
      </div>
      {!isTokenExpired ? (
        <>
          <div className="space-y-4 max-w-screen-lg w-full py-3">
            <div className="mt-4">
              <div className="flex justify-center items-center flex-col gap-4 mt-4">
                <span className="text-gray-300">
                  Please set the password to secure your account.
                </span>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <Link
              to={`/auth/set-password?token=${query.get('token')}`}
              className="bg-neutral-700 bg-opacity-50 text-gray-50 hover:bg-neutral-700 hover:bg-opacity-70 duration-200 ease-in p-2.5 rounded-lg flex justify-center items-center"
            >
              Set Password
            </Link>
          </div>
        </>
      ) : null}
    </React.Fragment>
  );
}
