import React from 'react';
import { MdEventNote } from 'react-icons/md';

export default function NoDataFound({ title, icon, minHeight }) {
  return (
    <React.Fragment>
      <div
        className={`${
          minHeight ? minHeight : 'min-h-[200px] py-6'
        } flex justify-center items-center`}
      >
        <div className="flex flex-col items-center">
          <div>
            <span className="text-2xl text-gray-400">
              {icon ? (
                <>{icon}</>
              ) : (
                <>
                  <MdEventNote />
                </>
              )}
            </span>
          </div>
          <div className="text-center mt-2">
            <span className="text-[15px] text-gray-400">
              {title ? title : 'No Data Found'}{' '}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
