import axios from 'axios';
import config from '../../../api/Config';

export async function getTrendingEvents() {
  try {
    const res = axios.get(config.baseUrl + config.endpoints.eventSearcher, {
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    return res;
  } catch (err) {
    return null;
  }
}
