import React from 'react';
import { Navigate } from 'react-router-dom';
import useDecodedToken from '../../hooks/useDecodedToken';

function ProtectRoute({ children }) {
  let pathname = String(window.location.pathname);
  // const { state } = useLocation()
  const decodedToken = useDecodedToken();
  let splitpath = pathname.split('/');

  if (splitpath[3] === 'email-confirmation') {
    pathname = '/' + splitpath[1] + '/' + splitpath[2] + '/' + splitpath[3];
  }
  const slug = [
    '/user/login/reset/password',
    '/user/login/password/forgot',
    '/user/signup/email-confirmation',
    '/user/signup',
    'error',
    '/user/login',
    '/user/wallet-login',
    'user/signup/email-confirmation',
  ];
  console.log('slug.includes(pathname)', slug.includes(pathname));
  return decodedToken && slug.includes(pathname) ? (
    <>
      <Navigate to="/" />
    </>
  ) : (
    children
  );
}

export default ProtectRoute;
