import React, { useState } from 'react';
import SidebarAccordion from '../../../components/SearchCompo/SidebarAccordion';
import { Slider, Form as AntForm, Input } from 'antd';
import {
  afterBorder,
  afterBorderTop2,
  darkFormInput,
  formClass,
} from '../../../components/common/DefaultClasses';
import { BiFilterAlt } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import CheckboxGroupV2 from '../../../components/SearchCompo/CheckboxGroupV2';
// import { FILTER_CONST } from '../../../features/event/allevent.slice';
import { Col, Row } from 'react-bootstrap';
import FilterTabButton from '../../../components/landing_page_v2/mobile_filter/FilterTabButton';
import DrawerCommon from '../../../components/common/DrawerCommon';

export default function FilterLeftSidebarV2({
  filterParams,
  mobileFilter,
  filter_options,
  handlePriceSlider,
  filterActions = {},
  open,
  onClose,
  FILTER_CONST,
}) {
  const filter_labels = Object.keys(filter_options);
  // console.log('filter_labels', filter_labels);
  const filter_values = Object.values(filter_options);
  const [selectedIndex, setSelectedIndex] = useState(0);
  // const [itemsToShow, setItemsToShow] = useState(30); // Initial number of items to show
  const {
    handleClearFilter,
    handleResetFilter,
    handleApplyFilter,
    isFilterTouched,
    callback,
    disableApply,
    AntdForm: form,
  } = filterActions;

  return (
    <>
      <React.Fragment>
        <div
          className={`${
            mobileFilter
              ? ''
              : 'bg-color-base-2 dark:bg-color-base-4 border-1 border-level-4'
          }  relative flex flex-col justify-between h-full w-full rounded-lg`}
        >
          <div className="lg:max-h-[65vh] relative h-full dark-sidebar flex flex-col z-10">
            {!mobileFilter && (
              <div
                className={`relative ${afterBorder} p-2.5 flex justify-between`}
              >
                <div className="dark:text-whiteNew-0 text-zinc-800 text-lg flex items-center">
                  <div className="flex gap-1 items-center">
                    <BiFilterAlt />
                    <span className="">Filter</span>
                  </div>
                </div>
                {isFilterTouched ? (
                  <div className="text-xs">
                    <span
                      onClick={() => {
                        handleClearFilter();
                      }}
                      className="text-red-500 text-base py-1 cursor-pointer flex flex-row items-center gap-1 leading-tight"
                    >
                      <MdDelete />
                      Clear
                    </span>
                  </div>
                ) : null}
              </div>
            )}

            <div
              className={`${
                mobileFilter ? 'h-full' : 'lg:max-h-[90vh] xl:max-h-[90vh]'
              } overflow-y-auto overflow-x-hidden scrollCss divide-y divide-level-2`}
            >
              {filter_values?.map((options, index) => {
                if (typeof options === 'object' && options?.type === 'slider') {
                  return (
                    <div key={filter_labels[index]} className="py-3.5">
                      <SidebarAccordion
                        title={'Price'}
                        // opendis={openPrice}
                        // setOpenDis={setOpenPrice}
                      >
                        <div className="w-full">
                          <AntForm
                            initialValues={{
                              slider: [0, 0],
                              min: undefined,
                              max: undefined,
                            }}
                            form={form}
                          >
                            <AntForm.Item
                              dependencies={['min', 'max']}
                              name="slider"
                            >
                              <Slider
                                onChangeComplete={(e) => {
                                  handlePriceSlider(form, 'slider', e);
                                  form.setFieldValue('min', e[0]);
                                  form.setFieldValue('max', e[1]);
                                }}
                                range
                                // onChange={(e) =>
                                //   handlePriceSlider(form, 'slider', e)
                                // }
                                min={0}
                                max={5000}
                                // onChange={(value) => onChange(value)}
                                // railStyle={{ backgroundColor: '#9b9b9b' }}
                                // trackStyle={{ backgroundColor: '#923BFF' }}
                                // handleStyle={{ backgroundColor: '#923BFF' }}
                                className="filter-range-slider"
                              />
                            </AntForm.Item>

                            <div>
                              <div className="w-full flex  justify-center gap-2">
                                <div className="w-full">
                                  <div className="relative">
                                    <AntForm.Item
                                      name="min"
                                      type="number"
                                      placeholder="Min"
                                      dependencies={['max', 'slider']}
                                      // value={inputValuePrice[0]}
                                      rules={[
                                        ({ getFieldValue }) => ({
                                          validator(_, value) {
                                            if (
                                              !value ||
                                              !Number(getFieldValue('max')) ||
                                              Number(getFieldValue('max')) >
                                                Number(value)
                                            ) {
                                              return Promise.resolve();
                                            }
                                            return Promise.reject(
                                              new Error(
                                                'Min. value should be less than max value',
                                              ),
                                            );
                                          },
                                        }),
                                      ]}
                                    >
                                      <Input
                                        type="number"
                                        onChange={(e) => {
                                          handlePriceSlider(
                                            form,
                                            'min',
                                            e.target.value,
                                          );
                                          form.setFieldValue('slider', [
                                            e.target.value,
                                            form.getFieldValue('max'),
                                          ]);
                                        }}
                                        placeholder="Min"
                                        className={`${formClass} rounded-lg h-auto text-sm`}
                                      />
                                    </AntForm.Item>
                                  </div>
                                </div>
                                <div className="w-full">
                                  <AntForm.Item
                                    name="max"
                                    type="number"
                                    placeholder="Max"
                                    dependencies={['min', 'slider']}
                                    // value={inputValuePrice[1]}
                                    rules={[
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (
                                            !value ||
                                            !Number(getFieldValue('min')) ||
                                            Number(getFieldValue('min')) <
                                              Number(value)
                                          ) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(
                                            new Error(
                                              'Max. value should be greater than min value',
                                            ),
                                          );
                                        },
                                      }),
                                    ]}
                                  >
                                    <Input
                                      type="number"
                                      onChange={(e) => {
                                        handlePriceSlider(
                                          form,
                                          'max',
                                          e.target.value,
                                        );
                                        form.setFieldValue('slider', [
                                          form.getFieldValue('min'),
                                          e.target.value,
                                        ]);
                                      }}
                                      placeholder="Max"
                                      className={`${formClass} rounded-lg h-auto text-sm`}
                                    />
                                  </AntForm.Item>
                                </div>
                              </div>
                            </div>
                          </AntForm>
                        </div>
                      </SidebarAccordion>
                    </div>
                  );
                }
                return (
                  <div key={filter_labels[index]} className="py-3.5">
                    <SidebarAccordion
                      title={
                        filter_labels[index] === 'Artist List'
                          ? 'Artists/Teams List'
                          : filter_labels[index]
                      }
                      // opendis={openEventType}
                      // setOpenDis={setOpenEventType}
                    >
                      <CheckboxGroupV2
                        items={options}
                        selectedValues={
                          filterParams[FILTER_CONST[filter_labels[index]]]
                        }
                        extra_action={{
                          isFilterTouched,
                          callback,
                          disableApply,
                        }}
                        // itemsToShow={itemsToShow}
                        // setItemsToShow={setItemsToShow}
                        // selected={selectedEventType}
                        // setSelected={setSelectedEventType}
                        // type="eventtype"
                      />
                    </SidebarAccordion>
                  </div>
                );
              })}
              {/* 
              <div className="py-3.5">
                <SidebarAccordion title={'Sub Categories'}>
                  <CheckboxGroup
                    items={subcategories}
                    selected={selectedSubCategory}
                    setSelected={setSelectedSubCategory}
                    type={'subcategory'}
                  />
                </SidebarAccordion>
              </div> */}
            </div>
          </div>
          {!mobileFilter && isFilterTouched && (
            <div
              className={`space-y-4 p-3.5 relative ${afterBorderTop2} after:bg-level-2`}
            >
              <div className="w-full flex justify-between gap-3">
                <div className="w-full">
                  <button
                    onClick={() => {
                      handleResetFilter();
                    }}
                    className="bg-neutral-700 bg-opacity-50 text-gray-50 hover:bg-neutral-700 hover:bg-opacity-70 duration-200 ease-in shadow-lg shadow-gray-200 p-2 rounded-lg flex justify-center items-center w-full"
                    // onClick={onReset}
                  >
                    Reset
                  </button>
                </div>
                <div className="w-full">
                  <button
                    disabled={disableApply}
                    className="dark:bg-landingPurple-60 bg-sky-600 text-gray-50 hover:bg-landingPurple-70 p-2 rounded-lg duration-200 ease-in w-full disabled:cursor-not-allowed disabled:opacity-50"
                    onClick={handleApplyFilter}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <DrawerCommon
          title={
            <>
              <div className="flex justify-between items-center gap-2">
                <span>Filter</span>
                {isFilterTouched ? (
                  <div className="text-xs mr-6">
                    <span
                      onClick={() => {
                        form.setFieldsValue({
                          max: undefined,
                          min: undefined,
                          slider: [0, 0],
                        });
                        handleClearFilter();
                      }}
                      className="text-red-500 text-base py-1 cursor-pointer flex flex-row items-center gap-1 leading-tight"
                    >
                      <MdDelete />
                      Clear
                    </span>
                  </div>
                ) : null}
              </div>
            </>
          }
          // placement={isVerticalLayout ? 'bottom' : 'right'}
          placement={'bottom'}
          onClose={onClose}
          height="95%"
          width="480px"
          open={open}
          className={
            'select-city-drawer !bg-neutral-900 backdrop-blur-3xl border-1 border-neutral-800 dark-sidebar'
          }
          padding={'p-0 h-full'}
          footer={
            isFilterTouched ? (
              <>
                <div className="w-full flex justify-end gap-3">
                  <div className="">
                    <button
                      onClick={async () => {
                        form.setFieldsValue({
                          max: undefined,
                          min: undefined,
                          slider: [0, 0],
                        });

                        await handleResetFilter();
                      }}
                      className="bg-neutral-700 bg-opacity-50 text-gray-50 hover:bg-neutral-700 hover:bg-opacity-70 duration-200 ease-in shadow-lg shadow-gray-200 p-2 rounded-lg flex justify-center items-center w-full min-w-[130px]"
                      // onClick={onReset}
                    >
                      Reset
                    </button>
                  </div>
                  <div className="">
                    <button
                      disabled={disableApply}
                      className="bg-landingPurple-60 text-gray-50 hover:bg-landingPurple-70 p-2 rounded-lg duration-200 ease-in w-full disabled:cursor-not-allowed disabled:opacity-50 min-w-[130px]"
                      onClick={async () => {
                        await handleApplyFilter();
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </>
            ) : null
          }
          maskStyle={{ backdropFilter: 'blur(2px)' }}
        >
          <div className="h-full">
            <Row className="divide-x divide-neutral-700 h-full">
              <Col xs={4} md={3} className="bg-neutral-900 sticky top-0">
                <div>
                  {filter_labels?.map((options, index) => {
                    return (
                      <FilterTabButton
                        title={options}
                        index={index}
                        option={filter_values[index]}
                        handleTabChange={setSelectedIndex}
                        active={Boolean(index === selectedIndex)}
                        key={index}
                      />
                    );
                  })}
                </div>
              </Col>
              <Col xs={8} md={9}>
                <div className="h-full">
                  {selectedIndex === 4 ? (
                    <>
                      <div className="py-3.5 pr-2.5">
                        <div className="w-full">
                          <AntForm
                            initialValues={{
                              slider: [0, 0],
                              min: undefined,
                              max: undefined,
                            }}
                            form={form}
                          >
                            <AntForm.Item
                              dependencies={['min', 'max']}
                              name="slider"
                            >
                              <Slider
                                onChangeComplete={(e) => {
                                  handlePriceSlider(form, 'slider', e);
                                  form.setFieldValue('min', e[0]);
                                  form.setFieldValue('max', e[1]);
                                }}
                                range
                                // onChange={(e) =>
                                //   handlePriceSlider(form, 'slider', e)
                                // }
                                min={0}
                                max={5000}
                                tooltip={{
                                  placement: 'topRight',
                                  arrow: false,
                                }}
                                styles={{
                                  rail: { backgroundColor: '#9b9b9b' },
                                  track: { backgroundColor: '#923BFF' },
                                  handle: { backgroundColor: '#923BFF' },
                                }}
                                className="filter-range-slider"
                              />
                            </AntForm.Item>

                            <div>
                              <div className="w-full flex  justify-center gap-2">
                                <div className="w-full">
                                  <div className="relative">
                                    <AntForm.Item
                                      name="min"
                                      type="number"
                                      placeholder="Min"
                                      dependencies={['max', 'slider']}
                                      // value={inputValuePrice[0]}
                                      rules={[
                                        ({ getFieldValue }) => ({
                                          validator(_, value) {
                                            if (
                                              Number(getFieldValue('max')) >
                                              Number(value)
                                            ) {
                                              return Promise.resolve();
                                            }
                                            return Promise.reject(
                                              new Error(
                                                'Min. value should be less than max value',
                                              ),
                                            );
                                          },
                                        }),
                                      ]}
                                    >
                                      <Input
                                        type="number"
                                        onChange={(e) => {
                                          handlePriceSlider(
                                            form,
                                            'min',
                                            e.target.value,
                                          );
                                          form.setFieldValue('slider', [
                                            e.target.value,
                                            form.getFieldValue('max'),
                                          ]);
                                        }}
                                        placeholder="Min"
                                        className={`${darkFormInput} rounded-lg h-auto text-sm`}
                                      />
                                    </AntForm.Item>
                                  </div>
                                </div>
                                <div className="w-full">
                                  <AntForm.Item
                                    name="max"
                                    type="number"
                                    placeholder="Max"
                                    dependencies={['min', 'slider']}
                                    // value={inputValuePrice[1]}
                                    rules={[
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (
                                            Number(getFieldValue('min')) <
                                            Number(value)
                                          ) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(
                                            new Error(
                                              'Max. value should be greater than min value',
                                            ),
                                          );
                                        },
                                      }),
                                    ]}
                                  >
                                    <Input
                                      type="number"
                                      onChange={(e) => {
                                        handlePriceSlider(
                                          form,
                                          'max',
                                          e.target.value,
                                        );
                                        form.setFieldValue('slider', [
                                          form.getFieldValue('min'),
                                          e.target.value,
                                        ]);
                                      }}
                                      placeholder="Max"
                                      className={`${darkFormInput} rounded-lg h-auto text-sm`}
                                    />
                                  </AntForm.Item>
                                </div>
                              </div>
                            </div>
                          </AntForm>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="py-2.5 pr-1.5 h-full scrollCss scrollBarThumb overflow-y-auto overflow-x-hidden"
                        style={{ maxHeight: 'calc(100vh - 139px)' }}
                      >
                        <CheckboxGroupV2
                          items={filter_values[selectedIndex]}
                          // selectedValues={
                          //   initialFilterParams[
                          //     FILTER_CONST[filter_labels[selectedIndex]]
                          //   ]
                          // }
                          selectedValues={
                            filterParams[
                              FILTER_CONST[filter_labels[selectedIndex]]
                            ]
                          }
                          extra_action={{
                            isFilterTouched,
                            callback,
                          }}
                          // itemsToShow={itemsToShow}
                          // setItemsToShow={setItemsToShow}
                          // selected={selectedEventType}
                          // setSelected={setSelectedEventType}
                          // type="eventtype"
                        />
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </DrawerCommon>
      </React.Fragment>
    </>
  );
}
