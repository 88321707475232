import { useState } from 'react';

export const usePoliciesModal = (data) => {
  const [displayPoliciesRefund, setDisplayPoliciesRefund] = useState(false);

  const getTicketCancelDisableTime = () => {
    let days = 100;
    let hours = 100;
    data?.data?.ticket_policy
      ?.filter(
        (policy) =>
          policy?.sub_policy_type === 'Refund Policies' &&
          policy?.currently_in_use,
      )[0]
      ?.values?.forEach((data) => {
        if (data?.timeframeSelect === 'Hour') {
          if (hours >= Number(data?.timeframeValue)) {
            hours = Number(data?.timeframeValue);
          }
        } else {
          if (days >= Number(data?.timeframeValue)) {
            days = Number(data?.timeframeValue);
          }
        }
      });

    if (hours === 100 && days !== 100) {
      return `${days} days`;
    } else if (hours !== 100 && days === 100) {
      return `${hours} Hours`;
    } else if (hours !== 100 && days !== 100) {
      return `${hours} Hours`;
    } else if (hours === 100 && days === 100) {
      return '';
    }
  };

  return {
    displayPoliciesRefund,
    setDisplayPoliciesRefund,
    getTicketCancelDisableTime,
  };
};
