import React, { useMemo, useCallback } from 'react';
import Button from '../../../common/Button';
import { CartIcon, CustomLockIcon } from '../../../util/CustomIcons';
import CartDrawerCompo from './CartDrawerCompo';
import DrawerCommon from '../../../common/DrawerCommon';
import {
  btnPrimaryClass,
  primaryBtnClassGradientSec,
} from '../../../common/DefaultClasses';
import {
  CalculateTotal,
  checkOutInperson,
  checkTicketsAvailable,
  getIsVerticalLayout,
  getSelectedTicketPrice,
  handleSaveTicketData,
} from '../../../../utils/util';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { updateCartDrawer } from '../../../../features/home_page/homepage.slice';
import CartTimer from '../CartTimer';
import { updateCheckoutEvent } from '../../../../features/checkout/checkout.slice';
import { useNavigate } from 'react-router';
import useDecodedToken, {
  useApiHeader,
} from '../../../../hooks/useDecodedToken';
import {
  cartData,
  updateVirtualError,
} from '../../../../features/cart/cart.slice';
import { useTrendingEventHooks } from '../../../../pages/landing/home_page/hooks/useHomePageHooks';
import Tooltip from '../../../common/Tooltip';
import { CartCheckOutAlert } from '../../../common/notification_modal/AlertModal';
import { useMutation } from '@tanstack/react-query';
import { getTicketCategory } from '../../../../helpers/api/event/ticket_category/ticket_category';
import { message } from 'antd';
import { useUpdateCartOnMounting } from '../../../common/landing_page_component/check_out/useCartCheckOut';
// import CartTimerCopy from '../CartTimer copy';

function CartDrawer({ darkTheme = false, bg }) {
  const navigate = useNavigate();
  const decodedToken = useDecodedToken();
  const { json_header } = useApiHeader();
  const isVerticalLayout = useMemo(() => getIsVerticalLayout(), []);
  const selectedSeats = useSelector((state) => state.selectedSeats);
  const cartDrawer = useSelector((state) => state.cartDrawer);
  const cartselectedSeats = useSelector(cartData);
  const {
    cartInpersonData,
    cartvirtualData,
    cartDataType,
    selectedEvent,
    slug,
  } = cartselectedSeats;
  // const cartTimer = useSelector((state) => state.cartTimer);
  const {
    virtualDataCounter: counter,
    virtualDataID: catID,
    virtualDataResaleCounter: Resalecounter,
    // virtualData,
    // ticketType,
  } = Object.values(cartvirtualData)?.[0] ||
  Object.entries(cartInpersonData)?.[0] ||
  {};

  // console.log('cartvirtualData', cartvirtualData);

  ///Here checking the cart data when user logout and login again. Dont remove this///
  useUpdateCartOnMounting(); //Dont remove this///

  const page_limit = useSelector((state) => state?.homePageEvents);

  useTrendingEventHooks(page_limit?.page, page_limit?.limit, true);

  const insertNote = () => {
    return getTicketCategory(selectedEvent?._id, 1, 100, json_header);
  };

  const mutation = useMutation(insertNote, {
    onSuccess: (res) => {
      let data = res?.data;
      // console.log('data', data);
      if (checkTicketsAvailable(CalculateTotal(counter, data), data)) {
        // console.log('here');
        dispatch(updateCartDrawer(false));
        dispatch(updateVirtualError(true));
      } else {
        // console.log('available');
        let eventdetails = { data: { data: selectedEvent } };
        let slug = eventdetails?.data?.data?.slug;
        let payload = handleSaveTicketData(
          eventdetails,
          catID,
          Resalecounter,
          counter,
          'Virtual',
        );
        localStorage.setItem('PurchasedTickets', JSON.stringify(payload));
        localStorage.removeItem('notLoggedUser');
        updateEventDetails(eventdetails);
        navigate(`/checkout?slug=${slug}&type=virtual`);
      }
    },
    onError: () => {
      message.error('ERROR while saving');
    },
  });

  const dispatch = useDispatch();

  const showDrawer = useCallback(() => {
    dispatch(updateCartDrawer(true));
  }, [dispatch]);
  const onClose = useCallback(() => {
    dispatch(updateCartDrawer(false));
  }, [dispatch]);

  // const handleTime = useCallback(() => {
  //   dispatch(DeleteAllSeats());
  // }, []);

  const updateEventDetails = useCallback(
    (eventdetails) => {
      localStorage.setItem(
        'checkout_event',
        JSON.stringify(eventdetails.data?.data),
      );
      dispatch(updateCheckoutEvent(eventdetails.data?.data));
      console.log('asasasdas');
      dispatch(updateCartDrawer(false));
    },
    [dispatch],
  );

  const checkoutCart = useCallback(() => {
    if (
      Object.entries(cartInpersonData)?.length === 0 ||
      cartDataType === 'Virtual'
    ) {
      mutation?.mutate();
    } else {
      let eventdetails = { data: { data: selectedEvent } };
      let slug = eventdetails?.data?.data?.slug;
      let payload = checkOutInperson(
        { data: Object.values(cartInpersonData)?.[0] },
        eventdetails?.data,
        decodedToken,
      );

      localStorage.setItem('PurchasedTickets', JSON.stringify(payload));
      localStorage.removeItem('notLoggedUser');
      updateEventDetails(eventdetails);
      navigate(
        `/checkout?slug=${slug}${
          decodedToken?.id ? `&user_id=${decodedToken?.id}` : ''
        }&event_id=${eventdetails?.data?.data?._id}`,
      );
    }
  }, [
    decodedToken,
    cartDataType,
    cartInpersonData,
    mutation,
    navigate,
    selectedEvent,
    updateEventDetails,
  ]);
  return (
    <div>
      <Button
        onClick={showDrawer}
        type="button"
        className={`${
          Object.entries(cartvirtualData)?.length > 0 ||
          Object.entries(cartInpersonData)?.length > 0
            ? 'dark:border-landingPurple-60 border-sky-600 border-1'
            : ''
        } inline-flex items-center justify-center border-1 border-component-gray-1 whitespace-nowrap transition duration-200 text-md leading-md font-medium bg-component-gray-1 dark:bg-component-gray-1 hover:bg-component-gray-3 dark:hover:bg-component-gray-2 gap-1.5 rounded-[6px] disabled:pointer-events-none relative text-neutral-950 dark:text-whiteNew-0 p-[2px] disabled:opacity-80 disabled:text-opacity-70 hover:disabled:bg-inherit divide-x divide-level-2 dark:divide-gray-2`}
        // rotateBtn
      >
        <span className="flex items-center gap-1.5 p-1">
          {/* rotateIcon */}
          <Tooltip title={'Cart'} placement={'bottom'}>
            <CartIcon
              className={`text-lg ${
                Object.entries(cartvirtualData)?.length > 0 ||
                Object.entries(cartInpersonData)?.length > 0
                  ? `${
                      darkTheme
                        ? 'dark:text-landingPurple-60 text-sky-600'
                        : 'dark:text-whiteNew-0'
                    }`
                  : ''
              }`}
            />
          </Tooltip>
        </span>
        {(Object.entries(cartvirtualData)?.length > 0 ||
          Object.entries(cartInpersonData)?.length > 0) && (
          <span className="flex p-1 min-w-[57px] text-[13px] justify-center">
            <CartTimer selectedSeats={selectedSeats} />
            {/* <CartTimerCopy/> */}
          </span>
        )}
      </Button>

      <DrawerCommon
        width={`${
          Object.entries(cartvirtualData)?.length > 0 ||
          Object.entries(cartInpersonData)?.length > 0
            ? '760px'
            : '492px'
        }`}
        title={
          <>
            <div className="flex justify-between">
              <div
                className={`flex dark:text-white text-neutral-950 items-center`}
              >
                <CartIcon className={'text-xl'} />
                <span className="font-medium ml-2 uppercase">Cart</span>
              </div>
              {(Object.entries(cartvirtualData)?.length > 0 ||
                Object.entries(cartInpersonData)?.length > 0) && (
                <span
                  className={`flex px-1.5 min-w-[57px] justify-center text-zinc-800 dark:text-whiteNew-0 border-1 rounded-md`}
                >
                  <CartTimer selectedSeats={selectedSeats} />
                </span>
              )}
            </div>
          </>
        }
        // rootClassName={darkTheme ? '' : 'root-drawer-light'}
        // className={`gotix-drawer`}
        rootClassName={'drawer-root-className '}
        placement={isVerticalLayout ? 'bottom' : 'right'}
        height="95%"
        onClose={onClose}
        open={cartDrawer?.state}
        // className={`dark:bg-transparent select-city-drawer dark:[&_.ant-drawer-header]:bg-neutral-950 [&_.ant-drawer-header]:bg-gray-100 [&_.ant-drawer-wrapper-body]:bg-[#f2f2f2] [&_.ant-drawer-wrapper-body]:dark:bg-[#333333] ${
        //   Object.entries(cartvirtualData)?.length > 0 ||
        //   Object.entries(cartInpersonData)?.length > 0
        //     ? 'cart-drawer'
        //     : ''
        // } ${
        //   darkTheme
        //     ? 'border-neutral-800'
        //     : 'light-drawer !bg-gray-100 border-none'
        // }  backdrop-blur-3xl border-1`}
        className={`gotix-drawer select-city-drawer ${
          Object.entries(cartvirtualData)?.length > 0 ||
          Object.entries(cartInpersonData)?.length > 0
            ? 'cart-drawer'
            : ''
        }  backdrop-blur-3xl`}
        maskStyle={{ backdropFilter: 'blur(2px)' }}
        footer={
          (Object.entries(cartvirtualData)?.length > 0 ||
            Object.entries(cartInpersonData)?.length > 0) && (
            <>
              <div className="flex flex-col relative">
                <>
                  {/* <h3
                    className={`font-semibold my-3.5 ${
                      darkTheme ? 'text-white' : 'text-neutral-950'
                    }`}
                  >
                    Tax Inculded
                  </h3> */}
                  <button
                    className={`${
                      darkTheme ? primaryBtnClassGradientSec : btnPrimaryClass
                    } !text-whiteNew-0 p-[10px] py-[10px] mb-3.5`}
                    onClick={checkoutCart}
                  >
                    <span className="absolute left-3">
                      <CustomLockIcon />
                    </span>
                    Checkout -{' '}
                    <CurrencyFormat
                      disabled={true}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      value={
                        getSelectedTicketPrice(
                          cartvirtualData?.[slug] || {
                            data: Object.values(cartInpersonData)?.[0],
                          },
                        )?.toFixed(2) || 0
                      }
                      decimalScale={2}
                      fixedDecimalScale
                      className={`font-normal`}
                    />
                  </button>
                </>
              </div>
            </>
          )
        }
      >
        <CartDrawerCompo darkTheme={darkTheme} onClose={onClose} />
      </DrawerCommon>
      <CartCheckOutAlert
        message="Tickets you have selected might not be available. Would you like to review the event."
        darkTheme={true}
        slug={slug}
      />
    </div>
  );
}

export default CartDrawer;
