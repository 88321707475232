import { createSlice } from '@reduxjs/toolkit';

export const init_authorize_ticket = { is_authorized: false, ticket_details: {} }

const initialState = {
  event_details: {},
  ticket_data: [],
  authorize_ticket: init_authorize_ticket,
  disclosure_list: [],
  active_session_list: [],
  portal_state: {
    ticket_drawer: false,
    session_drawer: false,
    nft_drawer: false,
    shared_ticket_drawer: false,
    nft_details_drawer: false,
    ticket_design_drawer: false,
    show_qrmodal: false,
  },
  drawer_screen: {
    ticket_screen: false,
    session_screen: false,
    auth_screen: false
  },
  stream_nfts: {
    data: [],
    total_count: 0,
  },
  stream_nft_details: {},
  shared_ticket_list: [],
  scanned_qr_data: {
    is_loading: false,
    is_error: false,
    error: undefined,
    is_success: false,
    data: {},
  },
  is_shared_authorized: false,
  is_qr_ticket_authorized: false,
  show_singleton_session: {
    ticket_id: "",
    data: {}
  },
};

const slice = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    updateStreamEventDetails: (state, { payload }) => {
      state.event_details = payload
    },
    updateStreamTicketDetails: (state, { payload }) => {
      state.ticket_data = payload
    },
    updateTicketAuthorization: (state, { payload }) => {
      state.authorize_ticket = payload
    },
    updateDisclosureList: (state, { payload }) => {
      state.disclosure_list = payload
    },
    updateActiveSessionList: (state, { payload }) => {
      state.active_session_list = payload
    },
    updatePortalState: (state, { payload }) => {
      state.portal_state = payload;
    },
    updateDrawerScreen: (state, { payload }) => {
      state.drawer_screen = payload;
    },
    updateStreamNFTs: (state, { payload }) => {
      // if (Array.isArray(payload)) {
      //   payload.map(({nft_metadata}))
      // }
      state.stream_nfts = payload;
    },
    updateSingleNFTDetail: (state, { payload }) => {
      state.stream_nft_details = payload;
    },
    updateSharedTicketList: (state, { payload }) => {
      state.shared_ticket_list = payload
    },
    updateShareAuthorization: (state, { payload }) => {
      state.is_shared_authorized = payload
    },
    updateQRTicketVerified: (state, { payload }) => {
      state.is_qr_ticket_authorized = payload
    },
    updateQRScannedData: (state, { payload }) => {
      state.scanned_qr_data = payload
    },
    updateShowSingletonSession: (state, { payload }) => {
      state.show_singleton_session = payload
    }
  },
});

let streamReducer = slice.reducer;

export const {
  updateStreamEventDetails,
  updateStreamTicketDetails,
  updateTicketAuthorization,
  updateDisclosureList,
  updateActiveSessionList,
  updatePortalState,
  updateDrawerScreen,
  updateStreamNFTs,
  updateSingleNFTDetail,
  updateShareAuthorization,
  updateQRTicketVerified,
  updateSharedTicketList,
  updateQRScannedData,
  updateShowSingletonSession
} = slice.actions;
const getStreamDetails = (state) => state.stream;

export {
  streamReducer,
  getStreamDetails
};
