import React from 'react';

export default function HeadingTitle({ children, height }) {
  return (
    <React.Fragment>
      <div className="flex items-center gap-3">
        <div>
          <svg
            width="2"
            height={height ? height : '40'}
            viewBox="0 0 2 94"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="heading_bar"
          >
            <line
              x1="1"
              y1="94"
              x2="0.999996"
              y2="4.37114e-08"
              stroke="url(#paint0_linear_4274_1270)"
              strokeWidth="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_4274_1270"
                x1="3.08266"
                y1="-1.15196"
                x2="3.08267"
                y2="90.4289"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopOpacity="0" />
                <stop offset="0.463542" stopColor="white" />
                <stop offset="0.671875" stopColor="white" />
                <stop offset="1" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          {children}
        </div>
      </div>
    </React.Fragment>
  );
}
