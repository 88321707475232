import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import JoinLoungeCard from '../../../components/stream_lounge_components/JoinLoungeCard';
import {
  useConsolidatedTicket,
  useShowWhichCard,
  useStreamLounge,
} from './useStreamLounge';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../features/auth.slice';
import { get_all_active_sessions } from '../../../helpers/api/stream/stream';
import useDecodedToken from '../../../hooks/useDecodedToken';
// import { mutate } from "swr";
import {
  updateDisclosureList,
  updateDrawerScreen,
  updatePortalState,
  updateQRScannedData,
  updateShowSingletonSession,
} from '../../../features/stream_lounge/stream.slice';
import { Form } from 'antd';
import { shortenAddress, sleep } from '../../../utils/util';
import moment from 'moment';
import { useOnceCall } from '../../../hooks/useOnlyOnce';

export function useDrawerScreens(slug = null) {
  const dispatch = useDispatch();
  const decodedToken = useDecodedToken();
  const {
    from_dashboard,
    event_details,
    isObjectEmpty,
    getStreamingDetails,
    refetchOrderData,
    refetchTicketsData,
  } = useStreamLounge(slug);
  const { active_session_list, portal_state, drawer_screen } = useSelector(
    (s) => s.stream,
  );
  const { all_orders } = useSelector((s) => s.orders);
  const { data: orders_data } = all_orders;
  const { shared_ticket_data_all_type, shared_ticket_type } =
    useConsolidatedTicket();
  const { showIFrameDirectly, join_stream_direct } = useShowWhichCard();
  // const { validate_qr_ticket_api, validate_qr_order_api } = useStreamAPI()
  const { ticket_screen, auth_screen, session_screen } = drawer_screen;
  const [form] = Form.useForm();
  const joinIdPasscode = form;
  const [disclosure_list, setDisClosureList] = useState();

  const shared_list = useMemo(
    () =>
      shared_ticket_type.map((type, index) => ({
        id: `disclosure-panel-${index}-shared`,
        isOpen: false,
        lable: type,
        count: shared_ticket_data_all_type[index].length,
        panel: (
          <JoinLoungeCard
            isShared={true}
            selectedTicketTypeData={shared_ticket_data_all_type[index]}
          />
        ),
      })),
    [shared_ticket_data_all_type, shared_ticket_type],
  );

  // ticketid and passcode -> email
  const handleToggleLoginScreen = useCallback(() => {
    dispatch(authActions.resetState());
    joinIdPasscode.resetFields();
    return dispatch(
      updateDrawerScreen({
        ...drawer_screen,
        auth_screen: !drawer_screen.auth_screen,
      }),
    );
  }, [dispatch, drawer_screen, joinIdPasscode]);

  const handleToggleSessionScreen = useCallback(() => {
    joinIdPasscode.resetFields();
    return dispatch(
      updateDrawerScreen({
        ...drawer_screen,
        session_screen: !drawer_screen.session_screen,
      }),
    );
  }, [dispatch, drawer_screen, joinIdPasscode]);

  const handleClick = useCallback(
    (id) => {
      const temp = disclosure_list?.map((d) =>
        d.id === id ? { ...d, isOpen: !d.isOpen } : { ...d, isOpen: false },
      );
      dispatch(updateDisclosureList(temp));
      setDisClosureList((d) =>
        d?.map((d) =>
          d.id === id ? { ...d, isOpen: !d.isOpen } : { ...d, isOpen: false },
        ),
      );
    },
    [disclosure_list, dispatch],
  );

  const handleShareClick = useCallback((id) => {
    setSharedDisClosureList((d) =>
      d?.map((d) =>
        d.id === id ? { ...d, isOpen: !d.isOpen } : { ...d, isOpen: false },
      ),
    );
  }, []);

  const showDrawer = useCallback(() => {
    dispatch(updatePortalState({ ...portal_state, ticket_drawer: true }));
  }, [portal_state, dispatch]);

  const onCloseDrawer = useCallback(() => {
    joinIdPasscode.resetFields();
    dispatch(updatePortalState({ ...portal_state, ticket_drawer: false }));
  }, [portal_state, dispatch, joinIdPasscode]);

  const showSharedTicketDrawer = useCallback(() => {
    dispatch(
      updatePortalState({ ...portal_state, shared_ticket_drawer: true }),
    );
  }, [portal_state, dispatch]);

  const onCloseSharedTicketDrawer = useCallback(() => {
    dispatch(
      updatePortalState({ ...portal_state, shared_ticket_drawer: false }),
    );
  }, [portal_state, dispatch]);

  const onCloseSession = useCallback(() => {
    dispatch(updatePortalState({ ...portal_state, session_drawer: false }));
    sleep(1500);
    dispatch(
      updateShowSingletonSession({
        ticket_id: '',
        data: {},
      }),
    );
  }, [portal_state, dispatch]);

  const onOpenSession = useCallback(async () => {
    if (from_dashboard) {
      getStreamingDetails();
      return dispatch(
        updatePortalState({
          ...portal_state,
          session_drawer: true,
          ticket_drawer: false,
        }),
      );
    }
    if (!active_session_list.length) {
      await get_all_active_sessions({
        uid: decodedToken?.id,
        type: 'AUTH',
      });
    }
    return dispatch(
      updatePortalState({
        ...portal_state,
        session_drawer: true,
        ticket_drawer: false,
      }),
    );
  }, [
    portal_state,
    decodedToken?.id,
    active_session_list.length,
    dispatch,
    from_dashboard,
    getStreamingDetails,
  ]);

  const onCloseNFT = useCallback(() => {
    dispatch(updatePortalState({ ...portal_state, nft_drawer: false }));
  }, [portal_state, dispatch]);

  const onOpenNFT = useCallback(async () => {
    dispatch(updatePortalState({ ...portal_state, nft_drawer: true }));
  }, [portal_state, dispatch]);

  const onCloseNFTDetails = useCallback(() => {
    dispatch(updatePortalState({ ...portal_state, nft_details_drawer: false }));
  }, [portal_state, dispatch]);

  const onOpenNFTDetails = useCallback(
    async (from_ticket_drawer = false) => {
      dispatch(
        updatePortalState({
          ...portal_state,
          nft_details_drawer: true,
          ticket_drawer: from_ticket_drawer ? true : false,
        }),
      );
    },
    [portal_state, dispatch],
  );

  const openQRModal = useCallback(() => {
    dispatch(updatePortalState({ ...portal_state, show_qrmodal: true }));
  }, [portal_state, dispatch]);

  const closeQRModal = useCallback(() => {
    dispatch(updatePortalState({ ...portal_state, show_qrmodal: false }));
    dispatch(
      updateQRScannedData({
        is_loading: false,
        is_error: false,
        error: undefined,
        is_success: false,
        data: {},
      }),
    );
  }, [portal_state, dispatch]);

  const list = useMemo(() => {
    return orders_data?.map((orders, index) => ({
      id: `disclosure-panel-${index}`,
      isOpen: false,
      lable: `Booking Id - ${shortenAddress(orders?._id)} | ⏱️ ${moment
        .utc(orders?.created_at)
        .local()
        .format('MM-DD-YYYY HH:mm')}`,
      index: index,
      count: orders?.booked_tickets?.length,
      created_at: orders?.created_at,
      panel: (
        <JoinLoungeCard
          isShared={false}
          selectedTicketTypeData={orders?.booked_tickets}
          onCloseDrawer={onCloseDrawer}
          onOpenNFTDetails={onOpenNFTDetails}
          onOpenSession={onOpenSession}
          event_details={event_details}
          isObjectEmpty={isObjectEmpty}
        />
      ),
    }));
  }, [
    event_details,
    isObjectEmpty,
    onOpenNFTDetails,
    onOpenSession,
    onCloseDrawer,
    orders_data,
  ]);
  // const list = useMemo(
  //   () => {
  //     return ticket_type.map((type, index) => ({
  //       id: `disclosure-panel-${index}`,
  //       isOpen: false,
  //       lable: type,
  //       index: index,
  //       count: ticket_data_all_type[index].length,
  //       panel: (
  //         <JoinLoungeCard
  //           isShared={false}
  //           selectedTicketTypeData={ticket_data_all_type[index]}
  //           onCloseDrawer={onCloseDrawer}
  //           onOpenNFTDetails={onOpenNFTDetails}
  //           onOpenSession={onOpenSession}
  //           event_details={event_details}
  //           isObjectEmpty={isObjectEmpty}
  //         />
  //       ),
  //     }))
  //   },

  //   [ticket_data_all_type, ticket_type, event_details, isObjectEmpty, onOpenNFTDetails, onOpenSession, onCloseDrawer],
  // );

  // ticket-> ticket_list
  const handleToggleScreen = useCallback(() => {
    dispatch(authActions.resetState());

    setDisClosureList(list);
    dispatch(updateDisclosureList(list));
    joinIdPasscode.resetFields();
    // setToggleSessionScreen(false);
    return dispatch(
      updateDrawerScreen({
        ...drawer_screen,
        ticket_screen: !drawer_screen.ticket_screen,
      }),
    );
  }, [dispatch, drawer_screen, list, joinIdPasscode]);
  const handleJoinStream = useCallback(() => {
    // if (showQRSharedTicketList || showTicketQR || showSharedTicketList) {
    //   return showSharedTicketDrawer()
    //   // return validate_qr_order_api.mutate()
    // }
    // if (showTicketQR) {
    //   if (is_qr_ticket_authorized) {
    //     onCloseSharedTicketDrawer()
    //     return join_stream_direct.mutate();
    //   }
    //   showSharedTicketDrawer()
    //   return validate_qr_ticket_api.mutate()
    // }
    // if (showSharedTicketList) {
    //   return showSharedTicketDrawer()
    //   // return get_shared_ticket.mutate()
    // }
    setDisClosureList(list);
    // dispatch(updateDisclosureList(list))
    if (showIFrameDirectly) {
      onCloseDrawer();
      return join_stream_direct.mutate();
    }
    return showDrawer();
  }, [
    showIFrameDirectly,
    join_stream_direct,
    showDrawer,
    onCloseDrawer,
    list,
    // dispatch
    // showSharedTicketDrawer,
    // showSharedTicketList,
    // onCloseSharedTicketDrawer,
    // showTicketQR,
    // is_qr_ticket_authorized,
    // validate_qr_ticket_api,
    // showQRSharedTicketList
  ]);

  const [shared_disclosure_list, setSharedDisClosureList] =
    useState(shared_list);

  const disclosureRef = useRef(false);
  const sharedDisclosureRef = useRef(false);
  useOnceCall(() => {
    refetchTicketsData();
    refetchOrderData();
  }, Boolean(decodedToken && !orders_data?.length));

  useEffect(() => {
    if (!disclosureRef.current && Array.isArray(list)) {
      if (list.length) {
        setDisClosureList(list);
        disclosureRef.current = true;
      }
    }
  }, [list]);

  useEffect(() => {
    if (!sharedDisclosureRef.current && Array.isArray(shared_list)) {
      if (shared_list.length) {
        setSharedDisClosureList(shared_list);
        sharedDisclosureRef.current = true;
      }
    }
  }, [shared_list]);

  const memoizedValue = useMemo(
    () => ({
      toggleScreens: ticket_screen,
      toggleLoginScreen: auth_screen,
      toggleSessionScreen: session_screen,
      portal_state,
      disclosure_list,
      shared_disclosure_list,
      joinIdPasscode,
      drawer_screen,
      showDrawer,
      handleToggleScreen,
      handleToggleLoginScreen,
      handleToggleSessionScreen,
      onCloseSession,
      onOpenSession,
      openQRModal,
      closeQRModal,
      handleJoinStream,
      handleClick,
      handleShareClick,
      onCloseDrawer,
      onCloseNFT,
      onOpenNFT,
      onCloseNFTDetails,
      onOpenNFTDetails,
      showSharedTicketDrawer,
      onCloseSharedTicketDrawer,
      setDisClosureList,
    }),
    [
      ticket_screen,
      auth_screen,
      session_screen,
      portal_state,
      disclosure_list,
      shared_disclosure_list,
      joinIdPasscode,
      drawer_screen,
      showDrawer,
      handleToggleScreen,
      handleToggleLoginScreen,
      handleToggleSessionScreen,
      onCloseSession,
      onOpenSession,
      openQRModal,
      closeQRModal,
      handleJoinStream,
      handleClick,
      handleShareClick,
      onCloseDrawer,
      onCloseNFT,
      onOpenNFT,
      onCloseNFTDetails,
      onOpenNFTDetails,
      showSharedTicketDrawer,
      onCloseSharedTicketDrawer,
    ],
  );

  return memoizedValue;
}
