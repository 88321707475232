import React from 'react';
import ToogleSwitch from '../../util/ToogleSwitch';

export default function SecurityOptionalCompo() {
  const [toggle, setToggle] = React.useState(false);
  const toggleValue = (value) => {
    setToggle(!toggle);
  };
  return (
    <React.Fragment>
      <div className="flex flex-col lg:flex-row xs:flex-wrap lg:flex-nowrap">
        <div className="basis-full lg:basis-1/2 h-full w-full lg:pr-5">
          <div className="w-full">
            <div className="pt-6 pb-8">
              <div className="relative flex flex-wrap md:flex-nowrap justify-between gap-3">
                <div className="flex-shrink">
                  <h5 className="text-md mb-2">Save my activity logs</h5>
                  <span className="flex text-xs textMuted font-normal max-w-xs">
                    Save your all activity logs including unsual activity
                    detected.
                  </span>
                </div>
                <div className="relative md:absolute md:top-0 md:right-0">
                  <ToogleSwitch value={toggle} toggleValue={toggleValue} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-r border-level-2 border-dashed hidden lg:flex"></div>
        <div className="basis-full lg:basis-1/2 h-full w-full lg:pl-5">
          <div className="w-full">
            <div className="pt-6 pb-8">
              <div className="relative flex flex-wrap md:flex-nowrap justify-between gap-3">
                <div className="flex-shrink">
                  <h5 className="text-md mb-2">
                    Email me if encounter unusual activity
                  </h5>
                  <span className="flex text-xs textMuted font-normal max-w-xs">
                    You will get email notification whenever your account
                    encounters invalid login activity.
                  </span>
                </div>
                <div className="relative md:absolute md:top-0 md:right-0">
                  <ToogleSwitch value={toggle} toggleValue={toggleValue} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
