import axios from 'axios';
import config from '../../../api/Config';

export async function copyEvents(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.copy_event,
    body,
    headers,
  );

  return res.data;
}

export async function createEvents(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.create_event,
    body,
    headers,
  );

  return res.data;
}

export async function updateEvents(body, headers) {
  const res = await axios.put(
    config.baseUrl + config.endpoints.update_event,
    body,
    headers,
  );

  return res.data;
}

export async function published_events() {
  const res = await axios.get(
    config.baseUrl + config.endpoints.published_events,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}

export async function onGoing_events(page, limit) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.ongoing_events
    }?page=${page}&limit=${limit}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}

export async function trending_events(page, limit) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.trending_events
    }?page=${page}&limit=${limit}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}

export async function banner_events() {
  const res = await axios.get(
    `${config.baseUrl + config.endpoints.banner_events}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}

export async function recent_events(page, limit) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.recent_events
    }?page=${page}&limit=${limit}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}

export async function explore_events(page, limit) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.explore_events
    }?page=${page}&limit=${limit}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}

export async function all_events_filter_count() {
  const res = await axios.get(
    `${config.baseUrl + config.endpoints.all_event_filter_count}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return res.data;
}

export async function all_events_list(page, limit) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.all_event_list
    }?page=${page}&limit=${limit}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}

export async function all_events_list_filter() {
  const filter_params = new URLSearchParams(window.location.search).toString();
  const res = await axios.get(
    `${config.baseUrl + config.endpoints.all_event_list}?${filter_params}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}

export async function getEventLists(page, headers, limit) {
  const res = await axios.get(
    `${config.baseUrl + config.endpoints.event_list}?limit=${
      limit !== undefined ? limit : 10
    }&page=${page}`,
    headers,
  );
  return res.data;
}

export async function getEventListsCMS(
  page,
  headers,
  limit,
  organizer_id = '',
) {
  const res = await axios.get(
    `${config.baseUrl + config.endpoints.event_list_cms}?limit=${
      limit !== undefined ? limit : 10
    }&page=${page}&event_organiser=${organizer_id}`,
    headers,
  );
  return res.data;
}

export async function getSearchedEvents(
  page,
  search = '',
  sDate = '',
  eDate = '',
  event_type = '',
  status = '',
  headers,
  limit,
  activetab = 'all_events',
) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.event_list
    }?limit=${limit}&page=${page}&event_mode=${event_type}&sdate=${sDate}&edate=${eDate}&key=${search}&status=${status}&activetab=${activetab}`,
    headers,
  );

  return res.data;
}

export async function getTopSeller(headers) {
  const res = await axios.get(
    config.baseUrl + config.endpoints.TopSeller,
    headers,
  );

  return res.data;
}
export async function getTopArtists(page, limit) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.top_artist
    }?page=${page}&limit=${limit}`, //change the endpoint only
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}

export async function getEventDetails(id, headers) {
  const res = await axios.get(
    config.baseUrl + config.endpoints.get_event_details + id,
    headers,
  );
  return res.data;
}
export async function getEventReportDetails(id, page, pageSize, headers) {
  const res = await axios.get(
    config.baseUrl +
      config.endpoints.booked_ticket_by_event +
      id +
      `?page=${page}&limit=${pageSize}`,
    headers,
  );
  return res.data;
}
export async function getEventReportDetailsByOrganizer(
  page,
  pageSize,
  filter,
  sort,
  headers,
) {
  if (filter?._id === 'all') {
    const res = await axios.get(
      config.baseUrl +
        config.endpoints.booked_ticket_by_event_organizer +
        `?page=${page}&limit=${pageSize}&sort_by=${sort}`,
      headers,
    );
    return res.data;
  } else {
    const res = await axios.get(
      config.baseUrl +
        config.endpoints.booked_ticket_by_event_organizer +
        `?page=${page}&limit=${pageSize}&event_id=${filter?._id}&sort_by=${sort}`,
      headers,
    );
    return res.data;
  }
}
export async function getEventAttendiDetails(id, page, limit, headers) {
  const res = await axios.get(
    config.baseUrl +
      config.endpoints.attendees_list_by_event +
      id +
      `?page=${page}&limit=${limit}`,
    headers,
  );
  return res.data;
}

export async function getVirtualEventAttendiDetails(id, page, limit, headers) {
  const res = await axios.get(
    config.baseUrl +
      config.endpoints.virtual_attendees_list_by_event +
      id +
      `?page=${page}&limit=${limit}`,
    headers,
  );
  return res.data;
}

export async function getAllEventAttendiDetails(id, page, limit, headers) {
  const res = await axios.get(
    config.baseUrl +
      config.endpoints.all_attendees_list_by_event +
      id +
      `?page=${page}&limit=${limit}`,
    headers,
  );
  return res.data;
}

export async function deleteEvents(headers, payload) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.delete_event,
    payload,
    headers,
  );
  return res.data;
}

export async function saved_event_list(headers, page, limit) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.saved_event_list
    }?page=${page}&limit=${limit}`,
    headers,
  );

  return res.data;
}
export async function get_ticket_preview_details(headers, cid) {
  const res = await axios.get(
    config.baseUrl +
      config.endpoints.get_ticket_preview_details +
      `?cart_seats_id=${cid}`,
    headers,
  );

  return res.data;
}

export async function favourite_event_list(headers, page, limit) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.favourite_event_list
    }?page=${page}&limit=${limit}`,
    headers,
  );

  return res.data;
}

export async function getEventSeatDetails(headers, id) {
  const res = await axios.get(
    config.baseUrl + config.endpoints.event_seat_details + id,
    headers,
  );

  return res.data;
}

export async function getsSearchResults(new_params) {
  const res = await axios.get(
    config.baseUrl + config.endpoints.search_input + `${new_params}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}

export async function CheckEventName(headers, payload) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.event_name,
    payload,
    headers,
  );
  return res.data;
}

export async function getUserLocation(latitude, longitude) {
  const res = await axios.get(
    `${
      config.open_cage_baseurl + config.endpoints.user_location
    }?q=${latitude}+${longitude}&key=${config.open_cage_key}`,
  );

  return res.data;
}

export async function getPopularStates(key) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.popular_states
    }?page=${1}&limit=${10}&state=${key}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}

export async function RemoveEventImage(headers, payload) {
  const res = await axios.put(
    config.baseUrl + config.endpoints.remove_event_IMG,
    payload,
    headers,
  );
  return res.data;
}

export async function checkWaitlist(headers, payload) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.waitlist,
    payload,
    headers,
  );
  return res.data;
}
export async function getWaitlist(headers, payload) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.get_waitlist,
    payload,
    headers,
  );
  return res.data;
}

export async function getEventSalesReportDetails(filterValue, headers) {
  const res = await axios.get(
    config.baseUrl + config.endpoints.eventSales + `?dateFilter=${filterValue}`,
    headers,
  );
  return res.data;
}
