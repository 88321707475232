import { createSlice } from '@reduxjs/toolkit';
import { io } from 'socket.io-client';
const initialState = { socket: null, error: "", socket_connection_details: null, userDetails: { id: "", passcode: "" } };

const slice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    updateJoinRoom: (state, { payload }) => {
      state.socket_connection_details = payload
    },
    updateJoinRoomUserDetails: (state, { payload }) => {
      state.userDetails = payload
    },
    updateError: (state, { payload }) => {
      state.error = payload
    },
    handleConnect: (state, { payload }) => {

      // Close the socket connection when the "Disconnect" button is clicked
      const soc = io(
        "http://35.154.99.179:9091/ticket-streaming",
        {
          query: { bookid: payload?._id } // '65167c23bbae3859ddb38d73' }
        },
        //  config.socketBaseUrl,
        ///ticket-streaming?bookid=65167c23bbae3859ddb38d73
        // {
        //   reconnection: true,
        //   reconnectionDelay: 1000,
        //   reconnectionDelayMax: 5000,
        //   reconnectionAttempts: Infinity,
        // },
      );

      soc?.on('connect', (res) => {

        let data = res || payload?._id
        state.socket_connection_details = data
        state.userDetails = { id: payload?.ticket_access_code, passcode: payload?.uid4?.slice(-6) }
        state.socket = soc
        // dispatch(updateJoinRoom(data))
        // dispatch(updateJoinRoomUserDetails())
        // setSocket(soc);
      });
      soc.on("connect_error", (err) => {

      });
    }
  }
})

let joinRoomReducer = slice.reducer
export const { updateError, updateJoinRoom, updateJoinRoomUserDetails } = slice.actions
const getJoinedUserDetails = (state) => state.socket
export {
  joinRoomReducer,
  getJoinedUserDetails
};