import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { MdInfoOutline } from 'react-icons/md';
import Modal from '../../common/Modal';
import { btnPrimaryClass } from '../DefaultClasses';
import { useCheckoutConfirmationModal } from '../../../pages/checkout/hooks/useCheckout';
import { useSelector } from 'react-redux';
import { formatUsd } from '../../../utils/util';
import useDecodedToken from '../../../hooks/useDecodedToken';
import { Tooltip } from 'antd';

export default function ConfirmPaymentModeModel({
  isOpen,
  setIsOpen,
  darkTheme,
}) {
  const {
    reset,
    error,
    isDisabled,
    isDisabledFirst,
    ProceedWithUSDWallet,
    addMoneyInWallet,
    checkForWalletMoney,
    getTotal,
    isDisabledOther,
    selectOtherPayment,
  } = useCheckoutConfirmationModal();
  const decodedToken = useDecodedToken();
  const { checkout_billing_summary } = useSelector((s) => s.checkout);
  const { usd_money } = useSelector((s) => s.userDetails);
  const difference = useMemo(
    () =>
      Number(usd_money) -
      Number(checkout_billing_summary?.sub_total_price?.grand_total),
    [checkout_billing_summary?.sub_total_price, usd_money],
  );

  React.useEffect(() => {
    if (decodedToken) {
      checkForWalletMoney();
    }
  }, [checkForWalletMoney, decodedToken]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      reset={() => {
        reset();
      }}
      maxWidth="max-w-md"
      backdrop={darkTheme ? 'backdrop-blur-sm' : null}
      bgColor={`dark:bg-zinc-900 bg-whiteNew-0 bg-opacity-100 backdrop-blur-sm bg-center`}
      darkTheme={darkTheme}
      preventClosingOutsideClick={true}
    >
      <div className="mt-4 mt-md-0">
        <Row className="items-center">
          <Col xs={12} md={12}>
            <div className="flex justify-center flex-col text-center">
              <div className="text-6xl text-yellow-400 flex justify-center">
                <MdInfoOutline />
              </div>
              <div className="my-2 flex flex-col justify-center align-middle">
                <div>
                  <p className={`dark:text-whiteNew-0 text-zinc-800`}>
                    Please Select the Payment Method for Ticket Purchase.
                  </p>
                </div>
                {
                  <div className="mt-4 w-full flex flex-col justify-center align-middle">
                    <Tooltip
                      title={
                        !decodedToken
                          ? 'Login to proceed with USD wallet.'
                          : null
                      }
                    >
                      <button
                        className={`${btnPrimaryClass} w-full disabled:cursor-not-allowed`}
                        disabled={
                          error !== '' ||
                          isDisabledFirst ||
                          Boolean(difference < 0) ||
                          !usd_money ||
                          !decodedToken
                        }
                        onClick={ProceedWithUSDWallet}
                      >
                        Proceed With USD Wallet
                      </button>
                    </Tooltip>

                    {(Boolean(difference < 0) || !usd_money) &&
                      decodedToken && (
                        <div className="flex justify-center items-center align-middle flex-col">
                          <span className="text-red-500">
                            Required amount for checkout{' '}
                            {formatUsd(
                              Number(
                                checkout_billing_summary?.sub_total_price
                                  ?.grand_total - usd_money,
                              ),
                            )}
                          </span>
                          <button
                            disabled={isDisabled}
                            className="text-blue-500 underline cursor-pointer w-44"
                            onClick={() => {
                              addMoneyInWallet();
                            }}
                          >
                            Click Here to Add Money
                          </button>
                          {getTotal() > 5999 && (
                            <span className="text-red-500">
                              (maximum amount allowed for addition at one time
                              is $5,999)
                            </span>
                          )}
                        </div>
                      )}
                    <span
                      className={
                        darkTheme
                          ? 'dark:text-whiteNew-0 text-zinc-800 m-2 text-lg'
                          : 'text-dark m-2 text-lg'
                      }
                    >
                      or
                    </span>
                    <button
                      // className="btn btnPrimary rounded-xl w-full"
                      disabled={isDisabledOther || isDisabledFirst}
                      className={`${btnPrimaryClass} w-full disabled:cursor-not-allowed`}
                      onClick={selectOtherPayment}
                    >
                      Use Other Payment Modes
                    </button>
                  </div>
                }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
