import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineSearch, AiOutlineZoomIn } from 'react-icons/ai';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTicketPreview } from './useTicketPreview';
import moment from 'moment';
import { DATEFORMAT, truncate3 } from '../../utils/util';
import Modal from '../../components/common/Modal';
import Tooltip from '../../components/common/Tooltip';
import QRCode from 'qrcode.react';
import SVG from 'react-inlinesvg';

export default function TicketPreviewPage() {
  // const [showQrCode, setShowQrCode] = React.useState(false);
  const [showOpenModal, setShowOpenModal] = React.useState(false);
  const [searchParams] = useSearchParams();
  const { data, isLoading } = useTicketPreview(searchParams.get('cid'));
  const navigate = useNavigate();
  const [qrValue, setQRValue] = useState();
  // function render_xml(id, xml_string) {
  //   var doc = new DOMParser().parseFromString(xml_string, 'application/xml');
  //   return doc.documentElement;
  //   // var el = document.getElementById(id);
  //   // console.log(el);
  //   // el.appendChild(el.ownerDocument.importNode(doc.documentElement, true));
  // }
  const qrRef = useRef(null);
  // useEffect(() => {
  //   if (svg.current) {
  //     svg.current.appendChild(qrValue);
  //   }
  // }, [qrValue]);
  // function render_xml(id, xml_string) {
  //   var doc = new DOMParser().parseFromString(xml_string, 'application/xml');
  //   return doc;
  //   // var el = document.getElementById(id);
  //   // console.log(el, id);
  //   // el?.appendChild(el?.ownerDocument.importNode(doc.documentElement, true));
  // }

  // const downloadQRCode = () => {
  //   console.log(document.getElementById('qrCodeEl'));
  //   const qrCodeURL = document
  //     .getElementById('qrCodeEl')
  //     .querySelector('canvas')
  //     .toDataURL('image/png')
  //     .replace('image/png', 'image/octet-stream');

  //   let aEl = document.createElement('a');
  //   aEl.href = qrCodeURL;
  //   aEl.download = 'QR_Code.png';
  //   document.body.appendChild(aEl);
  //   aEl.click();
  //   document.body.removeChild(aEl);
  // };

  // const downloadQRCode = () => {
  //   const qrCodeURL = document
  //     .getElementById('qrCodeEl')
  //     .toDataURL('image/png')
  //     .replace('image/png', 'image/octet-stream');
  //   console.log(qrCodeURL);
  //   let aEl = document.createElement('a');
  //   aEl.href = qrCodeURL;
  //   aEl.download = 'QR_Code.png';
  //   document.body.appendChild(aEl);
  //   aEl.click();
  //   document.body.removeChild(aEl);
  // };

  const downloadQRCode = async () => {
    const svg = qrRef.current.querySelector('svg');
    const svgXML = new XMLSerializer().serializeToString(svg);
    const dataUrl = 'data:image/svg,' + encodeURIComponent(svgXML);

    // const image = await fetch(dataUrl);
    // image.src = dataUrl
    const anchor = document.createElement('a');
    anchor.href = dataUrl;
    anchor.download = `qr-code.svg`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  const getPhysicalsummary = (booked_tickets) => {
    return booked_tickets?.map((ticket) => {
      return (
        <div className="flex justify-between items-center mb-[5px]">
          <div className="flex flex-col">
            <span className="text-slate-300 text-sm">{ticket?.category}</span>
            <span className="text-[1.2em] font-medium flex gap-[0.5rem]">
              <span>Section: {ticket?.section},</span>
              <span>Row: {ticket?.row},</span>{' '}
              <span>Seat: {ticket?.seat_number}</span>
            </span>
          </div>
          <div>
            <span className="text-[1.2em]  flex">
              ${' '}
              {ticket?.resale_price !== null &&
              ticket?.resale_price !== undefined
                ? Number(ticket?.resale_price)?.toLocaleString('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : Number(ticket?.price)?.toLocaleString('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
            </span>
          </div>
        </div>
      );
    });
  };
  // function groupBy(list, keyGetter) {
  //   const map = new Map();
  //   list?.forEach((item) => {
  //     const key = keyGetter(item);
  //     const collection = map.get(key);
  //     if (!collection) {
  //       map.set(key, [item]);
  //     } else {
  //       collection.push(item);
  //     }
  //   });
  //   return map;
  // }
  const getVirtualsummary = (booked_tickets) => {
    // let grouped = groupBy(booked_tickets, (item) => item?.name);
    // let data = Object.fromEntries(grouped);
    return booked_tickets?.map((ticket, index) => {
      const qr = {
        link: `${window.location.origin}/streaming?evn=${data?.data?.event_data[0]?.slug}`,
        joining_id: data?.data?.booked_tickets?.[index]?.ticket_access_code,
        passcode: String(data?.data?.booked_tickets?.[index]?.uid4).slice(-6),
        type: 'ticket',
        share: 'no',
      };
      return (
        <div className="flex justify-between items-center pb-2">
          <div className="flex justify-between items-center flex-wrap2 md:flex-nowrap w-[80%] md:gap-1.5">
            <div className="flex flex-col w-full">
              <span className="text-[1.2em] font-medium flex gap-[0.5rem]">
                {ticket?.name} #{index + 1}
              </span>
              {/* <span className="text-[1.2em] font-medium flex gap-[0.5rem]">
              <span>Section: {ticket?.section},</span>
              <span>Row: {ticket?.row},</span>{' '}
              <span>Seat: {ticket?.seat_number}</span>
            </span> */}
            </div>
            <div className="shrink-0">
              <span className="text-[1.2em]  flex">
                ${' '}
                {ticket?.resale_price !== null &&
                ticket?.resale_price !== undefined
                  ? Number(ticket?.resale_price)?.toLocaleString('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : Number(ticket?.price)?.toLocaleString('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </span>
            </div>
          </div>
          {/* <button className=" bg-neutral-800 p-1 rounded-md "> */}
          {/* <QRCode
            className="w-full h-full"
            onClick={downloadQRCode}
            id="qrCodeEl"
            value={
              JSON.stringify(qr)

              // data?.data?.booked_tickets[0]?.event_type === 'Virtual'
              //   ? `${window.location.origin}/streaming?evn=${data?.data?.event_data[0]?.slug}`
              //   : `${window.location.origin}/event-detail/${data?.data?.event_data[0]?.slug}`
            }
          /> */}
          <div
            className="text-sm hover:underline hover:text-purple-600"
            type="button"
            id={'qrElem' + String(index)}
            onClick={() => {
              // setQRValue(JSON.stringify(qr));

              // let blob = new Blob([data?.qr_list[index]], {
              //   type: 'image/svg+xml',
              // });
              // let url = URL.createObjectURL(blob);
              // console.log(url);
              // var parser = new DOMParser();
              // var doc = parser.parseFromString(
              //   data?.qr_list[index],
              //   'image/svg+xml',
              // );
              // console.log(doc);
              setQRValue({ qr, QR: data?.qr_list[index] });
              // setQRValue(
              //   render_xml('qrElem', data?.qr_list[index]).documentElement,
              // );
              setShowOpenModal(!showOpenModal);
            }}
          >
            View QR
            {/* {render_xml('qrElem' + String(index), data?.qr_list[index])} */}
          </div>
          {/* </button> */}
        </div>
      );
    });
    // return Object?.entries(data)?.map(([key, value]) => {
    //   return (
    //     <div className="flex justify-between items-center mb-[5px]">
    //       <div className="flex flex-row">
    //         <span className="text-slate-300 text-sm">{key}</span>
    //         <span className="text-[1.2em] font-medium flex px-2">
    //           Qty: {value?.length}
    //         </span>
    //       </div>
    //       <div>
    //         <span className="text-[1.2em]  flex">
    //           ${' '}
    //           {value
    //             ?.reduce((acc, ticket) => {
    //               let val = ticket?.resale_price
    //                 ? ticket?.resale_price
    //                 : ticket?.price;
    //               return acc + val;
    //             }, 0)
    //             ?.toLocaleString('en-US', {
    //               style: 'decimal',
    //               minimumFractionDigits: 2,
    //               maximumFractionDigits: 2,
    //             })}
    //           {/* ${' '}
    //             {val[0]?.resale_price !== null && val?.resale_price !== undefined
    //               ? val?.resale_price
    //               : val?.price} */}
    //         </span>
    //       </div>
    //     </div>
    //   );
    // });
  };

  return (
    <React.Fragment>
      <div>
        <section className="bg-color-base-1 dark:bg-[#000] lg:min-h-[100vh] border-t border-t-level-2">
          <div className="flex pt-10">
            <div className="container">
              <div className="py-7">
                <section className="">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="flex justify-center mt-3">
                          <div className="bg-color-base-1 dark:bg-color-base-4 shadow-lg shadow-level-4 w-full max-w-[500px] rounded-lg overflow-hidden text-color-primary p-[20px]">
                            {/* <div
                              className="ticket_card_preview"
                              style={{
                                // background:
                                //   'linear-gradient(to top, rgb(43 43 43) 0%, rgb(23 23 23) 100%)',
                                background: '#111',
                                overflow: 'hidden',
                                borderRadius: '10px',
                                width: '100%',
                                maxWidth: '500px',
                                position: 'relative',
                                padding: '20px',
                                color: '#000',
                                fontWeight: '300',
                              }}
                            > */}
                            {/* <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                position: 'absolute',
                                top: '2px',
                                right: '4px',
                              }}
                            >
                              <div>
                                <LogoGoTix
                                  style={{
                                    height: '24px',
                                    color: 'rgb(23 23 23)',
                                    width: '96px',
                                  }}
                                />
                              </div>
                            </div> */}
                            <div className="flex gap-2">
                              <div className="w-[100px] h-[130px] rounded-md overflow-hidden">
                                <img
                                  src={
                                    data?.data?.event_data[0]?.profile_image
                                      ?.url
                                  }
                                  alt="event "
                                  className="w-full h-full object-cover"
                                />
                              </div>

                              <div>
                                <h5 className="text-color-primary  font-medium text-lg">
                                  {data?.data?.event_data[0]?.name}
                                </h5>
                                <div className="flex mt-[5px]">
                                  <span className="bg-color-base-2 text-color-primary px-3.5 py-[2px] flex rounded-md font-medium">
                                    {data?.data?.booked_tickets[0]?.event_type}
                                  </span>
                                </div>
                                <div className="flex mt-[5px] font-medium">
                                  <span className="flex text-color-primary ">
                                    {moment(data?.data?.created_at).format(
                                      DATEFORMAT,
                                    )}
                                  </span>
                                </div>
                                <div className="flex font-medium">
                                  <span className="flex text-color-primary ">
                                    {data?.data?.event_data[0]?.location}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="mt-[15px] relative">
                              <div className="bg-color-base-2 rounded-full flex justify-between py-[8px] px-[10px] w-full">
                                <span className="flex font-medium">
                                  Ticket Summary
                                </span>
                                <div className="flex">
                                  <Tooltip title={data?.data?._id}>
                                    <span className="text-landingPurple-60 font-medium">
                                      BOOKING ID:{' '}
                                      {truncate3(data?.data?._id, 4)}
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                              <div className="absolute w-[30px] h-[30px] rounded-full bg-color-base-2 dark:bg-[#000] top-1/2 -translate-y-1/2 -left-[37px]"></div>
                              <div className="absolute w-[30px] h-[30px] rounded-full bg-color-base-2 dark:bg-[#000] top-1/2 -translate-y-1/2 -right-[37px]"></div>
                            </div>

                            <div className="h-[20px]"></div>

                            <div>
                              {data?.data?.booked_tickets[0]?.event_type ===
                                'Physical' &&
                                getPhysicalsummary(data?.data?.seat_data)}
                              {data?.data?.booked_tickets[0]?.event_type ===
                                'Virtual' &&
                                getVirtualsummary(data?.data?.seat_data)}

                              {/* <div className="flex justify-between items-center mb-[5px]">
                                <div className="flex flex-col">
                                  <span className="text-slate-300 text-sm">
                                    VVIP
                                  </span>
                                  <span className="text-[1.2em] font-medium flex gap-[0.5rem]">
                                    <span>Section: 131,</span>
                                    <span>Row: 1,</span> <span>Seat: 2</span>
                                  </span>
                                </div>
                                <div>
                                  <span className="text-[1.2em]  flex">
                                    $ 100
                                  </span>
                                </div>
                              </div> */}
                            </div>

                            <div className="h-[20px]"></div>

                            {data?.data?.booked_tickets[0]?.event_type ===
                            'Physical' ? (
                              <div className="flex justify-between items-center">
                                <div className="flex flex-col w-full items-center">
                                  <button className="w-[100px] h-[100px] bg-color-base-2 p-1 rounded-md relative parentHover">
                                    <QRCode
                                      className="w-full h-full"
                                      renderAs="canvas"
                                      size={100}
                                      value={
                                        data?.data?.booked_tickets[0]
                                          ?.event_type === 'Virtual'
                                          ? `${window.location.origin}/streaming?evn=${data?.data?.event_data[0]?.slug}`
                                          : `${window.location.origin}/event-detail/${data?.data?.event_data[0]?.slug}`
                                      }
                                    />
                                    <div
                                      className="text-xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-gray-50 p-2 rounded-full childHover"
                                      type="button"
                                      onClick={() => {
                                        setShowOpenModal(!showOpenModal);
                                      }}
                                    >
                                      <AiOutlineSearch className="text-gray-500" />
                                    </div>
                                  </button>
                                </div>
                              </div>
                            ) : null}

                            <div className="h-[20px]"></div>

                            <div className="flex justify-between items-center gap-2.5">
                              <div
                                type="button"
                                onClick={() => {
                                  if (
                                    data?.data?.user_data[0]?.user_type ===
                                    'Seller'
                                  ) {
                                    navigate(
                                      `/user/${data?.data?.user_data[0]?.username}/dashboard?tab=purchase&subtab=orders`,
                                    );
                                  } else {
                                    navigate(
                                      `/user/${data?.data?.user_data[0]?.username}/dashboard?tab=orders`,
                                    );
                                  }
                                }}
                                className="flex w-full items-center justify-center mt-[20px] text-base gap-2.5 bg-landingPurple-60 text-whiteNew-0 rounded-md p-[8px]"
                              >
                                <div className="text-[20px]">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="m13 3 3.293 3.293-7 7 1.414 1.414 7-7L21 11V3z"></path>
                                    <path d="M19 19H5V5h7l-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5l-2-2v7z"></path>
                                  </svg>
                                </div>
                                <div className="font-normal">View Order</div>
                              </div>
                              {/* <div
                                type="button"
                                className="flex w-full items-center justify-center mt-[20px] text-base gap-2.5 bg-landingPurple-60 rounded-md p-[8px]"
                              >
                                <div className="text-[20px]">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="m12 16 4-5h-3V4h-2v7H8z"></path>
                                    <path d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"></path>
                                  </svg>
                                </div>
                                <div className="font-normal">
                                  Download Ticket
                                </div>
                              </div> */}
                            </div>

                            <div className="h-[20px]"></div>

                            <div className="mt-[15px] relative">
                              <div className="border-1 border-dashed border-level-2"></div>

                              <div className="absolute w-[30px] h-[30px] rounded-full  bg-color-base-2 dark:bg-[#000] top-1/2 -translate-y-1/2 -left-[37px]"></div>
                              <div className="absolute w-[30px] h-[30px] rounded-full bg-color-base-2 dark:bg-[#000] top-1/2 -translate-y-1/2 -right-[37px]"></div>
                            </div>

                            <div className="h-[20px]"></div>
                            <div className="-mt-[2px] -mx-[19px] -mb-[19px] bg-color-base-2 py-[10px] px-[20px]">
                              <div className="flex justify-between items-center mb-[8px] font-medium">
                                <div className="flex flex-col">
                                  <span className="text-base flex gap-1.5">
                                    <span>Ticket Amount</span>
                                  </span>
                                </div>
                                <div>
                                  <span className="text-base">
                                    ${' '}
                                    {Number(
                                      Number(
                                        data?.data?.booked_tickets?.reduce(
                                          (acc, ticket) => {
                                            let val = ticket?.resale_price
                                              ? ticket?.resale_price
                                              : ticket?.price;
                                            return acc + val;
                                          },
                                          0,
                                        ),
                                      ) + 0.52,
                                    )?.toLocaleString('en-US', {
                                      style: 'decimal',
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </span>
                                </div>
                              </div>

                              <div className="flex justify-between items-center text-color-secondary/80">
                                <div className="flex flex-col">
                                  <span className="text-xs font-normal flex fap-1.5">
                                    <span>Ticket Price</span>
                                  </span>
                                </div>
                                <div>
                                  <span className="text-xs font-normal">
                                    ${' '}
                                    {Number(
                                      data?.data?.booked_tickets?.reduce(
                                        (acc, ticket) => {
                                          let val = ticket?.resale_price
                                            ? ticket?.resale_price
                                            : ticket?.price;
                                          return acc + val;
                                        },
                                        0,
                                      ),
                                    )?.toLocaleString('en-US', {
                                      style: 'decimal',
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </span>
                                </div>
                              </div>
                              <div className="flex justify-between items-center text-color-secondary/80">
                                <div className="flex flex-col">
                                  <span className="text-xs font-normal flex gap-1.5">
                                    <span>Convenience Fees</span>
                                  </span>
                                </div>
                                <div>
                                  <span className="text-xs font-normal">
                                    $ 0.52
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        isOpen={showOpenModal}
        setIsOpen={setShowOpenModal}
        maxWidth="max-w-xs"
        backdrop={'backdrop-blur-sm'}
        bgColor={'bg-neutral-800 bg-opacity-80 backdrop-blur-md'}
        bodyClass={'p-0'}
        closeBtnPos={'-top-3 -right-3'}
        darkTheme
      >
        <div className="p-4 space-y-4 flex flex-col justify-center">
          <div className="flex w-full item-center justify-center" ref={qrRef}>
            {/* <QRCode
              id="qrCodeEl"
              size={250}
              value={
                data?.data?.booked_tickets[0]?.event_type === 'Virtual'
                  ? qrValue
                  : `${window.location.origin}/event-detail/${data?.data?.event_data[0]?.slug}`
              }
            /> */}
            {/* <img src={qrValue} alt="" /> */}
            {/* <div id="qrElem"></div> */}
            {/* {qrValue} */}
            {/* <div ref={svg} /> */}
            {/* <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(qrValue)}`}
              alt=""
            /> */}
            <SVG
              style={{
                width: 250,
                height: 250,
              }}
              id="qrCodeEl"
              src={qrValue?.QR}
            />
          </div>
          <div className="w-full flex space-x-2 justify-between">
            <span className="font-semibold">Joining Id</span>
            <span>{qrValue?.qr?.joining_id}</span>
          </div>
          <div className="flex space-x-2 justify-between">
            <span className="font-semibold">Passcode</span>
            <span>{qrValue?.qr?.passcode}</span>
          </div>
          <div className="flex space-x-2 justify-between">
            <span className="font-semibold">Link</span>
            <a
              className="hover:underline truncate cursor-pointer hover:text-purple-600"
              target="_blank"
              href={qrValue?.qr?.link}
              rel="noopener noreferrer"
            >
              {qrValue?.qr?.link}
            </a>
          </div>
        </div>
        <div
          type="button"
          className="flex w-full items-center justify-center mt-[20px] text-base gap-2.5 bg-landingPurple-60 rounded-md p-[8px]"
        >
          <div className="text-[20px]">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m12 16 4-5h-3V4h-2v7H8z"></path>
              <path d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"></path>
            </svg>
          </div>
          <div className="font-normal">Download Ticket</div>
        </div>
      </Modal>
    </React.Fragment>
  );
}
