import { ConfigProvider, Dropdown } from 'antd';
import React, { useCallback, useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { MdClose, MdKeyboardArrowDown } from 'react-icons/md';

export default function SliderFilterDropdown({
  options,
  setTabs,
  tabs,
  lightMode,
  noDefault = true,
  closeIcon = false,
  closeIconCallback = () => null,
  extra_props = {
    all_mode: true,
    classname:
      'dark:bg-landingPurple-60 bg-[#ccc] dark:hover:bg-landingPurple-80 py-[3px] px-2 rounded-[5px] border-1 dark:border-landingPurple-60 border-zinc-300 hover:border-zinc-200 dark:text-whiteNew-0 text-zinc-950 text-xs md:text-base',
  },
}) {
  const [selectedKeys, setSelectedKeys] = useState();
  const onClick = useCallback(
    (data) => {
      if (data === 'All') {
        setTabs('All');
      } else {
        setTabs(data?.name);
      }
    },
    [setTabs],
  );

  const menuOptions = useCallback(
    (data) => {
      let finalData;
      if (extra_props.all_mode) {
        finalData = [{ name: 'All' }];
        if (data) {
          finalData = [...finalData, ...data];
        }
      } else {
        finalData = data;
      }

      let filter = finalData?.map((data, index) => ({
        key: String(index),
        label: (
          <>
            <span
              className={`flex items-center justify-between gap-2 dark:text-whiteNew-0 text-zinc-800`}
            >
              <span className="flex gap-2 items-center pl-3">
                {/* <span className="text-green-500 flex w-[14px] text-lg">
                        <AiOutlineCheck />
                      </span> */}
                {data?.name === 'All' ? 'All' : data?.name}
              </span>
              {tabs === data?.name ? (
                <span className="absolute inset-y-0 left-0 flex items-center pl-1 text-landingPurple-60 mr-2">
                  <AiOutlineCheck className="h-4 w-4" aria-hidden="true" />
                </span>
              ) : null}
            </span>
          </>
        ),
        onClick: () => {
          setSelectedKeys(data?.id ?? index);
          onClick(data);
          if (
            typeof extra_props.all_mode === 'boolean' &&
            !extra_props.all_mode
          ) {
            if (selectedKeys === (data?.id ?? index)) {
              return;
            }
            data?.action();
          }
          // dispatch(setEventStatus('all'));
          // fetchEventWithFilter(event_type, 'all');
        },
      }));
      return filter;
    },
    [tabs, onClick, extra_props.all_mode, selectedKeys],
  );

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Poppins',
        },
      }}
    >
      <span
        type="button"
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="flex w-full"
      >
        <Dropdown
          trigger={['click']}
          menu={{
            items: menuOptions(options),
            selectable: true,
            defaultSelectedKeys: '0',
            selectedKeys: [selectedKeys],
          }}
          // value={tabs}
          className={String(extra_props.classname).concat(
            ` flex items-center justify-between gap-2 text-base relative duration-150 ease-in w-full min-w-[120px]`,
          )}
          placement="bottomRight"
          overlayClassName={`dropdownOverlay dowpdownDark `}
        >
          <span className="flex w-full justify-between ">
            {/* <BsThreeDots className="text-xl text-neutral-900" /> */}
            {extra_props?.icon ? (
              <div className="flex w-full gap-2 items-center">
                <span>{extra_props?.icon}</span>

                {/* <span className="text-sm">
                  {tabs === 0 ? options[0]?.name : tabs}
                </span> */}
              </div>
            ) : (
              <>
                <span className="">{tabs === 0 ? options[0]?.name : tabs}</span>
                {tabs !== 'Sort By' && noDefault ? (
                  <>
                    {closeIcon && (
                      <button
                        className="p-0.5 rounded-md bg-component-gray-2 dark:bg-color-base-2 dark:hover:bg-opacity-60 text-color-primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          closeIconCallback();
                        }}
                      >
                        <MdClose />
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <MdKeyboardArrowDown
                      className={`${lightMode ? 'text-neutral-950' : ''}`}
                    />
                  </>
                )}
              </>
            )}

            {/* {event_status !== 'all' && (
            <>
              <span className="text-sm capitalize">{event_status}</span>
              <span
                className="flex bg-rose-600 rounded-full text-white w-[15px] h-[15px] relative shrink-0"
                onClick={() => {
                  setSelectedKeys('0');
                  dispatch(setEventStatus('all'));
                  fetchEventWithFilter(event_type, 'all');
                }}
              >
                <MdClose className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-[2px]" />
              </span>
            </>
          )} */}
          </span>
        </Dropdown>
      </span>
    </ConfigProvider>
  );
}
