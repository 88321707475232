import React from 'react';
import { Form } from 'react-bootstrap';
import {
  formClass,
  formClassV2,
  primaryBtnClass,
} from '../../common/DefaultClasses';
import moment from 'moment';

export default function SecurityDefaultCompo({ email_verified, userdetails }) {
  return (
    <React.Fragment>
      <div className="flex flex-col lg:flex-row xs:flex-wrap lg:flex-nowrap">
        <div className="basis-full lg:basis-1/2 h-full w-full lg:pr-5">
          <div className="w-full">
            <div className="pt-6 pb-8 flex items-center w-full h-32">
              <div className="relative flex flex-wrap md:flex-nowrap justify-between gap-3 w-full">
                <div>
                  <h5 className="mb-2">Change Password</h5>
                  <span className="flex text-xs textMuted font-normal max-w-xs">
                    Set a unique password to protect your account.
                  </span>
                </div>
                <div className="relative xl:absolute xl:top-0 xl:right-0">
                  <div className="flex items-center gap-2">
                    {email_verified && userdetails?.last_update_password && (
                      <div>
                        <span className="flex text-xs textMuted font-normal">
                          <i className="mt-3">
                            Last Change{' '}
                            {moment
                              .utc(userdetails?.last_update_password)
                              .fromNow()}
                          </i>
                        </span>
                      </div>
                    )}

                    <div>
                      <button
                        disabled={!email_verified}
                        className={`${primaryBtnClass}  rounded-md`}
                        onClick={() => {
                          // openModal();
                        }}
                      >
                        {userdetails?.password_set
                          ? 'Change Password'
                          : 'Add Password'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="artcDivider"></div>
            <div className="pt-6 pb-8">
              <div className="relative flex flex-wrap md:flex-nowrap justify-between gap-3">
                <div>
                  <h5 className="text-md mb-2">Change Email</h5>
                  <span className="flex text-xs textMuted font-normal max-w-xs">
                    Set a unique email to login to your account.
                  </span>
                </div>
                <div className="relative xl:absolute xl:top-0 xl:right-0">
                  <div className="flex items-center gap-2">
                    <div>
                      <button
                        className={`${primaryBtnClass}  rounded-md`}
                        onClick={() => {
                          // setEmailModal(true);
                        }}
                      >
                        {email_verified ? 'Change email' : 'Add email'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-r border-level-2 border-dashed hidden lg:flex"></div>
        <div className="basis-full lg:basis-1/2 h-full w-full lg:pl-5">
          <div className="w-full">
            <div className="pt-6 pb-8 flex items-center w-full h-32">
              <div className="relative flex flex-wrap md:flex-nowrap justify-between gap-3 w-full">
                <div>
                  <div className="flex gap-2 items-center mb-2">
                    <h5 className="text-md">2FA Authentication</h5>
                    <span className="bg-rose-600 text-white px-2 rounded-md text-xs">
                      Disable
                    </span>
                  </div>
                  <span className="flex text-xs textMuted font-normal max-w-xs">
                    Secure your account with 2FA security. When it is activated
                    you will need to enter not only password, but also a special
                    code using your mobile
                  </span>
                </div>
                <div className="relative md:absolute md:top-0 md:right-0">
                  <div className="flex items-center gap-2">
                    <div>
                      <button
                        // disabled={!email_verified}
                        className={`${primaryBtnClass}  rounded-md`}
                      >
                        Enable
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="artcDivider"></div>
            <div className="pt-6 pb-8">
              <div className="relative flex flex-wrap md:flex-nowrap justify-between gap-3">
                <div className="w-full">
                  <div className="flex gap-2 items-center mb-2">
                    <h5 className="text-md">Session Inactivity Preference</h5>
                  </div>
                  {/* <span className="flex text-xs textMuted font-normal max-w-xs">
                          Secure your account with 2FA security. When it is
                          activated you will need to enter not only password,
                          but also a special code using your mobile
                        </span> */}
                  <div className="flex items-center gap-2 md:w-2/4">
                    <div className="w-full">
                      <Form.Select
                        aria-label="Default select example"
                        className={`${formClass} rounded-lg dark:bg-neutral-900 w-full text-sm form-select`}
                      >
                        <option value="5">5 Minute</option>
                        <option value="10">10 Minutes</option>
                        <option value="60">1 Hour</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
