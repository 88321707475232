import React from 'react';
import LeftSideBar from '../../common/LeftSideBar';
import {
  MdCreditCard,
  MdOutlineBook,
  MdOutlineEditLocation,
  MdOutlineMessage,
  MdPersonOutline,
} from 'react-icons/md';
import { AiOutlineHistory, AiOutlineSetting } from 'react-icons/ai';
import { BsCreditCard2Front, BsInfoCircle } from 'react-icons/bs';
import { BiUserCircle } from 'react-icons/bi';
import Tooltip from '../../common/Tooltip';
import { useNavigate } from 'react-router';
import SideBarWrapper from '../SideBarWrapper';
import ProfileSidebar from './ProfileSidebar';
import { BellIcon } from '../../util/CustomIcons';
import ProfileSection from './ProfileSection';
import MyAccountSidebar from '../my_account_compo/MyAccountSidebar';
import FavAndSavedSidebar from '../fav_saved_compo/FavAndSavedSidebar';
import NotificationEmailSidebar from '../notification_email_compo/NotificationEmailSidebar';
import MyAccountOverview from '../my_account_compo/MyAccountOverview';
import FavAndSavedSection from '../fav_saved_compo/FavAndSavedSection';
import MessagingSection from '../messaging_compo/MessagingSection';
import NotificationEmailSection from '../notification_email_compo/NotificationEmailSection';
import HistorySection from '../history_compo/HistorySection';

export default function AboutTabv2() {
  const navigate = useNavigate();
  const handleNavigation = (href) => {
    navigate(href);
  };

  const [activeParentSection, setActiveParentSection] =
    React.useState('profile_sec');
  const [activeSection, setActiveSection] = React.useState('profile');
  const [activeSubSection, setActiveSubSection] = React.useState('usd_wallet');

  return (
    <React.Fragment>
      <section className="">
        <div className="flex flex-wrap flex-md-nowrap h-full max-h-[93vh] min-h-[60vh]">
          <SideBarWrapper primarySidebarClass={'bg-slate-100'} padding={'p-2'}>
            <LeftSideBar
              title={
                <>
                  <span className="flex items-center gap-2">
                    {/* <Tooltip
                        title={'Profile'}
                        placement={'right'}
                        trigger={['hover']}
                      > */}
                    <MdPersonOutline className="w-5 h-5" />
                    {/* </Tooltip> */}
                    <span>Profile</span>
                  </span>
                </>
              }
              primarySidebar={true}
              activePrimaryMenu={
                activeParentSection === 'profile_sec' ? true : false
              }
              accordOnClick={() => {
                setActiveParentSection('profile_sec');
                setActiveSection('profile');
              }}
            />
            <LeftSideBar
              title={
                <>
                  <span className="flex items-center gap-2">
                    {/* <Tooltip
                        title={'Profile'}
                        placement={'right'}
                        trigger={['hover']}
                      > */}

                    {/* <BsCreditCard2Front className="w-5 h-5" /> */}
                    <MdCreditCard className="w-5 h-5" />
                    {/* </Tooltip> */}
                    <span>My Account</span>
                  </span>
                </>
              }
              primarySidebar
              activePrimaryMenu={
                activeParentSection === 'my_account' ? true : false
              }
              accordOnClick={() => {
                setActiveParentSection('my_account');

                setActiveSection('gotix_wallet');
                setActiveSubSection('usd_wallet');
              }}
            />
            <LeftSideBar
              title={
                <>
                  <span className="flex items-center gap-2">
                    {/* <Tooltip
                        title={'Profile'}
                        placement={'right'}
                        trigger={['hover']}
                      > */}
                    <MdOutlineBook className="w-5 h-5" />
                    {/* </Tooltip> */}
                    <span>Favorite & Saved</span>
                  </span>
                </>
              }
              primarySidebar
              activePrimaryMenu={
                activeParentSection === 'fav_savedSec' ? true : false
              }
              accordOnClick={() => {
                setActiveSection('fav_saved');
                setActiveParentSection('fav_savedSec');
                setActiveSubSection('favorite');
              }}
            />
            <LeftSideBar
              title={
                <>
                  <span className="flex items-center gap-2">
                    {/* <Tooltip
                        title={'Profile'}
                        placement={'right'}
                        trigger={['hover']}
                      > */}
                    <MdOutlineMessage className="w-5 h-5" />
                    {/* </Tooltip> */}
                    <span>Messaging</span>
                  </span>
                </>
              }
              primarySidebar
              activePrimaryMenu={
                activeParentSection === 'messaging' ? true : false
              }
              accordOnClick={() => {
                setActiveParentSection('messaging');
                setActiveSection('notification_email');
                setActiveSubSection('notification');
              }}
            />
            <LeftSideBar
              title={
                <>
                  <span className="flex items-center gap-2">
                    {/* <Tooltip
                        title={'Profile'}
                        placement={'right'}
                        trigger={['hover']}
                      > */}
                    <AiOutlineHistory className="w-5 h-5" />
                    {/* </Tooltip> */}
                    <span>History</span>
                  </span>
                </>
              }
              primarySidebar
              activePrimaryMenu={
                activeParentSection === 'history' ? true : false
              }
              accordOnClick={() => {
                setActiveParentSection('history');
              }}
            />
            <LeftSideBar
              title={
                <>
                  <span className="flex items-center gap-2">
                    {/* <Tooltip
                        title={'Profile'}
                        placement={'right'}
                        trigger={['hover']}
                      > */}
                    <BellIcon className="w-5 h-5" />
                    {/* </Tooltip> */}
                    <span>Notification & Email</span>
                  </span>
                </>
              }
              primarySidebar
              activePrimaryMenu={
                activeParentSection === 'notification' ? true : false
              }
              accordOnClick={() => {
                setActiveParentSection('notification');
              }}
            />
          </SideBarWrapper>
          {activeParentSection === 'profile_sec' && (
            <SideBarWrapper padding={'p-2'}>
              <ProfileSidebar
                activeSection={activeSection}
                setActiveSection={setActiveSection}
                setActiveSubSection={setActiveSubSection}
                activeSubSection={activeSubSection}
              />
            </SideBarWrapper>
          )}
          {/* My Account Sub-Sidebar */}
          {activeParentSection === 'my_account' && (
            <SideBarWrapper padding={'p-2'}>
              <MyAccountSidebar
                activeSection={activeSection}
                setActiveSection={setActiveSection}
                setActiveSubSection={setActiveSubSection}
                activeSubSection={activeSubSection}
              />
            </SideBarWrapper>
          )}
          {/* Favorite and Saved Sub-Sidebar */}
          {activeParentSection === 'fav_savedSec' && (
            <SideBarWrapper padding={'p-2'}>
              <FavAndSavedSidebar
                activeSection={activeSection}
                setActiveSection={setActiveSection}
                setActiveSubSection={setActiveSubSection}
                activeSubSection={activeSubSection}
              />
            </SideBarWrapper>
          )}
          {activeParentSection === 'messaging' && <></>}
          {activeParentSection === 'history' && <></>}
          {activeParentSection === 'notification' && (
            <SideBarWrapper padding={'p-2'}>
              <NotificationEmailSidebar
                activeSection={activeSection}
                setActiveSection={setActiveSection}
                setActiveSubSection={setActiveSubSection}
                activeSubSection={activeSubSection}
              />
            </SideBarWrapper>
          )}
          <div className="w-full flex-grow overflow-hidden">
            <div className="w-full flex-grow relative flex flex-col">
              {activeParentSection === 'profile_sec' && (
                <ProfileSection
                  activeSection={activeSection}
                  setActiveSection={setActiveSection}
                  activeSubSection={activeSubSection}
                  setActiveSubSection={setActiveSubSection}
                />
              )}
              {activeParentSection === 'my_account' && (
                <MyAccountOverview
                  activeSection={activeSection}
                  setActiveSection={setActiveSection}
                  activeSubSection={activeSubSection}
                  setActiveSubSection={setActiveSubSection}
                />
              )}
              {activeParentSection === 'fav_savedSec' && (
                <FavAndSavedSection
                  activeSection={activeSection}
                  setActiveSection={setActiveSection}
                  activeSubSection={activeSubSection}
                  setActiveSubSection={setActiveSubSection}
                />
              )}
              {activeParentSection === 'messaging' && (
                <MessagingSection
                  activeSection={activeSection}
                  setActiveSection={setActiveSection}
                  activeSubSection={activeSubSection}
                  setActiveSubSection={setActiveSubSection}
                />
              )}
              {activeParentSection === 'history' && (
                <HistorySection
                  activeSection={activeSection}
                  setActiveSection={setActiveSection}
                  activeSubSection={activeSubSection}
                  setActiveSubSection={setActiveSubSection}
                />
              )}

              {activeParentSection === 'notification' && (
                <NotificationEmailSection
                  activeSection={activeSection}
                  setActiveSection={setActiveSection}
                  activeSubSection={activeSubSection}
                  setActiveSubSection={setActiveSubSection}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
