import React, { useEffect, useState } from 'react';
import AuthScreenWrapper from '../AuthScreenWrapper';
import SignUpScreen from './SignUpScreen';
import useSearchQuery from '../../../hooks/useSearchQuery';
import axios from 'axios';
import config from '../../../api/Config';
import { DataLoader } from '../../../components/common/Loaders';
import loaderMessage from '../../../utils/LoaderMessage';
import { Navigate } from 'react-router-dom';
import { useLogout } from '../../../helpers/api/auth/auth';

function Index({ compo, darkTheme, showLogo }) {
  const query = useSearchQuery();
  const token = query.get('token');
  const [tokenStatus, setTokenStatus] = useState({
    isCheckedToken: false,
    isTokenValidated: false,
  });
  useLogout();

  useEffect(() => {
    async function fetchData() {
      // You can await here
      if (token && !tokenStatus.isCheckedToken) {
        const body = {
          is_transfer_token: token,
        };
        try {
          const res = await axios.post(
            config.baseUrl + config.endpoints.nft_transfer_token_verification,
            body,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          );
          if (res.data.status) {
            setTokenStatus({
              ...tokenStatus,
              isCheckedToken: true,
              isTokenValidated: res.data.status,
            });
          }
        } catch (err) {
          setTokenStatus({
            ...tokenStatus,
            isCheckedToken: true,
            isTokenValidated: err?.response?.data?.status,
          });
        }
      }
    }
    fetchData();
  }, [token, tokenStatus]);

  if (token && tokenStatus.isTokenValidated) {
    return (
      <AuthScreenWrapper darkTheme={darkTheme}>
        <SignUpScreen compo={compo} darkTheme={darkTheme} showLogo={showLogo} />
      </AuthScreenWrapper>
    );
  }
  if (token && !tokenStatus.isTokenValidated && tokenStatus.isCheckedToken) {
    return <Navigate to={'/auth/claim-nft/error'} />;
  }
  if (token && !tokenStatus.isCheckedToken) {
    return <DataLoader message={loaderMessage?.loading_link} />;
  }
  return (
    <AuthScreenWrapper darkTheme={darkTheme}>
      <SignUpScreen compo={compo} darkTheme={darkTheme} showLogo={showLogo} />
    </AuthScreenWrapper>
  );
}

export default Index;
