import moment from 'moment';
import Badge from '../../common/Badge';
import Tooltip from '../../common/Tooltip';
import { MoveLocationIcon, MoveLocationIconOpp } from '../../util/CustomIcons';
import { DATEFORMAT } from '../../../utils/util';

export const getTicketType = (data) => {
  if (data?.event_type === 'both') {
    return <span className="rounded-full relative">Virtual & In-Person</span>;
  } else if (data?.event_type === 'Virtual') {
    return <span className="rounded-full relative">Virtual</span>;
  } else if (data?.event_type === 'Physical') {
    return <span className="rounded-full relative">In-Person</span>;
  } else {
    return '';
  }
};
export const getPrice = (data) => {
  let price = 0;
  data.booked_tickets?.forEach((data) => {
    price += Number(parseFloat(data?.price?.toString()?.replace(/,/g, '')));
  });
  return price;
};
export const checkifAllTicketClear = (data) => {
  let isPresent = data?.booked_tickets
    // ?.filter((data) => !data?.is_cancel)
    ?.filter(
      (data) =>
        // !data?.is_cancel ||
        (data?.is_resale === true || data?.is_resale === undefined) &&
        !data?.is_resold &&
        !data?.is_shared &&
        !data?.resale_ticket &&
        !data?.is_cancel,
    );

  if (isPresent?.length > 0) {
    return false;
  } else {
    return true;
  }
};

export const getTicketsOnResaleAndResold = (data) => {
  let filterdata = data?.booked_tickets?.filter(
    (data) =>
      !data?.is_cancel &&
      !data?.is_resold &&
      !data?.is_shared &&
      data?.is_resale !== true,
  );
  if (filterdata?.length === 0) {
    return true;
  } else {
    return false;
  }
};

export const checkIsMinting = (data) => {
  return (
    data?.booked_tickets
      ?.filter((data) => !data?.is_cancel)
      ?.filter((data) => !data?.is_resold)
      ?.filter((data) => !data.nft_metadata)?.length !== 0
  );
};

export const getRefundPriceValue = (item) => {
  if (item?.seat_id) {
    let resalevalue = item?.cart_seats?.seat_data[getIndex(item)]?.resale_price;
    let pricevalue = item?.cart_seats?.seat_data[getIndex(item)]?.price;
    if (resalevalue) {
      if (
        stringconvertToNumber(resalevalue) > stringconvertToNumber(pricevalue)
      ) {
        return pricevalue;
      } else {
        return resalevalue;
      }
    } else {
      return pricevalue;
    }
  } else {
    let resale_pricef = item?.price;
    let pricef = item?.cart_seats?.seat_data?.find(
      (val) => val?.name === item?.category?.name,
    )?.price;
    if (resale_pricef) {
      if (resale_pricef > pricef) {
        return pricef || 0;
      } else {
        return resale_pricef || 0;
      }
    } else {
      return pricef || 0;
    }
  }
};

export const getFinalAmount = (price, data) => {
  let amount = 0;
  if (typeof price === 'string') {
    amount = Number(parseFloat(price?.toString()?.replace(/,/g, '')));
  } else {
    amount = price;
  }
  //?difference in cancel date and event start date
  var duration = moment.duration(
    moment(data?.booked_tickets[0]?.tickets?.event?.start_date_time).diff(
      moment(),
    ),
  );
  //?difference in hour
  var hours = duration.asHours();

  //?difference in days
  var days = duration.asDays();

  //?difference in months
  var months = duration.asMonths();
  //?array of amount with favourable policies
  let finalAmount = [];
  let selectedPolicyHour = [];
  // let policies = data[0]?.tickets?.event?.policy[0];
  let policies = data?.booked_tickets[0]?.tickets?.event?.policy?.filter(
    (obj) =>
      obj?.sub_policy_type === 'Refund Policies' &&
      obj?.currently_in_use &&
      !obj?.default_values,
  )[0];

  //?loop over the poicies and those match put in array
  policies?.values?.forEach((policy) => {
    if (
      policy.timeframeSelect === 'Hour' &&
      hours >= Number(policy?.timeframeValue)
    ) {
      finalAmount.push((amount * policy?.percent) / 100);
      selectedPolicyHour.push(Number(policy?.timeframeValue));
    } else if (
      policy?.timeframeSelect === 'Days' &&
      days >= Number(policy?.timeframeValue)
    ) {
      finalAmount.push((amount * policy?.percent) / 100);
      //?convert days into hour
      selectedPolicyHour.push(Number(policy?.timeframeValue) * 24);
    } else if (
      policy?.timeframeSelect === 'Month' &&
      months >= Number(policy?.timeframeValue)
    ) {
      finalAmount.push((amount * policy?.percent) / 100);
      //?convert month into hour  1 month=730 hour approx
      selectedPolicyHour.push(Number(policy?.timeframeValue) * 730);
    }
  });

  //?here we need to find the greater one
  if (selectedPolicyHour?.length > 0) {
    let policyindex = selectedPolicyHour.indexOf(
      Math.max(...selectedPolicyHour),
    );

    //   'selected policyindex: ',
    //   policyindex,
    //   selectedPolicyHour[policyindex],
    // );

    return finalAmount[policyindex];
  } else {
    // let lastpolicy = policies?.values[policies?.values?.length - 1];
    // return Number(amount * lastpolicy?.percent) / 100;
    return 0;
  }
};

export const checkForRedeem = (ticketData, event_type) => {
  //in current session or in all session then its redeem

  if (event_type === 'Physical') {
    if (ticketData) {
      if (ticketData?.is_verify) {
        return true;
      } else {
        //not redeem
        return false;
      }
    } else {
      return false;
    }
  } else {
    if (ticketData) {
      if (
        Object?.keys(ticketData?.current_session)?.length > 0 ||
        ticketData?.all_sessions?.length > 0
      ) {
        return true;
      } else {
        //not redeem
        return false;
      }
    } else {
      return false;
    }
  }
};

export const getIndex = (item) => {
  return item?.cart_seats?.seat_data?.findIndex(
    (seat) => seat?.seat_id === item.seat_id,
  );
};

export const stringconvertToNumber = (val) => {
  return Number(parseFloat(val?.toString()?.replace(/,/g, '')));
};

export const getVirtualTicketGrouped = (data) => {
  let filteredData = data?.booked_tickets?.filter(
    (data) =>
      !data?.is_cancel &&
      !data?.is_resold &&
      data?.is_resale !== true &&
      !data?.is_shared &&
      !checkForRedeem(
        data?.event_type === 'Physical'
          ? data?.cart_seats?.seat_data[getIndex(data)] || data
          : data?.streaming_data?.[0],
        data?.event_type,
      ),
  );
  let groupedData = filteredData.reduce((grouped, ticket) => {
    grouped[ticket?.category?.name] = grouped[ticket?.category?.name] || [];
    grouped[ticket?.category?.name].push(ticket);
    return grouped;
  }, {});
  let newArrayGroup = {};
  Object.entries(groupedData)?.map(([key1, value1], index1) => {
    let groupByPrice = value1?.reduce((grouped, ticket) => {
      grouped[ticket?.price] = grouped[ticket?.price] || [];
      grouped[ticket?.price].push(ticket);
      return grouped;
    }, {});
    newArrayGroup = { ...newArrayGroup, ...groupByPrice };
  });
  // return groupedData;
  return newArrayGroup;
};

export const checkResaleAllowed = (data) => {
  let resaleNotAllowed =
    data?.booked_tickets[0]?.tickets?.event?.policy?.filter(
      (obj) =>
        obj?.sub_policy_type === 'Resale Policies' &&
        obj?.currently_in_use &&
        !obj?.default_values,
    );

  if (resaleNotAllowed?.length > 0) {
    if (
      resaleNotAllowed[0]?.values[0]?.selected_resale_times_limit ===
      'not_allowed'
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const checkEventEnded = (enddate) => {
  if (Date.parse(moment(enddate)) < Date.parse(moment())) {
    return true;
  } else {
    return false;
  }
};

export const getValidColor = (ticket, color) => {
  return !checkEventEnded(ticket?.tickets?.event?.end_date_time)
    ? color
    : `bg-rose-600 text-white`;
};
export const getBgColor = (ticket, no_bg = false) => {
  if (no_bg) return null;
  if (
    !checkForRedeem(
      ticket?.event_type === 'Physical'
        ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
        : ticket?.streaming_data?.[0],
      ticket?.event_type,
    ) &&
    ticket?.is_shared
  ) {
    return getValidColor(ticket, `bg-sky-200 text-sky-700`);
  } else if (
    !checkForRedeem(
      ticket?.event_type === 'Physical'
        ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
        : ticket?.streaming_data?.[0],
      ticket?.event_type,
    ) &&
    ticket?.is_cancel
  ) {
    return `text-red-700 bg-red-100 dark:bg-[#302024] dark:text-[#E42855]`;
  } else if (
    !checkForRedeem(
      ticket?.event_type === 'Physical'
        ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
        : ticket?.streaming_data?.[0],
      ticket?.event_type,
    ) &&
    ticket?.is_resale &&
    !ticket?.is_resold
  ) {
    return getValidColor(ticket, `bg-pink-200/20 text-pink-700`);
  } else if (
    !checkForRedeem(
      ticket?.event_type === 'Physical'
        ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
        : ticket?.streaming_data?.[0],
      ticket?.event_type,
    ) &&
    ticket?.is_resale &&
    ticket?.is_resold
  ) {
    return getValidColor(ticket, `bg-yellow-200 text-yellow-700`);
  } else if (
    !checkForRedeem(
      ticket?.event_type === 'Physical'
        ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
        : ticket?.streaming_data?.[0],
      ticket?.event_type,
    ) &&
    ticket?.resale_ticket
  ) {
    return getValidColor(
      ticket,
      `text-green-2 bg-[#dfffea] dark:bg-[#1F212A] dark:text-green-2`,
    );
  } else if (
    checkForRedeem(
      ticket?.event_type === 'Physical'
        ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
        : ticket?.streaming_data?.[0],
      ticket?.event_type,
    )
  ) {
    return `bg-purple-200  text-purple-700`;
  } else {
    return getValidColor(
      ticket,
      `text-green-2 bg-[#dfffea] dark:bg-[#1F212A] dark:text-green-2`,
    );
  }
};

export const getTicketStatus = (ticket, from = null) => {
  return (
    <>
      {console.log('ticket', ticket)}
      <span className="flex">
        {/* when ticket is Shared by buyer */}
        {checkForRedeem(
          ticket?.event_type === 'Physical'
            ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
            : ticket?.streaming_data?.[0],
          ticket?.event_type,
        ) && (
          <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
            <span className={` px-1`}>Redeemed</span>
          </Badge>
        )}

        {!checkForRedeem(
          ticket?.event_type === 'Physical'
            ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
            : ticket?.streaming_data?.[0],
          ticket?.event_type,
        ) &&
          ticket?.is_shared &&
          (!checkEventEnded(ticket?.tickets?.event?.end_date_time) ? (
            <>
              {
                // from === "share_view" ?
                <Tooltip
                  title={
                    ticket?.is_claimed
                      ? 'Successfully claimed'
                      : ticket?.transfer_nft
                      ? 'Yet to be claimed'
                      : 'Shared Successfully'
                  }
                >
                  <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
                    <span className="px-1">
                      {ticket?.transfer_nft ? 'Transferred' : 'Shared'}
                    </span>
                  </Badge>
                </Tooltip>
                // :
                //  <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
                //   <span className="px-1">Transferred</span>
                // </Badge>
              }
            </>
          ) : (
            <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
              <span className="px-1">Expired</span>
            </Badge>
          ))}
        {/* when ticket is cancelled by buyer */}
        {!checkForRedeem(
          ticket?.event_type === 'Physical'
            ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
            : ticket?.streaming_data?.[0],
          ticket?.event_type,
        ) &&
          ticket?.is_cancel && (
            <Tooltip
              placement="top"
              title={`Cancelled At : ${moment(ticket?.canceled_at).format(
                DATEFORMAT,
              )}`}
            >
              <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
                <span className={`px-1`}>Cancelled</span>
              </Badge>
            </Tooltip>
          )}
        {!checkForRedeem(
          ticket?.event_type === 'Physical'
            ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
            : ticket?.streaming_data?.[0],
          ticket?.event_type,
        ) &&
          ticket?.is_resale &&
          !ticket?.is_resold && (
            <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
              <span className={`px-1`}>
                {!checkEventEnded(ticket?.tickets?.event?.end_date_time) ? (
                  <>On Resale</>
                ) : (
                  'Expired'
                )}
              </span>
            </Badge>
          )}
        {/* when ticket on resale in bought then in seller end will display*/}
        {!checkForRedeem(
          ticket?.event_type === 'Physical'
            ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
            : ticket?.streaming_data?.[0],
          ticket?.event_type,
        ) &&
          ticket?.is_resale &&
          ticket?.is_resold && (
            <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
              <span className={` px-1`}>
                {!checkEventEnded(ticket?.tickets?.event?.end_date_time)
                  ? 'Resold'
                  : 'Expired'}
              </span>
            </Badge>
          )}
        {/* when purchase ticket is resale ticket*/}
        {ticket?.resale_ticket &&
          !checkForRedeem(
            ticket?.event_type === 'Physical'
              ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
              : ticket?.streaming_data?.[0],
            ticket?.event_type,
          ) &&
          !(ticket?.is_resale && ticket?.is_resold) &&
          !(ticket?.is_resale && !ticket?.is_resold) &&
          !ticket?.is_cancel &&
          !ticket?.is_shared && (
            <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
              <span className={`px-1`}>Active (2nd+)</span>
            </Badge>
          )}
        {/* when resale is cancelled*/}
        {/* {ticket?.is_resale === false && !ticket?.is_cancel && (
              <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
                <span className={` px-1`}>Active</span>
              </Badge>
            )} */}
        {/* when none of the above condition applied then we add booked */}
        {
          // ticket?.is_resale !== false &&
          !checkForRedeem(
            ticket?.event_type === 'Physical'
              ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
              : ticket?.streaming_data?.[0],
            ticket?.event_type,
          ) &&
            !(ticket?.is_resale && ticket?.is_resold) &&
            !(ticket?.is_resale && !ticket?.is_resold) &&
            !ticket?.is_cancel &&
            !ticket?.is_shared &&
            !ticket?.resale_ticket &&
            ticket?.nft_metadata && (
              <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
                <span className={` px-1`}>
                  {!checkEventEnded(ticket?.tickets?.event?.end_date_time)
                    ? 'Active'
                    : 'Expired'}
                </span>
              </Badge>
            )
        }
        {
          // ticket?.is_resale !== false &&
          !checkForRedeem(
            ticket?.event_type === 'Physical'
              ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
              : ticket?.streaming_data?.[0],
            ticket?.event_type,
          ) &&
            !(ticket?.is_resale && ticket?.is_resold) &&
            !(ticket?.is_resale && !ticket?.is_resold) &&
            !ticket?.is_cancel &&
            !ticket?.is_shared &&
            !ticket?.resale_ticket &&
            !ticket?.nft_metadata && (
              <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
                <span className={` px-1`}>
                  {!checkEventEnded(ticket?.tickets?.event?.end_date_time)
                    ? 'Purchased'
                    : 'Expired'}
                </span>
              </Badge>
            )
        }
        {/* {ticket?.is_resale !== false &&
              !(ticket?.is_resale && ticket?.is_resold) &&
              !(ticket?.is_resale && !ticket?.is_resold) &&
              !ticket?.is_cancel &&
              !ticket?.is_shared && (
                <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
                  <span className={` px-1`}>Active</span>
                </Badge>
              )} */}
      </span>
    </>
  );
};

export const getTicketStatusWithoutBadge = (ticket, no_bg) => {
  return (
    <>
      <span className="flex">
        {/* when ticket is Shared by buyer */}
        {checkForRedeem(
          ticket?.event_type === 'Physical'
            ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
            : ticket?.streaming_data?.[0],
          ticket?.event_type,
        ) && (
          // <div className="flex items-center gap-1 py-1 px-2 rounded-xl bg-bluev2-500 text-whiteNew-0">
          //   <span className="font-medium text-xs flex justify-center gap-1 leading-tight">
          //     Redeemed
          //     {/* <span className="bg-blue-800 text-white text-[11px] min-w-[1rem] h-[1rem] flex justify-center items-center px-0.5 rounded-[4px] leading-tight">
          //                 +2
          //               </span> */}
          //   </span>
          // </div>
          <div
            className={` ${getBgColor(
              ticket,
              no_bg,
            )} text-xs px-2 mx-1 flex items-center gap-1 py-0.5 rounded-xl`}
          >
            {ticket?.transfer_booked_ticket_id && (
              <span className="">
                <MoveLocationIconOpp className={'text-base'} />
              </span>
            )}
            <div className={``}>
              <span className={`px-1`}>Redeemed</span>
            </div>
          </div>
        )}
        {/* DOUBTED COMMENT */}
        {/* {!checkForRedeem(ticket?.streaming_data?.[0]) &&
          ticket?.is_shared &&
          ticket?.is_claimed &&
          (!checkEventEnded(ticket?.tickets?.event?.end_date_time) ? (

            <div
              className={` bg-green-200 text-green-700 text-xs px-2 mx-1 flex items-center gap-1 py-1 rounded-xl`}
            >
              <span className={`flex items-center gap-1`}>
                <span>
                  <MoveLocationIcon className={'text-base'} />
                </span>
                Active 2nd+
              </span>
            </div>
          ) : (

            <div
              className={` ${getBgColor(
                ticket,
                no_bg
              )} text-xs px-2 mx-1 flex items-center gap-1 py-1 rounded-xl`}
            >
              <div className={``}>
                <span className={`px-1`}>ExpiredCLAIMSHARE</span>
              </div>
            </div>
          ))} */}

        {!checkForRedeem(
          ticket?.event_type === 'Physical'
            ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
            : ticket?.streaming_data?.[0],
          ticket?.event_type,
        ) &&
          ticket?.is_shared &&
          !ticket?.is_claimed &&
          !ticket?.transfer_booked_ticket_id && (
            // !checkEventEnded(ticket?.tickets?.event?.end_date_time) &&
            // <div className="flex items-center gap-1 py-1 px-2 rounded-xl bg-bluev2-500 text-whiteNew-0">
            //   <span className="font-medium text-xs flex justify-center gap-1 leading-tight">
            //     Active
            //     {/* <span className="bg-blue-800 text-white text-[11px] min-w-[1rem] h-[1rem] flex justify-center items-center px-0.5 rounded-[4px] leading-tight">
            //               +2
            //             </span> */}
            //   </span>
            // </div>
            // <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
            //   <span className="px-1">Active</span>
            // </Badge>
            <div
              className={`${getBgColor(
                ticket,
              )} text-xs px-2 mx-1 flex items-center gap-1 py-1 rounded-xl`}
            >
              <span className={`flex items-center gap-1`}>
                <span>
                  {/* <MoveLocationIcon className={'text-base'} /> */}
                </span>
                {!checkEventEnded(ticket?.tickets?.event?.end_date_time)
                  ? 'Active'
                  : 'Expired'}
              </span>
            </div>
          )}
        {/* when ticket is cancelled by buyer */}
        {!checkForRedeem(
          ticket?.event_type === 'Physical'
            ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
            : ticket?.streaming_data?.[0],
          ticket?.event_type,
        ) &&
          ticket?.is_cancel && (
            <Tooltip
              placement="top"
              title={`Cancelled At : ${moment(ticket?.canceled_at).format(
                DATEFORMAT,
              )}`}
            >
              {/* <div className="flex items-center gap-1 py-1 px-2 rounded-xl bg-bluev2-500 text-whiteNew-0">
                <span className="font-medium text-xs flex justify-center gap-1 leading-tight">
                  Cancelled
                  {/* <span className="bg-blue-800 text-white text-[11px] min-w-[1rem] h-[1rem] flex justify-center items-center px-0.5 rounded-[4px] leading-tight">
                          +2
                        </span> */}
              {/* </span>
              </div> */}
              <div
                className={` ${getBgColor(
                  ticket,
                  no_bg,
                )} text-xs px-2 mx-1 flex items-center gap-1 py-1 rounded-xl`}
              >
                <div className={``}>
                  <span className={`px-1`}>Cancelled</span>
                </div>
              </div>
              {/* <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
              <span className={`px-1`}>Cancelled</span>
            </Badge> */}
            </Tooltip>
          )}
        {!checkForRedeem(
          ticket?.event_type === 'Physical'
            ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
            : ticket?.streaming_data?.[0],
          ticket?.event_type,
        ) &&
          ticket?.is_resale &&
          !ticket?.is_resold && (
            // <div className="flex items-center gap-1 py-1 px-2 rounded-xl bg-bluev2-500 text-whiteNew-0">
            //   <span className="font-medium text-xs flex justify-center gap-1 leading-tight">
            //     {!checkEventEnded(ticket?.tickets?.event?.end_date_time) ? (
            //       <>Active</>
            //     ) : (
            //       'Expired'
            //     )}
            //     {/* <span className="bg-blue-800 text-white text-[11px] min-w-[1rem] h-[1rem] flex justify-center items-center px-0.5 rounded-[4px] leading-tight">
            //               +2
            //             </span> */}
            //   </span>
            // </div>
            // <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
            //   <span className={`px-1`}>
            //     {!checkEventEnded(ticket?.tickets?.event?.end_date_time) ? (
            //       <>Active</>
            //     ) : (
            //       'Expired'
            //     )}
            //   </span>
            // </Badge>
            <div
              className={` ${getBgColor(
                ticket,
                no_bg,
              )} text-xs px-2 mx-1 flex items-center gap-1 py-1 rounded-xl`}
            >
              <div className={``}>
                <span className={`px-1`}>
                  {!checkEventEnded(ticket?.tickets?.event?.end_date_time) ? (
                    <>Active</>
                  ) : (
                    'Expired'
                  )}
                </span>
              </div>
            </div>
          )}
        {/* when ticket on resale in bought then in seller end will display*/}
        {!checkForRedeem(
          ticket?.event_type === 'Physical'
            ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
            : ticket?.streaming_data?.[0],
          ticket?.event_type,
        ) &&
          ticket?.is_resale &&
          ticket?.is_resold && (
            // <div className="flex items-center gap-1 py-1 px-2 rounded-xl bg-bluev2-500 text-whiteNew-0">
            //   <span className="font-medium text-xs flex justify-center gap-1 leading-tight">
            //     {!checkEventEnded(ticket?.tickets?.event?.end_date_time)
            //       ? 'Resold Ticket'
            //       : 'Expired'}
            //     {/* <span className="bg-blue-800 text-white text-[11px] min-w-[1rem] h-[1rem] flex justify-center items-center px-0.5 rounded-[4px] leading-tight">
            //               +2
            //             </span> */}
            //   </span>
            // </div>
            // <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
            //   <span className={` px-1`}>
            //     {!checkEventEnded(ticket?.tickets?.event?.end_date_time)
            //       ? 'Resold Ticket'
            //       : 'Expired'}
            //   </span>
            // </Badge>
            <div
              className={` ${getBgColor(
                ticket,
                no_bg,
              )} text-xs px-2 mx-1 flex items-center gap-1 py-1 rounded-xl`}
            >
              <div className={``}>
                <span className={`px-1`}>
                  <span className={` px-1`}>
                    {!checkEventEnded(ticket?.tickets?.event?.end_date_time)
                      ? 'Resold'
                      : 'Expired'}
                  </span>
                </span>
              </div>
            </div>
          )}
        {/* when purchase ticket is resale ticket*/}
        {ticket?.resale_ticket &&
          !checkForRedeem(
            ticket?.event_type === 'Physical'
              ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
              : ticket?.streaming_data?.[0],
            ticket?.event_type,
          ) &&
          !(ticket?.is_resale && ticket?.is_resold) &&
          !(ticket?.is_resale && !ticket?.is_resold) &&
          !ticket?.is_cancel &&
          !ticket?.is_shared && (
            // <div className="flex items-center gap-1 py-1 px-2 rounded-xl bg-bluev2-500 text-whiteNew-0">
            //   <span className="font-medium text-xs flex justify-center gap-1 leading-tight">
            //     Active (2nd+)
            //     {/* <span className="bg-blue-800 text-white text-[11px] min-w-[1rem] h-[1rem] flex justify-center items-center px-0.5 rounded-[4px] leading-tight">
            //               +2
            //             </span> */}
            //   </span>
            // </div>
            // <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
            //   <span className={`px-1`}>Active (2nd+)</span>
            // </Badge>
            <div
              className={` ${getBgColor(
                ticket,
                no_bg,
              )} text-xs px-2 mx-1 flex items-center gap-1 py-1 rounded-xl`}
            >
              <div className={``}>
                <span className={`px-1`}>
                  {!checkEventEnded(ticket?.tickets?.event?.end_date_time)
                    ? 'Active (2nd+)'
                    : 'Expired'}
                </span>
              </div>
            </div>
          )}
        {!checkForRedeem(
          ticket?.event_type === 'Physical'
            ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
            : ticket?.streaming_data?.[0],
          ticket?.event_type,
        ) &&
          ((ticket?.is_claimed &&
            ticket?.is_shared &&
            ticket?.transfer_booked_ticket_id) ||
            (ticket?.is_claimed && ticket?.is_shared) ||
            ticket?.transfer_booked_ticket_id) && (
            <div
              className={` ${getBgColor(
                ticket,
                no_bg,
              )} text-xs px-2 mx-1 flex items-center gap-1 py-1 rounded-xl`}
            >
              <div className={``}>
                <span className={`px-1 flex items-center gap-1`}>
                  <span className="">
                    <MoveLocationIconOpp className={'text-base'} />
                  </span>
                  {!checkEventEnded(ticket?.tickets?.event?.end_date_time)
                    ? 'Active (2nd+)'
                    : 'Expired'}
                </span>
              </div>
            </div>
          )}
        {/* when resale is cancelled*/}
        {/* {ticket?.is_resale === false && !ticket?.is_cancel && (
              <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
                <span className={` px-1`}>Active</span>
              </Badge>
            )} */}
        {/* when none of the above condition applied then we add booked */}
        {
          // ticket?.is_resale !== false &&
          !checkForRedeem(
            ticket?.event_type === 'Physical'
              ? ticket?.cart_seats?.seat_data[getIndex(ticket)] || ticket
              : ticket?.streaming_data?.[0],
            ticket?.event_type,
          ) &&
            !(ticket?.is_resale && ticket?.is_resold) &&
            !(ticket?.is_resale && !ticket?.is_resold) &&
            !ticket?.is_cancel &&
            !ticket?.is_shared &&
            !ticket?.resale_ticket &&
            !ticket?.transfer_booked_ticket_id && (
              // <div className="flex items-center gap-1 py-1 px-2 rounded-xl bg-bluev2-500 text-whiteNew-0">
              //   <span className="font-medium text-xs flex justify-center gap-1 leading-tight">
              //     {!checkEventEnded(ticket?.tickets?.event?.end_date_time)
              //       ? 'Active'
              //       : 'Expired'}
              //     {/* <span className="bg-blue-800 text-white text-[11px] min-w-[1rem] h-[1rem] flex justify-center items-center px-0.5 rounded-[4px] leading-tight">
              //               +2
              //             </span> */}
              //   </span>
              // </div>
              // <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
              //   <span className={` px-1`}>
              //     {!checkEventEnded(ticket?.tickets?.event?.end_date_time)
              //       ? 'Active'
              //       : 'Expired'}
              //   </span>
              // </Badge>
              <div
                className={` ${getBgColor(
                  ticket,
                  no_bg,
                )} text-xs px-2 mx-1 flex items-center gap-1 py-1 rounded-xl`}
              >
                <div className={``}>
                  <span className={`px-1`}>
                    {!checkEventEnded(ticket?.tickets?.event?.end_date_time)
                      ? 'Active'
                      : 'Expired'}
                  </span>
                </div>
              </div>
            )
        }

        {/* {ticket?.is_resale !== false &&
              !(ticket?.is_resale && ticket?.is_resold) &&
              !(ticket?.is_resale && !ticket?.is_resold) &&
              !ticket?.is_cancel &&
              !ticket?.is_shared && (
                <Badge className={` ${getBgColor(ticket)} text-xs px-2 mx-1`}>
                  <span className={` px-1`}>Active</span>
                </Badge>
              )} */}
      </span>
    </>
  );
};
