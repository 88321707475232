import React from 'react';
import { afterBorder, primaryBtnClass } from '../../common/DefaultClasses';
import { CopyIcon, CustomWalletIcon, EthIcon } from '../../util/CustomIcons';
import { CiUnlock } from 'react-icons/ci';
import { BsPlus } from 'react-icons/bs';
import CurrencyFormat from 'react-currency-format';
import TableComponentv2 from '../../common/TableComponentv2';
import Tooltip from '../../common/Tooltip';
import { truncate3 } from '../../../utils/util';
import { Table } from 'antd';

export default function ExternalWalletInfo() {
  const dataSource = [
    {
      key: '1',
      payment_id: (
        <>
          <span className="flex gap-2 iems-center">
            <span className="text-bluev2-600 w-full max-w-[80px]">
              <Tooltip title={'pay_McDNEYpjl2DtGL'}>
                {truncate3('pay_McDNEYpjl2DtGL', 4)}
              </Tooltip>
            </span>
            <span>
              <span className="text-bluev2-600 bg-blue-200 flex py-0 text-sm px-2 rounded-full">
                Internal
              </span>
            </span>
          </span>
        </>
      ),
      ticket_id: (
        <>
          <span>64dbd3....6df0ef</span>
        </>
      ),
      wallet_add: (
        <>
          <span>0x98....7620</span>
        </>
      ),
      transaction_fee: <div className="pr-2">0.033ETH</div>,
      signature: (
        <>
          <span className="flex">0x4cbf....8f1f45</span>
        </>
      ),
      Date: '03-10-2023 04:06:49 pm',
      Status: (
        <>
          <span className="flex">
            <span
              className={`text-green-700 bg-green-200
                    flex py-0 px-2 rounded-full`}
            >
              + Credited to USD Wallet
            </span>
          </span>
        </>
      ),
    },
  ];

  const columns = [
    {
      title: <span className="text-gray-500">Payment Id</span>,
      dataIndex: 'payment_id',
      key: 'payment_id',
      width: '200px',
    },
    // {
    //   title: <span className="text-gray-500">Wallet Type</span>,
    //   dataIndex: 'wallet_type',
    //   key: 'wallet_type',
    //   width: '400',
    // },
    {
      title: <span className="text-gray-500">Ticket Id</span>,
      dataIndex: 'ticket_id',
      key: 'ticket_id',
      width: '250px',
    },
    {
      title: <span className="text-gray-500">Minting Address</span>,
      dataIndex: 'wallet_add',
      key: 'wallet_add',
      width: '150px',
    },
    {
      title: (
        <span className="text-gray-500 inline-block">Transaction Fee</span>
      ),
      dataIndex: 'transaction_fee',
      key: 'transaction_fee',
      width: '150px',
      align: 'right',
    },
    {
      title: <span className="text-gray-500">Signature</span>,
      dataIndex: 'signature',
      key: 'signature',
      width: '200px',
    },
    // {
    //   title: <span className="text-gray-500">Mint Address</span>,
    //   dataIndex: 'mint_add',
    //   key: 'mint_add',
    //   width: '200',
    // },
    {
      title: <span className="text-gray-500">Created At</span>,
      dataIndex: 'Date',
      key: 'Date',
      width: '200px',
    },
    {
      title: <span className="text-gray-500">Status</span>,
      dataIndex: 'Status',
      key: 'Status',
      width: '200px',
    },
    // {
    //   title: <span className="text-gray-900">Action</span>,
    //   dataIndex: 'Action',
    //   key: 'Action',
    //   width: '50',
    // },
    // {
    //   title: <span className="text-gray-500">Invoice</span>,
    //   dataIndex: 'Invoice',
    //   key: 'Invoice',
    // },
  ];
  return (
    <React.Fragment>
      <div className="relative p-3 bg-white">
        <div className={`${afterBorder}`}>
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <div className="flex gap-2 items-center py-1">
                <div>
                  <div className="w-8 h-8 rounded-full">
                    <EthIcon className={'w-full h-full p-1'} />
                  </div>
                </div>
                <div className="flex flex-col justify-end text-left">
                  <div className="flex items-center gap-2">
                    <span className="text-lg font-medium">EHaC...qs2a</span>
                    <span className="text-bluev2-600 text-lg">
                      <CopyIcon />
                    </span>
                    {/* <span className="text-green-600 text-lg">
                      <CiUnlock />
                    </span> */}
                    <span className="bg-green-200 text-green-700 px-2 rounded-md text-xs">
                      Primary
                    </span>
                  </div>
                  {/* <span className="font-normal">ETHEREUM</span> */}
                </div>
                <div></div>
              </div>
            </div>
            <div>
              <button className={`${primaryBtnClass}`}>
                <span>
                  <BsPlus className="text-lg" />
                </span>
                Add New Wallet
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="block overflow-hidden" style={{ flex: '0 0 75%' }}>
        <div className="w-full">
          <TableComponentv2
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            // scroll={{
            //   x: 1200,
            //   y: 500,
            // }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
