import React, { useState, useRef, useMemo, useCallback } from 'react';
import {
  AnimatePing,
  FullScreenIcon,
  LogoGoTix,
  // StreamingPlayIconCircle,
  StreamingTicketIcon,
} from '../../../components/util/CustomIcons';
import { useStreamAPI, useStreamLounge } from '../hooks/useStreamLounge';
import ExitEvent from '../ExitEvent/ExitEvent';
import { usePlayer } from '../hooks/usePlayer';
import {
  RxSpeakerLoud,
  RxSpeakerModerate,
  RxSpeakerOff,
  RxSpeakerQuiet,
} from 'react-icons/rx';
import ReactPlayer from 'react-player';
import { Popover, Radio, Space } from 'antd';
// import { FaPause, FaPlay } from 'react-icons/fa';
import {
  BsFillPlayFill,
  BsPip,
  BsPipFill,
  BsThreeDotsVertical,
} from 'react-icons/bs';
import Duration from './Duration';
import { Col, Row } from 'react-bootstrap';
import { MdPause, MdPlayArrow } from 'react-icons/md';
import TimeoutStrip from '../../../components/stream_lounge_components/TimeoutStrip';
import NFTDetailDrawer from '../NFTDetailDrawer';
import { useSelector } from 'react-redux';
import { useDrawerScreens } from '../hooks/useDrawer';
import Tooltip from '../../../components/common/Tooltip';
import { portalTexts } from '../../../utils/LoaderMessage';

function LiveStream() {
  const { authorize_ticket, isObjectEmpty, event_ended } = useStreamLounge();
  const { ticket_details } = authorize_ticket;
  const [isExited, setIsExiting] = useState(false);
  const stream_nft = useSelector((s) => s.stream.stream_nft_details);
  // const [showSeekbar, setShowSeekbar] = useState(false);
  const playerWrapperRef = useRef(null);
  const { ticket_status } = useStreamAPI();
  const { onOpenNFTDetails } = useDrawerScreens();
  console.log(ticket_details);
  const {
    state,
    player,
    handlePlay,
    handleEnablePIP,
    handleDisablePIP,
    handlePause,
    handleOnPlaybackRateChange,
    handleEnded,
    handleDuration,
    handleProgress,
    handleToggleMuted,
    handleClickFullscreen,
    handleVolumeChange,
    handleSetPlaybackRate,
    handlePlayPause,
    handleTogglePIP,
    handleToggleVolumeSlider,
    handleTogglePlaybackPanel,
    handleToggleShowCustomControls,
  } = usePlayer();

  const {
    url,
    pip,
    playing,
    controls,
    light,
    volume,
    muted,
    played,
    // loaded,
    duration,
    playbackRate,
    loop,
    isShowPlaybackPanel,
    isShowVolumeSlider,
    isShowCustomControls,
  } = state;
  // const { categoryDetails, ticket_access_code } = current_ticket;

  const parsedUrl = useMemo(() => {
    if (String(url).startsWith('<iframe')) {
      var tempContainer = document.createElement('div');

      // Set the string as innerHTML of the container
      tempContainer.innerHTML = url;

      // Return the first <iframe> element found in the container
      return tempContainer.querySelector('iframe')?.src;
    }
    return url;
  }, [url]);

  const handleExitEvent = useCallback(() => {
    setIsExiting(true);
  }, []);

  const isTerminated = useMemo(() => {
    return (
      typeof ticket_status?.data?.status === 'boolean' &&
      !ticket_status?.data?.status
    );
  }, [ticket_status?.data?.status]);

  // useEffect(() => {
  //   const unloadCallback = (event) => {
  //     event.preventDefault();
  //     event.returnValue = '';
  //     handleExitEventOnPage();
  //   };

  //   window.addEventListener('beforeunload', handleExitEventOnPage);
  //   return () => {
  //     window.removeEventListener('beforeunload', unloadCallback);
  //   };
  // }, [handleExitEventOnPage]);

  if (isExited || event_ended.current) {
    return (
      <ExitEvent setIsExiting={setIsExiting} ticket_details={ticket_details} />
    );
  }

  // const isMouseInBound = (e) => {
  //   const eleBounds = playerWrapperRef.current.getBoundingClientRect();
  //   if (e.clientX >= eleBounds.left && e.clientX <= eleBounds.right) {

  //     setShowSeekbar(true);
  //   } else {

  //     setShowSeekbar(false);
  //     // setTimeout(() => setShowSeekbar(false), 3000);
  //   }
  // };

  const PlayAndPause = ({ size, className }) => {
    return (
      <button size={size} onClick={handlePlayPause} className={className}>
        {playing ? <MdPause size={size} /> : <MdPlayArrow size={size} />}
      </button>
    );
  };

  const PipMode = ({ size, className }) => {
    return (
      <button size={size} onClick={handleTogglePIP} className={className}>
        {!pip ? <BsPip size={size} /> : <BsPipFill size={size} />}
      </button>
    );
  };

  return (
    <div className="bg-neutral-900 overflow-hidden">
      {isTerminated ? <TimeoutStrip /> : null}
      <div className="absolute bottom-[50px] z-20 md:bottom-20 w-full">
        {isShowCustomControls ? (
          <div className="relative container mb-3 px-7">
            <div className="relative w-full ">
              <div className="h-[3px]  rounded-full  bg-white w-full relative bottom-2 z-40">
                <div
                  style={{
                    width: `${
                      !isFinite(duration) ? '100' : (played * 100).toFixed(0)
                    }%`,
                  }} // Don't use tailwind css className here it won't work. It will be always in-line 😊
                  className={`h-[3px] bg-rose-600`}
                ></div>
                <Popover
                  placement="top"
                  arrow={false}
                  content={
                    <Duration
                      className={'text-gray-700 font-semibold'}
                      seconds={state?.playedSeconds}
                    />
                  }
                >
                  <div
                    style={{
                      left: `${
                        !isFinite(duration) ? '100' : (played * 100).toFixed(0)
                      }%`,
                    }} // Don't use tailwind css className here. It will be always in-line 😊
                    className={`hover:scale-[1.1] duration-300 ease-in cursor-pointer h-5 w-5 bg-rose-800 absolute top-1/2 -translate-y-1/2 z-40 rounded-full left-[${(
                      played * 100
                    ).toFixed(0)}%] -translate-x-1/2`}
                  ></div>
                </Popover>
              </div>
              <div className="my-1">
                <div className="flex justify-between gap-2 items-center w-full">
                  <div>
                    {/* <span className="bg-neutral-800 px-2 py-1 rounded-md">
                    <Duration
                      className={'bg-gray-200'}
                      seconds={state?.playedSeconds}
                    />
                  </span> */}
                  </div>
                  <div className="bg-neutral-800 px-2 py-1 rounded-md">
                    <span className="cursor-pointer font-semibold flex gap-2 items-center text-rose-700">
                      <span className="relative flex h-2 w-2">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-rose-600 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-2 w-2 bg-rose-700"></span>
                      </span>
                      LIVE
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <header className="absolute w-full py-3.5 lg:py-7 z-10">
        <div className="container mx-auto px-7">
          <div className="">
            <Row>
              <Col md={12}>
                <div className="flex justify-between gap-2">
                  <div className="flex-shrink-0 relative h-12 w-28 sm:w-36 xl:w-56">
                    <LogoGoTix
                      className={'w-full text-white absolute inset-0 h-full'}
                    />
                  </div>
                  <div className=" flex items-center">
                    <div className="flex md:hidden items-center gap-2">
                      <div>
                        <button className="btn-strp">
                          <StreamingTicketIcon />
                        </button>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-white font-semibold">
                          {ticket_details?.access_id ??
                            ticket_details?.ticket_id}
                        </span>
                        <span className=" text-[12px] text-white">
                          {ticket_details?.category}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </header>
      {/* Streaming Player */}
      <div
        ref={playerWrapperRef}
        className="player-wrapper bg-neutral-900 max-h-screen  absolute h-full z-0 w-full bg-center inset-0 overflow-hidden"
      >
        <ReactPlayer
          ref={player}
          className="react-player"
          width="100%"
          height="100%"
          url={parsedUrl}
          pip={pip}
          playing={playing}
          controls={controls}
          light={light}
          loop={loop}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          playIcon={
            <>
              <Tooltip title={'Click to Play'} placement={'top'}>
                <span className="relative flex h-14 w-14">
                  <AnimatePing className={'bg-rose-600 opacity-80'} />
                  <span
                    className={`bg-rose-600 rounded-full w-14 h-14 flex items-center justify-center`}
                  >
                    {/* <StreamingPlayIconCircle /> */}
                    <BsFillPlayFill className="text-3xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-whiteNew-0 ml-[2px]" />
                  </span>
                </span>
              </Tooltip>
            </>
          }
          onReady={handleToggleShowCustomControls}
          onStart={handleToggleShowCustomControls}
          onPlay={handlePlay}
          onEnablePIP={handleEnablePIP}
          onDisablePIP={handleDisablePIP}
          onPause={handlePause}
          onBuffer={(e) => console.log('onBuffer', e)}
          onPlaybackRateChange={handleOnPlaybackRateChange}
          onSeek={(e) => console.log('onSeek', e)}
          onEnded={handleEnded}
          onError={(e) => console.log('onError', e)}
          onProgress={handleProgress}
          onDuration={handleDuration}
          onPlaybackQualityChange={(e) =>
            console.log('onPlaybackQualityChange', e)
          }
        />
      </div>

      <div className="btm-strip fixed w-full flex bottom-0 z-50">
        <div className="container mx-auto py-[12px] px-[27px] md:py-4 flex items-between md:justify-between flex-col md:flex-row">
          <div className=" flex items-center">
            <div className="hidden md:flex items-center gap-2">
              <div>
                <button className="btn-strp">
                  <StreamingTicketIcon />
                </button>
              </div>
              <div className="flex flex-col">
                <span className="text-white font-semibold">
                  {ticket_details?.access_id ?? ticket_details?.ticket_id}
                </span>
                <span className=" text-[12px] text-white">
                  {ticket_details?.category}
                </span>
              </div>
            </div>
          </div>

          <div className=" flex items-center gap-2 justify-end">
            {isShowCustomControls ? (
              <>
                <div>
                  <PlayAndPause size={20} className={'btn-strp text-white'} />
                </div>
                <div>
                  <Popover
                    placement="top"
                    arrow={false}
                    open={isShowVolumeSlider}
                    onOpenChange={handleToggleVolumeSlider}
                    content={
                      <>
                        <input
                          type="range"
                          min={0}
                          max={1}
                          step="any"
                          value={volume}
                          onChange={handleVolumeChange}
                          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                        />
                      </>
                    }
                  >
                    <button
                      size={25}
                      onClick={handleToggleMuted}
                      className={'btn-strp text-white'}
                    >
                      {muted || volume === 0 ? (
                        <RxSpeakerOff size={20} />
                      ) : !muted ? (
                        <>
                          {volume <= 0.3 ? (
                            <RxSpeakerQuiet size={20} />
                          ) : volume <= 0.8 ? (
                            <RxSpeakerModerate size={20} />
                          ) : (
                            <RxSpeakerLoud size={20} />
                          )}
                        </>
                      ) : null}
                    </button>
                  </Popover>
                </div>
                <div className="hidden md:flex">
                  <Popover
                    placement="top"
                    arrow={false}
                    open={isShowPlaybackPanel}
                    onOpenChange={handleTogglePlaybackPanel}
                    content={
                      <>
                        <Radio.Group
                          onChange={handleSetPlaybackRate}
                          value={playbackRate}
                        >
                          <Space direction="vertical">
                            {[1, 1.5, 1.75, 2].map((speed, index) => (
                              <>
                                <Radio
                                  defaultChecked={index === 0}
                                  key={speed}
                                  value={speed}
                                >
                                  <span className="font-semibold text-color-primary">
                                    {speed} x
                                  </span>
                                </Radio>
                              </>
                            ))}
                          </Space>
                        </Radio.Group>
                      </>
                    }
                  >
                    <button className={'btn-strp text-white'}>
                      <span className="font-medium text-[13px] flex px-0.5">
                        {playbackRate} x
                      </span>
                    </button>
                  </Popover>
                </div>
                <div>
                  <button onClick={handleClickFullscreen} className="btn-strp">
                    <FullScreenIcon className={'text-[21px]'} size={20} />
                  </button>
                </div>
                {ReactPlayer.canEnablePIP(url) ? (
                  <div>
                    <PipMode size={20} className={'btn-strp text-white'} />
                  </div>
                ) : null}
              </>
            ) : null}

            <div>
              <button
                disabled={isTerminated}
                onClick={handleExitEvent}
                className="btn-exit-event font-medium flex items-center"
              >
                Exit Event
              </button>
            </div>
            {!isObjectEmpty(stream_nft) ? (
              <div>
                <button
                  className="text-white font-medium flex items-center bg-[#4d4545] p-[8px] rounded-[10px]"
                  onClick={onOpenNFTDetails}
                >
                  View {portalTexts?.nTkt}
                </button>
              </div>
            ) : null}

            <span className="flex">
              <span className="flex w-1 min-h-[34px] bg-neutral-400"></span>
            </span>
            <div>
              <button className="text-white font-medium flex items-center bg-[#4d4545] p-[8px] rounded-[10px]">
                <span className="hidden md:flex">More Actions</span>
                <span className="flex md:hidden text-lg">
                  <BsThreeDotsVertical />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* View NFT Drawer */}
      <NFTDetailDrawer />
    </div>
  );
}

export default LiveStream;
