import React, { memo, useCallback, useRef } from 'react';
import { Form } from 'react-bootstrap';
import {
  CustomLocationIcon,
  CustomSearchIcon,
} from '../../../util/CustomIcons';
import Button from '../../../common/Button';
// import Image from '../../../common/Image';
// import pic1 from '../../../../assets/images/background/city1.png';
// import pic2 from '../../../../assets/images/background/city2.png';
// import pic3 from '../../../../assets/images/background/city3.png';
// import pic4 from '../../../../assets/images/background/city4.png';
// import pic5 from '../../../../assets/images/background/city5.png';
// import pic6 from '../../../../assets/images/background/city6.png';
import { useMemo } from 'react';
import { AllUSAstates } from '../../../../utils/USAstates';
// import { useQuery } from '@tanstack/react-query';
// import { locations } from '../../../../helpers/api/event/tickets/tickets';
import { useApiHeader } from '../../../../hooks/useDecodedToken';
import { useDispatch, useSelector } from 'react-redux';
import { useUserLocationHooks } from '../../../../pages/landing/home_page/hooks/useUserCurrentLocation';
// import Modal from '../../../common/Modal';
// import { getPopularStates } from '../../../../helpers/api/event/event';
import {
  updateLocation,
  // updatePopularStates,
} from '../../../../features/home_page/homepage.slice';
import { useHeaderHooks } from '../../../../pages/landing/home_page/hooks/useHeaderPage';
import { DataLoader } from '../../../common/Loaders';
import loaderMessage from '../../../../utils/LoaderMessage';
import useLodashDebounce from '../../../../hooks/useLodashDebounce';
import _debounce from 'lodash/debounce';
import { splitArrayIntoColumns } from '../../../../utils/util';
import { MdOutlineMyLocation } from 'react-icons/md';
import { formClass } from '../../../common/DefaultClasses';

function SelectCountryDrawerCompo({ onClose, open }) {
  //datas from redux storing the popular states
  let popularStates = useSelector((state) => state?.popularStates);
  //Function to get the fetch location of the user.
  let { getCurrentLocation } = useUserLocationHooks(onClose);
  const [search, setSearch] = React.useState('');
  // storing all states of usa in useState for filtering purpose.
  const [allStates, setAllStates] = React.useState(AllUSAstates);
  let dispatch = useDispatch();
  // Inputref is for input values.
  const inputRef = useRef(null);

  // Involke the popular state API.
  const { PopularStatesdetails } = useHeaderHooks(search);

  /// dividing the states in to three columns.
  const Statescolumns = useMemo(
    () => splitArrayIntoColumns(allStates, 3),
    [allStates],
  );

  //Callback function used for debouncing purpose.

  const onChangeCallBack = (e) => {
    setSearch(e.target.value);
    let filter = AllUSAstates.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    if (e.target.value === '') {
      setAllStates(AllUSAstates);
      PopularStatesdetails?.mutate(['Popular_States', '']);
      // dispatch(updatePopularStates(data));
    } else {
      if (filter !== undefined) {
        setAllStates(filter);
      } else {
        setAllStates([]);
      }
    }
  };

  // Debounce the search function with a delay of 1000 milliseconds
  const { debouncedSearch } = useLodashDebounce(onChangeCallBack, 1000);

  const OnchangeText = useCallback(
    (e) => {
      debouncedSearch(e);
    },
    [debouncedSearch],
  );

  // function used to set the user selected location from popular or other states.
  const setLocation = useCallback(
    (data, from) => {
      console.log('from', data, from);
      if (from === 'popular') {
        let selectedCountry = AllUSAstates.find(
          (obj) => obj.code === data || obj.name === data,
        )?.name;
        localStorage.setItem(
          'CurrentLocation',
          JSON.stringify(selectedCountry),
        );
        dispatch(updateLocation(selectedCountry));
      } else {
        localStorage.setItem('CurrentLocation', JSON.stringify(data));
        dispatch(updateLocation(data));
      }
      onClose();
    },
    [dispatch, onClose],
  );

  // Effect to clear the input when the component unmounts
  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
      setSearch('');
      setAllStates(AllUSAstates);
    }
  }, [open]);

  ///Contain popular states and other states code.

  const statesDrawer = useCallback(() => {
    return (
      <>
        {PopularStatesdetails?.isLoading && (
          <DataLoader
            message={loaderMessage?.popular_states}
            minHeight={'min-h-[250px]'}
            darkTheme
          />
        )}
        {popularStates?.popular_states?.[0]?.length > 0 && (
          <>
            <div className="flex flex-row mt-3">
              <div className="text-sm text-center py-2 flex flex-col w-full items-start justify-start relative">
                <span className="dark:bg-color-base-1 bg-whiteNew-0 text-color-primary relative z-10 px-2 font-semibold">
                  Popular States
                </span>
                <span className="flex w-full h-[1px] dark:bg-zinc-700 bg-gray-200 absolute left-0 top-1/2 -translate-y-1/2"></span>
              </div>
            </div>
            {popularStates?.popular_states?.map((States, index) => (
              <div className="flex flex-row gap-3.5 mt-3" key={index}>
                {States?.map((state, idx) => (
                  <div class="basis-1/2" key={idx}>
                    <button
                      onClick={() => {
                        setLocation(state?._id, 'popular');
                      }}
                      className="dark:bg-color-base-2 dark:hover:bg-opacity-60 bg-slate-100 bg-opacity-30 flex justify-center py-2 px-1.5 dark:text-whiteNew-0 text-sky-500 font-medium rounded-[4px] w-full hover:bg-opacity-80 border-sky-100 border-1 dark:border-none"
                    >
                      <span>
                        {
                          AllUSAstates.find(
                            (obj) =>
                              obj.code === state?._id ||
                              obj.name === state?._id,
                          )?.name
                        }
                      </span>
                    </button>
                  </div>
                ))}
              </div>
            ))}
          </>
        )}

        <div className="flex flex-row mt-3">
          <div className="text-gray-50 text-sm text-center py-2 flex flex-col w-full items-start justify-start relative">
            <span className="dark:bg-color-base-1 bg-whiteNew-0 relative z-10 px-2 font-semibold text-zinc-900 dark:text-whiteNew-0">
              Other States
            </span>
            <span className="flex w-full h-[1px] dark:bg-zinc-700 bg-gray-200 absolute left-0 top-1/2 -translate-y-1/2"></span>
          </div>
        </div>
        {Statescolumns?.[0]?.length > 0 ? (
          <>
            <div className="flex flex-row mt-2">
              {Statescolumns?.map((states, index) => (
                <div class="basis-1/3 pe-2" key={index}>
                  <ul className="dark:text-zinc-400 text-zinc-400">
                    {states?.map((state, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setLocation(state?.name);
                        }}
                      >
                        <a className="dark:hover:text-white hover:text-sky-600">
                          {state?.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row mt-3">
              <span className="text-white">
                We could not find what you were looking for.
              </span>
            </div>
          </>
        )}
      </>
    );
  }, [PopularStatesdetails]);

  ///Memoising popular states and other states code.
  const StatesComponent = memo(statesDrawer);

  return (
    <div>
      <Form className="w-full">
        <Form.Group className="relative w-full" controlId="">
          <Form.Control
            ref={inputRef}
            type="text"
            onChange={OnchangeText}
            placeholder="Search City"
            className={`${formClass} pl-6`}
          />
          <div className="flex absolute inset-y-0 left-0">
            <button
              className="p-1.5 dark:text-whiteNew-0 text-sky-600 h-full rounded-md flex justify-center items-center text-base"
              type="button"
            >
              <CustomSearchIcon />
            </button>
          </div>
          <div className="flex absolute inset-y-0 right-3">
            <Button
              className="dark:text-whiteNew-0 text-zinc-900"
              onClick={() => {
                getCurrentLocation();
              }}
            >
              <MdOutlineMyLocation />
            </Button>
          </div>
        </Form.Group>
      </Form>
      <StatesComponent />
    </div>
  );
}

export default SelectCountryDrawerCompo;
