import React, { useMemo } from 'react';
import { Button, notification, Space } from 'antd';
import { primaryBtnClassGradient } from '../components/common/DefaultClasses';
import { useLocation } from 'react-router-dom';
export const NotificationControllerContext = React.createContext();

export const NotificationProvider = ({ children }) => {
  const [enabled, setEnabled] = React.useState(false);
  const [threshold, setThreshold] = React.useState(0);
  const [isOpened, setIsopened] = React.useState(false);
  const location = useLocation();

  const [api, contextHolder] = notification.useNotification({
    stack: enabled
      ? {
          threshold,
        }
      : false,
  });

  if (location.pathname !== '/checkout') {
    api.destroy();
  }

  // const close = () => {
  //   console.log(
  //     'Notification was closed. Either the close button was clicked or duration time elapsed.',
  //   );
  // };

  const openNotification = (props) => {
    setIsopened(true);
    const {
      message = null,
      description = null,
      className = null,
      duration = null,
      primaryButtonOnclick,
      secondaryButtonOnClick,
    } = props;
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <div className="">
          <button
            type="link"
            size="small"
            className="bg-neutral-700 bg-opacity-50 text-gray-50 hover:bg-neutral-700 hover:bg-opacity-70 duration-200 ease-in shadow-lg shadow-gray-200 p-2.5 py-1 rounded-lg flex justify-center items-center"
            onClick={() => {
              secondaryButtonOnClick();
              api.destroy();
            }}
          >
            No
          </button>
        </div>
        <div className="">
          <button
            type="primary"
            size="small"
            className="bg-landingPurple-60 text-gray-50 hover:bg-landingPurple-70 rounded-lg duration-200 ease-in w-full disabled:cursor-not-allowed disabled:opacity-50 p-2.5 py-1"
            onClick={() => {
              primaryButtonOnclick();
              api.destroy(key);
            }}
          >
            Yes
          </button>
        </div>
      </Space>
    );
    api.open({
      message: message,
      description: description,
      duration: duration,
      btn,
      key,
      // onClose: close,
      className: className,
      closeIcon: false,
      maxCount: 1,
    });
  };
  const contextValue = useMemo(
    () => ({
      name: 'Ant Design',
    }),
    [],
  );

  return (
    <NotificationControllerContext.Provider
      value={{
        contextValue,
        openNotification,
        setEnabled,
        setThreshold,
        setIsopened,
        isOpened,
        api,
      }}
    >
      {contextHolder}
      {children}
    </NotificationControllerContext.Provider>
  );
};

export default NotificationProvider;
