import React from 'react';
import { Switch } from '@headlessui/react';
import { updateEvents } from '../../helpers/api/event/event';
import { useApiHeader } from '../../hooks/useDecodedToken';

export default function ToogleSwitch({
  updateEvent = null,
  label = null,
  data = null,
  toggleValue,
  value,
  disabled = false,
  showInpersonError,
  setShowInpersonError,
  // from,
  // onSubmit,
}) {
  const { form_header } = useApiHeader();
  return (
    <React.Fragment>
      <div className="flex items-center justify-center">
        <Switch.Group>
          <Switch
            disabled={disabled}
            checked={value}
            onChange={(e) => {
              if (updateEvent) {
                const formData = new FormData();
                formData.append('event_id', data?.data?._id);
                formData.append('is_published', String(e));
                updateEvents(formData, form_header).then((res) => {
                  // console.info(res);
                });
              }
              // if (from === 'Security') {
              //   onSubmit(e);
              // }
              showInpersonError &&
                showInpersonError !== '' &&
                setShowInpersonError('');
              toggleValue(e);
            }}
            className={`${
              value
                ? 'bg-blue-600'
                : 'bg-component-gray-4 dark:bg-component-gray-3'
            } relative inline-flex h-[16px] w-8 items-center rounded-full ${
              disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
            // style={{backgroundColor:"#0d6efd"}}
          >
            <span className="sr-only">Enable notifications</span>
            <span
              className={`${
                value ? 'translate-x-[17px]' : 'translate-x-0.5'
              } inline-block h-[13px] w-[13px] transform rounded-full bg-whiteNew-0 transition`}
            />
          </Switch>
          {label ? (
            <Switch.Label passive className={'ml-2'}>
              {label}
            </Switch.Label>
          ) : (
            <></>
          )}
        </Switch.Group>
      </div>
    </React.Fragment>
  );
}
export function ToggleSwitchWithText({
  updateEvent = null,
  label = null,
  data = null,
  toggleValue,
  value,
  disabled = false,
  enabledValueText,
  disabledValueText,
}) {
  // const [enabled, setEnabled] = useState(false);
  // console.log('value', value);
  const { form_header } = useApiHeader();
  return (
    <React.Fragment>
      <div className="flex items-center justify-center">
        <Switch.Group>
          <Switch
            // disabled={disabled}
            // checked={enabled}
            // onChange={setEnabled}
            disabled={disabled}
            checked={value}
            onChange={(e) => {
              if (updateEvent) {
                const formData = new FormData();
                formData.append('event_id', data?.data?._id);
                formData.append('is_published', String(e));
                updateEvents(formData, form_header).then((res) => {
                  // console.info(res);
                });
              }
              toggleValue(e);
            }}
            className={`${
              value
                ? 'bg-gradient-to-r from-color-primaryColor1 to-color-primaryColor2 pl-[40px]'
                : 'bg-component-gray-2 pr-[40px]'
            } relative flex items-center rounded-full py-1 text-xs ${
              disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
            // style={{backgroundColor:"#0d6efd"}}
          >
            {/* <span className="sr-only">Enable notifications</span> */}
            <span
              className={`${
                value ? 'right-1' : 'left-1 dark:bg-component-gray-4'
                // translate-x-[79px]
                // -translate-x-[4px]
              } ${
                disabled ? 'cursor-not-allowed' : 'cursor-pointer'
              } inline-block h-[19px] w-[19px] transform rounded-full bg-whiteNew-0 transition shrink-0 duration-300 ease-out absolute`}
            />
            <Switch.Label
              passive
              // className={'ml-2'}
              className={`${
                value
                  ? '-translate-x-[29px] text-whiteNew-0'
                  : 'translate-x-[29px]'
              } ${
                disabled ? 'cursor-not-allowed' : 'cursor-pointer'
              } inline-block font-medium  max-w-[100px] text-left w-full cursor-pointer`}
            >
              {value
                ? enabledValueText
                  ? enabledValueText
                  : 'Enabled'
                : disabledValueText
                ? disabledValueText
                : 'Disabled'}
            </Switch.Label>
          </Switch>
        </Switch.Group>
      </div>
    </React.Fragment>
  );
}
