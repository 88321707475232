import { Form } from 'antd';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SpinnerBtn } from '../util/CustomIcons';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useState } from 'react';
import OTPTimer from '../../utils/components/OTPTimer';
import { get_all_active_sessions } from '../../helpers/api/stream/stream';
import useDecodedToken from '../../hooks/useDecodedToken';
import { useMutation } from '@tanstack/react-query';
import { useStreamLounge } from '../../pages/stream_lounge/hooks/useStreamLounge';
import { useDispatch } from 'react-redux';
import { updateActiveSessionList } from '../../features/stream_lounge/stream.slice';
import { useDrawerScreens } from '../../pages/stream_lounge/hooks/useDrawer';
import { btnPrimaryClass, btnSecondayClass } from '../common/DefaultClasses';

export default function SessionAuthDrawer({ lightTheme }) {
  const [form] = Form.useForm();
  const [otp, setOtp] = useState('');
  const decodedToken = useDecodedToken();
  const { event_details } = useStreamLounge();
  const { handleToggleSessionScreen, onCloseSession } = useDrawerScreens();
  const dispatch = useDispatch();
  const { isError, isLoading, mutate, error } = useMutation(
    (otp) =>
      get_all_active_sessions({
        uid: decodedToken?.id,
        event_id: event_details._id,
        otp,
        type: 'VALIDATE',
      }),
    {
      onSuccess: (data) => {
        dispatch(updateActiveSessionList(data?.data));
        handleToggleSessionScreen();
      },
      onError: (e) => {
        form.resetFields();
        setOtp('------');
      },
    },
  );

  return (
    <React.Fragment>
      <div className="flex flex-col">
        <h2 className=" text-center text-[18px] md:text-2xl font-semibold mb-2 text-white">
          Authenticate
        </h2>
        <span className="text-center text-gray-500">
          An OTP has been sent on your registered mail address 👉
        </span>
        <div className="mt-10">
          <Form
            name="session"
            form={form}
            layout="vertical"
            autoComplete="off"
            // onFinish={handleSubmit}
            scrollToFirstError
          >
            <Row className="gy-3">
              <Col lg={12}>
                <div
                  className={`mt-3 flex items-center justify-center verifyOtpBox ${
                    lightTheme ? '' : 'verifyOtpBoxDark'
                  } `}
                >
                  <ReactInputVerificationCode
                    value={otp}
                    placeholder="-"
                    length={6}
                    autoFocus
                    onChange={setOtp}
                    className="text-white"
                  />
                </div>
              </Col>
              <Col lg={12}>
                <div className="mt-4 flex justify-center">
                  <OTPTimer
                    minutes={1}
                    seconds={30}
                    text="Resend OTP in"
                    ButtonText="Resend OTP"
                    background="none"
                    textColor="unset"
                    buttonColor="#cc8411"
                    darkTheme={lightTheme ? false : true}
                    className="text-white"
                    resend={async () => {
                      setOtp('------');
                      await get_all_active_sessions({
                        uid: decodedToken?.id,
                        type: 'AUTH',
                      });
                      // mutate('send_otp');
                    }}
                  />
                </div>
              </Col>
            </Row>
            <div
              className={`absolute bottom-0 ${
                lightTheme ? 'bg-[#fff]' : 'bg-[#000]'
              } left-0 right-0`}
            >
              <div className="py-2.5 px-3.5">
                {isError ? (
                  <span className="block fw-bold text-end mb-2 text-rose-600">
                    {error?.response?.data?.message}
                  </span>
                ) : null}
                <Form.Item className="mb-0">
                  <div className="flex justify-end">
                    <div>
                      <button
                        className={`${
                          lightTheme
                            ? `${btnSecondayClass} mr-2`
                            : 'btn-cancel text-white rounded-md'
                        }`}
                        onClick={() => {
                          onCloseSession();
                        }}
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      <button
                        disabled={otp.includes('-')}
                        onClick={() => {
                          mutate(otp);
                        }}
                        className={`${
                          lightTheme
                            ? `${btnPrimaryClass}`
                            : 'btn-join text-white disabled:cursor-not-allowed disabled:bg-landingPurple-60 rounded-md'
                        }`}
                        type="submit"
                      >
                        {isLoading ? (
                          <>
                            <SpinnerBtn className={'text-md'} />
                          </>
                        ) : (
                          <span>Submit</span>
                        )}
                      </button>
                    </div>
                  </div>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}
