import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Form } from 'react-bootstrap';
import { history } from '../../../helpers/router_templates/history';
import useSearchQuery from '../../../hooks/useSearchQuery';
import OTPTimer from '../../../utils/components/OTPTimer';
import { EmailIcon, LinkBroken } from '../../util/CustomIcons';
import { guest_nft_claim } from '../../../helpers/api/auth/auth';
import { useOnceCall } from '../../../hooks/useOnlyOnce';
import { portalTexts } from '../../../utils/LoaderMessage';

export default function NFTMailConfirmation({ darkTheme }) {
  const query = useSearchQuery();
  const mutation = useMutation(() =>
    guest_nft_claim({ token: query.get('token') }),
  );

  const onSubmit = (data) => {
    mutation.mutate();
  };

  useOnceCall(() => onSubmit());

  return (
    <React.Fragment>
      <div className="flex justify-center items-center flex-col text-center">
        <div className="mb-3">
          {mutation?.isSuccess ? (
            <EmailIcon className={'w-20 h-20 lg:w-28 lg:h-28 textSuccess'} />
          ) : mutation?.isError ? (
            <LinkBroken className={'w-20 h-20 lg:w-28 lg:h-28 textSuccess'} />
          ) : null}
        </div>
        <h1 className="fw-medium text-lg 2xl:text-xl text-purple-500">
          {mutation?.isSuccess
            ? `Verify Your Email To Claim ${portalTexts?.nTkt}`
            : mutation?.isError
            ? 'Unexcepted Error occurred'
            : 'Authenticating'}
        </h1>

        <p className="text-gray-300 mt-3">
          {mutation.isLoading ? (
            <i className="fas text-center fa-circle-notch fa-spin text-2xl"></i>
          ) : mutation.isSuccess ? (
            <Form.Text className="block fw-bold text-center text-green-600 mb-2">
              Email sent successfully.
            </Form.Text>
          ) : (
            mutation.isError && (
              <Form.Text className="block fw-bold text-center mb-2 text-danger">
                {mutation.error.message
                  ? mutation.error.message +
                    ` (${mutation.error.response?.data?.message})`
                  : mutation.error}
              </Form.Text>
            )
          )}
        </p>
      </div>
      {mutation.isSuccess ? (
        <>
          <div className="space-y-4 max-w-screen-lg w-full py-3">
            <div className="mt-4">
              <div className="flex justify-center items-center flex-col gap-4 mt-4">
                <span className="text-white">
                  An Email confirmation link has been sent on your register
                  email.
                </span>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <p className="text-xs text-gray-400">
              <i>
                In case the email is not recieved in the Inbox, please check the
                Spam folder.
              </i>
            </p>
          </div>
        </>
      ) : null}
    </React.Fragment>
  );
}
