import axios from 'axios';
import { useDispatch } from 'react-redux';
import config from '../../../api/Config';
import { useWalletModal } from '../../../contexts/WalletProvider';
import { authActions } from '../../../features/auth.slice';
import { useWalletHooks } from '../../../hooks/useWalletConnected';
import Axios from '../../router_templates/axios';
import { EMAIL_REG } from '../../../utils/util';
import { useCallback } from 'react';
import { setEventList } from '../../../features/event/analytics.slice';
import { updateLoader } from '../../../features/loader/loader.slice';
import { DeleteAllSeats } from '../../../features/seatmap/seatmap.slice';
import { DeleteAllCart } from '../../../features/cart/cart.slice';
import {
  resetEvents,
  resetPageLimit,
} from '../../../features/home_page/homepage.slice';
import { useMutation } from '@tanstack/react-query';
import { useApiHeader } from '../../../hooks/useDecodedToken';

export async function signup(data) {
  const res = await Axios.post(config.endpoints.email_signup, data);
  return res.data;
}

export async function email_verification(token) {
  try {
    const res = await Axios.put(
      config.endpoints.email_verification + token,
      {},
    );
    return res.data;
  } catch (err) {
    return err.response?.data;
  }
}

export async function resend_email(body) {
  const res = await Axios.post(config.endpoints.resend_email, body);
  return res.data;
}

export async function check_username(username) {
  if (!username) {
    return false;
  }
  // await sleep(1500).then(() => {})
  const res = await Axios.get(config.endpoints.check_username + username);
  return res.data.status;
}

export async function check_email(email, from, type = null) {
  if (!email || !EMAIL_REG.test(email)) {
    return false;
  }
  let url = config.endpoints.check_email + email
  if (type) {
    url = url + type
  }
  const res = await Axios.get(url);
  return from === 'Login' ? res?.data : res.data.status;
}

export async function check_email_while_login(email) {
  if (!email || !EMAIL_REG.test(email)) {
    return false;
  }
  const res = await Axios.get(config.endpoints.check_email_new + email);
  return res.data;
}

export async function check_order_shared_on_email(email, order_id, type) {
  if (!email) {
    return;
  }
  const res = await Axios.get(
    config.endpoints.check_order_shared_on_email +
    `?email=${email}&order_id=${order_id}&type=${type}`,
  );
  return res.data.status;
}

export async function check_phone(phone, code) {
  console.log(phone, code);
  if (!phone || !code) {
    return false;
  }
  const res = await Axios.get(
    config.endpoints.check_phone + `${code}/` + phone,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res.data.status;
}

export async function forget_password(body) {
  const res = await Axios.post(config.endpoints.forget_password, body);
  return res.data;
}
export async function forget_password_phone(body) {
  const res = await Axios.post(config.endpoints.forget_password_phone, body);
  return res.data;
}
export async function reset_password(body, token) {
  const res = await Axios.post(config.endpoints.reset_password + token, body);
  return res.data;
}

export async function change_email(body) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.change_email,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res.data;
}

export async function verify_otp(body) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.verify_otp,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res.data;
}

export async function guest_nft_claim(body) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.guest_send_verification_email,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return res.data;
}

export async function guest_nft_claim_verification(body) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.guest_verification_email,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return res.data;
}

// Business Account helper apis
export async function getSignupBusinessList() {
  try {
    const res = await axios.get(
      config.baseUrl + config.endpoints.signup_business_type_list,
    );
    return res.data;
  } catch (err) {
    return null;
  }
}

export async function signupBusinessUser(body, header) {
  try {
    const res = await axios.post(
      config.baseUrl + config.endpoints.signup_business_user,
      body,
      header,
    );
    return res.data;
  } catch (err) {
    return null;
  }
}

export function useLogout() {
  const dispatch = useDispatch();
  const { handleDisconnectWallet } = useWalletHooks();
  const { setSelectedWallet } = useWalletModal();
  const { json_header } = useApiHeader();

  const insertNote = () => {
    return logout_User(json_header);
  };
  const logOutMutation = useMutation(insertNote, {
    onSuccess: () => {
      ClearDataOnLogout();
      handleDisconnectWallet();
      localStorage.removeItem('notLoggedUser');
      localStorage.removeItem('walletName');
      localStorage.removeItem('seat_cart_id');
      localStorage.removeItem('PurchasedTickets');
      dispatch(authActions.logout());
      setSelectedWallet();
    },
  });

  const ClearDataOnLogout = useCallback(() => {
    setEventList({});

    localStorage.removeItem('loaderState');
    dispatch(updateLoader(true));
    localStorage.removeItem('SelectedEvent');
    localStorage.removeItem('selectedVenue');
    dispatch(DeleteAllSeats());
    dispatch(DeleteAllCart());
    dispatch(resetEvents());
    dispatch(resetPageLimit());
  }, [dispatch]);

  function logout() {
    logOutMutation?.mutate();
  }

  return { logout, isLoading: logOutMutation?.isLoading };
}

export async function logout_User(headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.logout,
    {},
    headers,
  );

  return res.data;
}
