import moment from "moment";

export const RemoveExpiredEvents = (data) => {
  let filteredData = [];
  data?.map((item) => {
    if (
      Date.parse(
        moment(
          moment.utc(item?.end_date_time).local(),
          'YYYY-MM-DD hh:mm a',
        ),
      ) > Date.parse(moment())
    ) {
      filteredData.push(item);
    }
  });
  return filteredData;
};


export const getMomentAgoTime = (policy, event) => {

  if (policy === 'static') {
    let timevalue = moment(event?.start_time)?.subtract(
      policy?.timeframeValue,
      policy?.timeframeSelect === 'Hour'
        ? 'hours'
        : policy?.timeframeSelect?.toLowerCase(),
    );

    let remainingTime = moment
      .duration(moment(event?.end_time).diff())
      .asHours();




    return {
      remainingTime: remainingTime,
      timevalue: timevalue?.format('MM/DD/YY - HH:mm'),
    };
  } else {
    let timevalue = moment(event?.start_time).subtract(
      policy?.timeframeValue,
      policy?.timeframeSelect === 'Hour'
        ? 'hours'
        : policy?.timeframeSelect?.toLowerCase(),
    );

    let remainingTime = moment.duration(moment(timevalue).diff()).asHours();
    return {
      remainingTime: remainingTime,
      timevalue: timevalue?.format('MM/DD/YY - HH:mm'),
    };
  }
};