import React from 'react';
import CartItemsCard from './CartItemsCard';
import CartRecomItem from './CartRecomItem';
import { useSelector } from 'react-redux';
import { cartData } from '../../../../features/cart/cart.slice';

function CartDrawerCompo({ darkTheme, onClose }) {
  let cartselectedSeats = useSelector(cartData);
  let { cartInpersonData, cartvirtualData } = cartselectedSeats;

  return (
    <>
      <CartItemsCard darkTheme={darkTheme} onClose={onClose} />
      {(Object.entries(cartvirtualData)?.length > 0 ||
        Object.entries(cartInpersonData)?.length > 0) && (
        <CartRecomItem darkTheme={darkTheme} onClose={onClose} />
      )}
    </>
  );
}

export default CartDrawerCompo;
