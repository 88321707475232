import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import SelectCountryCheckout from '../../checkout/SelectCountryCheckout';
import {
  afterBorder,
  formClassV2,
  primaryBtnClass,
  secondaryBtnClass,
} from '../../common/DefaultClasses';

export default function ProfileOverviewForm() {
  return (
    <React.Fragment>
      <div className="">
        <Form>
          <div className="relative pb-7 mb-7">
            <div className={`${afterBorder}`}>
              <div className="max-w-[1920px]">
                <fieldset
                  // disabled={!value}
                  className="space-y-6"
                >
                  <Row className="gy-3">
                    <Col xxl={6}>
                      <div className="w-full">
                        <Form.Group className="w-full" controlId="username">
                          <Row className="">
                            <Col xxl={3} className="col-form-label">
                              <Form.Label className="font-normal text-base mb-0 flex items-center">
                                Username
                              </Form.Label>
                            </Col>
                            <Col xxl={9}>
                              <div className="relative w-full">
                                <Form.Control
                                  type="text"
                                  // disabled={!userdetails?.email_verified}
                                  // placeholder="Username"
                                  className={`${formClassV2} rounded-md`}
                                  // {...register('username', {
                                  //   minLength: {
                                  //     value: 5,
                                  //     message:
                                  //       'Username must be of 5-15 characters long.',
                                  //   },
                                  //   maxLength: {
                                  //     value: 15,
                                  //     message:
                                  //       'Username must be of 5-15 characters long.',
                                  //   },
                                  //   // required: 'username field is required',
                                  //   pattern: {
                                  //     value: USERNAME_REG,
                                  //     message: 'Username must match the pattern.',
                                  //   },
                                  //   onChange: async (e) => {
                                  //     if (
                                  //       !e.target.value ||
                                  //       String(e.target.value).length < 5 ||
                                  //       String(e.target.value).length > 15 ||
                                  //       !new RegExp(USERNAME_REG).test(e.target.value)
                                  //     ) {
                                  //       setIsUsernameAvailable();
                                  //     } else {
                                  //       if (
                                  //         e.target.value !== userdetails?.username
                                  //         // getValues('username') !== userdetails?.username
                                  //       ) {
                                  //         // localStorage.setItem("OldUsername",JSON.stringify(userdetails?.username))
                                  //         const res = await check_username(
                                  //           e.target.value,
                                  //         );

                                  //         setIsUsernameAvailable(res);
                                  //       }
                                  //     }
                                  //   },
                                  //   validate: isUsernameAvailable,
                                  // })}
                                />
                                {/* <span className="flex items-center gap-1 text-sky-600 absolute top-1/2 -translate-y-2/4 right-2">
                        {isUsernameAvailable === false && (
                          <>
                            <span>
                              <RxCrossCircled color="red" />
                            </span>

                            <span className="text-danger  ">Not Available</span>
                          </>
                        )}
                        {isUsernameAvailable && (
                          <>
                            <span>
                              <BsCheckCircleFill />
                            </span>
                            <span>Available</span>
                          </>
                        )}
                      </span> */}
                              </div>
                              {/* {errors?.username && (
                      <>
                        <Form.Text className="text-danger">
                          {errors?.username?.message}
                        </Form.Text>
                      </>
                    )} */}
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                    </Col>
                    <Col xxl={6}>
                      <div className="w-full">
                        <Form.Group className="w-full" controlId="fullname">
                          <Row className="">
                            <Col xxl={3} className="col-form-label">
                              <Form.Label className="font-normal text-base mb-0 flex items-center">
                                Full Name
                              </Form.Label>
                            </Col>
                            <Col xxl={9}>
                              <div className="relative w-full">
                                <Form.Control
                                  type="text"
                                  // disabled={!userdetails?.email_verified}
                                  // placeholder="Username"
                                  className={`${formClassV2} rounded-md`}
                                  // {...register('username', {
                                  //   minLength: {
                                  //     value: 5,
                                  //     message:
                                  //       'Username must be of 5-15 characters long.',
                                  //   },
                                  //   maxLength: {
                                  //     value: 15,
                                  //     message:
                                  //       'Username must be of 5-15 characters long.',
                                  //   },
                                  //   // required: 'username field is required',
                                  //   pattern: {
                                  //     value: USERNAME_REG,
                                  //     message: 'Username must match the pattern.',
                                  //   },
                                  //   onChange: async (e) => {
                                  //     if (
                                  //       !e.target.value ||
                                  //       String(e.target.value).length < 5 ||
                                  //       String(e.target.value).length > 15 ||
                                  //       !new RegExp(USERNAME_REG).test(e.target.value)
                                  //     ) {
                                  //       setIsUsernameAvailable();
                                  //     } else {
                                  //       if (
                                  //         e.target.value !== userdetails?.username
                                  //         // getValues('username') !== userdetails?.username
                                  //       ) {
                                  //         // localStorage.setItem("OldUsername",JSON.stringify(userdetails?.username))
                                  //         const res = await check_username(
                                  //           e.target.value,
                                  //         );

                                  //         setIsUsernameAvailable(res);
                                  //       }
                                  //     }
                                  //   },
                                  //   validate: isUsernameAvailable,
                                  // })}
                                />
                                {/* <span className="flex items-center gap-1 text-sky-600 absolute top-1/2 -translate-y-2/4 right-2">
                        {isUsernameAvailable === false && (
                          <>
                            <span>
                              <RxCrossCircled color="red" />
                            </span>

                            <span className="text-danger  ">Not Available</span>
                          </>
                        )}
                        {isUsernameAvailable && (
                          <>
                            <span>
                              <BsCheckCircleFill />
                            </span>
                            <span>Available</span>
                          </>
                        )}
                      </span> */}
                              </div>
                            </Col>
                          </Row>

                          {/* {errors?.username && (
                      <>
                        <Form.Text className="text-danger">
                          {errors?.username?.message}
                        </Form.Text>
                      </>
                    )} */}
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                  <Row className="gy-3">
                    <Col xxl={6}>
                      <div className="w-full">
                        <Form.Group className="w-full" controlId="businessname">
                          <Row className="">
                            <Col xxl={3} className="col-form-label">
                              <Form.Label className="font-normal text-base mb-0 flex items-center">
                                Business Name
                              </Form.Label>
                            </Col>
                            <Col xxl={9}>
                              <div className="relative w-full">
                                <Form.Control
                                  type="text"
                                  // disabled={!userdetails?.email_verified}
                                  // placeholder="Username"
                                  className={`${formClassV2} rounded-md`}
                                  // {...register('username', {
                                  //   minLength: {
                                  //     value: 5,
                                  //     message:
                                  //       'Username must be of 5-15 characters long.',
                                  //   },
                                  //   maxLength: {
                                  //     value: 15,
                                  //     message:
                                  //       'Username must be of 5-15 characters long.',
                                  //   },
                                  //   // required: 'username field is required',
                                  //   pattern: {
                                  //     value: USERNAME_REG,
                                  //     message: 'Username must match the pattern.',
                                  //   },
                                  //   onChange: async (e) => {
                                  //     if (
                                  //       !e.target.value ||
                                  //       String(e.target.value).length < 5 ||
                                  //       String(e.target.value).length > 15 ||
                                  //       !new RegExp(USERNAME_REG).test(e.target.value)
                                  //     ) {
                                  //       setIsUsernameAvailable();
                                  //     } else {
                                  //       if (
                                  //         e.target.value !== userdetails?.username
                                  //         // getValues('username') !== userdetails?.username
                                  //       ) {
                                  //         // localStorage.setItem("OldUsername",JSON.stringify(userdetails?.username))
                                  //         const res = await check_username(
                                  //           e.target.value,
                                  //         );

                                  //         setIsUsernameAvailable(res);
                                  //       }
                                  //     }
                                  //   },
                                  //   validate: isUsernameAvailable,
                                  // })}
                                />
                                {/* <span className="flex items-center gap-1 text-sky-600 absolute top-1/2 -translate-y-2/4 right-2">
                        {isUsernameAvailable === false && (
                          <>
                            <span>
                              <RxCrossCircled color="red" />
                            </span>

                            <span className="text-danger  ">Not Available</span>
                          </>
                        )}
                        {isUsernameAvailable && (
                          <>
                            <span>
                              <BsCheckCircleFill />
                            </span>
                            <span>Available</span>
                          </>
                        )}
                      </span> */}
                              </div>

                              {/* {errors?.username && (
                      <>
                        <Form.Text className="text-danger">
                          {errors?.username?.message}
                        </Form.Text>
                      </>
                    )} */}
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                    </Col>
                    <Col xxl={6}>
                      <div className="w-full">
                        <Form.Group className="w-full" controlId="email">
                          <Row className="">
                            <Col xxl={3} className="col-form-label">
                              <Form.Label className="font-normal text-base mb-0 flex items-center">
                                Email
                              </Form.Label>
                            </Col>
                            <Col xxl={9}>
                              <div className="relative w-full">
                                <Form.Control
                                  type="email"
                                  // disabled={!userdetails?.email_verified}
                                  // placeholder="Username"
                                  className={`${formClassV2} rounded-md`}
                                  // {...register('username', {
                                  //   minLength: {
                                  //     value: 5,
                                  //     message:
                                  //       'Username must be of 5-15 characters long.',
                                  //   },
                                  //   maxLength: {
                                  //     value: 15,
                                  //     message:
                                  //       'Username must be of 5-15 characters long.',
                                  //   },
                                  //   // required: 'username field is required',
                                  //   pattern: {
                                  //     value: USERNAME_REG,
                                  //     message: 'Username must match the pattern.',
                                  //   },
                                  //   onChange: async (e) => {
                                  //     if (
                                  //       !e.target.value ||
                                  //       String(e.target.value).length < 5 ||
                                  //       String(e.target.value).length > 15 ||
                                  //       !new RegExp(USERNAME_REG).test(e.target.value)
                                  //     ) {
                                  //       setIsUsernameAvailable();
                                  //     } else {
                                  //       if (
                                  //         e.target.value !== userdetails?.username
                                  //         // getValues('username') !== userdetails?.username
                                  //       ) {
                                  //         // localStorage.setItem("OldUsername",JSON.stringify(userdetails?.username))
                                  //         const res = await check_username(
                                  //           e.target.value,
                                  //         );

                                  //         setIsUsernameAvailable(res);
                                  //       }
                                  //     }
                                  //   },
                                  //   validate: isUsernameAvailable,
                                  // })}
                                />
                                {/* 
                      <span className="flex items-center gap-1 text-green-600 absolute top-1/2 -translate-y-2/4 right-2">
                        {userdetails?.email_verified ? (
                          <>
                            <span>
                              <BsCheckCircleFill />
                            </span>
                            <span>Verified</span>
                          </>
                        ) : (
                          userdetails?.email && (
                            <>
                              <span
                                onClick={() => {
                                  if (!value) {
                                    return;
                                  }
                                  setOtpOpen(true);
                                }}
                                className="text-success  "
                              >
                                Click to verify
                              </span>
                            </>
                          )
                        )}
                      </span> */}
                              </div>

                              {/* {errors?.username && (
                      <>
                        <Form.Text className="text-danger">
                          {errors?.username?.message}
                        </Form.Text>
                      </>
                    )} */}
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                  <Row className="gy-3">
                    <Col xxl={6}>
                      <div className="w-full">
                        <Form.Group className="w-full" controlId="phone">
                          <Row className="">
                            <Col xxl={3} className="col-form-label">
                              <Form.Label className="font-normal text-base mb-0 flex items-center">
                                Phone
                              </Form.Label>
                            </Col>
                            <Col xxl={9}>
                              <div className="relative w-full">
                                <div className="flex items-center gap-2 ">
                                  <div className="w-2/6">
                                    <SelectCountryCheckout
                                      // control={control}
                                      normalSelect
                                      // setSelectedCountryCode={(value) => {
                                      //   setSelectedCountryCode(value);
                                      //   reset({
                                      //     contact: '',
                                      //   });
                                      // }}
                                      // _selected={
                                      //   userdetails?.country_code?.split('+')?.length > 1
                                      //     ? userdetails?.country_code
                                      //     : selectedCountryCode
                                      // }
                                      from="about"
                                      version2={true}
                                    />
                                  </div>
                                  <div className="relative w-4/6">
                                    <Form.Control
                                      type="number"
                                      // placeholder="Phone Number"
                                      onKeyDown={(event) => {
                                        if (
                                          event.key === '.' ||
                                          event.key === 'e'
                                        ) {
                                          event.preventDefault();
                                        }
                                      }}
                                      className={`${formClassV2} rounded-md`}
                                      // {...register('contact', {
                                      //   onChange: async (e) => {
                                      //     let code = selectedCountryCode.split('+')[0];

                                      //     let valid = isValidPhoneNumber(
                                      //       e.target.value,
                                      //       code,
                                      //     );

                                      //     if (!e.target.value || !valid) {
                                      //       setIsPhoneAvailable();
                                      //     } else {
                                      //       clearErrors('contact');
                                      //       const res = await check_phone(e.target.value);
                                      //       if (res === false) {
                                      //         setIsPhoneAvailable(true);
                                      //       } else {
                                      //         setIsPhoneAvailable(false);
                                      //       }
                                      //     }
                                      //   },
                                      //   // validate: isPhoneAvailable,
                                      //   validate: (val) => {
                                      //     if (val !== '') {
                                      //       let code = selectedCountryCode.split('+')[0];
                                      //       let validated = isValidPhoneNumber(val, code);

                                      //       if (!validated) {
                                      //         return 'invalid phone number for the selected country.';
                                      //       }
                                      //     }
                                      //   },
                                      // })}
                                    />
                                    {/* <span className="flex items-center gap-1 text-green-600 absolute top-1/2 -translate-y-2/4 right-2">
                            {userdetails?.contact_verified === 'true' ||
                            (userdetails?.contact_verified === true &&
                              userdetails?.contact === getValues()?.contact) ? (
                              <>
                                <span>
                                  <BsCheckCircleFill />
                                </span>
                                <span>Verified</span>
                              </>
                            ) : userdetails?.contact &&
                              (userdetails?.contact_verified === 'false' ||
                                userdetails.contact_verified === '' ||
                                userdetails?.contact_verified === false) ? (
                              <>
                                <span className="flex items-center gap-1">
                                  <span>
                                    <RxCrossCircled color="red" />
                                  </span>
                                  <span
                                    className="text-danger"
                                    type="button"
                                    disabled={!value}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      // if (!userdetails?.contact_verified) {
                                      if (value) sendOTP();
                                      // }
                                    }}
                                    id={!value ? '' : 'sign-in-button'}
                                  >
                                    Click to Verify
                                  </span>
                                </span>
                              </>
                            ) : null}
                          </span> */}
                                  </div>
                                </div>
                              </div>
                              {/* {errors?.username && (
                      <>
                        <Form.Text className="text-danger">
                          {errors?.username?.message}
                        </Form.Text>
                      </>
                    )} */}
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>

                  <Row className="gy-3">
                    <Col xxl={6}>
                      <div className="w-full">
                        <Form.Group className="w-full" controlId="description">
                          <Row className="">
                            <Col xxl={3} className="col-form-label">
                              <Form.Label className="font-normal text-base mb-0 flex items-center">
                                Description
                              </Form.Label>
                            </Col>
                            <Col xxl={9}>
                              <div className="relative">
                                <Form.Control
                                  as="textarea"
                                  // disabled={!userdetails?.email_verified}
                                  rows={3}
                                  className={`${formClassV2} rounded-md`}
                                  // {...register('description', {
                                  //   maxLength: {
                                  //     value: 250,
                                  //     message: 'Max 250 characters allowed',
                                  //   },
                                  //   onChange: (e) => {
                                  //     setEventDes(e.target.value);
                                  //     setValue('description', e.target.value);
                                  //   },
                                  //   // minLength: {
                                  //   //   value: 50,
                                  //   //   message:
                                  //   //     'At least 50 character required for valid description',
                                  //   // },
                                  // })}
                                  maxLength={250}
                                  // onChange={(e) => {
                                  //   setEventDes(e.target.value);

                                  // }}
                                />
                                {/* <span className="flex float-right text-md text-gray-500 mt-1">
                            {eventDes ? eventDes.length : 0}/250
                          </span> */}
                              </div>
                              {/* {errors?.username && (
                      <>
                        <Form.Text className="text-danger">
                          {errors?.username?.message}
                        </Form.Text>
                      </>
                    )} */}
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                </fieldset>
              </div>
            </div>
          </div>
          <div className="flex gap-3 items-center">
            <button className={`${primaryBtnClass}  rounded-md`} type="button">
              Save
            </button>
            <button
              className={`${secondaryBtnClass} border-1 rounded-md`}
              type="button"
            >
              Cancel
            </button>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
}
