import React from 'react';

export default function Video({
  src,
  loading,
  className,
  alt,
  autoplay = true,
  removeControls,
}) {
  return (
    <>
      <div
        className={`${
          removeControls ? '__removeVideoControl' : ''
        } w-full h-full object-cover`}
      >
        <video
          className={className}
          alt={'Video'}
          src={src}
          controls
          loop
          autoPlay
          muted
        >
          {/* <source src={src} type="video/mp4" /> */}
        </video>
      </div>
    </>
  );
}
