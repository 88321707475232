import config from '../../../api/Config';
import Axios from '../../router_templates/axios';

export async function getArtistDetails(userid) {
  const res = await Axios.get(config.endpoints.artist_details + userid, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return res.data;
}
