import React, { useState, useMemo, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { getAddChainParameters } from '../utils/blockchain/ethereum/Chains';
import { useWallet } from '@solana/wallet-adapter-react';
import {
  hooks,
  metaMask,
} from '../utils/blockchain/ethereum/connectors/metamask';
import { metamaskImage } from '../assets/images/icons/metamaskIcon';
import { addUpdateWallet } from '../helpers/api/user/user_details';

export const getMetamaskWallet = ({ provider, ...config } = {}) => ({
  name: 'Metamask',
  url: 'https://metamask.io/',
  icon: metamaskImage,
});

export class MetamaskWalletAdapter {
  name = 'Metamask';
  url = 'https://metamask.io/';
  icon = metamaskImage;
}

export const MetaMaskContext = React.createContext(null);
const {
  useChainId,
  useAccounts,
  useIsActivating,
  useIsActive,
  useProvider,
  useENSNames,
} = hooks;

export const MetaMaskProvider = ({ children }) => {
  const { account } = useWeb3React();
  const connector = metaMask;
  const chainId = useChainId();
  const { connected } = useWallet();
  //   const { snackbar } = useSnackbarJS()

  const accounts = useAccounts();

  const isActivating = useIsActivating();

  const isActive = useIsActive();

  const provider = useProvider();
  const signer = provider?.getSigner();

  const ENSNames = useENSNames(provider);
  const [error, setError] = useState();

  // const [isActive, setIsActive] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);

  // const decodedToken = useDecodedToken();
  // const displayDefault = !isNetwork
  // const chainIds = (isNetwork ? Object.keys(URLS) : Object.keys(CHAINS)).map((chainId) => Number(chainId))

  const [desiredChainId] = useState(
    connector
      ? localStorage.getItem('chainId')
        ? localStorage.getItem('chainId')
        : 1
      : -1,
  );
  const [balances, setBalances] = useState(0.0);


  // Disconnect from Metamask wallet
  const disconnect = useCallback(async () => {
    try {
      // await deactivate();
      if (connector?.deactivate) {
        void connector.deactivate();
      } else {
        void connector.resetState();
      }
    } catch (error) {

    }
  }, [connector]);

  const refreshBalance = useCallback(async () => {
    const balance = await provider.getBalance(account)
    setBalances(balance);
    return balance;
  }, [provider, account]);

  const switchChain = useCallback(
    async (desiredChainId) => {
      // if we're already connected to the desired chain, return
      if (desiredChainId === chainId) {
        // console.error(undefined);
        return;
      }
      const wallet_name = localStorage.getItem("wallet_name")
      const isArtchive = wallet_name === 'ARTC Wallet';
      const isMetaMask = wallet_name === 'Metamask'
      await connector
        .activate(
          desiredChainId === -1
            ? undefined
            : getAddChainParameters(desiredChainId),
          isArtchive,
          isMetaMask,
        )
        .then((res) => {
          refreshBalance();
          localStorage.setItem('chainId', desiredChainId);
        })
        .catch((err) => console.error(err));
      // }
    },
    [connector, chainId, refreshBalance],
  );

  const connect = useCallback(async (isArtchive = false, isMetaMask = false) => {
    try {
      if (
        // !isExpired(localStorage.getItem('token')) &&
        !connected
      ) {
        // await activate(injected);
        connector
          .activate(
            desiredChainId === -1
              ? undefined
              : getAddChainParameters(desiredChainId),
            isArtchive,
            isMetaMask
          )
          .then((res) => {
            refreshBalance()
            addUpdateWallet({
              wallet_address: account,
              wallet_name: localStorage.getItem('wallet_name'),
              blockchain: 'ethereum',
            });
          })
          .catch((err) => {
            setError(err?.toString());
          });
      }
    } catch (error) {
      setError(error?.toString());
    }
  }, [connector, desiredChainId, connected, refreshBalance, account]);

  const values = useMemo(
    () => ({
      isActive,
      isActivating,
      connect,
      disconnect,
      balances,
      switchChain,
      refreshBalance,
      accounts,
      provider,
      ENSNames,
      chainId,
      account,
      signer,
      error,
      setError,
    }),
    [
      isActive,
      balances,
      accounts,
      provider,
      ENSNames,
      account,
      isActivating,
      chainId,
      connect,
      disconnect,
      error,
      refreshBalance,
      signer,
      switchChain,
    ],
  );

  return (
    <MetaMaskContext.Provider value={values}>
      {children}
    </MetaMaskContext.Provider>
  );
};

export default function useMetaMask() {
  const context = React.useContext(MetaMaskContext);

  if (context === undefined) {
    throw new Error(
      'useMetaMask hook must be used with a MetaMaskProvider component',
    );
  }

  return context;
}
