import React from 'react';
import HeaderFillTop from '../HeaderFillTop';
import RightSideSectionWrapper from '../RightSideSectionWrapper';
import NotificationCheckBox from '../../dashboard/notification/NotificationCheckBox';
import EmailPreferenceCheckbox from '../../dashboard/notification/EmailPreferenceCheckbox';

export default function EmailSettings() {
  return (
    <React.Fragment>
      <div>
        <div className="">
          <HeaderFillTop title={'Email'} extraPills={<></>} extra={<></>} />
        </div>
        <RightSideSectionWrapper>
          <section>
            <div className="block overflow-hidden" style={{ flex: '0 0 75%' }}>
              <div className="w-full p-3">
                <div className="space-y-8">
                  <div className="">
                    <EmailPreferenceCheckbox
                      title={'Successful Payments'}
                      subTitle={
                        'Receive a notification for every successful payment.'
                      }
                      checked={true}
                      textSize
                    />
                  </div>
                  <div className="">
                    <EmailPreferenceCheckbox
                      title={'Payouts'}
                      subTitle={
                        'Receive a notification for every initiated payout.'
                      }
                      checked={false}
                      textSize
                    />
                  </div>
                  <div className="">
                    <EmailPreferenceCheckbox
                      title={'Fee Collection'}
                      subTitle={
                        'Receive a notification each time you collect a fee from sales'
                      }
                      textSize
                    />
                  </div>
                  <div className="">
                    <EmailPreferenceCheckbox
                      title={'Customer Payment Dispute'}
                      subTitle={
                        'Receive a notification if a payment is disputed by a customer and for dispute purposes.'
                      }
                      checked={true}
                    />
                  </div>
                  <div className="">
                    <EmailPreferenceCheckbox
                      title={'Refund Alerts'}
                      subTitle={
                        'Receive a notification if a payment is stated as risk by the Finance Department.'
                      }
                      checked={true}
                      textSize
                    />
                  </div>
                  <div className="">
                    <EmailPreferenceCheckbox
                      title={'Invoice Payments'}
                      subTitle={
                        'Receive a notification if a customer sends an incorrect amount to pay their invoice.'
                      }
                    />
                  </div>
                  <div className="">
                    <EmailPreferenceCheckbox
                      title={'Webhook API Endpoints'}
                      subTitle={
                        'Receive notifications for consistently failing webhook API endpoints.'
                      }
                      textSize
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </RightSideSectionWrapper>
      </div>
    </React.Fragment>
  );
}
