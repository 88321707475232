import React from 'react';
import AuthScreenWrapper from '../AuthScreenWrapper';
import LoginScreens from './component/LoginScreens';

function index({ compo, darkTheme, showLogo }) {
  return (
    <AuthScreenWrapper darkTheme>
      <LoginScreens compo={compo} darkTheme={darkTheme} showLogo={showLogo} />
    </AuthScreenWrapper>
  );
}

export default index;
