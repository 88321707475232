import React from 'react';
import ProfileInfo from './ProfileInfo';
import ContactInfo from './ContactInfo';
import SecurityDefault from './SecurityDefault';
import SecurityOptional from './SecurityOptional';

export default function ProfileSection({ activeSection, activeSubSection }) {
  return (
    <React.Fragment>
      {activeSection === 'profile' && (
        <>
          <ProfileInfo />
        </>
      )}
      {activeSection === 'contactInfo' && (
        <>
          <ContactInfo />
        </>
      )}
      {/* {activeSection === 'security' && (
        <>
          <SecurityOverview />
        </>
      )} */}
      {activeSection === 'security' && activeSubSection === 'default' && (
        <>
          <SecurityDefault />
        </>
      )}
      {activeSection === 'security' && activeSubSection === 'optional' && (
        <>
          <SecurityOptional />
        </>
      )}
    </React.Fragment>
  );
}
