import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useDecodedToken, { useApiHeader } from '../../../hooks/useDecodedToken';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  useEventAllDetails,
  useGetSeatMappingDetails,
} from '../../../hooks/event/seat_mapping/useSeatMapping';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTicketSeatMap,
  postSeatMappingDetails,
} from '../../../helpers/api/event/seat_mapping/seat_mapping';
import {
  AddTotalSeats,
  AddVirtualResaleSeats,
  AddVirtualSeatData,
  AddVirtualSeats,
  AddVirtualSeatsID,
  DeleteAllSeats,
} from '../../../features/seatmap/seatmap.slice';
import moment from 'moment';
import {
  bookTickets,
  updateCartSeatsDetails,
} from '../../../helpers/api/event/tickets/tickets';
import { Form, message } from 'antd';
import { addMoney } from '../../../helpers/api/card/card';
import {
  displayRazorpay,
  make_ticket_order,
} from '../../../helpers/payment_gateway/payment';
import { useMutation } from '@tanstack/react-query';
import { useConsolidatedTicket } from '../../stream_lounge/hooks/useStreamLounge';
import { updateCheckoutPaymentLoader } from '../../../features/checkout/checkout.slice';
import { viewSingleNFT } from '../../../features/nft/nft.slice';
import { IoTicketOutline } from 'react-icons/io5';
import { getMomentAgoTime } from '../../../utils/EventUtil';
import CurrencyFormat from 'react-currency-format';
import {
  useUserDetailsSWR,
  useUserMoneyDetails,
} from '../../../hooks/user/useUserDetails';
import { useOnceCall } from '../../../hooks/useOnlyOnce';
import {
  DeleteAllCart,
  cartData,
  setOpenTimerattemptModal,
  updateTotalAttempt,
} from '../../../features/cart/cart.slice';
import { CountryCodes } from '../../../utils/CountryCodes';
import Image from '../../../components/common/Image';
import {
  DATEFORMAT,
  changeObjectKey,
  clearGuestUserCart,
} from '../../../utils/util';
import { useCookie } from '../../../hooks/cookie';
// import jwt from 'jsonwebtoken'

export function useCheckoutActions() {
  const { json_header } = useApiHeader();
  const navigate = useNavigate();
  // const user_id = JSON.parse(localStorage.getItem('userID')) ?? undefined;
  const decodedToken = useDecodedToken();
  const [btnStatus, setBtnStatus] = useState('Checkout');
  const { data: userdetails } = useSelector((s) => s.userDetails);
  // const [source, setSource] = useState({ wallet: 'usd_wallet', address: '' });
  const userDetails = JSON.parse(localStorage.getItem('UserDetails'));
  const PaymentDetails = useMemo(
    () => JSON.parse(localStorage.getItem('PurchasedTickets')) || {},
    [],
  );
  const Billing = useMemo(
    () => JSON.parse(localStorage.getItem('billing_info')) || {},
    [],
  );
  const method =
    localStorage.getItem('payment_method') === 'other'
      ? 'Razorpay'
      : 'USD Wallet';
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const slug1 = new URLSearchParams(location.search).get('slug');
  const { data: eventAllDetails } = useEventAllDetails(slug1, json_header);
  const dispatch = useDispatch();
  const selectedSeats = useSelector((state) => state.selectedSeats);
  const { data } = useGetSeatMappingDetails(
    searchParams.get('user_id'),
    searchParams.get('event_id'),
    searchParams.get('seatmap_id'),
  );
  const [OpenModal, setOpenmodal] = useState(false);
  const [backToHome, setBackToHome] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [error, setError] = useState(false);
  const {
    checkout_event_details,
    checkout_payment_loader,
    checkout_billing_summary,
  } = useSelector((s) => s.checkout);
  const [form] = Form.useForm();
  const { removeCookie } = useCookie(); //don't remove the cookie and setCookie similar for all the places

  //Abort the axios
  // const { clearCartData } = useCartCheckOut();

  useEffect(() => {
    if (paymentLoader) {
      const timeout = setTimeout(() => {
        // clearCartData();
        setPaymentLoader(false);
        dispatch(updateCheckoutPaymentLoader(false));
        if (!error) {
          navigate(
            `/ticket-confirmation/success?slug=${searchParams.get(
              'slug',
            )}&ticket_id=${localStorage.getItem(
              'cart_seat_id',
            )}&event_id=${searchParams.get(
              'event_id',
            )}&user_id=${searchParams.get('user_id')}`,
          );
        }
      }, 11000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [paymentLoader, error, searchParams, navigate, dispatch]);

  // Getting the seat data from redux
  const cartselectedSeats = useSelector(cartData);
  const { cartInpersonData, cartvirtualData, slug, cartDataType } =
    cartselectedSeats;

  //function to allow reload\.
  useOnceCall(() => {
    const handleBeforeUnload = () => {
      // Set a flag in localStorage indicating that a refresh is intended
      localStorage.setItem('refreshFlag', 'true');
      ///removing the guest user cart when user refresh the page after login in
      let IsGuestUser = localStorage.getItem('GuestUser')
        ? JSON.parse(localStorage.getItem('GuestUser'))
        : false;
      if (IsGuestUser) {
        // console.log('comesHere');
        clearGuestUserCart();
        removeCookie(`timer${'GuestUser'}`);
        dispatch(updateTotalAttempt(0));
        dispatch(setOpenTimerattemptModal(false));
        removeCookie(`TotalAttempt${'GuestUser'}`);
        dispatch(DeleteAllCart([]));
      }
      // console.log('here');
      // controller.abort();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useOnceCall(() => {
    // Check if the refresh flag is set
    const refreshFlag = localStorage.getItem('refreshFlag');
    // console.log('refreshFlag', refreshFlag);

    // If the flag is set, navigate to another page
    if (refreshFlag === 'true') {
      // setStartTimer();
      let slug = localStorage.getItem('slug')
        ? JSON.parse(localStorage.getItem('slug'))
        : '';
      localStorage?.removeItem('selectedSeatData');
      localStorage?.removeItem('PurchasedTickets');
      // localStorage?.removeItem('billingAddress');
      localStorage?.removeItem('selectedCartEvent');
      // localStorage.setItem('slug', JSON.stringify(slug)); // need to replace with persistance.
      localStorage.removeItem('refreshFlag');

      dispatch(DeleteAllSeats());
      navigate(`/event-detail/${slug}`);
      // window.location.href = `/event-detail/${slug}`;
    }
    // Clear the flag in localStorage
    localStorage.removeItem('refreshFlag');
  }, [slug]);

  /// replace seat dat with cart data if we refresh the page or else come from the cart checkout.
  useOnceCall(() => {
    if (PaymentDetails?.eventType === 'Virtual') {
      console.log('selectedSeats', selectedSeats);
      if (selectedSeats?.virtualData?.length === 0) {
        dispatch(
          AddVirtualSeatData(
            Object.values(cartvirtualData)?.[0]?.virtualData?.[0],
          ),
        );
        dispatch(
          AddVirtualSeats(
            Object.values(cartvirtualData)?.[0]?.virtualDataCounter,
          ),
        );
        dispatch(
          AddVirtualResaleSeats(
            Object.values(cartvirtualData)?.[0]?.virtualDataResaleCounter,
          ),
        );
        dispatch(
          AddVirtualSeatsID(Object.values(cartvirtualData)?.[0]?.virtualDataID),
        );
      }
    } else {
      // console.log('here', selectedSeats);
      if (selectedSeats?.data?.length === 0) {
        dispatch(AddTotalSeats(Object.values(cartInpersonData)?.[0]));
      }
    }
  }, [
    cartDataType,
    cartvirtualData,
    cartInpersonData,
    selectedSeats,
    PaymentDetails?.eventType,
    dispatch,
  ]);

  const handelDelete = useCallback(
    async (type = '') => {
      try {
        let datas = await deleteTicketSeatMap(
          localStorage.getItem('cart_seat_id'),
          json_header,
        );
        if (datas) {
          // message.success('Deleted successfully');
        }
        dispatch(DeleteAllSeats([]));
        if (type === '') {
          navigate(
            `/ticket-confirmation/error?slug=${searchParams.get(
              'slug',
            )}&ticket_id=${localStorage.getItem(
              'cart_seat_id',
            )}&event_id=${searchParams.get('event_id')}&user_id=${
              searchParams.get('user_id') || decodedToken?.id
            }`,
          );
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    },
    [dispatch, json_header, navigate, searchParams, decodedToken?.id],
  );

  const getTotal = useCallback(
    (convenience_fee = true) => {
      let price = 0;
      if (PaymentDetails?.eventType === 'Virtual') {
        PaymentDetails?.type?.map(
          (data) =>
            (price += Number(
              data?.resale_price !== null && data?.resale_price !== undefined
                ? Number(
                    parseFloat(
                      data?.resale_price?.toString()?.replace(/,/g, ''),
                    ),
                  )
                : data.price,
            )),
        );
      } else {
        if (selectedSeats?.data?.length > 0) {
          selectedSeats?.data?.map(
            (data) =>
              (price += Number(
                data?.resale_price
                  ? Number(parseFloat(data?.resale_price?.replace(/,/g, '')))
                  : Number(data.price),
              )),
          );
        }
      }
      if (convenience_fee) {
        return price + 0.52;
      } else {
        return price;
      }
    },
    [PaymentDetails?.eventType, PaymentDetails?.type, selectedSeats?.data],
  );

  const handelDeleteOnly = useCallback(
    async (res = '') => {
      try {
        let datas = await deleteTicketSeatMap(
          localStorage.getItem('cart_seat_id'),
          json_header,
        );
        if (datas) {
          // message.success('Deleted successfully');
          if (res) {
            let obj = {
              payment_id: res?.error?.metadata?.payment_id,
              account_id: decodedToken?.id,
              date: moment(),
              amount: Number(parseFloat(getTotal()).toFixed(2)).toLocaleString(
                'en',
                {
                  minimumFractionDigits: 2,
                },
              ),
              status: false, //in case of failed payment
              currency: 'USD',
              type: 'debit',
              transaction_for: 'purchase',
              event_id: PaymentDetails?.event_id, //!Added
            };
            const response = await addMoney(obj);
            if (!response?.status) {
            }
          }
          //testing only
          navigate(
            `/ticket-confirmation/error?slug=${searchParams.get(
              'slug',
            )}&ticket_id=${localStorage.getItem(
              'cart_seat_id',
            )}&event_id=${searchParams.get('event_id')}&user_id=${
              searchParams.get('user_id') || decodedToken?.id
            }`,
          );
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    },
    [
      PaymentDetails?.event_id,
      getTotal,
      navigate,
      json_header,
      searchParams,
      decodedToken?.id,
    ],
  );

  const handleUpdateCartDetails = useCallback(async () => {
    updateCartSeatsDetails(
      {
        cart_seats_id:
          localStorage.getItem('cart_seat_id') || data?.data[0]?._id,
        payment_status: true,
      },
      json_header,
    );
  }, [data?.data, json_header]);

  //credit to the organizer account
  const handleUSDPaymentOrganiser = useCallback(async () => {
    const obj = {
      payment_id: `usd${Math.floor(100000000 + Math.random() * 900000000)}`,
      account_id: PaymentDetails?.user_id, //organiser ID
      date: moment(),
      amount: Number(parseFloat(getTotal(false)).toFixed(2)).toLocaleString(
        'en',
        {
          minimumFractionDigits: 2,
        },
      ),
      status: true,
      customer_id: decodedToken?.id, //to show buyer details
      type: 'credit',
      currency: 'USD',
      transaction_for: 'purchase',
      event_id: PaymentDetails?.event_id, //!Added
    };

    const response = await addMoney(obj);
    if (response?.status) {
      return obj.payment_id;
    } else {
    }
  }, [
    PaymentDetails?.event_id,
    PaymentDetails?.user_id,
    getTotal,
    decodedToken?.id,
  ]);
  //debited from buyer account
  const handleUSDPayment = useCallback(
    async (status = true) => {
      //status true debit status false credit

      const obj = {
        payment_id: `usd${Math.floor(100000000 + Math.random() * 900000000)}`,
        account_id: decodedToken?.id,
        date: moment(),
        amount: Number(parseFloat(getTotal()).toFixed(2)).toLocaleString('en', {
          minimumFractionDigits: 2,
        }),
        customer_id: PaymentDetails?.user_id, //organiser ID to show organizer details,
        status: true,
        type: status ? 'debit' : 'credit',
        currency: 'USD',
        transaction_for: status ? 'purchase' : 'refund',
        event_id: PaymentDetails?.event_id, //!Added
      };

      // setTransactionDetails([...transactionDetails, obj]);
      // localStorage.setItem(
      //   'GOTIX_wallet_payment',
      //   JSON.stringify([...transactionDetails, obj]),
      // );
      const response = await addMoney(obj);
      if (response?.status) {
        // return obj.payment_id;
        return response?.data?.id;
      } else {
      }
    },
    [
      PaymentDetails?.event_id,
      PaymentDetails?.user_id,
      getTotal,
      decodedToken?.id,
    ],
  );
  //check for resale ticket
  const handleResaleTicketAndPayment = useCallback(async () => {
    let policy = PaymentDetails?.policies?.filter(
      (policy) =>
        policy?.sub_policy_type === 'Resale Policies' &&
        policy.currently_in_use,
    );

    let ticketOnResale = PaymentDetails.type.filter(
      (ticket) => ticket.is_resale,
    );

    let orignalTickets = PaymentDetails.type.filter(
      (ticket) => !ticket.is_resale,
    );

    console.log(orignalTickets, ticketOnResale, 'policy');
    let price = 0;
    // if (PaymentDetails?.eventType === 'Virtual') {
    //   PaymentDetails?.type?.map((data) => (price += Number(data.price)));
    // } else {
    if (orignalTickets.length > 0) {
      orignalTickets.map((data) => (price += Number(data.price)));
    }
    // }

    let obj = {
      payment_id: `usd${Math.floor(100000000 + Math.random() * 900000000)}`,
      account_id: PaymentDetails?.user_id, //organiser ID
      date: moment(),
      amount: Number(parseFloat(price).toFixed(2)).toLocaleString('en', {
        minimumFractionDigits: 2,
      }),
      status: true,
      customer_id: decodedToken?.id ?? localStorage.getItem('guest_user_id'), //to show buyer details
      type: 'credit',
      currency: 'USD',
      transaction_for: 'purchase',
      event_id: PaymentDetails?.event_id, //!Added
    };

    if (Boolean(price)) {
      // This condition is for adding the amount in original event organiser for original ticket if price is greater than 0
      const response = await addMoney(obj);
      if (response?.status) {
        // message.success('payment success');
        // return obj.payment_id;
      } else {
        return;
      }
    }

    let group = ticketOnResale.reduce((rv, x) => {
      rv[x.reseller_id] = rv[x.reseller_id] || [];
      rv[x.reseller_id].push(x);
      return rv;
    }, {});

    console.log(group, 'ticket on resale');

    Object.entries(group)?.forEach(async ([key, value]) => {
      if (key !== 'null') {
        value?.forEach(async (ticket) => {
          let resalePrice = ticket?.resale_price || 0;
          // Number(parseFloat(ticket?.resale_price?.replace(/,/g, ''))) || 0;

          let orignalPrice = ticket?.price;

          let royalty = policy[0]?.values[0]?.royalty || 0;
          let payableAmountToEO;
          let payableAmountToSR;
          if (resalePrice > orignalPrice) {
            payableAmountToEO = ((resalePrice - orignalPrice) * royalty) / 100;
            // payableAmountToSR = resalePrice - orignalPrice - payableAmountToEO;
            payableAmountToSR = resalePrice - payableAmountToEO;
          } else {
            payableAmountToEO = 0;
            payableAmountToSR = resalePrice;
          }

          let EOobj = {
            payment_id: `usd${Math.floor(
              100000000 + Math.random() * 900000000,
            )}`,
            account_id: PaymentDetails?.user_id, //organizer id
            date: moment(),
            amount: Number(
              parseFloat(payableAmountToEO).toFixed(2),
            ).toLocaleString('en', {
              minimumFractionDigits: 2,
            }),
            customer_id:
              decodedToken?.id ?? localStorage.getItem('guest_user_id'), //customer ID to show organizer details,
            status: true,
            type: 'credit',
            currency: 'USD',
            transaction_for: 'Royalty',
            event_id: PaymentDetails?.event_id, //!Added
          };

          // Person who put ticket on resale
          let SRobj = {
            payment_id: `usd${Math.floor(
              100000000 + Math.random() * 900000000,
            )}`,
            account_id: key, //reseller id
            date: moment(),
            amount: Number(
              parseFloat(payableAmountToSR).toFixed(2),
            ).toLocaleString('en', {
              minimumFractionDigits: 2,
            }),
            customer_id: PaymentDetails?.user_id, //Organizer ID to show organizer details,
            status: true,
            type: 'credit',
            currency: 'USD',
            transaction_for: 'Resale',
            event_id: PaymentDetails?.event_id, //!Added
          };

          const responseEo = await addMoney(EOobj);
          const responseSr = await addMoney(SRobj);
          if (responseEo?.status) {
            // message.success('Event Organizer payment success');
          } else {
            return;
          }
          if (responseSr?.status) {
            // message.success('Seller payment success');
          } else {
            return;
          }
        });
      }
    });
  }, [
    PaymentDetails?.event_id,
    PaymentDetails?.policies,
    PaymentDetails.type,
    PaymentDetails?.user_id,
    decodedToken?.id,
  ]);
  // handleResaleTicketAndPayment();

  // const { ClearCartData } = useCartCheckOut();

  const handleBookTickets = useCallback(
    async (data) => {
      setPaymentLoader(true);
      dispatch(updateCheckoutPaymentLoader(true));
      dispatch(updateTotalAttempt(3));
      dispatch(setOpenTimerattemptModal(false));
      let payload = {
        ticket_id: PaymentDetails?.ticket_id,
        event_id: PaymentDetails?.event_id,
        cart_seats_id: localStorage.getItem('cart_seat_id'),
        type: PaymentDetails?.type,
        billing_info: Object.keys(Billing).length
          ? Billing
          : checkout_billing_summary?.billing_address,
        event_type: PaymentDetails?.eventType,
        payment_id: data ? data : '',
        payment_method:
          localStorage.getItem('payment_method') === 'other'
            ? 'Razorpay'
            : 'USD Wallet',
        user_id: decodedToken?.id ?? localStorage.getItem('guest_user_id'),
        // user_id: PaymentDetails?.user_id, // added to avoid the linking issue for event organizer ~ akash
        user_type: !decodedToken ? 'guest' : undefined,
      };
      let purchaseDetails = { ...PaymentDetails, payment_id: data };
      localStorage.setItem('PurchasedTickets', JSON.stringify(purchaseDetails));
      const headers = {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const res = await bookTickets(
        payload,
        decodedToken ? json_header : headers,
      );
      if (res?.status) {
        //add money to organizer account
        // ClearCartData();
        handleUpdateCartDetails();
        // then((res) => {
        //   ClearCartData();
        // });
        // 1. we need to check for resale ticket
        //2. if yes then seperate this ticket
        //3. we need to do 3 transaction a. royalty to organizer b. resale amount to resaller c. other ticket amount to organizer
        let ticketOnResale = PaymentDetails.type.filter(
          (ticket) => ticket.is_resale,
        );
        console.log(ticketOnResale, ticketOnResale?.length, 'resale data');
        if (ticketOnResale?.length > 0) {
          console.log(ticketOnResale, 'comes here....');
          handleResaleTicketAndPayment();
        } else {
          handleUSDPaymentOrganiser();
        }
      } else {
        message.error('something went wrong please try again');
        setPaymentLoader(false);
        dispatch(updateCheckoutPaymentLoader(false));
        setError(true);
        handleUSDPayment(false);
        navigate(`/event-detail/${searchParams.get('slug')}`);
      }
    },
    [
      Billing,
      PaymentDetails,
      handleResaleTicketAndPayment,
      handleUSDPayment,
      handleUSDPaymentOrganiser,
      handleUpdateCartDetails,
      json_header,
      navigate,
      searchParams,
      dispatch,
      checkout_billing_summary?.billing_address,
      decodedToken,
    ],
  );

  const handleSaveTicket = useCallback(async () => {
    const method = localStorage.getItem('payment_method');

    if (method === 'usd_wallet') {
      // deduct the money from wallet of seller
      setBtnStatus('Processing...');
      await handleUSDPayment()
        .then((res) => {
          handleBookTickets(res);
          setBtnStatus('Checkout');
        })
        .catch(() => {
          setBtnStatus('Checkout');
        });
      // book the tickets
    } else {
      if (Object.keys(PaymentDetails).length > 0) {
        const order = await make_ticket_order({
          amount: Number(Number(getTotal() * 100)?.toFixed(2) || 0),
          currency: 'USD',
          receipt: 'test_receipt',
        });
        setBtnStatus('Processing...');
        if (order.data?.status) {
          displayRazorpay(
            order?.data?.data,
            setBtnStatus,
            userDetails,
            handleBookTickets,
            handelDelete,
            'billCheckout',
            searchParams.get('slug'),
            handelDeleteOnly,
            'order_purchase',
            decodedToken?.id,
          );
        }
      }
    }
  }, [
    PaymentDetails,
    getTotal,
    handelDelete,
    handelDeleteOnly,
    handleBookTickets,
    handleUSDPayment,
    searchParams,
    userDetails,
    decodedToken?.id,
  ]);

  const saveTicketMutation = useMutation(handleSaveTicket, {
    onSuccess: (data) => {
      console.info(data);
    },
    onError: (data) => {
      navigate(
        `/ticket-confirmation/error?slug=${searchParams.get(
          'slug',
        )}&ticket_id=${localStorage.getItem(
          'cart_seat_id',
        )}&event_id=${searchParams.get('event_id')}&user_id=${
          searchParams.get('user_id') || decodedToken?.id
        }`,
      );
    },
  });

  const goBack = useCallback(async () => {
    if (localStorage.getItem('cart_seat_id')) {
      let datas = await deleteTicketSeatMap(
        localStorage.getItem('cart_seat_id'),
        json_header,
      );
      if (datas) {
        // message.success('Deleted successfully');
      }
      dispatch(DeleteAllSeats([]));
      PaymentDetails?.eventType === 'Virtual'
        ? setOpenmodal(true)
        : navigate(
            `/seat-booking/?v=${
              eventAllDetails?.data?.venue[0]?._id
            }&slug1=${searchParams.get('slug')}`,
          );
    } else {
      PaymentDetails?.eventType === 'Virtual'
        ? setOpenmodal(true)
        : navigate(
            `/seat-booking/?v=${
              eventAllDetails?.data?.venue[0]?._id
            }&slug1=${searchParams.get('slug')}`,
          );
    }
  }, [
    PaymentDetails,
    eventAllDetails,
    searchParams,
    dispatch,
    json_header,
    navigate,
  ]);

  const goBackToHomePage = useCallback(() => {
    if (localStorage.getItem('cart_seat_id')) {
      handelDelete('backhome');
      setBackToHome(true);
    } else {
      setBackToHome(true);
    }
  }, [handelDelete]);

  const values = useMemo(
    () => ({
      PaymentDetails,
      selectedSeats,
      OpenModal,
      backToHome,
      btnStatus,
      checkout_event: Object.keys(checkout_event_details)?.length
        ? checkout_event_details
        : eventAllDetails,
      saveTicketMutation,
      userDetails,
      userdetails: userdetails?.data,
      paymentLoader,
      checkout_payment_loader,
      checkout_billing_summary,
      method,
      setBtnStatus,
      goBack,
      goBackToHomePage,
      handleSaveTicket,
      handleBookTickets,
      handleResaleTicketAndPayment,
      handleUSDPayment,
      handleUSDPaymentOrganiser,
      handleUpdateCartDetails,
      handelDeleteOnly,
      getTotal,
      handelDelete,
      setOpenmodal,
      setBackToHome,
      slug1,
      form,
    }),
    [
      userDetails,
      PaymentDetails,
      selectedSeats,
      OpenModal,
      backToHome,
      btnStatus,
      userdetails,
      paymentLoader,
      checkout_event_details,
      eventAllDetails,
      checkout_billing_summary,
      checkout_payment_loader,
      method,
      goBack,
      goBackToHomePage,
      saveTicketMutation,
      handleSaveTicket,
      handleBookTickets,
      handleResaleTicketAndPayment,
      handleUSDPayment,
      handleUSDPaymentOrganiser,
      handleUpdateCartDetails,
      handelDeleteOnly,
      getTotal,
      handelDelete,
      setOpenmodal,
      setBackToHome,
      slug1,
      form,
    ],
  );

  return values;
}

export function useCheckoutTicket() {
  const { PaymentDetails, selectedSeats } = useCheckoutActions();

  const { groupBy } = useConsolidatedTicket();
  const { eventType } = PaymentDetails;
  const ticket_category = useMemo(() => {
    if (eventType === 'Virtual') {
      const grouped = Object.fromEntries(
        groupBy(PaymentDetails?.type, (item) => item.name),
      );
      return { tickets: grouped, isVirtual: true };
    }
    const sorted_array = selectedSeats?.data?.toSorted(
      (a, b) => Number(b?.price) - Number(a?.price),
    );
    return { tickets: sorted_array, isVirtual: false };
  }, [eventType, groupBy, PaymentDetails?.type, selectedSeats?.data]);

  const ticket_price_for_category = useCallback((arr) => {
    const sum = Array.isArray(arr)
      ? arr.reduce((accumulator, object) => {
          return (
            accumulator +
            (object?.resale_price
              ? Number(
                  parseFloat(
                    object?.resale_price?.toString()?.replace(/,/g, ''),
                  ),
                )
              : Number(object?.price))
          );
        }, 0)
      : 0;
    return sum;
  }, []);

  const sub_total_price = useMemo(() => {
    if (eventType === 'Virtual') {
      const total = ticket_price_for_category(
        Object.values(ticket_category?.tickets)?.flat(),
      );
      return { total, grand_total: Number((total ?? 0) + 0.52) };
    }
    const total = ticket_price_for_category(ticket_category?.tickets);
    return { total, grand_total: Number((total ?? 0) + 0.52) };
  }, [ticket_category?.tickets, ticket_price_for_category, eventType]);

  return { ticket_category, ticket_price_for_category, sub_total_price };
}

export function useCheckoutBilling() {
  const {
    PaymentDetails,
    selectedSeats,
    saveTicketMutation,
    checkout_billing_summary,
  } = useCheckoutActions();

  const { json_header } = useApiHeader();
  const decodedToken = useDecodedToken();
  const [billingModalState, setBillingModalState] = useState({
    payment_modal: false,
    payment_alert_modal: false,
    payment_policy_modal: false,
  });
  function insertNote() {
    //here add loader true

    let body;
    if (PaymentDetails?.eventType === 'Virtual') {
      body = {
        seat_data: changeObjectKey(PaymentDetails?.type),
        user_id: decodedToken?.id,
        event_id: PaymentDetails?.event_id,
        // price_seatmap_id: "",
      };
    } else {
      body = {
        seat_data: selectedSeats?.data,
        user_id: decodedToken?.id,
        event_id: selectedSeats?.data[0]?.event_id,
        price_seatmap_id: selectedSeats?.data[0]?.price_seatmap_id,
      };
    }

    if (!decodedToken) {
      delete body['user_id'];
      body = {
        ...body,
        user_info: {
          email: checkout_billing_summary?.billing_address?.email,
          billing_info: checkout_billing_summary?.billing_address,
        },
      };
    }
    // console.log(body, checkout_billing_summary)
    return postSeatMappingDetails(body, json_header);
  }

  /// involking the API when user clicked proceed with USDWallet.
  const handleSaveAndPayment = () => {
    mutationsave.mutate();
  };

  /// involking the API when user clicked proceed with USDWallet.

  const mutationsave = useMutation(insertNote, {
    onSuccess: async (res) => {
      if (res?.data) {
        localStorage.setItem('cart_seat_id', res?.data);
        if (res?.user_id) {
          localStorage.setItem('guest_user_id', res?.user_id);
        }
        if (res?.access_token) {
          localStorage.setItem('access_token', res?.access_token);
        }
        saveTicketMutation.mutate();
        setBillingModalState({ ...billingModalState, payment_modal: false });
      }
      //here add loader false
    },
    onError: (err) => {
      //here add loader false
      console.error(err);
      message.error(err?.response?.data?.message);
    },
  });

  const getTicketCancelDisableTime = () => {
    let days = 100;
    let hours = 100;
    PaymentDetails?.policies
      ?.filter((policy) => policy?.sub_policy_type === 'Refund Policies')[0]
      ?.values?.forEcah((data) => {
        if (data?.timeframeSelect === 'Hour') {
          if (hours >= Number(data?.timeframeValue)) {
            hours = Number(data?.timeframeValue);
          }
        } else {
          if (days >= Number(data?.timeframeValue)) {
            days = Number(data?.timeframeValue);
          }
        }
      });

    if (hours === 100 && days !== 100) {
      return `${days} days`;
    } else if (hours !== 100 && days === 100) {
      return `${hours} Hours`;
    } else if (hours !== 100 && days !== 100) {
      return `${hours} Hours`;
    } else if (hours === 100 && days === 100) {
      return '';
    }
  };

  const country_list = CountryCodes;
  const countries = country_list?.map(({ code, label: country_name }) => ({
    key: code,
    value: country_name,
    label: (
      <span className="flex items-center gap-2 dark:text-whiteNew-0 text-zinc-800">
        <Image
          className="h-4"
          src={`https://flagcdn.com/w20/${String(code).toLowerCase()}.png`}
        />
        {country_name}
      </span>
    ),
  }));

  const phones = country_list?.map(({ code, phone, label }) => ({
    key: code,
    value: `${code}+${phone}`,
    label: (
      <span className="flex items-center gap-2 dark:text-whiteNew-0 text-zinc-800">
        <Image
          className="h-4"
          src={`https://flagcdn.com/w20/${String(code).toLowerCase()}.png`}
        />
        {code} +{phone}
      </span>
    ),
  }));

  const setToLocalstorage = useCallback((data) => {
    if (data?.email) {
      localStorage.setItem(
        'billingAddress',
        JSON.stringify({ billing_address: data }),
      );
    }
    return <></>;
  }, []);

  return {
    handleSaveAndPayment,
    getTicketCancelDisableTime,
    billingModalState,
    setBillingModalState,
    countries,
    phones,
    setToLocalstorage,
  };
}

export function useCheckoutConfirmation() {
  const { status } = useParams();
  const navigate = useNavigate();
  const decodedToken = useDecodedToken();
  const status_info = useMemo(() => {
    if (status === 'success') {
      return { name: 'Success', color: 'bg-green-600', status: true };
    }
    return { name: 'Error', color: 'bg-red-600', status: false };
  }, [status]);
  const dispatch = useDispatch();

  const showDetailNFTView = useSelector((state) => state?.ShowSinglenft);

  const viewOrdersInDashboard = useCallback(() => {
    // setStartTimer(); Need to check whether we required this or not
    localStorage.removeItem('PurchasedTickets');
    localStorage.removeItem('billing_info');
    if (showDetailNFTView?.status)
      showDetailNFTView?.status &&
        dispatch(
          viewSingleNFT({
            status: false,
            NFTID: '',
          }),
        );
    if (decodedToken?.user_type === 'buyer') {
      return navigate(
        `/user/${
          decodedToken?.username || decodedToken?.id
        }/dashboard?tab=${'orders'}`,
        {
          replace: true,
        },
      );
    } else {
      navigate(
        `/user/${
          decodedToken?.username || decodedToken?.id
        }/dashboard?tab=purchase&subtab=orders`,
        {
          replace: true,
        },
      );
    }
  }, [decodedToken, dispatch, navigate, showDetailNFTView?.status]);

  const retryPayment = useCallback(() => {
    return navigate(-1);
  }, [navigate]);

  const handleGoToHome = useCallback(() => {
    // setStartTimer();
    navigate('/', { replace: true });
  }, [navigate]);

  const handleClickAction = useCallback(() => {
    if (status_info?.status) {
      return viewOrdersInDashboard();
    }
    return retryPayment();
  }, [retryPayment, viewOrdersInDashboard, status_info?.status]);

  return {
    handleClickAction,
    handleGoToHome,
    status,
    status_info,
    viewOrdersInDashboard,
  };
}

export function useTicketPoliciesInfo({
  policies_arr = [],
  resaleModeOnly = false,
  darkTheme = true,
  event,
  refundModeOnly = false,
  transferModeOnly = false,
  event_type,
}) {
  const groupByPolicies = policies_arr?.reduce((group, policy) => {
    const { sub_policy_type } = policy; //take the field
    group[sub_policy_type] = group[sub_policy_type] ?? [];
    group[sub_policy_type].push(policy);
    return group;
  }, {});

  console.log(event_type);
  const { is_virtual, is_both } = useMemo(
    () => ({
      is_virtual: event_type === 'Virtual',
      is_inperson: event_type === 'Physical',
      is_both: event_type === 'both',
    }),
    [event_type],
  );

  console.log('is_both', is_both);

  const policies = {
    'Refund Policy': [
      {
        id: 1,
        component: (
          <>
            <div
              className={`${
                resaleModeOnly || transferModeOnly ? 'hidden' : ''
              }`}
            >
              {/* <div className="mb-3 flex">
                <div
                  className={`${
                    darkTheme ? 'border-landingPurple-60' : ' border-sky-600'
                  } border-b-2`}
                >
                  <h5
                    className={`text-lg ${
                      darkTheme ? 'text-whiteNew-0' : 'text-dark'
                    }`}
                  >
                    Refund Policy
                  </h5>
                </div>
              </div> */}
              <span
                className={`dark:text-whiteNew-0 text-zinc-800 pl-4 space-y-1 text-sm`}
              >
                The event starts on{' '}
                {moment(event?.start_time)?.format(DATEFORMAT)}. The following
                policies shall be valid:-
              </span>
              <ul className="list-disc pl-6 space-y-1 text-sm">
                {groupByPolicies['Refund Policies']?.length > 0 &&
                  groupByPolicies['Refund Policies']
                    ?.filter(
                      (pol) => pol?.currently_in_use && !pol?.default_values,
                    )[0] //ADDED default_values CHECK
                    ?.values?.map((policy, index) => {
                      return (
                        <li
                          className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-800`}
                          key={policy?.id + index}
                        >
                          <span
                            className={`${
                              getMomentAgoTime(policy, event)?.remainingTime < 0
                                ? 'dark:text-gray-400 text-gray-400'
                                : 'dark:text-gray-200 font-normal text-gray-200'
                            }`}
                          >
                            <IoTicketOutline />
                          </span>
                          <span
                            className={`${
                              getMomentAgoTime(policy, event)?.remainingTime < 0
                                ? 'dark:text-gray-400 text-gray-400'
                                : 'dark:text-gray-200 font-normal text-gray-200'
                            }`}
                          >
                            {
                              <CurrencyFormat
                                thousandSeparator
                                displayType={'text'}
                                // prefix="$"
                                value={policy?.percent || 0}
                                // decimalScale={2}
                                fixedDecimalScale
                                className={`font-normal`}
                              />
                            }
                            {/* % Refund, if cancelled {policy.timeframeValue}{' '}
                          {policy.timeframeSelect === 'Hour'
                            ? 'Hours'
                            : policy.timeframeSelect}{' '}
                          before{' '}
                          {moment(event?.start_time)?.format(
                            'MM/DD/YY - HH:mm',
                          )}
                          . */}
                            % Refund, if cancelled before{' '}
                            {getMomentAgoTime(policy, event)?.timevalue}.
                          </span>
                        </li>
                      );
                    })}
                {/* {groupByPolicies['Refund Policies']?.length > 0 &&  */}
                {groupByPolicies['Refund Policies']?.filter(
                  (pol) => pol?.currently_in_use && !pol?.default_values,
                )?.length > 0 && (
                  <li
                    className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-800`}
                    key={6}
                  >
                    {' '}
                    {/* {`Ticket cannot be cancelled when ${getTicketCancelDisableTime()} left for the event. `} */}
                    {/* {`0% Refund, if cancelled less than ${getTicketCancelDisableTime()} of ${moment(
                      event?.start_time,
                    )?.format('MM/DD/YY - HH:mm')}. `} */}
                    {/* <span>
                      <IoTicketOutline />
                    </span> */}
                    <span
                      className={`${
                        getMomentAgoTime('static', event)?.remainingTime < 0
                          ? 'dark:text-gray-400 text-zinc-800'
                          : 'font-normal'
                      }`}
                    >
                      <IoTicketOutline />
                    </span>
                    <span
                      className={`${
                        getMomentAgoTime('static', event)?.remainingTime < 0
                          ? 'dark:text-gray-400 text-zinc-800'
                          : 'font-normal'
                      }`}
                    >
                      {`0% Refund, if cancelled after ${
                        getMomentAgoTime(
                          groupByPolicies['Refund Policies']
                            ?.filter(
                              (pol) =>
                                pol?.currently_in_use && !pol?.default_values,
                            )[0]
                            ?.values?.slice(-1)[0],
                          event,
                        )?.timevalue
                      } `}
                    </span>
                  </li>
                )}
              </ul>
              {/* {groupByPolicies['Refund Policies']?.length <= 0 && ( */}
              {groupByPolicies['Refund Policies']?.filter(
                (pol) => pol?.currently_in_use && pol?.default_values,
              )?.length > 0 && (
                <span
                  className={` ${darkTheme ? 'text-whiteNew-0' : 'text-dark'}`}
                >
                  <ul className="list-disc pl-6 space-y-1 text-sm">
                    <li
                      className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-800`}
                    >
                      <span>
                        <IoTicketOutline />
                      </span>
                      <span>Ticket is not Refundable.</span>
                    </li>
                  </ul>
                </span>
              )}
              {/* <div
                className={`${
                  darkTheme ? 'bg-neutral-700' : 'bg-gray-200'
                } w-full h-1 my-3`}
              ></div> */}
            </div>
          </>
        ),
      },
    ],
    'Resale Policy': [
      {
        id: 2,
        component: (
          <>
            {is_virtual ? (
              <span className={`dark:text-whiteNew-0 text-zinc-600`}>
                <ul className="pl-4 space-y-1 text-sm">
                  <li className="flex gap-1 items-center">
                    <span>
                      <IoTicketOutline />
                    </span>
                    <span>
                      Resale of tickets for virtual events are not allowed.
                    </span>
                  </li>
                </ul>
              </span>
            ) : (
              <div
              // className={`${refundModeOnly ? 'hidden' : ''}`}
              >
                {/* <div className="mb-3 flex">
                <div
                  className={`${
                    darkTheme ? 'border-landingPurple-60' : ' border-sky-600'
                  } border-b-2`}
                >
                  <h5
                    className={`text-lg ${
                      darkTheme ? 'text-whiteNew-0' : 'text-dark'
                    }`}
                  >
                    Resale Policy
                  </h5>
                </div>
              </div> */}
                <span
                  className={`dark:text-whiteNew-0 text-zinc-800 pl-4 space-y-1 text-sm`}
                >
                  The event starts on{' '}
                  {moment(event?.start_time)?.format(DATEFORMAT)}. The following
                  policies shall be valid:-
                </span>
                <ul className="list-disc pl-4 space-y-1 text-sm">
                  {groupByPolicies['Resale Policies']?.length > 0 &&
                    groupByPolicies['Resale Policies']?.filter(
                      (pol) => pol?.currently_in_use,
                    )[0]?.values[0]?.selected_resale_times_limit !==
                      'not_allowed' &&
                    groupByPolicies['Resale Policies']
                      ?.filter(
                        (pol) => pol?.currently_in_use && !pol?.default_values,
                      )[0]
                      ?.values?.map((policy, index) => {
                        return (
                          <>
                            {policy?.selected_resale_times_limit !==
                              'not_allowed' && (
                              <li
                                className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-600`}
                                key={policy?.id + index}
                              >
                                <span>
                                  <IoTicketOutline />
                                </span>
                                {policy?.selected === 'unlimited' &&
                                  `Unlimited Resale price of
                                  Original Ticket Price.`}

                                {policy?.selected === 'max_resale' &&
                                  policy?.selected_resale_times_limit !==
                                    'not_allowed' && (
                                    <span>
                                      Max Resale Limit{' '}
                                      <CurrencyFormat
                                        thousandSeparator
                                        displayType={'text'}
                                        // prefix="$"
                                        value={policy?.max_resale_limit || 0}
                                        // decimalScale={2}
                                        fixedDecimalScale
                                        className={`font-normal`}
                                      />
                                      % of Original Ticket Price.
                                    </span>
                                  )}
                                {policy?.selected === 'resale_limit' &&
                                  policy?.selected_resale_times_limit !==
                                    'not_allowed' && (
                                    <span>
                                      {/* Max Resal Limit{' '} */}
                                      <CurrencyFormat
                                        thousandSeparator
                                        displayType={'text'}
                                        prefix="$"
                                        value={policy?.resale_limit || 0}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        className={`font-normal`}
                                      />{' '}
                                      Limit for Resale ticket.
                                    </span>
                                  )}
                              </li>
                            )}

                            {policy?.royalty !== '' &&
                              policy?.selected_resale_times_limit !==
                                'not_allowed' && (
                                <li
                                  className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-600`}
                                  key={policy?.id + index}
                                >
                                  <span>
                                    <IoTicketOutline />
                                  </span>
                                  <span>
                                    <CurrencyFormat
                                      thousandSeparator
                                      displayType={'text'}
                                      // prefix="$"
                                      value={policy?.royalty || 0}
                                      // decimalScale={2}
                                      fixedDecimalScale
                                      className={`font-normal`}
                                    />
                                    % Royalty to Event Organizer of profits
                                    above original price.
                                  </span>
                                </li>
                              )}
                            <li
                              className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-600`}
                              key={policy?.id + index}
                            >
                              <span>
                                <IoTicketOutline />
                              </span>
                              <span>
                                {policy?.selected_resale_times_limit ===
                                'allowed_once'
                                  ? 'Resale Once Allowed on original tickets only'
                                  : policy?.selected_resale_times_limit ===
                                    'allowed_multiple'
                                  ? 'Resale Multiple Times Allowed'
                                  : 'Resale Not Allowed.'}
                              </span>
                            </li>
                          </>
                        );
                      })}
                  {groupByPolicies['Resale Policies']?.length > 0 &&
                    groupByPolicies['Resale Policies']?.filter(
                      (pol) => pol?.currently_in_use,
                    )[0]?.values[0]?.selected_resale_times_limit ===
                      'not_allowed' && (
                      <li
                        className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-600`}
                        key={Math.random() * 100}
                      >
                        <span>
                          <IoTicketOutline />
                        </span>
                        <span>{'Resale Not Allowed.'}</span>
                      </li>
                    )}
                </ul>

                {groupByPolicies['Resale Policies']?.filter(
                  (pol) => pol?.currently_in_use && pol?.default_values,
                )?.length > 0 && (
                  <span className={`dark:text-whiteNew-0 text-zinc-600`}>
                    <ul className="pl-4 space-y-1 text-sm">
                      <li className="flex gap-1 items-center">
                        <span>
                          <IoTicketOutline />
                        </span>
                        <span>Ticket cannot be Resold.</span>
                      </li>
                    </ul>
                  </span>
                )}

                {(is_both || is_virtual) &&
                groupByPolicies['Resale Policies']?.length > 0 &&
                groupByPolicies['Resale Policies']?.filter(
                  (pol) => pol?.currently_in_use,
                )[0]?.values[0]?.selected_resale_times_limit !==
                  'not_allowed' ? (
                  <span className={`dark:text-whiteNew-0 text-zinc-600`}>
                    <ul className="pl-4 space-y-1 text-sm">
                      <li className="flex gap-1 items-center">
                        <span>
                          <IoTicketOutline />
                        </span>
                        <span>
                          Resale of tickets for virtual events are not allowed.
                        </span>
                      </li>
                    </ul>
                  </span>
                ) : null}
                {/* <div
                className={`${
                  darkTheme ? 'bg-neutral-700' : 'bg-gray-200'
                } w-full h-1 my-3`}
              ></div> */}
              </div>
            )}
          </>
        ),
      },
    ],
    'Share and Transfer policy': [
      {
        id: 3,
        component: (
          <>
            <div
              className={`${resaleModeOnly || refundModeOnly ? 'hidden' : ''}`}
            >
              {/* <div className="mb-3 flex">
                <div
                  className={`${
                    darkTheme ? 'border-landingPurple-60' : ' border-sky-600'
                  } border-b-2`}
                >
                  <h5
                    className={`text-lg ${
                      darkTheme ? 'text-whiteNew-0' : 'text-dark'
                    }`}
                  >
                    Transfer Ticket Policy
                  </h5>
                </div>
              </div> */}
              {/* {groupByPolicies['Shared And Transfer Policies']?.length <= 0 && ( */}
              {!groupByPolicies['Shared And Transfer Policies']?.[0]
                ?.default_values &&
                !groupByPolicies['Shared And Transfer Policies']?.[0]
                  ?.shared_default_values && (
                  <span
                    className={`pl-4 space-y-1 text-sm dark:text-whiteNew-0`}
                  >
                    The event starts on{' '}
                    {moment(event?.start_time)?.format(DATEFORMAT)}. The
                    following policies shall be valid:-
                  </span>
                )}
              <ul className="list-disc pl-6 space-y-1 text-sm">
                {groupByPolicies['Shared And Transfer Policies']?.length > 0 &&
                  groupByPolicies['Shared And Transfer Policies']?.map(
                    (pol, index) => (
                      <span
                        key={index}
                        className={`dark:text-whiteNew-0 text-zinc-800`}
                      >
                        {pol?.currently_in_use && (
                          <>
                            {pol?.default_values ? (
                              <li
                                className={`flex gap-1 items-center ${
                                  darkTheme ? 'text-whiteNew-0' : 'text-dark'
                                }`}
                                key={pol?._id + index}
                              >
                                {' '}
                                <span>
                                  <IoTicketOutline />
                                </span>
                                {`Tickets are non-transferable.`}
                              </li>
                            ) : (
                              <li
                                className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-800`}
                                key={pol?._id + index}
                              >
                                <span>
                                  <IoTicketOutline />
                                </span>
                                <span>
                                  {' '}
                                  <CurrencyFormat
                                    thousandSeparator
                                    displayType={'text'}
                                    prefix="$"
                                    value={pol?.values?.[0]?.price || 0}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    className={`font-normal`}
                                  />{' '}
                                  {`Fees Charged for Transfer.`}
                                </span>
                              </li>
                            )}
                            {pol?.shared_default_values ? (
                              <li
                                className={`flex gap-1 items-center ${
                                  darkTheme ? 'text-whiteNew-0' : 'text-dark'
                                }`}
                                key={pol?._id + index}
                              >
                                {' '}
                                <span>
                                  <IoTicketOutline />
                                </span>
                                {`Tickets are non-shareable.`}
                              </li>
                            ) : (
                              <li
                                className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-800`}
                                key={pol?._id + index}
                              >
                                <span>
                                  <IoTicketOutline />
                                </span>
                                <span>
                                  {' '}
                                  <CurrencyFormat
                                    thousandSeparator
                                    displayType={'text'}
                                    prefix="$"
                                    value={pol?.values?.[0]?.share_price || 0}
                                    // decimalScale={2}s
                                    fixedDecimalScale
                                    className={`font-normal`}
                                  />{' '}
                                  {`Fees Charged for Share.`}
                                </span>
                              </li>
                            )}
                          </>
                        )}
                      </span>
                    ),
                  )}
              </ul>
            </div>
          </>
        ),
      },
    ],
    'Cancelled Event Policy': [
      {
        id: 4,
        component: (
          <>
            <div
              className={`${resaleModeOnly || refundModeOnly ? 'hidden' : ''}`}
            >
              {/* <div className="mb-3 flex">
                <div
                  className={`${
                    darkTheme ? 'border-landingPurple-60' : ' border-sky-600'
                  } border-b-2`}
                >
                  <h5
                    className={`text-lg ${
                      darkTheme ? 'text-whiteNew-0' : 'text-dark'
                    }`}
                  >
                    Cancelled Event Policy
                  </h5>
                </div>
              </div> */}
              <span
                className={`pl-4 space-y-1 text-sm dark:text-whiteNew-0 text-zinc-800`}
              >
                The event starts on{' '}
                {moment(event?.start_time)?.format(DATEFORMAT)}. The following
                policies shall be valid:-
              </span>
              <ul className="list-disc pl-6 space-y-1 text-sm">
                {groupByPolicies['Cancelled Event']?.length > 0 &&
                  groupByPolicies['Cancelled Event']
                    ?.filter((pol) => pol?.currently_in_use)[0]
                    ?.values?.map((policy, index) => {
                      return (
                        <>
                          <li
                            className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-800`}
                            key={policy?.id + index}
                          >
                            <span>
                              <IoTicketOutline />
                            </span>
                            <span>
                              Buyer will get{' '}
                              <CurrencyFormat
                                thousandSeparator
                                displayType={'text'}
                                // prefix="$"
                                value={policy?.percent || 100}
                                // decimalScale={2}
                                fixedDecimalScale
                                className={`font-normal`}
                              />
                              % Refund if event is Cancelled.
                            </span>
                          </li>
                        </>
                      );
                      // }
                    })}
              </ul>
              {/* {groupByPolicies['Cancelled Event']?.length <= 0 && ( */}
              {groupByPolicies['Cancelled Event']?.filter(
                (pol) => pol?.currently_in_use,
              )?.length <= 0 && (
                <span className={`dark:text-whiteNew-0 text-zinc-800`}>
                  <ul className="list-disc pl-6 space-y-1 text-sm">
                    <li
                      className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-800`}
                    >
                      <span>
                        <IoTicketOutline />
                      </span>
                      100 % of Ticket value will be Refundable.
                    </li>
                  </ul>
                </span>
              )}
              {/* <div
                className={`${
                  darkTheme ? 'bg-neutral-700' : 'bg-gray-200'
                } w-full h-1 my-3`}
              ></div> */}
            </div>
          </>
        ),
      },
    ],
    'Delayed Event Policy': [
      {
        id: 5,
        component: (
          <>
            <div
              className={`${resaleModeOnly || refundModeOnly ? 'hidden' : ''}`}
            >
              {/* <div className="mb-3 flex">
                <div
                  className={`${
                    darkTheme ? 'border-landingPurple-60' : ' border-sky-600'
                  } border-b-2`}
                >
                  <h5
                    className={`text-lg ${
                      darkTheme ? 'text-whiteNew-0' : 'text-dark'
                    }`}
                  >
                    Delayed Event Policy
                  </h5>
                </div>
              </div> */}
              <span
                className={`pl-4 space-y-1 text-sm dark:text-whiteNew-0 text-zinc-800`}
              >
                The event starts on{' '}
                {moment(event?.start_time)?.format(DATEFORMAT)}. The following
                policies shall be valid:-
              </span>
              <ul className="list-disc pl-4 space-y-1 text-sm">
                {groupByPolicies['Delayed Event']?.length > 0 &&
                  groupByPolicies['Delayed Event']
                    ?.filter((pol) => pol?.currently_in_use)[0]
                    ?.values?.map((policy, index) => {
                      return (
                        <>
                          <li
                            className={`flex gap-1 items-center dark:text-whiteNew-0 text-zinc-800`}
                            key={policy?.id + index}
                          >
                            <span>
                              <IoTicketOutline />
                            </span>
                            <span>
                              Buyer will get{' '}
                              <CurrencyFormat
                                thousandSeparator
                                displayType={'text'}
                                // prefix="$"
                                value={policy?.percent || 0}
                                // decimalScale={2}
                                fixedDecimalScale
                                className={`font-normal`}
                              />
                              % Refund if event is postponed.
                            </span>
                          </li>
                        </>
                      );
                      // }
                    })}
              </ul>
              {/* {groupByPolicies['Delayed Event']?.length <= 0 && ( */}
              {groupByPolicies['Delayed Event']?.filter(
                (pol) => pol?.currently_in_use,
              )?.length <= 0 && (
                // <span
                //   className={` ${darkTheme ? 'text-whiteNew-0' : 'text-dark'}`}
                // >
                <ul className="list-disc pl-4 space-y-1 text-sm">
                  <li
                    className={`flex gap-1 items-center ${
                      darkTheme ? 'text-whiteNew-0' : 'text-dark'
                    }`}
                  >
                    <span>
                      <IoTicketOutline />
                    </span>{' '}
                    Ticket is not Refundable.
                  </li>
                </ul>
                // </span>
              )}
              {/* <div
                className={`${
                  darkTheme ? 'bg-neutral-700' : 'bg-gray-200'
                } w-full h-1 my-3`}
              ></div> */}
            </div>
          </>
        ),
      },
    ],
  };

  return { policies };
}

export function useCheckoutConfirmationModal() {
  //this function calculate the total amount in our USD wallet and displa it to user conditionally
  const { billingModalState, setBillingModalState, handleSaveAndPayment } =
    useCheckoutBilling();
  const { btnStatus, setBtnStatus, userdetails } = useCheckoutActions();
  const { checkout_billing_summary } = useSelector((s) => s.checkout);
  const { usd_money } = useSelector((s) => s.userDetails);
  const totalAmount = checkout_billing_summary?.sub_total_price?.grand_total;
  const { fetchUSDWallet } = useUserDetailsSWR();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabledFirst, setIsDisabledFirst] = useState(false);
  const [isDisabledOther, setIsDisabledOther] = useState(false);
  const [required, setRequired] = useState(0);
  const [error, setError] = useState('');
  // const user_id = JSON.parse(localStorage.getItem('userID'));
  const decodedToken = useDecodedToken();

  // const [btnStatus, setBtnStatus] = useState();
  const { data, refetch } = useUserMoneyDetails();
  //reset condition function
  const reset = useCallback(() => {
    setError('');
    setBillingModalState({ ...billingModalState, payment_modal: false });
    setIsDisabled(false);
    setIsDisabledFirst(false);
    setIsDisabledOther(false);
  }, [billingModalState, setBillingModalState]);

  const checkForWalletMoney = useCallback(async () => {
    await fetchUSDWallet({ is_refetching: true });
  }, [fetchUSDWallet]);

  useOnceCall(() => fetchUSDWallet({ is_refetching: true }));
  const difference = useMemo(
    () => Number(totalAmount - usd_money),
    [totalAmount, usd_money],
  );

  const getTotal = useCallback(() => {
    let price = 0;
    price += Number(parseFloat(required));
    return price;
  }, [required]);

  //this function is for adding money to our own wallet when booking
  const handleBooktickets = useCallback(
    async (paymentId) => {
      let obj = {
        payment_id: paymentId,
        account_id: decodedToken?.id,
        date: moment(),
        amount: difference > 5999 ? `5999.00` : difference?.toFixed(2),
        status: true,
        customer_id: decodedToken?.id, //funds added on self
        currency: 'USD',
        type: 'credit',
        transaction_for: 'funds_added',
      };
      const response = await addMoney(obj);
      if (response?.status) {
        refetch();
        setError('');
        setIsDisabled(false);
        setIsDisabledFirst(false);
        setIsDisabledOther(false);
        checkForWalletMoney();
      } else {
        setIsDisabled(false);
        setIsDisabledFirst(false);
        setIsDisabledOther(false);
      }
    },
    [checkForWalletMoney, refetch, decodedToken?.id, difference],
  );

  //if razerpay failed to load then is function is invoke to reset the conditions on buttons
  const handelDelete = useCallback(() => {
    setIsDisabled(false);
    setIsDisabledFirst(false);
    setIsDisabledOther(false);
    return;
  }, []);

  //if razerpay payment failed ,model dismiss then is function is invoke to delete the cart data which is created while payment
  // and reset other conditions and navigate to error page and show payment failed
  const handelDeleteOnly = useCallback(
    async (res = '') => {
      setIsDisabled(false);
      setIsDisabledFirst(false);
      setIsDisabledOther(false);
      if (res) {
        let obj = {
          payment_id: res?.error?.metadata?.payment_id,
          account_id: decodedToken?.id,
          date: moment(),
          amount: difference > 5999 ? `5999.00` : difference?.toFixed(2),
          status: false, //in case of failed payment
          customer_id: decodedToken?.id, //funds added on self
          currency: 'USD',
          type: 'debit',
          transaction_for: 'funds_added',
        };
        const response = await addMoney(obj);
        if (!response?.status) {
        }
      }
      return;
    },
    [decodedToken?.id, difference],
  );

  // useCallback added to all the onchange and onClick function in optimisations.

  const getWalletMoney = useCallback(() => {
    return checkForWalletMoney();
  }, [checkForWalletMoney]);

  //this function is for making payment with our USD wallet
  const ProceedWithUSDWallet = useCallback(() => {
    setIsDisabledFirst(true);
    handleSaveAndPayment();
    localStorage.setItem('payment_method', 'usd_wallet');
  }, [handleSaveAndPayment]);

  //function made order and  trigger the razerpay model to add money to our USD wallet
  const addMoneyInWallet = useCallback(async () => {
    setIsDisabled(true);
    setIsDisabledOther(true);
    let order;

    if (difference > 5999) {
      order = await make_ticket_order({
        amount: Number(Number(5999 * 100).toFixed(2)),
        currency: 'USD',
        receipt: 'test_receipt',
      });
    } else {
      order = await make_ticket_order({
        amount: Number(Number(difference * 100).toFixed(2)),
        currency: 'USD',
        receipt: 'test_receipt',
      });
    }

    displayRazorpay(
      order?.data?.data,
      setBtnStatus,
      userdetails,
      handleBooktickets,
      handelDelete,
      'orders',
      'slug',
      handelDeleteOnly,
      'funds_added',
      decodedToken?.id, // Added the check for the non registered user
    ).then((res) => {
      checkForWalletMoney();
    });
  }, [
    userdetails,
    setBtnStatus,
    handelDeleteOnly,
    handleBooktickets,
    handelDelete,
    checkForWalletMoney,
    difference,
    decodedToken?.id,
  ]);

  //this function is for making payment with our Other wallet (RAZERPAY) for now
  const selectOtherPayment = useCallback(() => {
    localStorage.setItem('payment_method', 'other');
    setIsDisabledFirst(true);
    handleSaveAndPayment();
    // reset();
  }, [handleSaveAndPayment]);

  return useMemo(
    () => ({
      selectOtherPayment,
      addMoneyInWallet,
      ProceedWithUSDWallet,
      checkForWalletMoney,
      getWalletMoney,
      handelDeleteOnly,
      handelDelete,
      handleBooktickets,
      getTotal,
      reset,
      error,
      btnStatus,
      isDisabled,
      isDisabledFirst,
      isDisabledOther,
    }),
    [
      selectOtherPayment,
      addMoneyInWallet,
      ProceedWithUSDWallet,
      getWalletMoney,
      handelDeleteOnly,
      handelDelete,
      handleBooktickets,
      getTotal,
      reset,
      error,
      btnStatus,
      isDisabled,
      isDisabledFirst,
      isDisabledOther,
      checkForWalletMoney,
    ],
  );
}
