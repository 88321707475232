import React from 'react';
import HeaderFillTop from '../HeaderFillTop';
import ToogleSwitch from '../../util/ToogleSwitch';
import RightSideSectionWrapper from '../RightSideSectionWrapper';
import { afterBorder, primaryBtnClass } from '../../common/DefaultClasses';
import CurrencyFormat from 'react-currency-format';
import { CustomWalletIcon } from '../../util/CustomIcons';
import TableComponentv2 from '../../common/TableComponentv2';
import { BsPlus } from 'react-icons/bs';

export default function GotixWalletInfo() {
  const dataSource = [
    {
      key: '1',
      transaction_id: (
        <>
          <span className="text-bluev2-600 cursor-pointer">7573....3931</span>
        </>
      ),
      Date: '03-10-2023 04:06:49 pm',
      Amount: (
        <div className="pr-2">
          <CurrencyFormat
            disabled={true}
            displayType="text"
            thousandSeparator
            prefix="$"
            value={100000}
            decimalScale={2}
            fixedDecimalScale
            placeholder="Enter Amount"
            className={`text-gray-700`}
          />
        </div>
      ),
      Type: (
        <>
          <span className="flex">
            <span
              className={`text-green-700 bg-green-200
                    flex py-0 px-2 rounded-full`}
            >
              Purchase
            </span>
          </span>
        </>
      ),
      Status: (
        <>
          <span className="flex">
            <span
              className={`text-green-700 bg-green-200
                    flex py-0 px-2 rounded-full`}
            >
              + Credited to USD Wallet
            </span>
          </span>
        </>
      ),
    },
    {
      key: '2',
      transaction_id: (
        <>
          <span className="text-bluev2-600 cursor-pointer">7573....3931</span>
        </>
      ),
      Date: '03-10-2023 04:06:49 pm',
      Amount: (
        <div className="pr-2">
          <CurrencyFormat
            disabled={true}
            displayType="text"
            thousandSeparator
            prefix="$"
            value={100000}
            decimalScale={2}
            fixedDecimalScale
            placeholder="Enter Amount"
            className={`text-gray-700`}
          />
        </div>
      ),
      Type: (
        <>
          <span className="flex">
            <span
              className={`text-red-700 bg-red-100 dark:bg-[#302024] dark:text-[#E42855]
                    flex py-0 px-2 rounded-md`}
            >
              Purchase
            </span>
          </span>
        </>
      ),
      Status: (
        <>
          <span className="flex">
            <span
              className={`text-red-700 bg-red-100 dark:bg-[#302024] dark:text-[#E42855]
                    flex py-0 px-2 rounded-md`}
            >
              - Debited from USD Wallet
            </span>
          </span>
        </>
      ),
    },
  ];

  const columns = [
    {
      title: <span className="text-gray-500">Transaction Id</span>,
      dataIndex: 'transaction_id',
      key: 'transaction_id',
      width: '200px',
    },
    {
      title: <span className="text-gray-500 flex max-w-[150px]">Date</span>,
      dataIndex: 'Date',
      // defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(b?.date) - new Date(a?.date),
      key: 'Date',
      width: '150px',
      // width: '120px',
    },
    {
      title: (
        <span className="text-gray-500 flex justify-end w-full">Amount</span>
      ),
      dataIndex: 'Amount',
      key: 'Amount',
      // defaultSortOrder: 'descend',
      sorter: (a, b) => a.Amount - b.Amount,
      width: '200px',
      align: 'right',
    },
    {
      title: <span className="text-gray-500">Transaction Type</span>,
      dataIndex: 'Type',
      key: 'Type',
      width: '200px',
    },
    // {
    //   title: <span className="text-gray-500">Policies</span>,
    //   dataIndex: 'Policies',
    //   key: 'Policies',
    //   width: '100px',
    // },
    {
      title: <span className="text-gray-500">Transaction Status</span>,
      dataIndex: 'Status',
      key: 'Status',
      width: '300px',
    },
  ];
  return (
    <React.Fragment>
      <div>
        <div className="">
          <HeaderFillTop
            title={'USD Wallet Overview'}
            extraPill={''}
            extra={<></>}
          />
        </div>
        <RightSideSectionWrapper>
          <section>
            <div className="relative p-3 bg-white">
              <div className={`${afterBorder}`}>
                <div className="flex justify-between">
                  <div className="flex items-center gap-2">
                    <div className="flex gap-2 items-center">
                      <div className="w-10 h-10 relative border-1 border-gray-200 rounded-full">
                        <CustomWalletIcon
                          className={
                            'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-xl text-bluev2-600'
                          }
                        />
                      </div>
                      <div className="flex flex-col justify-end text-left">
                        <h3 className="font-normal">USD Wallet</h3>
                        <span className="text-lg font-medium">
                          <CurrencyFormat
                            disabled={true}
                            displayType="text"
                            thousandSeparator
                            prefix="$"
                            value={1000}
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button className={`${primaryBtnClass}`}>
                      <span>
                        <BsPlus className="text-lg" />
                      </span>
                      Add Money
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <TableComponentv2 dataSource={dataSource} columns={columns} />
            </div>
          </section>
        </RightSideSectionWrapper>
      </div>
    </React.Fragment>
  );
}
