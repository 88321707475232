import { useEffect, useRef, useState } from 'react';
import { history } from '../../../helpers/router_templates/history';
import { useStreamLounge } from './useStreamLounge';

export function useTimer({ time, callback = () => null, fromExit = false }) {
  const [timeLeft, setTimeLeft] = useState(time ?? 10);
  const { event_id } = useStreamLounge();
  const intervalRef = useRef();
  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
      callback();
      if (fromExit) {
        history.navigate(`/event-detail/${event_id}`);
      }
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    intervalRef.current = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => {
      clearInterval(intervalRef.current);
      // handleExitEvent()
    };
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, callback, event_id, fromExit]);

  return {
    timeLeft: timeLeft ? timeLeft : 0,
    intervalRef: intervalRef.current,
  };
}
