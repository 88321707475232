import React, { useCallback } from 'react';
import { BiListUl, BiSolidGrid } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { TfiLayoutGrid4Alt } from 'react-icons/tfi';
import {
  GRID,
  LAYOUT2X2,
  LAYOUT3X3,
  LIST,
  updateFilterSettings,
} from '../../../features/event/allartist.slice';
import { animateScroll } from 'react-scroll';

export default function GridLayout({ scrollRef = null }) {
  const { filter_settings } = useSelector((s) => s.allArtistReducer);
  const { view, layout } = filter_settings;
  const dispatch = useDispatch();

  const changeLayout = useCallback(
    ({ view, layout }) => {
      animateScroll.scrollTo(
        scrollRef?.current?.getBoundingClientRect().top + window.scrollY,
        {
          delay: 0,
          duration: 0,
          smooth: true,
        },
      );
      return dispatch(
        updateFilterSettings({ ...filter_settings, view, layout }),
      );
    },
    [dispatch, scrollRef, filter_settings],
  );

  return (
    <>
      <div className=" max-w-md sm:px-0 border-1 dark:border-neutral-800 border-zinc-200 rounded-lg overflow-hidden">
        <div className="flex flex-row-reverse rounded-lg dark:bg-[#000] bg-transparent divide-neutral-200 p-1 gap-1">
          <button
            className={`${
              view === LIST
                ? 'dark:bg-neutral-800 bg-lightTheme-90 dark:text-whiteNew-0 text-zinc-800'
                : 'dark:bg-neutral-900 bg-lightTheme-70 dark:text-neutral-400 text-zinc-800'
            } py-1.5 px-1.5 leading-5 dark:hover:text-whiteNew-0 hover:text-zinc-800 dark:hover:bg-neutral-800 hover:bg-zinc-300 text-2xl font-medium w-[31px] h-[31px] relative duration-150 ease-in rounded-md`}
            onClick={() => changeLayout({ view: LIST, layout })}
            // key={index}
          >
            <BiListUl className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
          </button>
          <button
            className={`${
              view === GRID && layout === LAYOUT2X2
                ? 'dark:bg-neutral-800 bg-lightTheme-90 dark:text-whiteNew-0 text-zinc-800'
                : 'dark:bg-neutral-900 bg-lightTheme-70 dark:text-neutral-400'
            } py-1.5 px-1.5 leading-5 dark:hover:text-whiteNew-0 hover:text-zinc-800 dark:hover:bg-neutral-800 hover:bg-zinc-300 text-2xl font-medium w-[31px] h-[31px] relative duration-150 ease-in hidden lg:flex rounded-md`}
            onClick={() => changeLayout({ layout: LAYOUT2X2, view: GRID })}
            // key={index}
          >
            {/* <BiGridSmall className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" /> */}
            <BiSolidGrid className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
          </button>
          <button
            className={`${
              view === GRID && layout === LAYOUT3X3
                ? 'dark:bg-neutral-800 bg-lightTheme-90 dark:text-whiteNew-0 text-zinc-800'
                : 'dark:bg-neutral-900 bg-lightTheme-70 dark:text-neutral-400 text-zinc-800'
            } py-1.5 px-1.5 leading-5 hover:text-zinc-800 dark:hover:text-whiteNew-0 dark:hover:bg-neutral-800 hover:bg-zinc-300 text-2xl font-medium w-[31px] h-[31px] relative duration-150 ease-in rounded-md`}
            onClick={() => changeLayout({ layout: LAYOUT3X3, view: GRID })}
            // key={index}
          >
            <TfiLayoutGrid4Alt className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
          </button>
        </div>
      </div>
    </>
  );
}
