import React from 'react';

export default function FilterTabButton({
  icon,
  title,
  handleTabChange,
  index,
  active,
}) {
  return (
    <>
      <div
        type="button"
        className={`flex flex-col items-center p-1.5 py-3.5 ${
          active ? 'text-whiteNew-0' : 'text-gray-400'
        }`}
        onClick={() => {
          handleTabChange(index);
        }}
      >
        <div>
          <span className="text-base">{icon}</span>
        </div>
        <div>
          <span className="text-sm">{title}</span>
        </div>
      </div>
    </>
  );
}
