import React from 'react';
import { useTimer } from '../../pages/stream_lounge/hooks/useTimer';
import { useStreamAPI } from '../../pages/stream_lounge/hooks/useStreamLounge';

export default function TimeoutStrip() {
  // const [openOpenViewNFT, setOpenViewNFT] = React.useState(false);
  // const onCloseViewNFT = useCallback(() => {
  //   setOpenViewNFT(false);
  // }, []);
  const { handleExitEvent } = useStreamAPI();
  const { timeLeft } = useTimer({
    time: 10,
    callback: handleExitEvent,
    fromExit: false,
  });
  return (
    <React.Fragment>
      <div className="relative top-0 w-full z-[9999999999] hidden md:flex">
        <div className="bg-rose-700 w-full py-2.5 flex justify-center">
          <div className="text-whiteNew-0">
            {`Session will be be ended in ${timeLeft ? timeLeft : 0} seconds.`}
          </div>
        </div>
      </div>
      {/* <div className="">
        <Drawer
          placement={'bottom'}
          onClose={onCloseViewNFT}
          // open={openOpenViewNFT}
          open={true}
          closeIcon={false}
          title={null}
          height={'auto'}
          className="!bg-black text-white cstm-drwr bodyPadding"
          style={{ padding: '0px' }}
          maskStyle={{
            backdropFilter: 'blur(8px)',
            backgroundColor: 'rgb(0 0 0 / 58%)',
          }}
        >
          <div className="bg-rose-700 w-full py-2.5 flex justify-center">
            <div className="text-whiteNew-0">
              Session will be be ended in 10 seconds.
            </div>
          </div>
        </Drawer>
      </div> */}
    </React.Fragment>
  );
}
