import React, { Fragment, useState } from 'react';
import $ from 'jquery';
// import { MdOutlineCheckCircleOutline } from 'react-icons/md';
import { PulseLoader } from 'react-spinners';
import { Dialog, Transition } from '@headlessui/react';
import useThemeStore from '../../store/useThemeStore';

export default function StepsLoader({ preventClosingOutsideClick = true }) {
  const [isOpen, setIsOpen] = useState(true);
  const [darkMode] = useThemeStore();

  function Interval() {
    // setTimeout(() => {
    let items = document.getElementsByClassName('fade-item');

    for (let i = 0; i < items.length; ++i) {
      fadeIn(items[i], i * 1500);
    }
    for (let i = 0; i < items.length; ++i) {
      fadeOut(items[i], i * 3000);
    }
    function fadeIn(item, delay) {
      setTimeout(() => {
        item.classList.add('fadein');
      }, delay);
    }
    function fadeOut(item, delay) {
      setTimeout(() => {
        item.classList.add('fadeOutList');
      }, delay);
    }
    // }, 2000);
  }
  $(document).ready(function () {
    Interval();
  });
  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      // Click occurred outside the modal, prevent closing
      return;
    }
    // Otherwise, let the default behavior of closing the modal proceed
    setIsOpen(true);
  };
  return (
    <>
      <Transition
        // appear
        show={true}
        as={Fragment}
        onClose={(e) => e.stopPropogation()}
        className="fixed z-50"
      >
        <Dialog
          className={``}
          onClose={(e) => {
            e.stopPropogation();
          }}
          as="Fragment"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            show={true}
          >
            <div className={`fixed inset-0 bg-color-base-1 bg-opacity-25`} />
          </Transition.Child>
          <div className="fixed inset-0 max-h-[100%] overflow-y-auto">
            <div
              className="pageLoader bg-color-base-1 bg-opacity-50 dark:bg-opacity-80 backdrop-blur-lg fixed inset-0 w-full h-full"
              style={{ zIndex: '99999' }}
            >
              <div
                className="featuredBackgroundContainer h-screen w-screen flex items-center flex-col justify-center gap-2 px-3"
                style={{ zIndex: '99999' }}
              >
                <div className="">
                  <ul className="space-y-4 w-full max-w-md">
                    <li className="fade-item flex items-center text-xl">
                      <svg
                        className="h-6 w-6 flex-none fill-transparent stroke-color-primaryColor1 stroke-2"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="12" cy="12" r="11" />
                        <path
                          d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                          fill="none"
                        />
                      </svg>
                      <p className="ml-4 text-color-primary">Reviewing order</p>
                    </li>
                    <li className="fade-item flex items-center text-xl">
                      <svg
                        className="h-6 w-6 flex-none fill-transparent stroke-color-primaryColor1 stroke-2"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="12" cy="12" r="11" />
                        <path
                          d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                          fill="none"
                        />
                      </svg>
                      <p className="ml-4">Confirming ticket details</p>
                    </li>
                    <li className="fade-item flex items-center text-xl">
                      <svg
                        className="h-6 w-6 flex-none fill-transparent stroke-color-primaryColor1 stroke-2"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="12" cy="12" r="11" />
                        <path
                          d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                          fill="none"
                        />
                      </svg>
                      <p className="ml-4 text-color-primary">
                        Making payment securely
                      </p>
                    </li>
                    <li className="fade-item flex items-center text-xl">
                      <svg
                        className="h-6 w-6 flex-none fill-transparent stroke-color-primaryColor1 stroke-2"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="12" cy="12" r="11" />
                        <path
                          d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                          fill="none"
                        />
                      </svg>
                      <p className="ml-4 text-color-primary">
                        Confirming your attendance
                      </p>
                    </li>
                    <li className="fade-item flex items-center text-xl">
                      <svg
                        className="h-6 w-6 flex-none fill-transparent stroke-color-primaryColor1 stroke-2"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="12" cy="12" r="11" />
                        <path
                          d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                          fill="none"
                        />
                      </svg>
                      <p className="ml-4 text-color-primary">
                        Sending tickets via email
                      </p>
                    </li>
                    <li className="fade-item flex items-center text-xl">
                      <svg
                        className="h-6 w-6 flex-none fill-transparent stroke-color-primaryColor1 stroke-2"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="12" cy="12" r="11" />
                        <path
                          d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                          fill="none"
                        />
                      </svg>
                      <p className="ml-4 text-color-primary">
                        Preparing an Immersive Experience!
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="w-full flex justify-center mt-4">
                  <PulseLoader
                    // color="#923BFF"
                    color={darkMode ? '#8c1aff' : '#0284c7'}
                    width="200px"
                  />
                </div>
                <div>
                  <p className="text-xl text-rose-700 font-medium">
                    Please Don't Refresh and Press Back Button
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
