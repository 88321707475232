import { message } from 'antd';
import React, { useState, cloneElement, useMemo, useRef } from 'react';
import {
  AiOutlinePlus,
  AiOutlineMinus,
  AiOutlineExpand,
  AiOutlineCompress,
} from 'react-icons/ai';
import { GrPowerReset } from 'react-icons/gr';
import $ from 'jquery';
import TitleImg from '../../TitleImg';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddSeats,
  DeleteSeats,
} from '../../../../features/seatmap/seatmap.slice';
import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import useDecodedToken from '../../../../hooks/useDecodedToken';
import { Card } from 'react-bootstrap';
import config from '../../../../api/Config';
import CurrencyFormat from 'react-currency-format';
import { truncate } from '../../../../utils/util';
import Tooltip from '../../Tooltip';
import { MdErrorOutline } from 'react-icons/md';
import Modal from '../../Modal';
import { Col, Row } from 'react-bootstrap';
import { cartData } from '../../../../features/cart/cart.slice';
import SeatBookingMaxiumModal from './SeatBookingMaxiumModal';
import { maxiumSeatsBooked } from '../../../util/maxiumSeatsBooked';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import axios from 'axios';

let quantity = {};
let type = '';
let seatSlength = 0;

const OriginalDiv = () => (
  <div id="renderViewerSide">
    <div id="buyersmap" data-component="svg"></div>
  </div>
);

export default function SeatBookingCenter({
  setRowData,
  setLoader,
  eventAllDetails,
  expandView,
  setExpandView,
  setOpenTicketAlertModal,
  openTicketAlertModal,
  from,
}) {
  const [popOpen, setPopOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [singleSeat, setSingleSeat] = useState([]);
  const [singleSection, setSinglesection] = useState([]);
  const [searchParams] = useSearchParams();
  const [isDelete, setIsDelete] = useState(false);
  const [maxReached, setMaxReached] = useState('');
  const [maxTest, setMaxTest] = useState('');
  const [selectPop, setSelectPop] = useState(true);

  // let cartselectedSeats = useSelector(cartData);
  // let { cartInpersonData, cartvirtualData, slug } = cartselectedSeats;

  // const prevSlug = slug;

  // const [inpersonData, setInpersonData] = useState(cartInpersonData);
  // const [virtualData, setVirtualData] = useState(cartvirtualData);

  // useEffect(() => {
  //   if (document.getElementById('buyersmap')?.innerHTML) {
  //     if (
  //       Object.keys(cartInpersonData)?.length > 0 ||
  //       Object.keys(cartvirtualData)?.length > 0
  //     ) {
  //       window.clickSeatFunction(this, true);
  //     } else {
  //       window.clickSeatFunction(this, false);
  //     }
  //   }
  //   setInpersonData(cartInpersonData);
  // }, [
  //   cartInpersonData,
  //   cartvirtualData,
  //   document.getElementById('buyersmap')?.innerHTML,
  // ]);

  const [maxLimitAlert, setMaxLimitAlert] = useState(false);
  // from redux we are getting the user selected seats.
  let selectedSeats = useSelector((state) => state.selectedSeats);

  let dispatch = useDispatch();
  //commented by Surya
  // setting all the selectedSeats datas to the function seatSelections for seat maping.
  // useEffect(() => {
  //   if (document.getElementById('buyersmap')?.innerHTML) {
  //     if (selectedSeats?.data?.length > 0) {
  //       // prevSelectedSeats.current = selectedSeats;
  //       window.seatSelections(selectedSeats?.data);
  //     } else {
  //       window.seatSelections([]);
  //     }
  //   }
  // }, [selectedSeats]);

  //if zoom in and zoom out applied then this function will reset back to normal
  function resetZoom() {
    window.resetZoom();
  }

  //for the first time will store the localstorage value to quantity
  useEffect(() => {
    if (selectedSeats?.data?.length > 0) {
      quantity = JSON.parse(localStorage.getItem('quantity'));
    }
  }, []);

  //Zoomin function
  function zoomIn() {
    window.zoomIn();
  }

  //Zoomout function

  function zoomOut() {
    window.zoomOut();
  }
  const query = useSearchQuery();
  const decodedToken = useDecodedToken();
  // const renderOnDev = useMemo(
  //   () => String(window.location.host).includes('dev'),
  //   [],
  // );
  // let { json_header } = useApiHeader();
  const renderSeat = React.useCallback(() => {
    const script = document.createElement('script');
    // const matched = [
    //   '649571751a3ab340009295b9',
    //   '659d4446323d14a99021f9e4',
    // ].includes(query.get('v'));
    // script.src = Boolean(matched && renderOnDev)
    //   ? `${config.venue_builder_baseURL}/v5/buyers/index.js`
    //   : `${config.venue_builder_baseURL}/buyers/index.js`;
    script.src = `${config.venue_builder_baseURL}/buyers/index.js`;
    // script.src = 'https://localhost/seat-charting/buyers/index.js';
    if (script.src) {
      document.body.prepend(script);
    }
  }, []);

  /// rendering seats to the venue builder on mounting stage.
  React.useEffect(() => {
    renderSeat();
  }, []);

  // const getNote = (id) => {
  //   return getSingleTicketCategory(id, json_header);
  // };

  // const mutation = useMutation(getNote, {
  //   onSuccess: async (data) => {

  //     setCategory(data?.data);
  //     //message.success('Details updated successfully');
  //   },
  //   onError: () => {
  //     message.error('Error while updating profile details');
  //   },
  // });

  // React.useEffect(() => {

  //   if (singleSeat?.section?.isPriceAssigned) {
  //     mutation.mutate(singleSeat?.seat?.categories_id);
  //   } else if (singleSeat?.row?.isPriceAssigned) {
  //     mutation.mutate(singleSeat?.seat?.categories_id);
  //   }
  // }, [singleSeat]);

  //saving the row data to the useState from the seat map.

  const setRowDataFunction = React.useCallback((data) => {
    setRowData(data);
  }, []);

  useEffect(() => {
    if (maxTest !== '') {
      const seats = selectedSeats?.data.filter(
        (obj) => obj.category_id === maxTest?.seat?.categories_id,
      );
      if (seats.length < Number(maxTest?.seat?.category_maximum_per_order)) {
        addToCart(maxTest, 'add');
      } else {
        setMaxLimitAlert(true);
      }
    }
  }, [maxTest]);

  const location = useLocation();

  /// In this useEffect we are getting all the seat details like row number seat number category maximum limit etc... *************
  useEffect(() => {
    var x = setInterval(renderSeatMap, 1000);
    let timeout;
    function renderSeatMap() {
      if (document.getElementById('buyersmap')?.innerHTML) {
        timeout = setTimeout(() => {
          setLoader(false);
        }, 3000);

        clearInterval(x);
        var renderViewerSide = document?.getElementById('buyersmap');
        var clickableSection =
          renderViewerSide?.querySelectorAll('.clickAblePath');

        let arrayNew = [];
        let CurrentMouseXPostion;
        let CurrentMouseYPostion;

        $(document).mousemove(function (event) {
          CurrentMouseXPostion = event.pageX;
          CurrentMouseYPostion = event.pageY;
        });

        // Commented by dharmendra sir
        // if (renderOnDev) {
        let svgSeat;
        let allSeats;
        let setItvl = setInterval(function () {
          var getSeat = document.getElementsByClassName('svgSeat');

          // let totalSeats =
          //   eventAllDetails?.data?.venue[0]?.assignedInfo?.totalSeats;
          let totalSeats =
            eventAllDetails?.data?.venue[0]?.assignedInfo?.assignedSeats.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue?.totalSeats,
              0,
            );

          if (getSeat?.length === totalSeats) {
            clearInterval(setItvl);

            svgSeat = document?.querySelectorAll(
              '.svgSeat[data-status=available], .svgSeat[data-status=resale]',
            );
            allSeats = document?.querySelectorAll(
              '.svgSeat[data-status=available], .svgSeat[data-status=resale], .svgSeat[data-status="reserved"],.svgSeat[data-status="booked"]',
            );
            // console.log(svgSeat.length);

            svgSeat?.forEach(function (seat) {
              arrayNew.push(seat);
              seat?.addEventListener('click', function (event) {
                let data = window?.clickSeatFunction(event);

                if (data?.isSelected) {
                  setMaxTest(data);
                } else {
                  setMaxReached(data);
                  setIsDelete(true);
                }
                setRowDataFunction(data);
              });
            });

            allSeats?.forEach(function (seat) {
              seat?.addEventListener('mouseenter', function (event) {
                $(document).mousemove(function (e) {
                  window.x = e.pageX;
                  window.y = e.pageY;
                });
                let data = window.hoverSeatFunction(event);
                // console.log(data);
                // if (data.seat?.status === 'booked' || data.seat?.status === 'reserved'){

                // }
                setPopOpen(false);
                setSingleSeat(data);
                setPopOpen(true);
                setSelectPop(false);
                $('.ant-popover').css({});
              });

              seat?.addEventListener('mouseleave', function (event) {
                setSelectPop(false);
                setPopOpen(false);
              });
            });
          }
        }, 1000);
        // } else {
        //   // Commented as backup
        //   let svgSeat = document?.querySelectorAll(
        //     '.svgSeat[data-status=available], .svgSeat[data-status=resale]',
        //   );
        //   svgSeat?.forEach(function (seat) {
        //     arrayNew.push(seat);
        //     seat?.addEventListener('click', function (event) {
        //       let data = window?.clickSeatFunction(event);
        //       // if (checkReachedMax(data, 'add')) {
        //       //   // setAlertMaximumTicket(true);
        //       //   message.error(
        //       //     ' You have reached the maximum number of tickets per order for this ticket type.',
        //       //   );
        //       // } else {
        //       if (data?.isSelected) {
        //         setMaxTest(data);
        //         // const seats = selectedSeats?.data.filter(
        //         //   (obj) => obj.category_id === data?.seat?.categories_id,
        //         // );
        //         // if (
        //         //   seats.length <= Number(data?.seat?.category_maximum_per_order)
        //         // ) {
        //         //   console.log(
        //         //     seats.length <=
        //         //       Number(data?.seat?.category_maximum_per_order),
        //         //   );
        //         //   console.log(seats.length);
        //         //   addToCart(data, 'add', inpersonData);
        //         // } else {
        //         //   setMaxLimitAlert(true);
        //         // }
        //       } else {
        //         // validateCart(data);
        //         setMaxReached(data);
        //         setIsDelete(true);
        //       }
        //       // }
        //       setRowDataFunction(data);
        //     });
        //   });
        //   svgSeat?.forEach(function (seat) {
        //     seat?.addEventListener('mouseenter', function (event) {
        //       $(document).mousemove(function (e) {
        //         window.x = e.pageX;
        //         window.y = e.pageY;
        //       });
        //       // window.x = event.pageX;
        //       // window.y = event.pageY;
        //       let data = window.hoverSeatFunction(event);
        //       setPopOpen(false);
        //       setSingleSeat(data);
        //       setPopOpen(true);
        //       setSelectPop(false);
        //       // setSingleSeat(data);
        //       // setPopOpen(false);
        //       // $('.ant-popover').css({
        //       //   top: `${CurrentMouseYPostion}px`,
        //       //   left: `${CurrentMouseXPostion}px`,
        //       //   transform: `translate(-142px, -132px)`,
        //       // });
        //       $('.ant-popover').css({
        //         // top: `${data?.positions?.offset?.y}px`,
        //         // left: `${data?.positions?.offset?.x}px`,
        //         // top: `${window.y}px`,
        //         // left: `${window.x}px`,
        //         // transform: `translate(-142px, -132px)`,
        //         // transform: `translate(-119px, -304px)`,
        //         // transform: `translate(-119px, -323px)`,
        //         // transform: `translate(-115px, -414px)`,
        //       });
        //       // document.getElementsByClassName(
        //       //   'ant-popover-inner-content',
        //       // ).style.color = 'black';
        //     });
        //     seat?.addEventListener('mouseleave', function (event) {
        //       setSelectPop(false);
        //       // $(document).mousemove(function (e) {
        //       //   window.x = e.pageX;
        //       //   window.y = e.pageY;
        //       // });
        //       // let data = window.hoverSeatFunction(event);
        //       // setTimeout(function () {
        //       setPopOpen(false);
        //       // }, 1000);
        //       // $('.ant-popover').css({
        //       //   top: `${CurrentMouseYPostion}px`,
        //       //   left: `${CurrentMouseXPostion}px`,
        //       //   transform: `translate(-142px, -132px)`,
        //       // });
        //       // $('.ant-popover').css({
        //       //   top: `${data?.positions?.pages?.y}px`,
        //       //   left: `${data?.positions?.pages?.x}px`,
        //       //   transform: `translate(-142px, -132px)`,
        //       // });
        //       // $('.ant-popover').css({
        //       //   top: `${data?.positions?.pages?.y}px`,
        //       //   left: `${data?.positions?.pages?.x}px`,
        //       //   transform: `translate(-142px, -132px)`,
        //       // });
        //       // document.getElementsByClassName(
        //       //   'ant-popover-inner-content',
        //       // ).style.color = 'black';
        //     });
        //   });
        // }

        clickableSection?.forEach(function (section) {
          section?.addEventListener('mouseenter', function (event) {
            let data = window.hoverSectionFunction(event);
            setSinglesection(data);
            setOpen(true);
            setSelectPop(true);
            // $('.ant-popover1').css({
            //   // top: `${data?.positions?.offset?.y}px`,
            //   // left: `${data?.positions?.offset?.x}px`,
            //   // transform: `translate(-142px, -132px)`,
            // });
          });
        });
        clickableSection?.forEach(function (section) {
          section?.addEventListener('mouseleave', function (event) {
            setOpen(false);
            setSelectPop(true);
          });
        });
        //commednted by surya

        // setTimeout(() => {
        //   if (selectedSeats?.data?.length > 0) {
        //     window.seatSelections(selectedSeats?.data);
        //   } else {
        //     window.seatSelections([]);
        //   }
        // }, 1000);
      }
    }
    return () => {
      clearInterval(x);
      clearTimeout(timeout);
    };
  }, [eventAllDetails]);

  // useEffect(() => {
  //   if (maxReached !== '' && maxReached) {
  //     const seats = selectedSeats?.data.filter(
  //       (obj) => obj.category_id === maxReached?.seat?.categories_id,
  //     );
  //     if (seats.length > Number(maxReached?.seat?.category_maximum_per_order)) {
  //       // setMaxLimitAlert(true);
  //     }
  //   }
  // }, [maxReached]);

  useEffect(() => {
    // console.log('maxReached', maxReached);
    if (maxReached !== '') {
      // console.log('selectedSeats?.data', selectedSeats?.data);
      const seats = selectedSeats?.data.find(
        (obj) => obj.seat_id === maxReached?.seat?.id,
      );

      if (seats) {
        // commnted by surya
        // addToCart(maxReached?.seat?.id, 'delete');
        addToCart(maxReached, 'delete');
      } else {
        Object.keys(maxReached)?.length !== 0 && setMaxLimitAlert(true);
      }
    }
  }, [maxReached]);

  // function validateCart(data) {
  //   console.log(data);
  //   console.log(selectedSeats?.data);
  //   const seats = selectedSeats?.data.filter(
  //     (obj) => obj.category_id === data?.seat?.categories_id,
  //   );
  //   console.log(seats);
  //   // if (seats.length < Number(maximum)) {
  //   //   return true;
  //   // } else {
  //   //   return false;
  //   // }
  //   // console.log(maxSeats);
  //   // if (!maxSeats) {
  //   //   setMaxLimitAlert(true);
  //   // } else {
  //   //   addToCart(data, 'delete');
  //   // }
  // }

  // /// if we delete the seats then we are deleting from the redux store also.
  // useEffect(() => {
  // let maxSeats = maxiumSeatsBooked(
  //   singleSeat?.seat?.categories_id,
  //   singleSeat?.seat?.category_maximum_per_order,
  //   selectedSeats,
  // );
  // console.log(maxSeats);
  // if (!maxSeats) {
  //   setMaxLimitAlert(true);
  // }
  // if (isDelete) {
  //   // checkReachedMax(singleSeat, 'delete');
  //   console.log('delete');
  //   type = 'delete';
  //   addToCart(singleSeat?.seat?.id, 'delete');
  //   //
  // }
  // }, [isDelete]);

  // const checkIsthere = (selected) => {
  //   let filter = selectedSeats?.data?.filter(
  //     (data) =>
  //       data.row === selected?.row?.row_number &&
  //       data.seat_number === selected?.seat?.seat_number,
  //   );
  //   if (filter) {
  //     if (filter?.length > 0) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // };

  // Check the user selected the maximum tickets per order.

  // const checkReachedMax = (singleSeat, selectedSeat) => {
  //   let result = false;
  //   if (type !== 'delete' && seatSlength <= singleSeat?.length) {
  //     if (singleSeat.length > 0) {
  //       for (let i = singleSeat.length - 1; i <= singleSeat.length - 1; i++) {
  //         if (quantity[singleSeat[i]?.category] === undefined) {
  //           quantity[singleSeat[i]?.category] = 1;
  //           localStorage.setItem('quantity', JSON.stringify(quantity));
  //         } else {
  //           if (
  //             Number(quantity[singleSeat[i]?.category]) <=
  //             Number(selectedSeat?.seat?.category_maximum_per_order)
  //           ) {
  //             quantity[singleSeat[i]?.category] =
  //               Number(quantity[singleSeat[i]?.category]) + 1;
  //             localStorage.setItem('quantity', JSON.stringify(quantity));
  //           }
  //         }
  //       }

  //       seatSlength = singleSeat.length;
  //     }
  //   } else {
  //     if (seatSlength > singleSeat?.length) {
  //       quantity[selectedSeat?.seat?.category_name] =
  //         Number(quantity[selectedSeat?.seat?.category_name]) - 1;
  //       localStorage.setItem('quantity', JSON.stringify(quantity));
  //     } else {
  //       quantity[selectedSeat?.seat?.category_name] =
  //         Number(quantity[selectedSeat?.seat?.category_name]) + 1;
  //       localStorage.setItem('quantity', JSON.stringify(quantity));
  //     }
  //     type = '';
  //     seatSlength = singleSeat?.length || 0;
  //   }
  //   for (let x in quantity) {
  //     if (x === selectedSeat?.seat?.category_name) {
  //       if (
  //         Number(quantity[x]) >
  //         Number(selectedSeat?.seat?.category_maximum_per_order)
  //       ) {
  //         quantity[selectedSeat?.seat?.category_name] =
  //           Number(quantity[selectedSeat?.seat?.category_name]) - 1;
  //         localStorage.setItem('quantity', JSON.stringify(quantity));
  //         result = true;
  //       }
  //     }
  //   }

  //   return result;
  // };

  // if we reached the maximum limit then this error will show.
  // useEffect(() => {
  //   if (checkReachedMax(selectedSeats?.data, singleSeat)) {
  //     // message.error(
  //     //   'You have reached the maximum number of tickets per order for this ticket type.',
  //     // );
  //     setMaxLimitAlert(true);
  //   }
  // }, [selectedSeats]);

  // console.log('Outsidehere', inpersonData);

  // const checkCart = (inpersonData) => {
  //   // console.log(
  //   //   'asdasdasasd',
  //   //   Object.keys(inpersonData)?.length > 0 ||
  //   //     Object.keys(virtualData)?.length > 0,
  //   // );
  //   // console.log('asdasdasasd', slug);
  //   // console.log('eventAllDetails', eventAllDetails?.data?.slug);
  //   // console.log('here', inpersonData);
  //   let inperson = localStorage.getItem('selectedInPersonTickets')
  //     ? JSON.parse(localStorage.getItem('selectedInPersonTickets'))
  //     : {};

  //   if (
  //     Object.keys(inperson)?.length > 0 &&
  //     prevSlug !== eventAllDetails?.data?.slug &&
  //     prevSlug !== ''
  //   ) {
  //     // console.log('here');
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  /// adding all the user selected seats to the redux store and used for further purpose.

  const addToCart = (singleSeat, action, inpersonData) => {
    if (action === 'add') {
      dispatch(
        AddSeats({
          event_id: singleSeat?.others?.event_id,
          row_id: singleSeat?.row?.id,
          section_id: singleSeat?.section?.id,
          category_id: singleSeat?.seat?.categories_id,
          seat_id: singleSeat?.seat?.id,
          id: singleSeat?.seat?.id,
          section: singleSeat?.section?.name,
          row: singleSeat?.row?.row_number,
          seat_number: singleSeat?.seat?.seat_number,
          price: `${singleSeat?.seat?.category_price}`,
          category: singleSeat?.seat?.category_name,
          user_id: decodedToken?.id,
          price_seatmap_id: searchParams.get('seatPrice'),
          isSelected: singleSeat?.isSelected,
          resale_price: singleSeat?.seat?.resale_amount,
          reseller_id: singleSeat?.seat?.reseller_id,
          // eventAllDetails: eventAllDetails,
          eventType: 'Physical',
          category_maximum_per_order:
            singleSeat?.seat?.category_maximum_per_order,
        }),
      );
    } else {
      let filter = selectedSeats?.data?.filter(
        (obj) => obj.id !== singleSeat.seat.id,
      );
      //adaed by surya
      window.removeSelections([
        {
          event_id: singleSeat?.others?.event_id,
          row_id: singleSeat?.row?.id,
          section_id: singleSeat?.section?.id,
          category_id: singleSeat?.seat?.categories_id,
          seat_id: singleSeat?.seat?.id,
          id: singleSeat?.seat?.id,
          section: singleSeat?.section?.name,
          row: singleSeat?.row?.row_number,
          seat_number: singleSeat?.seat?.seat_number,
          price: `${singleSeat?.seat?.category_price}`,
          category: singleSeat?.seat?.category_name,
          user_id: decodedToken?.id,
          price_seatmap_id: searchParams.get('seatPrice'),
          isSelected: singleSeat?.isSelected,
          resale_price: singleSeat?.seat?.resale_amount,
          reseller_id: singleSeat?.seat?.reseller_id,
          // eventAllDetails: eventAllDetails,
          eventType: 'Physical',
          category_maximum_per_order:
            singleSeat?.seat?.category_maximum_per_order,
        },
      ]);
      //--------------------
      dispatch(DeleteSeats(filter));
    }
  };

  // onHover on seats we are showing all the seat details in this popup.
  const content = (
    <div
      className="flex flex-col w-full max-w-lg"
      onMouseEnter={() => {
        setPopOpen(true);
      }}
    >
      {/* <div className="flex  justify-end -mt-2 -mr-2">
              <span className="flex bg-rose-100 text-rose-600 rounded-full p-1 px-2 text-xs">
                Recently Viewed
              </span>
            </div> */}

      {/* {singleSeat?.seat?.status === 'booked' ||
      singleSeat?.seat?.status === 'reserved' ? (
        <Card className="bg-black border-0 p-3 overflow-hidden w-full relative text-center">
          <div className="flex items-center justify-between ">
            <div className="flex flex-col w-full">
              <div className="flex justify-between">
                <div>
                  <h6 className="font-medium text-white text-xs">
                    Purchased,Not Available
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </Card>
      ) : ( */}
      <Card className="bg-black border-0 p-3 overflow-hidden w-full relative">
        {singleSeat?.seat?.resale_amount && (
          <div className="absolute top-1 left-3">
            <span className="bg-resale-900 rounded-2xl text-resale-800 font-medium px-2 text-xs">
              On Resale
            </span>
          </div>
        )}
        {(singleSeat?.seat?.status === 'booked' ||
          singleSeat?.seat?.status === 'reserved') && (
          <div className="absolute top-2 left-4">
            <h6 className="font-medium text-white text-xs">Not Available</h6>
          </div>
        )}
        <div className="flex items-center justify-between mt-2">
          <div className="flex flex-col w-full">
            <div className="flex justify-between">
              <div>
                <h6 className="text-lg font-medium text-landingPurple-60">
                  {singleSeat?.seat?.category_name
                    ? singleSeat?.seat?.category_name
                    : '-'}
                </h6>
              </div>
              {singleSeat?.seat?.resale_amount ? (
                <div className="flex flex-col leading-none items-end">
                  <span className="text-lg font-medium flex text-whiteNew-0">
                    {singleSeat?.seat?.resale_amount
                      ? `${'$'} ${singleSeat?.seat?.resale_amount}`
                      : '-'}
                  </span>
                  {/* <span className="text-xs text-gray-200 flex -mt-2">each</span> */}
                </div>
              ) : singleSeat?.seat?.status === 'booked' ||
                singleSeat?.seat?.status === 'reserved' ? (
                <div className="flex flex-col leading-none items-end">
                  <span className="text-lg font-medium flex text-whiteNew-0">
                    {'-'}
                  </span>
                  {/* <span className="text-xs text-gray-200 flex -mt-2">each</span> */}
                </div>
              ) : (
                <div className="flex flex-col leading-none items-end">
                  <span className="text-lg font-medium flex text-whiteNew-0">
                    {singleSeat?.seat?.category_price
                      ? `${'$'} ${singleSeat?.seat?.category_price}`
                      : '-'}
                  </span>
                  {/* <span className="text-xs text-gray-200 flex -mt-2">each</span> */}
                </div>
              )}
            </div>

            <div className="flex justify-between gap-2 mt-2">
              <div className="w-full flex justify-center">
                <TitleImg
                  title={'Section'}
                  // subTitle={moment(data?.data?.start_date_time).format(
                  //   'hh:mm a',
                  // )}
                  subTitle={
                    <span className="text-lg text-whiteNew-0 break-words">
                      {/* <Tooltip
                        title={
                          singleSeat?.section?.name
                            ? singleSeat?.section?.name
                            : '-'
                        }
                        top={'top'}
                      >
                        {truncate(
                          singleSeat?.section?.name
                            ? singleSeat?.section?.name
                            : '-',
                          10,
                        )}
                      </Tooltip> */}
                      {singleSeat?.section?.name
                        ? singleSeat?.section?.name
                        : '-'}
                    </span>
                  }
                />
              </div>
              <div className="artcDividervertical"></div>
              <div className="w-full flex justify-center">
                <TitleImg
                  title={'Row No.'}
                  // subTitle={moment(data?.data?.start_date_time).format(
                  //   'hh:mm a',
                  // )}
                  subTitle={
                    <span className="text-lg text-whiteNew-0">
                      {singleSeat?.row?.row_number
                        ? singleSeat?.row?.row_number
                        : '-'}
                    </span>
                  }
                />
              </div>
              <div className="artcDividervertical"></div>
              <div className="w-full flex justify-center">
                <TitleImg
                  title={'Seat No.'}
                  // subTitle={moment(data?.data?.start_date_time).format(
                  //   'hh:mm a',
                  // )}
                  subTitle={
                    <span className="text-lg text-whiteNew-0">
                      {singleSeat?.seat?.seat_number
                        ? singleSeat?.seat?.seat_number
                        : '-'}
                    </span>
                  }
                />
              </div>
            </div>
            {/* <div className="mt-3">
            <button
              disabled={checkIsthere(singleSeat)}
              className="btn btnPrimary w-full h-auto"
              onClick={() => {
                addToCart(singleSeat, 'add');
              }}
            >
              {checkIsthere(singleSeat) ? 'Already Added' : 'Add To Cart'}
            </button>
          </div> */}
          </div>
        </div>
      </Card>
      {/* )} */}
    </div>
  );

  const noContent = (
    <div
      className="flex flex-col w-full max-w-[150px]"
      onMouseEnter={() => {
        // setPopOpen(true);
      }}
    >
      {/* <div className="flex  justify-end -mt-2 -mr-2">
              <span className="flex bg-rose-100 text-rose-600 rounded-full p-1 px-2 text-xs">
                Recently Viewed
              </span>
            </div> */}

      <Card className="bg-black border-0 p-3 overflow-hidden w-full relative text-center">
        <div className="flex items-center justify-between ">
          <div className="flex flex-col w-full">
            <div className="flex justify-between">
              <div>
                <h6 className="font-medium text-whiteNew-0 text-xs">
                  Hover on {selectPop ? 'Section' : 'Seat'} to view details
                </h6>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );

  const section = (
    <div className="flex flex-col w-full max-w-md">
      <div className="flex items-center justify-between w-full">
        <div className="flex flex-col w-full">
          <Card className="bg-black border-0 p-0 overflow-hidden w-full">
            <Card.Header className="dark:bg-landingPurple-60 bg-sky-500 rounded-none">
              <div className="flex justify-between items-center w-full">
                <div className="flex text-whiteNew-0">
                  <span>Section</span>
                </div>
                <div>
                  <span className="text-sm text-whiteNew-0 font-medium">
                    {singleSection?.sectionName
                      ? singleSection?.sectionName
                      : '-'}
                  </span>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="w-full">
              <div className="flex justify-between gap-3">
                <div className="flex justify-center ">
                  <TitleImg
                    title={
                      <>
                        <span className="text-gray-400 text-xs">Category</span>
                      </>
                    }
                    // subTitle={moment(data?.data?.start_date_time).format(
                    //   'hh:mm a',
                    // )}
                    subTitle={
                      <span className="text-sm 2xl:text-base text-whiteNew-0">
                        {singleSection?.category_name
                          ? singleSection?.category_name
                          : '-'}
                      </span>
                    }
                  />
                </div>
                <div className="flex justify-center shrink-0">
                  <TitleImg
                    title={<span className="text-gray-400 text-xs">Price</span>}
                    // subTitle={moment(data?.data?.start_date_time).format(
                    //   'hh:mm a',
                    // )}
                    subTitle={
                      <span className="text-sm 2xl:text-base text-white">
                        {singleSection?.category_price ? (
                          <span>
                            <CurrencyFormat
                              value={singleSection?.category_price || 0}
                              fixedDecimalScale
                              prefix="$"
                              width="auto"
                              displayType={'text'}
                              thousandSeparator={true}
                              decimalScale={2}
                            />
                          </span>
                        ) : (
                          '-'
                        )}
                      </span>
                    }
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );

  function cloneMapSection() {
    const node = document.getElementById('mainSvg');
    const clone = node.cloneNode(true);
    setTimeout(() => {
      document.getElementById('clonedSeatMap').appendChild(clone);
    }, 1000);
  }
  return (
    <React.Fragment>
      {/* <div id="clonedSeatMap"></div> */}
      <div id="renderViewerSide">
        <div id="buyersmap" data-component="svg"></div>
      </div>

      <div
        style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          padding: '0px',
        }}
      >
        <Tooltip title={expandView ? 'Compress' : 'Expand'} placement="left">
          <div
            onClick={() => {
              setExpandView(!expandView);
              cloneMapSection();
            }}
            style={{
              padding: '10px',
              cursor: 'pointer',
              marginBottom: '5px',
              background: '#fff',
              borderRadius: '5px',
              boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
              color: '#000',
            }}
          >
            {expandView ? <AiOutlineCompress /> : <AiOutlineExpand />}
          </div>
        </Tooltip>
        <Tooltip title="Reset" placement="left">
          <div
            onClick={() => resetZoom()}
            style={{
              padding: '10px',
              cursor: 'pointer',
              marginBottom: '5px',
              background: '#fff',
              borderRadius: '5px',
              boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
              color: '#000',
            }}
          >
            <GrPowerReset />
          </div>
        </Tooltip>

        <Tooltip title="Zoom In" placement="left">
          <div
            onClick={() => zoomIn()}
            style={{
              padding: '10px',
              cursor: 'pointer',
              marginBottom: '5px',
              background: '#fff',
              borderRadius: '5px',
              boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
              color: '#000',
            }}
          >
            <AiOutlinePlus />
          </div>
        </Tooltip>
        <Tooltip title="Zoom Out" placement="left">
          <div
            onClick={() => zoomOut()}
            style={{
              padding: '10px',
              cursor: 'pointer',
              background: '#fff',
              borderRadius: '5px',
              boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
              color: '#000',
            }}
          >
            <AiOutlineMinus />
          </div>
        </Tooltip>
      </div>
      <div>
        {/* <Popover
          content={content}
          open={popOpen}
          autoAdjustOverflow={true}
          destroyTooltipOnHide={false}
        >
          <button className="btn" type="primary">
            Hover me
          </button>
        </Popover> */}

        <div
          className={`ant-popover1 absolute duration-300 ease-in left-2 top-2  ${
            open ? 'flex' : 'hidden'
          }`}
          // style={{
          //   transformOrigin: '50% 142px',
          //   transform: 'translate(-122px, -187px)',
          // }}
          onMouseEnter={() => {
            setOpen(true);
          }}
          onMouseLeave={() => {
            setOpen(false);
          }}
        >
          <div className="ant-popover-content">
            <div className="ant-popover-arrow">
              <span className="ant-popover-arrow-content"></span>
            </div>
          </div>
          <div className={`p-0 rounded-lg shadow-sm`} role="tooltip">
            {section}
          </div>
        </div>

        <div
          className={`ant-popover absolute duration-300 ease-in left-2 top-2 ${
            popOpen ? 'flex' : 'hidden'
          }`}
          // style={{
          //   transformOrigin: '50% 142px',
          //   transform: 'translate(-122px, -187px)',
          // }}
          onMouseEnter={() => {
            setPopOpen(true);
          }}
          onMouseLeave={() => {
            // setTimeout(function () {
            // setPopOpen(false);
            // }, 500);
          }}
        >
          <div className="ant-popover-content">
            <div className="ant-popover-arrow">
              <span className="ant-popover-arrow-content"></span>
            </div>
          </div>
          <div className="rounded-lg shadow-sm" role="tooltip">
            {/* {Object.keys(category).length > 0 ? ( */}
            {content}
            {/* // ) : (
            //   <SpinnerBtn className={'text-xl'} />
            // )} */}
          </div>
        </div>

        <div
          className={`ant-popover absolute duration-300 ease-in left-2 top-2 ${
            open || popOpen ? 'hidden' : 'flex'
          }`}
          // style={{
          //   transformOrigin: '50% 142px',
          //   transform: 'translate(-122px, -187px)',
          // }}
          onMouseEnter={() => {
            // setPopOpen(true);
          }}
          onMouseLeave={() => {
            // setTimeout(function () {
            // setPopOpen(false);
            // }, 500);
          }}
        >
          <div className="ant-popover-content">
            <div className="ant-popover-arrow">
              <span className="ant-popover-arrow-content"></span>
            </div>
          </div>
          <div className="rounded-lg shadow-sm" role="tooltip">
            {/* {Object.keys(category).length > 0 ? ( */}
            {noContent}
            {/* // ) : (
            //   <SpinnerBtn className={'text-xl'} />
            // )} */}
          </div>
        </div>
      </div>
      <SeatBookingMaxiumModal
        maxLimitAlert={maxLimitAlert}
        setMaxLimitAlert={setMaxLimitAlert}
      />
    </React.Fragment>
  );
}
