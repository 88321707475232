import React, {
  Fragment,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import HelmetComponent from '../../../utils/HelmateComponent';
import { Col, Row } from 'react-bootstrap';
import ArtistBanner from '../../../components/landing_page_v2/artist_page/ArtistBanner';
import { useDispatch, useSelector } from 'react-redux';

import HeadingTitle from '../../../components/landing_page_v2/HeadingTitle';
import FilterLeftSidebarV2 from './FilterLeftSidebarV2';
import { Form, Input, message } from 'antd';
import {
  afterBorder,
  btnSecondayClass,
  formClass,
} from '../../../components/common/DefaultClasses';
import EventCardV2 from '../../../components/landing_page_v2/EventCardV2';
import {
  FILTER_CONST,
  GRID,
  LAYOUT2X2,
  LAYOUT3X3,
  LIST,
  updateFilter,
  updateFilterPills,
} from '../../../features/event/allevent.slice';
import { DataLoader } from '../../../components/common/Loaders';
import loaderMessage from '../../../utils/LoaderMessage';
import NoDataFound from '../../../components/common/NoDataFound';
import {
  useAllEventFilterAction,
  useAllEventsFilter,
  useAllEventsFilterSorting,
} from './hooks/useAllEventsFilter';
import { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { updateDataCountByViewportForEventCards } from '../../../utils/util';
import { animateScroll } from 'react-scroll';
import useDebounce from '../../../hooks/useDebounce';
import FilterPills from '../../../components/landing_page_v2/FilterPills';
import Button from '../../../components/common/Button';
import { BiFilterAlt } from 'react-icons/bi';
import GridLayout from './GridLayout';
import EventTableListView from './EventTableListView';
import { EventCardSkeleton } from '../../../components/util/Skeleton';
// import Tooltip from '../../../components/common/Tooltip';
import {
  MdFilterList,
  // MdKeyboardArrowLeft,
  // MdKeyboardArrowRight,
  MdOutlinePermContactCalendar,
} from 'react-icons/md';
import { SiYourtraveldottv } from 'react-icons/si';
import PopoverDropdown from '../../../components/dashboard_compo_v2/popover/PopoverDropdown';
import EventDetailDropdownFilter from '../../../components/dashboard_compo_v2/filters/EventDetailDropdownFilter';
import {
  updateDefaultCount,
  updateDefaultSortCount,
} from '../../../features/event/event.slice';
// import { useOnceCall } from '../../../hooks/useOnlyOnce';

export default function AllEventsPage() {
  const dispatch = useDispatch();
  const { filterParams, filter_loading, filter_settings, all_event } =
    useSelector((state) => state.allEventReducer);
  // const [tabs, setTabs] = useState('Sort By');
  const { showSkeleton, hasMore, loadMore } = useAllEventFilterAction();

  const [form] = Form.useForm();
  const PublishedEvents = useSelector((state) => state?.publishedEvents);
  const {
    obj: filter_options,
    all_filters_pills,
    removeSinglePill,
    AntdForm,
  } = useAllEventsFilter();
  const [disableApply, setDisableApply] = useState(false);
  const [showFilter, setShowFilter] = useState(true);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const {
    reset_filter,
    clear_filter,
    apply_filter,
    valid_params,
    setSearchParams,
    fetchEvents,
    isFilterTouched,
  } = useAllEventFilterAction();
  const scrollRef = useRef();
  const [search, setSearch] = useState();
  useEffect(() => {
    if (valid_params) {
      setSearchParams(new URLSearchParams(valid_params));
      setDisableApply(false);
    }
    return () => {
      setDisableApply(false);
    };
  }, [setSearchParams, valid_params, isFilterTouched]);

  const handleSortBy = useCallback(
    async (sort_by) => {
      await dispatch(
        updateFilter({ ...filterParams, sort_by: sort_by, page: 1 }),
      );
      await fetchEvents({});
    },
    [dispatch, filterParams, fetchEvents],
  );

  const handleFilterTourEvents = useCallback(
    async (tour) => {
      await animateScroll.scrollTo(
        scrollRef?.current?.getBoundingClientRect().top + window.scrollY,
        {
          delay: 0,
          duration: 0,
          smooth: true,
        },
      );
      await dispatch(updateFilter({ ...filterParams, tour, page: 1 }));
      // await fetchEvents({});
    },
    [dispatch, filterParams],
  );

  const handlePriceSlider = useCallback(
    (form, type, value) => {
      form.setFieldValue(type, value);
      if (type === 'slider') {
        return dispatch(
          updateFilter({ ...filterParams, min: value[0], max: value[1] }),
        );
      }
      if (type === 'min') {
        return dispatch(updateFilter({ ...filterParams, min: value }));
      }

      if (type === 'max') {
        return dispatch(updateFilter({ ...filterParams, max: value }));
      }
    },
    [dispatch, filterParams],
  );

  const handleSubmit = useCallback(
    async (e) => {
      const search_key = form.getFieldValue('search');
      await dispatch(updateFilter({ ...filterParams, search_key, page: 1 }));
      await fetchEvents({});
    },
    [dispatch, filterParams, form, fetchEvents],
  );

  const handleApplyFilter = useCallback(async () => {
    if (Boolean(filterParams?.min && filterParams?.max)) {
      if (Number(filterParams?.min) >= Number(filterParams?.max)) {
        return message.error(
          'Minimum value of price should be less than maximum value',
        );
      }
    }

    await animateScroll.scrollTo(
      scrollRef?.current?.getBoundingClientRect().top + window.scrollY,
      {
        delay: 0,
        duration: 0,
        smooth: true,
      },
    );
    setDisableApply(true);
    onClose();
    return await apply_filter();
  }, [apply_filter, filterParams?.max, filterParams?.min]);

  const callbackFilter = useCallback(
    async ({ id, key }) => {
      if (key in all_filters_pills) {
        if (
          Array.isArray(all_filters_pills[key]) &&
          all_filters_pills[key].some((e) => Boolean(e.id === String(id)))
        ) {
          await animateScroll.scrollTo(
            scrollRef?.current?.getBoundingClientRect().top + window.scrollY,
            {
              delay: 0,
              duration: 0,
              smooth: true,
            },
          );
          // dispatch(updateFilter({ ...filterParams, page: 1 }));
          return fetchEvents({});
        }
      }
      return false;
    },
    [fetchEvents, all_filters_pills],
  );

  const menuOptions = useMemo(
    () => [
      {
        name: 'Price low to high', // desc
        id: '0',
        action: async () => {
          return await handleSortBy('asc');
        },
        value: 'asc',
      },
      {
        name: 'Price high to low', // asc
        id: '1',
        action: async () => {
          return await handleSortBy('desc');
        },
        value: 'desc',
      },
      {
        name: 'Recently Listed',
        id: '2',
        action: async () => {
          return handleSortBy('newest');
        },
        value: 'newest',
      },
      {
        name: 'Oldest', // asc
        id: '3',
        action: async () => {
          return await handleSortBy('oldest');
        },
        value: 'oldest',
      },
    ],
    [handleSortBy],
  );

  const tourOptions = useMemo(
    () => [
      {
        name: 'All',
        id: '0',
        // action: async () => {
        //   return await handleFilterTourEvents(undefined);
        // },
        // active: Boolean(filterParams?.tour === undefined),
        value: null,
      },
      {
        name: 'Single Event', // asc
        id: '1',
        icon: (
          <MdOutlinePermContactCalendar className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
        ),
        // action: async () => {
        //   return await handleFilterTourEvents('2');
        // },
        // active: Boolean(filterParams?.tour === '2'),
        value: '2',
      },
      {
        name: 'Tour Event',
        id: '2',
        icon: (
          <SiYourtraveldottv className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
        ),
        // action: async () => {
        //   return await handleFilterTourEvents('1');
        // },
        // active: Boolean(filterParams?.tour === '1'),
        value: '1',
      },
    ],
    [],
  );

  const { getFilterData, onClearll } = useAllEventsFilterSorting(
    tourOptions,
    menuOptions,
    fetchEvents,
    handleSortBy,
    handleFilterTourEvents,
  );

  const handleClearFilter = useCallback(async () => {
    await animateScroll.scrollTo(
      scrollRef?.current?.getBoundingClientRect().top + window.scrollY,
      {
        delay: 0,
        duration: 0,
        smooth: true,
      },
    );
    form.setFieldValue('search', undefined);
    setSearch(undefined);
    AntdForm.setFieldsValue({
      max: undefined,
      min: undefined,
      slider: [0, 0],
    });
    // setTabs('Sort By');
    onClearll();
    onClose();
    return await clear_filter();
  }, [form, clear_filter, AntdForm, onClearll]);

  const handleResetFilter = useCallback(async () => {
    handleClearFilter();
    onClose();
    return await reset_filter();
  }, [reset_filter, handleClearFilter]);

  useDebounce(
    () => {
      dispatch(
        updateFilter({
          ...filterParams,
          search_key: search === '' ? undefined : search,
        }),
      );
    },
    500,
    [search, filterParams],
  );

  useEffect(() => {
    return () => {
      dispatch(updateFilterPills({}));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(updateDefaultCount(1));
    dispatch(updateDefaultSortCount(0));
  }, [dispatch]);

  return (
    <>
      <div className="bg-color-base-1">
        <HelmetComponent title={'NFTGOTIX | Events'} />
        <section className="">
          <div className="relative z-0">
            <div className="w-full py-4 bg-color-base-2 dark:bg-color-base-3">
              {/* Banner will come */}
              <ArtistBanner eventdetails={PublishedEvents?.banner} />
            </div>
          </div>
        </section>
        <section ref={scrollRef} className="">
          <div className="bg-color-base-4 pb-4 flex justify-center min-h-screen">
            <div className="w-full px-3.5">
              <div className="py-7">
                <div className="container-fluid max-w-[1800px]">
                  <Row>
                    <Col lg={12} xxl={12}>
                      <div className="">
                        <div className="flex justify-between items-center">
                          <HeadingTitle>
                            <h2 className="text-2xl font-normal dark:text-gray-50 text-zinc-800">
                              All Events
                            </h2>
                          </HeadingTitle>
                        </div>
                        <div className="mt-6 ">
                          <div
                            className={`flex flex-wrap2 lg:flex-nowrap w-full items-center gap-2 sticky lg:top-[68px] z-20 bg-color-base-4 pb-3 ${afterBorder}`}
                          >
                            <div className={`hidden xl:flex`}>
                              <button
                                className={`w-[40px] h-[40px] ${btnSecondayClass}`}
                                onClick={() => {
                                  setShowFilter(!showFilter);
                                }}
                              >
                                <MdFilterList
                                  className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 duration-200 ease-in text-lg ${
                                    // showFilter ? '' : 'rotate-180'
                                    ''
                                  }`}
                                />
                              </button>
                            </div>
                            <Form
                              form={form}
                              onFinish={handleSubmit}
                              className="w-full"
                              autoComplete="off"
                              // ariaautocomplete="off"
                            >
                              <Form.Item className="mb-0" name="search">
                                <Input
                                  onChange={async (e) => {
                                    setSearch(e.target.value);
                                    if (e.target.value === '') {
                                      await dispatch(
                                        updateFilter({
                                          ...filterParams,
                                          search_key: undefined,
                                        }),
                                      );
                                      return await fetchEvents({});
                                    }
                                    // await dispatch(
                                    //   updateFilter({
                                    //     ...filterParams,
                                    //     search_key: e.target.value,
                                    //   }),
                                    // );
                                  }}
                                  placeholder="Search events"
                                  className={`${formClass} mb-0 border-level-2`}
                                />
                              </Form.Item>
                            </Form>
                            <div className="flex justify-end lg:justify-between flex-wrap gap-2 w-full lg:w-auto shrink-0">
                              {/* <div className="min-w-[200px] w-full lg:max-w-[200px]"> */}
                              <PopoverDropdown
                                content={
                                  <EventDetailDropdownFilter
                                    props={getFilterData(true)}
                                  />
                                }
                                isSorting={true}
                              />
                              {/* <SliderFilterDropdown
                                  options={menuOptions}
                                  setTabs={setTabs}
                                  tabs={tabs}
                                  extra_props={{
                                    all_mode: false,
                                    classname:
                                      'dark:bg-neutral-900 bg-transparent dark:hover:bg-neutral-800 hover:bg-zinc-200 py-[7px] px-2 rounded-[5px] dark:text-whiteNew-0 text-zinc-800 border-1 border-level-2',
                                  }}
                                  closeIcon={true}
                                  closeIconCallback={async () => {
                                    setTabs('Sort By');
                                    await handleSortBy(undefined);
                                  }}
                                /> */}
                              {/* </div> */}
                              <div className="flex xl:hidden shrink-0 bg">
                                <Button
                                  className={`w-[40px] h-[40px] ${btnSecondayClass}`}
                                  onClick={() => {
                                    showDrawer();
                                  }}
                                >
                                  <BiFilterAlt
                                    className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 duration-200 ease-in text-lg `}
                                  />
                                </Button>
                              </div>
                              <div>
                                <div className="flex justify-end lg:justify-between flex-wrap gap-2 w-full h-full lg:w-auto shrink-0">
                                  {/* <div className="flex rounded-lg dark:bg-color-base-4 gap-1 divide-neutral-300 p-1"> */}
                                  {/* {tourOptions.map(
                                      ({ id, icon, action, name, active }) => (
                                        <Tooltip
                                          key={id + 'tour_option'}
                                          title={name}
                                        >
                                          <button
                                            onClick={action}
                                            className={`${
                                              active
                                                ? 'dark:bg-neutral-800 bg-lightTheme-90 dark:text-whiteNew-0 text-zinc-800'
                                                : 'dark:bg-neutral-900 bg-lightTheme-70 dark:text-neutral-400 text-zinc-800'
                                            }  leading-5 dark:hover:text-whiteNew-0 hover:text-zinc-900 dark:hover:bg-neutral-800 hover:bg-neutral-300 text-[15px] font-medium w-[31px] h-[31px] relative duration-150 ease-in rounded-md`}
                                          >
                                            {icon ?? name ?? ''}
                                          </button>
                                        </Tooltip>
                                      ),
                                    )} */}
                                  <PopoverDropdown
                                    content={
                                      <EventDetailDropdownFilter
                                        props={getFilterData(false)}
                                      />
                                    }
                                  />
                                  {/* </div> */}
                                </div>
                              </div>
                              <div>
                                <GridLayout scrollRef={scrollRef} />
                              </div>
                            </div>
                          </div>
                          <div
                            className={`flex relative py-3.5 flex-wrap flex-lg-nowrap h-full ${
                              showFilter ? 'lg:gap-4' : 'gap-0'
                            }`}
                          >
                            <div
                              className={`relative shrink-0 max-w-[230px] hidden xl:flex ${
                                showFilter ? 'w-full' : 'w-auto'
                              }`}
                            >
                              <div
                                // className="hidden xl:block xl:sticky lg:top-32 w-full shrink-0 h-full pt-[8px]"
                                className={`accordionWidth hidden xl:block xl:sticky lg:top-32 w-full shrink-0 h-full pt-[8px] duration-300 ease-in ${
                                  showFilter
                                    ? 'accordion-content opacity-100 w-full lg:max-w-[230px] 2xl:max-w-[230px]'
                                    : 'max-w-[0px]'
                                } `}
                              >
                                <div
                                  className={`${showFilter ? '' : 'opacity-0'}`}
                                >
                                  <FilterLeftSidebarV2
                                    filterParams={filterParams}
                                    filter_options={filter_options}
                                    handlePriceSlider={handlePriceSlider}
                                    filterActions={{
                                      handleClearFilter,
                                      handleResetFilter,
                                      handleApplyFilter,
                                      callback: callbackFilter,
                                      disableApply,
                                      isFilterTouched,
                                      AntdForm,
                                    }}
                                    open={open}
                                    onClose={onClose}
                                    FILTER_CONST={FILTER_CONST}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="block relative overflow-hidden w-full">
                              <div className="">
                                <div className="">
                                  <FilterPills
                                    data={all_filters_pills}
                                    pillActions={{
                                      handleClearFilter,
                                      handleApplyFilter,
                                      removeSinglePill,
                                      filter_loading,
                                    }}
                                  />
                                </div>
                                <ShowEventCardsMemo
                                  all_event={all_event}
                                  loadMore={loadMore}
                                  hasMore={hasMore}
                                  showSkeleton={showSkeleton}
                                  filter_loading={filter_loading}
                                  filter_settings={filter_settings}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          {/* <DrawerCommon
            title="Select State"
            // placement={isVerticalLayout ? 'bottom' : 'right'}
            placement={'bottom'}
            onClose={onClose}
            height="95%"
            width="480px"
            open={open}
            className={
              'select-city-drawer !bg-neutral-900 backdrop-blur-3xl border-1 border-neutral-800'
            }
            maskStyle={{ backdropFilter: 'blur(2px)' }}
          >
            <div>
              <MobileFilter />
            </div>
          </DrawerCommon> */}
        </section>
      </div>
    </>
  );
}

const ShowEventCards = ({
  all_event,
  loadMore,
  hasMore,
  showSkeleton,
  filter_loading,
  filter_settings,
}) => {
  const { view, layout } = filter_settings;
  if (
    // exploreEventdetails?.isLoading ||
    showSkeleton ||
    (view === GRID && filter_loading)
  ) {
    return (
      <>
        <Row className="row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 row-cols-xxl-5 g-3">
          {[...Array(updateDataCountByViewportForEventCards() * 2)].map(
            (_, index) => {
              return (
                <Col key={index}>
                  <EventCardSkeleton />
                </Col>
              );
            },
          )}
        </Row>
      </>
    );
  }
  if (!filter_loading && all_event?.length === 0) {
    return (
      <div className="">
        <>
          <div className="mt-2 min-h-[330px] flex items-center justify-center bg-color-base-1 bg-opacity-10 backdrop-blur-xl rounded-xl">
            <NoDataFound title={'No events found for selected filters.'} />
          </div>
        </>
      </div>
    );
  }
  if (view === LIST) {
    return (
      <EventTableListView
        props={{
          all_event,
          // exploreEventdetails,
          loadMore,
          hasMore,
          showSkeleton,
          filter_loading,
        }}
      />
    );
  }
  return (
    <>
      <InfiniteScroll
        dataLength={all_event?.length}
        next={loadMore}
        hasMore={hasMore}
        className="overflow-x-hidden py-1.5"
        loader={
          <Fragment>
            <div className="flex justify-center items-center mt-3.5">
              <DataLoader
                darkTheme
                message={loaderMessage.loading_events}
                minHeight={'min-h-[100px]'}
              />
            </div>
          </Fragment>
        }
      >
        <Row
          className={`row-cols-1 row-cols-sm-1 ${
            layout === LAYOUT2X2
              ? 'row-cols-md-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-3 row-cols-xxl-3'
              : layout === LAYOUT3X3
              ? 'row-cols-md-2 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-4'
              : 'row-cols-xxl-4'
          }   g-3`}
        >
          {all_event?.map((item, index) => (
            <Col key={String(item?._id).concat(`-${index}`)}>
              <div className="relative h-full py-[4px]">
                <EventCardV2
                  data={item}
                  // eventdetails={eventdetails}
                />
              </div>
            </Col>
          ))}
        </Row>
      </InfiniteScroll>
    </>
  );
};

const ShowEventCardsMemo = memo(ShowEventCards);
