import React from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import {
  afterBorder,
  formClassV2,
  primaryBtnClass,
  secondaryBtnClass,
} from '../../common/DefaultClasses';

export default function ContactInfoForm() {
  return (
    <React.Fragment>
      <div className="">
        <Form>
          <div className="relative pb-7 mb-7">
            <div className={`${afterBorder}`}>
              <div className="max-w-[1920px]">
                <fieldset
                  // disabled={!value}
                  className="space-y-6"
                >
                  <Row className="gy-3">
                    <Col xxl={6}>
                      <div className="w-full">
                        <Form.Group
                          className="w-full"
                          controlId="streetAddress"
                        >
                          <Row className="">
                            <Col xxl={3} className="col-form-label">
                              <Form.Label className="font-normal text-base mb-0 flex items-center">
                                Street Address
                              </Form.Label>
                            </Col>
                            <Col xxl={9}>
                              <div className="relative w-full">
                                <Form.Control
                                  type="text"
                                  // disabled={!userdetails?.email_verified}
                                  // placeholder="Street Address"
                                  className={`${formClassV2} rounded-md`}
                                />
                                {/* <span className="flex items-center gap-1 text-sky-600 absolute top-1/2 -translate-y-2/4 right-2">
                        {isUsernameAvailable === false && (
                          <>
                            <span>
                              <RxCrossCircled color="red" />
                            </span>

                            <span className="text-danger  ">Not Available</span>
                          </>
                        )}
                        {isUsernameAvailable && (
                          <>
                            <span>
                              <BsCheckCircleFill />
                            </span>
                            <span>Available</span>
                          </>
                        )}
                      </span> */}
                              </div>
                              {/* {errors?.username && (
                      <>
                        <Form.Text className="text-danger">
                          {errors?.username?.message}
                        </Form.Text>
                      </>
                    )} */}
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                    </Col>
                    <Col xxl={6}>
                      <div className="w-full">
                        <Form.Group className="w-full" controlId="city">
                          <Row className="">
                            <Col xxl={3} className="col-form-label">
                              <Form.Label className="font-normal text-base mb-0 flex items-center">
                                Town/City
                              </Form.Label>
                            </Col>
                            <Col xxl={9}>
                              <div className="relative w-full">
                                <Form.Control
                                  type="text"
                                  // disabled={!userdetails?.email_verified}
                                  // placeholder="Town/City"
                                  className={`${formClassV2} rounded-md`}
                                />
                                {/* <span className="flex items-center gap-1 text-sky-600 absolute top-1/2 -translate-y-2/4 right-2">
                        {isUsernameAvailable === false && (
                          <>
                            <span>
                              <RxCrossCircled color="red" />
                            </span>

                            <span className="text-danger  ">Not Available</span>
                          </>
                        )}
                        {isUsernameAvailable && (
                          <>
                            <span>
                              <BsCheckCircleFill />
                            </span>
                            <span>Available</span>
                          </>
                        )}
                      </span> */}
                              </div>
                              {/* {errors?.username && (
                      <>
                        <Form.Text className="text-danger">
                          {errors?.username?.message}
                        </Form.Text>
                      </>
                    )} */}
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>

                  <Row className="gy-3">
                    <Col xxl={6}>
                      <div className="w-full">
                        <Form.Group className="w-full" controlId="zipcode">
                          <Row className="">
                            <Col xxl={3} className="col-form-label">
                              <Form.Label className="font-normal text-base mb-0 flex items-center">
                                Postcode/ZIP
                              </Form.Label>
                            </Col>
                            <Col xxl={9}>
                              <div className="relative w-full">
                                <Form.Control
                                  type="text"
                                  // disabled={!userdetails?.email_verified}
                                  // placeholder="Postcode/ZIP"
                                  className={`${formClassV2} rounded-md`}
                                />
                                {/* <span className="flex items-center gap-1 text-sky-600 absolute top-1/2 -translate-y-2/4 right-2">
                        {isUsernameAvailable === false && (
                          <>
                            <span>
                              <RxCrossCircled color="red" />
                            </span>

                            <span className="text-danger  ">Not Available</span>
                          </>
                        )}
                        {isUsernameAvailable && (
                          <>
                            <span>
                              <BsCheckCircleFill />
                            </span>
                            <span>Available</span>
                          </>
                        )}
                      </span> */}
                              </div>
                              {/* {errors?.username && (
                      <>
                        <Form.Text className="text-danger">
                          {errors?.username?.message}
                        </Form.Text>
                      </>
                    )} */}
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                    </Col>
                    <Col xxl={6}>
                      <div className="w-full">
                        <Form.Group className="w-full" controlId="country">
                          <Row className="">
                            <Col xxl={3} className="col-form-label">
                              <Form.Label className="font-normal text-base mb-0 flex items-center">
                                Country
                              </Form.Label>
                            </Col>
                            <Col xxl={9}>
                              <div className="relative w-full">
                                <Form.Control
                                  type="text"
                                  className={`${formClassV2} rounded-md`}
                                />
                              </div>
                              {/* {errors?.username && (
                      <>
                        <Form.Text className="text-danger">
                          {errors?.username?.message}
                        </Form.Text>
                      </>
                    )} */}
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                  <Row className="gy-3">
                    <Col xxl={6}>
                      <div className="w-full">
                        <Form.Group className="w-full" controlId="facebook">
                          <Row className="">
                            <Col xxl={3} className="col-form-label">
                              <Form.Label className="font-normal text-base mb-0 flex items-center">
                                Facebook
                              </Form.Label>
                            </Col>
                            <Col xxl={9}>
                              <div className="relative w-full">
                                <InputGroup className="flex-nowrap">
                                  <InputGroup.Text
                                    id="facebook"
                                    className="text-sm w-[13rem]"
                                  >
                                    https://www.facebook.com/
                                  </InputGroup.Text>
                                  <Form.Control
                                    type="text"
                                    // disabled={!userdetails?.email_verified}
                                    // placeholder="Postcode/ZIP"
                                    className={`${formClassV2} rounded-md`}
                                  />
                                </InputGroup>
                              </div>
                              {/* {errors?.username && (
                      <>
                        <Form.Text className="text-danger">
                          {errors?.username?.message}
                        </Form.Text>
                      </>
                    )} */}
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                    </Col>
                    <Col xxl={6}>
                      <div className="w-full">
                        <Form.Group className="w-full" controlId="instagram">
                          <Row className="">
                            <Col xxl={3} className="col-form-label">
                              <Form.Label className="font-normal text-base mb-0 flex items-center">
                                Instagram
                              </Form.Label>
                            </Col>
                            <Col xxl={9}>
                              <InputGroup className="flex-nowrap">
                                <InputGroup.Text
                                  id="instagram"
                                  className="text-sm w-[13rem]"
                                >
                                  https://www.instagram.com/
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  // disabled={!userdetails?.email_verified}
                                  // placeholder="Postcode/ZIP"
                                  className={`${formClassV2} rounded-md`}
                                />
                              </InputGroup>
                              {/* {errors?.username && (
                      <>
                        <Form.Text className="text-danger">
                          {errors?.username?.message}
                        </Form.Text>
                      </>
                    )} */}
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                  <Row className="gy-3">
                    <Col xxl={6}>
                      <div className="w-full">
                        <Form.Group className="w-full" controlId="twitter">
                          <Row className="">
                            <Col xxl={3} className="col-form-label">
                              <Form.Label className="font-normal text-base mb-0 flex items-center">
                                Twitter
                              </Form.Label>
                            </Col>
                            <Col xxl={9}>
                              <InputGroup className="flex-nowrap">
                                <InputGroup.Text
                                  id="twitter"
                                  className="text-sm w-[13rem]"
                                >
                                  https://twitter.com/
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  // disabled={!userdetails?.email_verified}
                                  // placeholder="Postcode/ZIP"
                                  className={`${formClassV2} rounded-md`}
                                />
                              </InputGroup>
                              {/* {errors?.username && (
                      <>
                        <Form.Text className="text-danger">
                          {errors?.username?.message}
                        </Form.Text>
                      </>
                    )} */}
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                    </Col>
                    <Col xxl={6}>
                      <div className="w-full">
                        <Form.Group className="w-full" controlId="telegram">
                          <Row className="">
                            <Col xxl={3} className="col-form-label">
                              <Form.Label className="font-normal text-base mb-0 flex items-center">
                                Telegram
                              </Form.Label>
                            </Col>
                            <Col xxl={9}>
                              <InputGroup className="flex-nowrap">
                                <InputGroup.Text
                                  id="telegram"
                                  className="text-sm w-[13rem]"
                                >
                                  https://telegram.org/
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  // disabled={!userdetails?.email_verified}
                                  // placeholder="Postcode/ZIP"
                                  className={`${formClassV2} rounded-md`}
                                />
                              </InputGroup>
                              {/* {errors?.username && (
                      <>
                        <Form.Text className="text-danger">
                          {errors?.username?.message}
                        </Form.Text>
                      </>
                    )} */}
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                </fieldset>
              </div>
            </div>
          </div>
          <div className="flex gap-3 items-center">
            <button className={`${primaryBtnClass} rounded-md`} type="button">
              Save
            </button>
            <button
              className={`${secondaryBtnClass} border-1 rounded-md`}
              type="button"
            >
              Cancel
            </button>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
}
