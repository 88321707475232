import React, { useEffect } from 'react';
import './assets/css/style.css';
import './App.css';
import ThemeWrapper from './ThemeWrapper';
import Router from './Routers';
import { ConnectionProvider } from './contexts/ConnectionProvider';
import { WalletProvider } from './contexts/WalletProvider';
import { Web3ReactProvider } from '@web3-react/core';
import { connectors } from './utils/blockchain/ethereum/connectors/connectors';
import { MetaMaskProvider } from './contexts/MetamaskProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { history } from './helpers/router_templates/history';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import SystemError from './components/util/SystemError';
import GetEventProvider from './contexts/events/GetEventList';
import GetTourProvider from './contexts/tour/GetTourList';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/effect-fade';
import useDecodedToken from './hooks/useDecodedToken';
import { useScrollToTop } from './utils/util';
import { NotificationProvider } from './contexts/NotificationProvider';
import { CookiesProvider } from 'react-cookie';
import { getToken, onMessage } from 'firebase/messaging';
import { generateToken, messaging } from './helpers/firebase/firebase';
import { useOnceCall } from './hooks/useOnlyOnce';

function App() {
  useScrollToTop();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 60 * 5,
        // suspense: true,
      },
    },
  });
  const navigate = useNavigate();
  const decodedToken = useDecodedToken();

  // window.history.scrollRestoration = 'manual'
  React.useEffect(() => {
    if (!decodedToken) {
      localStorage.removeItem('token');
    }
    // if (decodedToken?.first_login) {
    //   navigate(`/user/${decodedToken?.username}/register`);
    // }
  }, [decodedToken, navigate]);

  // Initialize custom history object
  history.navigate = useNavigate();
  history.location = useLocation();
  // document.body.style.overflow = 'auto';

  // useOnceCall(() => {
  //   if (decodedToken) {
  //     generateToken();
  //     onMessage(messaging, (payload) => {
  //       console.log(payload);
  //     });
  //   }
  // }, [decodedToken]);
  //firebase code

  ///checking is session expired // Guest user can also book tickets
  // React.useEffect(() => {
  //   const checkSession = () => {
  //     if (!decodedToken) {
  //       navigate('/user/login');
  //     }
  //   };

  //   // Set the interval to 30 minutes
  //   const intervalDuration = 30 * 60 * 1000; // 30 minutes in milliseconds

  //   const interval = setInterval(checkSession, intervalDuration); // Check every second (adjust as needed)

  //   return () => clearInterval(interval);
  // }, [navigate, decodedToken]);

  return (
    <div className="App appClass">
      <ErrorBoundary FallbackComponent={SystemError}>
        {/* <Suspense fallback={<PageLoader />}> */}
        {/* <PageDarkLoader /> */}
        {/* <StepsLoader /> */}
        <QueryClientProvider client={queryClient}>
          {/* <CoingeckoProvider> */}
          <ConnectionProvider>
            <Web3ReactProvider connectors={connectors}>
              <MetaMaskProvider>
                <WalletProvider>
                  <ThemeWrapper>
                    <GetTourProvider>
                      <GetEventProvider>
                        <NotificationProvider>
                          <CookiesProvider defaultSetOptions={{ path: '/' }}>
                            <Router />
                          </CookiesProvider>
                        </NotificationProvider>
                      </GetEventProvider>
                    </GetTourProvider>
                  </ThemeWrapper>
                </WalletProvider>
              </MetaMaskProvider>
            </Web3ReactProvider>
          </ConnectionProvider>
          {/* </CoingeckoProvider> */}
        </QueryClientProvider>
        {/* </Suspense> */}
      </ErrorBoundary>
    </div>
  );
}

export default App;
