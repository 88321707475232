import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useLogout } from '../../../helpers/api/auth/auth';
import useDecodedToken from '../../../hooks/useDecodedToken';
import { useUserDetails } from '../../../hooks/user/useUserDetails';
import Tooltip from '../../common/Tooltip';
import { useContext } from 'react';
import { GetEventContext } from '../../../contexts/events/GetEventList';
import { profileUrl } from '../CustomIcons';
import { BiLogOutCircle } from 'react-icons/bi';
import { BsGlobeAmericas } from 'react-icons/bs';
import { updateLoader } from '../../../features/loader/loader.slice';
import { useDispatch } from 'react-redux';
import { DeleteAllSeats } from '../../../features/seatmap/seatmap.slice';
import { DeleteAllCart } from '../../../features/cart/cart.slice';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
export default function UserProfileDropdown({
  btnClassName,
  route,
  headerDark,
}) {
  const { logout, isLoading } = useLogout();
  const decodedToken = useDecodedToken();
  const { userdetails, user } = useUserDetails(
    decodedToken?.username || decodedToken?.id,
  );
  let dispatch = useDispatch();

  const { setEventList } = useContext(GetEventContext);

  const user_navigation = [
    {
      name: <></>,
      href: '#',
      current: false,
      submenu: [
        {
          name: (
            <>
              <Tooltip
                title={
                  route !==
                    `/user/${userdetails?.data?.data?.username}/dashboard` &&
                  'Go to Dashboard'
                }
                placement={'top'}
              >
                <div className="flex items-center gap-2 w-full">
                  <div className="flex-shrink-0 relative">
                    <img
                      className="h-8 w-8 rounded-full border-green-500 border-2 object-cover"
                      src={user.imageUrl || profileUrl}
                      alt=""
                      loading="lazy"
                    />
                    {/* add green dot */}
                    {/* {decodedToken?.id && (
                      <span className="w-3 h-3 flex absolute -top-0.5 -left-0.5 bg-green-500 rounded-full border-2 border-neutral-900"></span>
                    )} */}
                  </div>
                  <div className="">
                    {/* <div className="text-sm font-medium leading-none text-gray-400 mb-1">
                    {user.email}
                  </div> */}
                    <div
                      className={`text-sm font-medium leading-none capitalize ${
                        headerDark ? 'text-gray-100 ' : 'text-sky-700'
                      } ${
                        route !==
                          `/user/${userdetails?.data?.data?.username}/dashboard` &&
                        ''
                      }`}
                    >
                      {user.name}
                    </div>
                  </div>
                </div>
              </Tooltip>
            </>
          ),
          href:
            route !== `/user/${userdetails?.data?.data?.username}/dashboard`
              ? `/user/${
                  userdetails?.data?.data?.username ||
                  userdetails?.data?.data?._id
                }/dashboard`
              : '#',
          type: 'user',
        },
        {
          name:
            route === `/user/${userdetails?.data?.data?.username}/dashboard`
              ? 'Go to Website'
              : '',
          href:
            route === `/user/${userdetails?.data?.data?.username}/dashboard` &&
            `/`,
        },
        {
          name: 'Logout',
          href: '#',
        },
      ],
    },
  ];
  return (
    <React.Fragment>
      <div>
        <div className="flex gap-2 content-center">
          {user_navigation.map((item, idx) =>
            item?.submenu ? (
              <div className="" key={idx}>
                <Menu as="div" className="relative">
                  <div>
                    <Tooltip title="Profile" placement={'top'}>
                      <Menu.Button
                        className={` flex gap-2 relative p-0 rounded-full ${btnClassName}`}
                      >
                        <span className="sr-only">Open user menu</span>
                        <div className="flex items-center gap-2">
                          <div className="flex-shrink-0 relative p-0 border-0 rounded-full">
                            <img
                              className="h-[34px] w-[34px] rounded-full border-green-500 border-2 object-cover"
                              src={user.imageUrl || profileUrl}
                              alt=""
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </Menu.Button>
                    </Tooltip>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className={`${
                        headerDark
                          ? 'border-1 border-neutral-800 overflow-hidden bg-neutral-900 backdrop-blur-3xl bg-opacity-100'
                          : 'bg-white ring-black'
                      } absolute right-0 z-20 mt-2 origin-top-right rounded-2xl p-2 py-2 shadow-lg ring-1 ring-opacity-5 focus:outline-none`}
                      style={{ minWidth: '17rem' }}
                    >
                      {item?.submenu.map((_item, id) => (
                        <Menu.Item key={id}>
                          {({ active }) => (
                            <>
                              {_item?.name === 'Logout' ? (
                                <button
                                  onClick={(e) => {
                                    setEventList({});
                                    e.preventDefault();
                                    localStorage.removeItem('loaderState');
                                    dispatch(updateLoader(true));
                                    localStorage.removeItem('SelectedEvent');
                                    localStorage.removeItem('selectedVenue');
                                    dispatch(DeleteAllSeats());
                                    dispatch(DeleteAllCart());
                                    localStorage.removeItem(
                                      'selectedVirtualTickets',
                                    );
                                    localStorage.removeItem(
                                      'selectedInPersonTickets',
                                    );
                                    localStorage.removeItem('slug');
                                    localStorage.removeItem(
                                      'selectedCartEvent',
                                    );
                                    localStorage.removeItem('cartDataType');
                                    localStorage.removeItem('timerStart');
                                    logout();
                                  }}
                                  disabled={isLoading}
                                  className={`${
                                    headerDark
                                      ? 'text-gray-50'
                                      : 'text-gray-700'
                                  } block py-2 text-sm rounded-xl px-3 w-full text-left`}
                                >
                                  <span className="flex gap-2 items-center">
                                    <span className="text-lg">
                                      <BiLogOutCircle />
                                    </span>
                                    Logout
                                  </span>
                                </button>
                              ) : (
                                <>
                                  {_item.name !== '' ? (
                                    <Link
                                      to={_item.href}
                                      state={
                                        route ===
                                          `/user/${userdetails?.data?.data?.username}/dashboard` &&
                                        _item.name === 'Go to Website'
                                          ? { redirected: true }
                                          : null
                                      }
                                      style={{
                                        pointerEvents:
                                          route ===
                                            `/user/${userdetails?.data?.data?.username}/dashboard` &&
                                          _item.name !== 'Go to Website'
                                            ? 'none'
                                            : '',
                                      }}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block py-2 text-sm text-gray-700',
                                        _item?.type === 'user'
                                          ? `${
                                              route !== '/checkout' &&
                                              route !==
                                                '/ticket-confirmation/success' &&
                                              route !==
                                                '/ticket-confirmation/error'
                                                ? `${
                                                    headerDark
                                                      ? 'border-gray-600'
                                                      : 'border-gray-200'
                                                  } border-b-1  hover:bg-transparent px-2 mb-2`
                                                : 'hover:bg-transparent px-2'
                                            } ? `
                                          : ' rounded-xl px-3 w-full',
                                      )}
                                    >
                                      <span className="flex gap-2 items-center">
                                        {_item.name === 'Go to Website' && (
                                          <span className="text-base">
                                            <BsGlobeAmericas />
                                          </span>
                                        )}
                                        {_item.name}
                                      </span>
                                    </Link>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            ) : (
              <Link
                key={idx}
                to={item.href}
                className={classNames(
                  item.current
                    ? ' border-b-4 border-sky-600 text-sky-600'
                    : 'text-gray-900 hover:border-b-4 hover:text-sky-600 hover:border-sky-600',
                  'px-3 py-2 rounded-md text-sm font-normal border-b-4 border-b-transparent',
                )}
                aria-current={item.current ? 'page' : undefined}
              >
                {item.name}
              </Link>
            ),
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
