import React from 'react';
import HeaderFillTop from '../HeaderFillTop';
import RightSideSectionWrapper from '../RightSideSectionWrapper';
import NotificationCheckBox from '../../dashboard/notification/NotificationCheckBox';

export default function NotificationSettings() {
  return (
    <React.Fragment>
      <div>
        <div className="">
          <HeaderFillTop
            title={'Notification'}
            extraPills={<></>}
            extra={<></>}
          />
        </div>
        <RightSideSectionWrapper>
          <section>
            <div className="block overflow-hidden" style={{ flex: '0 0 75%' }}>
              <div className="w-full p-3">
                <div className="space-y-8">
                  <div className="">
                    <NotificationCheckBox textSize title={'Notifications'} />
                  </div>
                  <div className="">
                    <NotificationCheckBox textSize title={'Billing Updates'} />
                  </div>
                  <div className="">
                    <NotificationCheckBox textSize title={'New Team Members'} />
                  </div>
                  <div className="">
                    <NotificationCheckBox
                      textSize
                      title={'Completed Projects'}
                    />
                  </div>
                  <div className="">
                    <NotificationCheckBox textSize title={'Newsletters'} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </RightSideSectionWrapper>
      </div>
    </React.Fragment>
  );
}
