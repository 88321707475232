import React from 'react';
import Image from '../../common/Image';

export default function NotificationList() {
  return (
    <>
      <div className="divide-y divide-level-2">
        <div className="p-2.5 bg-color-base-2 dark:bg-color-base-1">
          <div className="flex items-center justify-between gap-1.5">
            <div className="flex items-center gap-2">
              <div className="w-[38px] h-[38px] rounded-md overflow-hidden">
                <Image
                  src="https://nltmarketplace.s3.us-west-1.amazonaws.com/nftgotix/development/event-image/658eb39a4c53fa63078126db.png"
                  className={'w-full h-full object-cover'}
                />
              </div>
              <div className="flex flex-col gap-0">
                <span className="text-color-primary font-semibold">
                  MLB: Rangers Vs Royals
                </span>
                <span className="text-color-secondary text-xs">
                  24 APRIL, 2024
                </span>
              </div>
            </div>
            <div className="shrink-0">
              <span className="text-color-secondary font-medium">$ 80.00+</span>
            </div>
          </div>
        </div>

        {[...Array(50)].map((_, idx) => (
          <div className="p-2.5">
            <div className="flex items-center justify-between gap-1.5">
              <div className="flex items-center gap-2">
                <div className="w-[38px] h-[38px] rounded-md overflow-hidden">
                  <Image
                    src="https://nltmarketplace.s3.us-west-1.amazonaws.com/nftgotix/development/event-image/658eb39a4c53fa63078126db.png"
                    className={'w-full h-full object-cover'}
                  />
                </div>
                <div className="flex flex-col gap-0">
                  <span className="text-color-primary font-semibold">
                    MLB: Rangers Vs Royals
                  </span>
                  <span className="text-color-secondary text-xs">
                    24 APRIL, 2024
                  </span>
                </div>
              </div>
              <div className="shrink-0">
                <span className="relative flex h-[8px] w-[8px]">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-2 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-[8px] w-[8px] bg-green-2"></span>
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
