const config = {
  baseUrl: process.env.REACT_APP_API_STAGE_BASEURL,
  walletEndpoint: process.env.REACT_APP_WALLET_ENDPOINT,
  QRCodeBaseUrl: process.env.REACT_APP_QR_CODE_URL,
  venue_builder_baseURL: process.env.REACT_APP_VENUE_BUILDER_STAGE_BASEURL,
  venue_builder_baseURLV2: process.env.REACT_APP_VENUE_BUILDER_STAGE_BASEURLV2,
  venue_builder_baseURLV3: process.env.REACT_APP_VENUE_BUILDER_STAGE_BASEURLV3,
  open_cage_baseurl: process.env.REACT_APP_OPEN_CAGE_URL,
  open_cage_key: process.env.REACT_APP_OPEN_CAGE_KEY,
  rayzorpay_key: process.env.REACT_APP_RAYZORPAY_KEY,

  libraries: ['places'],

  endpoints: {
    // user auth endpoints
    email_signup: '/user',
    email_login: '/user/signin',
    wallet_login: '/user/wallet/signin',
    email_verification: '/user/verification/',
    check_username: '/user/check/',
    check_email: '/user/check/email/',
    check_order_shared_on_email: '/ticket/check-order-shared',
    check_phone: '/user/check/contact/',
    resend_email: '/user/verification/email',
    email_signin: '/user/signin',
    phone_signin: '/user/signin',
    forget_password: '/user/verification/forgotPassword',
    reset_password: '/user/verification/resetPassword/',
    change_email: '/user/verification/email/otp',
    verify_otp: '/user/verification/otp',
    refresh_token: '/user/auth/token',
    subscribe_to_newsletter: '/cms/home/subscribe-newsletter-email',
    forget_password_phone: '/user/verification/forgotPasswordContact',

    // user details endpoints
    user_details: '/user/',
    add_update_wallet: '/user/wallet',
    set_primary_wallet: '/user/wallet/primary',
    remove_wallet: '/user/wallet/remove',
    remove_images: '/user/remove-image',
    follow_unfollow: '/user/follow',

    // Event details
    create_event: '/event',
    event_list: '/event',
    delete_event: '/event/delete',
    update_event: '/event',
    get_event_details: '/event/event-details/',
    published_events: '/event/published-event',
    ongoing_events: '/event/ongoing-event',
    banner_events: '/event/home-slider',
    trending_events: '/event/trending-event',
    recent_events: '/event/recent-event',
    explore_events: '/event/explore-event',
    saved_event_list: '/event/saved-events',
    favourite_event_list: '/event/favourite-events',
    event_seat_details: '/event/event-seat-details/',
    booked_ticket_by_event: '/ticket/booked-ticket-by-event/',
    attendees_list_by_event: '/ticket/attendees-list-by-event/',
    virtual_attendees_list_by_event:
      '/ticket/streaming-virtual-attendees-list-by-event/',
    all_attendees_list_by_event: '/ticket/all-attendees-list-by-event/',
    copy_event: '/event/copy-event',
    all_event_list: '/cms/home/event-filter-list',
    booked_ticket_by_event_organizer:
      '/ticket/booked-ticket-by-event-organizer',
    eventSales: '/event/event-analytics-report',

    //create venue
    venue_type: '/cms/venue-category?limit=10&page=',
    venue_category: '/cms/venue-sub-category/sub-category',
    suggested_venue: '/venues?limit=100&page=',
    create_venue: '/venues/create-venue-v2',
    update_venue: '/venues/update-venue',
    delete_venue: '/venues/delete',
    get_venue_details_by_id: '/venues/venue-details/',
    create_venue_from_preDesign: '/venues',

    //Ticket Type
    category_list: '/category/event-category',
    create_category: '/category',
    delete_category: '/category/delete',
    category_Details: '/category/category-details/',
    update_category: '/category',
    view_buyer_details: '/ticket/booked-ticket-user',
    cancel_ticket: '/ticket/cancel-ticket',
    experience_bundle: '/event/experiance-bundle',

    //seat Mapping
    event_all_details: '/event/event-all-details/',
    price_seatmap_list: '/price-seatmap/price-seatmap-list/',
    postSeatMappingDetails: '/price-seatmap/create-cart-seats',
    getSeatMappingDetails: '/price-seatmap/get-cart-seats',
    deletSeatMapDetails: '/price-seatmap/delete-cart-seats',
    updateCartSeatsDetails: '/price-seatmap/update-cart-seats',

    //ticket design
    create_ticket: '/ticket',
    ticket_list: '/ticket/event-ticket',
    delete_ticket: '/ticket/delete',
    ticket_details: '/ticket/ticket-details/',
    update_tickets: '/ticket',
    book_ticket: '/ticket/booking',
    booked_ticket_list: '/ticket/booked-ticket',
    booked_ticket_event_user: '/ticket/booked-ticket-by-event',
    get_ticket_preview_details: '/ticket/book-ticket-by-order-id',
    cancel_shared_ticket: '/ticket/cancel-shared-ticket',
    check_name: '/ticket/check-ticket-name',

    //venue cat and sub category
    venue_cat_details: '/cms/venue-category/venue-type/',
    venue_subcat_details_by_id: '/cms/venue-sub-category/venue-category/',
    venue_subcat_details: '/cms/venue-sub-category/sub-category/',

    //custodial-wallet-api
    create_wallet: '/wallet/create',
    lock_wallet: '/wallet/lock',
    unlock_wallet: '/wallet/unlock',
    wallet_verify_otp: '/wallet/verify',
    wallet_balance_check: '/user/wallet/check-balance',

    //search input landing page
    search_input: '/cms/home?',
    location: '/cms/home/locations',
    eventSearcher: '/cms/home/trending-search',

    // payments
    make_ticket_order: '/payment/order',
    capture_payment: '/payment/capture',
    minted_ticket_payment: '/admin/payment/user-all-minted-tickets',

    // NFT's API
    mint_nft: '/nft/mint',
    all_nft: '/nft/list',
    single_nft_details: '/nft/nft-details/',
    retry_mint: '/nft/retry-mint',
    single_nft_logs: '/nft/logs',
    single_nft_list: '/nft/list?',

    // Qr Code API
    QRCode: 'booking-details?booking_id=',

    //get Top 10 Event Organiser
    TopSeller: '/user/top/seller',

    //add credit or debit card
    add_card: '/wallet/create-card',
    delete_card: '/wallet/remove-card',
    add_bank: '/wallet/create-bank',
    delete_bank: '/wallet/remove-bank',
    add_wallet_money: '/wallet/usd-money',
    get_wallet_money_details: '/wallet/usd-money',
    get_wallet_balance: '/wallet/usd-amount',
    get_usdmoney_by_id: '/wallet/usd-money/',
    get_razerpay_money: '/payment/payment-list',

    //address
    add_address: '/user/add-address',
    update_address: '/user/update-address',
    delete_address: '/user/address/delete/',
    get_addresses: '/user/address/list',
    get_address_by_id: '/user/address/list/',
    nft_transfer_token_verification: '/user/nft-transfer-token-verification',

    //get ticket template
    ticket_template: '/admin/ticket?page=',
    ticket_type: '/admin/category?page=',
    category_User: '/category?page=',

    //get Locations
    get_location: '/cms/home/locations',

    //Venue Filter List
    get_venue_filter: '/cms/home/venue-filter-list',

    //checking name
    event_name: '/event/check-name',
    venue_name: '/venues/check-name',
    category_name: '/category/check-name',
    nft_name: '/ticket/check-nft-name',

    //Refund Policy
    getPolicy: '/admin/ticket-policy',
    createPolicy: '/admin/ticket-policy',
    list_By_UserID: '/admin/ticket-policy/user-list/',
    Update_policy: '/admin/ticket-policy',
    delete_policy: '/admin/ticket-policy/delete',
    check_Policy_name: '/admin/ticket-policy/check-policy-name',
    check_copy_policy: '/admin/ticket-policy/check-copy-policy',

    // Tour API's
    // tour_list: '/event/tour-list?',
    create_Tour: '/event/tour',
    tour_list: '/event/tour-list',
    get_Tour_details: '/event/tour-details/',
    delete_tour: '/event/delete-tour',
    tour_name: '/event/check-tour-name',
    update_tour: '/event/tour',
    tour_event_list: '/event/tour-events',
    tour_details_by_id: '/event/tour-details/',
    remove_tour_IMG: '/event/remove-tour-img',
    remove_event_IMG: '/event/remove-image',

    //artist
    sample_artist: '/cms/artist/pre-defined-list',
    Artist_list_By_UserID: '/cms/artist',
    create_artist: '/cms/artist',
    Update_artist: '/cms/artist',
    delete_artist: '/cms/artist/delete',
    remove_artist_IMG: '/cms/artist/remove-artist-img',
    check_Artist_name: '/cms/artist/check-name',
    artist_type: '/cms/artist/type',
    create_artist_type: '/cms/artist/type',
    artist_details: '/cms/artist/detail/',
    top_artist: '/cms/artist/trending-artists-teams',
    artist_by_id: '/cms/artist/detail',
    artist_by_tour_id: '/cms/artist/artist-by-tour',
    all_event_filter_count: '/cms/home/event-filter-count-list',
    all_artist_filter_count: '/cms/home/artist-filter-data',
    all_artist_list: '/cms/home/artist-filter-list',

    resale_ticket: '/ticket/resale-ticket',
    cancel_resale_ticket: '/ticket/cancel-resale-ticket',
    share_virtual_ticket: '/ticket/share-ticket-on-email',
    share_order_on_email: '/ticket/share-order-on-email',

    // Stream APIs
    enter_stream: '/ticket/enter-stream',
    exit_stream: '/ticket/exit-from-stream',
    all_sessions: '/ticket/live-stream',
    ticket_auth_status: '/ticket/check-ticket-status',
    stream_all_nfts: '/ticket/user-nfts',
    get_shared_ticket: '/ticket/get-shared-order',
    validate_shared_ticket: '/ticket/validate-stream-order',
    validate_qr_ticket: '/ticket/validate-stream-ticket',
    //follow or unfollow artist
    follow_artist: '/cms/artist/follow',
    artist_follower_list: '/user/follower/list',

    //LOGS
    getLogs: '/ticket/get-logs',
    get_logs_history: '/user/history/all-history',
    get_logs_activities: '/user/log/activities',

    //get UserLocation
    user_location: `geocode/v1/json`,
    popular_states: `/cms/home/popular-locations`,

    event_list_cms: `/event/all-published-events`,

    //Booking Status
    bookStatus: '/venues/check-order-status',
    seatConfirm: '/venues/insert-venue-seat',
    virtualStatus: '/price-seatmap/check-virtual-seats',
    virtualConfirm: '/price-seatmap/virtual-hold-seats',

    // Guest User
    guest_send_verification_email: '/user/guest-send-verification/email',
    guest_verification_email: '/user/guest-verification/email',

    // Business Account
    check_email_new: '/business/user/check/email/',
    signup_business_user: '/business/user',
    signin_business_user: '/business/user/signin',
    signup_business_type_list: '/business/user/business-type-list',
    get_business_user_details: '/business/user/get-user-data',
    add_user: '/business/user/business-sub-user',
    get_user: '/business/user/business-sub-user',
    update_user: '/business/user/business-sub-user',
    delete_user: '/business/user/business-sub-user-data?businessSubUsers_id=',
    get_category: '/business/user/game-category', // Pagination is also done
    get_subcategory: '/business/user/game-sub-category',
    get_bet_category: '/business/user/bet-category',
    get_bet_type_list: '/business/user/bet-type',
    get_bet_period: '/business/user/bet-period',
    get_bet_selection: '/business/user/bet-selection',
    check_all_user: '/business/user/check-sub-user-data',
    mint_nft_for_business: '/business/user/mint-nft',
    check_balance_for_business: '/business/user/check-balance',
    get_mint_data: '/business/user/get-sub-user-mint-data',
    get_sub_user_data: '/business/user/business-sub-user-data',
    get_info_data: '/business/user/game-info-sub-category',

    // Waitlist
    waitlist: '/cms/home/join-waiting-list',
    get_waitlist: '/cms/home/get-join-waiting-list',

    //2FA Authentication
    get2FAOTP: '/user/verification/authentication/otp',
    resendOTP2FA: '/user/verification/authentication/resend-otp',

    //pageSize
    getPaginationSize: '/user/get/get-pagination-size',
    updatePaginationSize: '/user/update-pagination-size',

    logout: '/user/logged/out',
  },
};

export default config;
