import React from 'react';
import HeaderDashboard from '../../components/dashboard_compo_v2/HeaderDashboard';
import { Outlet } from 'react-router-dom';

export const DashboardHeaderLayout = () => {
  return (
    <>
      <div className="dashboard_v2">
        <HeaderDashboard
          logoClassName={'text-white'}
          btnClassName={'btnDark'}
          landingPage={'transparent'}
        />
        <Outlet />
        {/* <FooterLandingPage /> */}
      </div>
    </>
  );
};
