import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { MdInfoOutline } from 'react-icons/md';
import Modal from '../../common/Modal';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteAllSeats } from '../../../features/seatmap/seatmap.slice';
import { useCallback } from 'react';
import { btnPrimaryClass, btnSecondayClass } from '../DefaultClasses';
import {
  DeleteAllCart,
  cartError,
  setOpenTimerattemptModal,
  updateTotalAttempt,
  updateVirtualError,
} from '../../../features/cart/cart.slice';
import { clearGuestUserCart } from '../../../utils/util';
import { updateCartDrawer } from '../../../features/home_page/homepage.slice';
import { useCookie } from '../../../hooks/cookie';

export default function AlertModal({
  isOpen,
  setIsOpen,
  invoke,
  setInitialValue,
  initialValue,
  updateDetails,
  from = '',
  navigateBack = false,
  slug = '',
  involke = false,
  refetch = () => {},
  darkTheme,
  show,
  isPhoneAvailable = true,
  setIsPhoneAvailable = () => {},
  isUsernameAvailable = true,
  setIsUsernameAvailable = () => {},
  checkout = false,
  profile = false,
}) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  // from redux we are getting the user selected seats.
  // let selectedSeats = useSelector((state) => state.selectedSeats);
  let IsGuestUser = localStorage.getItem('GuestUser')
    ? JSON.parse(localStorage.getItem('GuestUser'))
    : false;
  const { removeCookie } = useCookie(); //don't remove the cookie and setCookie similar for all the places

  // to check the quantity is added to previous data or not
  // const { setStartTimer } = useCheckOut();

  // useCallback added to all the onchange function in optimisations.

  const onCancel = useCallback(() => {
    if (from === 'buyer') {
      setIsOpen(false);
      refetch();
    } else {
      setIsOpen(false);
      setInitialValue(initialValue);
      invoke();
      refetch();
    }
    setIsPhoneAvailable();
    setIsUsernameAvailable();
  }, [
    initialValue,
    from,
    invoke,
    refetch,
    setInitialValue,
    setIsOpen,
    setIsPhoneAvailable,
    setIsUsernameAvailable,
  ]);

  const onSubmit = useCallback(() => {
    if (from === 'buyer') {
      setIsOpen(false);
      if (navigateBack) {
        // if (involke) {
        //   dispatch(DeleteAllSeats([]));
        // }
        navigate(`/event-detail/${slug}`);
      } else {
        navigate('/', { state: { redirected: true } });
      }
    } else {
      updateDetails();
      setIsOpen(false);
    }
    setIsPhoneAvailable();
    setIsUsernameAvailable();
    // console.log('slug', slug);
    // localStorage.setItem('slug', JSON.stringify(slug)); // need to replace with persistance.
    // setStartTimer(slug);
    localStorage?.removeItem('selectedSeatData');
    localStorage?.removeItem('PurchasedTickets');
    localStorage?.removeItem('billingAddress');
    localStorage?.removeItem('selectedCartEvent');
    dispatch(DeleteAllSeats());
    if (IsGuestUser) {
      clearGuestUserCart();
      removeCookie(`timer${'GuestUser'}`);
      dispatch(updateTotalAttempt(0));
      dispatch(setOpenTimerattemptModal(false));
      removeCookie(`TotalAttempt${'GuestUser'}`);
      dispatch(DeleteAllCart([]));
    }
    // console.log(show);
  }, [
    navigateBack,
    dispatch,
    from,
    navigate,
    setIsOpen,
    setIsPhoneAvailable,
    setIsUsernameAvailable,
    slug,
    updateDetails,
    IsGuestUser,
    removeCookie,
  ]);

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        maxWidth="max-w-md"
        backdrop={darkTheme ? 'backdrop-blur-sm' : null}
        bgColor="dark:bg-zinc-900 bg-whiteNew-0 bg-opacity-100 backdrop-blur-sm bg-center"
        darkTheme={darkTheme}
      >
        <div className="mt-4 mt-md-0">
          <Row className="items-center">
            <Col xs={12} md={12}>
              <div className="flex justify-center flex-col text-center">
                <div className="text-6xl text-yellow-400 flex justify-center">
                  <MdInfoOutline />
                </div>
                <div className="my-2">
                  <h4
                    className={
                      darkTheme
                        ? 'dark:text-whiteNew-0 text-zinc-800'
                        : 'text-dark'
                    }
                  >
                    {from === 'buyer'
                      ? IsGuestUser
                        ? 'Checkout will be cancelled and order will not be saved in the cart. Do you wish to to proceed?'
                        : checkout
                        ? 'Checkout will be cancelled and order will be saved in the cart. Do you wish to to proceed?'
                        : `The seat selection will be lost and you will be navigated to ${
                            !navigateBack ? 'home' : 'event'
                          } page. Do you wish to to proceed?`
                      : 'Do you want to Update the changes?'}
                  </h4>
                </div>
              </div>
              <div className="flex justify-center mt-4 gap-2 gap-md-3">
                <button
                  onClick={onCancel}
                  type="button"
                  className={
                    darkTheme
                      ? `bg-neutral-700 bg-opacity-50 text-gray-50 hover:bg-neutral-700 hover:bg-opacity-70 duration-200 ease-in shadow-lg shadow-gray-200 p-2 rounded-lg  flex justify-center items-center w-full max-w-[120px]`
                      : btnSecondayClass
                  }
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={onSubmit}
                  disabled={
                    isPhoneAvailable === false || isUsernameAvailable === false
                  }
                  className={
                    darkTheme
                      ? `dark:bg-landingPurple-60 bg-sky-600 hover:bg-sky-700 text-gray-50 dark:hover:bg-landingPurple-70 p-2 rounded-lg duration-200 ease-in w-full max-w-[120px]`
                      : btnPrimaryClass
                  }
                >
                  Confirm
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export const TicketAlert = ({
  isOpen,
  setIsOpen,
  message,
  closeModal,
  setTicketNameError,
  darkTheme,
}) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        maxWidth="max-w-md"
        backdrop={darkTheme ? 'backdrop-blur-sm' : null}
        bgColor={
          darkTheme
            ? 'bg-neutral-800 bg-opacity-100 backdrop-blur-sm bg-center'
            : null
        }
      >
        <div className="mt-4 mt-md-0">
          <Row className="items-center">
            <Col xs={12} md={12}>
              <div className="flex justify-center flex-col text-center">
                <div className="text-6xl text-yellow-400 flex justify-center">
                  <MdInfoOutline />
                </div>
                <div className="my-2">
                  <h4 className={darkTheme ? 'text-whiteNew-0' : 'text-dark'}>
                    {message}
                  </h4>
                </div>
              </div>
              <div className="flex justify-center mt-4 gap-2 gap-md-3">
                <button
                  onClick={() => {
                    setIsOpen(false);
                    closeModal(false);
                    setTicketNameError('');
                  }}
                  type="button"
                  className={`${btnSecondayClass}`}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setIsOpen(false);
                    message !==
                      'You are already having Ticket created in this category. Would you like to replace it?' &&
                      closeModal(false);
                    setTicketNameError('');
                  }}
                  className={`${btnPrimaryClass}`}
                >
                  Confirm
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export const SeatBookStatus = ({ isOpen, setIsOpen, refresh }) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        preventClosingOutsideClick={true}
        closeBtnPos={'hidden'}
        maxWidth="max-w-md"
        backdrop={'backdrop-blur-sm'}
        bgColor={'bg-neutral-800 bg-opacity-100 backdrop-blur-sm bg-center'}
      >
        <div className="mt-4 mt-md-0">
          <Row className="items-center">
            <Col xs={12} md={12}>
              <div className="flex justify-center flex-col text-center">
                <div className="text-6xl text-yellow-400 flex justify-center">
                  <MdInfoOutline />
                </div>
                <div className="my-2">
                  <h4 className={'text-whiteNew-0'}>
                    The tickets you selected might not be available. Refresh to
                    review the selection.
                  </h4>
                </div>
              </div>
              <div className="flex justify-center mt-4 gap-2 gap-md-3">
                <button
                  onClick={refresh}
                  className={`bg-landingPurple-60 text-gray-50 hover:bg-landingPurple-70 p-2 rounded-lg duration-200 ease-in w-full max-w-[120px]`}
                >
                  Refresh
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export const CartCheckOutAlert = ({ message, darkTheme, slug }) => {
  const openModal = useSelector(cartError);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeModal = useCallback(() => {
    dispatch(updateVirtualError(false));
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    dispatch(updateVirtualError(false));
    dispatch(updateCartDrawer(false));
    setTimeout(() => {
      navigate(`/event-detail/${slug}`);
    }, 1000);
  }, [slug, dispatch, navigate]);

  return (
    <React.Fragment>
      <Modal
        isOpen={openModal?.Virtual || openModal?.Inperson}
        setIsOpen={closeModal}
        maxWidth="max-w-md"
        backdrop={darkTheme ? 'backdrop-blur-sm' : null}
        bgColor={
          darkTheme
            ? 'bg-neutral-800 bg-opacity-100 backdrop-blur-sm bg-center'
            : null
        }
      >
        <div className="mt-4 mt-md-0">
          <Row className="items-center">
            <Col xs={12} md={12}>
              <div className="flex justify-center flex-col text-center">
                <div className="text-6xl text-yellow-400 flex justify-center">
                  <MdInfoOutline />
                </div>
                <div className="my-2">
                  <h4 className={darkTheme ? 'text-whiteNew-0' : 'text-dark'}>
                    {message}
                  </h4>
                </div>
              </div>
              <div className="flex justify-center mt-4 gap-2 gap-md-3">
                <button
                  onClick={closeModal}
                  type="button"
                  className={`${btnSecondayClass}`}
                >
                  Cancel
                </button>
                <button onClick={onSubmit} className={`${btnPrimaryClass}`}>
                  Proceed
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
};
