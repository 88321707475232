import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  razerpay_data: {},
  razerpay_pagination: { page: 1, limit: 10 },
  usd_money: 0,
  user_address_list: [],
  gotix_wallet_transaction: undefined,
  total_transaction_count: 0,
  page: 1,
  pageSize: 10,
  sort_by_value: ""
};

const slice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    AddUserDetail: (state, { payload }) => {
      state.data = payload.data;
    },
    updateUsdMoney: (state, { payload }) => {
      state.usd_money = payload
    },
    updateUserAddressList: (state, { payload }) => {
      state.user_address_list = payload
    },
    updateWalletTranscation: (state, { payload }) => {
      state.usd_money = payload
    },
    updateGotixTransactions: (state, { payload }) => {
      state.gotix_wallet_transaction = payload.data
      state.total_transaction_count = payload.count
    },
    setPage: (state, { payload }) => {
      state.page = payload
    },
    setPageSize: (state, { payload }) => {
      state.pageSize = payload
    },
    setSortBy: (state, { payload }) => {
      state.sort_by_value = payload
    },
    setRazerpayData: (state, { payload }) => {
      state.razerpay_data = payload
    },
    setRazerPayPagination: (state, { payload }) => {
      state.razerpay_pagination = payload
    },
  },
});

let userDetailsreducer = slice.reducer;

export const { AddUserDetail, updateUsdMoney, updateUserAddressList, updateWalletTranscation, setPage, setPageSize, updateGotixTransactions, setSortBy, setRazerpayData, setRazerPayPagination } = slice.actions;

export { userDetailsreducer };
