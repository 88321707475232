import React from 'react';
// import Image from '../../common/Image';
import { FullScreenSkeleton } from '../../util/Skeleton';
// import { checkFileType } from '../../../utils/util';
// import Video from '../../common/Video';
import EventImageVideo from '../../common/EventImageVideo';

export default function EventBanner({
  image,
  children,
  className,
  isLoading,
  full_banner,
}) {
  // console.log('image', image);
  return (
    <React.Fragment>
      <div
        className={`relative container-fluid px-0  h-auto ${
          full_banner ? 'w-full' : 'max-w-[1800px]'
        }`}
      >
        <div className={`relative h-full ${className}`}>
          <div className="absolute inset-0 z-0">
            {isLoading ? (
              <FullScreenSkeleton />
            ) : (
              <>
                <EventImageVideo
                  data={image}
                  alt="banner"
                  className={'w-full h-full object-cover'}
                  loading="lazy"
                  // width="384px"
                  // height="512px"
                />
                {/* {checkFileType(image) === 'video' ? (
                  <Video
                    className="w-full h-full object-cover"
                    src={image?.url}
                    removeControls={true}
                  />
                ) : (
                  <Image
                    src={image?.url}
                    alt="banner"
                    className="w-full h-full object-cover"
                  />
                )} */}
                {/* <Video className="w-full h-full object-cover" src={''} /> */}
              </>
            )}

            <div className="dark:bg-neutral-900 bg-whiteNew-0 absolute inset-0 bg-opacity-0 dark:bg-opacity-30"></div>
          </div>
          <div className="flex justify-center w-full">
            <div className="z-10 relative py-0 pb-5 md:py-5 px-2 md:px-0 w-full max-w-[1800px]">
              {children}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
