import { useDispatch, useSelector } from 'react-redux';
import {
  AddCartInpersonData,
  AddCartvirtualData,
  AddEventDetails,
  AddSlug,
  AddcartDataType,
  DeleteAllCart,
  cartData,
  setOpenTimerattemptModal,
  updateTotalAttempt,
} from '../../../../features/cart/cart.slice';
import {
  clearCart,
  getDatasUserid,
  getSubDatasUserid,
  setCartData,
  setInpersonCartData,
} from '../../../../utils/util';
import { DeleteAllSeats } from '../../../../features/seatmap/seatmap.slice';
import useDecodedToken, {
  useApiHeader,
} from '../../../../hooks/useDecodedToken';
import { useOnceCall } from '../../../../hooks/useOnlyOnce';
import { useCookies } from 'react-cookie';
import { useCookie } from '../../../../hooks/cookie';
import { useMutation } from '@tanstack/react-query';
import {
  getSeatConfirmation,
  getVirtualConfirmation,
} from '../../../../hooks/event/seat_mapping/useSeatMapping';
// import { updateCartDrawer } from '../../../../features/home_page/homepage.slice';

export const useCartCheckOut = () => {
  let dispatch = useDispatch();
  const { json_header } = useApiHeader();

  let selectedSeats = useSelector((state) => state.selectedSeats);
  let cartselectedSeats = useSelector(cartData);
  let { cartInpersonData, cartvirtualData, slug, selectedEvent } =
    cartselectedSeats;
  const decodedToken = useDecodedToken();
  const { setCookie, removeCookie } = useCookie();

  function virtualSeatConfirmation() {
    const categoryLengths = {};

    cartvirtualData?.[slug]?.virtualData[0]?.type?.forEach(({ category }) => {
      categoryLengths[category] = (categoryLengths[category] || 0) + 1;
    });

    const finalCategory = Object.entries(categoryLengths).map(
      ([category, quantity]) => ({
        category,
        quantity,
      }),
    );
    let event = cartvirtualData?.selectedEvent?._id;
    let user = cartvirtualData?.selectedEvent?.user_id;
    let body = {
      category: finalCategory,
      event_id: event,
      user_id: user,
      type: 'remove',
    };

    return getVirtualConfirmation(body, json_header);
  }

  const virtualConfirmMutation = useMutation(virtualSeatConfirmation, {
    onSuccess: (data) => {
      removeStatus();
      // dispatch(updateCartDrawer(true));
      // ClearCartData();
      // dispatch(updateCartDrawer(false));
    },
    onError: (data) => {
      console.log(data);
    },
  });

  function seatConfirmation() {
    const seatIDs = cartInpersonData?.[slug].map((obj) => obj.seat_id);
    const venueID = selectedEvent?.venue_id;

    let body = {
      seat_id: seatIDs,
      venue_id: venueID,
      type: 'remove',
    };

    return getSeatConfirmation(body, json_header);
  }

  const seatConfirmMutation = useMutation(seatConfirmation, {
    onSuccess: (data) => {
      removeStatus();
      // ClearCartData();
      // dispatch(updateCartDrawer(false));
    },
    onError: (data) => {},
  });

  const checkQuantityAdded = () => {
    if (
      Object.keys(cartInpersonData)?.length === 0 &&
      Object.keys(cartvirtualData)?.length === 0
    ) {
      return true;
    } else if (Object.keys(cartInpersonData)?.length > 0) {
      let currentLength = selectedSeats?.data?.length;
      let prevLength = Object.values(cartInpersonData)?.[0]?.length;
      if (prevLength < currentLength) {
        return true;
      } else {
        return false;
      }
    } else if (Object.keys(cartvirtualData)?.length > 0) {
      let currentLength = selectedSeats?.virtualData?.[0]?.type?.length;
      let prevLength =
        Object.values(cartvirtualData)?.[0]?.virtualData?.[0]?.type?.length;

      if (prevLength < currentLength) {
        return true;
      } else {
        return false;
      }
    }
  };
  const addingDatasToCart = (type, slug, eventDetails, payload, userID) => {
    setStartTimer(slug);
    if (type === 'Virtual') {
      localStorage.setItem(
        `selectedVirtualTickets${
          decodedToken ? decodedToken?.id : 'GuestUser'
        }`,
        JSON.stringify(
          selectedSeats
            ? setCartData(slug, selectedSeats, eventDetails, payload) //used to set the data for virtual event, Check util.js file
            : {
                data: [],
                allData: [],
                virtualDataCounter: {},
                virtualData: [],
                virtualDataID: {},
                virtualDataResaleCounter: {},
              },
        ),
      ); // need to change persistance.

      localStorage.setItem('slug', JSON.stringify(slug));
      dispatch(
        AddCartvirtualData(
          setCartData(slug, selectedSeats, eventDetails, payload),
        ),
      );
      dispatch(AddSlug(slug));
      dispatch(AddcartDataType('Virtual'));
      dispatch(AddEventDetails(eventDetails));
    } else {
      selectedSeats &&
        selectedSeats?.data?.length > 0 &&
        localStorage.setItem(
          `selectedInPersonTickets${
            decodedToken ? decodedToken?.id : 'GuestUser'
          }`,
          JSON.stringify(
            selectedSeats?.data
              ? setInpersonCartData(slug, selectedSeats, eventDetails)
              : [],
          ),
        ); // need to change persistance.

      if (selectedSeats && selectedSeats?.data?.length > 0) {
        dispatch(
          AddCartInpersonData(
            setInpersonCartData(slug, selectedSeats, eventDetails),
          ),
        );
        localStorage.setItem('slug', JSON.stringify(slug));
        dispatch(AddSlug(slug));
        dispatch(AddcartDataType('Physical'));
        dispatch(AddEventDetails(eventDetails));
      }
    }
  };

  const setStartTimer = (slug) => {
    // console.log('hereree');
    // if (
    //   selectedSeats?.data?.length > 0 ||
    //   selectedSeats?.virtualData?.length > 0
    // ) {
    // dispatch(startTimer(60));
    // console.log('here');
    const currentTime = Math.floor(Date.now() / 1000);
    setCookie(
      `timer${decodedToken ? decodedToken?.id : 'GuestUser'}`,
      currentTime,
      {
        maxAge: 600,
      },
    );
    sessionStorage.setItem(
      `timer${decodedToken ? decodedToken?.id : 'GuestUser'}`,
      JSON.stringify('10:00'),
    );
    // localStorage.removeItem('slug');
    // }
  };

  function removeStatus() {
    clearCart();
    // dispatch(startTimer(0));
    dispatch(DeleteAllSeats([]));
    dispatch(DeleteAllCart([]));
    removeCookie(
      `timer${
        decodedToken ? decodedToken?.id : decodedToken === null && 'GuestUser'
      }`,
    );
    removeCookie(
      `TotalAttempt${
        decodedToken ? decodedToken?.id : decodedToken === null && 'GuestUser'
      }`,
    );
    dispatch(setOpenTimerattemptModal(false));
    dispatch(updateTotalAttempt(0));
    dispatch(setOpenTimerattemptModal(false));
  }

  const ClearCartData = () => {
    if (Object.keys(cartInpersonData).length > 0) {
      seatConfirmMutation.mutate();
    }
    if (Object.keys(cartvirtualData).length > 0) {
      virtualConfirmMutation.mutate();
    }
  };

  return {
    addingDatasToCart,
    checkQuantityAdded,
    setStartTimer,
    ClearCartData,
  };
};

export const useUpdateCartOnMounting = () => {
  const decodedToken = useDecodedToken();
  const dispatch = useDispatch();
  const { ClearCartData } = useCartCheckOut();
  const cartselectedSeats = useSelector(cartData);
  const { cartInpersonData, cartvirtualData } = cartselectedSeats;
  const [cookies] = useCookies([
    `timer${decodedToken ? decodedToken?.id : 'GuestUser'}`,
  ]);

  ///Updating the cart onMounting face if the timer not expired in the cookies, If it's expired then deleting the cart.

  useOnceCall(() => {
    // console.log(decodedToken, 'decoded');
    const getRemainingTimeFromCookie = () => {
      let cookieValue =
        cookies?.[`timer${decodedToken ? decodedToken?.id : 'GuestUser'}`];
      return cookieValue ? parseInt(cookieValue) : 0;
    };
    const cookie = getRemainingTimeFromCookie();

    // console.log(cookie, 'cookie');
    if (cookie && cookie !== 0) {
      if (
        Object.entries(cartvirtualData)?.length > 0 ||
        Object.entries(cartInpersonData)?.length > 0
      ) {
      } else {
        dispatch(
          AddCartInpersonData(
            getDatasUserid(
              'cartInpersonData',
              decodedToken ? decodedToken?.id : 'GuestUser',
            ),
          ),
        );
        dispatch(
          AddCartvirtualData(
            getDatasUserid(
              'cartvirtualData',
              decodedToken ? decodedToken?.id : 'GuestUser',
            ),
          ),
        );
        dispatch(
          AddcartDataType(
            getSubDatasUserid(
              'eventType',
              decodedToken ? decodedToken?.id : 'GuestUser',
            ),
          ),
        );
        dispatch(
          AddEventDetails(
            getSubDatasUserid(
              'selectedEvent',
              decodedToken ? decodedToken?.id : 'GuestUser',
            ),
          ),
        );
        dispatch(
          AddSlug(
            getSubDatasUserid(
              'slug',
              decodedToken ? decodedToken?.id : 'GuestUser',
            ),
          ),
        );
      }
    } else {
      // console.log('cleared');
      ClearCartData();
    }
  }, [decodedToken]);
};
