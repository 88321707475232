const MATIC = {
    name: 'Matic',
    symbol: 'MATIC',
    decimals: 18,
  };

const ETH = {
  name: "Ethereum",
  symbol: "ETH",
  decimals: 8
}
  
  function isExtendedChainInformation(chainInformation) {
    return !!chainInformation.nativeCurrency;
  }
  
  export function getAddChainParameters(chainId) {
    const chainInformation = CHAINS[chainId];
    if (isExtendedChainInformation(chainInformation)) {
      return {
        chainId: chainInformation.chainId,
        chainName: chainInformation.name,
        nativeCurrency: chainInformation.nativeCurrency,
        rpcUrls: chainInformation.urls,
        blockExplorerUrls: chainInformation.blockExplorerUrls,
      };
    } else {
      return chainId;
    }
  }
  
  export const CHAINS = {
    2909: {
      chainId: 2909,
      url: 'https://rpc.artchivewallet.com/',
      name: "Artchive Eth Testnet",
      label: 'artc-testnet',
      blockExplorerUrls: ['https://explorer.artchivewallet.com/?network=ARTC%20ETH%20Testnet'],
      nativeCurrency: ETH,
    },
  
    1: {
      chainId: 1,
      urls: [
        process.env.REACT_APP_INFURA_KEY
          ? `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
          : '',
        process.env.REACT_APP_ALCHEMY_KEY
          ? `https://eth-mainnet.alchemyapi.io/v2/${process.env.REACT_APP_ALCHEMY_KEY}`
          : '',
        'https://cloudflare-eth.com',
      ].filter((url) => url !== ''),
      name: 'Ethereum Mainnet',
      nativeCurrency: ETH,
      label: 'mainnet',
    },
  
    //   5: {
    //     urls: [
    //       process.env.REACT_APP_INFURA_KEY
    //         ? `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
    //         : '',
    //     ].filter((url) => url !== ''),
    //     name: 'Görli',
    //   },
  
    // Polygon
    137: {
      chainId: 137,
      urls: [
        process.env.REACT_APP_INFURA_KEY
          ? `https://polygon-mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
          : '',
        'https://polygon-rpc.com',
      ].filter((url) => url !== ''),
      name: 'Polygon Mainnet',
      nativeCurrency: MATIC,
      blockExplorerUrls: ['https://polygonscan.com'],
      label: 'polygon-mainnet',
    },
    80001: {
      chainId: 80001,
      urls: [
        process.env.REACT_APP_INFURA_KEY
          ? `https://polygon-mumbai.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
          : '',
      ].filter((url) => url !== ''),
      name: 'Polygon Mumbai',
      nativeCurrency: MATIC,
      blockExplorerUrls: ['https://mumbai.polygonscan.com'],
      label: 'mumbai-testnet',
    },
  };
  
  export const URLS =
    ((accumulator, chainId) => {
      const validURLs = CHAINS[Number(chainId)].urls;
  
      if (validURLs.length) {
        accumulator[Number(chainId)] = validURLs;
      }
  
      return accumulator;
    },
      {});
  