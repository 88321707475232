import React, { useCallback, useState, useEffect } from 'react';
import {
  TabOutlineClass,
  afterBorder,
  btnSecondayClass,
  classNames,
  horizontalTabClass,
  horizontalTabSelectedClass,
  horizontalTabUnselectedClass,
} from '../../../common/DefaultClasses';
import { Tab } from '@headlessui/react';
import { SearchArtistListData, SearchEventListData } from './SearchListData';
import { CustomSearchIcon } from '../../../util/CustomIcons';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { DataLoader } from '../../../common/Loaders';
import { MdOutlineArrowForward, MdOutlineArrowRightAlt } from 'react-icons/md';

function HeaderTabSearch({
  eventDataSearchPage,
  artistsTeamsData,
  setOpenInputSearch,
  setInputValue,
  noEventCaption,
  noOrganiserCaption,
  loading,
  inputValue,
}) {
  let navigate = useNavigate();
  const [filteredEventData, setFilteredEventData] = useState([]);
  const [filteredArtistData, setFilteredArtistData] = useState([]);

  const onSearch = useCallback((e, item) => {
    setOpenInputSearch(false);

    let recentSearches =
      JSON.parse(localStorage.getItem('recent_searches')) || [];
    // const itemExists = recentSearches.some(
    //   (search) => search.eventData?._id === item?._id,
    // );
    const itemExists = item.eventData
      ? recentSearches.some(
          (search) => search.eventData?._id === item?.eventData?._id,
        )
      : recentSearches.some((search) => search?._id === item?._id);

    if (!itemExists) {
      recentSearches = [item, ...recentSearches];
      localStorage.setItem('recent_searches', JSON.stringify(recentSearches));
    }
    if (item.eventData) {
      navigate(`/event-detail/${item?.eventData?.slug}`);
    } else {
      navigate(`/artist/${item?._id}`);
    }

    setInputValue('');
  }, []);

  const highlightLetters = useCallback(
    (text) => {
      const regex = new RegExp(`(${inputValue})`, 'gi');
      return text.split(regex).map((part, index) =>
        regex.test(part) ? (
          <span
            key={index}
            className="text-color-primaryColor1 dark:text-color-primaryColor2 font-semibold text-base"
          >
            {part}
          </span>
        ) : (
          part
        ),
      );
    },
    [inputValue],
  );

  useEffect(() => {
    const filteredNamesStartingWith = eventDataSearchPage.filter((obj) =>
      obj.eventData.name.toLowerCase().startsWith(inputValue.toLowerCase()),
    );

    // Filter names not starting with the entered letter
    const remainingNames = eventDataSearchPage.filter(
      (obj) =>
        !obj.eventData.name.toLowerCase().startsWith(inputValue.toLowerCase()),
    );

    // Concatenate the two filtered arrays
    setFilteredEventData(filteredNamesStartingWith.concat(remainingNames));
  }, [eventDataSearchPage]);

  useEffect(() => {
    const filteredNamesStartingWith = artistsTeamsData.filter((obj) =>
      obj.name.toLowerCase().startsWith(inputValue.toLowerCase()),
    );

    // Filter names not starting with the entered letter
    const remainingNames = artistsTeamsData.filter(
      (obj) => !obj.name.toLowerCase().startsWith(inputValue.toLowerCase()),
    );

    // Concatenate the two filtered arrays
    setFilteredArtistData(filteredNamesStartingWith.concat(remainingNames));
  }, [artistsTeamsData]);

  return (
    <>
      <div className="bg-whiteNew-0 dark:bg-transparent">
        <Tab.Group>
          <div className={`w-full relative ${afterBorder}`}>
            <Tab.List className="flex gap-3 space-x-1 overflow-x-auto overflow-hidden scrollCss scrollHide">
              <Tab
                className={({ selected }) =>
                  classNames(
                    horizontalTabClass,
                    'py-2',
                    selected
                      ? horizontalTabSelectedClass
                      : horizontalTabUnselectedClass,
                  )
                }
              >
                Events
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    horizontalTabClass,
                    'py-2',
                    selected
                      ? horizontalTabSelectedClass
                      : horizontalTabUnselectedClass,
                  )
                }
              >
                Artists/Teams
              </Tab>
            </Tab.List>
          </div>
          <div className="w-full">
            <Tab.Panels className="">
              <Tab.Panel
                className={classNames(
                  '',
                  TabOutlineClass,
                  // 'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                )}
              >
                <div className="flex flex-col justify-between gap-1.5">
                  {loading ? (
                    <DataLoader
                      message={
                        <>
                          <span className="text-sm">Loading Data...</span>
                        </>
                      }
                      darkTheme={true}
                      minHeight={'min-h-[100px]'}
                    />
                  ) : (
                    <div className="py-1.5 px-1.5 space-y-3 min-h-[100px] max-h-[356px] overflow-y-auto overflow-x-hidden scrollCss">
                      {noEventCaption ? (
                        <div className="container">
                          <Row className="justify-content-center text-center">
                            <Col xs={12}>
                              <h5 className="dark:text-whiteNew-0 text-zinc-800 m-0 my-5">
                                There are no events found.
                              </h5>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        filteredEventData
                          .slice(0, 10)
                          .map((item) => (
                            <SearchEventListData
                              key={item._id}
                              item={item}
                              onSubmit={onSearch}
                              highlightLetters={highlightLetters}
                            />
                          ))
                      )}
                    </div>
                  )}
                  <div className="p-2.5 pt-0">
                    <button
                      className={`${btnSecondayClass} w-full`}
                      onClick={() => {
                        navigate('/events');
                        setOpenInputSearch(false);
                        setInputValue('');
                      }}
                    >
                      {/* <CustomSearchIcon /> */}
                      See All Events
                      <MdOutlineArrowForward />
                    </button>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel
                className={classNames(
                  '',
                  TabOutlineClass,
                  // 'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                )}
              >
                <div className="flex flex-col justify-between gap-1.5">
                  {loading ? (
                    <DataLoader
                      message={
                        <>
                          <span className="text-sm">Loading Data...</span>
                        </>
                      }
                      darkTheme={true}
                      minHeight={'min-h-[100px]'}
                    />
                  ) : (
                    <div className="py-1.5 px-1.5 space-y-3 min-h-[100px] max-h-[356px] overflow-y-auto overflow-x-hidden scrollCss">
                      {noOrganiserCaption ? (
                        <div className="container">
                          <Row className="justify-content-center text-center">
                            <Col xs={12}>
                              <h5 className="dark:text-whiteNew-0 text-zinc-800 m-0 my-5">
                                There are no artists/teams found.
                              </h5>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        filteredArtistData
                          .slice(0, 10)
                          .map((item) => (
                            <SearchArtistListData
                              key={item._id}
                              item={item}
                              onSubmit={onSearch}
                              highlightLetters={highlightLetters}
                            />
                          ))
                      )}

                      {/* <SearchArtistListData />
                    <SearchArtistListData />
                    <SearchArtistListData /> */}
                    </div>
                  )}
                  <div className="p-2.5 pt-0">
                    <button
                      className={`${btnSecondayClass} w-full`}
                      onClick={() => {
                        navigate('/artists');
                        setOpenInputSearch(false);
                        setInputValue('');
                      }}
                    >
                      {/* <CustomSearchIcon /> */}
                      See All Artists/Teams
                      <MdOutlineArrowForward />
                    </button>
                  </div>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </>
  );
}

export default React.memo(HeaderTabSearch);
