import React from 'react';
import Image from './Image';
import Video from './Video';
import { checkFileType } from '../../utils/util';

export default function EventImageVideo({
  data,
  alt,
  loading,
  // className,
  width,
  height,
}) {
  return checkFileType(data) === 'video' ? (
    <Video
      className="w-full h-full object-cover"
      src={data?.url}
      removeControls={true}
    />
  ) : (
    <Image
      src={data?.url || data}
      alt={alt}
      className={'w-full h-full object-cover'}
      loading={loading}
      width={width}
      height={height}
      srcset={true}
    />
  );
}
