import React, { useMemo, useState } from 'react';
import Button from '../../../common/Button';
import { LocationIcon } from '../../../util/CustomIcons';
import { MdOutlineArrowRight } from 'react-icons/md';
import DrawerCommon from '../../../common/DrawerCommon';
import SelectCountryDrawerCompo from './SelectCountryDrawerCompo';
import { getIsVerticalLayout } from '../../../../utils/util';
import { useSelector } from 'react-redux';

export default function SelectCountryDrawer() {
  const isVerticalLayout = useMemo(() => getIsVerticalLayout(), []);
  const currentLocation = useSelector((state) => state?.userCurrentLocation);
  console.log('currentLocation', currentLocation);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={showDrawer}
        type="button"
        className="p-1.5 text-zinc-900 dark:text-whiteNew-0 bg-lightTheme-70 dark:bg-brown-800 backdrop-blur-lg hover:bg-opacity-90 rounded-md flex gap-1 items-center justify-center w-full max-w-[100px] lg:max-w-[100%] lg:min-w-[100px] leading-tight"
      >
        <LocationIcon className={'text-base'} />
        {currentLocation?.currentState !== ''
          ? currentLocation?.currentState
          : 'USA'}{' '}
        <MdOutlineArrowRight className={'text-xl'} />
      </Button>
      <DrawerCommon
        title={
          <span className="font-medium ml-2 uppercase text-zinc-800 dark:text-whiteNew-0">
            Select State
          </span>
        }
        placement={isVerticalLayout ? 'bottom' : 'right'}
        onClose={onClose}
        height="95%"
        width="480px"
        open={open}
        className={
          'select-city-drawer backdrop-blur-3xl border-1 border-neutral-800 [&_.ant-drawer-header]:bg-gray-100 dark:[&_.ant-drawer-header]:bg-zinc-950'
        }
        maskStyle={{ backdropFilter: 'blur(2px)' }}
      >
        <SelectCountryDrawerCompo onClose={onClose} open={open} />
      </DrawerCommon>
    </>
  );
}
