import React from 'react';
import { Input, Select, Form } from 'antd';
import { formClass } from '../common/DefaultClasses';
import NoDataFound from '../common/NoDataFound';
import { RiListCheck2 } from 'react-icons/ri';
import { BsChevronDown } from 'react-icons/bs';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useCheckoutBilling } from '../../pages/checkout/hooks/useCheckout';

function CheckoutBillingInfo({ form }) {
  const { countries, phones, setToLocalstorage } = useCheckoutBilling();

  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex w-full">
        <div className="flex flex-col">
          <h2 className="text-color-secondary text-lg md:text-2xl font-medium">
            Billing Information
          </h2>
        </div>
      </div>
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Please select country.',
          },
        ]}
        className="m-0"
        name="country"
      >
        <Select
          placeholder={
            <>
              <span className="text-[#818181]">Select Country</span>
            </>
          }
          // popupClassName={`${antCustomSelect} antcstm`}
          className={`rounded-lg`}
          options={countries}
          showSearch
          autoClearSearchValue
          suffixIcon={
            <>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                <BsChevronDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </>
          }
          size="middle"
          notFoundContent={
            <div className="w-full">
              <NoDataFound
                title={
                  <span className="text-sm text-whiteNew-0">No Data Found</span>
                }
                minHeight={'min-h-[150px]'}
                icon={<RiListCheck2 />}
              />
            </div>
          }
        />
      </Form.Item>

      <div className="flex mt-3 gap-3">
        <Form.Item
          rules={[
            {
              required: true,
              message: 'First name is required.',
            },
          ]}
          className="m-0 basis-1/2"
          name="first_name"
        >
          <Input className={`${formClass}`} placeholder="First Name" />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'Last name is required.',
            },
          ]}
          className="m-0 basis-1/2"
          name="last_name"
        >
          <Input className={`${formClass}`} placeholder="Last Name" />
        </Form.Item>
      </div>
      <div className="flex mt-3 gap-3">
        <Form.Item
          rules={[
            {
              max: 50,
              message: 'Max. 50 character allowed.',
            },
          ]}
          className="m-0 w-full"
          name="building"
        >
          <Input
            className={`${formClass}`}
            placeholder="Apartment, suite, etc. (optional)"
          />
        </Form.Item>
      </div>
      <div className="flex mt-3 gap-3">
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Street address is required.',
            },
            {
              max: 100,
              message: 'Max. 100 characters allowed.',
            },
          ]}
          className="m-0 w-full"
          name="street_address"
        >
          <Input className={`${formClass} w-full`} placeholder="Address" />
        </Form.Item>
      </div>

      <div className="flex flex-row gap-3 mt-3">
        <Form.Item
          rules={[
            {
              required: true,
              message: 'City address is required.',
            },
            {
              max: 25,
              message: 'Max. 25 characters allowed.',
            },
          ]}
          className="m-0 basis-1/3"
          name="town"
        >
          <Input className={`${formClass}`} placeholder="City" />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'State address is required.',
            },
            {
              max: 25,
              message: 'Max. 25 characters allowed.',
            },
          ]}
          className="m-0 basis-1/2"
          name="state"
        >
          <Input className={`${formClass}`} placeholder="State" />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'Zip Code is required.',
            },
            {
              max: 10,
              message: 'Max. 10 characters allowed.',
            },
          ]}
          className="m-0 basis-1/2"
          name="postcode"
        >
          <Input
            type="number"
            className={`${formClass}`}
            placeholder="Pin Code"
          />
        </Form.Item>
      </div>

      <div className="flex w-full justify-between mt-3">
        <div className="w-1/3 mr-3 pr-1">
          <Form.Item
            className="flex m-0 flex-col"
            name="phone_code"
            rules={[
              {
                required: true,
                message: 'Please select country code.',
              },
            ]}
            dependencies={['phone_no']}
          >
            <Select
              placeholder={
                <span className="text-[#818181]">Select phone code</span>
              }
              // popupClassName={`${antCustomSelect} antcstm`}
              // className="w-full cstm-drpdwn h-10 dark:border-zinc-700 border-zinc-300 border-1 text-sm rounded-md cursor-pointer"

              className={`rounded-lg`}
              options={phones}
              showSearch
              autoClearSearchValue
              suffixIcon={
                <>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                    <BsChevronDown
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </>
              }
              size="middle"
              notFoundContent={
                <div className="w-full">
                  <NoDataFound
                    title={
                      <span className="text-sm text-whiteNew-0">
                        No Data Found
                      </span>
                    }
                    minHeight={'min-h-[150px]'}
                    icon={<RiListCheck2 />}
                  />
                </div>
              }
            />
          </Form.Item>
        </div>

        <div className="flex w-full">
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Phone number is required.',
              },
              {
                max: 15,
                message: 'Max. 15 characters allowed.',
              },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (
                    !value ||
                    isValidPhoneNumber(
                      value,
                      String(getFieldValue('phone_code')).split('+')[0],
                    )
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Invalid phone number'));
                },
              }),
            ]}
            dependencies={['phone_code']}
            className="m-0 w-full"
            name="phone_no"
          >
            <Input className={`${formClass} w-full`} placeholder="Phone" />
          </Form.Item>
        </div>
        <Form.Item shouldUpdate>
          {() => {
            <>{setToLocalstorage(form.getFieldsValue())}</>;
          }}
        </Form.Item>
      </div>
      {/* <div>
        <div className="mt-3">
          <Form.Item valuePropName="checked" name="is_default" className="m-0">
            <Checkbox className="text-[#818181] text-xs md:text-base">
              Save it as your default address
            </Checkbox>
          </Form.Item>
        </div>
      </div> */}
    </div>
  );
}

export default CheckoutBillingInfo;
