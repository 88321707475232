import { useCallback, useState } from 'react';

export function useLocalStorageState(key, defaultState) {
  const localStorage = useLocalStorage();
  const [state, setState] = useState(() => {
    // NOTE: Not sure if this is ok
    const storedState = localStorage.getItem(key);
    if (storedState) {
      return JSON.parse(storedState);
    }
    return defaultState;
  });

  const setLocalStorageState = useCallback(
    (newState) => {
      const changed = state !== newState;
      if (!changed) {
        return;
      }
      setState(newState);
      if (newState === null) {
        localStorage.removeItem(key);
      } else {
        try {
          localStorage.setItem(key, JSON.stringify(newState));
        } catch {
          // ignore
        }
      }
    },
    [state, key],
  );

  return [state, setLocalStorageState];
}

const useLocalStorage = () => {
  const isBrowser = (() => typeof window !== 'undefined')();

  const getItem = (key) => {
    return isBrowser ? window.localStorage[key] : '';
  };

  const setItem = (key, value) => {
    if (isBrowser) {
      window.localStorage.setItem(key, value);
      return true;
    }

    return false;
  };

  const removeItem = (key) => {
    window.localStorage.removeItem(key);
  };

  return {
    getItem,
    setItem,
    removeItem,
  };
};
