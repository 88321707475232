import useSWR, { useSWRConfig } from 'swr';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocation } from '../../../../features/home_page/homepage.slice';
import { getUserLocation } from '../../../../helpers/api/event/event';

export const useUserLocationHooks = (onClose) => {
  let dispatch = useDispatch();
  let currentLocation = useSelector((state) => state?.userCurrentLocation);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {

          if (result.state === 'granted') {
            // currentLocation?.currentState === ''
            getUserCurrentAddress();
            //If granted then you can directly call your function here
          } else if (result.state === 'prompt') {

            // setOpen(true);
            getUserCurrentAddress();
            //If prompt then the user will be asked to give permission
          } else if (result.state === 'denied') {

            // alert('asdasdasdasdasdasdasdasdx');
            // setOpen(true);
            getUserCurrentAddress();
            //If denied then you have to show instructions to enable location
          }
        });
    } else {

    }
  };

  const getUserCurrentAddress = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;



        getUserLocation(latitude, longitude)
          .then((res) => {

            localStorage.setItem(
              'CurrentLocation',
              JSON.stringify(res?.results?.[0]?.components?.state),
            );
            dispatch(updateLocation(res?.results?.[0]?.components?.state));
            onClose();
          })
          .catch((err) => console.error(err, 'err'));
      },
      (error) => {
        console.error('Error getting user location:', error);
      },
      { enableHighAccuracy: true },
    );
  };

  return {
    getCurrentLocation,
  };
};
