import React from 'react';
import TopDashboardTabs from './TopDashboardTabs';
import { TopHeaderBg } from '../util/CustomIcons';
import top_header_bg from '../../assets/images/background/top_header_bg.svg';

export default function DashboardTopInfo() {
  return (
    <React.Fragment>
      <div className="relative">
        <div className="">
          <TopDashboardTabs />
        </div>
      </div>
    </React.Fragment>
  );
}
