import { useEffect } from 'react';

const DisableScrollRestoration = () => {
  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  return null; // This component doesn't render anything
};

export default DisableScrollRestoration;
