import React from 'react';
import SeatCartItem from './SeatCartItem';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useDecodedToken, {
  useApiHeader,
} from '../../../../hooks/useDecodedToken';
import {
  useEventAllDetails,
  getBookingStatus,
  getSeatConfirmation,
} from '../../../../hooks/event/seat_mapping/useSeatMapping';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import { useCallback } from 'react';
import {
  primaryBtnClassGradient,
  primaryBtnClassGradientSec,
} from '../../DefaultClasses';
import { CustomLockIcon } from '../../../util/CustomIcons';
import { getSelectedTicketPrice } from '../../../../utils/util';
import { DeleteAllSeats } from '../../../../features/seatmap/seatmap.slice';
import { MdArrowRight, MdDelete } from 'react-icons/md';
import { updateCheckoutEvent } from '../../../../features/checkout/checkout.slice';
import { cartData } from '../../../../features/cart/cart.slice';
import { useCartCheckOut } from '../check_out/useCartCheckOut';
import { useMutation } from '@tanstack/react-query';
import { SeatBookStatus } from '../../notification_modal/AlertModal';
import { CheckoutConfirmation } from '../../../landing_page_v2/event_booking_detail/CheckoutConfirmation';
import PoliciesModel from '../../notification_modal/PoliciesModel';
import { usePoliciesModal } from '../../../../pages/landing/v2/hooks/usePoliciesModal';

export default function SeatBookingRightSide({
  className,
  eventAllDetails,
  setOpenTicketAlertModal,
}) {
  // from redux we are getting all the selected seats by the user.
  let selectedSeats = useSelector((state) => state.selectedSeats);
  const [, setCommonCat] = React.useState(false);
  const [ticketDetails, setTicketDetails] = React.useState(true);
  const [modifiedAlert, setModifiedAlert] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const dispatch = useDispatch();
  const {
    displayPoliciesRefund = false,
    setDisplayPoliciesRefund = () => {},
    getTicketCancelDisableTime,
  } = usePoliciesModal(eventAllDetails);

  const decodedToken = useDecodedToken();
  const { json_header } = useApiHeader();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  //getting the event details of that slug.
  const { data: _data } = useEventAllDetails(
    searchParams.get('slug1'),
    json_header,
  );

  const cartselectedSeats = useSelector(cartData);
  const {
    cartInpersonData,
    cartvirtualData,
    // slug: previousSlug,
    // selectedEvent: selectedCartEvent,
  } = cartselectedSeats;

  // Update the cart
  const { addingDatasToCart } = useCartCheckOut();
  // console.log('_data: ', _data);

  /// saving all the required ticket details in the localstorage with the key name as PurchasedTickets then redirecting to checkout page.

  const handleSaveTicket = useCallback(() => {
    if (_data?.data?.ticket?.length) {
      const getPurchasDdetails = () => {
        let PurchasDetails = [];
        selectedSeats?.data?.map((data) => {
          PurchasDetails.push({
            category: data?.category_id,
            quantity: 1,
            price: parseInt(data?.price),
            name: data?.category,
            section: data?.section,
            seat_number: data?.seat_number,
            seat_id: data?.seat_id,
            row: data?.row,
            resale_price: Number(
              parseFloat(data?.resale_price?.toString()?.replace(/,/g, '')),
            ),
            reseller_id: data?.reseller_id,
            //flag to check ticket on resale
            is_resale:
              data?.resale_price === null || data?.resale_price === undefined
                ? false
                : true,
            ticket_id: checkContainCommon()
              ? checkContainCommon(true)
              : getTicketId(data?.category_id),
          });
        });
        return PurchasDetails;
      };

      const checkContainCommon = (getID) => {
        let result = false;
        let id = '';

        _data?.data?.ticket?.map((item) => {
          if (item.category_id === 'common') {
            if (item.status && item.ticket_status) {
              result = true;
              id = item._id;
            }
          }
        });

        return getID ? id : result;
      };

      const getTicketId = (id) => {
        let catID = '';

        _data?.data?.ticket?.map((item) => {
          if (item.category_id === id) {
            if (item.category_id === 'common') {
              setCommonCat(true);
              catID = item._id;
            } else {
              catID = item._id;
            }
          }
        });
        return catID;
      };

      let payload = {
        start_date_time: _data?.data?.start_date_time,
        event_id: _data?.data?._id,
        event_name: _data?.data?.name,
        eventType: 'Physical',
        user_id: _data?.data?.user_id,
        login_user_id: decodedToken?.id,
        dateTime: moment(),
        policies: _data?.data?.ticket_policy || _data?.data['ticket-policy'], //will edit once api live
        event_img: _data?.data?.profile_image?.url,
        venue_location: _data?.data?.venue?.length
          ? _data?.data?.venue[0]?.location
          : 'N/A',
        venue_name: _data?.data?.venue?.length
          ? _data?.data?.venue[0]?.name
          : 'N/A',
        type: getPurchasDdetails(),
      };
      if (checkContainCommon()) {
        payload = {
          ...payload,
          ticket_id: checkContainCommon() ? checkContainCommon(true) : '',
        };
      }
      if (_data?.data?.tourData) {
        payload = {
          ...payload,
          tourData: _data?.data?.tourData,
        };
      }
      localStorage.setItem('PurchasedTickets', JSON.stringify(payload));
      localStorage.removeItem('notLoggedUser');
    }
  }, [_data?.data, decodedToken?.id, selectedSeats?.data]);

  // function insertNote() {
  //   let body = {
  //     seat_data: selectedSeats?.data,
  //     user_id: selectedSeats?.data[0]?.user_id,
  //     event_id: selectedSeats?.data[0]?.event_id,
  //     price_seatmap_id: selectedSeats?.data[0]?.price_seatmap_id,
  //   };
  //   return postSeatMappingDetails(body, json_header);
  // }
  // const mutation = useMutation(insertNote, {
  //   onSuccess: async (res) => {
  //
  //     // navigate(
  //     //   `/checkout-summary?slug=${searchParams.get('slug1')}&user_id=${
  //     //     selectedSeats?.data[0]?.user_id
  //     //   }&seatmap_id=${selectedSeats?.data[0]?.price_seatmap_id}&event_id=${
  //     //     selectedSeats?.data[0]?.event_id
  //     //   }`,
  //     // );
  //   },
  //   onError: (err) => {

  //     message.error(err?.response?.data?.message);
  //   },
  // });
  function seatBookStatus(id) {
    const eventID = eventAllDetails?.data?.venue_id;
    let body = {
      seat_id: id,
      venue_id: eventID,
    };
    return getBookingStatus(body, json_header);
  }

  function seatConfirmation() {
    const seatIDs = selectedSeats?.data.map((obj) => obj.seat_id);
    const eventID = eventAllDetails?.data?.venue_id;
    let body = {
      seat_id: seatIDs,
      venue_id: eventID,
      status: 1,
      type: 'insert',
    };
    return getSeatConfirmation(body, json_header);
  }

  const seatConfirmMutation = useMutation(seatConfirmation, {
    onSuccess: (data) => {
      console.log('data', data);
    },
    onError: (data) => {
      console.log(data);
      // Logic to handle mutation error
      //  alert(data);
    },
  });

  const checkOutMutation = useMutation(seatBookStatus, {
    onSuccess: (data) => {
      if (data.status) {
        // onSubmit();
        seatConfirmMutation.mutate();
        addingDatasToCart(
          'Physical',
          searchParams.get('slug1'),
          eventAllDetails?.data,
        );
        handleSaveTicket();
        //! with price seatmap id
        // navigate(
        //   `/checkout?slug=${searchParams.get('slug1')}&user_id=${
        //     selectedSeats?.data[0]?.user_id
        //   }&seatmap_id=${selectedSeats?.data[0]?.price_seatmap_id}&event_id=${
        //     selectedSeats?.data[0]?.event_id
        //   }`,
        // );
        //! without price seatmap id
        if (selectedSeats && selectedSeats?.data?.length > 0) {
          localStorage.setItem(
            'selectedSeatData',
            JSON.stringify(selectedSeats?.data),
          );
          localStorage.setItem(
            'checkout_event',
            JSON.stringify(eventAllDetails?.data),
          );
          dispatch(updateCheckoutEvent(eventAllDetails?.data));
        }

        navigate(
          `/checkout?slug=${searchParams.get('slug1')}${
            decodedToken?.id ? `&user_id=${decodedToken?.id}` : ''
          }&event_id=${selectedSeats?.data[0]?.event_id}`,
        );
        // localStorage.removeItem('isPageReloaded');
      } else {
        setModifiedAlert(true);
        // alert('This is an alert message!');
      }
      // onSubmit();
      // Logic to handle mutation success
      // console.log('data', data);
    },
    onError: (data) => {
      // Logic to handle mutation error
      // alert(data);
    },
  });

  const statusCheck = useCallback(() => {
    const seatIDs = selectedSeats?.data.map((obj) => obj.seat_id);
    checkOutMutation.mutate(seatIDs);
  }, [checkOutMutation, selectedSeats?.data]);

  const onSubmit = useCallback(() => {
    if (Object.entries(cartvirtualData)?.length > 0) {
      window.scrollTo(0, 0);
      setOpenTicketAlertModal(true);
    } else {
      if (Object.entries(cartInpersonData)?.length > 0) {
        window.scrollTo(0, 0);
        setOpenTicketAlertModal(true);
      } else {
        statusCheck();
        // addingDatasToCart(
        //   'Physical',
        //   searchParams.get('slug1'),
        //   eventAllDetails?.data,
        // );
        // handleSaveTicket();
        // //! with price seatmap id
        // // navigate(
        // //   `/checkout?slug=${searchParams.get('slug1')}&user_id=${
        // //     selectedSeats?.data[0]?.user_id
        // //   }&seatmap_id=${selectedSeats?.data[0]?.price_seatmap_id}&event_id=${
        // //     selectedSeats?.data[0]?.event_id
        // //   }`,
        // // );
        // //! without price seatmap id
        // if (selectedSeats && selectedSeats?.data?.length > 0) {
        //   localStorage.setItem(
        //     'selectedSeatData',
        //     JSON.stringify(selectedSeats?.data),
        //   );
        //   localStorage.setItem(
        //     'checkout_event',
        //     JSON.stringify(eventAllDetails?.data),
        //   );
        //   dispatch(updateCheckoutEvent(eventAllDetails?.data));
        // }
        // navigate(
        //   `/checkout?slug=${searchParams.get('slug1')}&user_id=${
        //     selectedSeats?.data?.[0]?.user_id
        //   }&event_id=${selectedSeats?.data[0]?.event_id}`,
        // );
      }
    }
  }, [statusCheck, cartInpersonData, cartvirtualData, setOpenTicketAlertModal]);

  const handleRemoveAllSeats = () => {
    //added by surya
    window.removeSelections(selectedSeats?.data);
    //-------------------
    dispatch(DeleteAllSeats([]));
    //commented by surya
    // window.seatSelections([]);

    //------------------------------------

    // selectedSeats?.data?.forEach((seat) => {
    // dispatch(DeleteSeats([]));
    // });
  };

  const refreshContent = () => {
    window.location.reload();
    setModifiedAlert(false);
  };

  return (
    <React.Fragment>
      <div
        className={`h-full ${
          className ? className : 'dark:bg-whiteNew-0'
        } dark:bg-color-base-2 bg-color-base-1 border-1 border-level-4 dark:border-level-2`}
      >
        <div className={`flex flex-col justify-between h-full`}>
          <div className="flex flex-col h-full">
            <div className="p-3.5 relative after:absolute after:content-[''] after:bottom-0 after:left-0 after:h-1 after:w-full after:flex after:z-10 dark:after:bg-zinc-700 after:bg-zinc-200 flex justify-between">
              <h4 className="dark:text-whiteNew-0 text-zinc-800 text-md 2xl:text-lg">
                Ticket Summary
              </h4>

              <div className="flex gap-2 items-center">
                {selectedSeats?.data?.length > 0 && (
                  <button
                    className="rounded-md text-xs flex items-center font-semibold text-rose-500 hover:text-rose-600"
                    onClick={handleRemoveAllSeats}
                  >
                    <MdDelete />
                    Clear All
                  </button>
                )}
                <div className="md:hidden">
                  <button
                    onClick={() => {
                      setTicketDetails(!ticketDetails);
                    }}
                    className="text-whiteNew-0 p-1.5 bg-brown-800 rounded-sm"
                  >
                    <MdArrowRight
                      className={`${
                        ticketDetails ? 'rotate-90' : '-rotate-90'
                      }`}
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className={`w-full shrink-0 tourActive h-full pl-1.5`}>
              <div
                className={`w-full accordionHeight ${
                  ticketDetails
                    ? 'accordion-content fullMaxHeight opacity-100'
                    : ''
                } h-full`}
              >
                {selectedSeats?.data?.length > 0 && (
                  <div className="overflow-y-auto scrollCss px-1.5 cstm_scroll">
                    <div className="space-y-3 py-2">
                      {selectedSeats?.data?.length > 0
                        ? selectedSeats?.data?.map((item, index) => (
                            <SeatCartItem
                              key={index}
                              data={item}
                              className={className}
                            />
                          ))
                        : ''}
                    </div>
                  </div>
                )}
                {selectedSeats?.data?.length === 0 && (
                  <div className="screenHeight min-h-[200px] 2xl:min-h-[520px] flex items-center overflow-y-auto scrollCss px-1.5">
                    <div
                      className={`space-y-3 py-2 h-full max-h-screen text-center text-xs 2xl:text-sm dark:text-whiteNew-0 text-zinc-800`}
                    >
                      You have not selected any seats. Please double click on
                      any section to view the seating arrangement
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="shrink-0 p-2.5">
            <div
              className="w-full dark:bg-zinc-700 bg-zinc-200"
              style={{ height: '1px' }}
            ></div>
            <div className="h-full w-full flex items-end">
              <div className="w-full space-y-8 flex flex-col justify-between h-full pt-3 px-2 pb-2">
                <div className="px-2">
                  <div
                    className={`flex justify-between items-center ${
                      className && 'dark:text-whiteNew-0 text-zinc-900'
                    }`}
                  >
                    <span className="2xl:text-base font-normal mb-0">
                      Sub Total
                    </span>
                    <span className="2xl:text-xl font-normal">
                      <CurrencyFormat
                        disabled={true}
                        displayType="text"
                        thousandSeparator
                        prefix="$"
                        value={
                          getSelectedTicketPrice(selectedSeats)?.toFixed(2) || 0
                        }
                        decimalScale={2}
                        fixedDecimalScale
                        className={`font-normal`}
                      />
                      {/* $ {getPrice()?.toFixed(2)} */}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="flex pl-2 mb-1.5">
                    <CheckoutConfirmation
                      props={{ checked, setChecked, setDisplayPoliciesRefund }}
                    />
                  </div>
                  <button
                    className={`${primaryBtnClassGradientSec} text-white  focus:bg-landingPurple-70 focus-within:bg-landingPurple-70 focus-visible:bg-landingPurple-70 w-full  min-auto px-2 py-2.5 font-normal text-sm rounded-lg flex items-center justify-center relative`}
                    disabled={selectedSeats?.data?.length < 1 || !checked}
                    onClick={() => {
                      // statusCheck();
                      onSubmit();
                      localStorage.removeItem('refreshFlag');
                    }}
                  >
                    <span className="flex absolute top-1/2 left-3 -translate-y-1/2">
                      <CustomLockIcon />
                    </span>
                    <span className="flex items-center gap-1">
                      <span>Checkout - </span>
                      <span>
                        <CurrencyFormat
                          disabled={true}
                          displayType="text"
                          thousandSeparator
                          prefix="$ "
                          value={
                            getSelectedTicketPrice(selectedSeats)?.toFixed(2) ||
                            0
                          }
                          decimalScale={2}
                          fixedDecimalScale
                          className={`font-normal`}
                        />
                      </span>
                    </span>
                    {/* Proceed For Billing */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PoliciesModel
        isOpen={displayPoliciesRefund}
        setIsOpen={setDisplayPoliciesRefund}
        policies={eventAllDetails?.data?.ticket_policy}
        event={{
          start_time: eventAllDetails?.data?.start_date_time,
          end_time: eventAllDetails?.data?.end_date_time,
        }}
        darkTheme={true}
        event_type={eventAllDetails?.data?.event_type}
        getTicketCancelDisableTime={getTicketCancelDisableTime} //to get the ticket cancel time
      />
      <SeatBookStatus
        isOpen={modifiedAlert}
        setIsOpen={setModifiedAlert}
        refresh={refreshContent}
      />
    </React.Fragment>
  );
}
