import React from 'react';
import AllEventsPage from './AllEventsPage';

export default function index() {
  return (
    <>
      <AllEventsPage />
    </>
  );
}
