import React from 'react';
import {
  MdOutlineAlignHorizontalLeft,
  MdOutlineAlignVerticalBottom,
} from 'react-icons/md';

import {
  TicketTemplate,
  TicketTemplate2,
  TicketTemplateVertical,
  TicketTemplateVertical2,
} from '../../TicketTemplate';
import Tooltip from '../../../common/Tooltip';
import { DefaultTicketTeplateVerticalCard } from './DefaultTicketTeplateVerticalCard';
import DefaultTicketTeplateHorizontalCard from './DefaultTicketTeplateVerticalCard';
import { btnPrimaryClass } from '../../../common/DefaultClasses';

export default function DefaultTicketCard({
  data,
  type,
  result,
  banner,
  uploader,
  fields,
  from = null,
  update,
  ticketDetails,
  created,
  seatData = null,
  category = null,
  booking_id = null,
  event_id = null,
  method,
  hideToggle,
  setOpen,
  // setNftId,
  // setShowDetailView,
  changeLayout,
  setChangeLayout,
  Showedit,
  event_type,
  allData,
  hideNft,
  ShowCopy,
  tabsTour,
  setTabs,
  tooglePosition,
  nftDrawer = false,
  setOpenSubDrawer,
  setSelectedIndex,
  isAnyTicketBook = false,
  ticket_name = null,
}) {
  const handleUploadBG = (e) => {
    e.preventDefault();
    document.getElementById('upload-BG').click();
  };

  const uploadBG = (e) => {
    e.preventDefault();
    uploader(e, 'banner');
  };

  React.useEffect(() => {
    if (hideToggle && !hideNft) {
      setChangeLayout(
        ticketDetails?.data[0]?.ticket_layout === 'Landscape'
          ? 'horizontal'
          : 'portrait',
      );
    }
  }, [hideToggle]);

  return (
    <React.Fragment>
      <div className="">
        {(hideToggle === false || from !== 'create') && (
          <div
            className={`flex flex-wrap justify-end items-center gap-2 ${
              tooglePosition ? tooglePosition : 'mb-3'
            }`}
          >
            <div className="flex border-1 border-level-2 rounded-lg overflow-hidden">
              <Tooltip title={'Landscape Ticket'} placement={'top'}>
                <button
                  className={`p-2 py-1 text-xl duration-200 ${
                    changeLayout === 'horizontal'
                      ? `text-whiteNew-0 bg-color-primaryColor1 hover:bg-color-primaryColor1`
                      : 'hover:bg-component-gray-2 text-color-primary'
                  }`}
                  type="button"
                  onClick={() => {
                    setChangeLayout('horizontal');
                  }}
                >
                  <MdOutlineAlignHorizontalLeft />
                </button>
              </Tooltip>

              <Tooltip title={'Portrait Ticket'} placement={'top'}>
                <button
                  className={`p-2 py-1 text-xl duration-200 ${
                    changeLayout === 'portrait'
                      ? `text-whiteNew-0 bg-color-primaryColor1 hover:bg-color-primaryColor1`
                      : 'hover:bg-component-gray-2 text-color-primary'
                  }`}
                  type="button"
                  onClick={() => {
                    setChangeLayout('portrait');
                  }}
                >
                  <MdOutlineAlignVerticalBottom />
                </button>
              </Tooltip>
            </div>
          </div>
        )}

        {created === 'true' ||
        ticketDetails?.data[0]?.template_design === 'design 3' ? (
          <>
            <div
              className={
                (type !== 'preview' && method === 'defaultTemplate') ||
                (type === 'edit' && Showedit) ||
                (type === 'copy' && ShowCopy)
                  ? 'p-2 relative rounded-md'
                  : 'w-full relative'
              }
            >
              {(method === 'defaultTemplate' ||
                (type === 'edit' && Showedit) ||
                (type === 'copy' && ShowCopy)) && (
                <div className="absolute -top-2 z-10">
                  <input
                    hidden
                    id="upload-BG"
                    onChange={uploadBG}
                    accept="image/*"
                    type={'file'}
                  />
                  <Tooltip
                    title={`This sets the base layer for your ticket design.`}
                    placement={'right'}
                  >
                    <button
                      className={`${btnPrimaryClass} py-1`}
                      onClick={handleUploadBG}
                      disabled={isAnyTicketBook}
                    >
                      Upload Background
                    </button>
                  </Tooltip>
                </div>
              )}
              <div
                className={
                  type !== 'preview' &&
                  from !== 'published' &&
                  method === 'defaultTemplate'
                    ? 'pt-2 pt-xl-3'
                    : 'w-full  relative'
                }
              >
                {changeLayout === 'horizontal' ? (
                  <DefaultTicketTeplateHorizontalCard
                    data={data}
                    type={type}
                    result={result}
                    banner={banner}
                    fields={fields}
                    from={from}
                    update={update}
                    TicketDetails={ticketDetails?.data[0]}
                    seatData={seatData}
                    category={category}
                    booking_id={booking_id}
                    event_id={event_id}
                    method={method}
                    uploader={uploader}
                    setOpen={setOpen}
                    // setNftId={setNftId}
                    // setShowDetailView={setShowDetailView}
                    updateModal={update}
                    event_type={event_type}
                    eventDetails={data}
                    allData={allData}
                    hideNft={hideNft}
                    setTabs={setTabs}
                    nftDrawer={nftDrawer}
                    setOpenSubDrawer={setOpenSubDrawer}
                    setSelectedIndex={setSelectedIndex}
                    ticket_name={ticket_name}
                  />
                ) : (
                  <>
                    <DefaultTicketTeplateVerticalCard
                      data={data}
                      type={type}
                      result={result}
                      banner={banner}
                      fields={fields}
                      from={from}
                      update={update}
                      TicketDetails={ticketDetails?.data[0]}
                      seatData={seatData}
                      category={category}
                      booking_id={booking_id}
                      event_id={event_id}
                      method={method}
                      uploader={uploader}
                      setOpen={setOpen}
                      // setNftId={setNftId}
                      // setShowDetailView={setShowDetailView}
                      updateModal={update}
                      event_type={event_type}
                      allData={allData}
                      hideNft={hideNft}
                      setTabs={setTabs}
                      nftDrawer={nftDrawer}
                      setOpenSubDrawer={setOpenSubDrawer}
                      setSelectedIndex={setSelectedIndex}
                      ticket_name={ticket_name}
                    />
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="w-full mt-2">
              {ticketDetails?.data[0]?.template_design === 'design 1' ? (
                <>
                  {changeLayout === 'horizontal' ? (
                    <TicketTemplate
                      data={ticketDetails?.data[0]}
                      banner={banner}
                      from={from}
                      uploadBG={uploadBG}
                      handleUploadBG={handleUploadBG}
                      eventDetails={data}
                      seatData={seatData}
                      category={category}
                      booking_id={booking_id}
                      event_id={event_id}
                      method={method}
                      setOpen={setOpen}
                      // setNftId={setNftId}
                      // setShowDetailView={setShowDetailView}
                      update={update}
                      event_type={event_type}
                      allData={allData}
                      hideNft={hideNft}
                      setTabs={setTabs}
                      nftDrawer={nftDrawer}
                      setOpenSubDrawer={setOpenSubDrawer}
                      setSelectedIndex={setSelectedIndex}
                      ticket_name={ticket_name}
                    />
                  ) : (
                    <TicketTemplateVertical
                      data={ticketDetails?.data[0]}
                      banner={banner}
                      from={from}
                      uploadBG={uploadBG}
                      handleUploadBG={handleUploadBG}
                      eventDetails={data}
                      seatData={seatData}
                      category={category}
                      booking_id={booking_id}
                      event_id={event_id}
                      method={method}
                      setOpen={setOpen}
                      // setNftId={setNftId}
                      // setShowDetailView={setShowDetailView}
                      update={update}
                      event_type={event_type}
                      allData={allData}
                      hideNft={hideNft}
                      setTabs={setTabs}
                      nftDrawer={nftDrawer}
                      setOpenSubDrawer={setOpenSubDrawer}
                      setSelectedIndex={setSelectedIndex}
                      ticket_name={ticket_name}
                    />
                  )}
                </>
              ) : (
                ticketDetails?.data[0]?.template_design === 'design 2' && (
                  <>
                    {changeLayout === 'horizontal' ? (
                      <TicketTemplate2
                        data={ticketDetails?.data[0]}
                        banner={banner}
                        from={from}
                        uploadBG={uploadBG}
                        handleUploadBG={handleUploadBG}
                        eventDetails={data}
                        seatData={seatData}
                        category={category}
                        booking_id={booking_id}
                        event_id={event_id}
                        method={method}
                        setOpen={setOpen}
                        // setNftId={setNftId}
                        // setShowDetailView={setShowDetailView}
                        update={update}
                        event_type={event_type}
                        allData={allData}
                        hideNft={hideNft}
                        setTabs={setTabs}
                        nftDrawer={nftDrawer}
                        setOpenSubDrawer={setOpenSubDrawer}
                        setSelectedIndex={setSelectedIndex}
                        ticket_name={ticket_name}
                      />
                    ) : (
                      <TicketTemplateVertical2
                        data={ticketDetails?.data[0]}
                        banner={banner}
                        from={from}
                        uploadBG={uploadBG}
                        handleUploadBG={handleUploadBG}
                        eventDetails={data}
                        category={category}
                        seatData={seatData}
                        booking_id={booking_id}
                        event_id={event_id}
                        method={method}
                        setOpen={setOpen}
                        // setNftId={setNftId}
                        // setShowDetailView={setShowDetailView}
                        update={update}
                        event_type={event_type}
                        allData={allData}
                        hideNft={hideNft}
                        setTabs={setTabs}
                        nftDrawer={nftDrawer}
                        setOpenSubDrawer={setOpenSubDrawer}
                        setSelectedIndex={setSelectedIndex}
                        ticket_name={ticket_name}
                      />
                    )}
                  </>
                )
              )}
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
}
