import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openUserModal: false,
  openUserDrawer: false,
  business_user_details: {},
  refetchUserList: false,
  category: [],
  subCategory: [],
  bet_category: [],
  bet_type: [],
  bet_period: [],
  bet_selection: [],
  picks_in_bets: [],
  selectedSubUser: {},
  initialValues: {},
  wallet_balance: 0,
  nft_data: {},
  isMintingStatus: {
    id: '',
    status: false,
  },
};

const pageSize = {
  pagination_list: {},
};

const slice = createSlice({
  name: 'business_user',
  initialState,
  reducers: {
    setOpenAddUserModal: (state, { payload }) => {
      state.openUserModal = payload;
    },
    setOpenUserDrawer: (state, { payload }) => {
      state.openUserDrawer = payload;
    },
    updateBusinessUserDetails: (state, { payload }) => {
      state.business_user_details = payload;
    },
    refetchBusinessUserList: (state, { payload }) => {
      state.refetchUserList = payload;
    },
    updateCategoryData: (state, { payload }) => {
      switch (payload.type) {
        case 'category':
          state.category = payload.data;
          break;
        case 'sub_category':
          state.subCategory = payload.data;
          break;
        case 'bet_category':
          state.bet_category = payload.data;
          break;
        case 'bet_type':
          state.bet_type = payload.data;
          break;
        case 'bet_period':
          state.bet_period = payload.data;
          break;
        case 'bet_selection':
          state.bet_selection = payload.data;
          break;
        case 'picks_in_bet':
          state.picks_in_bets = payload.data;
          break;
        default:
          return state;
      }
    },
    setSelectedSubUser: (state, { payload }) => {
      state.selectedSubUser = payload;
    },
    updateInitialValues: (state, { payload }) => {
      state.initialValues = payload;
    },
    updateWalletBalance: (state, { payload }) => {
      state.wallet_balance = Number(payload ?? 0);
    },
    updateNftMetadata: (state, { payload }) => {
      state.nft_data = payload;
    },
    updateMintingStatus: (state, { payload }) => {
      state.isMintingStatus = payload;
    },
  },
});
const pageSizeSlice = createSlice({
  name: 'allPaginationSize',
  initialState: pageSize,
  reducers: {
    updatePaginationSize: (state, { payload }) => {
      state.pagination_list = payload;
    },
  },
});

const businessUserReducer = slice.reducer;
const pageSizeReducer = pageSizeSlice.reducer;

export const {
  setOpenAddUserModal,
  updateBusinessUserDetails,
  setOpenUserDrawer,
  refetchBusinessUserList,
  updateCategoryData,
  setSelectedSubUser,
  updateInitialValues,
  updateWalletBalance,
  updateNftMetadata,
  updateMintingStatus,
} = slice.actions;

export const { updatePaginationSize } = pageSizeSlice.actions;

export { businessUserReducer, pageSizeReducer };
