import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  dataCount: 0,
  event_id: '',
  selected_tickets: {},
  needRefetch: false,
};
const InpersonInitialState = {
  data: [],
  dataCount: 0,
  event_id: '',
  selected_tickets: {},
  needRefetch: false,
};

const slice = createSlice({
  name: 'VirtualEventAttendees',
  initialState,
  reducers: {
    updateVirtualAttendees: (state, { payload }) => {
      state.data = payload?.data;
      state.event_id = payload?.event_id;
      state.dataCount = payload?.dataCount;
    },
    updateselectedTickets: (state, { payload }) => {
      state.selected_tickets = payload;
    },
    needRefetchVirtualAttendee: (state, { payload }) => {
      state.needRefetch = payload;
    },
    // update_selected_artist: (state, { payload }) => {
    //   state.selected_artist = payload;
    // },
  },
});

const InPersonslice = createSlice({
  name: 'InPersonEventAttendees',
  initialState: InpersonInitialState,
  reducers: {
    updateInPersonAttendees: (state, { payload }) => {
      state.data = payload?.data;
      state.event_id = payload?.event_id;
      state.dataCount = payload?.dataCount;
    },
    updateselectedInPersonTickets: (state, { payload }) => {
      state.selected_tickets = payload;
    },
    needRefetchInPersonAttendee: (state, { payload }) => {
      state.needRefetch = payload;
    },
    // update_selected_artist: (state, { payload }) => {
    //   state.selected_artist = payload;
    // },
  },
});

let virtualAttendeesReducer = slice.reducer;
let inPersonAttendeesReducer = InPersonslice.reducer;

export const {
  updateVirtualAttendees,
  updateselectedTickets,
  needRefetchVirtualAttendee,
} = slice.actions;

export const {
  updateInPersonAttendees,
  updateselectedInPersonTickets,
  needRefetchInPersonAttendee,
} = InPersonslice.actions;

const getAllVirtualAttendees = (state) => state.allVirtualAttendees;
const getAllInPersonAttendees = (state) => state.allInPersonAttendees;

export {
  virtualAttendeesReducer,
  getAllVirtualAttendees,
  inPersonAttendeesReducer,
  getAllInPersonAttendees,
};
