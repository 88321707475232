import axios from 'axios';
import config from '../../../../api/Config';

export async function getEventAllDetail(slug, headers) {
  if (slug === '') {
    return [];
  } else {
    const res = await axios.get(
      config.baseUrl + config.endpoints.event_all_details + slug,
      headers,
    );
    return res.data;
  }
}

export async function getPriceSeatmapList(id, page, headers) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.price_seatmap_list
    }${id}?limit=100&page=${page}`,
    headers,
  );

  return res.data;
}
export async function getVenueDetailsById(id, headers) {
  const res = await axios.get(
    `${config.baseUrl + config.endpoints.get_venue_details_by_id}${id}`,
    headers,
  );
  return res.data;
}

export async function getSeatMappingDetails(
  user_id,
  event_id,
  seatmap_id,
  headers,
) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.getSeatMappingDetails
    }?user_id=${user_id}&event_id=${event_id}&price_seatmap_id=${seatmap_id}`,
    headers,
  );
  return res.data;
}

export async function deleteTicketSeatMap(payload, headers) {
  const res = await axios.delete(
    config.baseUrl + config.endpoints.deletSeatMapDetails + `/${payload}`,
    headers,
  );
  return res.data;
}
export async function postSeatMappingDetails(body, headers) {
  const res = await axios.post(
    `${config.baseUrl + config.endpoints.postSeatMappingDetails}`,
    body,
    headers,
  );
  return res.data;
}
