import React from 'react';
import { afterBorder } from '../common/DefaultClasses';

export default function HeaderFillTop({ title, extra, extraPills }) {
  return (
    <React.Fragment>
      <div className="relative p-3 bg-white">
        <div className={`${afterBorder}`}>
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2 py-1">
              <span className="text-lg font-medium">{title}</span>
              <div>{extraPills}</div>
            </div>
            {extra && <div>{extra}</div>}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
