import { Connector } from '@web3-react/types'
import { detectEthereumProvider } from './DetectEthereumProvider'

export class NoArtChiveError extends Error {
    constructor() {
        super('ArtChive not installed')
        this.name = NoArtChiveError.name
        Object.setPrototypeOf(this, NoArtChiveError.prototype)
    }
}

export class NoMetaMaskError extends Error {
    constructor() {
        super('MetaMask not installed')
        this.name = NoMetaMaskError.name
        Object.setPrototypeOf(this, NoMetaMaskError.prototype)
    }
}

function parseChainId(chainId) {
    return Number.parseInt(chainId, 16)
}

/**
 * @param options - Options to pass to `@MetaMask/detect-provider`
 * @param onError - Handler to report errors thrown from eventListeners.
 */
// export interface MetaMaskConstructorArgs {
//   actions: Actions
//   options?: Parameters<typeof detectEthereumProvider>[0]
//   onError?: (error: Error) => void
// }

export class EthAdapter extends Connector {
    /** {@inheritdoc Connector.provider} */
    //   public provider?: MetaMaskProvider

    //   private readonly options?: Parameters<typeof detectEthereumProvider>[0]
    //   private eagerConnection?: Promise<void>

    constructor({ actions, options, onError }) {
        super(actions, onError)
        this.options = options
    }

    async isomorphicInitialize(isArtchive, isMetaMask) {
        // if (this.eagerConnection) return
        const provider = isArtchive ? window.$artchive.ethereum : await detectEthereumProvider({ mustBeMetaMask: true })

        if (isArtchive) {

            if (!provider.isArtChive) throw new NoArtChiveError()
        }

        if (isMetaMask) {

            if (!provider) throw new NoMetaMaskError()
        }

        if (provider) {
            this.provider = provider

            // handle the case when e.g. MetaMask and coinbase wallet are both installed
            if (this.provider.providers?.length) {
                this.provider = this.provider.providers.find((p) => p.isMetaMask) ?? this.provider.providers[0]
            }

            this.provider.on('connect', ({ chainId }) => {
                this.actions.update({ chainId: parseChainId(chainId) })
            })

            this.provider.on('disconnect', (error) => {
                // 1013 indicates that MetaMask is attempting to reestablish the connection
                // https://github.com/MetaMask/providers/releases/tag/v8.0.0
                if (error.code === 1013) {
                    console.debug('MetaMask logged connection error 1013: "Try again later"')
                    return
                }
                this.actions.resetState()
                this.onError?.(error)
            })

            this.provider.on('chainChanged', (chainId) => {
                this.actions.update({ chainId: parseChainId(chainId) })
            })

            this.provider.on('accountsChanged', (accounts) => {
                if (accounts.length === 0) {
                    // handle this edge case by disconnecting
                    this.actions.resetState()
                } else {
                    this.actions.update({ accounts })
                }
            })
        }
    }

    /** {@inheritdoc Connector.connectEagerly} */
    // async connectEagerly() {
    //     const cancelActivation = this.actions.startActivation()

    //     try {
    //         await this.isomorphicInitialize()
    //         if (!this.provider) return cancelActivation()

    //         // Wallets may resolve eth_chainId and hang on eth_accounts pending user interaction, which may include changing
    //         // chains; they should be requested serially, with accounts first, so that the chainId can settle.
    //         const accounts = (await this.provider.request({ method: 'eth_accounts' }))
    //         if (!accounts.length) throw new Error('No accounts returned')
    //         const chainId = (await this.provider.request({ method: 'eth_chainId' }))
    //         this.actions.update({ chainId: parseChainId(chainId), accounts })
    //     } catch (error) {
    //         console.debug('Could not connect eagerly', error)
    //         // we should be able to use `cancelActivation` here, but on mobile, MetaMask emits a 'connect'
    //         // event, meaning that chainId is updated, and cancelActivation doesn't work because an intermediary
    //         // update has occurred, so we reset state instead
    //         this.actions.resetState()
    //     }
    // }

    /**
     * Initiates a connection.
     *
     * @param desiredChainIdOrChainParameters - If defined, indicates the desired chain to connect to. If the user is
     * already connected to this chain, no additional steps will be taken. Otherwise, the user will be prompted to switch
     * to the chain, if one of two conditions is met: either they already have it added in their extension, or the
     * argument is of type AddEthereumChainParameter, in which case the user will be prompted to add the chain with the
     * specified parameters first, before being prompted to switch.
     */
    async activate(desiredChainIdOrChainParameters, isArtchive, isMetaMask) {
        let cancelActivation
        if (!this.provider?.isConnected?.()) cancelActivation = this.actions.startActivation()
        return this.isomorphicInitialize(isArtchive, isMetaMask)
            .then(async () => {
                // Wallets may resolve eth_chainId and hang on eth_accounts pending user interaction, which may include changing
                // chains; they should be requested serially, with accounts first, so that the chainId can settle.
                const accounts = (await this.provider.request({ method: 'eth_requestAccounts' }))
                const chainId = (await this.provider.request({ method: 'eth_chainId' }))
                const receivedChainId = parseChainId(chainId)
                const desiredChainId =
                    typeof desiredChainIdOrChainParameters === 'number'
                        ? desiredChainIdOrChainParameters
                        : desiredChainIdOrChainParameters?.chainId

                // if there's no desired chain, or it's equal to the received, update
                if (!desiredChainId || receivedChainId === desiredChainId)
                    return this.actions.update({ chainId: receivedChainId, accounts })

                const desiredChainIdHex = `0x${desiredChainId.toString(16)}`

                // if we're here, we can try to switch networks
                return this.provider
                    .request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: desiredChainIdHex }],
                    })
                    .catch((error) => {
                        // https://github.com/MetaMask/MetaMask-mobile/issues/3312#issuecomment-1065923294
                        const errorCode = (error.data)?.originalError?.code || error.code

                        // 4902 indicates that the chain has not been added to MetaMask and wallet_addEthereumChain needs to be called
                        // https://docs.MetaMask.io/guide/rpc-api.html#wallet-switchethereumchain
                        if (errorCode === 4902 && typeof desiredChainIdOrChainParameters !== 'number') {
                            if (!this.provider) throw new Error('No provider')
                            // if we're here, we can try to add a new network
                            return this.provider.request({
                                method: 'wallet_addEthereumChain',
                                params: [{ ...desiredChainIdOrChainParameters, chainId: desiredChainIdHex }],
                            })
                        }

                        throw error
                    })
                    .then(() => this.activate(desiredChainId))
            })
            .catch((error) => {
                cancelActivation?.()

                throw error
            })
    }

    async watchAsset({ address, symbol, decimals, image }) {
        if (!this.provider) throw new Error('No provider')

        return this.provider
            .request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address, // The address that the token is at.
                        symbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals, // The number of decimals in the token
                        image, // A string url of the token logo
                    },
                },
            })
            .then((success) => {
                if (!success) throw new Error('Rejected')
                return true
            })
    }
}