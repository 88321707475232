import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { history } from '../../helpers/router_templates/history';
import { LogoGoTix } from './CustomIcons';
export default function SystemError({ error, resetErrorBoundary }) {
  return (
    <>
      <div className="pageLoader bg-white absolute inset-0 z-50 w-full h-full">
        <div className="featuredBackgroundContainer h-screen w-screen flex items-center flex-col justify-center gap-4">
          <div className="container">
            <Row className="justify-content-center py-3 py-lg-5">
              <Col md={8} lg={7} xl={6} xxl={5}>
                <div className="mb-4">
                  <LogoGoTix className="w-full h-16 text-sky-700" />
                </div>
                <div className="">
                  <Card className="rounded-xl border-gray-200">
                    <Card.Body className="py-4">
                      <div className="text-center">
                        <div className="text-danger flex justify-center w-full">
                          <MdOutlineErrorOutline
                            style={{ color: '#f1416c', fontSize: '5rem' }}
                          />
                        </div>
                        <h2 className="text-2xl">System Error!</h2>

                        <h3 className="text-gray-400">
                          Look like! something went wrong at our end.
                        </h3>
                        <strong className="text-gray-400">
                          {error ? error.message : null}
                        </strong>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-12 col-md-6 text-center">
                          <div className="d-flex gap-2 mt-4">
                            <button
                              onClick={() => {
                                resetErrorBoundary();
                                history.navigate('/', {
                                  state: { redirected: true },
                                });
                              }}
                              className="btn btnPrimary rounded-xl w-full flex justify-center items-center text-3xl"
                            >
                              Go to Homepage
                            </button>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
