import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import NoDataFound from '../common/NoDataFound';
import { EventCardSkeleton } from '../util/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { AddPage } from '../../features/home_page/homepage.slice';
import { updateDataCountByViewport } from '../../utils/util';
import EventCardV2 from './EventCardV2';
import { sliderButton } from '../common/DefaultClasses';

export default function TrendingSlider({ data, from, isLoading, tabs, small }) {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const [swiper, setSwiper] = React.useState(null);
  let page_limit = useSelector((state) => state?.homePageEvents);
  let dispatch = useDispatch();
  let PublishedEvents = useSelector((state) => state?.publishedEvents);
  const { ongoing, trending } = PublishedEvents;

  const involkeAPI = () => {
    if (
      from === 'trending' &&
      trending?.data?.length > 0 &&
      trending?.data?.length < trending?.totalCount
    ) {
      console.log('coming');
      trending?.data?.length < trending?.totalCount &&
        dispatch(
          AddPage({
            ...page_limit,
            page: {
              ...page_limit?.page,
              trending: page_limit?.page?.trending + 1,
            },
            limit: {
              ...page_limit?.limit,
              trending: updateDataCountByViewport(),
            },
          }),
        );
    } else if (
      ongoing?.data?.length > 0 &&
      ongoing?.data?.length < ongoing?.totalCount
    ) {
      console.log('ongoing', ongoing);
      console.log('page_limit', page_limit);

      ongoing?.data?.length < ongoing?.totalCount &&
        dispatch(
          AddPage({
            ...page_limit,
            page: {
              ...page_limit?.page,
              ongoing: page_limit?.page?.ongoing + 1,
            },
            limit: {
              ...page_limit?.limit,
              ongoing: updateDataCountByViewport(),
            },
          }),
        );
    }
  };

  return (
    <>
      <div className="relative px-0.5">
        <Swiper
          hashNavigation={{
            watchState: true,
          }}
          // allowTouchMove={true}
          // allowSlideNext={true}
          // allowSlidePrev={true}
          // lazy={true}
          slidesPerView={3}
          freeMode={true}
          effect="fade"
          spaceBetween={0}
          //   touchStartPreventDefault={false}
          //   pagination={pagination}
          // effect="fade"
          // grabCursor={true}
          loop={false}
          // sticky={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onSwiper={(s) => {
            setSwiper(s);
          }}
          onBeforeInit={(swiper) => {
            navigationPrevRef.current = swiper.params.navigation.prevEl;
            navigationNextRef.current = swiper.params.navigation.nextEl;
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          onReachEnd={(swiper) => {
            !swiper?.isBeginning && involkeAPI();
          }}
          modules={[Pagination, Navigation]}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            576: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            // 900: {
            //   slidesPerView: 3,
            //   spaceBetween: 20,
            // },
            1200: {
              // slidesPerView: 3,
              slidesPerView: small ? 2 : 3,
              spaceBetween: 10,
            },

            1600: {
              slidesPerView: small ? 3 : 4,
              spaceBetween: 10,
            },
            // 1920: {
            //   slidesPerView: 5,
            //   spaceBetween: 20,
            // },
            // 2500: {
            //   slidesPerView: 6,
            //   spaceBetween: 20,
            // },
            // 3750: {
            //   slidesPerView: 7,
            //   spaceBetween: 20,
            // },
          }}
          className={''}
        >
          {isLoading && data?.length === 0 ? (
            <>
              {[...Array(updateDataCountByViewport())].map((_, idx) => (
                <SwiperSlide className="relative pointer-events-none" key={idx}>
                  <EventCardSkeleton />
                </SwiperSlide>
              ))}
            </>
          ) : (
            <>
              {data?.length > 0 ? (
                <>
                  {data?.map((item, index) => (
                    <SwiperSlide className="relative p-2.5" key={index}>
                      <div className="relative h-full">
                        {/* <EventCardHorizontal
                          data={item}
                          locate={from}
                          type="expired"
                        /> */}
                        <EventCardV2 data={item} locate={from} type="expired" />
                      </div>
                    </SwiperSlide>
                  ))}
                  {/* {from !== 'artistEvents' && (
                    <SwiperSlide className="relative p-2.5 h-auto">
                      <div className="relative h-full">
                        <EventViewAllCard
                          link={'/events'}
                          title={'All Events'}
                          subtitle={'Show me more'}
                        />
                      </div>
                    </SwiperSlide>
                  )} */}
                </>
              ) : (
                <>
                  <div className="mt-2 min-h-[330px] flex items-center justify-center bg-color-base-1 bg-opacity-10 backdrop-blur-xl rounded-xl">
                    <NoDataFound
                      title={`There are no ${
                        tabs ? tabs : from
                      } events in this category.`}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </Swiper>
        {/* {console.log(navigationPrevRef, 'navigationPrevRefInside')} */}
        <div className="md:flex justify-content-end align-items-center gap-2">
          <div className="absolute -left-2 lg:-left-3 top-1/2 z-10 -translate-y-1/2">
            <button
              ref={navigationPrevRef}
              disabled={isLoading}
              // onClick={prevto}
              className={`relative w-8 h-8 rounded-full dark:bg-landingPurple-60 bg-zinc-950 disabled:bg-neutral-700 text-gray-50 disabled:opacity-30 ${sliderButton}`}
            >
              <MdKeyboardArrowLeft className="text-md md:text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
            </button>
          </div>
          <div className="absolute -right-2 lg:-right-3 top-1/2 z-10 -translate-y-1/2">
            <button
              ref={navigationNextRef}
              disabled={isLoading}
              // onClick={nexto}
              className={`relative w-8 h-8 rounded-full dark:bg-landingPurple-60 bg-zinc-950 disabled:bg-neutral-700 text-gray-50 disabled:opacity-30 ${sliderButton}`}
            >
              <MdKeyboardArrowRight className="text-md md:text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
