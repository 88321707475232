import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SeatBookingRightSide from '../../common/landing_page_component/seat_booking/SeatBookingRightSide';
import SeatBookingCenter from '../../common/landing_page_component/seat_booking/SeatBookingCenter';
import {
  getBookingStatus,
  getSeatConfirmation,
  getVirtualConfirmation,
  useEventAllDetails,
} from '../../../hooks/event/seat_mapping/useSeatMapping';
import useDecodedToken, { useApiHeader } from '../../../hooks/useDecodedToken';
import SeatAvailabilityList from '../../common/landing_page_component/seat_booking/SeatAvailabilityList';
import HelmetComponent from '../../../utils/HelmateComponent';
import AlertModal, {
  SeatBookStatus,
} from '../../common/notification_modal/AlertModal';
import { PageLoaderSeatmap } from '../../util/PageLoader';
import SeatBookingEventDetailStrip from '../../common/landing_page_component/seat_booking/SeatBookingEventDetailStrip';
import SeatBookingSelectTicketStrip from '../../common/landing_page_component/seat_booking/SeatBookingSelectTicketStrip';
import SeatBookingLeftSide from '../../common/landing_page_component/seat_booking/SeatBookingLeftSide';
import { Transition } from '@headlessui/react';
import { Drawer } from 'antd';
import { getIsVerticalLayout } from '../../../utils/util';
import { useMemo } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
// import AlertTicketModal from '../../common/notification_modal/AlertReloadModal';
import {
  DeleteAllCart,
  cartData,
  setOpenTimerattemptModal,
  updateTotalAttempt,
} from '../../../features/cart/cart.slice';
import InfoStrip from '../InfoStrip';
import { updateCartDrawer } from '../../../features/home_page/homepage.slice';
import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useCartCheckOut } from '../../common/landing_page_component/check_out/useCartCheckOut';
import moment from 'moment';
import { updateCheckoutEvent } from '../../../features/checkout/checkout.slice';
import { useCookie } from '../../../hooks/cookie';
import { useOnceCall } from '../../../hooks/useOnlyOnce';

export default function SeatSelectionBoxV2() {
  const { json_header } = useApiHeader();
  const location = useLocation();
  let dispatch = useDispatch();
  const decodedToken = useDecodedToken();
  const { addingDatasToCart } = useCartCheckOut();
  const navigate = useNavigate();
  // const [searchParams] = useSearchParams();

  // let navigate = useNavigate();
  const slug1 = new URLSearchParams(location.search).get('slug1');
  const { data: eventAllDetails, isLoading } = useEventAllDetails(
    slug1,
    json_header,
  );

  const [rowData, setRowData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [OpenModal, setOpenmodal] = React.useState(false);
  const [backToHome, setBackToHome] = React.useState(false);
  const [leftSidebarShow, setLeftSidebarShow] = React.useState(false);
  const [categorySlider, setCategorySlider] = React.useState(false);
  const [expandView, setExpandView] = React.useState(false);
  const [rowSectionData, setRowSectionData] = useState([]);
  const isVerticalLayout = useMemo(() => getIsVerticalLayout(), []);
  const [openTicketAlertModal, setOpenTicketAlertModal] = useState(false);
  const [categoryCards, setCategoryCards] = useState([]);
  const [modifiedAlert, setModifiedAlert] = React.useState(false);
  const [, setCommonCat] = React.useState(false);

  const { removeCookie } = useCookie(); //don't remove the cookie and setCookie similar for all the places

  // let { ClearCartData } = useCartCheckOut();
  // const [cartData, setCartData] = useState(
  //   JSON.parse(localStorage.getItem('selectedInPersonTickets')) !== undefined &&
  //     JSON.parse(localStorage.getItem('selectedInPersonTickets')) !== null
  //     ? JSON.parse(localStorage.getItem('selectedInPersonTickets'))
  //     : [],
  // );
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [sectionLoader, setSectionLoader] = useState(false);

  const onCloseExpandView = () => {
    setExpandView(false);
    var myMap = document.getElementById('clonedSeatMap');
    // Empty the content of the element
    myMap.innerHTML = '';
  };

  const cartselectedSeats = useSelector(cartData);
  const {
    cartInpersonData,
    cartvirtualData,
    // slug,
    // selectedEvent: selectedCartEvent,
  } = cartselectedSeats;

  useLayoutEffect(() => {
    const isPageReloaded = localStorage.getItem('isPageReloaded');
    if (!isPageReloaded) {
      localStorage.setItem('isPageReloaded', true);
      window.location.reload();
    }
    return () => {
      if (localStorage.getItem('isPageReloaded')) {
        localStorage.removeItem('isPageReloaded');
      }
      // Cleanup code (optional)
    };
  }, []);

  //getting all the selected Inperson seats from the redux.
  let selectedSeats = useSelector((state) => state.selectedSeats);
  // let cartselectedSeats = useSelector(cartData);
  // let { cartInpersonData, slug, cartDataType, cartvirtualData } =
  //   cartselectedSeats;

  // checking the eventis is same or not. If its same just removing the seats from the redux store and not from localstorage.

  // React.useEffect(() => {
  //   if (
  //     Object.keys(cartInpersonData).length > 0 &&
  //     eventAllDetails !== undefined
  //   ) {
  //     if (slug !== eventAllDetails?.data?.slug) {
  //       dispatch(DeleteAllSeats([]));
  //     } else {
  //       let data = Object.values(cartInpersonData)?.[0];
  //       dispatch(AddTotalSeats(data));
  //     }
  //   }
  // }, [eventAllDetails, cartInpersonData, slug, cartDataType]);

  const handleSelectedID = (id) => {
    setSelectedCardId(id);
  };

  const handleSectionLoader = (arg) => {
    setSectionLoader(arg);
  };

  const onCancel = useCallback(() => {
    dispatch(updateCartDrawer(true));
  }, []);

  function removeVirtualSeat() {
    const categoryLengths = {};

    cartvirtualData?.[cartvirtualData.slug]?.virtualData[0]?.type?.forEach(
      ({ category }) => {
        categoryLengths[category] = (categoryLengths[category] || 0) + 1;
      },
    );

    const finalCategory = Object.entries(categoryLengths).map(
      ([category, quantity]) => ({
        category,
        quantity,
      }),
    );
    let event = cartvirtualData?.selectedEvent?._id;
    let user = cartvirtualData?.selectedEvent?.user_id;
    let body = {
      category: finalCategory,
      event_id: event,
      user_id: user,
      type: 'remove',
    };

    return getVirtualConfirmation(body, json_header);
  }

  const removeVirtualMutation = useMutation(removeVirtualSeat, {
    onSuccess: (data) => {
      statusRemoveTickets();
      console.log('data', data);
    },
    onError: (data) => {
      console.log(data);
    },
  });

  function removeSeat() {
    const seatIDs = cartInpersonData?.[cartInpersonData?.slug]?.map(
      (obj) => obj.seat_id,
    );

    const venueID = cartInpersonData?.selectedEvent?.venue_id;
    let body = {
      seat_id: seatIDs,
      venue_id: venueID,
      type: 'remove',
    };

    return getSeatConfirmation(body, json_header);
  }

  const removeSeatMutation = useMutation(removeSeat, {
    onSuccess: (data) => {
      statusRemoveTickets();
      // console.log('data', data);
    },
    onError: (data) => {},
  });

  const statusRemoveTickets = () => {
    statusCheck();
    // setTimeout(() => {
    // need to replace with persistance
    localStorage.removeItem(
      `selectedInPersonTickets${decodedToken ? decodedToken?.id : 'GuestUser'}`,
    );
    localStorage.removeItem(
      `selectedVirtualTickets${decodedToken ? decodedToken?.id : 'GuestUser'}`,
    );
    localStorage.removeItem('selectedCartEvent');
    dispatch(DeleteAllCart([]));
    removeCookie(`timer${decodedToken ? decodedToken?.id : 'GuestUser'}`);
    removeCookie(
      `TotalAttempt${decodedToken ? decodedToken?.id : 'GuestUser'}`,
    );
    dispatch(updateTotalAttempt(0));
    dispatch(setOpenTimerattemptModal(false));
    setOpenTicketAlertModal(false);
  };

  const onSubmit = useCallback(() => {
    if (Object.keys(cartInpersonData).length > 0) {
      removeSeatMutation.mutate();
    }
    if (Object.keys(cartvirtualData).length > 0) {
      removeVirtualMutation.mutate();
    }

    // }, 2000);
  }, [
    decodedToken,
    cartInpersonData,
    cartvirtualData,
    dispatch,
    removeSeatMutation,
    removeVirtualMutation,
    statusCheck,
    removeCookie,
  ]);

  ///if infoStrip is open and cart is empty then closing this.
  React.useEffect(() => {
    // console.log('here');
    if (
      Object.keys(cartvirtualData)?.length === 0 ||
      Object.keys(cartInpersonData)?.length === 0
    ) {
      // console.log('hereaaa');
      setOpenTicketAlertModal(false);
    }
  }, [cartvirtualData, cartInpersonData]);

  useEffect(() => {
    const filteredArray = eventAllDetails?.data?.category?.filter((mainObj) => {
      const filterObj =
        eventAllDetails?.data?.venue[0]?.assignedInfo?.assignedSeats?.find(
          (obj) => obj.categories_id === mainObj._id,
        );
      if (filterObj) {
        return mainObj;
      }
      return false;
    });
    setCategoryCards(filteredArray);
  }, [eventAllDetails]);
  // console.log(eventAllDetails?.data?.venue_id);

  function seatBookStatus(id) {
    const eventID = eventAllDetails?.data?.venue_id;
    let body = {
      seat_id: id,
      venue_id: eventID,
    };
    return getBookingStatus(body, json_header);
  }

  const handleSaveTicket = () => {
    if (eventAllDetails?.data?.ticket?.length) {
      const getPurchasDdetails = () => {
        let PurchasDetails = [];
        selectedSeats?.data?.map((data) => {
          PurchasDetails.push({
            category: data?.category_id,
            quantity: 1,
            price: parseInt(data?.price),
            name: data?.category,
            section: data?.section,
            seat_number: data?.seat_number,
            seat_id: data?.seat_id,
            row: data?.row,
            resale_price: Number(
              parseFloat(data?.resale_price?.toString()?.replace(/,/g, '')),
            ),
            reseller_id: data?.reseller_id,
            //flag to check ticket on resale
            is_resale:
              data?.resale_price === null || data?.resale_price === undefined
                ? false
                : true,
            ticket_id: checkContainCommon()
              ? checkContainCommon(true)
              : getTicketId(data?.category_id),
          });
        });
        return PurchasDetails;
      };

      const checkContainCommon = (getID) => {
        let result = false;
        let id = '';

        eventAllDetails?.data?.ticket?.map((item) => {
          if (item.category_id === 'common') {
            if (item.status && item.ticket_status) {
              result = true;
              id = item._id;
            }
          }
        });

        return getID ? id : result;
      };

      const getTicketId = (id) => {
        let catID = '';

        eventAllDetails?.data?.ticket?.map((item) => {
          if (item.category_id === id) {
            if (item.category_id === 'common') {
              setCommonCat(true);
              catID = item._id;
            } else {
              catID = item._id;
            }
          }
        });
        return catID;
      };

      let payload = {
        start_date_time: eventAllDetails?.data?.start_date_time,
        event_id: eventAllDetails?.data?._id,
        event_name: eventAllDetails?.data?.name,
        eventType: 'Physical',
        user_id: eventAllDetails?.data?.user_id,
        login_user_id: decodedToken?.id,
        dateTime: moment(),
        policies:
          eventAllDetails?.data?.ticket_policy ||
          eventAllDetails?.data['ticket-policy'], //will edit once api live
        event_img: eventAllDetails?.data?.profile_image?.url,
        venue_location: eventAllDetails?.data?.venue?.length
          ? eventAllDetails?.data?.venue[0]?.location
          : 'N/A',
        venue_name: eventAllDetails?.data?.venue?.length
          ? eventAllDetails?.data?.venue[0]?.name
          : 'N/A',
        type: getPurchasDdetails(),
      };
      if (checkContainCommon()) {
        payload = {
          ...payload,
          ticket_id: checkContainCommon() ? checkContainCommon(true) : '',
        };
      }
      if (eventAllDetails?.data?.tourData) {
        payload = {
          ...payload,
          tourData: eventAllDetails?.data?.tourData,
        };
      }
      localStorage.setItem('PurchasedTickets', JSON.stringify(payload));
      localStorage.removeItem('notLoggedUser');
    }
  };

  function seatConfirmation() {
    const seatIDs = selectedSeats?.data.map((obj) => obj.seat_id);
    const eventID = eventAllDetails?.data?.venue_id;
    let body = {
      seat_id: seatIDs,
      venue_id: eventID,
      status: 1,
      type: 'insert',
    };
    return getSeatConfirmation(body, json_header);
  }

  const seatConfirmMutation = useMutation(seatConfirmation, {
    onSuccess: (data) => {
      console.log('data', data);
    },
    onError: (data) => {
      console.log(data);
      // Logic to handle mutation error
      //  alert(data);
    },
  });

  const checkOutMutation = useMutation(seatBookStatus, {
    onSuccess: (data) => {
      if (data.status) {
        // onSubmit();
        seatConfirmMutation.mutate();
        addingDatasToCart('Physical', slug1, eventAllDetails?.data);
        handleSaveTicket();
        //! with price seatmap id
        // navigate(
        //   `/checkout?slug=${searchParams.get('slug1')}&user_id=${
        //     selectedSeats?.data[0]?.user_id
        //   }&seatmap_id=${selectedSeats?.data[0]?.price_seatmap_id}&event_id=${
        //     selectedSeats?.data[0]?.event_id
        //   }`,
        // );
        //! without price seatmap id
        if (selectedSeats && selectedSeats?.data?.length > 0) {
          localStorage.setItem(
            'selectedSeatData',
            JSON.stringify(selectedSeats?.data),
          );
          localStorage.setItem(
            'checkout_event',
            JSON.stringify(eventAllDetails?.data),
          );
          dispatch(updateCheckoutEvent(eventAllDetails?.data));
        }

        navigate(
          `/checkout?slug=${slug1}${
            decodedToken?.id ? `&user_id=${decodedToken?.id}` : ''
          }&event_id=${selectedSeats?.data[0]?.event_id}`,
        );
        localStorage.removeItem('isPageReloaded');
      } else {
        setModifiedAlert(true);
        // alert('This is an alert message!');
      }
      // onSubmit();
      // Logic to handle mutation success
      // console.log('data', data);
    },
    onError: (data) => {
      // Logic to handle mutation error
      // alert(data);
    },
  });

  function statusCheck() {
    const seatIDs = selectedSeats?.data.map((obj) => obj.seat_id);
    checkOutMutation.mutate(seatIDs);
  }

  const refreshContent = () => {
    window.location.reload();
    setModifiedAlert(false);
  };

  useOnceCall(() => {
    localStorage.removeItem('refreshFlag');
  }, []);

  return (
    <React.Fragment>
      <HelmetComponent title={'NFTGOTIX | Booking Section'} />
      <div className="w-full sm:px-0 bg-color-base-1 min-h-screen">
        <section className="w-full h-full">
          <div className="pt-3 pb-0 px-3">
            <SeatBookingEventDetailStrip
              eventAllDetails={eventAllDetails}
              darkTheme
              categorySlider={categorySlider}
              setCategorySlider={setCategorySlider}
              setOpenmodal={setOpenmodal}
              setBackToHome={setBackToHome}
              loader={loader}
              slug={slug1}
              isLoading={isLoading}
            />
          </div>
          {openTicketAlertModal && (
            <div className="">
              <InfoStrip
                message={'You have already added an order to your cart.'}
                secondaryBtn={{ name: 'Review', onClick: onCancel }}
                primaryBtn={{ name: 'Replace', onClick: onSubmit }}
              />
            </div>
          )}
          {/* <div className="relative overflow-hidden lg:min-h-[700px]"> */}
          <div className="relative overflow-hidden">
            <div
              className={`accordionHeight relative ${
                categorySlider ? 'accordion-content' : null
              }`}
            >
              <div className="p-3.5 lg:p-0">
                <SeatBookingSelectTicketStrip
                  eventAllDetails={categoryCards}
                  setLeftSidebarShow={setLeftSidebarShow}
                  leftSidebarShow={leftSidebarShow}
                  setRowSectionData={setRowSectionData}
                  handleSelectedID={handleSelectedID}
                  selectedCardId={selectedCardId}
                  handleSectionLoader={handleSectionLoader}
                />
              </div>
            </div>
            <div className="w-full h-full">
              {/* <div className="container-fluid py-7"> */}
              <div className="container-fluid">
                <div className="flex justify-between h-full xl:max-h-[100vh] flex-wrap2 xl:flex-nowrap mt-2 rounded-lg mx-[5px]">
                  <div
                    className={`accordionWidth shrink-0 pb-[10px] ${
                      leftSidebarShow
                        ? 'accordion-content opacity-100 w-full xl:max-w-[360px]'
                        : 'max-w-[0px]'
                    } `}
                  >
                    <div className="h-full">
                      <Transition
                        show={leftSidebarShow}
                        as={'div'}
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-200 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                        className={'h-full'}
                      >
                        <SeatBookingLeftSide
                          rowData={rowData}
                          rowSectionData={rowSectionData}
                          setRowSectionData={setRowSectionData}
                          setLeftSidebarShow={setLeftSidebarShow}
                          className={
                            'dark:bg-color-base-2 bg-color-base-2 bg-opacity-100 rounded-lg me-[0px] lg:me-2.5'
                          }
                          eventAllDetails={eventAllDetails}
                          // setOpenTicketAlertModal={setOpenTicketAlertModal}
                          // cartData={cartData}
                          setSelectedCardId={setSelectedCardId}
                          handleSectionLoader={handleSectionLoader}
                          sectionLoader={sectionLoader}
                          selectedCardId={selectedCardId}
                        />
                      </Transition>
                    </div>
                  </div>
                  <div className="w-full flex flex-col justify-between">
                    <div className="h-full relative bg-gray-50 dark:bg-[#c5c5c5] overflow-hidden max-h-[400px] md:max-h-[600px] lg:max-h-[100%] seat-map me-2.5 rounded-lg">
                      <SeatBookingCenter
                        eventAllDetails={eventAllDetails}
                        expandView={expandView}
                        setExpandView={setExpandView}
                        setRowData={setRowData}
                        rowData={rowData}
                        setLoader={setLoader}
                        setOpenTicketAlertModal={setOpenTicketAlertModal}
                        openTicketAlertModal={openTicketAlertModal}
                        from={'main'}
                        // cartData={cartData}/
                      />
                    </div>
                    <div className="flex justify-center w-full my-3">
                      <div className="flex gap-3 items-center justify-center flex-wrap2 xl:flex-nowrap">
                        <SeatAvailabilityList
                          bgColor={'bg-sky-600'}
                          title={'Available Seats'}
                        />
                        <SeatAvailabilityList
                          bgColor={'bg-green-400'}
                          title={'Selected Seats'}
                        />
                        <SeatAvailabilityList
                          bgColor={'bg-gray-500'}
                          title={'Booked Seats'}
                        />
                        <SeatAvailabilityList
                          bgColor={'bg-resale-900'}
                          title={'Seats on Resale'}
                        />
                      </div>
                    </div>
                    <Drawer
                      open={expandView}
                      onClose={onCloseExpandView}
                      // backdrop={'backdrop-blur-sm'}
                      // maxWidth="w-full"
                      // bodyClass={'p-0'}
                      // closeBtnPos={'hidden'}
                      // fullScreen
                      // bgColor={'bg-gray-50 backdrop-blur-md rounded-none'}
                      // height={'96%'}
                      maskStyle={{ backdropFilter: 'blur(2px)' }}
                      className="lg:rounded-t-lg"
                      rootClassName={'bg-neutral-900 bg-opacity-50'}
                      height={isVerticalLayout ? '100%' : '96%'}
                      destroyOnClose={true}
                      closeIcon={
                        <>
                          <span className="flex absolute top-2.5 left-2.5 lg:-top-7 lg:right-2 lg:left-auto">
                            <AiOutlineClose className="lg:text-whiteNew-0 text-lg" />
                          </span>
                        </>
                      }
                      headerStyle={{ borderBottom: '0' }}
                      title={false}
                      placement="bottom"
                    >
                      <div className="w-full h-full seat-modal">
                        <div id="clonedSeatMap"></div>
                        {/* <SeatBookingCenter
                          eventAllDetails={eventAllDetails}
                          expandView={expandView}
                          setExpandView={setExpandView}
                          setRowData={setRowData}
                          rowData={rowData}
                          setLoader={setLoader}
                          setOpenmodal={setOpenmodal}
                          from="modal"
                        /> */}
                      </div>
                    </Drawer>
                  </div>

                  <div className="w-full xl:w-[400px] h-full lg:h-auto shrink-0">
                    <div className="h-full">
                      <SeatBookingRightSide
                        className={'rounded-lg mb-2'}
                        eventAllDetails={eventAllDetails}
                        setOpenTicketAlertModal={setOpenTicketAlertModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loader && (
              <div className="-mb-7">
                <PageLoaderSeatmap darkTheme />
              </div>
            )}
          </div>
        </section>
      </div>
      <AlertModal
        isOpen={backToHome}
        setIsOpen={setBackToHome}
        from="buyer"
        darkTheme={true}
        show={'timer'}
        slug={slug1}
      />
      <AlertModal
        isOpen={OpenModal}
        setIsOpen={setOpenmodal}
        from="buyer"
        navigateBack
        slug={slug1}
        involke
        darkTheme={true}
        show={'timer'}
      />
      {/* <AlertTicketModal
        setIsOpen={setOpenTicketAlertModal}
        isOpen={openTicketAlertModal}
        darkTheme={true}
        // setCartData={setCartData}
        // setEventType={setEventType}
        // eventType={eventdetails?.data?.data?.event_type}
      /> */}
      <SeatBookStatus
        isOpen={modifiedAlert}
        setIsOpen={setModifiedAlert}
        refresh={refreshContent}
      />
    </React.Fragment>
  );
}
