import React from 'react';
import { Link } from 'react-router-dom';
import { EmailIconSuccess } from '../../util/CustomIcons';

export default function ResetPasswordSuccess() {
  return (
    <React.Fragment>
      <div className="flex justify-center items-center flex-col text-center">
        <div className="mb-3">
          <EmailIconSuccess
            className={'w-20 h-20 lg:w-28 lg:h-28 textSuccess'}
          />
        </div>
        <h1 className="fw-medium text-lg 2xl:text-xl text-purple-500">
          Whoo Whoo!
        </h1>
        <p className="textMuted w-2/3 mt-3">
          Your password has been reset sucessfuly! login with your new password.
        </p>
        {/* <p>Your Social Campaigns</p> */}
      </div>
      <div className="space-y-4 max-w-screen-lg w-full py-3">
        <div className="mt-4">
          <div className="mt-4 flex justify-center items-center">
            <Link
              to="/user/login"
              className="btn flex items-center justify-center btnPrimary rounded-xl w-full md:w-2/3"
            >
              Login
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
