import React from 'react';
import Image from '../../../common/Image';
import { VerifiedUserIcon } from '../../../util/CustomIcons';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export default function SearchListData({ children }) {
  return (
    <>
      <div className="bg-color-base-1 bg-opacity-10 dark:bg-neutral-700 dark:bg-opacity-10 backdrop-blur-lg px-2.5 py-2.5 text-color-primary rounded-lg shrink-0">
        <span> {children} </span>
      </div>
    </>
  );
}

export function SearchEventListData({
  closeIcon = false,
  item,
  onSubmit,
  removeFromStorage,
  highlightLetters = () => {},
  from = 'search',
}) {
  // let lowestValue = Math.min(
  //   ...(item?.eventData
  //     ? item?.eventData.category
  //     : item?.category?.map((product) => Number(product.ticket_price)) || []),
  // );
  // let lowestValue = 0;

  let lowestValue = Math.min(
    ...item.eventData.category.map((product) => Number(product?.ticket_price)),
  );

  return (
    <>
      <div
        className="bg-color-base-1 bg-opacity-40 dark:bg-neutral-700 hover:bg-color-base-2 dark:bg-opacity-20 backdrop-blur-lg hover:dark:bg-neutral-700 hover:dark:bg-opacity-40 px-1.5 py-1.5 text-color-primary rounded-xl border-1 dark:border-level-1 cursor-pointer group"
        onClick={(e) => onSubmit(e, item)}
      >
        <div className="flex justify-between gap-2.5 items-center">
          <div className="flex justify-between gap-1.5 items-center w-full">
            <div className="flex gap-2.5 items-center">
              <div className="w-7 h-7 rounded-full overflow-hidden shrink-0">
                <Image
                  src={
                    Array.isArray(item?.eventData?.profile_image)
                      ? item?.eventData?.profile_image[0]?.url
                      : item?.eventData?.profile_image?.url
                  }
                  className={'w-full h-full object-cover'}
                />
              </div>
              <div>
                <span className="text-color-primary font-semibold">
                  {from === 'search'
                    ? highlightLetters(item?.eventData?.name)
                    : item?.eventData?.name}
                </span>
              </div>
            </div>
            <div className="shrink-0">
              <span className="text-color-secondary font-medium">
                {typeof lowestValue === 'number' ? `$ ${lowestValue}.00+` : 0}
              </span>
            </div>
          </div>
          {closeIcon ? (
            <div className="flex">
              <button
                className="w-4 h-4 flex justify-center items-center relative rounded-sm text-zinc-500/50 duration-150 ease-in group-hover:text-zinc-500 dark:text-zinc-500/90 dark:group-hover:text-zinc-400 text-lg"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent onClick of parent from triggering
                  removeFromStorage(e, item);
                }}
              >
                <MdClose className=" absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
export function SearchArtistListData({
  closeIcon = false,
  item,
  onSubmit,
  removeFromStorage,
  highlightLetters = () => {},
  from = 'search',
}) {
  return (
    <>
      <div
        className="bg-color-base-1 bg-opacity-40 dark:bg-neutral-700 hover:bg-color-base-2 dark:bg-opacity-20 backdrop-blur-lg hover:dark:bg-neutral-700 hover:dark:bg-opacity-40 px-1.5 py-1.5 text-color-primary rounded-xl border-1 dark:border-level-1 cursor-pointer group"
        onClick={(e) => onSubmit(e, item)}
      >
        <div className="flex justify-between gap-2.5 items-center">
          <div className="flex gap-2.5 items-center">
            <div className="w-7 h-7 rounded-full overflow-hidden shrink-0">
              <Image
                src={item?.profile_image?.url}
                className={'w-full h-full object-cover'}
              />
            </div>
            <div>
              <span className="text-color-primary font-semibold">
                {from === 'search' ? highlightLetters(item.name) : item.name}
              </span>
            </div>
            <div>
              <span className="text-green-600 flex text-base">
                <VerifiedUserIcon />
              </span>
            </div>
          </div>
          {closeIcon ? (
            <div className="flex">
              <button
                className="w-4 h-4 flex justify-center items-center relative rounded-sm text-zinc-500/50 duration-150 ease-in group-hover:text-zinc-500 dark:text-zinc-500/90 dark:group-hover:text-zinc-400 text-lg"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent onClick of parent from triggering
                  removeFromStorage(e, item);
                }}
              >
                <MdClose className=" absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
