import axios from 'axios';
import config from '../../../../api/Config';

export async function getVenueType(page, headers) {
  const res = await axios.get(
    config.baseUrl + config.endpoints.venue_type + page,
    headers,
  );

  return res.data;
}
export async function getLocationData(headers) {
  const res = await axios.get(
    config.baseUrl + config.endpoints.location,
    headers,
  );

  return res.data;
}

export async function getVenueCategory(id, page, headers) {
  if (id !== undefined && id !== '' && id !== null) {
    const res = await axios.get(
      `${
        config.baseUrl + config.endpoints.venue_category
      }/${id}?limit=10&page=${page}`,
      headers,
    );
    return res.data;
  } else {
    return [];
  }
}

export async function getSuggestedVenue(page, headers) {
  const res = await axios.get(
    config.baseUrl + config.endpoints.suggested_venue + page,
    headers,
  );
  return res.data;
}

export async function getSearchedVenue(
  page,
  venueType = '',
  location = '',
  search = '',
  in_search = '',
  headers,
) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.suggested_venue + page
    }&venue_type=${venueType}&location=${location}&key=${search}${
      in_search !== '' ? `&in_search=${in_search}` : ''
    }`,
    headers,
  );
  return res.data;
}

export async function getVenueCatDetails(id, headers) {
  if (id === undefined) {
    return null;
  } else {
    const res = await axios.get(
      config.baseUrl + config.endpoints.venue_cat_details + id,
      headers,
    );
    return res.data;
  }
}

export async function getVenueSubCatDetails(id, headers) {
  if (id === undefined) {
    return null;
  } else {
    const res = await axios.get(
      config.baseUrl + config.endpoints.venue_subcat_details + id,
      headers,
    );

    return res.data;
  }
}
export async function getVenueSubCatDetailsByID(id, headers) {
  if (id === undefined) {
    return null;
  } else {
    const res = await axios.get(
      config.baseUrl + config.endpoints.venue_subcat_details_by_id + id,
      headers,
    );

    return res.data;
  }
}

export async function createVenue(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.create_venue,
    body,
    headers,
  );

  return res.data;
}
export async function createVenueFromPre(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.create_venue_from_preDesign,
    body,
    headers,
  );

  return res.data;
}
export async function UpdateVenue(body, headers) {
  const res = await axios.put(
    config.baseUrl + config.endpoints.update_venue,
    body,
    headers,
  );

  return res.data;
}

export async function deleteVenue(headers, payload) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.delete_venue,
    payload,
    headers,
  );
  return res.data;
}

export async function CheckVenueName(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.venue_name,
    body,
    headers,
  );

  return res.data;
}
