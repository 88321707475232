import React, { useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import RightSideSectionWrapper from '../RightSideSectionWrapper';
import HeaderFillTop from '../HeaderFillTop';
import { CustomWalletIcon } from '../../util/CustomIcons';
import {
  afterBorder,
  classNames,
  primaryBtnClass,
} from '../../common/DefaultClasses';
import TableComponentv2 from '../../common/TableComponentv2';
import { Tab } from '@headlessui/react';
import InternalWalletInfo from './InternalWalletInfo';
import ExternalWalletInfo from './ExternalWalletInfo';

export default function CryptoWalletInfo() {
  let [categories] = useState({
    'Internal Wallet': [
      {
        id: 1,
        // title: 'Does drinking coffee make you smarter?',
        compo: (
          <>
            <InternalWalletInfo />
          </>
        ),
      },
    ],
    'External Wallet': [
      {
        id: 1,
        compo: (
          <>
            <ExternalWalletInfo />
          </>
        ),
      },
    ],
  });
  return (
    <React.Fragment>
      <div>
        <div className="">
          <HeaderFillTop
            title={'Crypto Wallet Overview'}
            extraPill={''}
            extra={<></>}
          />
        </div>
        <RightSideSectionWrapper>
          <section>
            <div className="relative">
              <div className="w-full">
                <Tab.Group>
                  <div className="p-0 relative w-full after:absolute after:content-[''] after:bg-gray-200 after:bottom-0 after:left-0 after:h-1 after:w-full after:flex">
                    <div className="relative z-10">
                      <Tab.List className="flex space-x-3 rounded-xl p-0">
                        {Object.keys(categories).map((category) => (
                          <div key={category} className="overflow-hidden">
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  'px-3 rounded-lg py-2.5 text-sm font-medium leading-5 text-neutral-600 relative shrink-0',
                                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 after:duration-200 after:ease-in',
                                  `after:content-[''] after:left-0 after:h-1.3 after:rounded-full after:bottom-0 after:absolute after:w-full`,
                                  selected
                                    ? 'bg-white shadow-none after:bg-blue-500'
                                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-neutral-900',
                                )
                              }
                            >
                              {category}
                            </Tab>
                          </div>
                        ))}
                      </Tab.List>
                    </div>
                  </div>
                  <div className="relative h-full">
                    <div className="h-full bg-white">
                      <Tab.Panels className="">
                        {Object.values(categories).map((posts, idx) => (
                          <Tab.Panel
                            key={idx}
                            className={classNames(
                              'rounded-xl bg-white p-0',
                              '',
                            )}
                          >
                            {posts.map((post) => post.compo)}
                          </Tab.Panel>
                        ))}
                      </Tab.Panels>
                    </div>
                  </div>
                </Tab.Group>
              </div>
            </div>
          </section>
        </RightSideSectionWrapper>
      </div>
    </React.Fragment>
  );
}
