import { useQuery } from '@tanstack/react-query';
import {
  getBusinessUserDetails,
  getUserDetails,
  getWalletBalanceForBusinessUser,
} from '../../helpers/api/user/user_details';
import { shortenAddress } from '../../utils/util';
// import { shortenAddress } from "../../utils/util";
import useDecodedToken, { useApiHeader } from '../useDecodedToken';
import Axios from '../../helpers/router_templates/axios';
import {
  getTicketPaymentDetails,
  getWalletBalance,
  getWalletMoneyByEventId,
  getWalletMoneyDetails,
} from '../../helpers/api/card/card';
import { useDispatch } from 'react-redux';
import {
  AddUserDetail,
  updateUsdMoney,
} from '../../features/user_detail.slice';
import { useCallback } from 'react';
import useSWR from 'swr';
import { useOnceCall } from '../useOnlyOnce';
import {
  updateBusinessUserDetails,
  updateCategoryData,
  updateWalletBalance,
} from '../../features/business_user/dashboard/dashboard.slice';
import {
  getBetCategoryList,
  getBetPeriodList,
  getBetSelectionList,
  getBetTypeList,
  getCategory,
} from '../../helpers/api/business/businessDashboard';
import config from '../../api/Config';
import { useGetAllPaginationSize } from '../usePageSize';

export function useUserDetailsSWR() {
  const decodedToken = useDecodedToken();
  const dispatch = useDispatch();
  const current_user_details = useSWR(
    'user_swr',
    () => (decodedToken ? getUserDetails(decodedToken?.username) : null),
    {
      revalidateOnMount: true,
      revalidateOnReconnect: true,
      revalidateOnFocus: false,
      onSuccess: (data) => {
        dispatch(AddUserDetail({ data: data }));
      },
    },
  );
  // const current_user_usd_wallet = useSWR('user_swr_wallet', () => decodedToken ? getWalletMoneyDetails() : null, {
  const current_user_usd_wallet = useSWR(
    'user_swr_wallet',
    () => (decodedToken ? getWalletBalance() : null),
    {
      revalidateOnMount: true,
      revalidateOnReconnect: true,
      revalidateOnFocus: false,
      onSuccess: (res) => {
        const availableAmount = res?.data?.availableAmount;
        dispatch(updateUsdMoney(Number(availableAmount)));
      },
    },
  );
  const fetchUSDWallet = useCallback(
    async ({ is_refetching = false }) => {
      if (is_refetching) {
        const walletbalancedata = await getWalletBalance();
        const availableAmount = walletbalancedata?.data?.availableAmount;
        dispatch(updateUsdMoney(Number(availableAmount)));
      }
    },
    [dispatch],
  );

  return { fetchUSDWallet, current_user_details, current_user_usd_wallet };
}

export function useBusinessUser() {
  const { json_header } = useApiHeader();
  const dispatch = useDispatch();
  const decodedToken = useDecodedToken();
  useOnceCall(async () => {
    const business_user_data = await getBusinessUserDetails(json_header);
    dispatch(updateBusinessUserDetails(business_user_data?.data));
    const category = await getCategory();
    dispatch(updateCategoryData({ type: 'category', data: category?.data }));
    const bet_category_list = await getBetCategoryList();
    dispatch(
      updateCategoryData({
        type: 'bet_category',
        data: bet_category_list?.data,
      }),
    );
    const bet_type_list = await getBetTypeList();
    dispatch(
      updateCategoryData({ type: 'bet_type', data: bet_type_list?.data }),
    );
    const bet_period_list = await getBetPeriodList();
    dispatch(
      updateCategoryData({ type: 'bet_period', data: bet_period_list?.data }),
    );
    const bet_selection_list = await getBetSelectionList();
    dispatch(
      updateCategoryData({
        type: 'bet_selection',
        data: bet_selection_list?.data,
      }),
    );
    const wallet_balance = await getWalletBalanceForBusinessUser(
      decodedToken?.id,
      json_header,
    );
    // const balance = await ethToUSD(Number(wallet_balance?.eth_balance) ?? 0)
    dispatch(updateWalletBalance(wallet_balance?.eth_balance ?? 0));
  });
}

export function useUserDetails(username) {
  const decodedToken = useDecodedToken();
  const dispatch = useDispatch();
  useGetAllPaginationSize();
  const userdetails = useQuery({
    queryKey: ['username', username],
    queryFn: async () => {
      if (
        username !== '' &&
        username !== undefined &&
        decodedToken?.user_type !== 'business'
      ) {
        return await getUserDetails(username);
      } else {
        return {};
      }
    },
    onSuccess: (res) => {
      return dispatch(AddUserDetail({ data: res }));
    },
    // refetchOnWindowFocus: true,
    // keepPreviousData: true,
    // suspense: true,
    //refetchOnWindowFocus: false,
  });

  const { refetch, isLoading } = userdetails;

  const user = {
    name: userdetails?.data?.data?.username
      ? userdetails?.data?.data?.username
      : decodedToken?.wallet?.wallet_address
      ? shortenAddress(String(decodedToken?.wallet?.wallet_address), 7)
      : 'Unnamed',
    email: userdetails?.data?.data?.email,
    imageUrl: userdetails?.data?.data?.image?.url,
  };

  const subscribeToNewsLetter = async (email, type) => {
    try {
      const res = await Axios.post(config.endpoints.subscribe_to_newsletter, {
        type: type,
        email: email,
      });
      return res.data;
    } catch (error) {
      return error?.response?.data;
    }
  };

  return { userdetails, user, refetch, isLoading, subscribeToNewsLetter };
}

export function useUserMoneyDetails(page, limit) {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['userMoney'],
    queryFn: async () => {
      return await getWalletMoneyDetails({
        page: page || 1,
        limit: limit || 10,
      });
    },
    refetchOnWindowFocus: true,
  });
  return { data, refetch, isLoading };
}

export function useTransactionByEventId(id, page, limit) {
  // const decodedToken = useDecodedToken();
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['userMoney', id, page, limit],
    queryFn: async () => {
      return await getWalletMoneyByEventId(id, page, limit);
    },
    refetchOnWindowFocus: true,
  });
  return { data, refetch, isLoading };
}
export function useTicketPaymentDetails(page, limit) {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['user_ticket_mint_payment', page, limit],
    queryFn: async () => {
      return await getTicketPaymentDetails(page, limit);
    },
  });
  return { data, refetch, isLoading };
}
