import React from 'react';
import { CalenderIcon } from '../util/CustomIcons';

export default function EventTime({ title, dateTime }) {
  return (
    <>
      <div className="relative w-auto">
        <div className="text-gray-400 fw-normal text-sm mb-1">{title}</div>
        <div className="border-1 border-component-gray-1 bg-component-gray-2 rounded-md flex items-center justify-center gap-2 py-1.5 px-2">
          <div className="flex items-center justify-center gap-1.5">
            <CalenderIcon className={'w-5 h-5'} />
            <span className="text-sm">{dateTime}</span>
          </div>
        </div>
      </div>
    </>
  );
}
