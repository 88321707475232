import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal from '../../Modal';
import { MdErrorOutline } from 'react-icons/md';

function SeatBookingMaxiumModal({ maxLimitAlert, setMaxLimitAlert }) {
  return (
    <>
      <Modal
        isOpen={maxLimitAlert}
        setIsOpen={setMaxLimitAlert}
        maxWidth="max-w-md"
        backdrop={'backdrop-blur-sm'}
        bgColor={'bg-neutral-800 bg-opacity-100 backdrop-blur-sm bg-center'}
      >
        <div className="mt-4 mt-md-0">
          <Row className="items-center">
            <Col xs={12} md={12}>
              <div className="flex justify-center flex-col text-center">
                <div className="text-6xl text-yellow-400 flex justify-center">
                  <MdErrorOutline />
                </div>
                <div className="my-2">
                  <h4 className={'text-whiteNew-0'}>
                    You have reached the maximum number of tickets per order for
                    this ticket type.
                  </h4>
                </div>
              </div>
              <div className="flex justify-center mt-4 gap-2 gap-md-3">
                <button
                  type="submit"
                  onClick={() => {
                    setMaxLimitAlert(false);
                  }}
                  className={`bg-landingPurple-60 text-gray-50 hover:bg-landingPurple-70 p-2 rounded-lg duration-200 ease-in w-full max-w-[120px]`}
                >
                  Close
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}

export default SeatBookingMaxiumModal;
