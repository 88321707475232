import React, { useCallback, useMemo, useState } from 'react';
// import Image from '../../../common/Image';
// import pic1 from '../../../../assets/images/background/city1.png';
import { CustomMapIcon } from '../../../util/CustomIcons';
import { useSelector } from 'react-redux';
import { DATEFORMAT, truncate } from '../../../../utils/util';
import moment from 'moment';
import Tooltip from '../../../common/Tooltip';
import { RiHeartFill, RiHeartLine } from 'react-icons/ri';
import { useEventFavSave } from '../../../../hooks/event/useEventList';
import NoDataFound from '../../../common/NoDataFound';
import { useNavigate } from 'react-router';
import EventImageVideo from '../../../common/EventImageVideo';
import Image from '../../../common/Image';

function CartRecomItem({ darkTheme, onClose }) {
  let navigate = useNavigate();
  //getting the userdetails
  const userDetails = useSelector((state) => state.userDetails);
  //getting the trending events
  let PublishedEvents = useSelector((state) => state?.cartTrendingEvent);
  const { data: trending } = PublishedEvents;
  const [dataID, setDataId] = useState(null);

  // console.log('trending', PublishedEvents);

  //function used to add favourite events.
  const { makeItFavourite } = useEventFavSave(dataID);

  //used to navigate to events
  const navigateToEvents = useCallback((slug) => {
    onClose();
    setTimeout(() => navigate(`/event-detail/${slug}`), 100);
  });

  // let selectedSeats = useSelector((state) => state.selectedSeats);
  const profile_temp = useMemo((data) => {
    if (data?.profile_image?.url) {
      return data?.profile_image?.url;
    }
    return data?.profile_image?.[0]?.url;
  }, []);

  return (
    <div className="you_may_like mt-2.5 md:mt-0 hidden md:inline-block">
      <div className="p-[15px] px-[20px]">
        <h3
          className={`text-color-primary text-center text-[16px] uppercase font-semibold pt-2 pb-3`}
        >
          May you also like
        </h3>
        <div className="max-h-[85vh] overflow-y-auto overflow-x-hidden scrollCss">
          {trending?.data?.length > 0 ? (
            trending?.data?.map((data, index) => (
              <div
                className={`recomitmbx rounded p-2 mb-2.5 border-1 bg-color-base-1 dark:bg-color-base-4 border-level-2`}
                key={index}
              >
                <div onClick={() => navigateToEvents(data?.slug)}>
                  {/* <EventImageVideo
                    data={data?.profile_image || data?.banner_image}
                    alt="profile picture"
                    className={
                      'w-full h-full object-cover rounded mb-3 cursor-pointer'
                    }
                    loading="lazy"
                    // width="384px"
                    // height="512px"
                  /> */}
                  <Image
                    src={
                      data?.profile_image?.url
                        ? data?.profile_image?.url
                        : data?.profile_image?.[0]?.url
                    }
                    alt={'profile picture'}
                    className={
                      'w-full h-full object-cover rounded mb-3 cursor-pointer'
                    }
                  />
                </div>
                <div className="flex flex-row items-center justify-between">
                  <h2
                    className={`dark:text-whiteNew-0 text-zinc-800 text-[16px] font-bold dark:hover:text-landingPurple-60 hover:text-sky-600 duration-150 ease-in cursor-pointer`}
                    onClick={() => navigateToEvents(data?.slug)}
                  >
                    <Tooltip placement={'top'} title={data?.name}>
                      {truncate(data?.name, 18) || '-'}
                    </Tooltip>
                  </h2>
                  {userDetails?.data?.data?.favourite_event?.some(
                    (e) => e === data?._id,
                  ) ? (
                    <>
                      <Tooltip title={'Remove from Favourite'}>
                        <RiHeartFill
                          className={'text-xl text-red-600'}
                          onClick={() => {
                            setDataId(data?._id);
                            makeItFavourite();
                          }}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title={'Add to Favourite'}>
                        <RiHeartLine
                          className={
                            'text-xl dark:text-whiteNew-0 text-rose-500'
                          }
                          onClick={() => {
                            setDataId(data?._id);
                            makeItFavourite();
                          }}
                        />
                      </Tooltip>
                    </>
                  )}
                </div>
                <span className="text-gray-500 flex mt-1 mb-1">
                  {moment(
                    moment.utc(data?.start_date_time).local(),
                    'YYYY-MM-DD hh:mm a',
                  ).format(DATEFORMAT) || '-'}
                </span>

                <div className="flex flex-row items-center">
                  <CustomMapIcon />
                  <span
                    className={`dark:text-whiteNew-0 text-zinc-800 flex text-[12px] ml-1`}
                  >
                    {data?.venue && data?.event_type !== 'Virtual' ? (
                      <span className="flex flex-col">
                        <span>
                          {data?.venue[0]?.name?.split('-')
                            ? truncate(
                                data?.venue[0]?.name?.split('-')?.[0],
                                25,
                              )
                            : truncate(data?.venue[0]?.name, 25)}
                        </span>
                      </span>
                    ) : data?.event_type === 'both' ? (
                      'In-Person & Virtual Event'
                    ) : data?.event_type === 'Virtual' ? (
                      'Virtual'
                    ) : (
                      'N/A'
                    )}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div className="mt-2">
              <NoDataFound title={'There are no Trending events.'} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CartRecomItem;
