import React from 'react';
import { MdEventNote } from 'react-icons/md';
import { btnPrimaryClass, btnSecondayClass } from './DefaultClasses';

export default function NoDataFound({
  title,
  className,
  icon,
  minHeight,
  subTitle = '',
  button = undefined,
  buttonProps,
  onSubmit,
  onCancel,
  primaryBtnIcon,
}) {
  console.log(subTitle, 'subTitle');
  return (
    <React.Fragment>
      <div
        className={`${
          minHeight ? minHeight : 'min-h-[200px] py-3'
        } flex items-center flex-col justify-center `}
      >
        <div className="flex flex-col items-center">
          <div>
            <span className="text-3xl text-zinc-200 dark:text-zinc-700">
              {icon ? (
                <span className={className}>{icon}</span>
              ) : (
                <>
                  <MdEventNote />
                </>
              )}
            </span>
          </div>
          <div className="text-center flex flex-col mt-1.5">
            <span className="text-sm text-zinc-400 dark:text-zinc-500">
              {title ? title : 'No Data Found'}
            </span>
            {subTitle !== null ? (
              <span
                className={`text-sm text-zinc-300 dark:text-zinc-500 ${className}`}
              >
                {subTitle ? subTitle : ''}
              </span>
            ) : null}
          </div>
        </div>
        {buttonProps?.secondaryBtnText ||
        buttonProps?.primaryBtnText ||
        button ? (
          <>
            <div className="p-2 flex mt-1.5 items-center gap-2.5">
              {buttonProps?.secondaryBtnText ? (
                <>
                  <button
                    onClick={onCancel}
                    type="button"
                    className={`text-color-primaryColor2 text-sm font-medium capitalize`}
                  >
                    {buttonProps?.secondaryBtnText}
                  </button>
                  <span className="text-color-secondary font-semibold">Or</span>
                </>
              ) : null}
              {buttonProps?.primaryBtnText ? (
                <>
                  <button
                    onClick={onSubmit}
                    className={`${btnPrimaryClass} capitalize min-w-[175px]`}
                  >
                    {buttonProps?.primaryBtnIcon}
                    {buttonProps?.primaryBtnText}
                  </button>
                </>
              ) : null}
              {button}
            </div>
          </>
        ) : null}
      </div>
    </React.Fragment>
  );
}
