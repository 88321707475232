const loaderMessage = {
  transactions: 'Loading Transactions...',
  favorite_events: 'Loading Favorite Events...',
  saved_events: 'Loading Saved Events...',
  my_venue: 'Loading My Venues...',
  system_venue: 'Loading System Venues...',
  my_ticket_type: 'Loading My Ticket Type...',
  system_ticket_type: 'Loading System Ticket Type...',
  my_ticket_design: 'Loading My Ticket Design...',
  system_ticket_design: 'Loading System Ticket Design...',
  my_ticket_policy: 'Loading My Ticket Policies...',
  system_ticket_policy: 'Loading System Ticket Policies...',
  my_artist: 'Loading My Artist...',
  system_artist: 'Loading System Artist...',
  my_seat_design: 'Loading My Seat Design...',
  system__seat_design: 'Loading System Seat Design...',
  loading_events: 'Loading Events...',
  loading_tours: 'Loading Tours...',
  ticket_type: 'Loading Ticket Type...',
  ticket_design: 'Loading Ticket Design...',
  ticket_list: 'Loading Ticket list...',
  venue: 'Loading Venue...',
  orders: 'Loading Your Orders...',
  nfts: 'Loading nTkts...',
  logs: 'Loading Logs...',
  event_artist: 'Loading Event Artist...',
  event_policies: 'Loading Event Policies...',
  in_person_attendees: 'Loading In Person Attendees...',
  virtual_attendees: 'Loading Virtual Attendees...',
  loading_event_summary: 'Loading Event Summary...',
  loading_nft: 'Loading nTkts...',
  loading_orders: 'Loading Orders...',
  loading_link: 'Validating Link...',
  loading_artists: 'Loading artists/teams...',
  loading_seats: 'Loading Seats...',
  popular_states: 'Loading Popular states...',
  history: 'Loading History...',
};

export default loaderMessage;

export const portalTexts = {
  nTkt: 'nTkt',
  nTkts: 'nTkts',
};

export const infoTexts = {
  create_venue_modal: {
    placeholderText: {
      famousName: `This name will be displayed prominently for users searching for
          venues.`,
      fileName: `This name will be displayed prominently for users searching for
          venues.`,
    },
    infoText: {
      nTkt: 'nTkt',
      nTkts: 'nTkts',
    },
  },
  nTkts: 'nTkts',
  ticket_policies: {
    refund_policy:
      'Outline the conditions under which a buyer can receive a refund on ticket cancellation',
    resale_policy:
      'Outline the conditions under which a buyer can pu the tickets on resale',
    transfer_policy:
      'Outline the conditions under which a customer can share or transfer the ticket',
    cancelled_policy:
      'Outline the conditions under which a customer can receive a refund on event cancellation',
    delayed_policy:
      'Outline the conditions under which a customer can receive a refund on event delay',
  },
  title_filter: {
    current: 'Shows __type__ happening today or in the future.',
    past: 'Displays __type__ that have already taken place.',
    draft: `Lists __type__ you've created but haven't built yet.`,
    all: `A comprehensive list of current and past __type__.`,
  },
  venues: {
    my_venues:
      'This is your list of event venues, including those you created, used for past events, or plan to use for upcoming ones.',
    system_venues:
      'This is a comprehensive catalog of all famous venues available on the nftGotix which the users can utilize to create events.',
  },
  ticket_type: {
    my_ticket:
      "This list shows tickets you've created for events, including any copied from the system.",
    system_ticket:
      'Choose from a variety of pre-built ticket options for your events.',
  },
  ticket_policy: {
    my_ticket:
      'Manage refund, resale, and other policies for your events (including copied policies).',
    system_ticket:
      'This is a comprehensive catalog of all predefined ticket policies available on the nftGotix which the users can utilize to create events.',
  },
  artist: {
    my_artist:
      "Manage custom artists/teams you've created, including any copied artists.",
    system_artist:
      'This is a comprehensive catalog of all Famous Artists available on the nftGotix which the users can utilize to create events.',
  },

  reports: {
    analytics: 'Overview of your event performances.',
    order_list: 'List of all bookings of your events.',
  },
};

export const eventStatusMessage = {
  TicketNotAvailable: 'Ticket is Not Available.',
  VirtualTicketNotAvailable: 'Virtual Ticket is Not Available.',
  InPersonTicketNotAvailable: 'Virtual Ticket is Not Available.',
  saleNotStarted: 'The sale of tickets will starts on',
  virtualTicketSold: 'Virtual tickets for this event are sold out.',
  virtualTicketEnded: 'The sale of tickets for virtual event has ended.',
  inPersonTicketSold: 'In-person tickets for this event are sold out.',
  inPersonTicketEnded: 'The sale of tickets for in-person event has ended.',
  bothTicketEnded:
    'The sale of tickets for in-person & virtual event has ended.',
  bothTicketSoldOut:
    'In-person and virtual tickets for this event have been sold out.',
};
