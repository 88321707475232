import React, { useState } from 'react';
import { btnSecondayClass } from '../../common/DefaultClasses';
// import PopoverComponent from '../../common/PopoverComponent';
import { BellIcon } from '../CustomIcons';
import { Popover as AntdPopover, Drawer } from 'antd';
import NotificationSection from './NotificationSection';
import { AiOutlineClose } from 'react-icons/ai';

export default function NotificationDropdown({ btnClassName, headerDark }) {
  const [open, setIsOpen] = useState(false);
  const [openNotification, setIsOpenNotification] = useState(false);

  const handleOpenChange = (newOpen) => {
    setIsOpen(newOpen);
  };
  return (
    <>
      <AntdPopover
        content={
          <div className="min-w-[420px] pt-3.5">
            <NotificationSection />
          </div>
        }
        // title="Title"
        trigger="click"
        open={open}
        destroyTooltipOnHide={true}
        arrow={false}
        placement={'bottomLeft'}
        onOpenChange={handleOpenChange}
        overlayClassName="antd-popover"
        overlayStyle={{ paddingTop: '12px' }}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        className=""
      >
        <button
          className={`min-h-[33px] min-w-[33px] p-[2px] dark:bg-brown-800 bg-lightTheme-70 hover:bg-lightTheme-80 dark:hover:bg-zinc-700 dark:hover:bg-opacity-90 dark:border-brown-800 rounded-lg dark:text-white flex gap-1 items-center justify-center text-base leading-tight relative`}
        >
          <span className="flex w-3 h-3 rounded-full -top-1 -right-1 absolute">
            <span className="relative flex h-[8px] w-[8px]">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-2 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-[8px] w-[8px] bg-green-2"></span>
            </span>
          </span>
          <BellIcon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pulse-button" />
        </button>
      </AntdPopover>
      {/* <PopoverComponent
        tooltip={'Notifications'}
        button={
          <>
            <BellIcon
              className={`absolute top-1/2 left-1/2 translate-neg-50-50 object-cover h-5 w-5 ${btnClassName}`}
            />
          </>
        }
        button_class_es={
          'min-h-[33px] min-w-[33px] p-[2px] dark:bg-brown-800 bg-lightTheme-70 hover:bg-lightTheme-80 dark:hover:bg-zinc-700 dark:hover:bg-opacity-90 dark:border-brown-800 rounded-lg dark:text-white flex gap-1 items-center justify-center text-base leading-tight relative divide-x dark:divide-neutral-500 divide-neutral-300 relative'
        }
      ></PopoverComponent> */}

      <Drawer
        destroyOnClose={true}
        title={
          <>
            <div className="flex items-center justify-between gap-2 px-3.5 w-full pl-6">
              <div>
                <h6 className="text-color-primary text-lg font-semibold">
                  Notifications
                </h6>
              </div>
              <div>
                <button className="text-color-primary underline text-xs font-medium">
                  Mark all as read
                </button>
              </div>
            </div>
          </>
        }
        className={`gotix-drawer scrollCss footerNoBorder body-padding-none`}
        rootClassName={'drawer-root-className'}
        width={'540px'}
        open={openNotification}
        headerStyle={{
          position: 'relative',
          padding: '13px 16px',
          paddingLeft: '10px',
        }}
        // style={{
        //   header: {
        //     position: 'relative',
        //     padding: '10px 16px',
        //     paddingTop: '10px',
        //   },
        //   mask: 'maskStyle',
        // }}
        onClose={() => {
          setIsOpenNotification(false);
        }}
        closeIcon={
          <div className="absolute top-[11px] left-2 z-10 flex">
            <button
              type="button"
              // onClick={setIsOpen(false)}
              className={`${btnSecondayClass} Icon w-[28px] h-[28px] height-auto min-auto flex gap-2 relative p-0 text-sm`}
            >
              <AiOutlineClose className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover h-3.5 w-3.5" />
            </button>
          </div>
        }
        footer={null}
        bodyClass={'h-full'}
      >
        <div>
          <NotificationSection mobView={true} />
        </div>
      </Drawer>
    </>
  );
}
