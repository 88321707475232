import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  // HeaderBusinessDashbaordLayout,
  HeaderLandingPageLayout,
  LandingHeaderLayout,
  LandingHeaderLayoutV2,
} from './helpers/header_templates/HeaderLayout';
import LoginScreens from './pages/auth/login';
import SignUpScreen from './pages/auth/signup';
import PrivateRoute from './helpers/router_templates/PrivateRoute';
import ScrollToTop from './utils/components/ScrollToTop';

import SeatSelectionBox from './components/landing_page_v2/seat_booking_v2/';
import { createLazyComponent } from './utils/createLazyComponent';
import PageLoader, { PageLoader2 } from './components/util/PageLoader';
// import OrganiserProfile from './pages/organiser_profile';
import PageDarkLoader from './components/util/PageDarkLoader';
import ArtistPage from './pages/artist_page';
import TicketPreviewPage from './pages/ticket_preview';
// import HomePage2 from './pages/landing/home_page';
import CheckoutPage from './pages/checkout';
import StreamLoungePage from './pages/stream_lounge';
import { DashboardHeaderLayout } from './helpers/header_templates/HeaderLayoutV2';
import DashboardVersion2 from './pages/dashboard_v2';
// import StreamingPage from './pages/stream_lounge/StreamingPage';
import LiveStream from './pages/stream_lounge/LiveStream/LiveStream';
import ExitEvent from './pages/stream_lounge/ExitEvent/ExitEvent';
import { HomePageLanding } from './pages/landing/home_page/HomePageLanding';
import { useSelector } from 'react-redux';
import { getDashboardLoader } from './features/loader/loader.slice';
// import EventDetailPage from './pages/landing/v2/event_details/EventDetailPage';
import AllArtistPage from './pages/landing/artist_page';
import AllEventsPage from './pages/landing/all_events';
// import DashboardBusiness from './pages/business_dashboard';
// import NftViewDetailPage from './pages/business_dashboard/nft_view';
import TwoFactorAuth from './pages/auth/authentication';
import useDecodedToken from './hooks/useDecodedToken';
import { useLogout } from './helpers/api/auth/auth';
import LogoutAlert from './components/common/notification_modal/LogoutAlert';
import DisableScrollRestoration from './utils/components/DisableScrollRestoration';
// import { AppleAppSiteAssociation } from './.well-known/apple-app-site-association';
// import SearchPage from './pages/search_page';
// import UserDashboard from './pages/dashboard/user';
// import TermsOfUse from './pages/terms_of_use';
// import PrivacyPolicy from './pages/privacy';

export const CheckLoaderStatus = () => {
  let dashboardLoader = useSelector(getDashboardLoader);
  return <PageLoader2 darkTheme={!dashboardLoader?.loader} />;
};

const Error404 = createLazyComponent(() => import('./pages/error/error404/'), {
  fallback: <PageLoader />,
});
// const CreateTicketPage = createLazyComponent(() => import('./pages/'), {fallback: <PageLoader />}) './pages/create_ticket/CreateTicketPage';
// const CheckoutPage = createLazyComponent(() => import('./pages/checkout'), {
//   fallback: null,
// });
const TicketConfirmationPage = createLazyComponent(
  () => import('./pages/ticket_confirmation'),
  { fallback: <PageDarkLoader noLoaderShow /> },
);
const NftDetail = createLazyComponent(() => import('./pages/nft_detail'), {
  fallback: <PageLoader />,
});
// const BillingCheckout = createLazyComponent(() => import('./pages/'), {fallback: <PageLoader />}) './components/checkout/BillingCheckout';
const CheckoutBillingPage = createLazyComponent(
  () => import('./pages/checkout/CheckoutBillingPage'),
  { fallback: <PageLoader2 darkTheme removeText /> },
);
// const SeatBookingPage = createLazyComponent(() => import('./pages/'), {fallback: <PageLoader />}) './pages/seat_booking/';
// const EventDetailPageV2 = createLazyComponent(
//   () => import('./pages/landing/v2/EventDetailPageV2'),
//   { fallback: <PageLoader2 darkTheme removeText /> },
// );

const EventDetailPageV3 = createLazyComponent(
  () => import('./pages/landing/v2/EventDetailPageV3'),
  { fallback: <PageLoader2 darkTheme removeText /> },
);

const PrivacyPolicy = createLazyComponent(() => import('./pages/privacy'), {
  fallback: <PageLoader />,
});

const TermsOfUse = createLazyComponent(() => import('./pages/terms_of_use'), {
  fallback: <PageDarkLoader />,
});
const SearchPage = createLazyComponent(() => import('./pages/search_page'), {
  fallback: <PageDarkLoader />,
});
const TourPage = createLazyComponent(() => import('./pages/tour_page'), {
  fallback: <PageLoader2 darkTheme removeText />,
});

const OrganiserProfile = createLazyComponent(
  () => import('./pages/organiser_profile'),
  { fallback: <PageLoader2 darkTheme removeText /> },
);
const DemoStadium = createLazyComponent(
  () => import('./pages/landing/v2/demo_stadium'),
  { fallback: <PageLoader2 darkTheme removeText /> },
);
const UserDashboard = createLazyComponent(
  () => import('./pages/dashboard/user'),
  { fallback: <CheckLoaderStatus /> },
);
// const EventDetailDashboard = createLazyComponent(
//   () => import('./pages/dashboard/EventDetailDashboard'),
//   { fallback: <PageLoader /> },
// );
// const VenuePage = createLazyComponent(
//   () => import('./pages/dashboard/VenuePage'),
//   { fallback: <PageLoader /> },
// );
// const EventDetailLandingPage = React.lazy(() =>
//   import('./pages/landing/EventDetailLandingPage'),
// );
// const HomePage2 = createLazyComponent(
//   () => import('./pages/landing/home_page'),
//   { fallback: null },
// );
const UserRegisterSteps = createLazyComponent(
  () => import('./pages/auth/register'),
  { fallback: <PageLoader /> },
);

function Router() {
  const decodedToken = useDecodedToken();
  // const [modalState, setModalState] = useState({
  //   publish_modal: false,
  //   success_modal: false,
  //   message: '',
  // });
  const [sessionExpiredModal, setSessionExpiredModal] = useState(false);
  const [timer, setTimer] = useState(false);

  const { data: userdetails } = useSelector((state) => state.userDetails);

  const { logout, isLoading } = useLogout();

  const closeIntervalModal = () => {
    setSessionExpiredModal(false);
    // setModalState({ ...modalState, success_modal: false });
  };

  useEffect(() => {
    let timeoutId;
    const resetTimer = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (userdetails?.data?.session_time) {
        timeoutId = setTimeout(() => {
          setSessionExpiredModal(true);
          // setModalState({
          //   ...modalState,
          //   publish_modal: false,
          //   success_modal: true,
          //   message: <TwoMinuteTimer closeIntervalModal={closeIntervalModal} />,
          // });
        }, (Number(userdetails?.data?.session_time) - 2) * 60 * 1000);
      }
    };

    const handleUserActivity = () => {
      resetTimer();
    };

    if (decodedToken) {
      document.addEventListener('mousemove', handleUserActivity);
      document.addEventListener('keypress', handleUserActivity);
      resetTimer();
    }

    return () => {
      if (decodedToken) {
        document.removeEventListener('mousemove', handleUserActivity);
        document.removeEventListener('keypress', handleUserActivity);
        clearTimeout(timeoutId);
      }
    };
  }, [decodedToken]);

  return (
    <>
      <DisableScrollRestoration />
      <ScrollToTop />
      <Routes>
        {/* Auth Routes */}
        <Route index path="/streaming" element={<StreamLoungePage />} />
        {/* <Route path="/streaming" element={<StreamingPage />} /> */}
        <Route path="/live-streaming" element={<LiveStream />} />
        <Route path="/exit-event" element={<ExitEvent />} />
        <Route
          path="/user/login"
          element={<LoginScreens compo={'login'} darkTheme showLogo={false} />}
        />
        <Route
          path="/user/signup"
          element={<SignUpScreen compo={'signup'} darkTheme showLogo={false} />}
        />
        <Route
          path="/user/TFA"
          element={<TwoFactorAuth compo={'TFA'} darkTheme showLogo={false} />}
        />
        <Route
          path="/user/wallet-login"
          element={<LoginScreens compo={'wallet'} darkTheme />}
        />
        <Route
          path="/user/wallet-signup"
          element={<LoginScreens compo={'wallet'} darkTheme />}
        />
        <Route
          path="/auth/reset-password/confirm"
          element={<LoginScreens compo={'confirmation'} darkTheme />}
        />
        <Route
          path="/auth/email-confiramtion"
          element={<LoginScreens compo={'confirmation'} darkTheme />}
        />
        <Route
          path="/user/reset-password"
          element={<LoginScreens compo={'resetPass2'} darkTheme />}
        />
        <Route
          path="/auth/reset-password"
          element={<LoginScreens compo={'newpass'} darkTheme />}
        />
        <Route
          path="/auth/set-password"
          element={<LoginScreens compo={'newpass'} darkTheme />}
        />
        <Route
          path="/auth/verify-email"
          element={<LoginScreens compo={'verify'} darkTheme />}
        />
        <Route
          path="/auth/claim-nft/error"
          element={<LoginScreens compo={'claimNft'} darkTheme />}
        />
        <Route
          path="/nft/claim/verify-email"
          element={<LoginScreens compo={'nft_confirmation'} darkTheme />}
        />

        <Route
          path="/nft/claim/verification"
          element={<LoginScreens compo={'nft_claim_verfication'} darkTheme />}
        />
        <Route
          path="/user/:username/register"
          element={
            <PrivateRoute>
              <UserRegisterSteps darkTheme />
            </PrivateRoute>
          }
        />
        <Route
          path=""
          element={
            <PrivateRoute>
              <LandingHeaderLayout />
            </PrivateRoute>
          }
        >
          <Route
            path="/user/:username/dashboard"
            element={
              <PrivateRoute>
                <UserDashboard />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/user/:username/:slug"
            element={<EventDetailDashboard />}
          /> */}
          {/* <Route path="/user/:username/venue" element={<VenuePage />} /> */}
          <Route index path="/nft/:id" element={<NftDetail />} />
        </Route>
        <Route
          path=""
          element={<HeaderLandingPageLayout headerDark={true} demo={true} />}
        >
          <Route index path="/demo" element={<DemoStadium />} />
        </Route>
        <Route path="" element={<HeaderLandingPageLayout headerDark={true} />}>
          <Route index path="/" element={<HomePageLanding />} />
          {/* <Route
            index
            path="/event-detail/:slug"
            element={<EventDetailPageV2 />}
          /> */}
          <Route
            index
            path="/event-detail/:slug"
            element={<EventDetailPageV3 />}
          />
          <Route index path="/artists" element={<AllArtistPage />} />
          <Route index path="/search" element={<SearchPage />} />

          <Route index path="/tour/:id" element={<TourPage />} />
          <Route index path="/organiser/:id" element={<OrganiserProfile />} />
          {/* <Route index path="/artist/" element={<ArtistPage />} /> */}
          <Route index path="/ticket" element={<TicketPreviewPage />} />
          <Route index path="/artist/:id" element={<ArtistPage />} />
          <Route index path="/events" element={<AllEventsPage />} />

          {/* <Route
              index
              path="/ticket-confirmation/:status"
              element={<TicketConfirmationPage darkTheme />}
            /> */}
        </Route>
        {/* <Route
          path=""
          element={
            <PrivateRoute>
              <HeaderBusinessDashbaordLayout headerDark={true} />
            </PrivateRoute>
          }
        >
          <Route
            index
            path="/user/business/:username"
            element={<DashboardBusiness />}
          />
          <Route
            index
            path="/user/business/nft/:nft_id"
            element={<NftViewDetailPage />}
          />
        </Route> */}
        <Route
          path=""
          element={<LandingHeaderLayoutV2 headerDark={true} onlyLogo={true} />}
        >
          <Route index path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route index path="/terms-of-use" element={<TermsOfUse />} />
        </Route>
        <Route path="" element={<LandingHeaderLayoutV2 noHeader />}>
          <Route index path="/seat-booking" element={<SeatSelectionBox />} />
          <Route
            index
            path="/checkout-summary"
            element={<CheckoutBillingPage />}
          />

          <Route index path="/checkout" element={<CheckoutPage />} />
          <Route
            index
            path="/ticket-confirmation/:status"
            element={<TicketConfirmationPage darkTheme />}
          />
        </Route>
        <Route
          path=""
          element={
            <PrivateRoute>
              <DashboardHeaderLayout />
            </PrivateRoute>
          }
        >
          <Route
            index
            path="/user/:username/dashboard2"
            element={
              <>
                <DashboardVersion2 />
              </>
            }
          />
        </Route>
        {/* <Route
          index
          path="/.well-known/assestLink.json"
          onEnter={window.location.reload()}
        /> */}

        <Route path="*" element={<Error404 />} />
      </Routes>
      <LogoutAlert
        sessionExpiredModal={sessionExpiredModal}
        setSessionExpiredModal={setSessionExpiredModal}
        timer={timer}
        closeIntervalModal={closeIntervalModal}
        logout={logout}
        setTimer={setTimer}
        isLoading={isLoading}
      />

      {/* <div>
        <SuccessModal
          isOpen={modalState.success_modal}
          // isOpen={true}
          icon={{
            icon: <AiOutlineFieldTime />,
            iconClass: 'text-7xl text-yellow-500',
          }}
          setIsOpen={() =>
            setModalState({ ...modalState, success_modal: false })
          }
          onSubmit={
            () => {
              setModalState({ ...modalState, success_modal: false });
              // logout();
            }
            //primary
          }
          onCancel={
            () => {
              setModalState({ ...modalState, success_modal: false });
              logout();
            }

            //secondary
          }
          buttonProps={{
            primaryBtnText: 'Yes Continue!',
            secondaryBtnText: 'Logout',
          }}
          maxWidth={'max-w-lg'}
          message={modalState.message}
        />
      </div> */}
    </>
  );
}

export default Router;
