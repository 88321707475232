import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import design_img from '../../assets/images/tickets/bg_img2.png';
import QRCode from 'react-qr-code';
import { MdAlarm, MdOutlineLocationOn } from 'react-icons/md';
import { BiCrown } from 'react-icons/bi';
import moment from 'moment';
import {
  DATEFORMAT,
  GenerateQRCodeUrl,
  onCloseModal,
  truncate,
} from '../../utils/util';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import CurrencyFormat from 'react-currency-format';
import Tooltip from '../common/Tooltip';
import { btnPrimaryClass } from '../common/DefaultClasses';
import { useDispatch } from 'react-redux';
import { viewSingleNFT } from '../../features/nft/nft.slice';
import { setIsOpen, setShowPolicy } from '../../features/orders/orders.slice';
import useDecodedToken from '../../hooks/useDecodedToken';
import { portalTexts } from '../../utils/LoaderMessage';

const CheckPrice = (ticket, event) => {
  let price = 'XXX.XX';
  if (ticket?.category_id === 'common') {
    if (event?.data?.category?.length > 0) {
      price = event?.data?.category[0]?.ticket_price;
      price = `${
        Number(event?.data?.category[0]?.ticket_price) < 10 ? '0' : ''
      }${event?.data?.category[0]?.ticket_price}`;
    } else {
      price = 'XXX.XX';
    }
  } else {
    if (event?.data?.category?.length > 0) {
      let temp = event?.data?.category;
      let catDetails = temp.filter((item) => ticket?.category_id === item?._id);
      if (catDetails?.length > 0) {
        price = `${Number(catDetails[0]?.ticket_price) < 10 ? '0' : ''}${
          catDetails[0]?.ticket_price
        }`;
      } else {
        price = 'XXX.XX';
      }
    }
  }
  return price;
};

const getVenueCategory = (ticket, event) => {
  let cat = '-';
  if (
    ticket?.category_id === undefined ||
    ticket?.category_id === null ||
    ticket?.category_id === ''
  ) {
    cat = ticket?.template_name?.split(' ')?.[0];
  } else {
    if (ticket?.category_id === 'common') {
      cat = 'All';
    } else {
      if (event?.data?.category?.length > 0) {
        let temp = event?.data?.category;
        let catDetails = temp.filter(
          (item) => ticket?.category_id === item?._id,
        );
        if (catDetails?.length > 0) {
          cat = `${catDetails[0]?.name}`;
        } else {
          cat = '-';
        }
      }
    }
  }
  return cat;
};

export function TicketTemplate({
  data,
  banner,
  eventDetails = {},
  uploadBG,
  handleUploadBG,
  from,
  showModal = false,
  seatData,
  category,
  booking_id = null,
  event_id = null,
  method,
  setOpen = () => {},
  // setNftId = () => {},
  // setShowDetailView = () => {},
  update,
  event_type,
  allData,
  hideNft,
  type,
  setTabs = () => {},
  nftDrawer,
  setOpenSubDrawer,
  setSelectedIndex,
  ticket_name,
}) {
  let dispatch = useDispatch();
  const decodedToken = useDecodedToken();

  return (
    <React.Fragment>
      <div className="ticket_font relative parentHover" key={data?._id}>
        <Card className="rounded-md overflow-hidden border-0">
          <Card.Body className="p-0 h-full">
            <Row className="g-0 h-full relative">
              <Col md={4} lg={3}>
                <div className="p-4 bg-neutral-900 h-full">
                  <div
                    className="flex absolute top-0 h-full"
                    style={{ left: '23%', zIndex: '1' }}
                  >
                    <img
                      src={design_img}
                      className="w-full object-cover"
                      alt="ticket bg"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-between space-y-5">
                    <div className="space-y-3">
                      <div>
                        <h2 className="text-gray-50 text-lg uppercase">
                          {truncate(eventDetails?.data?.name, 12) ||
                            'Event Name'}{' '}
                        </h2>
                        <span className="flex text-xl lg:text-4xl text-orange-600">
                          {moment(
                            moment
                              .utc(eventDetails?.data?.start_date_time)
                              .local(),
                          ).format('YYYY') || '2023'}
                        </span>
                      </div>
                      <div className="flex">
                        <div className="bg-neutral-800 p-2 rounded-sm backdrop-blur-sm">
                          <span className="flex items-center lg:text-lg text-gray-50">
                            Price*
                            <span className="flex ml-2 text-orange-600">
                              {event_type === 'Virtual' ? (
                                <CurrencyFormat
                                  value={allData?.price}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'$'}
                                  disabled={true}
                                  decimalScale={2}
                                  fixedDecimalScale
                                />
                              ) : seatData !== null ? (
                                <CurrencyFormat
                                  value={seatData?.price}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'$'}
                                />
                              ) : (
                                `$${CheckPrice(data, eventDetails)}`
                              )}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="flex">
                        <div
                          style={{
                            backgroundColor:
                              category !== null && category !== undefined
                                ? category?.color
                                : '',
                          }}
                          className="mt-2 p-1 px-2 bg-sky-600 bg-opacity-40 backdrop-blur-md rounded-lg flex gap-1 items-center text-white 2xl:text-lg"
                        >
                          <BiCrown className="" />
                          {ticket_name ? (
                            ticket_name
                          ) : category !== null && category !== undefined ? (
                            <>{truncate(category?.name, 15)}</>
                          ) : (
                            truncate(getVenueCategory(data, eventDetails), 15)
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      {event_type !== 'Virtual' && (
                        <div className="flex flex-wrap gap-2">
                          <div className="bg-gray-50 p-1 rounded-sm">
                            <span className="flex items-center text-sm">
                              Section
                            </span>
                            {seatData !== null && (
                              <span className="flex text-pink-700">
                                {seatData?.section}
                              </span>
                            )}
                          </div>
                          <div className="bg-gray-50 p-1 rounded-sm">
                            <span className="flex items-center text-sm">
                              Row
                            </span>
                            {seatData !== null && (
                              <span className="flex text-pink-700">
                                {seatData?.row}
                              </span>
                            )}
                          </div>
                          <div className="bg-gray-50 p-1 rounded-sm">
                            <span className="flex items-center text-sm">
                              Seat No.
                            </span>
                            {seatData !== null && (
                              <span className="flex text-pink-700">
                                {seatData?.seat_number}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="relative h-full -top-full">
                  <div className="h-full border-1 border-level-2 border-dashed absolute top-0 right-0"></div>
                </div>
              </Col>
              <Col md={8} lg={9}>
                <div className="relative w-full h-full py-4 pl-10">
                  <div className="absolute top-1 right-1 p-1.5 bg-neutral-800 bg-opacity-40 backdrop-blur-md rounded-lg  z-30">
                    <div className="w-full flex justify-center">
                      {booking_id !== null ? (
                        <QRCode
                          className="h-12 w-12"
                          // value={GenerateQRCodeUrl(booking_id, event_id)}
                          value={GenerateQRCodeUrl(
                            allData?.cart_seats_id,
                            event_id,
                          )}
                        />
                      ) : (
                        <QRCode
                          className="h-12 w-12"
                          value={'NFTGOTIX'}
                          viewBox={`0 0 256 256`}
                        />
                      )}
                    </div>
                    {booking_id !== null && (
                      <div className="flex gap-2 mt-2">
                        {!hideNft && (
                          <div className="flex gap-2 w-full justify-center">
                            <Tooltip
                              title={
                                allData?.is_minting === 0 ||
                                allData?.is_cancel !== undefined
                                  ? 'Minting is under process.'
                                  : ''
                              }
                              placement={'bottom'}
                              childClassname="flex w-full"
                            >
                              <button
                                disabled={
                                  type === 'preview' ||
                                  allData?.is_cancel !== undefined ||
                                  allData?.is_minting === 0
                                }
                                className={`${btnPrimaryClass} w-full h-auto flex items-center justify-center disabled:cursor-not-allowed p-1`}
                                onClick={() => {
                                  // setNftId(booking_id);
                                  dispatch(setShowPolicy(false));
                                  dispatch(setIsOpen(false));
                                  nftDrawer && setOpenSubDrawer(false);
                                  nftDrawer && onCloseModal();
                                  decodedToken?.user_type === 'seller'
                                    ? setTabs('nfts')
                                    : setSelectedIndex('nfts');
                                  onCloseModal();
                                  setOpen(false);
                                  localStorage.setItem(
                                    'showNFT',
                                    JSON.stringify({
                                      status: true,
                                      NFTID: booking_id,
                                    }),
                                  );
                                  dispatch(
                                    viewSingleNFT({
                                      status: true,
                                      NFTID: booking_id,
                                    }),
                                  );
                                  // setShowDetailView(true);
                                }}
                              >
                                View {portalTexts?.nTkt}
                              </button>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div
                    className="absolute inset-0 w-full h-full bg-cover bg-center z-0"
                    style={{
                      backgroundImage: `url(${
                        banner ? banner : data?.background_img?.url
                      })`,
                    }}
                  >
                    <div className="bg-neutral-900 bg-opacity-40 w-full h-full"></div>
                  </div>
                  <div className="h-full flex flex-col justify-between relative z-20">
                    <div className="space-y-3">
                      <div>
                        <div className="flex">
                          <Tooltip
                            placement={'bottom'}
                            title={
                              eventDetails?.data?.event_type === 'both'
                                ? 'In-Person & Virtual Event'
                                : eventDetails?.data?.event_type === 'Virtual'
                                ? 'Virtual'
                                : eventDetails?.data?.event_type === 'Physical'
                                ? 'In-Person'
                                : hideNft
                                ? 'Event Type'
                                : 'In-Person or Virtual Event'
                            }
                          >
                            {eventDetails?.data?.event_type === 'both' ? (
                              <>
                                <span className="text-xs xl:text-sm rounded-full bg-sky-600 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 px-2">
                                  <span className="rounded-full relative">
                                    Virtual & In-Person
                                  </span>
                                </span>
                              </>
                            ) : eventDetails?.data?.event_type === 'Virtual' ? (
                              <span className="text-xs xl:text-sm rounded-full bg-sky-600 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 px-2">
                                Virtual Event
                              </span>
                            ) : eventDetails?.data?.event_type ===
                              'Physical' ? (
                              <span className="text-xs xl:text-sm rounded-full bg-sky-600 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 px-2">
                                In-Person
                              </span>
                            ) : (
                              <span className="text-xs xl:text-sm rounded-full bg-sky-600 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 px-2">
                                {hideNft
                                  ? 'Event Type'
                                  : 'In-Person or Virtual Event'}
                              </span>
                            )}
                          </Tooltip>
                        </div>
                        <h2 className="text-gray-50 text-lg lg:text-xl uppercase">
                          {truncate(eventDetails?.data?.name, 20) ||
                            'Event Name'}
                        </h2>
                        <span className="flex text-lg lg:text-3xl text-orange-600">
                          2023
                        </span>
                      </div>

                      <div className="flex flex-wrap gap-2">
                        <p className="text-sm 2xl:text-base text-white break-words">
                          {truncate(eventDetails?.data?.description, 40) ||
                            'Description'}
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-3">
                      <div className="flex gap-1">
                        <div className="flex items-center gap-1 text-lg">
                          <MdAlarm className="text-orange-600" />
                          <span className="flex text-gray-50">
                            {eventDetails?.data?.start_date_time
                              ? `${moment(
                                  moment
                                    .utc(eventDetails?.data?.start_date_time)
                                    .local(),
                                  'YYYY-MM-DD hh:mm a',
                                ).format(DATEFORMAT)}`
                              : '20 Dec 2023'}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-1 text-lg">
                        <MdOutlineLocationOn className="text-orange-600" />
                        <span className="flex text-gray-50">
                          {booking_id !== null
                            ? eventDetails?.data?.venue
                              ? eventDetails?.data?.venue[0]?.location ||
                                eventDetails?.data?.venue?.location
                              : '123 Anywhere St., Any City' || 'New York'
                            : eventDetails?.data?.venue?.length > 0
                            ? eventDetails?.data?.venue[0]?.location
                            : '123 Anywhere St., Any City' || 'New York'}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="absolute top-3 right-3 p-1 p-lg-2 bg-neutral-800 bg-opacity-40 backdrop-blur-md rounded-lg  z-10">
                    {booking_id !== null ? (
                      <QRCode
                        className="h-24 w-24 2xl:h-40 2xl:w-40"
                        value={GenerateQRCodeUrl(booking_id, event_id)}
                        // viewBox={`0 0 256 256`}
                      />
                    ) : (
                      <QRCode
                        className="h-24 w-24 2xl:h-40 2xl:w-40"
                        //   style={{
                        //     height: 'auto',
                        //     maxWidth: '100%',
                        //     width: '100%',
                        //   }}
                        value={'NFTGOTIX'}
                        viewBox={`0 0 256 256`}
                      />
                    )}
                    {booking_id !== null && (
                      <div className="flex gap-2 mt-2">
                        <span
                          type="button"
                          className="btn btnPrimary rounded-xl w-full h-auto flex items-center justify-center p-2"
                          onClick={() => {
                            setNftId(booking_id);
                            setOpen(false);
                            setShowDetailView(true);
                          }}
                        >
                          View NFT
                        </span>
                      </div>
                    )}
                  </div> */}
                  <div
                    style={{
                      backgroundColor:
                        category !== null && category !== undefined
                          ? category?.color
                          : '',
                    }}
                    className="absolute bottom-3 lg:bottom-5 right-3 p-1 px-2 bg-sky-600 bg-opacity-40 backdrop-blur-md rounded-lg flex gap-1 items-center text-white 2xl:text-lg"
                  >
                    <BiCrown className="" />
                    {ticket_name ? (
                      ticket_name
                    ) : category !== null && category !== undefined ? (
                      <>{truncate(category?.name, 15)}</>
                    ) : (
                      truncate(getVenueCategory(data, eventDetails), 15)
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {update && (
          <div
            className="rounded-md absolute inset-0 h-full w-full bg-gray-800 bg-opacity-5 z-20 childHover"
            type="button"
          >
            <input
              hidden
              id="upload-BG"
              onChange={uploadBG}
              accept="image/*"
              type={'file'}
            />
            <div
              className="h-full flex items-center justify-center"
              onClick={handleUploadBG}
            >
              <Row className="w-full max-w-md">
                <Col>
                  <Card className="bg-neutral-900 bg-opacity-60 backdrop-blur-sm border-level-2 border-dashed">
                    <Card.Body>
                      <div className="py-0 w-full flex flex-col items-center justify-center">
                        <div className="flex">
                          <AiOutlineCloudUpload className="text-gray-50 text-4xl" />
                        </div>
                        <div className="flex flex-col items-center">
                          <span className="text-lg text-gray-50">
                            Upload Background Image
                          </span>
                          <span className="text-sm text-gray-50">
                            File types supported: JPG, PNG, JPEG Max Size: 50 MB
                          </span>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export function TicketTemplateVertical({
  data,
  banner,
  eventDetails = {},
  uploadBG,
  handleUploadBG,
  from,
  showModal = false,
  seatData,
  category,
  booking_id,
  event_id,
  method,
  setOpen = () => {},
  // setNftId = () => {},
  // setShowDetailView = () => {},
  update,
  event_type,
  allData,
  hideNft,
  type,
  setTabs = () => {},
  nftDrawer,
  setOpenSubDrawer,
  setSelectedIndex,
  ticket_name,
}) {
  let dispatch = useDispatch();
  const decodedToken = useDecodedToken();

  return (
    <React.Fragment>
      <Row className="w-full justify-center">
        <Col md={4} lg={4} xl={4} xxl={4}>
          <div className="relative rounded-2xl overflow-hidden shadow-lg my-2">
            <div
              className="absolute inset-0 w-full h-full"
              style={{
                backgroundImage: `url(${
                  banner ? banner : data?.background_img?.url
                })`,
              }}
            ></div>
            <div className="ticket bg-neutral-900 w-full h-full rounded-2xl bg-opacity-20 backdrop-blur-md">
              <div className="title p-2 p-lg-3 p-xxl-4">
                <div className="flex">
                  <Tooltip
                    placement={'bottom'}
                    title={
                      eventDetails?.data?.event_type === 'both'
                        ? 'In-Person & Virtual Event'
                        : eventDetails?.data?.event_type === 'Virtual'
                        ? 'Virtual'
                        : eventDetails?.data?.event_type === 'Physical'
                        ? 'In-Person'
                        : hideNft
                        ? 'Event Type'
                        : 'In-Person or Virtual Event'
                    }
                  >
                    {eventDetails?.data?.event_type === 'both' ? (
                      <>
                        <span className="text-xs xl:text-sm rounded-full bg-sky-600 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 px-2">
                          <span className="rounded-full relative">
                            Virtual & In-Person
                          </span>
                        </span>
                      </>
                    ) : eventDetails?.data?.event_type === 'Virtual' ? (
                      <span className="text-xs xl:text-sm rounded-full bg-sky-600 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 px-2">
                        Virtual
                      </span>
                    ) : eventDetails?.data?.event_type === 'Physical' ? (
                      <span className="text-xs xl:text-sm rounded-full bg-sky-600 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 px-2">
                        In-Person
                      </span>
                    ) : (
                      <span className="text-xs xl:text-sm rounded-full bg-sky-600 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 px-2">
                        {hideNft ? 'Event Type' : 'In-Person or Virtual Event'}
                      </span>
                    )}
                  </Tooltip>
                </div>
                <p className="xl:text-2xl mb-0 text-gray-50">
                  {truncate(eventDetails?.data?.name, 12) || 'Event Name'}
                </p>
                <span className="flex lg:text-xl 2xl:text-2xl text-orange-600">
                  {moment(
                    moment.utc(eventDetails?.data?.start_date_time).local(),
                  ).format('YYYY') || '2023'}
                </span>
                <div className="flex">
                  <div className="bg-neutral-700 p-1 rounded-md backdrop-blur-sm bg-opacity-60">
                    <span className="flex items-center text-sm 2xl:text-base text-gray-50">
                      Price*
                      <span className="flex ml-2 text-orange-600">
                        {event_type === 'Virtual' ? (
                          <CurrencyFormat
                            value={allData?.price}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            disabled={true}
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        ) : seatData !== null ? (
                          <CurrencyFormat
                            value={seatData?.price}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        ) : (
                          `$${CheckPrice(data, eventDetails)}`
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  backgroundColor:
                    category !== null && category !== undefined
                      ? category?.color
                      : '',
                }}
                className="absolute top-2 right-2 p-1 px-2 bg-sky-600 bg-opacity-40 backdrop-blur-md rounded-lg flex gap-1 items-center text-white 2xl:text-lg"
              >
                <BiCrown className="" />
                {ticket_name ? (
                  ticket_name
                ) : category !== null && category !== undefined ? (
                  <>{truncate(category?.name, 15)}</>
                ) : (
                  truncate(getVenueCategory(data, eventDetails), 15)
                )}
              </div>
              <div className="poster relative">
                <img
                  src={banner ? banner : data?.background_img?.url}
                  alt="Movie: Only God Forgives"
                  className="w-full h-full object-cover"
                />

                {update && (
                  <div
                    className="rounded-md absolute inset-0 h-full w-full bg-gray-800 bg-opacity-10 backdrop-blur-sm z-20 childHover"
                    type="button"
                  >
                    <input
                      hidden
                      id="upload-BG"
                      onChange={uploadBG}
                      accept="image/*"
                      type={'file'}
                    />
                    <div
                      className="h-full flex items-center justify-center"
                      onClick={handleUploadBG}
                    >
                      <Row className="w-full max-w-md">
                        <Col>
                          <Card className="bg-transparent border-level-2 border-dashed">
                            <Card.Body>
                              <div className="py-0 w-full flex flex-col items-center justify-center">
                                <div className="flex">
                                  <AiOutlineCloudUpload className="text-gray-50 text-4xl" />
                                </div>
                                <div className="flex flex-col items-center">
                                  <span className="text-lg text-gray-50">
                                    Upload Background Image
                                  </span>
                                  <span className="text-sm text-gray-50">
                                    File types supported: JPG, PNG, JPEG Max
                                    Size: 50 MB
                                  </span>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </div>
              <div className="info bg-neutral-800 bg-opacity-20 backdrop-blur-md">
                {/* <table className="mb-3">
                  <tr>
                    <th className="">SCREEN</th>
                    <th>ROW</th>
                    <th>SEAT</th>
                  </tr>
                  <tr>
                    <td className="bigger">18</td>
                    <td className="bigger">H</td>
                    <td className="bigger">24</td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <th>PRICE</th>
                    <th>DATE</th>
                    <th>TIME</th>
                  </tr>
                  <tr>
                    <td>$12.00</td>
                    <td>1/13/17</td>
                    <td>19:30</td>
                  </tr>
                </table> */}

                {event_type !== 'Virtual' && (
                  <div className="flex flex-wrap justify-center gap-2 mb-3">
                    <div className="bg-gray-50 p-1 rounded-sm  backdrop-blur-md shrink-0 flex items-center gap-2">
                      <span className="flex items-center text-sm gap-1 text-neutral-950">
                        Section
                      </span>
                      {seatData !== null && (
                        <span className="flex text-pink-700">
                          {seatData?.section}
                        </span>
                      )}
                    </div>
                    <div className="bg-gray-50 p-1 rounded-sm  backdrop-blur-md shrink-0 flex items-center gap-2">
                      <span className="flex items-center text-sm gap-1 text-neutral-950">
                        Row
                      </span>
                      {seatData !== null && (
                        <span className="flex text-pink-700">
                          {seatData?.row}
                        </span>
                      )}
                    </div>
                    <div className="bg-gray-50 p-1 rounded-sm  backdrop-blur-md shrink-0 flex items-center gap-2">
                      <span className="flex items-center text-sm gap-1 text-neutral-950">
                        Seat
                      </span>
                      {seatData !== null && (
                        <span className="flex text-pink-700">
                          {seatData?.seat_number}
                        </span>
                      )}
                    </div>
                  </div>
                )}
                <div className="flex flex-col gap-3 text-gray-50">
                  <div className="flex gap-1 flex-wrap">
                    <div className="flex items-center gap-1 2xl:text-xl">
                      <MdAlarm className="text-orange-600 shrink-0" />
                      <div className="flex flex-col text-sm pt-1">
                        <span className="flex text-gray-50">
                          {eventDetails?.data?.start_date_time
                            ? `${moment(
                                moment
                                  .utc(eventDetails?.data?.start_date_time)
                                  .local(),
                                'YYYY-MM-DD hh:mm a',
                              ).format(DATEFORMAT)}`
                            : '20 Dec 2023'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-1 text-sm">
                    <MdOutlineLocationOn className="text-orange-600 shrink-0" />
                    <span className="flex text-gray-50">
                      {booking_id !== null
                        ? eventDetails?.data?.venue
                          ? eventDetails?.data?.venue[0]?.location ||
                            eventDetails?.data?.venue?.location
                          : '123 Anywhere St., Any City' || 'New York'
                        : eventDetails?.data?.venue?.length > 0
                        ? eventDetails?.data?.venue[0].location
                        : '123 Anywhere St., Any City' || 'New York'}
                    </span>
                  </div>
                </div>
              </div>
              <div className="holes-lower"></div>
              <div className="flex justify-center py-0">
                <div className="py-2.5">
                  {booking_id !== null ? (
                    <QRCode
                      className="h-24 w-24"
                      // value={GenerateQRCodeUrl(booking_id, event_id)}
                      value={GenerateQRCodeUrl(
                        allData?.cart_seats_id,
                        event_id,
                      )}
                    />
                  ) : (
                    <QRCode
                      className="h-24 w-24"
                      value={'NFTGOTIX'}
                      viewBox={`0 0 256 256`}
                    />
                  )}
                  {booking_id !== null && (
                    <div className="flex gap-2 mt-2">
                      {!hideNft && (
                        <div className="flex gap-2 w-full justify-center">
                          <Tooltip
                            title={
                              allData?.is_minting === 0 ||
                              allData?.is_cancel !== undefined
                                ? 'Minting is under process.'
                                : ''
                            }
                            placement={'bottom'}
                            childClassname="flex w-full"
                          >
                            <button
                              disabled={
                                type === 'preview' ||
                                allData?.is_cancel !== undefined ||
                                allData?.is_minting === 0
                              }
                              className={`${btnPrimaryClass} w-full h-auto flex items-center justify-center disabled:cursor-not-allowed p-1`}
                              onClick={() => {
                                // setNftId(booking_id);
                                dispatch(setShowPolicy(false));
                                dispatch(setIsOpen(false));
                                nftDrawer && setOpenSubDrawer(false);
                                nftDrawer && onCloseModal();
                                decodedToken?.user_type === 'seller'
                                  ? setTabs('nfts')
                                  : setSelectedIndex('nfts');
                                setOpen(false);
                                onCloseModal();
                                localStorage.setItem(
                                  'showNFT',
                                  JSON.stringify({
                                    status: true,
                                    NFTID: booking_id,
                                  }),
                                );
                                dispatch(
                                  viewSingleNFT({
                                    status: true,
                                    NFTID: booking_id,
                                  }),
                                );
                                // setShowDetailView(true);
                              }}
                            >
                              View {portalTexts?.nTkt}
                            </button>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export function TicketTemplate2({
  data,
  banner,
  eventDetails = {},
  uploadBG,
  handleUploadBG,
  from,
  showModal = false,
  seatData,
  category,
  booking_id,
  event_id,
  method,
  setOpen = () => {},
  // setNftId = () => {},
  // setShowDetailView = () => {},
  update,
  event_type,
  allData,
  hideNft,
  type,
  setTabs = () => {},
  nftDrawer,
  setOpenSubDrawer,
  setSelectedIndex,
  ticket_name,
}) {
  let dispatch = useDispatch();
  const decodedToken = useDecodedToken();
  return (
    <React.Fragment>
      <div className="ticket_font relative parentHover">
        <Card className="rounded-md overflow-hidden border-0">
          <Card.Body className="p-0 h-full">
            <Row className="g-0 h-full relative">
              <Col md={4} lg={4} xl={3} xxl={3}>
                <div className="relative h-full overflow-hidden">
                  <div className=" bg-gray-900 h-full">
                    <div className="flex w-20 h-20 xl:w-32 xl:h-32 2xl:w-48 2xl:h-48 bg-slate-800 absolute -top-5 xl:-top-16 -left-3 z-0 bg-opacity-80 backdrop-blur-md rounded-full"></div>
                    <div className="flex w-24 h-24 xl:w-32 xl:h-32 2xl:w-56 2xl:h-56 bg-slate-800 absolute -bottom-10 -right-10 z-0 bg-opacity-80 backdrop-blur-md rounded-full"></div>

                    <div className="h-full flex justify-between relative z-10">
                      {event_type !== 'Virtual' && (
                        <div className="w-full flex flex-col items-start pl-6 pr-2 py-5 gap-4">
                          <div className="flex text-gray-50 space-x-5 items-center">
                            <div style={{ writingMode: ' vertical-rl' }}>
                              <span className="flex text-lg xl:text-2xl 2xl:text-3xl">
                                {seatData !== null && seatData?.section}
                              </span>
                            </div>
                            <div style={{ writingMode: ' vertical-rl' }}>
                              <span className="text-lg xl:text-xl 2xl:text-2xl">
                                Section
                              </span>
                            </div>
                          </div>
                          <div className="flex text-gray-50 space-x-5 items-center">
                            <div style={{ writingMode: ' vertical-rl' }}>
                              <span className="flex text-lg xl:text-2xl 2xl:text-3xl">
                                {seatData !== null && seatData?.row}
                              </span>
                            </div>
                            <div style={{ writingMode: ' vertical-rl' }}>
                              <span className="text-lg xl:text-xl 2xl:text-2xl">
                                Row
                              </span>
                            </div>
                          </div>
                          <div className="flex text-gray-50 space-x-5 items-center">
                            <div style={{ writingMode: ' vertical-rl' }}>
                              <span className="flex text-lg xl:text-2xl 2xl:text-3xl">
                                {seatData !== null && seatData?.seat_number}
                              </span>
                            </div>
                            <div style={{ writingMode: ' vertical-rl' }}>
                              <span className="text-lg xl:text-xl 2xl:text-2xl">
                                Seat
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="h-full flex items-center ">
                        <div className="px-2">
                          <h2
                            className="text-gray-50 2xl:text-xl uppercase"
                            style={{ writingMode: ' vertical-rl' }}
                          >
                            {truncate(eventDetails?.data?.name, 12) ||
                              'Event Name'}
                          </h2>
                        </div>
                        <div className="absolute h-full top-0">
                          <div
                            className="h-full border-2 border-r-0 border-t-0 border-b-0 absolute top-0 right-0 border-pink-400"
                            style={{ borderLeftWidth: '1px' }}
                          ></div>
                        </div>
                        {/* <div className="flex">
                          <div className="bg-neutral-800 p-2 rounded-sm backdrop-blur-sm">
                            <span className="flex items-center lg:text-lg text-gray-50">
                              Price*
                              <span className="flex ml-2 text-orange-600">
                                $50,00
                              </span>
                            </span>
                          </div>
                        </div>
                        */}{' '}
                      </div>
                    </div>
                  </div>
                  <div className="absolute bottom-2 left-2">
                    <div
                      style={{
                        backgroundColor:
                          category !== null && category !== undefined
                            ? category?.color
                            : '',
                      }}
                      className="p-1 px-2 bg-pink-600 bg-opacity-40 backdrop-blur-md rounded-lg flex gap-1 items-center text-white text-sm 2xl:text-base"
                    >
                      <BiCrown className="" />
                      {ticket_name ? (
                        ticket_name
                      ) : category !== null && category !== undefined ? (
                        <>{truncate(category?.name, 15)}</>
                      ) : (
                        truncate(getVenueCategory(data, eventDetails), 15)
                      )}
                    </div>
                  </div>
                  <div className="relative h-full -top-full">
                    <div
                      className="h-full border-2 border-dashed border-r-0 border-t-0 border-b-0 absolute top-0 right-0 border-pink-400"
                      style={{ borderLeftWidth: '1px' }}
                    ></div>
                  </div>
                </div>
              </Col>
              <Col md={8} lg={8} xl={9} xxl={9}>
                <div className="relative w-full h-full py-4 pl-10 overflow-hidden">
                  <div className="absolute top-2 right-2 p-1 bg-neutral-800 bg-opacity-40 backdrop-blur-md rounded-lg z-30">
                    <div className="w-full flex justify-center">
                      {booking_id !== null ? (
                        <QRCode
                          className="h-16 w-16"
                          // value={GenerateQRCodeUrl(booking_id, event_id)}
                          value={GenerateQRCodeUrl(
                            allData?.cart_seats_id,
                            event_id,
                          )}
                        />
                      ) : (
                        <QRCode className="h-12 w-12" value={'NFTGOTIX'} />
                      )}
                    </div>
                    {booking_id !== null && (
                      <div className="flex gap-2 mt-2">
                        {!hideNft && (
                          <div className="flex gap-2 w-full justify-center">
                            <Tooltip
                              title={
                                allData?.is_minting === 0 ||
                                allData?.is_cancel !== undefined
                                  ? 'Minting is under process.'
                                  : ''
                              }
                              placement={'bottom'}
                              childClassname="flex w-full"
                            >
                              <button
                                disabled={
                                  type === 'preview' ||
                                  allData?.is_cancel !== undefined ||
                                  allData?.is_minting === 0
                                }
                                className={`${btnPrimaryClass} w-full h-auto flex items-center justify-center disabled:cursor-not-allowed p-1`}
                                onClick={() => {
                                  // setNftId(booking_id);
                                  dispatch(setShowPolicy(false));
                                  dispatch(setIsOpen(false));
                                  nftDrawer && setOpenSubDrawer(false);
                                  nftDrawer && onCloseModal();
                                  decodedToken?.user_type === 'seller'
                                    ? setTabs('nfts')
                                    : setSelectedIndex('nfts');
                                  setOpen(false);
                                  localStorage.setItem(
                                    'showNFT',
                                    JSON.stringify({
                                      status: true,
                                      NFTID: booking_id,
                                    }),
                                  );
                                  dispatch(
                                    viewSingleNFT({
                                      status: true,
                                      NFTID: booking_id,
                                    }),
                                  );
                                  // setShowDetailView(true);
                                }}
                              >
                                View {portalTexts?.nTkt}
                              </button>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="absolute inset-0 w-full h-full bg-cover bg-center z-0">
                    <div className="flex absolute inset-0 w-full h-full bg-slate-900 bg-cover bg-center z-0">
                      {/* <div className="bg-neutral-900 bg-opacity-40 backdrop-blur-lg w-5/12 h-full relative z-10">
                        <div className="w-full h-full bg-slate-900 bg-opacity-10 backdrop-blur-lg absolute -right-[8%] top-0 rounded-tr-[10%] rounded-br-[10%] z-0 overflow-hidden">
                          <div className="bg-neutral-900 bg-opacity-40 backdrop-blur-lg w-full h-full "></div>
                        </div>
                        <div className="flex w-20 h-20 xl:w-32 xl:h-32 2xl:w-48 2xl:h-48 bg-slate-800 absolute -top-5 xl:-top-16 -left-3 z-0 bg-opacity-80 backdrop-blur-md rounded-full"></div>
                        <div className="flex w-24 h-24 xl:w-32 xl:h-32 2xl:w-36 2xl:h-36 bg-slate-800 absolute bottom-12 right-12 z-0 bg-opacity-80 backdrop-blur-md rounded-full"></div>
                        <div className="flex w-16 h-16 bg-slate-800 absolute top-12 -right-8 z-0 bg-opacity-80 backdrop-blur-md rounded-full"></div>
                      </div> */}
                      <div className="w-full">
                        <div className="relative h-full w-full z-0">
                          <img
                            src={banner ? banner : data?.background_img?.url}
                            className="w-full h-full object-cover object-center"
                            alt="ticket bg"
                          />
                          <div className="w-full h-full bg-slate-900 bg-opacity-10 absolute  top-0  z-0 overflow-hidden"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="h-full flex flex-col justify-between relative z-20">
                    <div className="space-y-3">
                      <div>
                        <div className="flex">
                          <Tooltip
                            placement={'bottom'}
                            title={
                              eventDetails?.data?.event_type === 'both'
                                ? 'In-Person & Virtual Event'
                                : eventDetails?.data?.event_type === 'Virtual'
                                ? 'Virtual'
                                : eventDetails?.data?.event_type === 'Physical'
                                ? 'In-Person'
                                : hideNft
                                ? 'Event Type'
                                : 'In-Person or Virtual Event'
                            }
                          >
                            {eventDetails?.data?.event_type === 'both' ? (
                              <>
                                <span className="text-xs xl:text-sm rounded-full bg-sky-600 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 px-2">
                                  <span className="rounded-full relative">
                                    Virtual & In-Person
                                  </span>
                                </span>
                              </>
                            ) : eventDetails?.data?.event_type === 'Virtual' ? (
                              <span className="text-xs xl:text-sm rounded-full bg-sky-600 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 px-2">
                                Virtual
                              </span>
                            ) : eventDetails?.data?.event_type ===
                              'Physical' ? (
                              <span className="text-xs xl:text-sm rounded-full bg-sky-600 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 px-2">
                                In-Person
                              </span>
                            ) : (
                              <span className="text-xs xl:text-sm rounded-full bg-sky-600 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 px-2">
                                {hideNft
                                  ? 'Event Type'
                                  : 'In-Person or Virtual Event'}
                              </span>
                            )}
                          </Tooltip>
                        </div>
                        <div className="flex gap-2 items-center">
                          <h2 className="text-gray-50 text-lg lg:text-xl uppercase">
                            {truncate(eventDetails?.data?.name, 12) ||
                              'Event Name'}
                          </h2>
                          <div
                            style={{
                              backgroundColor:
                                category !== null && category !== undefined
                                  ? category?.color
                                  : '',
                            }}
                            className="p-1 px-2 bg-pink-600 bg-opacity-40 backdrop-blur-md rounded-lg flex gap-1 items-center text-white text-sm 2xl:text-base"
                          >
                            <BiCrown className="" />
                            {ticket_name ? (
                              ticket_name
                            ) : category !== null && category !== undefined ? (
                              <>{truncate(category?.name, 15)}</>
                            ) : (
                              truncate(getVenueCategory(data, eventDetails), 15)
                            )}
                          </div>
                        </div>
                        <span className="flex text-xl lg:text-3xl text-pink-600">
                          {moment(
                            moment
                              .utc(eventDetails?.data?.start_date_time)
                              .local(),
                          ).format('YYYY') || '2023'}
                        </span>
                      </div>

                      <div className="flex flex-wrap gap-2">
                        <p className="text-sm 2xl:text-base text-white break-words">
                          {truncate(eventDetails?.data?.description, 40) ||
                            'Description'}
                        </p>
                      </div>
                      <div className="flex">
                        <div className="border-t-2 border-b-2 border-pink-400 py-2">
                          <span className="text-sm xl:text-lg text-gray-50">
                            {booking_id !== null
                              ? eventDetails?.data?.venue
                                ? `${
                                    eventDetails?.data?.start_date_time
                                      ? `${moment(
                                          moment
                                            .utc(
                                              eventDetails?.data
                                                ?.start_date_time,
                                            )
                                            .local(),
                                          'YYYY-MM-DD hh:mm a',
                                        ).format(DATEFORMAT)}`
                                      : '20 Dec 2023'
                                  } / ${
                                    eventDetails?.data?.venue[0]?.location ||
                                    eventDetails?.data?.venue?.location
                                  }`
                                : '123 Anywhere St., Any City' || 'New York'
                              : eventDetails?.data?.venue?.length > 0
                              ? `${
                                  eventDetails?.data?.start_date_time
                                    ? `${moment(
                                        moment
                                          .utc(
                                            eventDetails?.data?.start_date_time,
                                          )
                                          .local(),
                                        'YYYY-MM-DD hh:mm a',
                                      ).format(DATEFORMAT)}`
                                    : '20 Dec 2023'
                                } / ${eventDetails?.data?.venue[0].location}`
                              : '123 Anywhere St., Any City' || 'New York'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="flex text-lg xl:text-2xl text-orange-600">
                        {event_type === 'Virtual' ? (
                          <CurrencyFormat
                            value={allData?.price}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            disabled={true}
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        ) : seatData !== null ? (
                          <>
                            <CurrencyFormat
                              value={seatData?.price}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'$'}
                            />
                          </>
                        ) : (
                          `$${CheckPrice(data, eventDetails)}`
                        )}
                      </span>
                    </div>
                    {/* <div className="flex gap-3">
                      <div className="flex gap-1">
                        <div className="flex items-center gap-1 2xl:text-2xl">
                          <MdAlarm className="text-orange-600" />
                          <span className="flex text-gray-50">12 Dec 2023</span>
                        </div>
                        <div className="flex gap-1 text-sm pt-1">
                          <span className="flex text-gray-50">
                            08.00 pm - 11.30 pm
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-1 2xl:text-2xl">
                        <MdOutlineLocationOn className="text-orange-600" />
                        <span className="flex text-gray-50">
                          123 Anywhere St., Any City
                        </span>
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="absolute top-3 right-3 p-1 p-lg-2 bg-neutral-800 bg-opacity-40 backdrop-blur-md rounded-lg z-10">
                    {booking_id !== null ? (
                      <QRCode
                        // size={256}
                        // style={{
                        //   height: 'auto',
                        //   maxWidth: '100%',
                        //   width: '100%',
                        // }}
                        className="h-24 w-24 2xl:h-40 2xl:w-40"
                        value={GenerateQRCodeUrl(booking_id, event_id)}
                      />
                    ) : (
                      <QRCode
                        className="h-24 w-24 2xl:h-40 2xl:w-40"
                        //   style={{
                        //     height: 'auto',
                        //     maxWidth: '100%',
                        //     width: '100%',
                        //   }}
                        // viewBox={`0 0 256 256`}
                        value={'NFTGOTIX'}
                      />
                    )}
                    {booking_id !== null && (
                      <div className="flex gap-2 mt-2">
                        <button
                          type="button"
                          disabled={true}
                          className="btn btnPrimary rounded-xl w-full h-auto flex items-center justify-center p-2"
                          onClick={() => {
                            setNftId(booking_id);
                            setOpen(false);
                            setShowDetailView(true);
                          }}
                        >
                          View NFT
                        </button>
                      </div>
                    )}
                  </div> */}
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {update && (
          <div
            className="rounded-md absolute inset-0 h-full w-full bg-gray-800 bg-opacity-0 z-20 childHover"
            type="button"
          >
            <input
              hidden
              id="upload-BG"
              onChange={uploadBG}
              accept="image/*"
              type={'file'}
            />
            <div
              className="h-full flex items-center justify-center"
              onClick={handleUploadBG}
            >
              <Row className="w-full max-w-md">
                <Col>
                  <Card className="bg-neutral-900 bg-opacity-60 backdrop-blur-sm border-level-2 border-dashed">
                    <Card.Body>
                      <div className="py-0 w-full flex flex-col items-center justify-center">
                        <div className="flex">
                          <AiOutlineCloudUpload className="text-gray-50 text-4xl" />
                        </div>
                        <div className="flex flex-col items-center">
                          <span className="text-lg text-gray-50">
                            Upload Background Image
                          </span>
                          <span className="text-sm text-gray-50">
                            File types supported: JPG, PNG, JPEG Max Size: 50 MB
                          </span>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
export function TicketTemplateVertical2({
  data,
  banner,
  eventDetails = {},
  uploadBG,
  handleUploadBG,
  from,
  showModal = false,
  seatData,
  category,
  booking_id,
  event_id,
  method,
  setOpen = () => {},
  // setNftId = () => {},
  // setShowDetailView = () => {},
  update,
  event_type,
  allData,
  hideNft,
  type,
  setTabs = () => {},
  nftDrawer,
  setOpenSubDrawer,
  setSelectedIndex,
  ticket_name,
}) {
  let dispatch = useDispatch();
  const decodedToken = useDecodedToken();
  return (
    <React.Fragment>
      <Row className="w-full justify-center">
        <Col md={4} lg={4} xl={4} xxl={4}>
          <div className="relative rounded-2xl overflow-hidden shadow-lg my-2 ">
            <div
              className="absolute inset-0 w-full h-full"
              style={{
                backgroundImage: `url(${
                  banner ? banner : data?.background_img?.url
                })`,
              }}
            ></div>
            <div className="ticket bg-neutral-900 w-full h-full rounded-2xl bg-opacity-20 backdrop-blur-md">
              <div className="title p-2 p-lg-3 p-xxl-4">
                <div className="flex">
                  <Tooltip
                    placement={'bottom'}
                    title={
                      eventDetails?.data?.event_type === 'both'
                        ? 'In-Person & Virtual Event'
                        : eventDetails?.data?.event_type === 'Virtual'
                        ? 'Virtual'
                        : eventDetails?.data?.event_type === 'Physical'
                        ? 'In-Person'
                        : hideNft
                        ? 'Event Type'
                        : 'In-Person or Virtual Event'
                    }
                  >
                    {eventDetails?.data?.event_type === 'both' ? (
                      <>
                        <span className="text-xs xl:text-sm rounded-full bg-sky-600 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 px-2">
                          <span className="rounded-full relative">
                            Virtual & In-Person
                          </span>
                        </span>
                      </>
                    ) : eventDetails?.data?.event_type === 'Virtual' ? (
                      <span className="text-xs xl:text-sm rounded-full bg-sky-600 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 px-2">
                        Virtual
                      </span>
                    ) : eventDetails?.data?.event_type === 'Physical' ? (
                      <span className="text-xs xl:text-sm rounded-full bg-sky-600 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 px-2">
                        In-Person
                      </span>
                    ) : (
                      <span className="text-xs xl:text-sm rounded-full bg-sky-600 backdrop-blur-2xl bg-opacity-60 relative text-whiteNew-0 px-2">
                        {hideNft ? 'Event Type' : 'In-Person or Virtual Event'}
                      </span>
                    )}
                  </Tooltip>
                </div>
                <p className="xl:text-2xl mb-0 text-gray-50">
                  {truncate(eventDetails?.data?.name, 12) || 'Event Name'}
                </p>
                <span className="flex lg:text-xl 2xl:text-2xl text-pink-700">
                  {moment(
                    moment.utc(eventDetails?.data?.start_date_time).local(),
                  ).format('YYYY') || '2023'}
                </span>
                <div className="flex">
                  <div className="bg-neutral-800 p-2 rounded-sm backdrop-blur-sm bg-opacity-60">
                    <span className="flex items-center lg:text-lg text-gray-50">
                      Price*
                      <span className="flex ml-2 text-pink-700">
                        {event_type === 'Virtual' ? (
                          <CurrencyFormat
                            value={allData?.price}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            disabled={true}
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        ) : seatData !== null ? (
                          <CurrencyFormat
                            value={seatData?.price}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        ) : (
                          `$${CheckPrice(data, eventDetails)}`
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  backgroundColor:
                    category !== null && category !== undefined
                      ? category?.color
                      : '',
                }}
                className="absolute top-2 right-2 p-1 bg-pink-600 bg-opacity-40 backdrop-blur-md rounded-lg flex gap-1 items-center text-white 2xl:text-lg"
              >
                <BiCrown className="" />
                {ticket_name ? (
                  ticket_name
                ) : category !== null && category !== undefined ? (
                  <>{truncate(category?.name, 15)}</>
                ) : (
                  truncate(getVenueCategory(data, eventDetails), 15)
                )}
              </div>
              <div className="poster relative parentHover">
                <img
                  src={banner ? banner : data?.background_img?.url}
                  alt="Movie: Only God Forgives"
                  className="w-full h-full object-cover"
                />
                {update && (
                  <div
                    className="rounded-md absolute inset-0 h-full w-full bg-gray-800 bg-opacity-10 backdrop-blur-sm z-20 childHover"
                    type="button"
                  >
                    <input
                      hidden
                      id="upload-BG"
                      onChange={uploadBG}
                      accept="image/*"
                      type={'file'}
                    />
                    <div
                      className="h-full flex items-center justify-center"
                      onClick={handleUploadBG}
                    >
                      <Row className="w-full max-w-md">
                        <Col>
                          <Card className="bg-transparent border-level-2 border-dashed">
                            <Card.Body>
                              <div className="py-0 w-full flex flex-col items-center justify-center">
                                <div className="flex">
                                  <AiOutlineCloudUpload className="text-gray-50 text-4xl" />
                                </div>
                                <div className="flex flex-col items-center">
                                  <span className="text-lg text-gray-50">
                                    Upload Background Image
                                  </span>
                                  <span className="text-sm text-gray-50">
                                    File types supported: JPG, PNG, JPEG Max
                                    Size: 50 MB
                                  </span>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </div>
              <div className="info bg-neutral-800 bg-opacity-20 backdrop-blur-md">
                {event_type !== 'Virtual' && (
                  <div className="flex flex-wrap justify-center gap-2 mb-3">
                    <div className="bg-gray-50 p-1 rounded-sm  backdrop-blur-md shrink-0 flex items-center gap-2">
                      <span className="flex items-center text-sm gap-1 text-neutral-950">
                        Section*{' '}
                        {seatData !== null && (
                          <span className="flex text-pink-700">
                            {seatData?.section}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="bg-gray-50 p-1 rounded-sm  backdrop-blur-md shrink-0 flex items-center gap-2">
                      <span className="flex items-center text-sm gap-1 text-neutral-950">
                        Row*{' '}
                        {seatData !== null && (
                          <span className="flex text-pink-700">
                            {seatData?.row}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="bg-gray-50 p-1 rounded-sm  backdrop-blur-md shrink-0 flex items-center gap-2">
                      <span className="flex items-center text-sm gap-1 text-neutral-950">
                        Seat*{' '}
                        {seatData !== null && (
                          <span className="flex text-pink-700">
                            {seatData?.seat_number}
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                )}
                <div className="flex flex-col gap-3 text-gray-50">
                  <div className="flex gap-1 flex-wrap">
                    <div className="flex items-center gap-1 2xl:text-xl">
                      <MdAlarm className="text-pink-700 shrink-0" />
                      <div className="flex flex-col text-sm pt-1">
                        <span className="flex text-gray-50">
                          {eventDetails?.data?.start_date_time
                            ? `${moment(
                                moment
                                  .utc(eventDetails?.data?.start_date_time)
                                  .local(),
                                'YYYY-MM-DD hh:mm a',
                              ).format(DATEFORMAT)}`
                            : '20 Dec 2023'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="border-b-2 border-pink-400 w-full"></div>
                  <div className="flex items-center gap-1 2xl:text-xl">
                    <MdOutlineLocationOn className="text-pink-700 shrink-0" />
                    <span className="flex text-gray-50">
                      {booking_id !== null
                        ? eventDetails?.data?.venue
                          ? `${
                              eventDetails?.data?.start_date_time
                                ? `${moment(
                                    moment
                                      .utc(eventDetails?.data?.start_date_time)
                                      .local(),
                                    'YYYY-MM-DD hh:mm a',
                                  ).format(DATEFORMAT)}`
                                : '20 Dec 2023'
                            } / ${
                              eventDetails?.data?.venue[0]?.location ||
                              eventDetails?.data?.venue?.location
                            }`
                          : '123 Anywhere St., Any City' || 'New York'
                        : eventDetails?.data?.venue?.length > 0
                        ? `${
                            eventDetails?.data?.start_date_time
                              ? `${moment(
                                  moment
                                    .utc(eventDetails?.data?.start_date_time)
                                    .local(),
                                  'YYYY-MM-DD hh:mm a',
                                ).format(DATEFORMAT)}`
                              : '20 Dec 2023'
                          } / ${eventDetails?.data?.venue[0].location}`
                        : '123 Anywhere St., Any City' || 'New York'}
                    </span>
                  </div>
                </div>
              </div>
              <div className="holes-lower"></div>
              <div className="flex justify-center py-4">
                <div className="p-2 bg-neutral-800 bg-opacity-40 backdrop-blur-md rounded-lg">
                  {booking_id !== null ? (
                    <QRCode
                      className="h-24 w-24 2xl:h-40 2xl:w-40"
                      // value={GenerateQRCodeUrl(booking_id, event_id)}
                      value={GenerateQRCodeUrl(
                        allData?.cart_seats_id,
                        event_id,
                      )}
                      // viewBox={`0 0 256 256`}
                    />
                  ) : (
                    <QRCode
                      className="h-24 w-24"
                      value={'NFTGOTIX'}
                      viewBox={`0 0 256 256`}
                    />
                  )}
                  {booking_id !== null && (
                    <div className="flex gap-2 mt-2">
                      {!hideNft && (
                        <div className="flex gap-2 w-full justify-center">
                          <Tooltip
                            title={
                              allData?.is_minting === 0 ||
                              allData?.is_cancel !== undefined
                                ? 'Minting is under process.'
                                : ''
                            }
                            placement={'bottom'}
                            childClassname="flex w-full"
                          >
                            <button
                              disabled={
                                type === 'preview' ||
                                allData?.is_cancel !== undefined ||
                                allData?.is_minting === 0
                              }
                              className={`${btnPrimaryClass} w-full h-auto flex items-center justify-center disabled:cursor-not-allowed p-1`}
                              onClick={() => {
                                // setNftId(booking_id);
                                dispatch(setShowPolicy(false));
                                dispatch(setIsOpen(false));
                                nftDrawer && setOpenSubDrawer(false);
                                nftDrawer && onCloseModal();
                                decodedToken?.user_type === 'seller'
                                  ? setTabs('nfts')
                                  : setSelectedIndex('nfts');
                                onCloseModal();
                                setOpen(false);
                                localStorage.setItem(
                                  'showNFT',
                                  JSON.stringify({
                                    status: true,
                                    NFTID: booking_id,
                                  }),
                                );
                                dispatch(
                                  viewSingleNFT({
                                    status: true,
                                    NFTID: booking_id,
                                  }),
                                );
                                // setShowDetailView(true);
                              }}
                            >
                              View {portalTexts?.nTkt}
                            </button>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

// export function TicketTemplateVerticalOld() {
//   <React.Fragment>
//     <div
//       className={
//         'border-2 border-dashed border-gray-200 w-full p-2 p-xl-3 relative rounded-md'
//       }
//       style={{
//         backgroundImage: `url(${ticket3})`,
//       }}
//     >
//       <Row className="gx-3">
//         <Col xl={9}>
//           <div className="h-full">
//             <Card className={'border-0 rounded-lg h-full'}>
//               <Card.Body>
//                 <div className="flex flex-col justify-between h-full">
//                   <div className="lg:space-y-2">
//                     <div className="flex gap-3 items-center">
//                       <div
//                         className="border-1 border-gray-300 h-16 w-36 relative rounded-md overflow-hidden"
//                         type="button"
//                         onClick={() => {
//                           // openModal();
//                         }}
//                       >
//                         <img
//                           src={ticket3}
//                           alt="user"
//                           className="w-full h-full object-cover"
//                           loading="lazy"
//                         />
//                       </div>
//                     </div>
//                     <div>
//                       <div className="flex gap-3 items-center">
//                         <h3 className={`text-white text-2xl`}>Event Name</h3>
//                         {/* <button
//                             className="btn btnSecondary border-0 wpx-40 hpx-40 height-auto min-auto flex gap-2 roundrem-49 relative p-0 text-sm"
//                             type="button"
//                             onClick={() => {
//                               //   openModal();
//                             }}
//                           >
//                             <RiPencilLine className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover" />
//                           </button> */}
//                       </div>
//                       <div className="flex gap-3 items-center">
//                         <span className="text-md text-gray-300">
//                           {truncate('description', 150)}
//                         </span>
//                         {/* <button
//                             className="btn btnSecondary border-0 wpx-40 hpx-40 height-auto min-auto flex gap-2 roundrem-49 relative p-0 text-sm"
//                             type="button"
//                             onClick={() => {
//                               //   openModal();
//                             }}
//                           >
//                             <RiPencilLine className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover" />
//                           </button> */}
//                       </div>
//                     </div>
//                     <div className="flex gap-2 gap-lg-3 items-center flex-wrap">
//                       <div className="flex gap-3 items-center">
//                         <h3 className="text-xs lg:text-xl">
//                           <span className={`text-white`}>06/30/23 | 20:20</span>
//                         </h3>
//                         {/* <button
//                             className="btn btnSecondary border-0 wpx-40 hpx-40 height-auto min-auto flex gap-2 roundrem-49 relative p-0 text-sm"
//                             type="button"
//                             onClick={() => {
//                               //   openModal();
//                             }}
//                           >
//                             <RiPencilLine className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover" />
//                           </button> */}
//                       </div>
//                       <div className="h-7 border-1 hidden lg:flex"></div>
//                       <div className="flex gap-2 items-center">
//                         <h3 className="text-xs lg:text-xl flex gap-3">
//                           <span className={`text-white`}>06/30/23 | 20:20</span>
//                         </h3>
//                         {/* <button
//                             className="btn btnSecondary border-0 wpx-40 hpx-40 height-auto min-auto flex gap-2 roundrem-49 relative p-0 text-sm"
//                             type="button"
//                             onClick={() => {
//                               //   openModal();
//                             }}
//                           >
//                             <RiPencilLine className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover" />
//                           </button> */}
//                       </div>
//                     </div>
//                   </div>
//                   <div className="py-2">
//                     <div className="row row-cols-5">
//                       {/* {fields?.map((data, idx) => {
//                         return (
//                           <div key={idx} className="col">
//                             <div className="flex flex-col items-center">
//                               <div className="w-3/4">
//                                 {type === 'preview' ||
//                                 (type === 'published' &&
//                                   from === 'savedtemplate') ? (
//                                   <Form.Label
//                                     // aria-label="Default select example"
//                                     // size="sm"
//                                     className={`placeholder:text-slate-400 placeholder:text-md font-normal text-md py-2 px-2 rounded-lg bg-white`}
//                                     // name={idx}
//                                     // placeholder={`header ${idx + 1}`}
//                                     // value={data.name}
//                                     // onChange={(event) =>
//                                     //   handleSelectChange(
//                                     //     event,
//                                     //     idx,
//                                     //     'select',
//                                     //   )
//                                     // }
//                                   >
//                                     {data?.name}
//                                   </Form.Label>
//                                 ) : (
//                                   <Form.Select
//                                     aria-label="Default select example"
//                                     size="sm"
//                                     className={`${formClass} rounded-lg`}
//                                     name={idx}
//                                     placeholder={`header ${idx + 1}`}
//                                     value={data.name}
//                                     onChange={(event) =>
//                                       handleSelectChange(event, idx, 'select')
//                                     }
//                                   >
//                                     <option value="" disabled={true}>
//                                       header {idx + 1}
//                                     </option>
//                                     <option
//                                       value="section"
//                                       disabled={
//                                         disableFields?.find(
//                                           (val) => val?.field === 'section',
//                                         )
//                                           ? true
//                                           : false
//                                       }
//                                     >
//                                       Section
//                                     </option>
//                                     <option
//                                       value="row_no"
//                                       disabled={
//                                         disableFields?.find(
//                                           (val) => val?.field === 'row_no',
//                                         )
//                                           ? true
//                                           : false
//                                       }
//                                     >
//                                       Row
//                                     </option>
//                                     <option
//                                       value="seats"
//                                       disabled={
//                                         disableFields?.find(
//                                           (val) => val?.field === 'seats',
//                                         )
//                                           ? true
//                                           : false
//                                       }
//                                     >
//                                       Seat
//                                     </option>
//                                     <option
//                                       value="price"
//                                       disabled={
//                                         disableFields?.find(
//                                           (val) => val?.field === 'price',
//                                         )
//                                           ? true
//                                           : false
//                                       }
//                                     >
//                                       Price
//                                     </option>
//                                   </Form.Select>
//                                 )}
//                               </div>
//                             </div>
//                           </div>
//                         );
//                       })} */}
//                     </div>
//                   </div>
//                   <div className="flex gap-3 items-center">
//                     {/* <span className="text-xs lg:text-md text-gray-400">
//                             Add event description, tagline, or any other useful
//                             information
//                           </span> */}
//                     {/* <button
//                         className="btn btnSecondary border-0 wpx-40 hpx-40 height-auto min-auto flex gap-2 roundrem-49 relative p-0 text-sm"
//                         type="button"
//                         onClick={() => {
//                           //   openModal();
//                         }}
//                       >
//                         <RiPencilLine className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover" />
//                       </button> */}
//                   </div>
//                 </div>
//               </Card.Body>
//             </Card>
//           </div>
//         </Col>
//         <Col xl={3}>
//           <div className="h-full">
//             <Card className="border-0 rounded-lg h-full">
//               <Card.Body>
//                 <div className="space-y-3">
//                   <div className="px-4 pt-4 flex justify-center">
//                     <BsQrCode className="w-full h-full max-w-xs" />
//                   </div>
//                   <div className="flex flex-col items-center">
//                     <span className="flex text-sm">Ticket Code</span>
//                     <span className="flex text-lg">12345678564</span>
//                   </div>
//                 </div>
//               </Card.Body>
//             </Card>
//           </div>
//         </Col>
//       </Row>
//     </div>
//   </React.Fragment>;
// }
