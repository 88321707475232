import React from 'react';
import { LogoGoTix, LogoGoTixN } from './CustomIcons';
import { PuffLoader } from 'react-spinners';
import { useSearchParams } from 'react-router-dom';
import { getDashboardLoader } from '../../features/loader/loader.slice';
import { useSelector } from 'react-redux';

export default function PageLoader({ text = '' }) {
  const [searchParams] = useSearchParams();

  // useEffect(() => {
  //   const interval = setInterval(() => {

  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <React.Fragment>
      <div
        className="pageLoader bg-color-base-1 fixed  inset-0 w-full h-full"
        style={{ zIndex: '9999999999999999999' }}
      >
        <div className="featuredBackgroundContainer h-screen w-screen flex items-center flex-col justify-center gap-2 px-3">
          <div className="w-full flex justify-center puff_darkText">
            <PuffLoader color="rgb(2 132 199)" width="200px" />
          </div>

          <LogoGoTix className="w-full h-20 text-sky-700 loader_darkText" />

          {searchParams?.get('home') && (
            <div className="w-full flex justify-center text-center">
              <h3 className="text-sm md:text-lg text-sky-700">
                {
                  'Please wait while we set up your dashboard with all the necessary tools and data.'
                }
              </h3>
            </div>
          )}
          {searchParams?.get('eo') && (
            <div className="w-full flex justify-center text-center">
              <h3 className="text-sm md:text-lg text-sky-700">
                {
                  'Please wait while we set up your dashboard with all the necessary tools and data...'
                }
              </h3>
            </div>
          )}
        </div>
      </div>
      {/* <PageDarkLoader /> */}
      {/* <StepsLoader /> */}
    </React.Fragment>
  );
}

export function PageLoader2({ darkTheme, removeText }) {
  let dashboardLoader = useSelector(getDashboardLoader);

  const twoColors = {
    '0%': 'rgb(2 132 199)',
    '100%': 'rgb(2 132 199)',
  };
  const twoColorsDark = {
    '0%': 'rgb(2 132 199)',
    '100%': 'rgb(2 132 199)',
  };

  return (
    <div>
      <div
        className={`pageLoader bg-color-base-1 fixed inset-0 w-full h-full`}
        style={{ zIndex: '9999999999999999999' }}
      >
        <div className="featuredBackgroundContainer h-screen w-screen flex items-center flex-col justify-center gap-2 px-3 relative">
          <div className="featuredBgImg js_featuredBackgroundImage z-0">
            <div className="absolute top-[10rem] left-[20rem]">
              <span className="span1 bg-rose-900 opacity-10 backdrop-blur-3xl absolute rounded-full w-[18rem] h-[18rem] lg:w-[25rem] lg:h-[25rem] bg-blend-multiply blur-3xl"></span>
              <span className="span2 bg-sky-600 opacity-10 backdrop-blur-3xl absolute rounded-full w-[18rem] h-[18rem] lg:w-[25rem] lg:h-[25rem] bg-blend-multiply mt-[10rem] ml-[10rem]"></span>
            </div>
            <div className="absolute top-[10rem] right-[50rem]">
              <span className="span1 bg-rose-900 opacity-10 backdrop-blur-3xl absolute rounded-full w-[18rem] h-[18rem] lg:w-[25rem] lg:h-[25rem] bg-blend-multiply"></span>
              <span className="span2 bg-sky-600 opacity-10 backdrop-blur-3xl absolute rounded-full w-[18rem] h-[18rem] lg:w-[25rem] lg:h-[25rem] bg-blend-multiply mt-[10rem] mr-[10rem]"></span>
            </div>
          </div>
          <div className="relative w-full">
            <LogoGoTixN className="w-full h-20 text-neutral-900 loader_darkText relative" />
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 72 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`absolute w-full top-[9px] h-[63px] flipLogo text-color-primary`}
            >
              <path
                d="M71.0188 32.1471C66.8384 29.3983 64.6931 25.4714 64.7332 20.7016C64.7632 16.9375 66.2269 13.4033 69.4348 11.1238C71.6904 9.51473 72.1015 7.75241 71.9812 5.35796C71.8007 1.96741 69.9461 0.0614303 66.3472 0.0326969C64.2319 0.0135413 62.1167 0.0997414 60.0114 0.00396327C58.8886 -0.0439258 58.6982 0.339187 58.6982 1.31612C58.7383 11.6314 58.7182 21.9563 58.7082 32.2812C58.7082 32.693 58.8185 33.124 58.5578 33.6221C58.227 33.3443 57.9363 33.1049 57.6556 32.8463C46.2372 22.1958 34.8188 11.5356 23.4005 0.885122C23.1198 0.616943 22.7889 0.396654 22.4782 0.147631C22.3178 0.118897 22.1674 0.0614301 22.007 0.0614301C16.393 0.0614301 10.7691 0.0614301 5.15513 0.0614301C4.58371 0.0614301 4.02231 0.128475 3.50102 0.36792C1.28551 1.04794 0.0825215 2.57082 0.0624717 4.75456C0.0524468 5.87516 0.102571 7.00534 0.0424219 8.12595C0.00232228 8.90175 0.26297 9.37106 0.954688 9.82122C8.53352 14.8017 9.45581 25.0308 2.5286 30.8062C0.393294 32.5877 -0.0879021 34.3117 0.012347 36.7061C0.152696 39.9243 2.10755 41.926 5.47592 41.9644C7.51098 41.9835 9.53601 41.8973 11.5711 41.9931C12.7239 42.0506 13.0648 41.7632 13.0648 40.6139C13.0147 31.0265 13.0347 21.4487 13.0447 11.8613C13.0447 11.459 12.9044 11.028 13.2252 10.53C15.0898 12.1391 16.9143 13.7194 18.7389 15.2997C28.7137 23.9676 38.6784 32.6356 48.6532 41.3035C49.0442 41.6387 49.385 41.9739 49.9965 41.9739C55.6005 41.9548 61.2044 42.0122 66.8083 41.9356C69.906 41.8973 71.921 39.7998 71.931 36.8498C71.931 35.8441 71.8809 34.8289 71.9511 33.8232C72.0012 33.0474 71.7205 32.5877 71.0288 32.1279L71.0188 32.1471Z"
                // fill="url(#paint0_linear_3494_486)"
                fill="currentColor"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3494_486"
                  x1="0.00232207"
                  y1="20.9985"
                  x2="71.9912"
                  y2="20.9985"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop />
                  <stop offset="0.39" />
                  <stop offset="0.79" />
                  <stop offset="1" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="flex w-full max-w-[250px] mt-3">
            <div className={`progressParent bg-gray-400 dark:bg-[#2e2c2c]`}>
              <div className="progressBar bg-sky-600">
                <div className="childLoad"></div>
              </div>
            </div>
          </div>
          {!removeText && (
            <div className="w-full flex justify-center text-center">
              <h3
                className={`text-sm lg:text-base text-sky-700 dark:text-whiteNew-0`}
              >
                {dashboardLoader?.loader
                  ? 'Please wait while we set up your dashboard with all the necessary tools and data.'
                  : darkTheme
                  ? 'Please wait! while we are taking to your dashboard.'
                  : 'Please wait while we set up your dashboard with all the necessary tools and data.'}
              </h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function PageLoaderSeatmap({ darkTheme, removeText, height, bgColor }) {
  let dashboardLoader = useSelector(getDashboardLoader);

  const twoColors = {
    '0%': 'rgb(2 132 199)',
    '100%': 'rgb(2 132 199)',
  };
  const twoColorsDark = {
    '0%': 'rgb(2 132 199)',
    '100%': 'rgb(2 132 199)',
  };

  return (
    <div
      className={`pageLoader ${
        darkTheme
          ? 'bg-color-base-1 bg-opacity-80 backdrop-blur-lg'
          : `bg-color-base-1 ${bgColor ? bgColor : ''}`
      } absolute inset-0 w-full h-full`}
      style={{ zIndex: '9999999999999999999' }}
    >
      <div
        className={`featuredBackgroundContainer flex items-center flex-col justify-center gap-2 px-3 relative ${
          height ? height : 'h-screen w-screen'
        }`}
      >
        {/* <div className="featuredBgImg js_featuredBackgroundImage z-0">
            <div className="absolute top-[10rem] left-[20rem]">
              <span className="span1 bg-rose-900 opacity-10 backdrop-blur-3xl absolute rounded-full w-[18rem] h-[18rem] lg:w-[25rem] lg:h-[25rem] bg-blend-multiply blur-3xl"></span>
              <span className="span2 bg-sky-600 opacity-10 backdrop-blur-3xl absolute rounded-full w-[18rem] h-[18rem] lg:w-[25rem] lg:h-[25rem] bg-blend-multiply mt-[10rem] ml-[10rem]"></span>
            </div>
            <div className="absolute top-[10rem] right-[50rem]">
              <span className="span1 bg-rose-900 opacity-10 backdrop-blur-3xl absolute rounded-full w-[18rem] h-[18rem] lg:w-[25rem] lg:h-[25rem] bg-blend-multiply"></span>
              <span className="span2 bg-sky-600 opacity-10 backdrop-blur-3xl absolute rounded-full w-[18rem] h-[18rem] lg:w-[25rem] lg:h-[25rem] bg-blend-multiply mt-[10rem] mr-[10rem]"></span>
            </div>
          </div> */}
        <div className="relative w-full">
          <LogoGoTixN className="w-full h-20 text-neutral-900 loader_darkText relative" />
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 72 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`absolute w-full top-[9px] h-[63px] flipLogo text-color-primary`}
          >
            <path
              d="M71.0188 32.1471C66.8384 29.3983 64.6931 25.4714 64.7332 20.7016C64.7632 16.9375 66.2269 13.4033 69.4348 11.1238C71.6904 9.51473 72.1015 7.75241 71.9812 5.35796C71.8007 1.96741 69.9461 0.0614303 66.3472 0.0326969C64.2319 0.0135413 62.1167 0.0997414 60.0114 0.00396327C58.8886 -0.0439258 58.6982 0.339187 58.6982 1.31612C58.7383 11.6314 58.7182 21.9563 58.7082 32.2812C58.7082 32.693 58.8185 33.124 58.5578 33.6221C58.227 33.3443 57.9363 33.1049 57.6556 32.8463C46.2372 22.1958 34.8188 11.5356 23.4005 0.885122C23.1198 0.616943 22.7889 0.396654 22.4782 0.147631C22.3178 0.118897 22.1674 0.0614301 22.007 0.0614301C16.393 0.0614301 10.7691 0.0614301 5.15513 0.0614301C4.58371 0.0614301 4.02231 0.128475 3.50102 0.36792C1.28551 1.04794 0.0825215 2.57082 0.0624717 4.75456C0.0524468 5.87516 0.102571 7.00534 0.0424219 8.12595C0.00232228 8.90175 0.26297 9.37106 0.954688 9.82122C8.53352 14.8017 9.45581 25.0308 2.5286 30.8062C0.393294 32.5877 -0.0879021 34.3117 0.012347 36.7061C0.152696 39.9243 2.10755 41.926 5.47592 41.9644C7.51098 41.9835 9.53601 41.8973 11.5711 41.9931C12.7239 42.0506 13.0648 41.7632 13.0648 40.6139C13.0147 31.0265 13.0347 21.4487 13.0447 11.8613C13.0447 11.459 12.9044 11.028 13.2252 10.53C15.0898 12.1391 16.9143 13.7194 18.7389 15.2997C28.7137 23.9676 38.6784 32.6356 48.6532 41.3035C49.0442 41.6387 49.385 41.9739 49.9965 41.9739C55.6005 41.9548 61.2044 42.0122 66.8083 41.9356C69.906 41.8973 71.921 39.7998 71.931 36.8498C71.931 35.8441 71.8809 34.8289 71.9511 33.8232C72.0012 33.0474 71.7205 32.5877 71.0288 32.1279L71.0188 32.1471Z"
              // fill="url(#paint0_linear_3494_486)"
              fill="currentColor"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3494_486"
                x1="0.00232207"
                y1="20.9985"
                x2="71.9912"
                y2="20.9985"
                gradientUnits="userSpaceOnUse"
              >
                <stop />
                <stop offset="0.39" />
                <stop offset="0.79" />
                <stop offset="1" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="flex w-full max-w-[250px] mt-3">
          <div className={`progressParent bg-gray-400 dark:bg-[#2e2c2c]`}>
            <div className="progressBar bg-sky-600">
              <div className="childLoad"></div>
            </div>
          </div>
        </div>
        {!removeText && (
          <div className="w-full flex justify-center text-center">
            <h3
              className={`text-sm lg:text-base text-sky-700 dark:text-whiteNew-0`}
            >
              {/* {dashboardLoader?.loader
                  ? 'Please wait while we set up your dashboard with all the necessary tools and data.'
                  : darkTheme
                  ? 'Please wait! while we are taking to your dashboard.'
                  : 'Please wait while we set up your dashboard with all the necessary tools and data.'} */}
              Please wait! While we setting up your seat selection..
            </h3>
          </div>
        )}
      </div>
    </div>
  );
}
