import { useQuery } from '@tanstack/react-query';
import {
  getEventAllDetail,
  getPriceSeatmapList,
  getSeatMappingDetails,
  getVenueDetailsById,
} from '../../../helpers/api/event/seat_mapping/seat_mapping';
import { getEventSeatDetails } from '../../../helpers/api/event/event';
import axios from 'axios';
import config from '../../../api/Config';

export function useEventAllDetails(slug, headers) {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['eventAllDetails', slug],
    queryFn: async () => {
      if (slug !== undefined && slug !== null && slug !== '') {
        return await getEventAllDetail(slug, headers);
      } else {
        return null;
      }
    },
    // suspense: true,
    // keepPreviousData: true,
  });
  return { data, refetch, isLoading };
}

export function useSeatMapPricing(id, page, headers) {
  const { data, refetch } = useQuery({
    queryKey: ['seatmap', id],
    queryFn: async () => {
      if (id !== undefined && id !== null && id !== '') {
        return await getPriceSeatmapList(id, page, headers);
      } else {
        return [];
      }
    },
    keepPreviousData: true,
    // suspense: true,
  });
  return { data, refetch };
}

export function useGetSeatMappingDetails(
  user_id,
  event_id,
  seatmap_id,
  headers,
) {
  const { data, refetch } = useQuery({
    queryKey: ['seatmap', user_id, event_id, seatmap_id],
    queryFn: async () => {
      if (
        user_id !== undefined &&
        user_id !== null &&
        user_id !== '' &&
        event_id !== undefined &&
        event_id !== null &&
        event_id !== '' &&
        seatmap_id !== undefined &&
        seatmap_id !== null &&
        seatmap_id !== ''
      ) {
        return await getSeatMappingDetails(
          user_id,
          event_id,
          seatmap_id,
          headers,
        );
      } else {
        return [];
      }
    },
    keepPreviousData: true,
    // suspense: true,
  });
  return { data, refetch };
}

export function useGetVenueDetails(id, headers) {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['venueDetails', id],
    queryFn: async () => {
      if (id !== undefined && id !== null && id !== '') {
        return await getVenueDetailsById(id, headers);
      } else {
        return null;
      }
    },
    // keepPreviousData: true,
  });

  return { data, refetch, isLoading };
}

export function useGetSeatDetails(id, headers) {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['seatMappingDetails', id],
    queryFn: async () => {
      if (id !== undefined && id !== null && id !== '') {
        return await getEventSeatDetails(headers, id);
      } else {
        return null;
      }
    },
    keepPreviousData: true,
    // suspense: true,
  });
  return { data, refetch, isLoading };
}

export async function getBookingStatus(body, headers) {
  // console.log(body);
  const res = await axios.post(
    config.baseUrl + config.endpoints.bookStatus,
    body,
    headers,
  );

  return res.data;
}

export async function getSeatConfirmation(body, headers) {
  // console.log(body);
  const res = await axios.post(
    config.baseUrl + config.endpoints.seatConfirm,
    body,
    headers,
  );

  return res.data;
}

export async function getVirtualStatus(body, headers) {
  // console.log(body);
  const res = await axios.post(
    config.baseUrl + config.endpoints.virtualStatus,
    body,
    headers,
  );

  return res.data;
}

export async function getVirtualConfirmation(body, headers) {
  // console.log(body);
  const res = await axios.post(
    config.baseUrl + config.endpoints.virtualConfirm,
    body,
    headers,
  );

  return res.data;
}
