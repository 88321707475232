import React, { useEffect } from 'react';
import { RadioGroup } from '@headlessui/react';
import { useWalletModal } from '../../contexts/WalletProvider';
import { MetamaskWalletAdapter } from '../../contexts/MetamaskProvider';
import { useWalletHooks } from '../../hooks/useWalletConnected';
import { Controller } from 'react-hook-form';
// import { ArtchiveETHAdapter } from '../../contexts/ArtchiveETHProvider';

export default function WalletSelectRadioGroup({ control }) {
  const { selectedWallet, setSelectedWallet } = useWalletModal();
  const { wallets } = useWalletModal();
  const { walletConnected, walletname } = useWalletHooks();

  const walletList = [
    ...wallets,
    new MetamaskWalletAdapter(),
    // new ArtchiveETHAdapter(),
  ];

  useEffect(() => {
    setSelectedWallet(null);
  }, [setSelectedWallet]);
  return (
    <div className="w-full pt-7 pb-3">
      <div className="mx-auto w-full max-w-lg">
        <Controller
          name="wallet"
          control={control}
          render={({ field: { onChange } }) => (
            <RadioGroup
              value={selectedWallet || walletname}
              onChange={(e) => {
                setSelectedWallet(e);
                onChange(e);
              }}
            >
              <RadioGroup.Label className="sr-only">option</RadioGroup.Label>
              <div className="space-y-4">
                {walletList.map((plan, idx) => (
                  <RadioGroup.Option
                    key={idx}
                    value={plan}
                    disabled={walletConnected}
                    className={({ active, checked }) =>
                      `${
                        selectedWallet?.name === plan?.name
                          ? 'ring-0 ring-white ring-opacity-60 ring-offset-0 border-1 border-sky-600'
                          : ' border-level-2'
                      }
                  ${
                    selectedWallet?.name === plan?.name
                      ? 'bg-transparent border-1 border-sky-600 bg-opacity-75'
                      : 'bg-color-base-1 border-level-2'
                  }
                    relative flex cursor-pointer rounded-lg px-5 py-3 focus:outline-none border-2`
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <div className="flex w-full items-center justify-between">
                          <div className="flex items-center">
                            <div className="text-sm">
                              <RadioGroup.Label
                                as="div"
                                className={`font-medium  ${
                                  checked ? 'text-white' : 'text-gray-900'
                                }`}
                              >
                                <React.Fragment>
                                  <div className="flex justify-center items-center gap-3">
                                    <div>
                                      {
                                        <img
                                          alt={plan?.name}
                                          src={plan?.icon}
                                          width={40}
                                          height={40}
                                        />
                                      }
                                    </div>
                                    <div>
                                      <span className="flex mb-0 text-color-primary text-lg 2xl:text-3xl">
                                        {plan?.name}
                                      </span>
                                    </div>
                                  </div>
                                </React.Fragment>
                              </RadioGroup.Label>
                            </div>
                          </div>
                          <div className="absolute top-1/2 -translate-y-1/2 right-2 shrink-0 text-white">
                            {selectedWallet?.name === plan?.name ? (
                              <span className="flex w-5 h-5 border-2 border-transparent rounded-full bgPrimary"></span>
                            ) : (
                              <span className="flex w-5 h-5 border-2 border-gray-400 rounded-full"></span>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          )}
        />
      </div>
    </div>
  );
}

// function CheckIcon(props) {
//   return (
//     <svg viewBox="0 0 24 24" fill="none" {...props}>
//       <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
//       <path
//         d="M7 13l3 3 7-7"
//         stroke="#fff"
//         strokeWidth={1.5}
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//     </svg>
//   );
// }
