import axios from 'axios';
import config from '../../../../api/Config';

export async function getTourLists(page, headers, limit, key, sDate, eDate) {
  console.log(limit, 'limit');
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.tour_list
    }?limit=${limit}&page=${page}&key=${key !== undefined ? key : ''}&sdate=${
      sDate !== undefined ? sDate : ''
    }&edate=${eDate !== undefined ? eDate : ''}`,
    headers,
  );

  return res.data;
}

export async function getTourList(page, limit, headers) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.tour_list
    }?limit=${limit}&page=${page}`,
    headers,
  );

  return res.data;
}

export async function createTours(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.create_Tour,
    body,
    headers,
  );

  return res.data;
}

export async function updateTours(body, headers) {
  const res = await axios.put(
    config.baseUrl + config.endpoints.update_tour,
    body,
    headers,
  );

  return res.data;
}

export async function getTourDetails(id) {
  if (id !== undefined) {
    const res = await axios.get(
      config.baseUrl + config.endpoints.get_Tour_details + id,
    );
    return res.data;
  } else {
    return null;
  }
}

export async function deleteTours(headers, payload) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.delete_tour,
    payload,
    headers,
  );
  return res.data;
}

export async function CheckTourName(headers, payload) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.tour_name,
    payload,
    headers,
  );
  return res.data;
}
export async function getTourArtistLists(id, headers) {
  const res = await axios.get(
    config.baseUrl + config.endpoints.artist_by_tour_id + `/${id}`,
    headers,
  );
  return res.data;
}

export async function getTourEventLists(
  page,
  tour_id,
  limit,
  key,
  sDate,
  eDate,
) {
  if (tour_id !== undefined) {
    const res = await axios.get(
      `${
        config.baseUrl + config.endpoints.tour_event_list
      }/${tour_id}?page=${page}&limit=${limit}&event_type=${''}&key=${
        key !== undefined ? key : ''
      }&sdate=${sDate !== undefined ? sDate : ''}&edate=${
        eDate !== undefined ? eDate : ''
      }`,
    );

    return res.data;
  } else {
    return null;
  }
}

export async function RemoveTourImage(headers, payload) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.remove_tour_IMG,
    payload,
    headers,
  );
  return res.data;
}
