import React from 'react';
import { Drawer as AntDrawer } from 'antd';
import { AiOutlineClose } from 'react-icons/ai';
import { afterBorderLeft, btnSecondayClass } from './DefaultClasses';

export default function DrawerCommon({
  title,
  placement,
  children,
  width,
  className,
  autoFocus,
  afterOpenChange,
  bodyStyle,
  closeIcon,
  contentWrapperStyle,
  destroyOnClose = false,
  extra,
  footer,
  footerStyle,
  forceRender,
  getContainer,
  headerStyle,
  height,
  keyboard,
  mask,
  maskClosable,
  maskStyle,
  push,
  rootClassName,
  rootStyle,
  style,
  size,
  open,
  setOpen,
  zIndex,
  onClose,
  subHeader,
  subHeadingPadding,
  subHeaderClass,
  padding,
}) {
  // //   const [open, setOpen] = useState(false);
  // if (open) {
  //   // $('html').removeProp('overflow');
  //   $('html, body').css('overflow', 'hidden');
  //   $('html, .sticky-header').css('width', 'calc(100% - 14px)');
  // } else {
  //   // $('html, .sticky-header').css('width', '100%');
  //   // $('html').css('overflow', 'auto');
  // }
  // function onCloseDrawer() {
  //   $('html, .sticky-header').css('width', '100%');
  //   $('html').css('overflow', 'auto');
  // }
  return (
    <React.Fragment>
      {/* <button type="primary" onClick={showDrawer}>
        Open
      </button> */}
      <AntDrawer
        title={
          <>
            <div
              className={`-ml-5 flex items-center gap-2 relative ${afterBorderLeft} after:bg-gray-200 min-h-[30px] after:min-h-[33px] after:top-0`}
            >
              <div className="w-full">{title}</div>
            </div>
          </>
        }
        styles={{
          header: {
            ...headerStyle,
            borderBottomWidth: !title && '0',
          },
          mask: maskStyle,
        }}
        destroyOnClose={destroyOnClose}
        placement={placement ? placement : 'right'}
        onClose={() => {
          onClose();
          // onCloseDrawer();
        }}
        open={open}
        width={width}
        height={height}
        className={`gotix-drawer ${className}`}
        rootClassName={rootClassName ? `${rootClassName} ` : ''}
        footer={footer ? <>{footer}</> : null}
        closeIcon={
          <div className="absolute top-[1px] -right-10 z-10 flex">
            <div
              type="button"
              onClick={onClose}
              className={`${btnSecondayClass} Icon w-[28px] h-[28px] height-auto min-auto flex gap-2 relative p-0 text-sm`}
            >
              <AiOutlineClose className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover h-3.5 w-3.5" />
            </div>
          </div>
        }
        zIndex={zIndex}
      >
        <div className="h-full">
          <div
            className={`${
              padding ? padding : 'p-[22px] h-full'
            } cstm__drawer__mod overflow-hidden`}
          >
            {children}
          </div>
        </div>
      </AntDrawer>
    </React.Fragment>
  );
}
