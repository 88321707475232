import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sample_ticket_type: [],
  user_ticket_type: [],
  selected_ticket_type: {},
  ticket_list_event: {}
};

const slice = createSlice({
  name: 'ticket_type',
  initialState,
  reducers: {
    updateSampleTicketType: (state, { payload }) => {
      state.sample_ticket_type = payload;
    },
    updateUserTicketType: (state, { payload }) => {
      state.user_ticket_type = payload;
    },
    update_selected_TicketType: (state, { payload }) => {
      state.selected_ticket_type = payload;
    },
    updateTicketListEvent: (state, { payload }) => {
      state.ticket_list_event = payload
    }
  },
});

let ticketTypeReducer = slice.reducer;

export const {
  updateSampleTicketType,
  updateUserTicketType,
  update_selected_TicketType,
  updateTicketListEvent
} = slice.actions;
const getAllTicketType = (state) => state.ticket_type;

export { ticketTypeReducer, getAllTicketType };
