import React from 'react';
import { Link } from 'react-router-dom';
import { MdInfoOutline } from 'react-icons/md';
import { portalTexts } from '../../../utils/LoaderMessage';

export default function ClaimNFTErrorScreen({ darkTheme }) {
  return (
    <React.Fragment>
      <div className="flex justify-center items-center flex-col text-center">
        <div className="text-7xl text-yellow-400 flex justify-center">
          <MdInfoOutline />
        </div>
        <h1 className="fw-medium text-lg 2xl:text-xl text-purple-500">
          {portalTexts?.nTkt} to be claimed is currently unavailable!
        </h1>

        <p className="text-gray-300 mt-3">
          The transfered {portalTexts?.nTkt} must have been canceled by the
          transferrer.
        </p>
      </div>
      <div className="space-y-4 max-w-screen-lg w-full py-3">
        <div className="mt-4">
          <div className="flex justify-center items-center flex-col gap-4 mt-4">
            <Link
              to={'/user/login'}
              // className="btn flex items-center justify-center btnPrimary rounded-xl w-full"
              className={`${
                darkTheme
                  ? 'bg-landingPurple-60 text-gray-50 hover:bg-landingPurple-70'
                  : 'bg-sky-600 text-gray-50 hover:bg-sky-700'
              } p-2.5 rounded-lg duration-200 ease-in w-full flex items-center justify-center md:w-2/3`}
            >
              Login/Signup
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <p className="text-xs text-gray-400">
          <i>To get your own {portalTexts?.nTkt} Ticket for this event.</i>
        </p>
      </div>

      {/* Add message according to the error mentioned in screenshot */}
    </React.Fragment>
  );
}
