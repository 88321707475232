import React, { useCallback, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { MyLink, truncate } from '../../utils/util';

import { useNavigate } from 'react-router-dom';
import { btnPrimaryClass } from '../common/DefaultClasses';
import {
  RiFacebookCircleLine,
  RiInstagramLine,
  RiLinkedinBoxLine,
  RiTwitterLine,
} from 'react-icons/ri';
import useDecodedToken from '../../hooks/useDecodedToken';
import { useArtistFollow } from '../../hooks/event/artist/artist';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import EventImageVideo from '../common/EventImageVideo';
import { Skeleton } from '../util/Skeleton';
export default function OrganiserCardV2({
  type,
  data,
  refetch = () => {},
  all_artist,
  from,
}) {
  const navigate = useNavigate();
  // const [organiserPublishedEvents, setOrganiserPublishedEvents] =
  //   React.useState([]);
  let decodedToken = useDecodedToken();
  // const { data: userDetails } = useSelector((state) => state.userDetails);
  // console.log(userDetails, 'data?.socialmedia_urls');
  // const [selectedUser, setSelectedUser] = React.useState({});
  const socialMediaUrls = data?.socialmedia_urls
    ? data?.socialmedia_urls
    : {
        instagram_url: '',
        facebook_url: '',
        twitter_url: '',
        telegram_url: '',
      };

  // const getOrganizerEvents = (id) => {
  //   let OrganiserPublishedEvents = [];
  //   if?.data?.explore) {
  //   ?.data?.explore?.map((item) => {
  //       if (item?.creator?._id === id) {
  //         OrganiserPublishedEvents.push(item);
  //       }
  //     });
  //     // setOrganiserPublishedEvents(OrganiserPublishedEvents);
  //   } else {
  //   ?.map((item) => {
  //       if (item?.creator?._id === id) {
  //         OrganiserPublishedEvents.push(item);
  //       }
  //     });
  //     // setOrganiserPublishedEvents(OrganiserPublishedEvents);
  //   }
  // };
  // const getOrganizerEvents = (id) => {
  //   let OrganiserPublishedEvents = [];
  //   if?.data?.explore) {
  //   ?.data?.explore?.map((item) => {
  //       if (item?.creator?._id === id) {
  //         OrganiserPublishedEvents.push(item);
  //       }
  //     });
  //     setOrganiserPublishedEvents(OrganiserPublishedEvents);
  //   } else {
  //   ?.data?.explore?.map((item) => {
  //       if (item?.creator?._id === id) {
  //         OrganiserPublishedEvents.push(item);
  //       }
  //     });
  //     setOrganiserPublishedEvents(OrganiserPublishedEvents);
  //   }
  // };

  // console.log('followers', data?.followers?.includes(decodedToken?.id));
  // console.log('decodedToken', decodedToken);
  const { FollowMutation } = useArtistFollow(all_artist, from);

  const onClick = useCallback(() => {
    // getOrganizerEvents(data?._id);
    // setSelectedUser(data);
    if (type === 'organiser') {
      navigate(`/organiser/${data?._id}`);
    } else {
      navigate(`/artist/${data?._id}`);
    }
  }, [type, data, navigate]);

  const profileImage = useMemo(() => {
    return data?.profile_image && data?.profile_image?.length > 0
      ? data?.profile_image
      : [data?.profile_image];
  }, [data]);

  return (
    <React.Fragment>
      {/* <Link to={'/'}> */}
      <div className="group">
        <div className="group-hover:-translate-y-2 group-hover:scale-[1.005] duration-300 ease-in-out">
          <Card className="rounded-[12px] overflow-hidden w-full h-full relative bg-color-base-1 border-1 border-level-4 shadow-md shadow-level-4 group-hover:duration-300 ease-in group-hover:shadow-level-4">
            <Card.Body className="p-0 h-full">
              <div className="relative w-full max-h-[250px]" onClick={onClick}>
                <div className="cardOverlay"></div>
                <div className="absolute inset-0 w-full h-full overflow-hidden rounded-md rounded-b-none">
                  {profileImage ? (
                    <Swiper
                      hashNavigation={{
                        watchState: true,
                      }}
                      // loopedSlides={6}
                      loop={profileImage?.length > 1 ? true : false}
                      centeredSlides={true}
                      // freeMode={true}
                      allowTouchMove={true}
                      pagination={{
                        dynamicBullets: true,
                        clickable: true,
                      }}
                      // allowSlideNext={true}
                      // allowSlidePrev={true}
                      // lazy={true}
                      slidesPerView={1}
                      // freeMode={true}
                      // effect="fade"
                      spaceBetween={10}
                      //   touchStartPreventDefault={false}
                      // pagination={{ clickable: true }}
                      // effect="fade"
                      grabCursor={true}
                      // sticky={true}

                      // onSwiper={(s) => {
                      //   setSwiper(s);
                      // }}
                      // onBeforeInit={(swiper) => {
                      //   navigationPrevRef.current =
                      //     swiper.params.navigation.prevEl;
                      //   navigationNextRef.current =
                      //     swiper.params.navigation.nextEl;
                      // }}
                      autoplay={
                        profileImage?.length > 1
                          ? {
                              delay: 5000,
                              disableOnInteraction: false,
                            }
                          : false
                      }
                      modules={[Autoplay, Pagination, Navigation]}
                      className="card_swiper h-full w-full"
                      // className="items-center"
                    >
                      {profileImage ? (
                        <>
                          {profileImage?.map((image, index) => (
                            <SwiperSlide
                              className="relative h-full w-full"
                              key={index}
                            >
                              <div className="relative w-full h-full">
                                <div className="w-full h-0 pb-[52%]"></div>
                                <div className="absolute inset-0 w-full h-full overflow-hidden rounded-md rounded-b-none object-cover">
                                  <EventImageVideo
                                    data={image}
                                    alt={'Profile'}
                                    className={'w-full h-full object-cover'}
                                    loading=""
                                    // width="384px"
                                    // height="512px"
                                  />
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </>
                      ) : (
                        <>
                          {[...Array(2)].map((_, idx) => (
                            <SwiperSlide
                              className="relative pointer-events-none"
                              key={idx}
                            >
                              <Skeleton />
                            </SwiperSlide>
                          ))}
                        </>
                      )}
                    </Swiper>
                  ) : null}
                </div>
                {/* <div className="absolute inset-0 w-full h-full overflow-hidden rounded-md rounded-b-none">
                  <img
                    src={
                      data?.image?.url || data?.profile_image?.url || profileUrl
                    }
                    alt="event-img"
                    className={`w-full h-full object-contain`}
                  />
                </div> */}
              </div>

              {/* <div className="overlay2 bg-neutral-800 backdrop-blur-lg bg-opacity-30">
            <div className="h-full w-full relative">
              <div className="absolute w-full h-full flex justify-center items-center">
                <div className="flex flex-col justify-center items-center -mt-3 space-y-2 w-full max-w-[80%] text-center">
                  <span
                    className="flex text-white text-lg md:text-lg 2xl:text-lg -mt-2 capitalize cursor-pointer leading-1"
                    style={{ lineHeight: '1' }}
                    onClick={onClick}
                  >
                    {truncate(data?.fullname) ||
                      truncate(data?.username) ||
                      truncate(
                        data?.name?.split('@')?.length > 1
                          ? data?.name?.split('@')[1]
                          : data?.name?.split('@')[0],
                      ) ||
                      '-'}
                  </span>
                  <div className="flex justify-center w-full gap-2">
                    <span className="flex">
                      <Link
                        to={`${
                          socialMediaUrls?.facebook_url
                            ? socialMediaUrls?.facebook_url
                            : '#'
                        }`}
                        target={socialMediaUrls?.facebook_url ? '_blank' : ''}
                        rel="noopener noreferrer"
                        className="btn flex gap-2 relative p-0 w-8 h-8 rounded-full border-0"
                      >
                        <FacebookIcon className="absolute top-1/2 left-1/2 translate-neg-50-50 w-full h-full object-cover" />
                      </Link>
                    </span>
                    <span className="flex">
                      <Link
                        to={`${
                          socialMediaUrls?.instagram_url
                            ? socialMediaUrls?.instagram_url
                            : '#'
                        }`}
                        target={socialMediaUrls?.instagram_url ? '_blank' : ''}
                        rel="noopener noreferrer"
                        className="btn flex gap-2 relative p-0 w-8 h-8 rounded-full border-0"
                      >
                        <InstagramIcon className="absolute top-1/2 left-1/2 translate-neg-50-50 w-full h-full object-cover" />
                      </Link>
                    </span>
                    <span className="flex">
                      <Link
                        to={`${
                          socialMediaUrls?.twitter_url
                            ? socialMediaUrls?.twitter_url
                            : '#'
                        }`}
                        target={socialMediaUrls?.twitter_url ? '_blank' : ''}
                        rel="noopener noreferrer"
                        className="btn flex gap-2 relative p-0 w-8 h-8 rounded-full border-0"
                      >
                        <TwitterIcon className="absolute top-1/2 left-1/2 translate-neg-50-50 w-full h-full object-cover" />
                      </Link>
                    </span>
                    <span className="flex">
                      <Link
                        to={`${
                          socialMediaUrls?.telegram_url
                            ? socialMediaUrls?.telegram_url
                            : '#'
                        }`}
                        target={socialMediaUrls?.telegram_url ? '_blank' : ''}
                        rel="noopener noreferrer"
                        className="btn flex gap-2 relative p-0 w-8 h-8 rounded-full border-0"
                      >
                        <TelegramIcon className="absolute top-1/2 left-1/2 translate-neg-50-50 w-full h-full object-cover" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
              <div className="dark:bg-color-base-3 bg-color-base-1 space-y-3.5 relative max-h-[18rem] flex flex-col justify-between">
                <div className="px-3 pt-3">
                  <div className="flex gap-2 items-center justify-between">
                    {/* <div>
                  <span className="text-sm text-white">
                    <HomeIcon />
                  </span>
                </div> */}
                    <div>
                      <h6 className="hover:text-sky-600 duration-150 ease-in cursor-pointer text-sm dark:text-sky-50 leading-tight">
                        <span
                          className="flex text-zinc-900 dark:text-white text-lg md:text-lg 2xl:text-lg -mt-2 capitalize cursor-pointer leading-1"
                          style={{ lineHeight: '1' }}
                          onClick={onClick}
                        >
                          {/* <Tooltip
                      placement={'top'}
                      title={
                        data?.fullname
                          ? data?.fullname
                          : data?.username
                          ? data?.username
                          : '-'
                      }
                    > */}
                          {truncate(data?.fullname) ||
                            truncate(data?.username) ||
                            truncate(
                              data?.name?.split('@')?.length > 1
                                ? data?.name?.split('@')[1]
                                : data?.name?.split('@')[0],
                            ) ||
                            '-'}
                          {/* </Tooltip> */}
                        </span>
                      </h6>
                    </div>
                    {/* <div>
                  <HeartIcon className={'text-whiteNew-0 text-lg'} />
                </div> */}
                  </div>
                  <div className="flex gap-1.5 items-center mt-1.5">
                    {/* <span className="text-amber-400 leading-none flex -mt-0.5 text-lg">
                  <MdStarRate />
                </span> */}
                    <span className="text-zinc-900 dark:text-whiteNew-0 font-medium min-h-[40px]">
                      {truncate(data?.description, 40)}
                    </span>
                    {/* <span className="text-whiteNew-0 text-xs font-light">
                  People have shown interested recently
                </span> */}
                  </div>
                  <div className="flex items-center gap-2 mt-4 min-h-[18px]">
                    {decodedToken && (
                      <>
                        <div className="flex">
                          {data?.followers &&
                          data?.followers?.includes(decodedToken?.id) ? (
                            <button
                              className={`dark:bg-landingPurple-60 bg-sky-600 dark:hover:bg-landingPurple-70 hover:bg-sky-700 text-whiteNew-0 rounded-[4px] duration-200 ease-in text-xs flex justify-end items-center px-1 py-0`}
                              onClick={() => {
                                FollowMutation.mutate({
                                  method: 'unfollow',
                                  artistID: data?._id,
                                });
                              }}
                            >
                              UNFOLLOW
                            </button>
                          ) : (
                            <button
                              className={`dark:bg-landingPurple-60 bg-sky-600 dark:hover:bg-landingPurple-70 hover:bg-sky-600 text-whiteNew-0 rounded-[4px] duration-200 ease-in text-xs flex justify-end items-center px-1 py-0`}
                              onClick={() => {
                                FollowMutation.mutate({
                                  method: 'follow',
                                  artistID: data?._id,
                                });
                              }}
                            >
                              FOLLOW
                            </button>
                          )}
                        </div>

                        <div className="flex">
                          {(socialMediaUrls?.telegram_url ||
                            socialMediaUrls?.facebook_url ||
                            socialMediaUrls?.instagram_url ||
                            socialMediaUrls?.twitter_url) && (
                            <span className="flex h-[13px] w-[1px] bg-whiteNew-0"></span>
                          )}
                        </div>
                      </>
                    )}
                    <div className="flex text-zinc-900 dark:text-whiteNew-0 gap-2 items-center text-lg">
                      {socialMediaUrls?.facebook_url && (
                        <MyLink
                          className="hover:text-sky-500"
                          to={`${
                            socialMediaUrls?.facebook_url
                              ? socialMediaUrls?.facebook_url
                              : '#'
                          }`}
                          target={socialMediaUrls?.facebook_url ? '_blank' : ''}
                          rel="noopener noreferrer"
                        >
                          <RiFacebookCircleLine />
                        </MyLink>
                      )}
                      {socialMediaUrls?.instagram_url && (
                        <MyLink
                          className="hover:text-sky-500"
                          to={`${
                            socialMediaUrls?.instagram_url
                              ? socialMediaUrls?.instagram_url
                              : '#'
                          }`}
                          target={
                            socialMediaUrls?.instagram_url ? '_blank' : ''
                          }
                          rel="noopener noreferrer"
                        >
                          <RiInstagramLine />
                        </MyLink>
                      )}
                      {socialMediaUrls?.twitter_url && (
                        <MyLink
                          className="hover:text-sky-500"
                          to={`${
                            socialMediaUrls?.twitter_url
                              ? socialMediaUrls?.twitter_url
                              : '#'
                          }`}
                          target={socialMediaUrls?.twitter_url ? '_blank' : ''}
                          rel="noopener noreferrer"
                        >
                          <RiTwitterLine />
                        </MyLink>
                      )}
                      {socialMediaUrls?.telegram_url && (
                        <MyLink
                          className="hover:text-sky-500"
                          to={`${
                            socialMediaUrls?.telegram_url
                              ? socialMediaUrls?.telegram_url
                              : '#'
                          }`}
                          target={socialMediaUrls?.telegram_url ? '_blank' : ''}
                          rel="noopener noreferrer"
                        >
                          <RiLinkedinBoxLine />
                        </MyLink>
                      )}
                    </div>
                  </div>
                </div>
                <div className="px-3.5 pb-3.5">
                  <button
                    onClick={onClick}
                    // className={`${primaryBtnClassGradient} py-1.5 text-gray-50 rounded-[5px] duration-200 ease-in w-full flex justify-end items-center`}
                    className={`${btnPrimaryClass} w-full`}
                  >
                    VIEW {data?.artist_type === 'team' ? 'TEAM' : 'ARTIST'}
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* <EventOrganiserDetailModal
        OrganiserPublishedEvents={organiserPublishedEvents}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedUser={selectedUser}
      /> */}
      {/* </Link> */}
    </React.Fragment>
  );
}
