import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  page: {
    ongoing: 1,
    trending: 1,
    recent: 1,
    explore: 1,
    venueType: 1,
    artist: 1,
  },
  limit: {
    ongoing: 6,
    trending: 6,
    recent: 6,
    explore: 6,
    venueType: 10,
    artist: 1,
  },
};

const Publishedevents = {
  ongoing: { data: [], totalCount: 0 },
  trending: { data: [], totalCount: 0 },
  recent: { data: [], totalCount: 0 },
  explore: { data: [], totalCount: 0 },
  banner: { data: [], totalCount: 0 },
  venueType: { data: [], totalCount: 0 },
  artist: { data: [], totalCount: 0 },
};

const bannerEvents = {
  banner: { data: [], totalCount: 0 },
};

const userCurrentLocation = {
  currentState: localStorage.getItem('CurrentLocation')
    ? JSON.parse(localStorage.getItem('CurrentLocation'))
    : '',
};

const popularStates = {
  popular_states: [],
};

const cartDrawer = {
  state: false,
};

const cartTimer = {
  timeLeft: 0,
};

const slice = createSlice({
  name: 'eventLimit&Pages',
  initialState,
  reducers: {
    AddPage: (state, { payload }) => {
      state.page = payload.page;
    },
    AddLimit: (state, { payload }) => {
      state.limit = payload.limit;
    },
    resetPageLimit: (state, { payload }) => {
      state.page = {
        ongoing: 1,
        trending: 1,
        recent: 1,
        explore: 1,
        artist: 1,
      };
      state.limit = {
        ongoing: 6,
        trending: 6,
        recent: 6,
        explore: 6,
        artist: 6,
      };
    },
  },
});

const PublishedEventslice = createSlice({
  name: 'PublishedEvent',
  initialState: Publishedevents,
  reducers: {
    addEvents: (state, { payload }) => {
      state.ongoing = payload.ongoing;
      state.trending = payload.trending;
      state.recent = payload.recent;
      state.explore = payload.explore;
      state.banner = payload.banner;
      state.artist = payload.artist;
    },
    addVenueType: (state, { payload }) => {
      state.venueType = payload.venueType;
    },
    resetOngoingEvents: (state, { payload }) => {
      state.ongoing = { data: [], totalCount: 0 };
    },
    resetTrendingEvents: (state, { payload }) => {
      state.trending = { data: [], totalCount: 0 };
    },
    resetRecentEvents: (state, { payload }) => {
      state.recent = { data: [], totalCount: 0 };
    },
    resetExploreEvents: (state, { payload }) => {
      state.explore = { data: [], totalCount: 0 };
    },
    resetBannerEvents: (state, { payload }) => {
      state.banner = { data: [], totalCount: 0 };
    },
    resetArtistEvents: (state, { payload }) => {
      state.artist = { data: [], totalCount: 0 };
    },
    resetEvents: (state, { payload }) => {
      state.ongoing = { data: [], totalCount: 0 };
      state.trending = { data: [], totalCount: 0 };
      state.recent = { data: [], totalCount: 0 };
      state.explore = { data: [], totalCount: 0 };
      state.banner = { data: [], totalCount: 0 };
      state.artist = { data: [], totalCount: 0 };
    },
  },
});

const CurrentLocationslice = createSlice({
  name: 'CurrentLocation',
  initialState: userCurrentLocation,
  reducers: {
    updateLocation: (state, { payload }) => {
      state.currentState = payload;
    },
  },
});

const Bannerslice = createSlice({
  name: 'CurrentLocation',
  initialState: bannerEvents,
  reducers: {
    updateBannerEvents: (state, { payload }) => {
      state.banner = payload;
    },
  },
});

const PopularStatesSlice = createSlice({
  name: 'PopularStates',
  initialState: popularStates,
  reducers: {
    updatePopularStates: (state, { payload }) => {
      state.popular_states = payload;
    },
  },
});

const CartDrawerSlice = createSlice({
  name: 'cartDrawer',
  initialState: cartDrawer,
  reducers: {
    updateCartDrawer: (state, { payload }) => {
      state.state = payload;
    },
  },
});

const CartTimerSlice = createSlice({
  name: 'cartTimer',
  initialState: cartTimer,
  reducers: {
    updateTimer: (state, { payload }) => {
      state.timeLeft = payload;
    },
  },
});

let homePageEventsReducer = slice.reducer;
let PublishedEventsReducer = PublishedEventslice.reducer;
let UserCurrentLocationReducer = CurrentLocationslice.reducer;
let PopularStatesReducer = PopularStatesSlice.reducer;
let CartDrawerReducer = CartDrawerSlice.reducer;
let CartTimerReducer = CartTimerSlice.reducer;
let bannerSliceReducer = Bannerslice.reducer;

export const { AddPage, AddLimit, resetPageLimit } = slice.actions;
export const {
  addEvents,
  addVenueType,
  resetEvents,
  resetOngoingEvents,
  resetTrendingEvents,
  resetBannerEvents,
  resetExploreEvents,
  resetRecentEvents,
  resetArtistEvents,
} = PublishedEventslice.actions;
export const { updateLocation } = CurrentLocationslice.actions;
export const { updateBannerEvents } = Bannerslice.actions;
export const { updatePopularStates } = PopularStatesSlice.actions;
export const { updateCartDrawer } = CartDrawerSlice.actions;
export const { updateTimer } = CartTimerSlice.actions;

export {
  homePageEventsReducer,
  PublishedEventsReducer,
  UserCurrentLocationReducer,
  PopularStatesReducer,
  CartDrawerReducer,
  CartTimerReducer,
  bannerSliceReducer,
};
