import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { Card, Form } from 'react-bootstrap';
import { AdvanceSearchIcon, CustomSearchIcon } from '../../../util/CustomIcons';
import Button from '../../../common/Button';
import { MdOutlineArrowRight } from 'react-icons/md';
import { primaryBtnClassGradient } from '../../../common/DefaultClasses';
import { Transition } from '@headlessui/react';
import HeaderAdvanceSelectBox from './../HeaderAdvanceSelectBox';
import { useNavigate } from 'react-router-dom';
import SearchBoxHeader from '../../../util/SearchBoxHeader';
import { useGetLocation } from '../../../../hooks/event/venues/venues';
import { useApiHeader } from '../../../../hooks/useDecodedToken';
import moment from 'moment';
import { addDays } from 'date-fns';
import PopularSearchCard from './PopularSearchCard';
import HeaderTabSearch from './HeaderTabSearch';
import { getTrendingEvents } from '../../../../helpers/api/header';
import { useMutation, useQuery } from '@tanstack/react-query';
import useLodashDebounce from '../../../../hooks/useLodashDebounce';
import { getsSearchResults } from '../../../../helpers/api/event/event';

function SearchBarHeader() {
  const [openAdvanceSearch, setOpenAdvanceSearch] = useState(false);
  const [openInputSearch, setOpenInputSearch] = useState(false);
  const [selectedValueCity, setSelectedValueCity] = React.useState();
  const [dateRange, setDateRange] = React.useState([]);
  const [dateRangeWeekend, setDateRangeWeekend] = React.useState([]);
  const [openRecentTab, setOpenRecentTab] = useState(false);
  const [eventDataSearchPage, setEventDataSearchPage] = useState([]);
  const [artistsTeamsData, setArtistsTeamsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const headerDropDownRef = useRef(null);
  const cardRef = useRef(null);
  const [noEventCaption, setNoEventCaption] = useState(false);
  const [noOrganiserCaption, setNoOrganiserCaption] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedDateOptions, setSelectedDateOptions] = useState({
    _id: 'All Dates',
  });
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);

  let dateOptions = [
    { _id: 'All Dates' },
    { _id: 'This Weekend' },
    { _id: 'Date Range' },
  ];

  let { json_header } = useApiHeader();

  const { data: location, refetch: refetchLocation } =
    useGetLocation(json_header);

  let navigate = useNavigate();

  const add_params = useMemo(
    () => ({
      key: inputValue ? inputValue : '',
      city: selectedValueCity
        ? `${selectedValueCity?._id?.split(',')[0]},${
            selectedValueCity?._id?.split(',')[1]
          },${selectedValueCity?._id?.split(',')[2]}`
        : '',
      sdate:
        dateRange?.length > 0
          ? dateRange[0]
          : dateRangeWeekend?.length > 0
          ? dateRangeWeekend[0]
          : '',
      edate:
        dateRange?.length > 0
          ? dateRange[1]
          : dateRangeWeekend?.length > 0
          ? dateRangeWeekend[1]
          : '',
    }),
    [inputValue, selectedValueCity, dateRange, dateRangeWeekend],
  );

  const navigaeToPage = useCallback(() => {
    let new_params = new URLSearchParams([
      ...Object.entries(add_params), // [["c","a"],["d","2"],["e","false"]]
    ]);
    let keysForDel = [];
    new_params.forEach((value, key) => {
      if (value === '') {
        keysForDel.push(key);
      }
    });

    keysForDel.forEach((key) => {
      new_params.delete(key);
    });
    navigate(`/search?${new_params.toString()}`);
  }, [add_params]);

  const resetBack = useCallback(() => {
    setDateRangeWeekend([]);
    setDateRange([]);
  }, []);

  const setWeekend = useCallback(() => {
    setDateRange([]);
    const from_date = moment().day(6).format('YYYY-MM-DD');
    const to_date = moment().day(7).format('YYYY-MM-DD');
    setDateRangeWeekend([from_date, to_date]);
  }, []);

  const onChangeRangepicker = useCallback((item) => {
    setState([item.selection]);
    setDateRange([
      moment(item.selection.startDate)?.format('YYYY-MM-DD'),
      moment(item.selection.endDate)?.format('YYYY-MM-DD'),
    ]);
  }, []);

  const { data: trendingData } = useQuery({
    queryKey: ['trendingEvents'],
    queryFn: async () => await getTrendingEvents(),
  });

  useEffect(() => {
    const handleClick = (event) => {
      if (
        !headerDropDownRef.current.contains(event.target) &&
        !cardRef.current.contains(event.target)
      ) {
        setOpenInputSearch(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const searchResults = useCallback(() => {
    let new_params = new URLSearchParams([
      ...Object.entries(add_params), // [["c","a"],["d","2"],["e","false"]]
    ]);
    let keysForDel = [];
    new_params.forEach((value, key) => {
      if (value === '') {
        keysForDel.push(key);
      }
    });

    keysForDel.forEach((key) => {
      new_params.delete(key);
    });

    return getsSearchResults(new_params.toString());
  }, [inputValue]);

  const mutation = useMutation(searchResults, {
    onSuccess: async (res) => {
      setLoading(false);

      if (res?.eventData.length > 0) {
        setNoEventCaption(false);
        setEventDataSearchPage(res?.eventData);
      } else {
        setNoEventCaption(true);
        setEventDataSearchPage([]);
      }

      if (res?.artistTeamData.length > 0) {
        setNoOrganiserCaption(false);
        setArtistsTeamsData(res?.artistTeamData);
      } else {
        setNoOrganiserCaption(true);
        setArtistsTeamsData([]);
      }
    },
    onError: (err) => {
      // message.error('Error while deleting events details');
    },
  });

  const onChange = useCallback(() => {
    mutation.mutate();
  }, []);

  // Debounce the search function with a delay of 1000 milliseconds
  const { debouncedSearch } = useLodashDebounce(onChange, 1000);

  const OnchangeText = useCallback((e) => {
    debouncedSearch(e);
  }, []);

  useEffect(() => {
    if (inputValue !== '') {
      setLoading(true);
      OnchangeText();
    }

    if (inputValue === '') {
      setOpenRecentTab(true);
    }
  }, [inputValue]);

  return (
    <>
      <div className="w-full relative">
        <Form
          className="w-full"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Form.Group className="relative w-full" controlId="">
            <Form.Control
              type="text"
              ref={headerDropDownRef}
              value={inputValue}
              onChange={(e) => {
                setOpenRecentTab(false);
                setInputValue(e.target.value);
              }}
              onFocus={(e) => {
                setOpenInputSearch(true);
                inputValue !== ''
                  ? setOpenRecentTab(false)
                  : setOpenRecentTab(true);
              }}
              placeholder="Search for Events & Artist/Teams"
              className="w-full py-2.5 leading-tight bg-color-base-1 dark:bg-neutral-950 bg-opacity-40 dark:focus:bg-neutral-800 backdrop-blur-xl focus:outline-none focus:shadow-md font-normal text-neutral-900 dark:text-white pl-2.5 pr-8 placeholder:text-gray-400 duration-0 rounded-lg border-1 border-gray-200 dark:border-zinc-800 focus:border-gray-300 border-l-0"
            />
            <div className="flex absolute inset-y-0 right-2">
              <button
                className="p-1.5 dark:text-whiteNew-0 text-gray-500 h-full rounded-md flex justify-center items-center text-base"
                type="button"
              >
                <CustomSearchIcon />
              </button>
            </div>
          </Form.Group>
        </Form>
        <div
          className="w-full absolute top-10 left-0 z-20 mt-1.5"
          ref={cardRef}
        >
          <Transition
            show={openInputSearch}
            as={'div'}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Card className="w-full bg-color-base-1 dark:bg-color-base-2 backdrop-blur-3xl border-level-1/40 rounded-b-lg h-full max-h-[450px] overflow-hidden">
              <Card.Body className="p-0">
                <div className="min-h-[100px] max-h-[450px] overflow-y-auto overflow-x-hidden scrollCss space-y-2 h-full flex flex-col justify-between">
                  {openRecentTab ? (
                    <PopularSearchCard
                      trendingData={trendingData}
                      setOpenInputSearch={setOpenInputSearch}
                      setInputValue={setInputValue}
                      inputValue={inputValue}
                    />
                  ) : (
                    <HeaderTabSearch
                      eventDataSearchPage={eventDataSearchPage}
                      artistsTeamsData={artistsTeamsData}
                      setOpenInputSearch={setOpenInputSearch}
                      setInputValue={setInputValue}
                      noEventCaption={noEventCaption}
                      noOrganiserCaption={noOrganiserCaption}
                      loading={loading}
                      inputValue={inputValue}
                    />
                  )}
                </div>
              </Card.Body>
            </Card>
          </Transition>
        </div>
      </div>
    </>
  );
}
export default React.memo(SearchBarHeader);
