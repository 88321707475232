import React from 'react';
import { Form } from 'react-bootstrap';

export default function NotificationCheckBox({ title, active, textSize }) {
  return (
    <React.Fragment>
      <div className="flex justify-between md:max-w-xl gap-5">
        <div>
          <h5
            className={` ${active ? 'font-medium' : 'font-normal'} ${
              textSize ? '' : ''
            }`}
          >
            {title}
          </h5>
        </div>
        <div className="">
          <div className="flex lg:gap-9 w-full max-w-md">
            <Form.Check
              type={'checkbox'}
              bsPrefix="formCheck flex items-center gap-2"
              label={<span className="">Email</span>}
            />
            <Form.Check
              type={'checkbox'}
              bsPrefix="formCheck flex items-center gap-2"
              label={<span className="">Phone</span>}
            />
            <Form.Check
              type={'checkbox'}
              bsPrefix="formCheck flex items-center gap-2"
              label={<span className="">Push</span>}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
