import React from 'react';
import { Card } from 'react-bootstrap';
// import { AiOutlineDelete } from 'react-icons/ai';
import TitleImg from '../../TitleImg';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteSeats } from '../../../../features/seatmap/seatmap.slice';
import { useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useCallback } from 'react';
import { truncate } from '../../../../utils/util';
import Tooltip from '../../Tooltip';
import { afterBorder } from '../../DefaultClasses';

export default function SeatCartItem({ data }) {
  // from redux we are getting all the selected seats by the user.

  let selectedSeats = useSelector((state) => state.selectedSeats);
  let dispatch = useDispatch();
  //commented by surya
  // useEffect(() => {
  //   // console.log('outside', selectedSeats);
  //   if (document.getElementById('buyersmap')?.innerHTML) {
  //     // console.log('comesInside');
  //     if (selectedSeats?.data?.length > 0) {
  //       console.log('hihuihih');
  //       window.seatSelections(selectedSeats?.data);
  //     } else {
  //       window.seatSelections([]);
  //     }
  //   }
  // }, [selectedSeats?.data, document.getElementById('buyersmap')?.innerHTML]);

  //added useCallback hook for onchange and onClick functions for optimisation.

  const removeSeat = useCallback(() => {
    let filter = selectedSeats?.data?.filter((item) => item.id !== data?.id);
    //added by surya
    window.removeSelections([data]);
    //----------------------
    dispatch(DeleteSeats(filter));
  }, [selectedSeats]);

  return (
    <React.Fragment>
      <Card
        className={`dark:bg-[#2d2d2f] bg-color-base-1 dark:bg-opacity-100 bg-opacity-75 dark:text-whiteNew-0 text-zinc-800 shadow-md relative border-1 dark:border-zinc-700 border-[#ddd]`}
      >
        <Card.Body className="p-0">
          <div className="flex items-center flex-col justify-between flex-wrap gap-2 w-full">
            <div
              className={`flex flex-col space-y-2 w-full relative after:absolute after:content-[''] after:bottom-0 after:left-0 after:h-1 after:w-full after:flex after:z-10 p-2.5 dark:after:bg-zinc-700 after:bg-[#ddd]`}
            >
              {data?.resale_price !== null &&
                data?.resale_price &&
                data?.resale_price !== '' && (
                  <div>
                    <span className="bg-resale-900 rounded-2xl text-resale-800 px-2.5 text-xs">
                      On Resale
                    </span>
                  </div>
                )}
              <div className="flex justify-between items-center">
                <h6 className="text-sm 2xl:text-lg font-medium">
                  {data?.category}
                </h6>
                <div className="flex justify-end">
                  <span
                    type="button"
                    className="flex bg-component-gray-2 dark:bg-color-base-2 dark:hover:bg-opacity-60 text-color-primary duration-300 rounded-md py-0.5 px-2 justify-center items-center text-xs"
                    onClick={removeSeat}
                  >
                    Remove
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center w-full p-2 pb-3">
              <div className="flex gap-2">
                <div className="flex justify-center shrink-0">
                  {/* <Card className="bg-landingPurple-60 p-2">
                      <Card.Body className="p-0 flex justify-center">
                        <TitleImg
                          title={<span className="text-white">Section</span>}
                          subTitle={
                            <span className="flex text-base 2xl:text-base text-white">
                              <Tooltip title={data?.section}>
                                {truncate(data?.section, 5)}
                              </Tooltip>
                            </span>
                          }
                        />
                      </Card.Body>
                    </Card> */}
                  <div className="flex items-center gap-1">
                    <span className="dark:text-gray-300 text-zinc-700">
                      Section:
                    </span>
                    <Tooltip title={data?.section}>
                      <span className="font-medium">
                        {truncate(data?.section, 5)}
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <div className="flex justify-center shrink-0">
                  {/* <Card className="bg-landingPurple-60 p-2">
                      <Card.Body className="p-0 flex justify-center">
                        <TitleImg
                          title={<span className="text-white">Row No.</span>}
                          subTitle={
                            <span className="flex text-white text-sm 2xl:text-base">
                              {data?.row}
                            </span>
                          }
                        />
                      </Card.Body>
                    </Card> */}
                  <div className="flex items-center gap-1">
                    <span className="dark:text-gray-300 text-zinc-700">
                      Row No.:
                    </span>
                    <span className="font-medium">{data?.row}</span>
                  </div>
                </div>

                <div className="flex justify-center shrink-0">
                  {/* <Card className="bg-landingPurple-60 p-2">
                      <Card.Body className="p-0 flex justify-center">
                        <TitleImg
                          title={<span className="text-white">Seat No.</span>}
                          subTitle={
                            <span className="flex text-white text-sm 2xl:text-base">
                              {data?.seat_number}
                            </span>
                          }
                        />
                      </Card.Body>
                    </Card> */}
                  <div className="flex items-center gap-1 shrink-0">
                    <span className="dark:text-gray-300 text-zinc-700">
                      Seat No.
                    </span>
                    <span>{data?.seat_number}</span>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end text-right leading-none">
                <span className="text-lg font-medium flex">
                  <CurrencyFormat
                    disabled={true}
                    displayType="text"
                    thousandSeparator
                    prefix="$"
                    value={
                      Number(
                        data?.resale_price
                          ? parseFloat(
                              data?.resale_price?.toString()?.replace(/,/g, ''),
                            )
                          : data.price,
                      )?.toFixed(2) || 0
                    }
                    decimalScale={2}
                    fixedDecimalScale
                    className={`font-normal`}
                  />

                  {/* {`$ ${Number(
                  data?.price,)?.toFixed(2)}`} */}
                </span>
                {/* <span className="text-sm text-gray-300 flex -mt-2">each</span> */}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
