import { historyReducer } from '../components/dashboard/history_activity/history.slice';
import { authReducer } from '../features/auth.slice';
import {
  businessUserReducer,
  pageSizeReducer,
} from '../features/business_user/dashboard/dashboard.slice';
import {
  ExtendTimeReducer,
  cartErrorReducer,
  cartReducer,
  cartTimerAttemptModalReducer,
} from '../features/cart/cart.slice';
import {
  CartTrendingEventReducer,
  checkoutReducer,
} from '../features/checkout/checkout.slice';
import { cropperReducer } from '../features/cropper/cropper.slice';
import { allArtistReducer } from '../features/event/allartist.slice';
import { allEventReducer } from '../features/event/allevent.slice';
import {
  analyticsReducer,
  analyticsSubTabReducer,
} from '../features/event/analytics.slice';
import { artistReducer } from '../features/event/artist.slice';
import {
  DisabledBookNowReducer,
  DisabledHeaderCreateReducer,
  Navigatereducer,
  TicketTypeChangeReducer,
  TourListReducer,
  buildEventReducer,
  checkTicketPolicyError,
  eventSelectedRows,
  popOverReducer,
  reducer,
} from '../features/event/event.slice';
import {
  inPersonAttendeesReducer,
  virtualAttendeesReducer,
} from '../features/event/event_attendees.slice';
import {
  EventPolicysliceReducer,
  policyReducer,
} from '../features/event/policy.slice';
import { ticketDesignReducer } from '../features/event/ticket_design.slice';
import { ticketTypeReducer } from '../features/event/ticket_type.slice';
import {
  CartDrawerReducer,
  CartTimerReducer,
  PopularStatesReducer,
  PublishedEventsReducer,
  UserCurrentLocationReducer,
  bannerSliceReducer,
  homePageEventsReducer,
} from '../features/home_page/homepage.slice';
import { dashBoardLoaderReducer } from '../features/loader/loader.slice';
import {
  SingleEventnftReducer,
  SinglenftReducer,
  nftReducer,
} from '../features/nft/nft.slice';
import { orderReducer } from '../features/orders/orders.slice';
import seatmapReducer from '../features/seatmap/seatmap.slice';
import { joinRoomReducer } from '../features/socket.slice';
import { streamReducer } from '../features/stream_lounge/stream.slice';
import { userReducer } from '../features/user/user.slice';
import { userDetailsreducer } from '../features/user_detail.slice';
import { utilsReducer } from '../features/utils/utils.slice';

export const reducers = {
  auth: authReducer,
  eventList: reducer,
  navigateTab: Navigatereducer,
  selectedSeats: seatmapReducer,
  ShowBuildEvents: buildEventReducer,
  disabled: DisabledHeaderCreateReducer,
  checkPolicyError: checkTicketPolicyError,
  selectedEventsandTours: eventSelectedRows,
  stream: streamReducer,
  socket: joinRoomReducer,
  all_nfts: nftReducer,
  orders: orderReducer,
  artists: artistReducer,
  utils: utilsReducer,
  policy: policyReducer,
  analytics: analyticsReducer,
  user: userReducer,
  ShowSinglenft: SinglenftReducer,
  ticket_type: ticketTypeReducer,
  ticket_design: ticketDesignReducer,
  publishedEvents: PublishedEventsReducer,
  userDetails: userDetailsreducer,
  allEventReducer: allEventReducer,
  allArtistReducer: allArtistReducer,
  homePageEvents: homePageEventsReducer,
  allVirtualAttendees: virtualAttendeesReducer,
  allInPersonAttendees: inPersonAttendeesReducer,
  dashboardLoader: dashBoardLoaderReducer,
  EventPolicy: EventPolicysliceReducer,
  userCurrentLocation: UserCurrentLocationReducer,
  popularStates: PopularStatesReducer,
  cartDrawer: CartDrawerReducer,
  cartTimer: CartTimerReducer,
  checkout: checkoutReducer,
  bannerSlice: bannerSliceReducer,
  cartDatas: cartReducer,
  cartTrendingEvent: CartTrendingEventReducer,
  updateTicketTypeOnMount: TicketTypeChangeReducer,
  cartTimerAttemptModal: cartTimerAttemptModalReducer,
  all_single_event_nfts: SingleEventnftReducer,
  DisableBookTicket: DisabledBookNowReducer,
  cartCheckoutError: cartErrorReducer,
  TimeExtendedSlice: ExtendTimeReducer,
  tourListdatas: TourListReducer,
  ///////Business dashboard
  business_user: businessUserReducer,
  cropperDatas: cropperReducer,
  allPaginationSize: pageSizeReducer,
  Popover: popOverReducer,
  historyReducer: historyReducer,
  analyticsSubTab: analyticsSubTabReducer,
};
