import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {
  DATEFORMAT,
  GenerateQRCodeUrl,
  onCloseModal,
  truncate,
} from '../../../../utils/util';
import { BiCrown } from 'react-icons/bi';
import { MdAlarm, MdOutlineLocationOn } from 'react-icons/md';
import QRCode from 'react-qr-code';
import moment from 'moment';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import CurrencyFormat from 'react-currency-format';
import { btnPrimaryClass } from '../../../common/DefaultClasses';
import Tooltip from '../../../common/Tooltip';
import {
  viewSingleNFT,
  setIsOpen as setIsOpenNFT,
} from '../../../../features/nft/nft.slice';
import { useDispatch } from 'react-redux';
import {
  setIsOpen,
  setShowPolicy,
} from '../../../../features/orders/orders.slice';
import useDecodedToken from '../../../../hooks/useDecodedToken';
import { portalTexts } from '../../../../utils/LoaderMessage';

const CheckPrice = (ticket, event) => {
  let price = 'XXX.XX';
  if (ticket?.category_id === 'common') {
    if (event?.data?.category?.length > 0) {
      price = event?.data?.category[0]?.ticket_price;
      price = `${
        Number(event?.data?.category[0]?.ticket_price) < 10 ? '0' : ''
      }${event?.data?.category[0]?.ticket_price}`;
    } else {
      price = 'XXX.XX';
    }
  } else {
    if (event?.data?.category?.length > 0) {
      let temp = event?.data?.category;
      let catDetails = temp.filter((item) => ticket?.category_id === item?._id);
      if (catDetails?.length > 0) {
        price = `${Number(catDetails[0]?.ticket_price) < 10 ? '0' : ''}${
          catDetails[0]?.ticket_price
        }`;
      } else {
        price = 'XXX.XX';
      }
    }
  }
  return price;
};

const getVenueCategory = (ticket, event) => {
  let cat = '-';
  if (ticket?.category_id === 'common') {
    cat = 'All';
  } else {
    if (event?.data?.category?.length > 0) {
      let temp = event?.data?.category;
      let catDetails = temp.filter((item) => ticket?.category_id === item?._id);
      if (catDetails?.length > 0) {
        cat = `${catDetails[0]?.name}`;
      } else {
        cat = '-';
      }
    }
  }
  return cat;
};

const handleUploadlogo = (e) => {
  e.preventDefault();
  document.getElementById('upload-logo1').click();
};
const uploadImg = (e, uploader) => {
  e.preventDefault();
  uploader(e);
};

export default function DefaultTicketTeplateHorizontalCard({
  data,
  result = '',
  banner = '',
  uploader,
  fields,
  update,
  TicketDetails,
  seatData,
  method,
  setOpen = () => {},
  // setNftId = null,
  // setShowDetailView = () => {},
  updateModal,
  booking_id,
  event_type,
  category,
  allData,
  hideNft,
  type,
  setTabs,
  nftDrawer,
  setOpenSubDrawer,
  setSelectedIndex,
  ticket_name,
}) {
  // const [disableFields, setDisableFields] = React.useState([]);
  let dispatch = useDispatch();
  const decodedToken = useDecodedToken();
  // const handleSelectChange = (event, index, type) => {
  //   const { name, value } = event.target;
  //   let findDisableFieldBox = disableFields?.filter((data) => {
  //     return data?.boxIndex === index;
  //   });
  //   let otherBoxes = disableFields?.filter((data) => {
  //     return data?.boxIndex !== index;
  //   });
  //   if (findDisableFieldBox) {
  //     setDisableFields([...otherBoxes, { boxIndex: index, field: value }]);
  //   } else {
  //     setDisableFields([...disableFields, { boxIndex: index, field: value }]);
  //   }
  //   if (type === 'select') {
  //     update(name, { name: value, value: '' });
  //   } else {
  //     update(name, { name: fields[name].name, value: value });
  //   }
  // };

  return (
    <React.Fragment>
      <div
        className={
          'border-2 border-dashed border-level-2 bg-neutral-900 bg-opacity-20 w-full p-2 p-xl-3 relative rounded-md bg-center'
        }
        style={{
          backgroundImage: banner
            ? `url(${banner})`
            : TicketDetails !== undefined
            ? `url(${TicketDetails?.background_img?.url})`
            : 'url()',
        }}
      >
        <Row className="gx-3">
          <Col xl={12}>
            <div className="h-full">
              <Card
                className={
                  'border-0 rounded-lg h-full bg-neutral-900 backdrop-blur-sm bg-opacity-20'
                }
              >
                <Card.Body>
                  <div className="flex flex-col justify-between h-full">
                    <div className="lg:space-y-2">
                      <div className="flex gap-3 items-center">
                        <div
                          className="border-1 border-gray-300 h-16 w-36 relative rounded-md overflow-hidden parentHover"
                          type="button"
                          onClick={() => {
                            // openModal();
                          }}
                        >
                          {result !== '' ||
                          banner !== '' ||
                          (TicketDetails && TicketDetails?.logo_img?.url) ||
                          (data?.data?.profile_image &&
                            data?.data?.profile_image?.url) ? (
                            <>
                              <img
                                src={
                                  result !== '' || banner !== ''
                                    ? result
                                      ? result
                                      : banner && type !== 'edit'
                                      ? banner
                                      : TicketDetails !== undefined
                                      ? TicketDetails?.logo_img
                                        ? TicketDetails?.logo_img?.url
                                        : data?.data?.profile_image
                                        ? data?.data?.profile_image?.url
                                        : ''
                                      : ''
                                    : TicketDetails !== undefined
                                    ? TicketDetails?.logo_img
                                      ? TicketDetails?.logo_img?.url
                                      : data?.data?.profile_image
                                      ? data?.data?.profile_image?.url
                                      : ''
                                    : ''
                                }
                                alt="ticket logo"
                                className="w-full h-full object-cover"
                                loading="lazy"
                              />
                            </>
                          ) : (
                            <div
                              className="rounded-md absolute inset-0 h-full w-full bg-gray-800 bg-opacity-10 backdrop-blur-sm"
                              type="button"
                            >
                              <div className="h-full flex items-center justify-center">
                                <Row className="w-full max-w-md">
                                  <Col>
                                    <Card className="bg-transparent border-level-2 border-dashed">
                                      <Card.Body>
                                        <div
                                          className="py-0 w-full flex flex-col items-center justify-center"
                                          onClick={handleUploadlogo}
                                        >
                                          <div className="flex">
                                            <AiOutlineCloudUpload className="text-gray-50 text-4xl" />
                                          </div>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          )}
                          <input
                            hidden
                            accept="image/*"
                            type={'file'}
                            id={'upload-logo1'}
                            onChange={(e) => {
                              uploadImg(e, uploader);
                            }}
                          />
                          {(updateModal || method === 'defaultTemplate') && (
                            <Tooltip
                              title={`This could be your event logo, artwork, or a picture related to the event.`}
                              placement={'right'}
                            >
                              <div
                                className="rounded-md absolute inset-0 h-full w-full bg-gray-800 bg-opacity-10 backdrop-blur-sm z-20 childHover"
                                type="button"
                              >
                                <div className="h-full flex items-center justify-center">
                                  <Row className="w-full max-w-md">
                                    <Col>
                                      <Card className="bg-transparent border-level-2 border-dashed">
                                        <Card.Body>
                                          <div
                                            className="py-0 w-full flex flex-col items-center justify-center"
                                            onClick={handleUploadlogo}
                                          >
                                            <div className="flex">
                                              <AiOutlineCloudUpload className="text-gray-50 text-4xl" />
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="flex gap-3 items-center overflow-hidden">
                          <h3 className={`text-white text-xl break-all`}>
                            {truncate(
                              data?.data?.name?.charAt(0).toUpperCase() +
                                data?.data?.name?.slice(1),
                              150,
                            ) || 'Event Name'}
                          </h3>
                        </div>
                        <div className="flex gap-3 items-center">
                          <span className="text-md text-slate-300">
                            {truncate(data?.data?.description, 150) ||
                              'Description'}
                            {/* {data?.data?.description} */}
                          </span>
                        </div>
                      </div>
                      <div className="flex gap-2 gap-lg-3 items-center flex-wrap">
                        <div className="flex gap-3 items-center">
                          <h3 className="text-xs lg:text-xl">
                            <span className={`text-white`}>
                              {data?.data?.start_date_time
                                ? `${moment(
                                    moment
                                      .utc(data?.data?.start_date_time)
                                      .local(),
                                    'YYYY-MM-DD hh:mm a',
                                  ).format(DATEFORMAT)}`
                                : '08/23/23 - 15:00'}
                            </span>
                          </h3>
                        </div>
                        <div className="h-7 border-1 hidden lg:flex"></div>
                        <div className="flex gap-2 items-center">
                          <h3 className="text-xs lg:text-xl flex gap-3">
                            <span className={`text-white`}>
                              {data?.data?.end_date_time
                                ? `${moment(
                                    moment
                                      .utc(data?.data?.end_date_time)
                                      .local(),
                                    'YYYY-MM-DD hh:mm a',
                                  ).format(DATEFORMAT)}`
                                : '08/24/23 - 15:00'}
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="py-2">
                      <div className="row row-cols-5">
                        {event_type !== 'Virtual' && (
                          <>
                            <div className="col">
                              <span className="flex text-sm text-gray-300">
                                Section
                              </span>
                              <span className="flex text-lg text-gray-100">
                                {seatData !== null ? seatData?.section : '-'}
                              </span>
                            </div>
                            <div className="col">
                              <span className="flex text-sm text-gray-300">
                                Row
                              </span>
                              <span className="flex text-lg text-gray-100">
                                {seatData !== null ? seatData?.row : '-'}
                              </span>
                            </div>
                            <div className="col">
                              <span className="flex text-sm text-gray-300">
                                Seat
                              </span>
                              <span className="flex text-lg text-gray-100">
                                {seatData !== null
                                  ? seatData?.seat_number
                                  : '-'}
                              </span>
                            </div>
                          </>
                        )}
                        <div className="col">
                          <span className="flex text-sm text-gray-300">
                            Price
                          </span>

                          <span className="flex text-lg text-gray-100">
                            {event_type === 'Virtual' ? (
                              <CurrencyFormat
                                value={allData?.price}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                disabled={true}
                                decimalScale={2}
                                fixedDecimalScale
                              />
                            ) : booking_id !== null ? (
                              <CurrencyFormat
                                thousandSeparator
                                disabled={true}
                                displayType="text"
                                value={seatData?.price || 0}
                                decimalScale={2}
                                fixedDecimalScale
                                prefix="$"
                              />
                            ) : (
                              `$${CheckPrice(TicketDetails, data)}`
                            )}
                          </span>
                        </div>
                        <div className="flex">
                          <div
                            style={{
                              backgroundColor:
                                category !== null && category !== undefined
                                  ? category?.color
                                  : '',
                            }}
                            className="mt-2 p-0.5 px-2 bg-sky-600 bg-opacity-40 backdrop-blur-md rounded-lg flex gap-1 items-center text-white text-sm 2xl:text-base"
                          >
                            <BiCrown className="" />
                            {ticket_name
                              ? ticket_name
                              : category !== null && category !== undefined
                              ? category?.name
                              : getVenueCategory(TicketDetails, data)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-3 items-center">
                      {/* <span className="text-xs lg:text-md text-gray-400">
                            Add event description, tagline, or any other useful
                            information
                          </span> */}
                      {/* <button
                        className="btn btnSecondary border-0 wpx-40 hpx-40 height-auto min-auto flex gap-2 roundrem-49 relative p-0 text-sm"
                        type="button"
                        onClick={() => {
                          //   openModal();
                        }}
                      >
                        <RiPencilLine className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover" />
                      </button> */}
                      <div className="top-2 right-2 absolute">
                        <Card className="border-0 rounded-md h-full">
                          <Card.Body className="p-1">
                            {booking_id !== null ? (
                              <>
                                <div className="flex flex-col items-center justify-center py-0">
                                  <QRCode
                                    className="h-16 w-16"
                                    value={GenerateQRCodeUrl(
                                      allData?.cart_seats_id,
                                      data?.data?._id,
                                    )}
                                    // value={GenerateQRCodeUrl(
                                    //   booking_id,
                                    //   data?.data?._id,
                                    // )}
                                    viewBox={`0 0 256 256`}
                                  />
                                  {!hideNft && (
                                    <div className="flex gap-2 mt-1 w-full justify-center">
                                      <Tooltip
                                        title={
                                          allData?.is_minting === 0 ||
                                          allData?.is_cancel !== undefined
                                            ? 'Minting is under process.'
                                            : ''
                                        }
                                        placement={'bottom'}
                                        childClassname="flex w-full"
                                      >
                                        <button
                                          disabled={
                                            type === 'preview' ||
                                            allData?.is_cancel !== undefined ||
                                            allData?.is_minting === 0
                                          }
                                          className={`${btnPrimaryClass} w-full h-auto flex items-center justify-center p-1.5 disabled:cursor-not-allowed text-xs`}
                                          onClick={() => {
                                            // setNftId(booking_id);
                                            dispatch(setShowPolicy(false));
                                            dispatch(setIsOpen(false));
                                            dispatch(setIsOpenNFT(false));
                                            nftDrawer &&
                                              setOpenSubDrawer(false);
                                            nftDrawer && onCloseModal();
                                            decodedToken?.user_type === 'seller'
                                              ? setTabs('nfts')
                                              : setSelectedIndex('nfts');

                                            setOpen(false);
                                            localStorage.setItem(
                                              'showNFT',
                                              JSON.stringify({
                                                status: true,
                                                NFTID: booking_id,
                                              }),
                                            );
                                            // setTabs('nfts');

                                            dispatch(
                                              viewSingleNFT({
                                                status: true,
                                                NFTID: booking_id,
                                              }),
                                            );
                                            // setShowDetailView(true);
                                          }}
                                        >
                                          View {portalTexts?.nTkt}
                                        </button>
                                      </Tooltip>
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : (
                              <div className="">
                                <div className="p-0 flex justify-center">
                                  <QRCode
                                    className="h-16 w-16"
                                    value={'nftgotix'}
                                    // value={GenerateQRCodeUrl(
                                    //   booking_id,
                                    //   data?.data?._id,
                                    // )}
                                    viewBox={`0 0 256 256`}
                                  />
                                </div>
                              </div>
                            )}
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
          {/* <Col xl={3}>
            <div className="h-full">
              <Card className="border-0 rounded-lg h-full">
                <Card.Body>
                  {booking_id !== null ? (
                    <>
                      <div className="flex justify-center py-4">
                        <div className="">
                          <QRCode
                            className="h-24 w-24 2xl:h-40 2xl:w-40"
                            value={GenerateQRCodeUrl(
                              booking_id,
                              data?.data?._id,
                            )}
                            viewBox={`0 0 256 256`}
                          />
                          <div className="flex gap-2 mt-1">
                            <span
                              type="button"
                              className="btn btnPrimary rounded-xl w-full h-auto flex items-center justify-center p-2"
                              onClick={() => {
                                setNftId(booking_id);
                                setOpen(false);
                                setShowDetailView(true);
                              }}
                            >
                              View NFT
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="space-y-3">
                      <div className="px-4 pt-4 flex justify-center">
                        <BsQrCode className="w-full h-full max-w-xs" />
                      </div>
                      <div className="flex flex-col items-center">
                        <span className="flex text-sm">Ticket Code</span>
                        <span className="flex text-lg">12345678564</span>
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
          </Col> */}
        </Row>
      </div>
    </React.Fragment>
  );
}

export const DefaultTicketTeplateVerticalCard = ({
  data,
  type,
  result = '',
  banner = '',
  uploader,
  fields,
  from = null,
  update,
  TicketDetails,
  created,
  seatData,
  method,
  setOpen = () => {},
  // setNftId = null,
  // setShowDetailView = () => {},
  updateModal,
  uploadBG,
  handleUploadBG,
  booking_id,
  event_type,
  category,
  allData,
  hideNft,
  setTabs,
  nftDrawer,
  setOpenSubDrawer,
  setSelectedIndex,
  ticket_name,
}) => {
  const decodedToken = useDecodedToken();
  // const [disableFields, setDisableFields] = React.useState([]);

  //  const handleSelectChange = (event, index, type) => {
  //   const { name, value } = event.target;
  //   let findDisableFieldBox = disableFields?.filter((data) => {
  //     return data?.boxIndex === index;
  //   });
  //   let otherBoxes = disableFields?.filter((data) => {
  //     return data?.boxIndex !== index;
  //   });
  //   if (findDisableFieldBox) {
  //     setDisableFields([...otherBoxes, { boxIndex: index, field: value }]);
  //   } else {
  //     setDisableFields([...disableFields, { boxIndex: index, field: value }]);
  //   }

  //   if (type === 'select') {
  //     update(name, { name: value, value: '' });
  //   } else {
  //     update(name, { name: fields[name].name, value: value });
  //   }
  // };

  let dispatch = useDispatch();

  return (
    <React.Fragment>
      <Row className="w-full justify-center">
        <Col md={4} lg={4} xl={4} xxl={4}>
          <div className="relative rounded-2xl overflow-hidden shadow-lg my-2">
            <div
              className=" w-full h-full bg-center"
              style={{
                backgroundImage: banner
                  ? `url(${banner})`
                  : TicketDetails !== undefined
                  ? `url(${TicketDetails?.background_img?.url})`
                  : 'url()',
              }}
            >
              <div className="ticket bg-neutral-900 w-full h-full rounded-2xl bg-opacity-20 backdrop-blur-md">
                {/* <div className="holes-top"></div> */}
                <div className="title p-2 p-lg-3 p-xxl-4">
                  <div className="flex gap-3 items-center relative">
                    <div
                      className="border-1 border-gray-300 h-16 w-36 relative rounded-md overflow-hidden parentHover"
                      type="button"
                      onClick={() => {
                        // openModal();
                      }}
                    >
                      <img
                        src={
                          result !== '' || banner !== ''
                            ? result
                              ? result
                              : banner && type !== 'edit'
                              ? banner
                              : TicketDetails !== undefined
                              ? TicketDetails?.logo_img
                                ? TicketDetails?.logo_img?.url
                                : data?.data?.profile_image
                                ? data?.data?.profile_image?.url
                                : ''
                              : ''
                            : TicketDetails !== undefined
                            ? TicketDetails?.logo_img
                              ? TicketDetails?.logo_img?.url
                              : data?.data?.profile_image
                              ? data?.data?.profile_image?.url
                              : ''
                            : ''
                        }
                        // alt="user"
                        className="w-full h-full object-cover"
                        loading="lazy"
                      />
                      <input
                        hidden
                        accept="image/*"
                        type={'file'}
                        id={'upload-logo1'}
                        onChange={(e) => {
                          uploadImg(e, uploader);
                        }}
                      />
                      {(updateModal || method === 'defaultTemplate') && (
                        <div
                          className="rounded-md absolute inset-0 h-full w-full bg-gray-800 bg-opacity-10 backdrop-blur-sm z-20 childHover"
                          type="button"
                        >
                          <div
                            className="h-full flex items-center justify-center"
                            // onClick={handleUploadBG}
                          >
                            <Row className="w-full max-w-md">
                              <Col>
                                <Card className="bg-transparent border-level-2 border-dashed">
                                  <Card.Body>
                                    <div
                                      className="py-0 w-full flex flex-col items-center justify-center"
                                      onClick={handleUploadlogo}
                                    >
                                      <div className="flex">
                                        <AiOutlineCloudUpload className="text-gray-50 text-4xl" />
                                      </div>
                                      {/* <div className="flex flex-col items-center">
                                      <span className="text-lg text-gray-50">
                                        Upload Image
                                      </span>
                                    </div> */}
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <p className="xl:text-2xl mb-0 text-gray-50 mt-3">
                    {data?.data?.name?.charAt(0).toUpperCase() +
                      data?.data?.name?.slice(1) || 'Event Name'}
                  </p>
                  <span className="flex lg:text-2xl 2xl:text-3xl text-sky-600">
                    {data?.data?.start_date_time
                      ? `${moment(
                          moment.utc(data?.data?.start_date_time).local(),
                          'YYYY-MM-DD hh:mm a',
                        ).format('YYYY')}`
                      : '2023'}
                  </span>
                  <div className="flex">
                    <div className="bg-neutral-800 p-2 rounded-sm backdrop-blur-sm bg-opacity-60">
                      <span className="flex items-center lg:text-lg text-gray-50">
                        Price*
                        <span className="flex ml-2 text-sky-600">
                          {event_type === 'Virtual' ? (
                            <CurrencyFormat
                              value={allData?.price}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'$'}
                              disabled={true}
                              decimalScale={2}
                              fixedDecimalScale
                            />
                          ) : booking_id !== null ? (
                            <CurrencyFormat
                              thousandSeparator
                              disabled={true}
                              displayType="text"
                              value={seatData?.price || 0}
                              decimalScale={2}
                              fixedDecimalScale
                              prefix="$"
                            />
                          ) : (
                            `$${CheckPrice(TicketDetails, data)}`
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="absolute top-2 right-2 p-1 bg-sky-600 bg-opacity-60 backdrop-blur-md rounded-lg flex gap-1 items-center text-white 2xl:text-lg">
                  <BiCrown className="" />
                  {ticket_name
                    ? ticket_name
                    : booking_id !== null
                    ? seatData?.category
                    : getVenueCategory(TicketDetails, data)}
                </div>
                {/* <div className="poster">
                <img
                  //   src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/25240/only-god-forgives.jpg"
                  src={ticket3}
                  alt="Movie: Only God Forgives"
                  className="w-full h-full object-cover"
                />
              </div> */}
                <div className="info bg-neutral-800 bg-opacity-20 backdrop-blur-md">
                  {event_type !== 'Virtual' && (
                    <div className="flex justify-center gap-2 mb-3">
                      <div className="bg-gray-50 p-1 rounded-sm flex flex-col items-center backdrop-blur-md w-full">
                        <span className="flex items-center text-neutral-950 text-sm gap-1">
                          Section*
                        </span>
                        <span className="flex text-lg text-neutral-950">
                          {seatData !== null ? seatData?.section : '-'}
                        </span>
                      </div>
                      <div className="bg-gray-50 p-1 rounded-sm flex flex-col items-center backdrop-blur-md w-full">
                        <span className="flex items-center text-neutral-950 text-sm gap-1">
                          Row*
                        </span>
                        <span className="flex text-lg text-neutral-950">
                          {seatData !== null ? seatData?.row : '-'}
                        </span>
                      </div>
                      <div className="bg-gray-50 p-1 rounded-sm flex flex-col items-center backdrop-blur-md w-full">
                        <span className="flex items-center text-neutral-950 text-sm gap-1">
                          Seat*
                        </span>
                        <span className="flex text-lg text-neutral-950">
                          {seatData !== null ? seatData?.seat_number : '-'}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col gap-3 text-gray-50">
                    <div className="flex gap-1 flex-wrap">
                      <div className="flex items-center gap-1 2xl:text-xl">
                        <MdAlarm className="text-sky-700 shrink-0" />
                        <div className="flex flex-col text-sm pt-1">
                          <span className="flex text-gray-50">
                            {data?.data?.start_date_time
                              ? `${moment(
                                  moment
                                    .utc(data?.data?.start_date_time)
                                    .local(),
                                  'YYYY-MM-DD hh:mm a',
                                ).format(DATEFORMAT)}`
                              : '20 Dec 2023'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="border-b-2 border-gray-400 w-full"></div>
                    <div className="flex items-center gap-1 2xl:text-xl">
                      <MdOutlineLocationOn className="text-sky-700 shrink-0" />
                      <span className="flex text-gray-50">
                        {booking_id !== null
                          ? data?.data?.venue
                            ? data?.data?.venue[0]?.location ||
                              data?.data?.venue?.location
                            : '123 Anywhere St., Any City' || 'New York'
                          : data?.data?.venue?.length > 0
                          ? data?.data?.venue[0]?.location
                          : '123 Anywhere St., Any City' || 'New York'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="holes-lower"></div>
                {/* <div className="flex justify-center py-4">
                <div className="p-2 bg-neutral-800 bg-opacity-40 backdrop-blur-md rounded-lg">
                  <QRCode
                    className="h-28 w-28 2xl:h-44 2xl:w-44"
                    //   style={{
                    //     height: 'auto',
                    //     maxWidth: '100%',
                    //     width: '100%',
                    //   }}
                    value={'hello'}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div> */}
                <div className="h-full">
                  <Card className="border-0 rounded-none h-full bg-neutral-900">
                    <Card.Body>
                      {booking_id !== null ? (
                        <>
                          <div className="flex justify-center py-0">
                            <div className="">
                              <QRCode
                                className="h-24 w-24"
                                value={GenerateQRCodeUrl(
                                  allData?.cart_seats_id,
                                  data?.data?._id,
                                )}
                                // value={GenerateQRCodeUrl(
                                //   booking_id,
                                //   data?.data?._id,
                                // )}
                                viewBox={`0 0 256 256`}
                              />
                              {!hideNft && (
                                <div className="flex gap-2 mt-1 w-full justify-center">
                                  <Tooltip
                                    title={
                                      allData?.is_minting === 0 ||
                                      allData?.is_cancel !== undefined
                                        ? 'Minting is under process.'
                                        : ''
                                    }
                                    placement={'bottom'}
                                    childClassname="flex w-full"
                                  >
                                    <button
                                      disabled={
                                        type === 'preview' ||
                                        allData?.is_cancel !== undefined ||
                                        allData?.is_minting === 0
                                      }
                                      className={`${btnPrimaryClass} w-full h-auto flex items-center justify-center p-2 disabled:cursor-not-allowed`}
                                      onClick={() => {
                                        // setNftId(booking_id);
                                        dispatch(setShowPolicy(false));
                                        dispatch(setIsOpen(false));
                                        dispatch(setIsOpenNFT(false));
                                        nftDrawer && setOpenSubDrawer(false);
                                        nftDrawer && onCloseModal();
                                        decodedToken?.user_type === 'seller'
                                          ? setTabs('nfts')
                                          : setSelectedIndex('nfts');
                                        setOpen(false);
                                        localStorage.setItem(
                                          'showNFT',
                                          JSON.stringify({
                                            status: true,
                                            NFTID: booking_id,
                                          }),
                                        );
                                        dispatch(
                                          viewSingleNFT({
                                            status: true,
                                            NFTID: booking_id,
                                          }),
                                        );
                                        // setShowDetailView(true);
                                      }}
                                    >
                                      View {portalTexts?.nTkt}
                                    </button>
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="space-y-3">
                          <div className="px-0 pt-2 flex justify-center">
                            <QRCode
                              className="h-24 w-24"
                              value={'NFT Gotix'}
                              // value={GenerateQRCodeUrl(
                              //   booking_id,
                              //   data?.data?._id,
                              // )}
                              viewBox={`0 0 256 256`}
                            />
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
