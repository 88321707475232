import React from 'react';

export default function Image({ src, loading, className, alt, srcset }) {
  return (
    <React.Fragment>
      <img
        src={src}
        alt={alt}
        className={className}
        loading={loading ? loading : 'lazy'}
        srcset={`${srcset ? `${src} 300w,${src} 300w` : ''} `}
      />
    </React.Fragment>
  );
}
