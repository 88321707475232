import React from 'react';
// import { IoPhonePortraitOutline } from 'react-icons/io5';
// import { MacIcon } from '../util/CustomIcons';
import { Col, Row } from 'react-bootstrap';
import { getBrowserIcons, getDeviceIcons } from '../../utils/util';
import moment from 'moment';
export default function DeviceTitle({ device, current_session }) {
  const {
    // browserFullVersion,
    browserMajorVersion,
    browserName,
    // engineName,
    // engineVersion,
    osName,
    osVersion,
    browser,
    os,
  } = device;
  const { started_at } = current_session;
  return (
    <React.Fragment>
      <div className={`flex items-center gap-2 truncate w-full `}>
        {
          <div className="w-full">
            <Row>
              <Col md={6}>
                <div className="flex">
                  <span className={`flex flex-col gap-2`}>
                    <span className="text-whiteNew-0">
                      {getBrowserIcons(browserName || browser?.name)?.icon}
                    </span>
                    <span className="text-xs text-gray-400 font-semibold ">
                      {getBrowserIcons(browserName || browser?.name)?.name} v
                      {browserMajorVersion || browser?.major}
                    </span>
                  </span>
                </div>
              </Col>
              <Col md={6}>
                <span className={`flex flex-col gap-2`}>
                  <span className="text-whiteNew-0">
                    {getDeviceIcons(osName || os?.name)?.icon}
                  </span>
                  <span className="text-xs text-gray-400 font-semibold">
                    {getDeviceIcons(osName || os?.name)?.name} v
                    {osVersion || os?.version}
                  </span>
                </span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <div className="flex">
                  <span className={`flex flex-col gap-2`}>
                    <span className="text-xs text-gray-400">
                      Check In:{' '}
                      {moment
                        .utc(started_at)
                        .local()
                        .format('MM/DD/YYYY HH:mm')}
                    </span>
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        }
      </div>
    </React.Fragment>
  );
}
