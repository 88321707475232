import React from 'react';

export default function InfoStrip({ message, primaryBtn, secondaryBtn }) {
  return (
    <>
      <div className="flex justify-center py-2.5">
        <div className="flex w-full max-w-3xl items-center justify-between flex-wrap2 lg:flex-nowrap p-2.5 rounded-md dark:bg-brown-800 bg-color-base-3 dark:bg-opacity-70 min-h-[60px] border-[1px] border-zinc-200 dark:border-zinc-800">
          <span className="text-rose-600 text-sm font-medium">{message}</span>
          {primaryBtn || secondaryBtn ? (
            <>
              <div className="flex gap-2 items-center">
                {primaryBtn ? (
                  <>
                    <button
                      onClick={primaryBtn?.onClick}
                      className="dark:bg-landingPurple-60 bg-sky-600 hover:bg-sky-700 dark:hover:bg-landingPurple-70 p-1.5 px-3.5 rounded-md text-whiteNew-0 flex justify-center"
                    >
                      {primaryBtn?.name || 'Yes'}
                    </button>
                  </>
                ) : null}
                {secondaryBtn ? (
                  <>
                    <button
                      onClick={secondaryBtn?.onClick}
                      className="bg-brown-900 dark:bg-zinc-800 hover:bg-opacity-70 p-1.5 px-3.5 rounded-md text-whiteNew-0"
                    >
                      {secondaryBtn?.name || 'No'}
                    </button>
                  </>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
