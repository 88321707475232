import React from 'react';

export default function SeatAvailabilityList({ bgColor, title }) {
  return (
    <React.Fragment>
      <div className="flex gap-2 items-center">
        <div className={`w-5 h-5 rounded-full ${bgColor}`}></div>
        <div>
          <span className="dark:text-whiteNew-0 text-zinc-800">{title}</span>
        </div>
      </div>
    </React.Fragment>
  );
}
