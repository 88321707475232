import React from 'react';
import Image from '../../common/Image';
import {
  DesktopIcon,
  HeartIcon,
  HistoryIcon,
  NFTIcon,
  OrderListIcon,
  SecurityLockIcon,
  SignOutIcon,
  WalletIcon2,
  profileUrl,
} from '../../util/CustomIcons';
import { afterBorder } from '../../common/DefaultClasses';
import { Col, Row } from 'react-bootstrap';
import Button from '../../common/Button';
import { Link, useNavigate } from 'react-router-dom';
// import { useUserDetails } from '../../../hooks/user/useUserDetails';
import useDecodedToken from '../../../hooks/useDecodedToken';
import { useLogout } from '../../../helpers/api/auth/auth';
// import { updateLoader } from '../../../features/loader/loader.slice';
import { useSelector } from 'react-redux';
// import { GetEventContext } from '../../../contexts/events/GetEventList';
// import { useContext } from 'react';
import {
  concatinateID,
  // truncate,
  updateAccUserName,
} from '../../../utils/util';
import Tooltip from '../../common/Tooltip';
import { useCallback } from 'react';
import { BsGlobeAmericas } from 'react-icons/bs';
// import { DeleteAllSeats } from '../../../features/seatmap/seatmap.slice';
// import { DeleteAllCart } from '../../../features/cart/cart.slice';
// import {
//   resetEvents,
//   resetPageLimit,
// } from '../../../features/home_page/homepage.slice';
import { portalTexts } from '../../../utils/LoaderMessage';
import useSearchQuery from '../../../hooks/useSearchQuery';

export default function UserProfileDropdownCard({
  darkTheme,
  hideCompo,
  close,
}) {
  const { logout, isLoading } = useLogout();
  let navigate = useNavigate();
  const decodedToken = useDecodedToken();
  const { data: userdetails } = useSelector((state) => state.userDetails);
  // let dispatch = useDispatch();
  const query = useSearchQuery();
  // console.log('query', query.get('tab'));

  // const { eventList, setEventList, currentPage, setCurrentPage } =
  //   useContext(GetEventContext);

  const NavigateTab = useCallback(
    (e, tab) => {
      e.preventDefault();
      // e.stopPropogation();
      close();
      if (tab === 'wallet') {
        navigate(
          `/user/${decodedToken?.username}/dashboard?tab=about&subtab=myaccount`,
        );
      } else if (tab === 'orders') {
        if (decodedToken?.user_type === 'buyer') {
          navigate(
            `/user/${
              decodedToken?.username || decodedToken?.id
            }/dashboard?tab=${'orders'}`,
          );
        } else {
          navigate(
            `/user/${
              decodedToken?.username || decodedToken?.id
            }/dashboard?tab=purchase&subtab=orders`,
          );
        }
      } else if (tab === 'nfts') {
        if (decodedToken?.user_type === 'buyer') {
          navigate(
            `/user/${
              decodedToken?.username || decodedToken?.id
            }/dashboard?tab=${'nfts'}`,
          );
        } else {
          navigate(
            `/user/${
              decodedToken?.username || decodedToken?.id
            }/dashboard?tab=purchase&subtab=nfts`,
          );
        }
      } else if (tab === 'security') {
        navigate(
          `/user/${decodedToken?.username}/dashboard?tab=about&subtab=security`,
        );
      } else if (tab === 'favSave') {
        navigate(
          `/user/${decodedToken?.username}/dashboard?tab=about&subtab=favourite`,
        );
      } else if (tab === 'history') {
        navigate(
          `/user/${decodedToken?.username}/dashboard?tab=about&subtab=history`,
        );
      }
    },
    [decodedToken, navigate, close],
  );

  // const ClearDataOnLogout = useCallback(() => {
  //   setEventList({});

  //   localStorage.removeItem('loaderState');
  //   dispatch(updateLoader(true));
  //   localStorage.removeItem('SelectedEvent');
  //   localStorage.removeItem('selectedVenue');
  //   dispatch(DeleteAllSeats());
  //   dispatch(DeleteAllCart());
  //   dispatch(resetEvents());
  //   dispatch(resetPageLimit());
  // }, [dispatch, setEventList]);

  const highlightSelected = useCallback(
    (from) => {
      if (from === 'wallet') {
        if (
          query.get('tab') === 'about' &&
          query.get('subtab') === 'myaccount'
        ) {
          return true;
        }
      } else if (from === 'orders') {
        if (
          (query.get('tab') === 'purchase' &&
            query.get('subtab') === 'orders') ||
          (decodedToken?.user_type === 'buyer' && query.get('tab') === 'orders')
        ) {
          return true;
        }
      } else if (from === 'nfts') {
        if (
          (query.get('tab') === 'purchase' && query.get('subtab') === 'nfts') ||
          (decodedToken?.user_type === 'buyer' && query.get('tab') === 'nfts')
        ) {
          return true;
        }
      } else if (from === 'security') {
        if (
          query.get('tab') === 'about' &&
          query.get('subtab') === 'security'
        ) {
          return true;
        }
      } else if (from === 'fav') {
        if (
          query.get('tab') === 'about' &&
          query.get('subtab') === 'favourite'
        ) {
          return true;
        }
      } else if (from === 'history') {
        if (query.get('tab') === 'about' && query.get('subtab') === 'history') {
          return true;
        }
      }
    },
    [query, decodedToken],
  );

  return (
    <React.Fragment>
      <div
        className={`relative space-y-4 text-neutral-950 dark:text-whiteNew-0`}
      >
        <div className="flex gap-3">
          <div className="w-14 h-14 rounded-md overflow-hidden">
            <Image
              src={userdetails?.data?.image?.url || profileUrl}
              alt={'profile picture'}
              className={'w-full h-full object-cover'}
            />
          </div>
          <div className="">
            <div>
              <span className="flex text-base font-medium leading-tight">
                {updateAccUserName(userdetails?.data) ||
                  userdetails?.data?.name}
              </span>
            </div>
            <div>
              <span className="flex text-xs">{userdetails?.data?.email}</span>
            </div>
            <div>
              <span className="flex text-xs">
                <Tooltip title={userdetails?.data?._id}>
                  User ID : {concatinateID(userdetails?.data?._id)}
                </Tooltip>
              </span>
            </div>
          </div>
        </div>
        <div
          className={` bg-component-gray-1 dark:bg-opacity-60 bg-opacity-60 rounded-md w-full`}
        >
          <div
            className={`p-2 px-3 ${afterBorder} relative w-full ${
              darkTheme ? 'after:bg-level-2' : 'after:bg-level-2'
            }`}
          >
            <span className="text-xs font-medium">
              Features on your Accounts
            </span>
          </div>
          <div className="p-2">
            <Row className="row-cols-3 g-2">
              <Col>
                <Button
                  onClick={(e) => NavigateTab(e, 'wallet')}
                  className={`flex flex-col w-full items-center gap-1 p-2.5 py-3.5 rounded-md duration-200 ease-in  hover:bg-gray-300 hover:bg-opacity-60 dark:hover:bg-component-gray-2 hover:text-zinc-800 dark:hover:text-whiteNew-0 ${
                    highlightSelected('wallet') ? 'bg-component-gray-1' : ''
                  }`}
                >
                  <WalletIcon2 className={'text-base'} />
                  <span className="text-xs">Wallet</span>
                </Button>
              </Col>
              <Col>
                <Button
                  // onClick={()=> ('orders')}
                  onClick={(e) => NavigateTab(e, 'orders')}
                  className={`flex flex-col w-full items-center gap-1 p-2.5 py-3.5 rounded-md duration-200 ease-in  hover:bg-gray-300 hover:bg-opacity-60 dark:hover:bg-component-gray-2 hover:text-zinc-800 dark:hover:text-whiteNew-0 ${
                    highlightSelected('orders') ? 'bg-component-gray-1' : ''
                  }`}
                >
                  <OrderListIcon className={'text-base'} />
                  <span className="text-xs">Orders</span>
                </Button>
              </Col>
              <Col>
                <Button
                  // onClick={()=> ('nfts')}
                  onClick={(e) => NavigateTab(e, 'nfts')}
                  className={`flex flex-col w-full items-center gap-1 p-2.5 py-3.5 rounded-md duration-200 ease-in  hover:bg-gray-300 hover:bg-opacity-60 dark:hover:bg-component-gray-2 hover:text-zinc-800 dark:hover:text-whiteNew-0 ${
                    highlightSelected('nfts') ? 'bg-component-gray-1' : ''
                  }`}
                >
                  <NFTIcon className={'text-[17px]'} />
                  <span className="text-xs">{portalTexts?.nTkts}</span>
                </Button>
              </Col>
              <Col>
                <Button
                  // onClick={NavigateTab('security')}
                  onClick={(e) => NavigateTab(e, 'security')}
                  className={`flex flex-col w-full items-center gap-1 p-2.5 py-3.5 rounded-md duration-200 ease-in  hover:bg-gray-300 hover:bg-opacity-60 dark:hover:bg-component-gray-2 hover:text-zinc-800 dark:hover:text-whiteNew-0 ${
                    highlightSelected('security') ? 'bg-component-gray-1' : ''
                  }`}
                >
                  <SecurityLockIcon className={'text-base'} />
                  <span className="text-xs">Security</span>
                </Button>
              </Col>
              <Col>
                <Button
                  // onClick={NavigateTab('favSave')}
                  onClick={(e) => NavigateTab(e, 'favSave')}
                  className={`flex flex-col w-full items-center gap-1 p-2.5 py-3.5 rounded-md duration-200 ease-in  hover:bg-gray-300 hover:bg-opacity-60 dark:hover:bg-component-gray-2 hover:text-zinc-800 dark:hover:text-whiteNew-0 ${
                    highlightSelected('fav') ? 'bg-component-gray-1' : ''
                  }`}
                >
                  <HeartIcon className={'text-base'} />
                  <span className="text-xs flex">Fav&Save</span>
                </Button>
              </Col>
              <Col>
                <Button
                  // onClick={NavigateTab('history')}
                  onClick={(e) => NavigateTab(e, 'history')}
                  className={`flex flex-col w-full items-center gap-1 p-2.5 py-3.5 rounded-md duration-200 ease-in  hover:bg-gray-300 hover:bg-opacity-60 dark:hover:bg-component-gray-2 hover:text-zinc-800 dark:hover:text-whiteNew-0 ${
                    highlightSelected('history') ? 'bg-component-gray-1' : ''
                  }`}
                >
                  <HistoryIcon className={'text-base'} />
                  <span className="text-xs">History</span>
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <div
          className={`flex justify-between p-1.5 rounded-md bg-component-gray-1`}
        >
          <Link
            className={`flex gap-2 items-center px-1.5 py-0.5 rounded-md duration-100 ease-in dark:hover:bg-component-gray-1 dark:hover:bg-opacity-30 text-green-600 hover:bg-component-gray-2`}
            to={
              hideCompo
                ? `/user/${
                    userdetails?.data?.username || userdetails?.data?._id
                  }/dashboard`
                : '/'
            }
          >
            {hideCompo ? <DesktopIcon /> : <BsGlobeAmericas />}
            <span className="flex">
              {hideCompo ? `Go to Console` : `Go to Website`}
            </span>
          </Link>
          <Button
            className="flex gap-1 items-center text-rose-700 hover:text-rose-600 duration-100 ease-in"
            onClick={(e) => {
              e.preventDefault();
              // ClearDataOnLogout();
              logout();
            }}
            disabled={isLoading}
          >
            <SignOutIcon />

            <span className="flex">Sign Out</span>
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
