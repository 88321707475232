import React, {
  // Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import HelmetComponent from '../../../utils/HelmateComponent';
import { Col, Row } from 'react-bootstrap';
import HeadingTitle from '../../../components/landing_page_v2/HeadingTitle';
import ArtistBanner from '../../../components/landing_page_v2/artist_page/ArtistBanner';
import OrganiserCardV2 from '../../../components/landing_page_v2/OrganiserCardV2';
import { useDispatch, useSelector } from 'react-redux';
// import { useHomePageHooks } from '../home_page/hooks/useHomePageHooks';
import NoDataFound from '../../../components/common/NoDataFound';
import { DataLoader } from '../../../components/common/Loaders';
import loaderMessage from '../../../utils/LoaderMessage';
import {
  resetEvents,
  resetPageLimit,
} from '../../../features/home_page/homepage.slice';
import { ArtistCardSkeleton } from '../../../components/util/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  useAllArtistFilterAction,
  useAllArtistsFilter,
  useAllArtistsFilterSorting,
} from './hook/useArtist';
import FilterPills from '../../../components/landing_page_v2/FilterPills';
import FilterLeftSidebarV2 from '../all_events/FilterLeftSidebarV2';
import { MdFilterList } from 'react-icons/md';
import { Form, Input } from 'antd';
import {
  afterBorder,
  btnSecondayClass,
  // darkFormInput,
  formClass,
} from '../../../components/common/DefaultClasses';
import Button from '../../../components/common/Button';
import { BiFilterAlt } from 'react-icons/bi';
import GridLayout from './GridLayout';
import { FaUser, FaUsers } from 'react-icons/fa';
import {
  FILTER_CONST,
  GRID,
  LAYOUT2X2,
  LAYOUT3X3,
  LIST,
  updateFilter,
  updateFilterPills,
} from '../../../features/event/allartist.slice';
import { updateDataCountByViewportForEventCards } from '../../../utils/util';
import AllArtistListView from './AllArtistListView';
import { animateScroll } from 'react-scroll';
import useDebounce from '../../../hooks/useDebounce';
import {
  updateDefaultCount,
  updateDefaultSortCount,
} from '../../../features/event/event.slice';
import PopoverDropdown from '../../../components/dashboard_compo_v2/popover/PopoverDropdown';
import EventDetailDropdownFilter from '../../../components/dashboard_compo_v2/filters/EventDetailDropdownFilter';

export default function AllArtistPage() {
  // const { all_artist_list } = useSelector((s) => s.artists);
  const dispatch = useDispatch();
  // const { fetchArtist, loadMore, allArtistData, loadingArtist } = useArtist();
  const { showSkeleton, hasMore, loadMore } = useAllArtistFilterAction();
  const {
    obj: filter_options,
    all_filters_pills,
    removeSinglePill,
    AntdForm,
  } = useAllArtistsFilter();
  const [showFilter, setShowFilter] = useState(true);
  const [open, setOpen] = useState(false);
  const [disableApply, setDisableApply] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const { filterParams, filter_loading, filter_settings, all_artist } =
    useSelector((state) => state.allArtistReducer);

  let PublishedEvents = useSelector((state) => state?.publishedEvents);
  // const [tabs, setTabs] = useState('Sort By');
  const [search, setSearch] = useState();
  const [form] = Form.useForm();

  const {
    reset_filter,
    clear_filter,
    apply_filter,
    valid_params,
    setSearchParams,
    fetchArtists,
    isFilterTouched,
  } = useAllArtistFilterAction();

  useEffect(() => {
    if (valid_params) {
      setSearchParams(new URLSearchParams(valid_params));
      setDisableApply(false);
    }
    return () => {
      setDisableApply(false);
    };
  }, [setSearchParams, valid_params, isFilterTouched]);

  const handleSubmit = useCallback(
    async (e) => {
      const search_key = form.getFieldValue('search');
      await dispatch(updateFilter({ ...filterParams, search_key, page: 1 }));
      await fetchArtists({});
    },
    [dispatch, filterParams, form, fetchArtists],
  );

  const handleSortBy = useCallback(
    async (sort_by) => {
      await dispatch(
        updateFilter({ ...filterParams, sort_by: sort_by, page: 1 }),
      );
      await fetchArtists({});
    },
    [dispatch, filterParams, fetchArtists],
  );

  const handleFilterTourEvents = useCallback(
    async (tour) => {
      await animateScroll.scrollTo(
        scrollRef?.current?.getBoundingClientRect().top + window.scrollY,
        {
          delay: 0,
          duration: 0,
          smooth: true,
        },
      );
      await dispatch(updateFilter({ ...filterParams, tour, page: 1 }));
      // await fetchArtists({});
    },
    [dispatch, filterParams],
  );

  const callbackFilter = useCallback(
    async ({ id, key }) => {
      if (key in all_filters_pills) {
        if (
          Array.isArray(all_filters_pills[key]) &&
          all_filters_pills[key].some((e) => Boolean(e.id === String(id)))
        ) {
          await animateScroll.scrollTo(
            scrollRef?.current?.getBoundingClientRect().top + window.scrollY,
            {
              delay: 0,
              duration: 0,
              smooth: true,
            },
          );
          // dispatch(updateFilter({ ...filterParams, page: 1 }));
          return fetchArtists({});
        }
      }
      return false;
    },
    [fetchArtists, all_filters_pills],
  );

  const handleApplyFilter = useCallback(async () => {
    await animateScroll.scrollTo(
      scrollRef?.current?.getBoundingClientRect().top + window.scrollY,
      {
        delay: 0,
        duration: 0,
        smooth: true,
      },
    );
    setDisableApply(true);
    onClose();
    return await apply_filter();
  }, [apply_filter]);

  // const handleSearch = async (newSearchQuery) => {
  //   setPage(1);
  //   let data = await getSampleArtistList(page, newSearchQuery, limit);
  //   setArtistList([...data?.data?.data]);
  // };

  React.useEffect(() => {
    // Clear data from Redux store when leaving the page
    return () => {
      dispatch(resetEvents());
      dispatch(resetPageLimit());
    };
  }, [dispatch]);

  const scrollRef = useRef();
  const menuOptions = useMemo(
    () => [
      {
        name: 'Sort by A-Z', // desc
        id: '0',
        action: async () => {
          return await handleSortBy('asc');
        },
        value: 'asc',
      },
      {
        name: 'Sort by Z-A', // asc
        id: '1',
        action: async () => {
          return await handleSortBy('desc');
        },
        value: 'desc',
      },
      // {
      //   name: 'Recently Listed',
      //   id: '2',
      //   action: async () => {
      //     // return handleSortBy('newest');
      //   },
      // },
      // {
      //   name: 'Oldest', // asc
      //   id: '3',
      //   action: async () => {
      //     // return await handleSortBy('oldest');
      //   },
      // },
    ],
    [handleSortBy],
  );
  const tourOptions = useMemo(
    () => [
      {
        name: 'All',
        id: '0',
        // action: async () => {
        //   return await handleFilterTourEvents(undefined);
        // },
        // active: Boolean(filterParams?.tour === undefined),
        value: null,
      },
      {
        name: 'Artist', // asc
        id: '1',
        icon: (
          <>
            <FaUser className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
          </>
        ),
        // action: async () => {
        //   return await handleFilterTourEvents('individual');
        // },
        value: 'individual',
        // active: Boolean(filterParams?.tour === 'individual'),
      },
      {
        name: 'Team',
        id: '2',
        icon: (
          <>
            <FaUsers className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
          </>
        ),
        // action: async () => {
        //   return await handleFilterTourEvents('team');
        // },
        value: 'team',
        // active: Boolean(filterParams?.tour === 'team'),
      },
    ],
    [],
  );

  const { getFilterData, onClearAll } = useAllArtistsFilterSorting(
    tourOptions,
    menuOptions,
    fetchArtists,
    handleSortBy,
    handleFilterTourEvents,
  );

  const handleClearFilter = useCallback(async () => {
    await animateScroll.scrollTo(
      scrollRef?.current?.getBoundingClientRect().top + window.scrollY,
      {
        delay: 0,
        duration: 0,
        smooth: true,
      },
    );
    form.setFieldValue('search', undefined);
    setSearch(undefined);
    AntdForm.setFieldsValue({
      max: undefined,
      min: undefined,
      slider: [0, 0],
    });
    // setTabs('Sort By');
    onClose();
    onClearAll();
    return await clear_filter();
  }, [form, clear_filter, AntdForm, onClearAll]);

  const handleResetFilter = useCallback(async () => {
    handleClearFilter();
    onClose();
    return await reset_filter();
  }, [reset_filter, handleClearFilter]);

  useDebounce(
    () => {
      dispatch(
        updateFilter({
          ...filterParams,
          search_key: search === '' ? undefined : search,
        }),
      );
    },
    500,
    [search, filterParams],
  );

  useEffect(() => {
    return () => {
      dispatch(updateFilterPills({}));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(updateDefaultCount(1));
    dispatch(updateDefaultSortCount(0));
  }, [dispatch]);

  return (
    <>
      <div className="bg-color-base-1">
        <HelmetComponent title={'NFTGOTIX | Artist'} />
        <section className="">
          <div className="relative z-0">
            <div className="w-full py-4 bg-color-base-2 dark:bg-color-base-3">
              {/* Banner will come */}
              <ArtistBanner
                eventdetails={PublishedEvents?.banner}
                // userdetails={userdetails}
              />
            </div>
          </div>
        </section>
        <section ref={scrollRef} className="">
          <div className="bg-color-base-4 pb-4 flex justify-center min-h-screen">
            <div className="w-full px-3.5">
              <div className="py-7">
                <div className="container-fluid max-w-[1800px]">
                  <Row>
                    <Col lg={12} xxl={12}>
                      <div className="">
                        <div className="flex justify-between items-center">
                          <HeadingTitle>
                            <h2 className="text-2xl font-normal dark:text-gray-50 text-zinc-800">
                              All Artists/Teams
                            </h2>
                          </HeadingTitle>
                          {/* <div className="flex items-center gap-2">
                            <div>
                              <Form.Group className="relative" controlId="">
                                <Form.Control
                                  type="text"
                                  placeholder="Search"
                                  className="placeholder:text-stone-500 font-normal text-xs md:text-base bg-stone-800 text-white border-stone-800 focus:bg-stone-800 focus:bg-opacity-90 focus:outline-none focus:ring-0 focus-within:ring-transparent pr-10"
                                  onChange={(e) => {
                                    handleSearch(e.target.value);
                                    // setKey(e.target.value);
                                  }}
                                />
                                <div className="flex absolute inset-y-0 right-1">
                                  <button
                                    className="p-1.5 text-whiteNew-0 h-full rounded-md flex justify-center items-center text-base"
                                    type="button"
                                  >
                                    <CustomSearchIcon />
                                  </button>
                                </div>
                              </Form.Group>
                            </div>
                            <div className="">
                              <SliderFilterDropdown
                              // options={venueTypeData?.data}
                              // setTabs={setTabs}
                              // tabs={tabs}
                              />
                              {/* <ArtistSortBySelectFilter /> */}
                          {/* </div>
                          </div> */}
                        </div>
                        <div className="mt-6 ">
                          <div
                            className={`flex flex-wrap2 lg:flex-nowrap w-full items-center gap-2 sticky lg:top-[68px] z-20 bg-color-base-4 pb-3 ${afterBorder}`}
                          >
                            <div className={`hidden xl:flex`}>
                              <button
                                className={`w-[40px] h-[40px] ${btnSecondayClass}`}
                                onClick={() => {
                                  setShowFilter(!showFilter);
                                }}
                              >
                                <MdFilterList
                                  className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 duration-200 ease-in text-lg ${
                                    // showFilter ? '' : 'rotate-180'
                                    ''
                                  }`}
                                />
                              </button>
                            </div>
                            <Form
                              form={form}
                              onFinish={handleSubmit}
                              className="w-full"
                              autoComplete="off"
                              // ariaautocomplete="off"
                            >
                              <Form.Item className="mb-0" name="search">
                                <Input
                                  onChange={async (e) => {
                                    setSearch(e.target.value);
                                    if (e.target.value === '') {
                                      await dispatch(
                                        updateFilter({
                                          ...filterParams,
                                          search_key: undefined,
                                        }),
                                      );
                                      return await fetchArtists({});
                                    }
                                  }}
                                  placeholder="Search Artists/Teams"
                                  className={`${formClass} mb-0 border-level-2`}
                                />
                              </Form.Item>
                            </Form>
                            <div className="flex justify-end lg:justify-between flex-wrap gap-2 w-full lg:w-auto shrink-0">
                              <PopoverDropdown
                                content={
                                  <EventDetailDropdownFilter
                                    props={getFilterData(true)}
                                  />
                                }
                                isSorting={true}
                              />
                              {/* <div className="min-w-[200px] w-full lg:max-w-[200px]">
                                <SliderFilterDropdown
                                  options={menuOptions}
                                  setTabs={setTabs}
                                  tabs={tabs}
                                  extra_props={{
                                    all_mode: false,
                                    classname:
                                      'dark:bg-neutral-900 bg-transparent dark:hover:bg-neutral-800 hover:bg-zinc-200 py-[7px] px-2 rounded-[5px] dark:text-whiteNew-0 text-zinc-800 border-1 border-level-2',
                                  }}
                                  closeIcon={true}
                                  closeIconCallback={async () => {
                                    setTabs('Sort By');
                                    await handleSortBy(undefined);
                                  }}
                                />
                              </div> */}

                              <div className="flex xl:hidden shrink-0">
                                <Button
                                  className={`w-[40px] h-[40px] ${btnSecondayClass}`}
                                  onClick={() => {
                                    showDrawer();
                                  }}
                                >
                                  <BiFilterAlt
                                    className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 duration-200 ease-in text-lg `}
                                  />
                                </Button>
                              </div>
                              <div>
                                <div className="flex justify-end lg:justify-between flex-wrap gap-2 w-full h-full lg:w-auto shrink-0">
                                  {/* <div className="flex rounded-lg dark:bg-[#000] gap-1 divide-neutral-300 p-1">
                                    {tourOptions.map(
                                      ({ id, icon, action, name, active }) => (
                                        <Tooltip
                                          key={id + 'tour_option'}
                                          title={name}
                                        >
                                          <button
                                            onClick={action}
                                            className={`${
                                              active
                                                ? 'dark:bg-neutral-800 bg-lightTheme-90 dark:text-whiteNew-0 text-zinc-800'
                                                : 'dark:bg-neutral-900 bg-lightTheme-70 dark:text-neutral-400 text-zinc-800'
                                            }  leading-5 dark:hover:text-whiteNew-0 hover:text-zinc-900 dark:hover:bg-neutral-800 hover:bg-neutral-300 text-[15px] font-medium w-[31px] h-[31px] relative duration-150 ease-in rounded-md`}
                                          >
                                            {icon ?? name ?? ''}
                                          </button>
                                        </Tooltip>
                                      ),
                                    )}
                                  </div> */}
                                  <PopoverDropdown
                                    content={
                                      <EventDetailDropdownFilter
                                        props={getFilterData(false)}
                                      />
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <GridLayout scrollRef={scrollRef} />
                              </div>
                            </div>
                          </div>
                          <div
                            className={`flex relative py-3.5 flex-wrap flex-lg-nowrap h-full ${
                              showFilter ? 'lg:gap-4' : 'gap-0'
                            }`}
                          >
                            <div
                              className={`relative shrink-0 max-w-[230px] hidden xl:flex ${
                                showFilter ? 'w-full' : 'w-auto'
                              }`}
                            >
                              <div
                                // className="hidden xl:block xl:sticky lg:top-32 w-full shrink-0 h-full pt-[8px]"
                                className={`accordionWidth hidden xl:block xl:sticky lg:top-32 w-full shrink-0 h-full pt-[8px] duration-300 ease-in ${
                                  showFilter
                                    ? 'accordion-content opacity-100 w-full lg:max-w-[230px] 2xl:max-w-[230px]'
                                    : 'max-w-[0px]'
                                } `}
                              >
                                <div
                                  className={`${showFilter ? '' : 'opacity-0'}`}
                                >
                                  <FilterLeftSidebarV2
                                    filterParams={filterParams}
                                    filter_options={filter_options}
                                    // handlePriceSlider={handlePriceSlider}
                                    filterActions={{
                                      handleClearFilter,
                                      handleResetFilter,
                                      handleApplyFilter,
                                      callback: callbackFilter,
                                      disableApply,
                                      isFilterTouched,
                                      AntdForm,
                                    }}
                                    open={open}
                                    onClose={onClose}
                                    FILTER_CONST={FILTER_CONST}
                                  />

                                  {/* Need to Add these filters type:  Event List, Artist Type and Artist sub type */}
                                </div>
                              </div>
                            </div>
                            <div className="block relative overflow-hidden w-full">
                              <div className="">
                                <div className="">
                                  <FilterPills
                                    data={all_filters_pills}
                                    pillActions={{
                                      handleClearFilter,
                                      handleApplyFilter,
                                      removeSinglePill,
                                      filter_loading,
                                    }}
                                  />
                                </div>
                                <>
                                  <ShowArtistCardsMemo
                                    all_artist={all_artist}
                                    // all_artist_count={all_artist_list?.count}
                                    fetchArtist={fetchArtists}
                                    loadMore={loadMore}
                                    hasMore={hasMore}
                                    showSkeleton={showSkeleton}
                                    filter_loading={filter_loading}
                                    filter_settings={filter_settings}
                                  />
                                  {/* <InfiniteScroll
                                    dataLength={
                                      all_artist_list?.data?.length || 0
                                    }
                                    next={loadMore}
                                    hasMore={
                                      all_artist_list?.data?.length <
                                      all_artist_list?.count
                                    }
                                    className="overflow-x-hidden py-1.5"
                                    loader={
                                      <div className="flex justify-center items-center mt-2">
                                        <DataLoader
                                          darkTheme
                                          message={
                                            loaderMessage.loading_artists
                                          }
                                          minHeight={'min-h-[100px]'}
                                        />
                                      </div>
                                    }
                                  >
                                    <Row
                                      className={`row-cols-1 row-cols-sm-1 ${
                                        layout === LAYOUT2X2
                                          ? 'row-cols-md-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-3 row-cols-xxl-3'
                                          : layout === LAYOUT3X3
                                          ? 'row-cols-md-2 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-4'
                                          : 'row-cols-xxl-4'
                                      } g-3`}
                                    >
                                      {all_artist_list?.data?.map(
                                        (item, index) => (
                                          <Col>
                                            <div className="relative h-full">
                                              <OrganiserCardV2
                                                data={item}
                                                refetch={() =>
                                                  fetchArtist({
                                                    is_refetching: true,
                                                  })
                                                }
                                                // eventdetails={eventdetails}
                                                type={'artist'}
                                              />
                                            </div>
                                          </Col>
                                        ),
                                      )}
                                      {/* {!filterExplore ? (
                                <>
                                  {[...Array(10)].map((_, idx) => (
                                    <Col className="relative" key={idx}>
                                      <SkeletonAvatar className={''} />
                                    </Col>
                                  ))}
                                </>
                              ) : (
                                <>
                                  {filterExplore?.map((item, index) => {
                                    return index < viewMore ? (
                                      <Col key={index}>
                                        <div className="relative h-full">
                                          <EventCardV2 data={item} />
                                        </div>
                                      </Col>
                                    ) : null;
                                  })}{' '}
                                 
                                </>
                              )} */}
                                  {/* </Row>
                                  </InfiniteScroll> */}
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

const ShowArtistCards = ({
  all_artist,
  fetchArtist,
  loadMore,
  hasMore,
  showSkeleton,
  filter_loading,
  filter_settings,
}) => {
  const { view, layout } = filter_settings;
  // console.log(view, 'view');
  if (
    // exploreEventdetails?.isLoading ||
    showSkeleton ||
    (view === GRID && filter_loading)
  ) {
    return (
      <>
        <Row className="row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 row-cols-xxl-5 g-3">
          {[...Array(updateDataCountByViewportForEventCards() * 2)].map(
            (_, index) => {
              return (
                <Col key={index}>
                  <ArtistCardSkeleton />
                </Col>
              );
            },
          )}
        </Row>
      </>
    );
  }
  if (!filter_loading && all_artist?.length === 0) {
    return (
      <div className="">
        <>
          <div className="mt-2 min-h-[330px] flex items-center justify-center bg-color-base-1 bg-opacity-10 backdrop-blur-xl rounded-xl">
            <NoDataFound title={'No Artists found for selected filters.'} />
          </div>
        </>
      </div>
    );
  }
  if (view === LIST) {
    return (
      <AllArtistListView
        props={{
          all_artist,
          loadMore,
          hasMore,
          filter_loading,
        }}
      />
    );
  }
  return (
    <>
      <InfiniteScroll
        dataLength={all_artist?.length || 0}
        next={loadMore}
        hasMore={hasMore}
        className="overflow-x-hidden py-1.5"
        loader={
          <div className="flex justify-center items-center mt-2">
            <DataLoader
              darkTheme
              message={loaderMessage.loading_artists}
              minHeight={'min-h-[100px]'}
            />
          </div>
        }
      >
        <Row
          className={`row-cols-1 row-cols-sm-1 ${
            layout === LAYOUT2X2
              ? 'row-cols-md-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-3 row-cols-xxl-3'
              : layout === LAYOUT3X3
              ? 'row-cols-md-2 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-4'
              : 'row-cols-xxl-4'
          } g-3`}
        >
          {all_artist?.map((item, index) => (
            <Col>
              <div className="relative h-full">
                <OrganiserCardV2
                  all_artist={all_artist}
                  data={item}
                  refetch={() =>
                    fetchArtist({
                      is_refetching: true,
                    })
                  }
                  // eventdetails={eventdetails}
                  type={'artist'}
                  from="allArtist"
                />
              </div>
            </Col>
          ))}
        </Row>
      </InfiniteScroll>
    </>
  );
};

const ShowArtistCardsMemo = memo(ShowArtistCards);
