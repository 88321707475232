import {
  BaseMessageSignerWalletAdapter,
  isIosAndRedirectable,
  isVersionedTransaction,
  scopePollingDetectionStrategy,
  WalletAccountError,
  WalletConnectionError,
  WalletDisconnectedError,
  WalletDisconnectionError,
  WalletError,
  WalletNotConnectedError,
  WalletNotReadyError,
  WalletPublicKeyError,
  WalletReadyState,
  WalletSendTransactionError,
  WalletSignMessageError,
  WalletSignTransactionError,
} from '@solana/wallet-adapter-base';

import { PublicKey } from '@solana/web3.js';

// interface PhantomWallet extends EventEmitter<PhantomWalletEvents> {
//     isPhantom?: boolean;
//     publicKey?: { toBytes(): Uint8Array };
//     isConnected: boolean;
//     signTransaction<T extends Transaction | VersionedTransaction>(transaction: T): Promise<T>;
//     signAllTransactions<T extends Transaction | VersionedTransaction>(transactions: T[]): Promise<T[]>;
//     signAndSendTransaction<T extends Transaction | VersionedTransaction>(
//         transaction: T,
//         options?: SendOptions
//     ): Promise<{ signature: TransactionSignature }>;
//     signMessage(message: Uint8Array): Promise<{ signature: Uint8Array }>;
//     connect(): Promise<void>;
//     disconnect(): Promise<void>;
// }

// interface PhantomWindow extends Window {
//     phantom?: {
//         solana?: PhantomWallet;
//     };
//     solana?: PhantomWallet;
// }

// declare const window: PhantomWindow;

// export interface PhantomWalletAdapterConfig {}

export const ArtchiveWalletName = 'ARTC Wallet';

export class ArtchiveWalletAdapter extends BaseMessageSignerWalletAdapter {
  name = ArtchiveWalletName;
  url = 'https://dev.artchivewallet.com';
  icon =
    'https://nltmarketplace.s3.us-west-1.amazonaws.com/Techrefic+wallet+logo+1.png';
  supportedTransactionVersions = new Set(['legacy', 0]);

  // private _connecting: boolean;
  // private _wallet: PhantomWallet | null;
  // private _publicKey: PublicKey | null;
  _readyState =
    typeof window === 'undefined' || typeof document === 'undefined'
      ? WalletReadyState.Unsupported
      : WalletReadyState.NotDetected;

  constructor(config = {}) {
    super();
    this._connecting = false;
    this._wallet = null;
    this._publicKey = null;

    if (this._readyState !== WalletReadyState.Unsupported) {
      if (isIosAndRedirectable()) {
        // when in iOS (not webview), set Phantom as loadable instead of checking for install
        this._readyState = WalletReadyState.Loadable;
        this.emit('readyStateChange', this._readyState);
      } else {
        if (this._readyState !== WalletReadyState.Unsupported) {
          scopePollingDetectionStrategy(() => {
            if (window.$artchive?.solana?.isArtChive || window.solana?.isArtChive) {
              this._readyState = WalletReadyState.Installed;
              this.emit('readyStateChange', this._readyState);
              return true;
            }
            return false;
          });
        }
      }
    }
  }

  get publicKey() {
    return this._publicKey;
  }

  get connecting() {
    return this._connecting;
  }

  get readyState() {
    return this._readyState;
  }

  async autoConnect() {
    // Skip autoconnect in the Loadable state
    // We can't redirect to a universal link without user input
    if (this.readyState === WalletReadyState.Installed) {
      await this.connect();
    }
  }

  async connect() {
    try {
      if (this.connected || this.connecting) return;

      if (this.readyState === WalletReadyState.Loadable) {
        // redirect to the Phantom /browse universal link
        // this will open the current URL in the Phantom in-wallet browser
        // const url = encodeURIComponent(window.location.href);
        // const ref = encodeURIComponent(window.location.origin);
        window.location.href = `https://dev.artchivewallet.com`;
        return;
      }

      if (this.readyState !== WalletReadyState.Installed) throw new WalletNotReadyError();

      this._connecting = true;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const wallet = window.$artchive?.solana || window.solana
      //  || window.solana!;

      if (!wallet.isConnected) {
        try {
          await wallet.connect();
        } catch (error) {
          throw new WalletConnectionError(error?.message, error);
        }
      }

      if (!wallet.publicKey) throw new WalletAccountError();

      let publicKey;
      try {
        publicKey = new PublicKey(wallet.publicKey.toBytes());
      } catch (error) {
        throw new WalletPublicKeyError(error?.message, error);
      }

      wallet.on('disconnect', this._disconnected);
      wallet.on('accountChanged', this._accountChanged);

      this._wallet = wallet;
      this._publicKey = publicKey;

      this.emit('connect', publicKey);
    } catch (error) {
      this.emit('error', error);
      throw error;
    } finally {
      this._connecting = false;
    }
  }

  async disconnect() {
    const wallet = this._wallet;
    if (wallet) {
      wallet.off('disconnect', this._disconnected);
      wallet.off('accountChanged', this._accountChanged);

      this._wallet = null;
      this._publicKey = null;

      try {
        await wallet.disconnect();
      } catch (error) {
        this.emit('error', new WalletDisconnectionError(error?.message, error));
      }
    }

    this.emit('disconnect');
  }

  async sendTransaction(
    transaction,
    connection,
    options = {}
  ) {
    try {
      const wallet = this._wallet;
      if (!wallet) throw new WalletNotConnectedError();

      try {
        const { signers, ...sendOptions } = options;

        if (isVersionedTransaction(transaction)) {
          signers?.length && transaction.sign(signers);
        } else {
          transaction = (await this.prepareTransaction(transaction, connection, sendOptions));
          signers?.length && (transaction).partialSign(...signers);
        }

        sendOptions.preflightCommitment = sendOptions.preflightCommitment || connection.commitment;

        const { signature } = await wallet.signAndSendTransaction(transaction, sendOptions);
        return signature;
      } catch (error) {
        if (error instanceof WalletError) throw error;
        throw new WalletSendTransactionError(error?.message, error);
      }
    } catch (error) {
      this.emit('error', error);
      throw error;
    }
  }

  async signTransaction(transaction) {
    try {
      const wallet = this._wallet;
      if (!wallet) throw new WalletNotConnectedError();

      try {
        return (await wallet.signTransaction(transaction)) || transaction;
      } catch (error) {
        throw new WalletSignTransactionError(error?.message, error);
      }
    } catch (error) {
      this.emit('error', error);
      throw error;
    }
  }

  async signAllTransactions(transactions) {
    try {
      const wallet = this._wallet;
      if (!wallet) throw new WalletNotConnectedError();

      try {
        return (await wallet.signAllTransactions(transactions)) || transactions;
      } catch (error) {
        throw new WalletSignTransactionError(error?.message, error);
      }
    } catch (error) {
      this.emit('error', error);
      throw error;
    }
  }

  async signMessage(message) {
    try {
      const wallet = this._wallet;
      if (!wallet) throw new WalletNotConnectedError();

      try {
        const { signature } = await wallet.signMessage(message);
        return signature;
      } catch (error) {
        throw new WalletSignMessageError(error?.message, error);
      }
    } catch (error) {
      this.emit('error', error);
      throw error;
    }
  }

  _disconnected = () => {
    const wallet = this._wallet;
    if (wallet) {
      wallet.off('disconnect', this._disconnected);
      wallet.off('accountChanged', this._accountChanged);

      this._wallet = null;
      this._publicKey = null;

      this.emit('error', new WalletDisconnectedError());
      this.emit('disconnect');
    }
  };

  _accountChanged = (newPublicKey) => {
    const publicKey = this._publicKey;
    if (!publicKey) return;

    try {
      newPublicKey = new PublicKey(newPublicKey.toBytes());
    } catch (error) {
      this.emit('error', new WalletPublicKeyError(error?.message, error));
      return;
    }

    if (publicKey.equals(newPublicKey)) return;

    this._publicKey = newPublicKey;
    this.emit('connect', newPublicKey);
  };
}