import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { check_email, check_phone } from '../../../../helpers/api/auth/auth';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../../features/auth.slice';
import { Form } from 'antd';
import { CountryCodes } from '../../../../utils/CountryCodes';
import Image from '../../../common/Image';

export function useLogin() {
  const [loginState, setLoginState] = useState({
    isCrudeStep: true,
    showPasswordField: false,
    user_type: null,
  });
  const dispatch = useDispatch();
  const authUser = useSelector((x) => x.auth);
  const [form] = Form.useForm();
  const [blockedUser, setBlockedUser] = useState('');

  const phones = useMemo(
    () =>
      CountryCodes?.map(({ code, phone, label }) => ({
        key: code,
        value: `${code}+${phone}`,
        label: (
          <span className="flex items-center gap-2 dark:text-whiteNew-0 text-zinc-800">
            <Image
              className="h-4"
              src={`https://flagcdn.com/w20/${String(code).toLowerCase()}.png`}
            />
            {/* {code}  */}+{phone}
          </span>
        ),
      })),
    [],
  );

  const {
    mutate,
    isLoading: checkingEmail,
    isError: checkingEmailError,
    error,
    data,
    reset,
  } = useMutation((email) => check_email(email, 'Login', '?type=login'), {
    onSuccess: (data) => {
      if (!data?.status) {
        if (data?.is_password_set === false) {
          return setLoginState({
            ...loginState,
            isCrudeStep: true,
            showPasswordField: false,
            is_password_set: false,
            token: data?.mailToken
          });
        }
        if (data?.email_verified === false) {
          return setLoginState({
            ...loginState,
            isCrudeStep: true,
            showPasswordField: false,
            is_password_set: null,
            email_verified: false,
          });
        }
        return setLoginState({
          ...loginState,
          isCrudeStep: false,
          showPasswordField: true,
          user_type: data?.user_type,
        });
      } else if (data?.status) {
        if (data?.block_reason) {
          setBlockedUser(data?.message);
        }
      }
    },
  });

  const {
    mutate: isRegisteredPhoneFn,
    isLoading: checkingPhone,
    isError: checkingPhoneError,
    error: phoneError,
    data: phoneRes,
    reset: resetPhone,
  } = useMutation(
    ({ creds, phone_code }) => {
      return check_phone(creds, phone_code);
    },
    {
      onSuccess: (data) => {
        if (data) {
          return setLoginState({
            ...loginState,
            isCrudeStep: false,
            showPasswordField: true,
          });
        }
      },
    },
  );

  const resetAll = useCallback(() => {
    reset();
    resetPhone();
    setBlockedUser('');
    dispatch(authActions.resetState());
  }, [dispatch, reset, resetPhone]);

  const toggleCrudeStep = useCallback(() => {
    resetAll();
    setLoginState({
      ...loginState,
      isCrudeStep: !loginState.isCrudeStep,
      showPasswordField: false,
    });
  }, [loginState, resetAll]);

  const { isLoading, isError, errored } = useMemo(
    () => ({
      isLoading: Boolean(authUser?.loading || checkingEmail || checkingPhone),
      isError: Boolean(
        checkingEmailError ||
        authUser?.error ||
        (typeof data?.status === 'boolean' && data?.status) ||
        (typeof phoneRes === 'boolean' && !phoneRes) ||
        checkingPhoneError,
      ),
      errored:
        blockedUser !== ''
          ? blockedUser
          : authUser?.error?.message ||
          error?.response?.data?.message ||
          phoneError?.response?.data?.message ||
          'No account is found.',
    }),
    [
      authUser?.loading,
      checkingEmail,
      authUser?.error,
      checkingEmailError,
      error?.response?.data?.message,
      data,
      checkingPhone,
      phoneError?.response?.data?.message,
      phoneRes,
      checkingPhoneError,
      blockedUser,
    ],
  );

  console.log('errored', errored);

  useEffect(() => {
    if (isError) {
      form.resetFields(['password']);
    }
  }, [form, isError]);

  return useMemo(
    () => ({
      loginState,
      toggleCrudeStep,
      isRegisteredEmailFn: mutate,
      isRegisteredPhoneFn,
      resetAll,
      isLoading,
      isError,
      errored,
      form,
      phones,
    }),
    [
      toggleCrudeStep,
      mutate,
      resetAll,
      isRegisteredPhoneFn,
      loginState,
      isLoading,
      isError,
      errored,
      form,
      phones,
    ],
  );
}
