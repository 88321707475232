import {
  useWallet,
  WalletProvider as BaseWalletProvider,
} from '@solana/wallet-adapter-react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useConnection } from './ConnectionProvider';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import { ArtchiveWalletAdapter } from './artchive/adapter';
import { message } from 'antd';

export const WalletModalContext = createContext();

export function useWalletModal() {
  return useContext(WalletModalContext);
}

export const WalletModalProvider = ({
  children,
  txError,
  setTxError,
  wallets,
}) => {
  const { publicKey } = useWallet();
  const connection = useConnection();

  const [selectedWallet, setSelectedWallet] = useState(null);

  // const decodedToken = mydecodedTokenFunction(
  //   user ? user?.token : localStorage.getItem('token'),
  // );
  // const decodedToken = useDecodedToken();
  const [bal, setBal] = useState(0);
  // const deviceInfo = useDeviceData();

  const [connected, setConnected] = useState(!!publicKey);
  // const decodedToken = useDecodedToken();

  useEffect(() => {
    if (!publicKey && connected) {
      message.error('Disconnected from wallet');
      // enqueueSnackbar(`Disconnected from wallet`, {
      //   variant: 'error',
      // });
    }
    setConnected(!!publicKey);
  }, [publicKey, connected, setConnected]);

  const refreshFunds = useCallback(() => {
    if (publicKey) {
      // setError("");
      // setLoading(true);
      // enqueueSnackbar(`Refreshing wallet....`, {
      //   variant: 'info',
      // });
      async function getBalance() {
        try {
          const balance = await connection.getBalance(publicKey);
          setBal(balance / LAMPORTS_PER_SOL);
          // message.success("Wallet synced successfully. Balance updated!!")
          // enqueueSnackbar(`Wallet updated successfully`, {
          //   variant: 'success',
          // });
          // setLoading(false);
        } catch (e) {
          setBal(0);
          message.error(e.toString());
          // enqueueSnackbar(
          //   `Error while updating wallet! Please ensure your network connection`,
          //   {
          //     variant: 'error',
          //   },
          // );
          // setLoading(false);
        }
      }
      getBalance();
    } 
  }, [publicKey, connection]);
  useEffect(() => {
    if (publicKey) {
      refreshFunds();
    }
    setConnected(!!publicKey);
  }, [publicKey, connected, setConnected, refreshFunds]);

  return (
    <WalletModalContext.Provider
      value={{
        bal,
        refreshFunds,
        txError,
        setTxError,
        wallets,
        setSelectedWallet,
        selectedWallet,
      }}
    >
      {children}
    </WalletModalContext.Provider>
  );
};

export const WalletProvider = ({ children }) => {
  const [txError, setTxError] = useState();
  const wallets = useMemo(
    () => [
      new ArtchiveWalletAdapter(),
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
    ],
    [],
  );

  const onError = useCallback((error) => {
    setTxError(error?.toString());
    // message.error(error?.toString())
  }, []);

  return (
    <>
      <BaseWalletProvider wallets={wallets} autoConnect onError={onError}>
        <WalletModalProvider
          wallets={wallets}
          txError={txError}
          setTxError={setTxError}
        >
          {children}
        </WalletModalProvider>
      </BaseWalletProvider>
    </>
  );
};
