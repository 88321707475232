import React from 'react';
import RightSideSectionWrapper from '../RightSideSectionWrapper';
import ToogleSwitch from '../../util/ToogleSwitch';
import HeaderFillTop from '../HeaderFillTop';
import TableComponentv2 from '../../common/TableComponentv2';
import Tooltip from '../../common/Tooltip';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import { durationAnimate } from '../../common/DefaultClasses';

export default function FavoriteListView() {
  const dataSource = [
    {
      key: '1',
      event_name: (
        <>
          <div className="flex items-center flex-wrap gap-3">
            <div className="min-w-0 2xl:flex-auto space-y-1 capitalize">
              <span className="text-bluev2-600 dark:text-slate-50 flex items-center gap-2">
                Event Name
              </span>
            </div>
          </div>
        </>
      ),
      start_date: (
        <>
          <span>08/25/23 - 13:43</span>
        </>
      ),
      end_date: (
        <>
          <span>08/25/23 - 22:43</span>
        </>
      ),
      event_type: <span className="flex">Virtual</span>,
      status: (
        <>
          <span className="flex">
            <span className="flex border-1 py-0.5 border-red-600 bg-red-100 text-red-600 px-2 rounded-full">
              Completed
            </span>
          </span>
        </>
      ),
      action: (
        <>
          <span className="flex items-center">
            {/* <Tooltip title={'Edit'} placement={'top'}>
              <button
                className={`p-1.5 text-gray-600 hover:text-bluev2-600 text-lg ${durationAnimate}`}
              >
                <FaRegEdit />
              </button>
            </Tooltip> */}
            <Tooltip title={'Delete'} placement={'top'}>
              <button
                className={`p-1.5 text-gray-600 hover:text-rose-700 text-lg ${durationAnimate}`}
              >
                <FaRegTrashAlt />
              </button>
            </Tooltip>
          </span>
        </>
      ),
    },
  ];

  const columns = [
    {
      title: <span className="text-gray-500">Event Name</span>,
      dataIndex: 'event_name',
      key: 'event_name',
      width: '200px',
    },
    {
      title: <span className="text-gray-500">Start Date</span>,
      dataIndex: 'start_date',
      key: 'start_date',
      width: '150px',
    },
    {
      title: <span className="text-gray-500">End Date</span>,
      dataIndex: 'end_date',
      key: 'end_date',
      width: '150px',
    },
    {
      title: (
        <span className="text-gray-500 inline-block pr-8">Event Type</span>
      ),
      dataIndex: 'event_type',
      key: 'event_type',
      width: '150px',
    },
    {
      title: <span className="text-gray-500">Status</span>,
      dataIndex: 'status',
      key: 'status',
      width: '150px',
    },
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: 'action',
      key: 'action',
      width: '100px',
    },
    // {
    //   title: <span className="text-gray-900">Action</span>,
    //   dataIndex: 'Action',
    //   key: 'Action',
    //   width: '50',
    // },
    // {
    //   title: <span className="text-gray-500">Invoice</span>,
    //   dataIndex: 'Invoice',
    //   key: 'Invoice',
    // },
  ];
  return (
    <React.Fragment>
      <div>
        <div className="">
          <HeaderFillTop title={'Favorite'} extraPills={<></>} extra={<></>} />
        </div>
        <RightSideSectionWrapper>
          <section>
            <div className="block overflow-hidden" style={{ flex: '0 0 75%' }}>
              <div className="w-full">
                <TableComponentv2 dataSource={dataSource} columns={columns} />
              </div>
            </div>
          </section>
        </RightSideSectionWrapper>
      </div>
    </React.Fragment>
  );
}
