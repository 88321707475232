import { useMutation } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { forget_password, resend_email } from '../../../helpers/api/auth/auth';
import { history } from '../../../helpers/router_templates/history';
import {
  EmailIconVerificationSuccess,
  EmailVerificationFailed,
  LinkBroken,
} from '../../util/CustomIcons';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { decodeToken } from 'react-jwt';

export default function VerifyStatus({ type, auth = null, darkTheme }) {
  const query = useSearchQuery();
  const decodedToken = useMemo(() => decodeToken(query.get('token')), [query]);

  const mutation = useMutation(insertNote, {
    onSuccess: () => {
      if (auth === 'reset') {
        history.navigate(
          `/auth/set-password/confirm?email=${decodedToken?.email}`,
        );
      } else {
        history.navigate(
          `/auth/email-confiramtion?email=${decodedToken?.email}`,
        );
      }
    },
  });

  if (type === 'reset') {
    return <ResetPasswordSuccess />;
  }

  function insertNote() {
    if (auth === 'reset') {
      return forget_password({ email: decodedToken?.email });
    }
    return resend_email({
      email: decodedToken?.email,
    });
  }

  const onSubmit = (data) => {
    if (decodedToken?.email) {
      mutation.mutate();
    }
  };
  return (
    <React.Fragment>
      <div className="flex justify-center items-center flex-col text-center">
        <div className="mb-3">
          {type === 'expired' && (
            <LinkBroken className={'w-20 h-20 lg:w-28 lg:h-28 textSuccess'} />
          )}
          {type === 'success' && (
            <EmailIconVerificationSuccess
              className={'w-20 h-20 lg:w-28 lg:h-28 textSuccess'}
            />
          )}
          {type === 'failure' && (
            <EmailVerificationFailed
              className={'w-20 h-20 lg:w-28 lg:h-28 textSuccess'}
            />
          )}
        </div>
        {mutation.isError && (
          <Form.Text className="block fw-bold text-center mb-2 text-danger">
            {mutation.error.message
              ? ` ${mutation.error.response?.data?.message}`
              : // ? mutation.error.message +` (${mutation.error.response?.data?.message})`
                mutation.error}
          </Form.Text>
        )}
        <h1 className="fw-medium text-lg 2xl:text-xl dark:text-purple-500 text-sky-600">
          {type === 'expired' && 'Oopss!'}
          {type === 'success' && <>Your Email Id is verified successfully!</>}
          {type === 'failure' && 'Verification failed'}
        </h1>
        <p className="dark:text-gray-300 text-zinc-800 w-2/3 mt-3">
          {type === 'expired'
            ? 'Your Link has been Expired'
            : type === 'failed' && 'Please check your details'}
          {type === 'success' && <>Please login to set up your account.</>}
        </p>
      </div>
      <div className="space-y-4 max-w-screen-lg w-full py-3">
        <div className="mt-4">
          <div className="mt-4 flex justify-center items-center">
            {(type === 'expired' || type === 'failure') && (
              <button
                onClick={() => onSubmit()}
                className={`${
                  darkTheme
                    ? 'dark:bg-landingPurple-60 bg-sky-600 hover:bg-sky-700 text-gray-50 dark:hover:bg-landingPurple-70'
                    : 'bg-sky-600 text-gray-50 hover:bg-sky-700'
                } p-2.5 rounded-lg duration-200 ease-in w-full flex items-center justify-center md:w-2/3`}
              >
                Resend Email
              </button>
            )}
            {type === 'success' && (
              <Link
                to="/user/login"
                className={`${
                  darkTheme
                    ? 'dark:bg-landingPurple-60 bg-sky-600 hover:bg-sky-700 text-gray-50 dark:hover:bg-landingPurple-70'
                    : 'bg-sky-600 text-gray-50 hover:bg-sky-700'
                } p-2.5 rounded-lg duration-200 ease-in w-full flex items-center justify-center md:w-2/3`}
              >
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
