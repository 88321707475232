import { Skeleton } from 'antd';
import React from 'react';

export default function TitleImg({ img, title, subTitle, isLoading }) {
  return (
    <React.Fragment>
      <div className="flex items-center space-x-4">
        {img ? (
          <>
            {!img === '' ? (
              <div className="w-10 h-10 lg:w-10 lg:h-10 bg-slate-300 rounded-full">
                <img
                  src="https://tailwindui.com/img/avatar-1.jpg"
                  alt=""
                  width="40px"
                  height="40px"
                  className="flex-none rounded-full bg-slate-100 object-cover h-full w-full"
                  loading="lazy"
                />
              </div>
            ) : (
              <div className="w-10 h-10 md:w-10 md:h-10 lg:w-10 lg:h-10 bg-slate-300 rounded-full"></div>
            )}
          </>
        ) : (
          <></>
        )}

        <div className="min-w-0 flex-auto font-semibold flex flex-col leading-0">
          {title ? (
            <span className="text-gray-400 text-xs truncate font-normal">
              {title}
            </span>
          ) : (
            <></>
          )}
          {isLoading ? (
            <>
              <Skeleton.Input active={true} size={'default'} block={true} />
            </>
          ) : (
            <>
              {subTitle ? (
                <span className=" dark:text-slate-50 text-md font-medium">
                  {subTitle}
                </span>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
