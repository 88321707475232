import React from 'react';
import { BsCreditCard2Front, BsInfoCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import LeftSideBar from '../../common/LeftSideBar';
import { AiOutlineSetting } from 'react-icons/ai';
import { MdOutlineEditLocation } from 'react-icons/md';

export default function ProfileSidebar({
  activeSection,
  setActiveSection,
  activeSubSection,
  setActiveSubSection,
}) {
  const navigate = useNavigate();
  const handleNavigation = (href) => {
    navigate(href);
  };
  return (
    <React.Fragment>
      <LeftSideBar
        title={
          <>
            <span className="flex items-center gap-2">
              <span>Profile Settings</span>
            </span>
          </>
        }
        secondarySidebar={true}
        // open={true}
        openAccordActive={activeSection === 'profile' ? true : false}
        accordOnClick={() => {
          setActiveSection('profile');
        }}
      />
      <LeftSideBar
        title={
          <>
            <span className="flex items-center gap-2">
              <span>Contact Info</span>
            </span>
          </>
        }
        secondarySidebar={true}
        openAccordActive={activeSection === 'contactInfo' ? true : false}
        accordOnClick={() => {
          setActiveSection('contactInfo');
        }}
      />
      {/* <LeftSideBar
        title={
          <>
            <span className="flex items-center gap-2">
              <span>Security Settings</span>
            </span>
          </>
        }
        secondarySidebar={true}
        // open={true}
        openAccordActive={activeSection === 'security' ? true : false}
        accordOnClick={() => {
          setActiveSection('security');
        }}
      /> */}
      <LeftSideBar
        title={
          <>
            <span className="flex items-center gap-2">
              {/* <BsCreditCard2Front className="w-5 h-5" /> */}
              <span>Security Settings</span>
            </span>
          </>
        }
        menu={[
          {
            name: 'Default',
            href: '#',
            // icon: (
            //   <>
            //     <BsInfoCircle />
            //   </>
            // ),
            active:
              activeSection === 'security' && activeSubSection === 'default'
                ? true
                : false,
            onClick: (e) => {
              // handleNavigation('#');
              e.stopPropagation();
              setActiveSubSection('default');
            },
          },
          {
            name: 'Optional',
            href: '#',
            // icon: (
            //   <>
            //     <MdOutlineEditLocation />
            //   </>
            // ),
            active:
              activeSection === 'security' && activeSubSection === 'optional'
                ? true
                : false,
            onClick: (e) => {
              // handleNavigation('#');
              e.stopPropagation();
              setActiveSubSection('optional');
            },
          },
        ]}
        secondarySidebar={true}
        openAccordActive={activeSection === 'security' ? true : false}
        accordOnClick={() => {
          setActiveSection('security');
          setActiveSubSection('default');
        }}
      />
    </React.Fragment>
  );
}
