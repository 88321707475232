const pageSizeComponent = {
  crypto_wallet: 'about_crypto_wallet',
  usd_wallet: 'about_usd_wallet',
  credit_card_trans: 'about_credit_card_trans',
  about_favourite: 'about_favourite',
  about_saved: 'about_saved',
  about_artist: 'about_artist',
  tools_venue: 'tools_venue',
  tools_sample_venue: 'tools_sample_venue',
  tools_ticket_type: 'tools_ticket_type',
  tools_system_ticket_type: 'tools_system_ticket_type',
  tools_ticket_design: 'tools_ticket_design',
  tools_system_ticket_design: 'tools_system_ticket_design',
  tools_artist: 'tools_artist',
  tools_system_artist: 'tools_system_artist',
  eventTabcurrent: 'event_tab_current',
  eventTabpast: 'event_tab_past',
  eventTabdraft: 'event_tab_draft',
  eventTaball: 'event_tab_all',
  tourTabCurrent: 'tour_tab_current',
  tourTabPast: 'tour_tab_past',
  tourTabDraft: 'tour_tab_draft',
  tourTabAll: 'tour_tab_all',
  tourEventTabcurrent: 'tour_event_tab_current',
  tourEventTabpast: 'tour_event_tab_past',
  tourEventTabdraft: 'tour_event_tab_draft',
  tourEventTaball: 'tour_event_tab_all',
  tourModalEvent: 'tour_modal_events',
  event_summary_reports: 'event_summary_reports',
  event_summary_redeemed_all: 'event_summary_redeemed_all',
  event_summary_redeemed_INP: 'event_summary_redeemed_INP',
  event_summary_redeemed_VIR: 'event_summary_redeemed_VIR',
  analytics_reports: 'analytics_reports',
};

export default pageSizeComponent;
