// import { useSelector } from 'react-redux';
import mydecodedTokenFunction from '../utils/DecodedToken';

export default function useDecodedToken() {
  const decodedToken = mydecodedTokenFunction(localStorage.getItem('token'));
  localStorage.setItem('userID', JSON.stringify(decodedToken?.id));
  return decodedToken;
}

export function useApiHeader() {
  let json_header = {
    headers: {
      'content-type': 'application/json',
    },
  };

  if (localStorage.getItem('token')) {
    json_header = {
      ...json_header,
      headers: {
        ...json_header?.headers,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
  }

  const form_header = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  // const json_header = {
  //   headers: {
  //     'content-type': 'application/json',
  //     Authorization: `Bearer ${localStorage.getItem('token')}`,
  //   },
  // };

  return { form_header, json_header };
}
