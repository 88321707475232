import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  tab: {
    order: true,
    resale: false,
    transfer: false,
    cancel: false,
    streaming: false,
  },
  policyMode: {
    all: true,
    resaleOnlyMode: false,
    refundOnlyMode: false,
    transferOnlyMode: false,
  },
  selectedPolicyIndex: 0,
  open_policy_drawer: false,
  event_type: 'all',
  selectedOrderType: 'individual',
  event_status: 'all',
  order_type: 'event',
  showPolicy: false,
  orderPage: 1,
  policydata: [],
  logsData: [],
  isLoadingLogs: false,
  data: { booked_tickets: [] },
  ticket: {},
  logs_mode: 'order',
  openSessions: false,
  all_orders: { data: [], count: 0 },
  ticket_count: {
    all: 0,
    upcoming: 0,
    completed: 0,
    ongoing: 0,
  },
  selected_order_event_id: '',
  orders_filters: {
    dropdown_filters: ['All', 'Upcoming', 'Ongoing', 'Completed'],
    tab_filters: ['All', 'Virtual', 'In-Person'],
    order_sorters: ['Sort by Event', 'Sort by Order'],
  },
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    updateAllOrders: (state, { payload }) => {
      state.all_orders = payload;
    },
    setSelectedOrder: (state, { payload }) => {
      state.data = payload;
    },
    setSelectedTicket: (state, { payload }) => {
      state.ticket = payload;
    },
    setLogsMode: (state, { payload }) => {
      state.logs_mode = payload;
    },
    setIsOpen: (state, { payload }) => {
      state.isOpen = payload;
    },
    setTab: (state, { payload }) => {
      state.tab = payload;
    },
    setPolicyMode: (state, { payload }) => {
      state.policyMode = payload;
    },
    setShowPolicy: (state, { payload }) => {
      state.showPolicy = payload;
    },
    setEventType: (state, { payload }) => {
      state.event_type = payload;
    },
    setEventStatus: (state, { payload }) => {
      state.event_status = payload;
    },
    setOrderType: (state, { payload }) => {
      state.order_type = payload;
    },
    setIsOpenPolicyDrawer: (state, { payload }) => {
      state.open_policy_drawer = payload;
    },
    setPolicydata: (state, { payload }) => {
      state.policydata = payload;
    },
    setSelectedPolicyIndex: (state, { payload }) => {
      state.selectedPolicyIndex = payload;
    },
    setOrderPage: (state, { payload }) => {
      state.orderPage = payload;
    },
    setTicketCount: (state, { payload }) => {
      state.ticket_count = payload;
    },
    setLogsData: (state, { payload }) => {
      state.logsData = payload;
    },
    setIsLoadingLogs: (state, { payload }) => {
      state.isLoadingLogs = payload;
    },
    setOpenSessions: (state, { payload }) => {
      state.openSessions = payload;
    },
    setSelectedOrderEventId: (state, { payload }) => {
      state.selected_order_event_id = payload;
    },
    setSelectedOrderType: (state, { payload }) => {
      state.selectedOrderType = payload;
    },
  },
});

let orderReducer = slice.reducer;

export const {
  updateAllOrders,
  setIsOpen,
  setTab,
  setPolicyMode,
  setShowPolicy,
  setSelectedOrder,
  setEventType,
  setEventStatus,
  setIsOpenPolicyDrawer,
  setPolicydata,
  setSelectedPolicyIndex,
  setOrderPage,
  setTicketCount,
  setSelectedTicket,
  setLogsMode,
  setLogsData,
  setIsLoadingLogs,
  setOpenSessions,
  setOrderType,
  setSelectedOrderEventId,
  setSelectedOrderType,
} = slice.actions;
const getAllOrders = (state) => state.orders;

export { orderReducer, getAllOrders };
