import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FloatingLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import {
  check_phone,
  forget_password,
  forget_password_phone,
} from '../../../helpers/api/auth/auth';
import { history } from '../../../helpers/router_templates/history';
import { EMAIL_REG } from '../../../utils/util';
import { SpinnerBtn } from '../../util/CustomIcons';
import {
  antCustomSelect,
  formClass,
  primaryBtnClassGradient,
  primaryLoginBtnClassGradient,
} from '../../common/DefaultClasses';
import { Form, Input, Select, message } from 'antd';
import { useLogin } from '../login/hooks/useLogin';
import { BsChevronDown } from 'react-icons/bs';
import NoDataFound from '../../common/NoDataFound';
import { RiListCheck2 } from 'react-icons/ri';
import { isValidPhoneNumber } from 'react-phone-number-input';
import ReactInputVerificationCode from 'react-input-verification-code';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../../../helpers/firebase/firebase';

export default function ResetPassword({ darkTheme }) {
  // const initValues = {
  //   email: '',
  // };
  // const [initialValues] = useState(initValues);
  const [form] = Form.useForm();
  const creds = Form.useWatch('credential', form);
  const phone_code = Form.useWatch('phone_code', form);
  const isPhoneNumber = useMemo(
    () => Boolean(creds && /^\d+$/.test(creds)),
    [creds],
  );
  const { phones } = useLogin();
  const [otp, setOtp] = useState();
  const { state } = useLocation();
  const onOtpComplete = useCallback(() => {
    return window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        message.success('OTP verified successfully.');
        setOtp();
        const response = await forget_password_phone({
          country_code: phone_code,
          contact: creds,
        });
        if (response?.status) {
          return history.navigate(
            `/auth/reset-password/confirm?contact=${phone_code + creds}`,
          );
        }
      })
      .catch((err) => {
        message.error('Wrong OTP! ');
        setOtp();
      });
  }, [creds, otp, phone_code]);

  const handleSubmit = useCallback(() => {
    if (isPhoneNumber) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        'sign-in-button',
        {
          size: 'invisible',
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
        },
        auth,
      );
      const appVerifier = window.recaptchaVerifier;
      return signInWithPhoneNumber(
        auth,
        `+${phone_code?.split('+')[1] + creds}`,
        appVerifier,
        // recaptchaVerifier,
      )
        .then((res) => {
          window.confirmationResult = res;
          // setPhoneOpen(true);
          message.success(`Otp has been sent to your registered Mobile number`);
        })
        .catch((err) => {
          // recaptchaVerifier.clear();
          appVerifier.clear();
          message.error(err.toString());
        });
      // return forget_password_phone({
      //   country_code: phone_code,
      //   contact: creds,
      // });
    }
    return forget_password({ email: creds });
  }, [creds, isPhoneNumber, phone_code]);

  const mutation = useMutation(handleSubmit, {
    onSuccess: () => {
      if (isPhoneNumber) {
        return;
      }
      return history.navigate(`/auth/reset-password/confirm?email=${creds}`);
    },
  });

  const otpMutation = useMutation(onOtpComplete, {
    onSuccess: () => {},
  });

  const handleFormSubmission = useCallback(() => {
    return mutation.mutate();
  }, [mutation]);

  const handleOtpSubmission = useCallback(() => {
    return otpMutation.mutate();
  }, [otpMutation]);

  return (
    <React.Fragment>
      <div className="flex justify-center items-center flex-col text-center">
        <h1 className="fw-medium text-lg 2xl:text-xl dark:text-purple-500 text-sky-600">
          It's Okay! Reset your password!
        </h1>
        <p className="text-gray-500 max-w-sm mt-3">
          Enter your email and we'll send you a <br /> reset password link.
        </p>
        {mutation?.isError && (
          <span className="text-danger mt-2 text-center fw-bold">
            {mutation.error?.response?.data?.message ??
              'Email is not Registered.'}
          </span>
        )}
      </div>

      <div className="space-y-4 max-w-screen-lg w-full pt-3">
        <div className="mt-4">
          <Form
            disabled={mutation.isLoading}
            form={form}
            onFinish={handleFormSubmission}
            initialValues={{
              credential: state?.creds,
              phone_code: state?.phone_code ?? phones[0]?.value,
            }}
          >
            <div className="flex justify-between items-center gap-2.5">
              <div className="w-full relative">
                {isPhoneNumber ? (
                  <div className="absolute left-0 top-0 z-10 w-full max-w-[125px] shrink-0">
                    <Form.Item
                      className="w-full mb-3 [&_.ant-select-disabled]:bg-zinc-100 dark:[&_.ant-select-disabled]:bg-neutral-700 [&_.ant-select-disabled]:opacity-40"
                      name="phone_code"
                      rules={[
                        {
                          required: true,
                          message: 'Please select',
                        },
                      ]}
                      dependencies={['credential']}
                    >
                      <Select
                        placeholder={
                          <span className="text-[#818181]">Select</span>
                        }
                        popupClassName={`${antCustomSelect} antcstm`}
                        className="w-full cstm-drpdwn cstm-drpdwn-transparent h-[42px] border-0 dark:bg-neutral-800 bg-neutral-100 !border-neutral-800 hover:border-transparent placeholder:text-[#818181] focus:border-0 text-sm rounded-md border-r-1 border-r-zinc-900 rounded-r-none"
                        options={phones}
                        disabled={mutation?.isSuccess && isPhoneNumber}
                        showSearch
                        autoClearSearchValue
                        suffixIcon={
                          <>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                              <BsChevronDown
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </>
                        }
                        notFoundContent={
                          <div className="w-full">
                            <NoDataFound
                              title={
                                <span className="text-xs text-whiteNew-0">
                                  Not Available
                                </span>
                              }
                              minHeight={'min-h-[80px]'}
                              icon={<RiListCheck2 className="text-sm" />}
                            />
                          </div>
                        }
                      />
                    </Form.Item>
                  </div>
                ) : null}

                <Form.Item
                  className={`${darkTheme ? 'dark-floating-label' : ''} mb-3`}
                  name="credential"
                  dependencies={['phone_code']}
                  rules={[
                    {
                      required: true,
                      message: 'Email or phone is required',
                    },
                    ({ getFieldValue }) => ({
                      validator: async (_, value) => {
                        if (
                          isPhoneNumber &&
                          (!value ||
                            isValidPhoneNumber(
                              value,
                              String(getFieldValue('phone_code')).split('+')[0],
                            ))
                        ) {
                          const res = await check_phone(
                            value,
                            getFieldValue('phone_code'),
                          );
                          if (res) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('No account found'));
                        }
                        if (
                          !isPhoneNumber &&
                          (!value || EMAIL_REG.test(creds))
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            isPhoneNumber
                              ? 'Invalid phone number'
                              : 'Invalid email',
                          ),
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder="Email/Phone Number"
                    disabled={mutation?.isSuccess && isPhoneNumber}
                    className={`${
                      darkTheme ? formClass : ''
                    } ${formClass} py-2.5 ${isPhoneNumber ? 'pl-[128px]' : ''}`}
                  />
                </Form.Item>
              </div>
            </div>
            {mutation?.isSuccess && isPhoneNumber ? (
              <div className="mt-3 d-flex align-items-center justify-content-center verifyOtpBox verifyOtpBoxDark">
                <ReactInputVerificationCode
                  value={otp}
                  placeholder="-"
                  length={6}
                  autoFocus={true}
                  onChange={(e) => {
                    // setOtpError('');
                    // onChange(e);
                    setOtp(e);
                  }}
                  className="text-whiteNew-0"
                />
              </div>
            ) : null}

            <div className="flex justify-center flex-col items-center mt-4 gap-3">
              {mutation?.isSuccess && isPhoneNumber ? (
                <button
                  disabled={
                    otpMutation.isLoading ||
                    String(otp).length !== 6 ||
                    !/^\d+$/.test(Number(otp)) ||
                    isNaN(otp)
                  }
                  onClick={handleOtpSubmission}
                  className={`${
                    darkTheme
                      ? `${primaryLoginBtnClassGradient}`
                      : 'bg-sky-600 text-gray-50 hover:bg-sky-700'
                  } p-2.5 duration-200 ease-in ${
                    otpMutation.isLoading
                      ? 'rounded-full w-auto'
                      : 'w-full rounded-[8px]'
                  }`}
                >
                  {otpMutation?.isLoading ? (
                    <SpinnerBtn className={'text-xl'} />
                  ) : (
                    'Verify OTP'
                  )}
                </button>
              ) : (
                <button
                  id="sign-in-button"
                  disabled={mutation.isLoading}
                  type="submit"
                  className={`${
                    darkTheme
                      ? `${primaryLoginBtnClassGradient}`
                      : 'bg-sky-600 text-gray-50 hover:bg-sky-700'
                  } p-2.5 duration-200 ease-in ${
                    mutation.isLoading
                      ? 'rounded-full w-auto'
                      : 'w-full rounded-[8px]'
                  }`}
                >
                  {mutation?.isLoading ? (
                    <SpinnerBtn className={'text-xl'} />
                  ) : (
                    'Continue'
                  )}
                </button>
              )}
              <Link
                to={'/user/login'}
                className={`${
                  darkTheme
                    ? 'bg-neutral-700 bg-opacity-50 text-gray-50 hover:bg-neutral-700 hover:bg-opacity-70 duration-200 ease-in shadow-lg shadow-gray-200'
                    : 'border-2 border-gray-200 hover:bg-gray-200'
                } p-2.5 rounded-lg w-full flex justify-center items-center hover:text-whiteNew-0`}
              >
                Back to Login
              </Link>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}
