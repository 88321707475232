import React from 'react';
import { CustomHomeIcon, LogoGoTix } from '../util/CustomIcons';
import { MdArrowBack } from 'react-icons/md';
import { useCheckoutActions } from '../../pages/checkout/hooks/useCheckout';
import AlertModal from '../common/notification_modal/AlertModal';
import Tooltip from '../common/Tooltip';
import { useSelector } from 'react-redux';
import { cartData } from '../../features/cart/cart.slice';
import CartTimer from '../landing_page_v2/header_compo/CartTimer';

function CheckoutHeader({ noAction }) {
  const { OpenModal, backToHome, slug1, setOpenmodal, setBackToHome } =
    useCheckoutActions();

  let selectedSeats = useSelector((state) => state.selectedSeats);
  let cartselectedSeats = useSelector(cartData);
  let { cartInpersonData, cartvirtualData } = cartselectedSeats;

  return (
    <>
      <div className="bg-color-base-header-1 border-1 border-level-4">
        <div className="container-fluid px-3.5 max-w-[1800px]">
          <div className="flex justify-between items-center px-3.5 py-1.5">
            <div className="flex gap-3 items-center">
              {!noAction ? (
                <div className="flex">
                  <button
                    onClick={() => setOpenmodal(true)}
                    className="bg-color-base-2 shadow-md rounded-full h-8 w-8 text-sm items-center justify-center flex text-color-primary"
                  >
                    <MdArrowBack />
                  </button>
                </div>
              ) : null}

              <div className="flex">
                <div className="flex-shrink-0 relative h-12 w-28 lg:w-36">
                  <div className="flex items-center">
                    <LogoGoTix
                      className={
                        'w-full dark:text-white text-zinc-800 absolute inset-0 h-full'
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {!noAction ? (
              <div className="flex items-center gap-2.5 ">
                {(Object.entries(cartvirtualData)?.length > 0 ||
                  Object.entries(cartInpersonData)?.length > 0) && (
                  <span className="flex px-1.5 min-w-[57px] justify-center dark:text-white text-zinc-800 text-sm border-1 dark:border-landingPurple-60 border-sky-600 rounded-md ">
                    <CartTimer selectedSeats={selectedSeats} />
                  </span>
                )}
                <Tooltip title={'Go to Home'}>
                  <button
                    onClick={() => setBackToHome(true)}
                    className="bg-color-base-2 shadow-md rounded-full h-8 w-8 text-sm items-center justify-center flex text-color-primary"
                  >
                    <CustomHomeIcon />
                  </button>
                </Tooltip>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* Need for optimizaiton */}
      <AlertModal
        isOpen={OpenModal}
        setIsOpen={setOpenmodal}
        from="buyer"
        navigateBack
        slug={slug1}
        darkTheme={true}
        show={'timer'}
        checkout
      />

      <AlertModal
        isOpen={backToHome}
        setIsOpen={setBackToHome}
        from="buyer"
        darkTheme={true}
        show={'timer'}
        slug={slug1}
        checkout
      />
    </>
  );
}

export default CheckoutHeader;
