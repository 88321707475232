import axios from 'axios';
import config from '../../../../api/Config';
import { updateUrl } from '../../../../utils/util';

export async function getSampleArtistList(page, key, limit) {
  const res = axios.get(
    `${config.baseUrl + config.endpoints.sample_artist}?page=${page}&limit=${
      limit ? limit : '100'
    }&${key !== undefined ? `key=${key}` : ``}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res;
}

export async function createArtist(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.create_artist,
    body,
    headers,
  );

  return res.data;
}

export async function getArtistListByUserID(page, key, limit, event_id, type) {
  const res = axios.get(
    `${config.baseUrl + config.endpoints.Artist_list_By_UserID}?limit=${
      limit ? limit : '100'
    }&event_id=${
      event_id !== undefined && event_id !== '' && event_id !== null
        ? event_id
        : 'empty'
    }&page=${page}&${key !== '' && key !== undefined ? `key=${key}` : ``}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res;
}

export async function updateArtist(payload, header) {
  const res = axios.put(
    config.baseUrl + config.endpoints.Update_artist,
    payload,
    header,
  );

  return res;
}

export async function handleDeleteArtist(payload, header) {
  const res = axios.post(
    config.baseUrl + config.endpoints.delete_artist,
    payload,
    header,
  );

  return res;
}

export async function RemoveArtistImage(headers, payload) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.remove_artist_IMG,
    payload,
    headers,
  );
  return res.data;
}

export async function checkArtistsName(payload) {
  const res = axios.post(
    config.baseUrl + config.endpoints.check_Artist_name,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res;
}

export async function getArtistType(artist) {
  const res = await axios.get(
    `${config.baseUrl + config.endpoints.artist_type}${
      artist === 'team' ? '?for=team' : ''
    }`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res.data;
}

export async function getArtistID(id) {
  const res = await axios.get(
    `${config.baseUrl + config.endpoints.artist_by_id}/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res.data;
}

export async function CreateArtistType(payload, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.create_artist_type,
    payload,
    headers,
  );
  return res.data;
}

export async function followUnfollowArtist(body, headers) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.follow_artist,
    body,
    headers,
  );

  return res.data;
}
export async function getArtistFollowerList(
  body,
  headers,
  page = 1,
  limit = 10,
) {
  const res = await axios.get(
    config.baseUrl +
      config.endpoints.artist_follower_list +
      `?page=${page}&limit=${limit}`,
    body,
    headers,
  );

  return res.data;
}

export async function all_artist_filter_count() {
  const res = await axios.get(
    `${config.baseUrl + config.endpoints.all_artist_filter_count}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return res.data;
}

export async function all_artists_list_filter() {
  const filter_params = new URLSearchParams(window.location.search).toString();
  console.log('filter_params', updateUrl(filter_params));
  const res = await axios.get(
    `${config.baseUrl + config.endpoints.all_artist_list}?${updateUrl(
      filter_params,
    )}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}

export async function all_artists_list(page, limit, top_filter) {
  const res = await axios.get(
    `${
      config.baseUrl + config.endpoints.all_artist_list
    }?page=${page}&limit=${limit}&top_filter=${top_filter}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}
