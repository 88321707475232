import React from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { Disclosure, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
// import { TbArrowsLeftRight } from "react-icons/tb";
export default function SidebarMenus({
  title,
  menu,
  openAccordActive,
  primarySidebar,
  secondarySidebar,
  activePrimaryMenu,
  accordOnClick,
}) {
  return (
    <React.Fragment>
      <Disclosure as="div">
        {({ open = openAccordActive }) => (
          <>
            <div className={``}>
              <Disclosure.Button
                onClick={accordOnClick}
                className={`relative flex w-full justify-between items-center flex-wrap gap-3 rounded-lg bg-white-100 ps-3 pe-3 py-2 text-left focus:outline-none focus-visible:ring-1 focus-visible:ring-slate-500 focus-visible:ring-opacity-75 duration-200 ease ${
                  primarySidebar
                    ? `${
                        activePrimaryMenu
                          ? 'border-l-0 text-bluev2-600 bg-slate-200'
                          : ''
                      }  rounded-lg`
                    : // before:content-[""] before:bg-bluev2-600 before:top-0 before:left-0 before:h-full before:w-1.4 before:absolute before:hover:opacity-100 before:duration-200 before:ease-linear
                      `border-l-0 justify-between`
                }  ${
                  secondarySidebar
                    ? `${
                        openAccordActive
                          ? 'bg-bluev2-600 text-whiteNew-0 hover:bg-bluev2-600'
                          : 'hover:bg-slate-200 hover:text-neutral-900'
                      }`
                    : 'hover:bg-slate-200'
                }`}
              >
                <div className="flex gap-3 items-center">
                  <div className="flex items-center flex-wrap gap-3">
                    <span className="">{title}</span>
                  </div>
                </div>
                {menu && (
                  <div>
                    <BsChevronRight
                      className={`
                    
                      ${
                        openAccordActive
                          ? 'rotate-90 transform duration-200 ease-in text-white'
                          : 'text-slate-500'
                      }
                      h-3 w-3 duration-200 ease-in`}
                    />
                  </div>
                )}
                {/* {menu && (
                  <div>
                    <BsChevronRight
                      className={`${
                        open
                          ? 'rotate-90 transform  text-blue-500'
                          : `${
                              openAccordActive
                                ? 'rotate-90 transform duration-200 ease-in text-white'
                                : 'text-slate-500'
                            }`
                      } h-3 w-3 duration-200 ease-in`}
                    />
                  </div>
                )} */}
              </Disclosure.Button>
              {menu && (
                <Transition
                  show={openAccordActive}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-0 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="py-0.5 pt-0">
                    <div className="flex flex-wrap gap-1 sm:shadow-sm shadow-inner shadow-neutral-600 bg-[#f7f8fa] rounded-bl-lg rounded-br-lg overflow-hidden">
                      {menu.map((item, key) => (
                        <Link
                          to={item?.href}
                          key={key}
                          className={`flex items-center gap-3 w-full pl-8 py-2 duration-200 ease rounded-lg ${
                            item.active
                              ? `${
                                  secondarySidebar
                                    ? 'text-bluev2-600 font-medium'
                                    : 'text-whiteNew-0 bg-bluev2-600'
                                }`
                              : `${
                                  secondarySidebar
                                    ? 'hover:bg-slate-200'
                                    : 'hover:bg-slate-200 hover:font-medium'
                                }`
                          } ${secondarySidebar ? '' : ''}`}
                          onClick={item.onClick}
                        >
                          {item.icon && <span>{item.icon}</span>}

                          <span>{item.name}</span>
                        </Link>
                      ))}
                      {/* <div className="text-lg flex items-center gap-3  border-l-8 w-full ps-3 py-3 text-sky-600 bg-sky-100 border-sky-600 ">
											<span>
												<TbArrowsLeftRight />
											</span>
											<span>menu</span>
										</div>
										<div className="text-lg flex items-center gap-3 border-l-8 w-full ps-3 py-3">
											<span>
												<TbArrowsLeftRight />
											</span>
											<span>Transaction</span>
										</div> */}
                    </div>
                  </Disclosure.Panel>
                </Transition>
              )}
            </div>
          </>
        )}
      </Disclosure>
    </React.Fragment>
  );
}
