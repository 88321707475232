import useSWR from 'swr';
import {
  banner_events,
  explore_events,
  getTopArtists,
  onGoing_events,
  // published_events,
  recent_events,
  trending_events,
} from '../../../../helpers/api/event/event';
import { useDispatch, useSelector } from 'react-redux';
import {
  addEvents,
  resetArtistEvents,
  resetBannerEvents,
  // resetEvents,
  resetExploreEvents,
  resetOngoingEvents,
  resetPageLimit,
  resetRecentEvents,
  resetTrendingEvents,
} from '../../../../features/home_page/homepage.slice';
import { updateCartTrendingEvent } from '../../../../features/checkout/checkout.slice';
import { useOnceCall } from '../../../../hooks/useOnlyOnce';
// import axios from 'axios';
// import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { checkIsDataAvailable } from '../../../../utils/util';

export const useOngoingEventHooks = (page, limit) => {
  let dispatch = useDispatch();
  let PublishedEvents = useSelector((state) => state?.publishedEvents);
  const { ongoing } = PublishedEvents;

  useOnceCall(() => {
    // Clear data from Redux store
    dispatch(resetOngoingEvents());
    dispatch(resetPageLimit());
  });

  const ongoingEventdetails = useSWR(
    [
      'onGoing_events',
      // ongoing?.data?.length === 0,
      page?.ongoing,
      limit?.ongoing,
    ],
    async () => await onGoing_events(page?.ongoing, limit?.ongoing),
    {
      // refreshInterval: 60 * 1000,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      // keepPreviousData: true,
      onSuccess: (res) => {
        dispatch(
          addEvents({
            ...PublishedEvents,
            ongoing: {
              data: [...ongoing?.data, ...res?.ongoingEvent],
              totalCount: res?.count,
            },
          }),
        );
      },
    },
  );

  return {
    ongoingEventdetails,
  };
};

export const useTrendingEventHooks = (page, limit, header) => {
  let dispatch = useDispatch();
  let PublishedEvents = useSelector((state) => state?.publishedEvents);
  const { trending } = PublishedEvents;
  const { pathname } = useLocation();

  useOnceCall(() => {
    // Clear data from Redux store
    dispatch(resetTrendingEvents());
    // dispatch(resetPageLimit());
  });

  const trendingEventdetails = useSWR(
    [
      header ? 'trending' : 'trending_events',
      pathname,
      page?.trending,
      limit?.trending,
    ],
    async () => await trending_events(page?.trending, limit?.trending),
    {
      // refreshInterval: 60 * 1000,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      // keepPreviousData: true,
      onSuccess: (res) => {
        if (header) {
          dispatch(
            updateCartTrendingEvent({
              data:
                trending?.data?.length < res?.count
                  ? [...trending?.data, ...res?.trendingEvent]
                  : [...trending?.data],
              totalCount: res?.count,
            }),
          );
        } else {
          dispatch(
            addEvents({
              ...PublishedEvents,
              trending: {
                data:
                  trending?.data?.length < res?.count
                    ? [...trending?.data, ...res?.trendingEvent]
                    : [...trending?.data],
                totalCount: res?.count,
              },
            }),
          );
        }
      },

      // onSuccess(data) {

      // },
    },
  );

  // return { eventdetails, topseller, topartist };
  return {
    trendingEventdetails,
  };
};

export const useRecentEventHooks = (page, limit) => {
  let dispatch = useDispatch();
  let PublishedEvents = useSelector((state) => state?.publishedEvents);
  const { recent } = PublishedEvents;

  useOnceCall(() => {
    // Clear data from Redux store

    dispatch(resetRecentEvents());
    dispatch(resetPageLimit());
  });

  const recentEventdetails = useSWR(
    ['recent_events', page?.recent, limit?.recent],
    async () => await recent_events(page?.recent, limit?.recent),
    {
      // refreshInterval: 60 * 1000,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      // keepPreviousData: true,
      onSuccess: (res) => {
        dispatch(
          addEvents({
            ...PublishedEvents,
            recent: {
              data: [...recent?.data, ...res?.recentEvent],
              totalCount: res?.count,
            },
          }),
        );
      },
      // onSuccess(data) {

      // },
    },
  );

  // return { eventdetails, topseller, topartist };
  return {
    recentEventdetails,
  };
};

export const useExploreEventHooks = (page, limit) => {
  let dispatch = useDispatch();
  let PublishedEvents = useSelector((state) => state?.publishedEvents);
  const { explore } = PublishedEvents;

  useOnceCall(() => {
    // Clear data from Redux store
    dispatch(resetExploreEvents());
    dispatch(resetPageLimit());
  });

  const exploreEventdetails = useSWR(
    [
      'explore_events',
      // explore?.data?.length === 0,
      page?.explore,
      limit?.explore,
    ],
    async () => await explore_events(page?.explore, limit?.explore),
    {
      // refreshInterval: 60 * 1000,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      // keepPreviousData: true,
      onSuccess: (res) => {
        dispatch(
          addEvents({
            ...PublishedEvents,
            explore: {
              data: [...explore?.data, ...res?.explore],
              totalCount: res?.count,
            },
          }),
        );
      },
      // onSuccess(data) {

      // },
    },
  );

  // return { eventdetails, topseller, topartist };
  return {
    exploreEventdetails,
  };
};

export const useBannerEventHooks = () => {
  let dispatch = useDispatch();
  let PublishedEvents = useSelector((state) => state?.publishedEvents);

  useOnceCall(() => {
    // Clear data from Redux store
    dispatch(resetBannerEvents());
    dispatch(resetPageLimit());
  });

  const bannerEventdetails = useSWR(
    ['banner_events'],
    async () => await banner_events(),
    {
      // refreshInterval: 60 * 1000,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      // keepPreviousData: true,
      onSuccess: (res) => {
        dispatch(
          addEvents({
            ...PublishedEvents,
            banner: {
              data: res?.data,
              totalCount: res?.count,
            },
          }),
        );
      },

      // onSuccess(data) {

      // },
    },
  );

  return {
    bannerEventdetails,
  };
};

export const useTopArtistHooks = (page, limit) => {
  let dispatch = useDispatch();
  let PublishedEvents = useSelector((state) => state?.publishedEvents);
  const { artist } = PublishedEvents;

  useOnceCall(() => {
    // Clear data from Redux store
    // dispatch(resetArtistEvents());w
    dispatch(resetPageLimit());
  });

  const topartist = useSWR(
    ['top_artist', page?.artist, limit?.artist],
    async () => await getTopArtists(page?.artist, limit?.artist),
    {
      // refreshInterval: 60 * 1000,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      // keepPreviousData: true,
      onSuccess: (res) => {
        // console.log('res', res);

        dispatch(
          addEvents({
            ...PublishedEvents,
            artist: {
              data: checkIsDataAvailable(artist?.data, res?.data),
              totalCount: res?.count,
            },
          }),
        );
      },
    },
  );

  // return { eventdetails, topseller, topartist };
  return {
    topartist,
  };
};
