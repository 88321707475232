import React from 'react';
import { LogoGoTix } from '../../../../components/util/CustomIcons';

// import CustomDropdown from '../../components/CustomDropdown';
import ResetPassword from '../../../../components/auth/reset_password/ResetPassword';
import ConfirmationEmail from '../../../../components/auth/util/ConfirmationEmail';
import SetNewPassword from '../../../../components/auth/reset_password/SetNewPassword';
import WalletLogin from '../../../../components/auth/login/WalletLogin';
import VerificationLinks from '../../../../components/auth/util/VerificationLinks';
import HelmetComponent from '../../../../utils/HelmateComponent';
import LoginV2 from '../../../../components/auth/login/LoginV2';
import TFA from '../../../../components/auth/login/TFA';

import ClaimNFTErrorScreen from '../../../../components/auth/util/ClaimNFTErrorScreen';
import { Link } from 'react-router-dom';
import NFTMailConfirmation from '../../../../components/auth/util/NFTMailConfirmation';
import NFTMailVerification from '../../../../components/auth/util/NFTMailVerification';

export default function LoginScreens({ compo, darkTheme, showLogo }) {
  return (
    <React.Fragment>
      {/* <LogoIcon
        className={'w-20 h-20 2xl:w-20 2xl:h-20 rounded-full mx-auto'}
      /> */}
      <HelmetComponent
        title={`NFTGOTIX | ${
          compo === 'login'
            ? 'Log In'
            : compo === 'confirmation'
            ? 'Email Confirmation'
            : compo === 'nft_confirmation'
            ? 'Claim NFT'
            : compo === 'verify'
            ? 'Email Verification'
            : compo === 'newpass'
            ? 'Reset Password'
            : compo === 'resetPass2'
            ? 'Reset Password'
            : compo === 'TFA'
            ? 'Two Factor Authentication'
            : ''
        }`}
      />
      {showLogo ? (
        <div className="w-full flex justify-center">
          <Link to="/" className="">
            <LogoGoTix
              className={`w-full max-w-sm h-10 ${
                darkTheme ? 'text-whiteNew-0' : ''
              }`}
            />
          </Link>
        </div>
      ) : null}

      <div className="w-full flex justify-center">
        <figure
          className={`flex justify-center items-center w-full border-1 rounded-2xl overflow-hidden dark:bg-color-base-1 max-w-2xl border-level-4`}
        >
          <div
            className={`w-full p-3.5 md:px-8 py-10 dark:bg-color-base-1 bg-whiteNew-0`}
          >
            <div>
              {/* {compo === 'login' && <Login darkTheme={darkTheme} />} */}
              {compo === 'login' && <LoginV2 darkTheme={darkTheme} />}
              {compo === 'wallet' && <WalletLogin darkTheme={darkTheme} />}
              {compo === 'TFA' && <TFA darkTheme={darkTheme} />}
              {compo === 'confirmation' && (
                <ConfirmationEmail darkTheme={darkTheme} />
              )}
              {compo === 'nft_confirmation' && (
                <NFTMailConfirmation darkTheme={darkTheme} />
              )}
              {compo === 'nft_claim_verfication' && (
                <NFTMailVerification darkTheme={darkTheme} />
              )}
              {compo === 'verify' && (
                <VerificationLinks darkTheme={darkTheme} />
              )}
              {compo === 'newpass' && <SetNewPassword darkTheme={darkTheme} />}
              {compo === 'resetPass2' && (
                <ResetPassword darkTheme={darkTheme} />
              )}
              {compo === 'claimNft' && (
                <ClaimNFTErrorScreen darkTheme={darkTheme} />
              )}

              {/* <WalletLogin /> */}
              {/* <ResetPassword /> */}
              {/* <PasswordResetLink /> */}
              {/* <SetNewPassword /> */}
              {/* <ResetLinkSentSuccessfully /> */}
              {/* <ResetLinkExpired /> */}
            </div>
          </div>
        </figure>
      </div>
    </React.Fragment>
  );
}
