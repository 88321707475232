import axios from "axios";
import config from "../../../api/Config";
import { message } from "antd";
import { sleep } from "../../../utils/util";

export async function enter_stream(payload) {
  const res = axios.post(config.baseUrl + config.endpoints.enter_stream, payload, {
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  return res;
}

export async function exit_from_stream(id) {
  if (!id) return
  const res = axios.post(config.baseUrl + config.endpoints.exit_stream + `?ticket_id=${id}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  return res;
}

export async function get_all_active_sessions({ uid, event_id, otp, type }) {
  let url;
  const base_api = config.baseUrl + config.endpoints.all_sessions
  if (type === 'AUTH') url = base_api + `?user_id=${uid}`
  if (type === 'VALIDATE') url = base_api + `?user_id=${uid}&event_id=${event_id}&otp=${otp}`
  if (type === 'NO_AUTH') url = base_api + `?user_id=${uid}&event_id=${event_id}&auth=true`
  const res = await axios.get(url)
  if (res.status === 200 && type === 'AUTH') message.success(res.data.message)
  return res.data
}

export async function get_current_ticket_status(ticket_id, session_id) {
  if (!ticket_id) return
  const res = axios.get(config.baseUrl + config.endpoints.ticket_auth_status + `?ticket_id=${ticket_id}&session_id=${session_id}`)

  return res;
}

export async function get_all_nfts({ user_id, event_id, page = 1, limit = 8 }) {
  if (!user_id || !event_id) return
  const res = axios.get(config.baseUrl + config.endpoints.stream_all_nfts + `?user_id=${user_id}&event_id=${event_id}&page=${page}&limit=${limit}`)

  return res;
}

export async function get_all_shared_order({ user_id, shared_email, order_id }) {
  if (!user_id || !shared_email || !order_id) return
  const res = await axios.get(config.baseUrl + config.endpoints.get_shared_ticket + `?user_id=${user_id}&order_id=${order_id}&shared_email=${shared_email}`, {
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })

  return res.data
}

export async function validate_shared_ticket({ access_id, passcode, slug }) {
  if (!access_id) return
  const res = axios.post(config.baseUrl + config.endpoints.validate_shared_ticket, { access_id, passcode, slug }, {
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })

  return res
}

export async function validate_qr_ticket({
  access_id,
  passcode,
  slug
  // access_id,
  // encrypted_password,
  // passcode,
  // shared_email,
  // event_id,
  // qr
}) {
  const payload = {
    access_id,
    passcode,
    slug
    // access_id,
    // encrypted_password,
    // passcode,
    // shared_email,
    // event_id,
    // qr
  }
  const res = axios.post(config.baseUrl + config.endpoints.validate_qr_ticket, payload, {
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  return res
}