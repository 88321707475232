import axios from 'axios';
import config from '../../api/Config';

const BASE_URL = config.baseUrl;

const Axios = axios.create({
  baseURL: BASE_URL,
  headers: {
    //  Authorization: `<Your Auth Token>`,
    'Content-Type': 'application/json',
  },
   timeout: 10000,
  // .. other options
});

const AuthAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    'content-type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
  timeout: 10000 
})

export {AuthAxios}
export default Axios;
