import React from 'react';
import { MdClose } from 'react-icons/md';

export default function FilterPills({ data, pillActions }) {
  // const to_exclude_key = ['search_key', 'sort_by', 'page', 'limit']
  // const filter_pills = {...data}
  console.log(data, 'all_filters_pills');
  let final_pill = [];
  Object.entries(data).map(([key, value]) => {
    if (['min', 'max'].includes(key)) {
      return null;
    } else {
      return (final_pill = [...final_pill, ...value]);
    }
  });
  const { handleClearFilter, removeSinglePill, filter_loading } = pillActions;
  return (
    <>
      {final_pill?.length ? (
        <div className="flex gap-2 items-center flex-wrap mb-3.5 mt-[8px]">
          <>
            {final_pill?.map(({ id, type, label }, index) => (
              <span
                key={index}
                className="flex justify-between items-center bg-component-gray-2 dark:bg-color-base-2 dark:hover:bg-opacity-60 hover:bg-zinc-200 duration-200 ease-in rounded-md p-1.5 px-2.5 pr-1 gap-2.5 text-color-primary disabled:cursor-not-allowed"
              >
                <span>{label}</span>
                <button
                  disabled={filter_loading}
                  onClick={() => removeSinglePill({ _id: id, label, type })}
                  className="w-4 h-4 flex relative rounded-xl bg-color-base-2 dark:bg-color-base-1 duration-150 ease-in text-color-primary disabled:cursor-not-allowed"
                >
                  <MdClose className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-xs" />
                </button>
              </span>
            ))}
          </>
          {final_pill?.length ? (
            <button
              disabled={filter_loading}
              onClick={handleClearFilter}
              className="flex justify-between items-center bg-component-gray-2 dark:bg-color-base-2 dark:hover:bg-opacity-60 hover:bg-zinc-200 duration-200 ease-in rounded-md p-1.5 px-2.5 gap-2.5 text-color-primary disabled:cursor-not-allowed"
            >
              <span>Clear All</span>
            </button>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
