import React from 'react';
import HeaderFillTop from '../HeaderFillTop';
import { durationAnimate, primaryBtnClass } from '../../common/DefaultClasses';
import { BsPlus } from 'react-icons/bs';
import RightSideSectionWrapper from '../RightSideSectionWrapper';
import TableComponentv2 from '../../common/TableComponentv2';
import Tooltip from '../../common/Tooltip';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import { AiOutlineBank } from 'react-icons/ai';
import { Col, Row } from 'react-bootstrap';

export default function AddressDetailInfo() {
  const dataSource = [
    {
      key: '1',
      nick_name: (
        <>
          <div className="flex items-center flex-wrap gap-2">
            <div className="min-w-0 2xl:flex-auto space-y-1 font-medium capitalize">
              <span className="text-bluev2-600 dark:text-slate-50 text-sm 2xl:text-base font-medium flex items-center gap-2">
                John
              </span>
            </div>
          </div>
        </>
      ),
      full_name: <span className="flex capitalize">John Wick</span>,
      email: (
        <>
          <span>john@gmail.com</span>
        </>
      ),
      phone_no: (
        <>
          <span>1234568790</span>
        </>
      ),
      address: (
        <>
          <span className="flex">
            Pennsylvania Station, 4 Pennsylvania Plaza, New York
          </span>
        </>
      ),
      action: (
        <>
          <span className="flex items-center">
            <Tooltip title={'Edit'} placement={'top'}>
              <button
                className={`p-1.5 text-gray-600 hover:text-bluev2-600 text-lg ${durationAnimate}`}
              >
                <FaRegEdit />
              </button>
            </Tooltip>
            <Tooltip title={'Delete'} placement={'top'}>
              <button
                className={`p-1.5 text-gray-600 hover:text-rose-700 text-lg ${durationAnimate}`}
              >
                <FaRegTrashAlt />
              </button>
            </Tooltip>
          </span>
        </>
      ),
      expand_data: (
        <>
          <div className="w-full max-w-[840px]">
            <Row>
              <Col xl={6}>
                <div className="flex flex-col gap-3">
                  <div className="flex justify-between">
                    <span className="text-slate-500 font-medium">Address</span>
                    <span className="text-color-primary">New York, US.</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-slate-500 font-medium">State</span>
                    <span className="text-color-primary">New York</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-slate-500 font-medium">City</span>
                    <span className="text-color-primary">New York.</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-slate-500 font-medium">Zip Code</span>
                    <span className="text-color-primary">50001</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-slate-500 font-medium">Country</span>
                    <span className="text-color-primary">US</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      ),
    },
  ];

  const columns = [
    {
      title: <span className="text-gray-500">Bank Nick Name</span>,
      dataIndex: 'nick_name',
      key: 'nick_name',
      width: '100px',
    },
    {
      title: <span className="text-gray-500">Full Name</span>,
      dataIndex: 'full_name',
      key: 'full_name',
      width: '150px',
    },
    {
      title: <span className="text-gray-500">Email</span>,
      dataIndex: 'email',
      key: 'email',
      width: '150px',
    },
    {
      title: <span className="text-gray-500">Phone Number</span>,
      dataIndex: 'phone_no',
      key: 'phone_no',
      width: '100px',
    },
    {
      title: <span className="text-gray-500 inline-block pr-8">Address</span>,
      dataIndex: 'address',
      key: 'address',
      width: '200px',
    },
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: 'action',
      key: 'action',
      width: '100px',
    },
  ];
  return (
    <React.Fragment>
      <div>
        <div className="">
          <HeaderFillTop
            title={'Address'}
            extraPill={''}
            extra={
              <>
                <button className={`${primaryBtnClass}`}>
                  <span>
                    <BsPlus className="text-lg" />
                  </span>
                  Add New Address
                </button>
              </>
            }
          />
        </div>
        <RightSideSectionWrapper>
          <section>
            <div className="block overflow-hidden" style={{ flex: '0 0 75%' }}>
              <div className="w-full">
                <TableComponentv2
                  dataSource={dataSource}
                  columns={columns}
                  expandable={{
                    expandedRowRender: (record) => (
                      <div className="m-0 bg-color-base-2 p-3">
                        {record.expand_data}
                      </div>
                    ),
                    // rowExpandable: (record) => record.name !== 'Not Expandable',
                  }}
                />
              </div>
            </div>
          </section>
        </RightSideSectionWrapper>
      </div>
    </React.Fragment>
  );
}
