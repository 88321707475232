import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { ArtistCardSkeleton } from '../util/Skeleton';
import OrganiserCardV2 from './OrganiserCardV2';
import { sliderButton } from '../common/DefaultClasses';
import { useDispatch, useSelector } from 'react-redux';
import { AddPage } from '../../features/home_page/homepage.slice';
import { updateDataCountByViewport } from '../../utils/util';
import NoDataFound from '../common/NoDataFound';
// import  { SkeletonAvatar } from '../util/Skeleton';

// import './styles.css';
// SwiperCore.use([EffectFade, FreeMode, Pagination, Navigation, Autoplay]);
export default function TopOrganiserSlider({
  data,
  isLoading,
  type,
  refetch,
  small,
  refetchUserDetails,
}) {
  // console.log('data', data);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const [swiper, setSwiper] = React.useState(null);
  let page_limit = useSelector((state) => state?.homePageEvents);
  let dispatch = useDispatch();
  let PublishedEvents = useSelector((state) => state?.publishedEvents);
  const { artist } = PublishedEvents;
  // const prevto = () => {
  //   swiper.slidePrev();
  // };
  // const nexto = () => {
  //   swiper.slideNext();
  // };

  const involkeAPI = () => {
    if (artist?.data?.length > 0 && artist?.data?.length < artist?.totalCount) {
      artist?.data?.length < artist?.totalCount &&
        dispatch(
          AddPage({
            ...page_limit,
            page: {
              ...page_limit?.page,
              artist: page_limit?.page?.artist + 1,
            },
            limit: {
              ...page_limit?.limit,
              artist: updateDataCountByViewport(),
            },
          }),
        );
    }
  };

  return (
    <React.Fragment>
      <div className="relative px-0.5">
        <Swiper
          hashNavigation={{
            watchState: true,
          }}
          // lazy={true}
          freeMode={true}
          effect="fade"
          loop={false}
          onSwiper={(s) => {
            setSwiper(s);
          }}
          // simulateTouch={false}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            navigationPrevRef.current = swiper.params.navigation.prevEl;
            navigationNextRef.current = swiper.params.navigation.nextEl;
          }}
          // autoplay={{
          //   delay: 2500,
          //   disableOnInteraction: false,
          // }}
          // pagination={{
          //   clickable: true,
          // }}
          onReachEnd={() => {
            involkeAPI();
          }}
          slidesPerView={3}
          spaceBetween={10}
          // modules={[Pagination]}
          className="mySwiper z-0"
          // navigation={true}
          modules={[Pagination, Navigation]}
          autoHeight={false}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            576: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            // 900: {
            //   slidesPerView: 3,
            //   spaceBetween: 20,
            // },
            1200: {
              slidesPerView: small ? 2 : 3,
              spaceBetween: 10,
            },

            1600: {
              slidesPerView: small ? 3 : 4,
              spaceBetween: 10,
            },
            // 2500: {
            //   slidesPerView: 7,
            //   spaceBetween: 20,
            // },
            // 3750: {
            //   slidesPerView: 7,
            //   spaceBetween: 20,
            // },
          }}
        >
          {isLoading && data?.length === 0 ? (
            <>
              {[...Array(updateDataCountByViewport())].map((_, idx) => (
                <SwiperSlide
                  className="relative rounded-xl overflow-hidden h-auto pointer-events-none"
                  key={idx}
                >
                  <ArtistCardSkeleton />
                </SwiperSlide>
              ))}
            </>
          ) : data?.length > 0 ? (
            <>
              {data?.map((item, index) => (
                <SwiperSlide className="relative p-2.5" key={index}>
                  <div className="relative h-full">
                    <OrganiserCardV2
                      data={item}
                      // eventdetails={eventdetails}
                      type={type}
                      refetch={refetch}
                      refetchUserDetails={refetchUserDetails}
                      from="home"
                    />
                  </div>
                </SwiperSlide>
              ))}
              {/* {type !== 'artistEvent' && (
                <SwiperSlide className="relative p-2.5">
                  <div className="relative h-full">
                    <EventViewAllCard
                      link={'/artists'}
                      title={'All Artists'}
                      subtitle={'Show me more'}
                      maxHeight={'h-full lg:max-h-[250px]'}
                    />
                  </div>
                </SwiperSlide>
              )} */}
            </>
          ) : (
            <>
              <div className="mt-2 min-h-[330px] flex items-center justify-center bg-color-base-1 bg-opacity-10 backdrop-blur-xl rounded-xl">
                <NoDataFound
                  title={`No artist is associated with this event.`}
                />
              </div>
            </>
          )}
        </Swiper>
        <div className="md:flex justify-content-end align-items-center gap-2">
          <div className="absolute -left-2 lg:-left-3 top-1/2 z-10 -translate-y-1/2">
            <button
              ref={navigationPrevRef}
              // onClick={prevto}
              className={`relative w-8 h-8 rounded-full dark:bg-landingPurple-60 bg-zinc-950 disabled:bg-neutral-700 text-gray-50 disabled:opacity-30 ${sliderButton}`}
            >
              <MdKeyboardArrowLeft className="text-md md:text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
            </button>
          </div>
          <div className="absolute -right-2 lg:-right-3 top-1/2 z-10 -translate-y-1/2">
            <button
              ref={navigationNextRef}
              // onClick={nexto}
              disabled={isLoading}
              className={`relative w-8 h-8 rounded-full dark:bg-landingPurple-60 bg-zinc-950 disabled:bg-neutral-700 text-gray-50 disabled:opacity-30 ${sliderButton}`}
            >
              <MdKeyboardArrowRight className="text-md md:text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
