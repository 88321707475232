import { PublicKey } from "@solana/web3.js";
import { BN } from "bn.js";

const PubKeysInternedMap = new Map();

export const toPublicKey = (key) => {
  if (typeof key !== "string") {
    return key;
  }

  let result = PubKeysInternedMap.get(key);
  if (!result) {
    result = new PublicKey(key);
    PubKeysInternedMap.set(key, result);
  }

  return result;
};

export const pubkeyToString = (key = "") => {
  return typeof key === "string" ? key : key?.toBase58() || "";
};

export const WRAPPED_SOL_MINT = new PublicKey(
  "So11111111111111111111111111111111111111112"
);

export const TOKEN_PROGRAM_ID = new PublicKey(
  "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
);

export const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);

export const BPF_UPGRADE_LOADER_ID = new PublicKey(
  "BPFLoaderUpgradeab1e11111111111111111111111"
);

export const MEMO_ID = new PublicKey(
  "MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"
);

export const METADATA_PROGRAM_ID =
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s";
export const STORE = new PublicKey(
  "9uFc5ZkL8Eqbb7UZaZ76VDGHSSnRhqXmaQZFpyHPuY11"
);

export const VAULT_ID = new PublicKey("vau1zxA2LbssAUEF7Gpw91zMM1LvXrvpzJtmZ58rPsn");
export const FRACTIONAL_NFT_ID = new PublicKey("xdip93TAtgHt3av2Z5gFPhoykf9tu7bBvFVdFxsXeKV")

export const AUCTION_ID = "auctxRXPeJoc4817jDhf4HbjnhEcr1cCXenosMhK5R8";

export const METAPLEX_ID = "p1exdMJcjVao65QdewkaZRUnU6VPSXhus9n2GzWfh98";

export const SYSTEM = new PublicKey("11111111111111111111111111111111");
export const AR_SOL_HOLDER_ID = new PublicKey(
  "8rgBbxcMS5cWrx5ztgYgJmCz8cyTrcbHp54DXLSVDyYh"
);

export function fromLamports(account, mint, rate = 1.0) {
  if (!account) {
    return 0;
  }

  const amount = Math.floor(
    typeof account === "number"
      ? account
      : BN.isBN(account)
        ? account.toNumber()
        : account.info.amount.toNumber()
  );

  const precision = Math.pow(10, mint?.decimals || 9);
  return (amount / precision) * rate;
}

export const isValidSolanaAddress = (address) => {
  try {
    // this fn accepts Base58 character
    // and if it pass we suppose Solana address is valid
    new PublicKey(address);
    return true;
  } catch (error) {
    // Non-base58 character or can't be used as Solana address
    return false;
  }
};
