import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CustomWalletIcon, LogoGoTix } from '../util/CustomIcons';
import useDecodedToken from '../../hooks/useDecodedToken';
import NotificationDropdown from '../util/header_components/NotificationDropdown';
import UserProfileDropdown from '../util/header_components/UserProfileDropdown';
import { AiOutlineClose } from 'react-icons/ai';
import Tooltip from '../common/Tooltip';
import { DeleteAllSeats } from '../../features/seatmap/seatmap.slice';
import { DeleteAllCart } from '../../features/cart/cart.slice';
import { useDispatch } from 'react-redux';

export default function HeaderLandingPage({
  btnClassName,
  headerDark,
  onlyLogo,
}) {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  let dispatch = useDispatch();
  const decodedToken = useDecodedToken();

  // $(document).ready(function () {
  //   $(window).scrollTop(0);
  // });
  // function hello() {
  //   $(window).scrollTop(0);
  // }
  // hello();
  // setTimeout(hello, 500);
  return (
    <React.Fragment>
      <div className="fixed z-50 top-0 left-0 w-full sticky-header">
        <div className="container-fluid lg:max-w-[95%] xl:max-w-[98%]">
          <div className="flex min-h-10 justify-between items-center py-0.5">
            <div>
              <div className="flex-shrink-0 relative h-12 w-28 lg:w-36">
                <Link
                  to="/"
                  state={{ redirected: true }}
                  className="flex items-center gap-2"
                >
                  <LogoGoTix
                    className={'w-full text-white absolute inset-0 h-full'}
                  />
                </Link>
              </div>
            </div>
            <div className="shrink-0">
              {decodedToken && !onlyLogo ? (
                <div className="flex shrink-0 gap-2.5">
                  {pathname !== '/demo' && (
                    <div>
                      <button
                        onClick={() => navigate('/demo')}
                        className="p-2 bg-landingPurple-60 text-gray-50 rounded-lg hover:bg-landingPurple-70 duration-200 ease-in md:w-24"
                      >
                        Demo
                      </button>
                    </div>
                  )}
                  <div>
                    <NotificationDropdown
                      btnClassName={btnClassName}
                      headerDark={headerDark}
                    />
                  </div>
                  <div>
                    <Tooltip title="Wallet" placement={'bottom'}>
                      <Link
                        to={`/user/${decodedToken?.username}/dashboard?tab=about&subtab=myaccount`}
                        className={`${
                          headerDark
                            ? 'flex text-opacity-90 w-[35px] h-[35px] bg-landingPurple-60 text-gray-50 shadow-none font-normal hover:bg-landingPurple-70 hover:text-white rounded-full btnDark relative'
                            : ''
                        }`}
                      >
                        <CustomWalletIcon
                          className={`absolute top-1/2 left-1/2 translate-neg-50-50 object-cover h-5 w-5 btnDark`}
                        />
                      </Link>
                    </Tooltip>
                  </div>
                  <div>
                    <UserProfileDropdown
                      btnClassName={btnClassName}
                      headerDark={headerDark}
                    />
                  </div>
                </div>
              ) : !onlyLogo ? (
                <div className="flex shrink-0 gap-2">
                  {pathname !== '/demo' && (
                    <button
                      onClick={() => navigate('/demo')}
                      className="p-2 bg-landingPurple-60 text-gray-50 rounded-lg hover:bg-landingPurple-70 duration-200 ease-in md:w-24"
                    >
                      Demo
                    </button>
                  )}
                  <button
                    onClick={() => navigate('/user/signup')}
                    className="p-2 dark:bg-landingPurple-60 text-gray-50 rounded-lg hover:bg-landingPurple-70 duration-200 ease-in md:w-24"
                  >
                    Sign Up
                  </button>
                  <button
                    onClick={() => {
                      dispatch(DeleteAllSeats());
                      dispatch(DeleteAllCart());
                      localStorage.removeItem('selectedVirtualTickets');
                      localStorage.removeItem('selectedInPersonTickets');
                      localStorage.removeItem('slug');
                      localStorage.removeItem('selectedCartEvent');
                      localStorage.removeItem('cartDataType');
                      localStorage.removeItem('timerStart');
                      navigate('/user/login');
                    }}
                    className="p-2 bg-landingPurple-60 text-gray-50 rounded-lg hover:bg-landingPurple-70 duration-200 ease-in md:w-24"
                  >
                    Log In
                  </button>
                </div>
              ) : (
                <div className="absolute top-4 right-4 z-10">
                  <button
                    type="button"
                    onClick={() => {
                      window.close();
                    }}
                    className="bg-landingPurple-60 border-2 border-landingPurple-60 hover:border-landingPurple-70 text-whiteNew-0 hover:shadow-xl shadow-slate-500 duration-150 ease-in w-8 h-8 height-auto min-auto flex gap-2 roundrem-49 relative p-0"
                  >
                    <AiOutlineClose className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover h-4 w-4" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
