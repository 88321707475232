import React from 'react';
import AllArtistsPage from './AllArtistsPage';

export default function index() {
  return (
    <>
      <AllArtistsPage />
    </>
  );
}
