import React from 'react';
import FavoriteListView from './FavoriteListView';
import SavedEventListView from './SavedEventListView';

export default function FavAndSavedSection({
  activeSection,
  setActiveSection,
  setActiveSubSection,
  activeSubSection,
}) {
  return (
    <React.Fragment>
      {activeSection === 'fav_saved' && activeSubSection === 'favorite' && (
        <>
          <FavoriteListView />
        </>
      )}
      {activeSection === 'fav_saved' && activeSubSection === 'saved' && (
        <>
          <SavedEventListView />
        </>
      )}
    </React.Fragment>
  );
}
