import { createSlice } from '@reduxjs/toolkit';

const initialState = { id: '', Showsummary: false };
const buildEvents = {
  id: '',
  ShowBuildEvents: false,
  fromVenueBuilder: localStorage.getItem('fromBuilder') || false,
};
const selectedRows = {
  selectedRows: [],
  selectedRowsKeys: [],
  tourSelectedRows: [],
  tourSelectedRowsKeys: [],
};
const isTicketolicyError = {
  isRefundError: false,
  isResaleError: false,
  isTransferError: false,
  isDelayError: false,
  isCancelledReeror: false,
  showError: false,
  checkErrorRefund: false,
  checkErrorResale: false,
  checkErrorCancel: false,
  checkErrordelay: false,
  checkErrorTransfer: false,
};

const tourList = {
  tourList: [],
  filteredTourList: [],
  selectedTour: {},
  label: 'Select Associated Tour',
};

const popover = {
  open: false,
  openSort: false,
  isFiltered: false,
  isSorted: false,
  isFormUpdate: false,
  isSortingFormUpdate: false,
  filterCount: 0,
  // sortCount: 0,
  defaultCount: 0,
  defaultSortCount: 0,
};

const DisableHeaderCreate = { disable: false };
const checkTicketTypeChanged = { data: {} };

const NavigateBuildTab = { navigate: false };

const disableBookTicket = { virtual: false, physical: false, both: false };

const slice = createSlice({
  name: 'eventTab',
  initialState,
  reducers: {
    AddTab: (state, { payload }) => {
      state.id = payload.id;
      state.Showsummary = payload.Showsummary;
    },
    deleteEventList: (state, { payload }) => {
      // state.count = payload.dataCount;
      state.items = payload.data;
      return;
    },
  },
});

const popOverSlice = createSlice({
  name: 'popover',
  initialState: popover,
  reducers: {
    updatePopover: (state, { payload }) => {
      state.open = payload;
    },
    updateSortingPopover: (state, { payload }) => {
      state.openSort = payload;
    },
    updateisFiltered: (state, { payload }) => {
      state.isFiltered = payload;
    },
    updateisSorted: (state, { payload }) => {
      state.isSorted = payload;
    },
    updateForm: (state, { payload }) => {
      state.isFormUpdate = payload;
    },
    updateSortingForm: (state, { payload }) => {
      state.isSortingFormUpdate = payload;
    },
    updateFilterCount: (state, { payload }) => {
      state.filterCount = payload;
    },
    updateSortCount: (state, { payload }) => {
      state.sortCount = payload;
    },
    updateDefaultCount: (state, { payload }) => {
      state.defaultCount = payload;
    },
    updateDefaultSortCount: (state, { payload }) => {
      state.defaultSortCount = payload;
    },
  },
});

const checkPolicyError = createSlice({
  name: 'eventModal',
  initialState: isTicketolicyError,
  reducers: {
    checkIsPolicyError: (state, { payload }) => {
      state.isRefundError = payload.isRefundError;
      state.isResaleError = payload.isResaleError;
      state.isTransferError = payload.isTransferError;
      state.isDelayError = payload.isDelayError;
      state.isCancelledReeror = payload.isCancelledReeror;
      state.showError = payload.showError;
      state.checkErrorRefund = payload.checkErrorRefund;
      state.checkErrorResale = payload.checkErrorResale;
      state.checkErrorCancel = payload.checkErrorCancel;
      state.checkErrordelay = payload.checkErrordelay;
      state.checkErrorTransfer = payload.checkErrorTransfer;
    },
    reset: (state, { payload }) => {
      state.isRefundError = false;
      state.isResaleError = false;
      state.isTransferError = false;
      state.isDelayError = false;
      state.isCancelledReeror = false;
      state.showError = false;
      state.checkErrorRefund = false;
      state.checkErrorResale = false;
      state.checkErrorCancel = false;
      state.checkErrordelay = false;
      state.checkErrorTransfer = false;
    },
  },
});

const Disabled = createSlice({
  name: 'Disabled',
  initialState: DisableHeaderCreate,
  reducers: {
    DisableButton: (state, { payload }) => {
      state.disable = payload.disable;
    },
  },
});

const tourListSlice = createSlice({
  name: 'tourListSlice',
  initialState: tourList,
  reducers: {
    updateTourLists: (state, { payload }) => {
      state.tourList = payload;
    },
    updateFilterTours: (state, { payload }) => {
      state.filteredTourList = payload;
    },
    updateSelectedTour: (state, { payload }) => {
      state.selectedTour = payload;
    },
  },
});

const DisabledBookNow = createSlice({
  name: 'disableBookNow',
  initialState: disableBookTicket,
  reducers: {
    updatePhysical: (state, { payload }) => {
      state.physical = payload;
    },
    updateVirtual: (state, { payload }) => {
      state.virtual = payload;
    },
    updateBoth: (state, { payload }) => {
      state.both = payload;
    },
  },
});

const TicketTypeChanged = createSlice({
  name: 'TicketTypeChanged',
  initialState: checkTicketTypeChanged,
  reducers: {
    UpdateTicketOnMount: (state, { payload }) => {
      state.data = payload;
    },
  },
});

const selectedRowsSlice = createSlice({
  name: 'selectedRows',
  initialState: selectedRows,
  reducers: {
    updateRows: (state, { payload }) => {
      state.selectedRows = payload.selectedRows;
      state.selectedRowsKeys = payload.selectedRowsKeys;
      state.tourSelectedRows = payload.tourSelectedRows;
      state.tourSelectedRowsKeys = payload.tourSelectedRowsKeys;
    },
    resetRows: (state, { payload }) => {
      state.selectedRows = [];
      state.selectedRowsKeys = [];
      state.tourSelectedRows = [];
      state.tourSelectedRowsKeys = [];
    },
  },
});

const buildEventslice = createSlice({
  name: 'BuildEvents',
  initialState: buildEvents,
  reducers: {
    showBuildYourEvents: (state, { payload }) => {
      state.id = payload.id;
      state.ShowBuildEvents = payload.ShowBuildEvents;
      state.fromVenueBuilder = payload.fromVenueBuilder;
    },
  },
});

const NavigateSlice = createSlice({
  name: 'NavigateTab',
  initialState: NavigateBuildTab,
  reducers: {
    NavigateTab: (state, { payload }) => {
      state.navigate = payload.navigate;
    },
  },
});

let reducer = slice.reducer;
let Navigatereducer = NavigateSlice.reducer;
let buildEventReducer = buildEventslice.reducer;
let DisabledHeaderCreateReducer = Disabled.reducer;
let DisabledBookNowReducer = DisabledBookNow.reducer;
let TourListReducer = tourListSlice.reducer;
let popOverReducer = popOverSlice.reducer;

let TicketTypeChangeReducer = TicketTypeChanged.reducer;

let checkTicketPolicyError = checkPolicyError.reducer;
let eventSelectedRows = selectedRowsSlice.reducer;

export const { AddTab } = slice.actions;
export const { NavigateTab } = NavigateSlice.actions;
export const { showBuildYourEvents } = buildEventslice.actions;
export const { DisableButton } = Disabled.actions;
export const { updateTourLists, updateSelectedTour, updateFilterTours } =
  tourListSlice.actions;
export const { checkIsPolicyError, reset } = checkPolicyError.actions;
export const { updateRows, resetRows } = selectedRowsSlice.actions;
export const { UpdateTicketOnMount } = TicketTypeChanged.actions;
export const { updatePhysical, updateVirtual, updateBoth } =
  DisabledBookNow.actions;
export const {
  updatePopover,
  updateSortingPopover,
  updateisFiltered,
  updateisSorted,
  updateForm,
  updateSortingForm,
  updateFilterCount,
  updateSortCount,
  updateDefaultCount,
  updateDefaultSortCount,
} = popOverSlice.actions;

const disableBookNow = (state) => state.DisableBookTicket;
const TourListData = (state) => state.tourListdatas;

export {
  reducer,
  Navigatereducer,
  buildEventReducer,
  DisabledHeaderCreateReducer,
  checkTicketPolicyError,
  eventSelectedRows,
  TicketTypeChangeReducer,
  DisabledBookNowReducer,
  disableBookNow,
  TourListReducer,
  TourListData,
  popOverReducer,
};
