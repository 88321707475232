import { createSlice } from '@reduxjs/toolkit';
import { updateDataCountByViewportForEventCards } from '../../utils/util';

export const EVENT_LIST = 'Event List';
export const ARTIST_TYPE = 'Artist Type';
export const ARTIST_SUB_TYPE = 'Artist Sub Type';
export const TOUR = 'Tour';

export const GRID = 'grid';
export const LIST = 'list';
export const LAYOUT3X3 = '4';
export const LAYOUT2X2 = '3';

export const FILTER_CONST = {
  [EVENT_LIST]: 'event_list',
  [ARTIST_TYPE]: 'artist_type',
  [ARTIST_SUB_TYPE]: 'artist_sub_type',
  [TOUR]: 'tour',
};

export const initialFilterParams = {
  search_key: undefined,
  event_list: [],
  artist_type: [],
  artist_sub_type: [],
  tour: undefined,
  sort_by: undefined,
  page: 1,
  limit: updateDataCountByViewportForEventCards() * 2,
};

const initialState = {
  all_artist: [],
  all_artist_count: 0,
  filterParams: initialFilterParams,
  paginated_index: 1,
  filter_pills: {},
  filter_loading: false,
  disable_apply: false,
  filter_settings: {
    view: GRID,
    layout: LAYOUT3X3,
  },
  all_artist_filter_count: {},
};

const slice = createSlice({
  name: 'allArtist',
  initialState,
  reducers: {
    updateAllArtist: (state, { payload }) => {
      state.all_artist = payload.array;
      state.all_artist_count = payload.count;
    },
    // updateAllSampleArtist: (state, { payload }) => {
    //   state.all_artist = payload;
    // },
    // updateAllCategories: (state, { payload }) => {
    //   state.all_categories = payload;
    // },

    updatePaginatedIndex: (state, { payload }) => {
      state.paginated_index = payload;
    },

    updateFilter: (state, { payload }) => {
      state.filterParams = payload;
      // let separam = new URLSearchParams(payload).toString()
      // const params = new URLSearchParams(separam);
      // const paramsObj = Object.fromEntries(params.entries());
      // updateFilter(paramsObj);
    },
    updateFilterPills: (state, { payload }) => {
      state.filter_pills = payload;
    },
    updateFilterSettings: (state, { payload }) => {
      state.filter_settings = payload;
    },
    updateFilterLoading: (state, { payload }) => {
      state.filter_loading = payload;
    },
    updateDisableApply: (state, { payload }) => {
      state.disable_apply = payload;
    },
    updateAllArtistsFilterCount: (state, { payload }) => {
      state.all_artist_filter_count = payload;
    },
  },
});

const allArtistReducer = slice.reducer;
export const {
  updateAllArtist,
  updateFilter,
  updatePaginatedIndex,
  updateFilterPills,
  updateFilterSettings,
  updateFilterLoading,
  updateDisableApply,
  updateAllArtistsFilterCount,
} = slice.actions;

export { allArtistReducer };
