import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Autoplay,
  Pagination,
  Navigation,
  Thumbs,
  EffectCoverflow,
} from 'swiper';
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-cube';
import { RiFacebookFill } from 'react-icons/ri';
import { AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';
import { FaTelegramPlane } from 'react-icons/fa';
import moment from 'moment';
import { DATEFORMAT, getDurtion, truncate } from '../../utils/util';
import Tooltip from '../common/Tooltip';
import { Link, useNavigate } from 'react-router-dom';
import {
  FullScreenSkeleton,
  LandingPageSliderSkeleton,
} from '../util/Skeleton';
import { CalenderIcon2, profileUrl } from '../util/CustomIcons';
import bannerImage from '../../assets/images/banner/bg.png';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  primaryBtnClassGradientSec,
  sliderButton,
} from '../common/DefaultClasses';
import 'swiper/css/thumbs';
import { useSelector } from 'react-redux';
// import Video from '../common/Video';
import EventImageVideo from '../common/EventImageVideo';
// import { getHours } from 'date-fns';
import { Controller } from 'swiper';

export default function BannerSliderV2({ isLoading }) {
  // console.log(isLoading, "'isLoading");
  let navigate = useNavigate();
  const PublishedEvents = useSelector((state) => state?.publishedEvents);
  const eventdetails = PublishedEvents?.banner;
  const userdetails = useSelector((state) => state.userDetails);
  const socialMediaUrls = userdetails?.data?.socialmedia_urls
    ? JSON.parse(userdetails?.data?.socialmedia_urls)
    : {
        instagram_url: '',
        facebook_url: '',
        twitter_url: '',
        telegram_url: '',
      };

  // const mainSwiperRef = useRef(null);
  // const thumbsSwiperRef = useRef(null);

  // Set up the connection between the main swiper and the thumb swiper
  // useEffect(() => {
  //   if (mainSwiperRef.current && thumbsSwiperRef.current) {
  //     mainSwiperRef.current.controller.control = thumbsSwiperRef.current;
  //     thumbsSwiperRef.current.controller.control = mainSwiperRef.current;
  //   }
  // }, []);

  // Function used to navigate page

  // in future need this
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  // in future need this

  const navigateToPage = useCallback(
    (data) => {
      navigate(`/event-detail/${data}`);
    },
    [navigate],
  );
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const [swiper, setSwiper] = React.useState(null);

  // const prevto = () => {
  //   swiper.slidePrev();
  // };
  // const nexto = () => {
  //   swiper.slideNext();
  // };
  // function slideTo(index) {
  //   thumbsSwiper.slideTo(index, 0);
  // }

  // swiper.update();

  // useEffect(() => {
  //   const slideTo = (index) => {
  //     console.log(index, 'index');
  //     thumbsSwiper?.slideTo(index, 0);
  //   };
  //   slideTo();
  // }, [thumbsSwiper]);

  const thumbnailSmallSlider = useCallback((props) => {
    const { data } = props;
    return data ? (
      <div className="relative h-full w-auto z-0">
        <div className="w-[60px] h-[80px] lg:w-[80px] lg:h-[100px] xl:w-[100px] xl:h-[120px] rounded-lg overflow-hidden thumbImage">
          <EventImageVideo
            data={data?.banner_image ? data?.banner_image : profileUrl}
            alt={'banner'}
            className={'w-full h-full object-cover'}
            loading="lazy"
            width="384px"
            height="512px"
          />
        </div>
      </div>
    ) : (
      <></>
    );
  }, []);

  const ThumnailSlider = memo(thumbnailSmallSlider);

  const mainSlider = useCallback(
    (props) => {
      const { data } = props;
      return (
        <div className="relative h-full banner_height banner_height_v2">
          <div className="absolute inset-0 z-0 rounded-3xl overflow-hidden border-1 dark:border-brown-800 border-brown-100">
            <EventImageVideo
              data={data?.banner_image ? data?.banner_image : bannerImage}
              alt={'banner'}
              className={'w-full h-full object-cover rounded-3xl'}
              loading="lazy"
              width="384px"
              height="512px"
            />

            <div className="bg-neutral-900 absolute inset-0 bg-opacity-50"></div>
          </div>
          <div className="container-fluid lg:max-w-[95%] xl:max-w-[97%] h-full relative pl-[20px] xl-pl-0">
            <div className="flex flex-col justify-between space-y-6 relative z-7 text-gray-50 h-full max-w-6xl 2xl:max-w-7xl pt-10 pb-24 lg:py-10">
              <div className="flex flex-col justify-start space-y-6">
                <div className="flex">
                  <span className="bg-brown-800 rounded-full p-1.5 px-3 text-lg">
                    Duration :{' '}
                    {getDurtion(
                      moment.utc(data?.start_date_time).local(),
                      moment.utc(data?.end_date_time).local(),
                    )}
                  </span>
                </div>

                <div className="flex items-center">
                  <div className="flex items-center gap-2">
                    <div className="flex gap-1.5 slider-title">
                      <span className="text-xl flex mt-0.5">
                        <CalenderIcon2 />
                      </span>
                      <div className="flex flex-col">
                        <span>Start Date & Time</span>
                        <span className="font-medium">
                          {moment(
                            moment.utc(data?.start_date_time).local(),
                            'YYYY-MM-DD hh:mm a',
                          ).format(DATEFORMAT)}
                        </span>
                      </div>
                    </div>
                    <div className="flex">
                      <span className="flex h-[30px] w-[1px] bg-slate-400"></span>
                    </div>
                    <div className="flex gap-1.5 slider-sub-title">
                      <span className="text-xl flex mt-0.5">
                        <CalenderIcon2 />
                      </span>
                      <div className="flex flex-col">
                        <span>End Date & Time</span>
                        <span className="font-medium">
                          {moment(
                            moment.utc(data?.end_date_time).local(),
                            'YYYY-MM-DD hh:mm a',
                          ).format(DATEFORMAT)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="min-h-[30px]"></div>
                <div className="w-full max-w-6xl">
                  <div
                    className="w-full slider-title"
                    onClick={() => navigateToPage(data?.slug)}
                    style={{ cursor: 'pointer' }}
                  >
                    <h2 className="text-2xl md:text-5xl font-bold">
                      {/* {truncate(data?.name, 18) || '-'} */}
                      {data?.name || 'NFTGOTIX'}
                    </h2>
                  </div>
                  <h5 className="text-sm md:text-lg slider-sub-title">
                    {truncate(data?.description, 100)}
                    {/* {data?.description} */}
                  </h5>
                </div>
              </div>
              {data && (
                <div className="flex pb-8 slider-buttton">
                  <Link
                    to={`/event-detail/${data?.slug}`}
                    className={`${primaryBtnClassGradientSec} lg:min-w-[150px] text-lg`}
                  >
                    Buy Now
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    },
    [navigateToPage],
  );

  const MainSlider = memo(mainSlider);

  const checkIsLoading = useCallback(() => {
    if (eventdetails?.data?.length === 0 && isLoading) {
      return true;
    }
    return false;
  }, [eventdetails, isLoading]);

  const updatedEventDetails = useMemo(() => {
    return eventdetails?.data;
    // return [eventdetails?.data?.[0], eventdetails?.data?.[1]];
    // return [eventdetails?.data?.[0]];
    // return [];
  }, [eventdetails]);

  const shouldShowNavigation = useMemo(
    () => updatedEventDetails.length > 1,
    [updatedEventDetails],
  );

  // console.log('thumbsSwiperRef.current', thumbsSwiperRef.current);
  // console.log('mainSwiperRef', mainSwiperRef?.current);
  // console.log('shouldShowNavigation', shouldShowNavigation);

  return (
    <React.Fragment>
      <div className="relative banner_height banner_height_v2 sam">
        <div className="container-fluid max-w-[1800px] relative">
          <div className="lg:px-9 relative">
            <div className="relative">
              <>
                {/* {updatedEventDetails?.length > 1 && ( */}
                <div className="absolute z-50 right-1/2 translate-x-1/2 lg:translate-x-0 lg:right-3 bottom-4 lg:bottom-6 w-full max-w-[170px] lg:max-w-[224px] xl:max-w-[278px]">
                  <Swiper
                    // ref={thumbsSwiperRef}
                    onSwiper={(swiper) => setThumbsSwiper(swiper)}
                    loop={true}
                    hashNavigation={{
                      watchState: true,
                    }}
                    // controller={{ control: swiper }}
                    // onSwiper={(e) => {
                    //   console.log(e.activeIndex);
                    //   setSwiper(e);
                    // }}
                    // watchSlidesProgress
                    spaceBetween={10}
                    type={'custom'}
                    slidesPerView={'auto'}
                    freeMode={true}
                    // cssMode={true}
                    watchSlidesProgress={true}
                    allowTouchMove={false}
                    pagination={shouldShowNavigation ? true : false}
                    centeredSlides={true}
                    // observeParents={true}
                    // observer={true}
                    // watchSlidesVisibility={true}
                    // swiperSlideSize={true}
                    // visibilityFullFit={true}
                    slideToClickedSlide={true}
                    effect="coverflow"
                    centeredSlidesBounds={true}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                    }}
                    // loopedSlides={50}
                    onBeforeInit={(swiper) => {
                      navigationPrevRef.current =
                        swiper.params.navigation.prevEl;
                      navigationNextRef.current =
                        swiper.params.navigation.nextEl;
                    }}
                    navigation={
                      shouldShowNavigation
                        ? {
                            prevEl:
                              updatedEventDetails?.length > 1
                                ? navigationPrevRef.current
                                : null,
                            nextEl:
                              updatedEventDetails?.length > 1
                                ? navigationNextRef.current
                                : null,
                          }
                        : false
                    }
                    modules={[
                      // FreeMode,
                      Navigation,
                      Thumbs,
                      EffectCoverflow,
                      Autoplay,
                      Controller,
                      Pagination,
                    ]}
                    // speed={1200}
                    coverflowEffect={{
                      rotate: 0,
                      stretch: 0,
                      depth: 50,
                      modifier: 3.5,
                      slideShadows: false,
                    }}
                    className="thumbSwiper relative banner_swiper"
                  >
                    {checkIsLoading() ? (
                      <>
                        {[...Array(3)].map((_, idx) => (
                          <SwiperSlide
                            key={idx}
                            className="relative max-w-[60px] max-h-[80px] lg:max-w-[80px] lg:max-h-[100px] xl:max-w-[100px] xl:max-h-[120px] mb-[18px] pointer-events-none"
                          >
                            <div className="relative h-full w-auto z-0">
                              <div className="w-[60px] h-[80px] lg:w-[80px] lg:h-[100px] xl:w-[100px] xl:h-[120px] rounded-lg overflow-hidden border-none shadow-none dark:bg-zinc-900 bg-lightTheme-70">
                                <FullScreenSkeleton />
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </>
                    ) : (
                      <>
                        {updatedEventDetails?.length > 1 ? (
                          updatedEventDetails?.map(
                            (data, index) =>
                              index <= 2 && (
                                <SwiperSlide
                                  onClick={() => {
                                    console.log('here');
                                    thumbsSwiper?.slideTo(
                                      swiper?.activeIndex,
                                      500,
                                      false,
                                    );
                                  }}
                                  className="relative max-w-[60px] max-h-[80px] lg:max-w-[80px] lg:max-h-[100px] xl:max-w-[100px] xl:max-h-[120px] mb-[4px]"
                                  key={data?._id}
                                  // virtualIndex={index}
                                  // onClick={() => slideTo(index)}
                                >
                                  <ThumnailSlider data={data} />
                                </SwiperSlide>
                              ),
                          )
                        ) : (
                          <>
                            {[...Array(3)].map((_, idx) => (
                              <SwiperSlide
                                key={idx}
                                className="relative max-w-[60px] max-h-[80px] lg:max-w-[80px] lg:max-h-[100px] xl:max-w-[100px] xl:max-h-[120px] mb-[18px] pointer-events-none"
                              >
                                {checkIsLoading() ? (
                                  <div className="relative h-full w-auto z-0">
                                    <div className="w-[60px] h-[80px] lg:w-[80px] lg:h-[100px] xl:w-[100px] xl:h-[120px] rounded-lg overflow-hidden border-none shadow-none dark:bg-zinc-900 bg-lightTheme-70">
                                      <FullScreenSkeleton />
                                    </div>
                                  </div>
                                ) : (
                                  idx >= 2 && <ThumnailSlider />
                                )}
                                {/* Need to design the skeleton for this ui */}
                              </SwiperSlide>
                            ))}
                          </>
                        )}
                      </>
                    )}
                    {/* <div className="absolute inset-0 z-[1] w-full h-full"></div> */}
                  </Swiper>
                </div>
                {/* )} */}

                <Swiper
                  // ref={mainSwiperRef}
                  className="banner_swiper h-full w-full relative animateSlider"
                  hashNavigation={{
                    watchState: true,
                  }}
                  onSwiper={(swiper) => setSwiper(swiper)}
                  // controller={{ control: thumbsSwiper }}
                  // allowTouchMove={false}
                  // lazy={'true'}
                  // pagination={true}

                  loopedSlides={6}
                  loop={updatedEventDetails?.length > 1 ? true : false}
                  slidesPerView={1}
                  centeredSlides={true}
                  // freeMode={true}
                  // touchStartPreventDefault={true}
                  thumbs={{
                    swiper:
                      updatedEventDetails?.length > 1 ? thumbsSwiper : null,
                  }}
                  // slideToClickedSlide={true}
                  allowTouchMove={false}
                  // swiperSlideSize={true}
                  // effect={'fade'}
                  navigation={
                    shouldShowNavigation
                      ? {
                          prevEl:
                            updatedEventDetails?.length > 1
                              ? navigationPrevRef.current
                              : null,
                          nextEl:
                            updatedEventDetails?.length > 1
                              ? navigationNextRef.current
                              : null,
                        }
                      : false
                  }
                  onBeforeInit={(swiper) => {
                    // if (shouldShowNavigation) {
                    navigationPrevRef.current = swiper.params.navigation.prevEl;
                    navigationNextRef.current = swiper.params.navigation.nextEl;
                    // } else {
                    //   return null;
                    // }
                  }}
                  // onSlideChange={(swiperCore, slideTo) => {
                  //   const { activeIndex, snapIndex, previousIndex, realIndex } =
                  //     swiperCore;

                  //     activeIndex,
                  //     snapIndex,
                  //     previousIndex,
                  //     realIndex,
                  //   });
                  // }}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  // speed={1200}
                  // effect={'cube'}
                  // cubeEffect={{
                  //   shadow: true,
                  //   slideShadows: true,
                  //   shadowOffset: 20,
                  //   shadowScale: 0.94,
                  // }}
                  modules={[
                    Autoplay,
                    Navigation,
                    Thumbs,
                    Controller,
                    Pagination,
                    // EffectCube,
                  ]}
                >
                  <div className="w-full min-h-screen relative overflow-hidden">
                    {checkIsLoading() ? (
                      <>
                        {console.log('here')}
                        {[...Array(3)].map((_, idx) => (
                          <SwiperSlide
                            key={idx}
                            className="relative h-full w-full  rounded-3xl overflow-hidden pointer-events-none"
                          >
                            <LandingPageSliderSkeleton />
                          </SwiperSlide>
                        ))}
                      </>
                    ) : (
                      <>
                        {updatedEventDetails?.length > 0 ? (
                          updatedEventDetails?.map(
                            (data, index) =>
                              index <= 2 && (
                                <SwiperSlide
                                  className={`relative h-full w-full slide-${
                                    index + 1
                                  }`}
                                  key={data?._id}
                                >
                                  <MainSlider data={data} />
                                </SwiperSlide>
                              ),
                          )
                        ) : (
                          <>
                            <SwiperSlide
                              key={1}
                              className="relative h-full w-full rounded-3xl overflow-hidden pointer-events-none"
                            >
                              <MainSlider />
                            </SwiperSlide>
                            {/* {[
                              ...Array(checkIsLoading()?.length === 0 ? 1 : 3),
                            ].map((_, idx) => (
                              <SwiperSlide
                                key={idx}
                                className="relative h-full w-full rounded-3xl overflow-hidden pointer-events-none"
                              >
                                {checkIsLoading() ? (
                                  <LandingPageSliderSkeleton />
                                ) : (
                                  <MainSlider />
                                )}
                              </SwiperSlide>
                            ))} */}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Swiper>
              </>

              <div
                className={`md:flex justify-content-end align-items-center gap-2 `}
              >
                <div className="absolute -left-3 lg:-left-4 top-1/2 z-10 -translate-y-1/2">
                  <button
                    ref={navigationPrevRef}
                    // onClick={prevto}
                    className={`relative w-8 h-8 rounded-full dark:bg-landingPurple-60 disabled:bg-neutral-700 text-gray-50 disabled:opacity-30 ${sliderButton}`}
                  >
                    <MdKeyboardArrowLeft className="text-md md:text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                  </button>
                </div>
                <div className="absolute -right-3 lg:-right-4 top-1/2 z-10 -translate-y-1/2">
                  <button
                    ref={navigationNextRef}
                    // onClick={nexto}
                    className={`relative w-8 h-8 rounded-full dark:bg-landingPurple-60 disabled:bg-neutral-700 text-gray-50 disabled:opacity-30 ${sliderButton}`}
                  >
                    <MdKeyboardArrowRight className="text-md md:text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="absolute z-20 rotate-90 floating_social hidden lg:flex">
            <div className="flex items-center shrink-0 p-1">
              <span className="flex text-zinc-900 dark:text-gray-300 shrink-0 tracking-widest">
                FOLLOW ME
              </span>
              <span className="flex w-44 shrink-0">
                <svg
                  width="1em"
                  height="2"
                  viewBox="0 0 1632 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-full"
                >
                  <line
                    y1="1"
                    x2="1632"
                    y2="1"
                    stroke="url(#paint0_linear_4289_1647)"
                    strokeWidth="2"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4289_1647"
                      x1="1652"
                      y1="3.08267"
                      x2="62"
                      y2="3.08267"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopOpacity="0" />
                      <stop offset="0.463542" stopColor="white" />
                      <stop offset="0.671875" stopColor="white" />
                      <stop offset="1" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <div className="flex justify-start w-full max-w-sm gap-1">
                <Tooltip title={`${'Facebook'}`} placement={'right'}>
                  <span className="flex w-8 h-8 rounded-full relative p-1 -rotate-90 bg-transparent items-center justify-center">
                    <Link
                      to={`${
                        socialMediaUrls?.facebook_url
                          ? socialMediaUrls?.facebook_url
                          : '#'
                      }`}
                      target={socialMediaUrls?.facebook_url ? '_blank' : ''}
                      rel="noopener noreferrer"
                    >
                      <RiFacebookFill className="w-full h-full" />
                    </Link>
                  </span>
                </Tooltip>
                <Tooltip title={`${'Instagram'}`} placement={'right'}>
                  <span className="flex w-8 h-8 rounded-full relative p-1 -rotate-90 bg-transparent items-center justify-center">
                    <Link
                      to={`${
                        socialMediaUrls?.instagram_url
                          ? socialMediaUrls?.instagram_url
                          : '#'
                      }`}
                      target={socialMediaUrls?.instagram_url ? '_blank' : ''}
                      rel="noopener noreferrer"
                    >
                      <AiOutlineInstagram className="w-full h-full" />
                    </Link>
                  </span>
                </Tooltip>
                <Tooltip title={`${'Telegram'}`} placement={'right'}>
                  <span className="flex w-8 h-8 rounded-full relative p-1 -rotate-90 bg-transparent items-center justify-center">
                    <Link
                      to={`${
                        socialMediaUrls?.telegram_url
                          ? socialMediaUrls?.telegram_url
                          : '#'
                      }`}
                      target={socialMediaUrls?.telegram_url ? '_blank' : ''}
                      rel="noopener noreferrer"
                    >
                      <FaTelegramPlane className="w-full h-full" />
                    </Link>
                  </span>
                </Tooltip>
                <Tooltip title={`${'Twitter'}`} placement={'right'}>
                  <span className="flex w-8 h-8 rounded-full relative p-1 -rotate-90 bg-transparent items-center justify-center">
                    <Link
                      to={`${
                        socialMediaUrls?.twitter_url
                          ? socialMediaUrls?.twitter_url
                          : '#'
                      }`}
                      target={socialMediaUrls?.twitter_url ? '_blank' : ''}
                      rel="noopener noreferrer"
                    >
                      <AiOutlineTwitter className="w-full h-full" />
                    </Link>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
