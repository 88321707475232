import React from 'react';
import {
  FacebookIcon,
  InstagramIcon,
  SpinnerBtn,
  TelegramIcon,
  TwitterIcon,
  UploadIcon,
  UploadIcon2,
  VerifiedUserIcon,
  profileUrl,
} from '../../util/CustomIcons';
import { AiOutlineDelete } from 'react-icons/ai';
// import EventTime from './EventTime';
import Tooltip from '../../common/Tooltip';
import { Link, useParams } from 'react-router-dom';
import { useUserDetails } from '../../../hooks/user/useUserDetails';
import { shortenAddress } from '../../../utils/util';
import moment from 'moment';
import { useDisplayImage } from '../../../hooks/useDisplayImage';
import {
  updateUserDetails,
  removeUserImages,
} from '../../../helpers/api/user/user_details';
import useDecodedToken, { useApiHeader } from '../../../hooks/useDecodedToken';
import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';
import { DotLoader } from 'react-spinners';
import EventTime from '../../dashboard/EventTime';
import { iconBtnSize, secondaryBtnClass } from '../../common/DefaultClasses';

let types = '';

export default function ProfileBannerV2() {
  const { username } = useParams();
  const {
    userdetails: { data },
    refetch,
  } = useUserDetails(username);

  const decodedToken = useDecodedToken();
  const socialMediaUrls = data?.data?.socialmedia_urls
    ? JSON.parse(data?.data?.socialmedia_urls)
    : {
        instagram_url: '',
        facebook_url: '',
        twitter_url: '',
        telegram_url: '',
      };

  React.useEffect(() => {
    if (data) {
      localStorage.setItem('userID', JSON.stringify(data?.data?._id));
    }
  }, [data]);

  const { form_header, json_header } = useApiHeader();
  const { uploader, result, banner, setResult, setBanner } = useDisplayImage();
  const handleUploadImg = (e) => {
    e.preventDefault();
    if (!data?.data?.email_verified) {
      message.error('Update only allowed for verified users');
    } else {
      document.getElementById('profile-btn').click();
    }
  };

  const handleUploadBanner = (e) => {
    e.preventDefault();
    if (!data?.data?.email_verified) {
      message.error('Update only allowed for verified users');
    } else {
      document.getElementById('banner-btn').click();
    }
  };

  const uploadImg = (e) => {
    e.preventDefault();
    if (e.target.files[0].size > 52428800) {
      message.error('Profile Image should not be greater than 50MB');
      e.target.value = null;
    } else {
      uploader(e);
      submitImg(e);
    }
  };

  const removeImg = (type) => {
    types = type;
    onRemoveImg(type);
  };

  const uploadBanner = (e) => {
    e.preventDefault();
    if (e.target.files[0].size > 52428800) {
      message.error('Banner Image should not be greater than 50MB');
      e.target.value = null;
    } else {
      uploader(e, 'banner');
      submitBanner(e);
    }
  };

  const inserNote = (e) => {
    const formdata = new FormData();
    formdata.append('image', e.target.files[0]);
    e.target.value = null;
    return updateUserDetails(formdata, form_header);
  };

  const insertBanner = (e) => {
    const formdata = new FormData();
    formdata.append('banner', e.target.files[0]);
    e.target.value = null;
    return updateUserDetails(formdata, form_header);
  };

  const removeImages = (type) => {
    let payload = {
      user_id: data?.data?._id,
      image_remove: type === 'profile' ? true : false,
      banner_image_remove: type === 'banner' ? true : false,
    };
    return removeUserImages(payload, json_header);
  };

  const mutation = useMutation(inserNote, {
    onSuccess: async () => {
      refetch();
      message.success('Profile Image uploaded successfully');
    },
    onError: (err) => {
      message.error(err?.response?.data?.message);
    },
  });

  const ban_mutation = useMutation(insertBanner, {
    onSuccess: async () => {
      refetch();
      message.success('Banner Image uploaded successfully');
      // openNotification();
    },
    onError: (err) => {
      message.error(err?.response?.data?.message);
    },
  });

  const remove_mutation = useMutation(removeImages, {
    onSuccess: async () => {
      types === 'profile' ? setResult('') : setBanner('');
      types = '';
      refetch();
      message.success(`${types} Image removed successfully`);
    },
    onError: (err) => {
      types = '';
      message.error(err?.response?.data?.message);
    },
  });

  const submitImg = (e) => {
    mutation.mutate(e);
  };

  const onRemoveImg = (type) => {
    remove_mutation.mutate(type);
  };

  const submitBanner = (e) => {
    ban_mutation.mutate(e);
  };
  // const [api, contextHolder] = notification.useNotification();
  // const openNotification = () => {
  //   api.open({
  //     message: (
  //       <>
  //         <span className="text-green-600">
  //           Banner Image uploaded successfully
  //         </span>
  //       </>
  //     ),
  //     // description:
  //     //   'I will never close automatically. This is a purposely very very long description that has many many characters and words.',
  //     duration: 0,
  //     style: { marginTop: '50px' },
  //   });
  // };
  return (
    <React.Fragment>
      {/* {contextHolder} */}
      <div className="w-full">
        <div className="relative authorBanner w-full h-56 md:h-52 xl:h-64 2xl:h-64">
          <div className="banImg absolute inset-0 w-full h-full bg-gray-200 shadow-sm z-0">
            <div className="">
              <div className="container-fluid">
                <div className="relative">
                  <div className="absolute top-8 right-0 zindex-1">
                    <input
                      hidden
                      id="banner-btn"
                      onChange={uploadBanner}
                      accept="image/*"
                      type={'file'}
                    />
                    <div className="flex gap-2">
                      {data?.data?.banner_image?.url && (
                        <>
                          <Tooltip
                            title={
                              <>
                                <span className="flex flex-col justify-center">
                                  <span>Upload Banner Image</span>
                                  <span>
                                    File types supported: JPG, PNG, JPEG Max
                                    Size: 50 MB
                                  </span>
                                </span>
                              </>
                            }
                            placement={'left'}
                          >
                            <button
                              type="button"
                              onClick={handleUploadBanner}
                              disabled={remove_mutation?.isLoading}
                              className={`${secondaryBtnClass} ${iconBtnSize} rounded-full`}
                            >
                              {ban_mutation?.isLoading ? (
                                <>
                                  <span className="flex absolute top-1/2 left-1/2 translate-neg-50-50 object-cover text-xl">
                                    <SpinnerBtn className={'text-md'} />
                                  </span>
                                </>
                              ) : (
                                <>
                                  <UploadIcon className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover h-6 w-6 text-bluev2-600" />
                                </>
                              )}
                            </button>
                          </Tooltip>
                          <Tooltip
                            title="Remove Banner"
                            placement={'bottomLeft'}
                          >
                            <button
                              type="button"
                              disabled={ban_mutation?.isLoading}
                              onClick={() => removeImg('banner')}
                              className={`${secondaryBtnClass} ${iconBtnSize} rounded-full`}
                            >
                              {remove_mutation?.isLoading ? (
                                <>
                                  <span className="flex absolute top-1/2 left-1/2 translate-neg-50-50 object-cover text-xl">
                                    <i className="fas fa-circle-notch fa-spin"></i>
                                  </span>
                                </>
                              ) : (
                                <>
                                  <AiOutlineDelete className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover h-6 w-6 text-rose-700" />
                                </>
                              )}
                            </button>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {banner && !data?.data?.banner_image?.url && (
              <>
                <div className="relative h-full bg-neutral-800 bg-opacity-60 zindex-1">
                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full flex flex-col items-center">
                    <DotLoader color="#fff" />
                    <span className="text-whiteNew-0 mt-2">
                      Please Wait, Uploading Banner Image...
                    </span>
                  </div>
                </div>
              </>
            )}
            {banner || data?.data?.banner_image?.url ? (
              <div className="absolute inset-0 w-full h-full">
                <img
                  src={banner || data?.data?.banner_image?.url}
                  alt="author cover pic"
                  className="img-fluid w-full h-full object-cover absolute inset-0"
                  loading="lazy"
                />
              </div>
            ) : (
              <>
                <div
                  className="absolute inset-0 w-full h-full bg-gray-200 shadow-sm"
                  // onClick={handleUploadBanner}
                  type="button"
                >
                  <UploadIcon2
                    className={
                      'text-9xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-gray-400'
                    }
                  />
                </div>
                <div
                  className="absolute w-full h-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  duration-200 parentHover overflow-hidden bg-opacity-30 "
                  onClick={handleUploadBanner}
                  type="button"
                >
                  <div className="flex justify-center gap-2 childHover w-full h-full items-center bg-sky-50 bg-opacity-20">
                    <Tooltip
                      title={
                        <>
                          <span className="flex flex-col justify-center">
                            <span>Upload Banner Image</span>
                            <span>
                              File types supported: JPG, PNG, JPEG Max Size: 50
                              MB
                            </span>
                          </span>
                        </>
                      }
                      placement={'bottom'}
                    >
                      <button
                        type="button"
                        // onClick={handleUploadBanner}
                        //disabled={!data?.data?.email_verified}

                        className={`${secondaryBtnClass} ${iconBtnSize} rounded-full`}
                      >
                        {ban_mutation?.isLoading ? (
                          <>
                            <span className="flex absolute top-1/2 left-1/2 translate-neg-50-50 object-cover text-xl">
                              <SpinnerBtn className={'text-md'} />
                            </span>
                          </>
                        ) : (
                          <>
                            <UploadIcon className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover h-6 w-6 text-bluev2-600" />
                          </>
                        )}
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="relative">
          <div className="container-fluid">
            <div className="relative w-32 h-32 md:h-44 md:w-44 2xl:w-44 2xl:h-44 -mt-16 md:-mt-24 2xl:-mt-24">
              <div className="w-32 h-32 md:h-44 md:w-44 2xl:w-44 2xl:h-44 bg-gray-100 rounded-full absolute border-4 lg:border-8 border-white">
                <div className="rounded-full h-full w-full overflow-hidden">
                  {result || data?.data?.image?.url ? (
                    <img
                      src={result || data?.data?.image?.url || profileUrl}
                      alt="user"
                      className="w-full h-full object-cover"
                      loading="lazy"
                    />
                  ) : (
                    <UploadIcon2
                      className={
                        'text-7xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-gray-400'
                      }
                    />
                  )}
                </div>
                <div className="absolute inset-0 w-full h-full items-center justify-center flex duration-200 parentHover rounded-full overflow-hidden bg-opacity-10">
                  <div className="flex justify-center gap-2 childHover w-full h-full items-center bg-gray-50 bg-opacity-20">
                    <input
                      hidden
                      id="profile-btn"
                      onChange={uploadImg}
                      accept="image/*"
                      type={'file'}
                    />
                    <Tooltip
                      title={
                        <>
                          <span className="flex flex-col justify-center">
                            <span> Upload Profile Image</span>
                            <span>
                              File types supported: JPG, PNG, JPEG Max Size:
                              50MB
                            </span>
                          </span>
                        </>
                      }
                      placement={'top'}
                    >
                      <button
                        type="button"
                        disabled={remove_mutation?.isLoading}
                        onClick={handleUploadImg}
                        className={`${secondaryBtnClass} ${iconBtnSize} rounded-full`}
                      >
                        {mutation.isLoading ? (
                          <>
                            <span className="flex absolute top-1/2 left-1/2 translate-neg-50-50 object-cover text-xl">
                              <SpinnerBtn className={'text-md'} />
                            </span>
                          </>
                        ) : (
                          <>
                            <UploadIcon className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover h-6 w-6 text-bluev2-600" />
                          </>
                        )}
                      </button>
                    </Tooltip>
                    {data?.data?.image?.url && (
                      <Tooltip title="Remove Profile" placement={'bottom'}>
                        <button
                          type="button"
                          disabled={mutation.isLoading}
                          onClick={() => removeImg('profile')}
                          className={`${secondaryBtnClass} ${iconBtnSize} rounded-full`}
                        >
                          {remove_mutation?.isLoading ? (
                            <>
                              <span className="flex absolute top-1/2 left-1/2 translate-neg-50-50 object-cover text-xl">
                                <i className="fas fa-circle-notch fa-spin"></i>
                              </span>
                            </>
                          ) : (
                            <>
                              <AiOutlineDelete className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover h-6 w-6  text-rose-700" />
                            </>
                          )}
                        </button>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className="relative inset-0">
                  <div className="absolute bottom-2 right-2 md:bottom-3 md:right-3 2xl:bottom-4 2xl:right-4 z-10">
                    {data?.data?.email_verified ? (
                      <VerifiedUserIcon className={'text-2xl'} />
                    ) : (
                      <>
                        {/* <GoUnverified color="red" className="w-8 h-8" /> */}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
