import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  checkout_event_details: localStorage.getItem('checkout_event')
    ? JSON.parse(localStorage.getItem('checkout_event') ?? {})
    : {},
  checkout_payment_loader: false,
  checkout_billing_summary: localStorage.getItem('billingAddress')
    ? JSON.parse(localStorage.getItem('billingAddress') ?? {})
    : {},
};

const TrendingEvent = {
  data: [],
};

const slice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    updateCheckoutEvent: (state, { payload }) => {
      state.checkout_event_details = payload;
    },
    updateCheckoutPaymentLoader: (state, { payload }) => {
      state.checkout_payment_loader = payload;
    },
    updateCheckoutBillingSummary: (state, { payload }) => {
      console.log(payload);
      state.checkout_billing_summary = payload;
    },
  },
});

const CartTrendingEventslice = createSlice({
  name: 'TrendingEvent',
  initialState: TrendingEvent,
  reducers: {
    updateCartTrendingEvent: (state, { payload }) => {
      state.data = payload;
    },
  },
});

const checkoutReducer = slice.reducer;
const CartTrendingEventReducer = CartTrendingEventslice.reducer;

export const {
  updateCheckoutEvent,
  updateCheckoutPaymentLoader,
  updateCheckoutBillingSummary,
} = slice.actions;

export const { updateCartTrendingEvent } = CartTrendingEventslice.actions;

export { checkoutReducer, CartTrendingEventReducer };
