import React from 'react';
import HelmetComponent from '../../utils/HelmateComponent';
import CheckoutHeader from '../../components/checkout-v2/CheckoutHeader';
import CheckoutAccountInfo from '../../components/checkout-v2/CheckoutAccountInfo';
import CheckoutBillingInfo from '../../components/checkout-v2/CheckoutBillingInfo';
import CheckoutPaymentOption from '../../components/checkout-v2/CheckoutPaymentOption';
import CheckoutOrderSummary from '../../components/checkout-v2/CheckoutOrderSummary';
// import { MdChevronRight } from 'react-icons/md';
import useDecodedToken from '../../hooks/useDecodedToken';
import { useCheckoutActions, useCheckoutBilling } from './hooks/useCheckout';
import StepsLoader from '../../components/util/StepsLoader';
import { Form } from 'antd';
import { useUserDetailsSWR } from '../../hooks/user/useUserDetails';
import AddTimerModal from '../../components/common/notification_modal/AddTimerModal';
import { ConfigProvider } from 'antd';

export default function CheckoutPageV2() {
  const decodedToken = useDecodedToken();
  const { checkout_payment_loader, form, checkout_billing_summary } =
    useCheckoutActions();

  const { countries, phones } = useCheckoutBilling();
  const { current_user_details } = useUserDetailsSWR();

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Poppins',
        },
      }}
    >
      <HelmetComponent title={'NFTGOTIX | Checkout'} />
      <div className={`w-full sm:px-0 min-h-screen bg-color-base-1`}>
        <div className="container px-0 max-w-[100%]">
          <section className="min-h-screen">
            <div>
              <CheckoutHeader />
            </div>

            <div className="">
              {/* <div className="flex justify-center py-2.5">
                <div className="flex w-full max-w-3xl items-center justify-between flex-wrap2 lg:flex-nowrap p-2.5 rounded-md bg-brown-800 bg-opacity-70">
                  <span className="text-rose-600 text-sm font-medium">
                    10:00 min remaining for complete this booking. Do you want
                    to add more time?
                  </span>
                  <div className="flex gap-2 items-center">
                    <button className="bg-landingPurple-60 hover:bg-landingPurple-70 p-1.5 px-3.5 rounded-md text-whiteNew-0 flex justify-center">
                      YES
                    </button>
                    <button className="bg-brown-900 hover:bg-opacity-70 p-1.5 px-3.5 rounded-md text-whiteNew-0">
                      NO
                    </button>
                  </div>
                </div>
              </div> */}
              <Form
                form={form}
                // onFinish={handleSubmit}
                initialValues={{
                  country:
                    checkout_billing_summary?.billing_address?.country ??
                    countries[0]?.value,
                  phone_code:
                    checkout_billing_summary?.billing_address?.phone_code ??
                    phones[0]?.value,
                  email: checkout_billing_summary?.billing_address?.email,
                  phone_no: checkout_billing_summary?.billing_address?.phone_no,
                  first_name:
                    checkout_billing_summary?.billing_address?.first_name,
                  last_name:
                    checkout_billing_summary?.billing_address?.last_name,
                  building: checkout_billing_summary?.billing_address?.building,
                  street_address:
                    checkout_billing_summary?.billing_address?.street_address,
                  town: checkout_billing_summary?.billing_address?.town,
                  postcode: checkout_billing_summary?.billing_address?.postcode,
                  state: checkout_billing_summary?.billing_address?.state,
                }}
              >
                <div className="flex flex-col md:flex-row">
                  {/* To do later */}
                  {/* <div className="flex flex-row md:hidden justify-between text-white border-1 border-transparent border-b-brown-800 p-[16px]">
                  <span className="flex flex-row items-center gap-2">
                    Show order summary <MdChevronRight />
                  </span>
                  <span className="font-semibold text-base">₹449.00</span>
                </div> */}

                  <div className="flex w-[100%] md:w-[55%] border-r border-level-2 min-h-[150px] flex-col ps-[0px] md:ps-10 pt-3.5 md:pt-0">
                    <div className="px-3.5 md:p-7 flex flex-row w-full">
                      <CheckoutAccountInfo
                        form={form}
                        userdata={current_user_details.data?.data}
                      />
                    </div>
                    {!decodedToken ? (
                      <div className="p-3.5 md:p-7 flex flex-row w-full">
                        <CheckoutBillingInfo form={form} />
                      </div>
                    ) : null}
                    {decodedToken ? (
                      <>
                        <div className="p-3.5 md:p-7 flex flex-row w-full">
                          <CheckoutPaymentOption />
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="flex w-[100%] md:w-[45%]">
                    <CheckoutOrderSummary form={form} />
                  </div>
                </div>
              </Form>
            </div>
          </section>
        </div>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {checkout_payment_loader ? <StepsLoader /> : null}
      </div>
      <AddTimerModal darkTheme={true} />
    </ConfigProvider>
  );
}
