import React, { useMemo, useState } from 'react';
import usePage from '../../hooks/event/page/usePage';
// import useSearchQuery from '../../hooks/useSearchQuery';
import { useSelector } from 'react-redux';
import { getpageSize } from '../../utils/util';
import pageSizeComponent from '../../utils/PageSizeComponent';
export const GetTourContext = React.createContext();
export const EventTourActiveContext = React.createContext();

export const GetTourProvider = ({ children }) => {
  const [TourList, setTourList] = useState({
    data: [],
    dataCount: 0,
  });
  const [currentPage, setCurrentPage] = usePage(1);
  // const [TourPageSize, setTourPageSize] = usePage(10);

  ///Updating the page size to the database started******

  const allPageSize = useSelector((state) => state?.allPaginationSize);
  const { pagination_list } = allPageSize;

  const TourPageSize = useMemo(() => {
    return getpageSize(pageSizeComponent?.tourTab, pagination_list);
  }, [pagination_list]);

  ///Updating the page size to the database ended******

  return (
    <GetTourContext.Provider
      value={{
        TourList,
        setTourList,
        currentPage,
        setCurrentPage,
        TourPageSize,
        // setTourPageSize,
      }}
    >
      {children}
    </GetTourContext.Provider>
  );
};

export default GetTourProvider;

// export const EventTourActiveProvider = ({ children }) => {
//   const query = useSearchQuery();
//   const [eventTourActive, setEventTourActive] = useState(
//     query.get('subtab')
//       ? query.get('subtab') === 'tours'
//         ? 1
//         : query.get('subtab') === 'events'
//         ? 0
//         : 0
//       : 0,
//   );

//   return (
//     <EventTourActiveContext.Provider
//       value={{ eventTourActive, setEventTourActive }}
//     >
//       {children}
//     </EventTourActiveContext.Provider>
//   );
// };
