import React from 'react';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { EMAIL_REG, USERNAME_REG } from '../../../utils/util';
import { useMutation } from '@tanstack/react-query';
import {
  check_email,
  check_email_while_login,
  check_phone,
  check_username,
  signup,
} from '../../../helpers/api/auth/auth';
import { history } from '../../../helpers/router_templates/history';
import { LogoGoTix, SpinnerBtn } from '../../util/CustomIcons';
import { Checkbox, Form, Input, Select, Tooltip } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  antCustomSelect,
  formClass,
  primaryBtnClassGradient,
  primaryBtnClassGradientSec,
} from '../../common/DefaultClasses';
import { TermsLink } from '../../util/TermsServiceLink';
import { authActions } from '../../../features/auth.slice';
import { useDispatch } from 'react-redux';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { MdOutlineStadium } from 'react-icons/md';
import { BsChevronDown } from 'react-icons/bs';
import NoDataFound from '../../common/NoDataFound';
import { RiListCheck2 } from 'react-icons/ri';
import { CountryCodes } from '../../../utils/CountryCodes';
import Image from '../../common/Image';
import { ConfigProvider, Typography } from 'antd';
import { useUserDeviceLocation } from '../../../pages/stream_lounge/hooks/useStreamLounge';
export default function SignUpV2({ darkTheme }) {
  // let navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const query = useSearchQuery();
  const proceed_with_username = Form.useWatch('proceed_with_username', form);
  const deviceData = useUserDeviceLocation();
  const country_list = CountryCodes;
  const phones = country_list?.map(({ code, phone, label }) => ({
    key: code,
    value: `${code}+${phone}`,
    label: (
      <span className="flex items-center gap-2 dark:text-whiteNew-0 text-zinc-800 text-[14px]">
        <Image
          className="h-4"
          src={`https://flagcdn.com/w20/${String(code).toLowerCase()}.png`}
        />
        {/* {code}  */}+{phone}
      </span>
    ),
  }));

  const mutation = useMutation(insertNote, {
    onSuccess: () => {
      history.navigate(
        `/auth/email-confiramtion?email=${form.getFieldValue('email')}`,
      );
    },
  });

  // const values = getValues();

  function insertNote() {
    const {
      email,
      username,
      password,
      phone_code,
      phone_number,
      account_name,
    } = form.getFieldsValue();
    let payload = {
      email,
      country_code: phone_number ? phone_code : undefined, //passing full country code
      contact: phone_number,
      password: password,
      username: username,
      account_name: account_name ? account_name : username,
      device_info: deviceData,
    };
    if (query.get('token')) {
      payload = {
        ...payload,
        token: query.get('token'),
      };
    }
    return signup(payload);
  }
  const onSubmit = () => {
    if (form.getFieldValue('accept_privacy')) return mutation.mutate();
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Poppins',
        },
      }}
    >
      <div className="flex justify-between w-full items-center">
        <Link to="/" className="flex justify-center w-[90px] md:w-[170px]">
          <LogoGoTix
            className={`w-full max-w-sm h-10 dark:text-whiteNew-0 text-zinc-800`}
          />
        </Link>
        <div className="flex items-center gap-2">
          <Link
            to={`${
              window.location.search
                ? new URLSearchParams(window.location.search).get('utm_source')
                : '/'
            }`}
            className={`relative justify-center items-center gap-2 dark:bg-zinc-900 bg-lightTheme-70 hover:bg-lighTheme-80 dark:hover:bg-zinc-800 border-1 dark:border-zinc-800 border-zinc-200 shadow-sm shadow-slate-600 duration-200 ease-in  py-1.5 disabled:opacity-70 cursor-pointer dark:text-whiteNew-0 text-zinc-600 flex rounded-[8px] p-1.5 px-2.5 text-xs md:text-base`}
            onClick={() => {
              dispatch(authActions.resetState());
              // navigate('/');
            }}
          >
            Explore as Guest
          </Link>
          <div className="h-full flex items-center">
            <span
              className="flex h-4 bg-gray-600"
              style={{ width: '1px' }}
            ></span>
          </div>
          <Tooltip placement={'top'} title={'Demo Stadium'}>
            <Link
              to="/demo"
              className={`relative justify-center items-center gap-2 bg-lightTheme-70 hover:bg-lightTheme-80 dark:bg-zinc-900 dark:hover:bg-zinc-800 border-1 dark:border-zinc-800 border-zinc-200 shadow-sm shadow-slate-600 duration-200 ease-in disabled:opacity-70 cursor-pointer dark:text-whiteNew-0 text-zinc-800 flex rounded-[8px] p-[9px] text-xs md:text-base`}
              onClick={() => {
                dispatch(authActions.resetState());
              }}
            >
              <MdOutlineStadium />
            </Link>
          </Tooltip>
        </div>
      </div>
      <div className="flex justify-center items-start flex-col text-left pt-6 pb-3.5">
        <h1
          className={`dark:text-whiteNew-0 text-sky-500 font-medium text-lg 2xl:text-xl `}
        >
          Welcome to NFTGOTIX!
        </h1>
        <p className="text-gray-500 text-sm 2xl:text-base">
          Please create your account.
        </p>
      </div>
      <div className="space-y-4 max-w-screen-lg w-full">
        {/* <div className="mt-2">
          <LoginOptionButton type={'Signup'} darkTheme={darkTheme} />
        </div> */}
        {/* Will be implemented later */}
        {mutation.isError ? (
          <div className="mb-4 mt-3 flex justify-center">
            <span className="text-rose-500">
              {mutation.error?.message
                ? mutation.error.message +
                  ` (${mutation.error.response?.data?.message})`
                : mutation.error}
            </span>
          </div>
        ) : null}

        <div className="mt-3.5">
          <Form
            form={form}
            onFinish={onSubmit}
            layout={'vertical'}
            initialValues={{
              phone_code: phones[0]?.value,
            }}
            disabled={mutation.isLoading}
          >
            <Form.Item
              // controlId="emailAdd"
              label={
                <span className="flex justify-start text-center items-center text-zinc-800 dark:text-whiteNew-0">
                  <span>Email Address</span>
                </span>
              }
              className={`${darkTheme ? 'dark-floating-label' : ''} mb-3`}
              name="email"
              validateDebounce={1000}
              rules={[
                {
                  required: true,
                  message: 'Please enter your email.',
                },
                {
                  pattern: EMAIL_REG,
                  message: 'Invalid email.',
                },
                ({ getFieldValue }) => ({
                  validator: async (_, value) => {
                    if (!value || !EMAIL_REG.test(value))
                      return Promise.resolve(); // Initial condition

                    // const res = await check_email_while_login(value);
                    const res = await check_email(value, 'Login');

                    if (
                      res?.block_reason ||
                      (!res?.status &&
                        ['seller', 'buyer', 'both', 'business'].includes(
                          res?.user_type,
                        ))
                    ) {
                      return Promise.reject(
                        new Error(
                          res?.block_reason
                            ? res?.message
                            : 'Email is registered already.',
                        ),
                      );
                    } // Email registered as both
                    if (
                      !proceed_with_username &&
                      !getFieldValue('username') // This is not the best way. Need to optimise
                    ) {
                      form.setFieldValue('username', value?.split('@')?.[0]);
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input
                type="email"
                placeholder="Email Address"
                className={`${darkTheme ? formClass : ''} ${formClass} py-2.5`}
                disabled={query.get('utm')}
              />
            </Form.Item>

            <Form.Item
              // controlId="emailAdd"
              label={
                <span className="flex justify-start text-center items-center text-zinc-800 dark:text-whiteNew-0">
                  <span>Account Name</span>
                </span>
              }
              className={`${darkTheme ? 'dark-floating-label' : ''} mb-3`}
              name="account_name"
            >
              <Input
                type="text"
                placeholder="Account Name"
                className={`${darkTheme ? formClass : ''} ${formClass} py-2.5`}
              />
            </Form.Item>

            <div className="mb-3">
              <Form.Item
                // controlId="userName"
                label={
                  <span
                    className={
                      form.getFieldValue('proceed_with_username')
                        ? 'text-gray-400'
                        : 'text-zinc-800 dark:text-whiteNew-0'
                    }
                  >
                    Username
                  </span>
                }
                className={`${darkTheme ? 'dark-floating-label' : ''} mb-1`}
                name="username"
                validateDebounce={1000}
                dependencies={['proceed_with_username']}
                rules={[
                  {
                    required: !proceed_with_username,
                    message: 'Please provide username.',
                  },
                  {
                    min: 5,
                    message: 'Username must be of 5-15 characters long.',
                  },
                  {
                    max: 15,
                    message: 'Username must be of 5-15 characters long.',
                  },
                  {
                    pattern: USERNAME_REG,
                    message:
                      'Username should contain letters (A-Z, a-z) and numbers (0-9).',
                  },
                  ({ getFieldValue }) => ({
                    validator: async (_, value) => {
                      if (!value || !USERNAME_REG.test(value))
                        return Promise.resolve();
                      const res = await check_username(value);
                      if (res) return Promise.resolve();
                      return Promise.reject(
                        new Error('Username is not available.'),
                      );
                    },
                  }),
                ]}
              >
                <Input
                  type="text"
                  disabled={proceed_with_username}
                  placeholder="Username"
                  className={`${
                    darkTheme ? formClass : ''
                  } ${formClass} py-2.5`}
                />
              </Form.Item>
              <Form.Item
                dependencies={['username']}
                name={'proceed_with_username'}
                valuePropName="checked"
              >
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      form.resetFields(['username']);
                    }
                  }}
                  className={`${darkTheme ? 'text-whiteNew-0' : ''}`}
                >
                  <span className="dark:text-whiteNew-0 text-zinc-800">
                    Proceed without username?
                  </span>
                </Checkbox>
              </Form.Item>
            </div>

            <div className="flex flex-wrap flex-md-nowrap gap-0 gap-md-3">
              <div className="w-full md:w-1/3 shrink-0">
                <div className="w-full">
                  <Form.Item
                    className={`${
                      darkTheme
                        ? 'dark-floating-label text-zinc-800'
                        : 'text-zinc-800'
                    } dark-floating-label [&_label]:!text-zinc-800 dark:[&_label]:!text-whiteNew-0 w-full`}
                    name="phone_code"
                    label="Country Code"
                    dependencies={['phone_no']}
                  >
                    <Select
                      placeholder={
                        <span className="text-[#818181]">Select</span>
                      }
                      // popupClassName={`${antCustomSelect} antcstm`}
                      className={` rounded-md`}
                      options={phones}
                      showSearch
                      autoClearSearchValue
                      suffixIcon={
                        <>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                            <BsChevronDown
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </>
                      }
                      notFoundContent={
                        <div className="w-full">
                          <NoDataFound
                            title={
                              <span className="text-sm text-whiteNew-0">
                                No Data Found.
                              </span>
                            }
                            minHeight={'min-h-[150px]'}
                            icon={<RiListCheck2 />}
                          />
                        </div>
                      }
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="w-full">
                <Form.Item
                  label={
                    <span className="flex justify-start text-center items-center text-zinc-800 dark:text-whiteNew-0">
                      <span>Phone Number</span>
                    </span>
                  }
                  className={`${darkTheme ? 'dark-floating-label' : ''} mb-3`}
                  name="phone_number"
                  validateDebounce={1000}
                  rules={[
                    {
                      max: 15,
                      message: 'Max. 15 characters allowed.',
                    },
                    ({ getFieldValue }) => ({
                      validator: async (_, value) => {
                        if (!value) return Promise.resolve();
                        if (
                          isValidPhoneNumber(
                            value,
                            String(getFieldValue('phone_code')).split('+')[0],
                          )
                        ) {
                          const res = await check_phone(
                            value,
                            getFieldValue('phone_code'),
                          );
                          if (!res) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              'Phone number is already associated with other account.',
                            ),
                          );
                        }
                        return Promise.reject(
                          new Error(
                            'Invalid phone number for the selected country code.',
                          ),
                        );
                      },
                    }),
                  ]}
                  dependencies={['phone_code']}
                >
                  <Input
                    placeholder="Phone Number"
                    className={`${
                      darkTheme ? formClass : ''
                    } ${formClass} py-2.5`}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="mb-0 relative">
              <Form.Item
                // controlId="floatingPassword"
                label={
                  <span className="flex gap-2 items-center">
                    <span className="flex justify-start text-center items-center text-zinc-800 dark:text-whiteNew-0">
                      <span>Password</span>
                    </span>

                    <Tooltip
                      title="Please make sure your password must have 8 characters."
                      //  meeting the following criteria: [(A-Z),(a-z),(0-9),(!@#$%^&*)]"
                      placement={'right'}
                      overlayStyle={{
                        maxWidth: '400px',
                      }}
                    >
                      <span className="pe-auto">
                        <AiOutlineInfoCircle className="text-zinc-800 dark:text-whiteNew-0" />
                      </span>
                    </Tooltip>
                  </span>
                }
                className={`${darkTheme ? 'dark-floating-label' : ''} w-full`}
                name="password"
                dependencies={['confirm_password']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your password.',
                  },
                  {
                    min: 8,
                    message:
                      'Please make sure your password must have 8 characters.',
                  },
                ]}
              >
                <Input.Password
                  // type={`${showPassword ? 'text' : 'password'}`}
                  placeholder="Password"
                  className={`${
                    darkTheme ? formClass : ''
                  } ${formClass} py-2.5`}
                  iconRender={(visible) =>
                    visible ? (
                      <span>
                        <FaRegEyeSlash className="cursor-pointer dark:text-whiteNew-0 text-zinc-800" />
                      </span>
                    ) : (
                      <span>
                        <FaRegEye className="cursor-pointer dark:text-whiteNew-0 text-zinc-800" />
                      </span>
                    )
                  }
                />
              </Form.Item>
              {/* <span
                className={`${
                  darkTheme ? 'text-whiteNew-0' : ''
                } absolute bottom-[14px] right-3`}
                type="button"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </span> */}
            </div>

            <div className="relative mb-0">
              <Form.Item
                // controlId="floatingConfPassword"
                label={
                  <span className="flex justify-start text-center items-center text-zinc-800 dark:text-whiteNew-0">
                    <span>Confirm Password</span>
                  </span>
                }
                className={`${
                  darkTheme ? 'dark-floating-label' : ''
                } w-full mb-0`}
                name="confirm_password"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password.',
                  },
                  ({ getFieldValue }) => ({
                    validator: (_, value) => {
                      if (getFieldValue('password') === value || !value)
                        return Promise.resolve();
                      return Promise.reject(
                        new Error('Your password does not match'),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  // type={`${showConfirmPassword ? 'text' : 'password'}`}
                  placeholder="Confirm Password"
                  className={`${
                    darkTheme ? formClass : ''
                  } ${formClass} py-2.5`}
                  iconRender={(visible) =>
                    visible ? (
                      <span>
                        <FaRegEyeSlash className="cursor-pointer dark:text-whiteNew-0 text-zinc-800" />
                      </span>
                    ) : (
                      <span>
                        <FaRegEye className="cursor-pointer dark:text-whiteNew-0 text-zinc-800" />
                      </span>
                    )
                  }
                />
              </Form.Item>
              {/* <span
                className={`${
                  darkTheme ? 'text-whiteNew-0' : ''
                } absolute bottom-[14px] right-3`}
                type="button"
                onClick={() => {
                  setShowConfirmPassword(!showConfirmPassword);
                }}
              >
                {showConfirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </span> */}
            </div>
            <div className="mb-3.5">
              <Form.Item
                className={`${
                  darkTheme ? 'text-whiteNew-0' : ''
                } mb-0 antdCheckBox`}
                // controlId="formBasicCheckbox"
                name="accept_privacy"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'Please accept the privacy policy and read the T&C.',
                        ),
                      );
                    },
                  },
                ]}
              >
                <Checkbox className="mb-0 items-center min-h-[5px]">
                  <span
                    className={`dark:text-whiteNew-0 text-zinc-800 mb-0 font-normal leading-0`}
                  >
                    I have read and agree to NFTGOTIX's{' '}
                    <TermsLink className={'text-sky-600'} />
                  </span>
                </Checkbox>
              </Form.Item>
            </div>

            <div className="flex justify-center items-center mt-4">
              <button
                type="submit"
                disabled={mutation.isLoading}
                className={`${
                  darkTheme
                    ? `${primaryBtnClassGradientSec}`
                    : 'bg-sky-600 text-gray-50 hover:bg-sky-700'
                } p-2.5 ${
                  mutation.isLoading
                    ? 'rounded-full w-auto'
                    : 'w-full rounded-[8px]'
                } duration-200 ease-in`}
              >
                {mutation.isLoading ? (
                  <SpinnerBtn className={'text-xl'} />
                ) : (
                  'Register'
                )}
              </button>
            </div>
          </Form>
        </div>

        <div className="relative mt-3.5 flex justify-center">
          <div className=" text-center after:absolute after:content-[''] dark:after:bg-zinc-600 after:bg-zinc-200 after:top-1/2 after:left-0 after:h-[1px] after:w-full after:flex after:z-0">
            <span className="dark:bg-zinc-900 bg-whiteNew-0 dark:text-whiteNew-0 text-zinc-800 relative z-10 flex px-1.5">
              Or
            </span>
          </div>
        </div>
        <div className="row justify-content-center mt-2">
          <div className="col-12 text-center">
            <div className="flex justify-center items-center gap-2">
              <span
                className={`dark:text-whiteNew-0 text-zinc-800 mb-0 font-normal`}
              >
                Already registered?
              </span>
              <Link
                to={'/user/login'}
                type="button"
                className="dark:text-landingPurple-60 text-sky-600 hover:text-sky-700 dark:hover:text-landingPurple-70 hover:underline text-sm"
              >
                Log In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
}
