import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Card } from 'react-bootstrap';
// import './FlipCounter.scss';

const FlipCountdown = (props) => {
  const [completed, setCompleted] = useState(false);
  const clock = {
    year: {
      title: 'Years',
      value: useState(0),
      prevValue: useState(0),
      ref: useRef(null),
      key: 0,
    },
    month: {
      title: 'Months',
      value: useState(0),
      prevValue: useState(0),
      ref: useRef(null),
      key: 1,
    },
    day: {
      title: 'Days',
      value: useState(0),
      prevValue: useState(0),
      ref: useRef(null),
      key: 2,
    },
    hour: {
      title: 'Hours',
      value: useState(0),
      prevValue: useState(0),
      ref: useRef(null),
      key: 3,
    },
    minute: {
      title: 'Minutes',
      value: useState(0),
      prevValue: useState(0),
      ref: useRef(null),
      key: 4,
    },
    second: {
      title: 'Seconds',
      value: useState(0),
      prevValue: useState(0),
      ref: useRef(null),
      key: 5,
    },
  };
  const {
    theme = 'dark',
    size = 'medium',
    endAt = moment(),
    className,
    hideYear,
    hideMonth,
    hideDay,
    hideHour,
    hideMinute,
    hideSecond,
    titlePosition = 'top',
    onTimeUp = () => null,
    message = null,
    cardColor,
    cardColorBody,
    // endAtZero,
  } = props;
  let interval = useRef(null);
  let prev = moment.duration(moment().diff(moment()));

  useEffect(() => {
    // if (interval.current !== null) {

    //   interval.current = null;
    //   setCompleted(true);
    //   clearInterval(interval.current);
    // }
    // setTimeout(() => {
    setCompleted(false);
    processClock(endAt);
    interval.current = setInterval(() => {
      processClock(endAt);
    }, 1000);
    // }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval.current);
        // interval.current = null;
      }
    };
  }, [endAt]);

  const processClock = (data) => {
    const then = moment(data);
    const value = moment.duration(then.diff(moment()));

    if (value.milliseconds() < 0) {
      setCompleted(true);
      clearInterval(interval);
      onTimeUp();
      return;
    }

    // Year
    if (!hideYear) {
      clock.year.value[1](value.years());
      clock.year.prevValue[1](prev.years());
      if (
        parseInt(value.years() / 10) !== parseInt(prev.years() / 10) &&
        clock.year.ref.current
      ) {
        const section = clock.year.ref.current.querySelector(
          '.flip-countdown-card-sec.one',
        );
        section.classList.remove('flip');
        void section.offsetWidth;
        section.classList.add('flip');
      }

      if (
        parseInt(value.years() % 10) !== parseInt(prev.years() % 10) &&
        clock.year.ref.current
      ) {
        const section = clock.year.ref.current.querySelector(
          '.flip-countdown-card-sec.two',
        );
        section.classList.remove('flip');
        void section.offsetWidth;
        section.classList.add('flip');
      }
    }

    // Months
    if (!hideMonth) {
      clock.month.value[1](value.months());
      clock.month.prevValue[1](prev.months());
      if (
        parseInt(value.months() / 10) !== parseInt(prev.months() / 10) &&
        clock.month.ref.current
      ) {
        const section = clock.month.ref.current.querySelector(
          '.flip-countdown-card-sec.one',
        );
        section.classList.remove('flip');
        void section.offsetWidth;
        section.classList.add('flip');
      }

      if (
        parseInt(value.months() % 10) !== parseInt(prev.months() % 10) &&
        clock.month.ref.current
      ) {
        const section = clock.month.ref.current.querySelector(
          '.flip-countdown-card-sec.two',
        );
        section.classList.remove('flip');
        void section.offsetWidth;
        section.classList.add('flip');
      }
    }

    // Days
    if (!hideDay) {
      clock.day.value[1](value.days());
      clock.day.prevValue[1](prev.days());
      if (
        parseInt(value.days() / 10) !== parseInt(prev.days() / 10) &&
        clock.day.ref.current
      ) {
        const section = clock.day.ref.current.querySelector(
          '.flip-countdown-card-sec.one',
        );
        section.classList.remove('flip');
        void section.offsetWidth;
        section.classList.add('flip');
      }

      if (
        parseInt(value.days() % 10) !== parseInt(prev.days() % 10) &&
        clock.day.ref.current
      ) {
        const section = clock.day.ref.current.querySelector(
          '.flip-countdown-card-sec.two',
        );
        section.classList.remove('flip');
        void section.offsetWidth;
        section.classList.add('flip');
      }
    }

    // Hours
    if (!hideHour) {
      clock.hour.value[1](value.hours());
      clock.hour.prevValue[1](prev.hours());
      if (
        parseInt(value.hours() / 10) !== parseInt(prev.hours() / 10) &&
        clock.hour.ref.current
      ) {
        const section = clock.hour.ref.current.querySelector(
          '.flip-countdown-card-sec.one',
        );
        section.classList.remove('flip');
        void section.offsetWidth;
        section.classList.add('flip');
      }

      if (
        parseInt(value.hours() % 10) !== parseInt(prev.hours() % 10) &&
        clock.hour.ref.current
      ) {
        const section = clock.hour.ref.current.querySelector(
          '.flip-countdown-card-sec.two',
        );
        section.classList.remove('flip');
        void section.offsetWidth;
        section.classList.add('flip');
      }
    }

    // Minutes
    if (!hideMinute) {
      clock.minute.value[1](value.minutes());
      clock.minute.prevValue[1](prev.minutes());
      if (
        parseInt(value.minutes() / 10) !== parseInt(prev.minutes() / 10) &&
        clock.minute.ref.current
      ) {
        const section = clock.minute.ref.current.querySelector(
          '.flip-countdown-card-sec.one',
        );
        section.classList.remove('flip');
        void section.offsetWidth;
        section.classList.add('flip');
      }

      if (
        parseInt(value.minutes() % 10) !== parseInt(prev.minutes() % 10) &&
        clock.minute.ref.current
      ) {
        const section = clock.minute.ref.current.querySelector(
          '.flip-countdown-card-sec.two',
        );
        section.classList.remove('flip');
        void section.offsetWidth;
        section.classList.add('flip');
      }
    }

    // Seconds
    if (!hideSecond) {
      clock.second.value[1](value.seconds());
      clock.second.prevValue[1](prev.seconds());
      if (
        parseInt(value.seconds() / 10) !== parseInt(prev.seconds() / 10) &&
        clock.second.ref.current
      ) {
        const section = clock.second.ref.current.querySelector(
          '.flip-countdown-card-sec.one',
        );
        section.classList.remove('flip');
        void section.offsetWidth;
        section.classList.add('flip');
      }

      if (
        parseInt(value.seconds() % 10) !== parseInt(prev.seconds() % 10) &&
        clock.second.ref.current
      ) {
        const section = clock.second.ref.current.querySelector(
          '.flip-countdown-card-sec.two',
        );
        section.classList.remove('flip');
        void section.offsetWidth;
        section.classList.add('flip');
      }
    }

    prev = value;
  };

  const getPiece = (key) => {
    const data = clock[key];
    const [value] = data.value;
    // const [prevValue] = data.prevValue;
    const part1 = parseInt(value / 10);
    const part2 = parseInt(value % 10);
    if (part1 === 0 && part2 === 0 && data.key < 3) {
      return null;
    }
    // const prev1 = parseInt(prevValue / 10);
    // const prev2 = parseInt(prevValue % 10);

    return props.newStyle ? (
      <>
        <div className="d-flex align-items-center lastChild">
          <span
            className="flip-countdown-piece text-start d-flex align-items-end"
            ref={data.ref}
            style={{ fontSize: '1em' }}
          >
            {'top' === titlePosition && (
              <span className="flip-countdown-title">
                {props[`${key}Title`] || data.title}
              </span>
            )}
            <span
              className="flip-countdown-card fw-300"
              style={{ letterSpacing: '-5px' }}
            >
              <span className="flip-countdown-card-sec one">
                <span className="card__top">{part1}</span>
                {/* <span className="card__bottom" data-value={prev1} /> */}
                {/* <span className="card__back" data-value={prev1}>
           <span className="card__bottom" data-value={part1} />
         </span> */}
              </span>
              <span className="flip-countdown-card-sec two">
                <span className="card__top">{part2}</span>
                {/* <span className="card__bottom" data-value={prev2} /> */}
                {/* <span className="card__back" data-value={prev2}>
           <span className="card__bottom" data-value={part2} />
         </span> */}
              </span>
            </span>
            {'bottom' === titlePosition && (
              <span
                className="flip-countdown-title fw-300"
                style={{ fontSize: '70%', marginLeft: '2px' }}
              >
                {props[`${key}Title`] || data.title}
              </span>
            )}
          </span>
          <span className="fsize-20 ms-2 lastChildInner">:</span>
        </div>
      </>
    ) : (
      <>
        {/* <Card
          className={`${
            cardColor ? cardColor : 'bg-sky-600'
          } border-0 p-2 rounded-none`}
          style={{ minWidth: '2rem' }}
        >
          <Card.Body className="p-0 py-0">
            <span className="flip-countdown-piece text-center" ref={data.ref}>
              {'top' === titlePosition && (
                <span className="flip-countdown-title">
                  {props[`${key}Title`] || data.title}
                </span>
              )}
              <span className="flip-countdown-card fw-300 justify-center">
                <span className="flip-countdown-card-sec one">
                  <span className="card__top">{part1}</span>
                </span>
                <span className="flip-countdown-card-sec two">
                  <span className="card__top">{part2}</span>
                </span>
              </span>
              {'bottom' === titlePosition && (
                <span className="flip-countdown-title fw-300 flex text-white mt-2 fsize-14">
                  {props[`${key}Title`] || data.title}
                </span>
              )}
            </span>
          </Card.Body>
        </Card> */}
        {cardColor ? (
          <>
            <Card
              className={`${
                cardColor
                  ? cardColor
                  : 'text-center border-0 bg-transparent rounded-none'
              }`}
              style={{ minWidth: '2rem' }}
            >
              <Card.Body
                className={`${
                  cardColorBody
                    ? cardColorBody
                    : 'p-2 bg-transparent border-purple-300 '
                }`}
                style={{
                  minHeight: '42px',
                }}
              >
                <div className="w-ful flex justify-center dark:text-whiteNew-0 text-zinc-800 text-lg">
                  <span className="flip-countdown-card  justify-center">
                    <span className="flip-countdown-card-sec one">
                      <span className="card__top">{part1}</span>
                      {/* <span className="card__bottom" data-value={prev1} /> */}
                      {/* <span className="card__back" data-value={prev1}>
              <span className="card__bottom" data-value={part1} />
            </span> */}
                    </span>
                    <span className="flip-countdown-card-sec two">
                      <span className="card__top">{part2}</span>
                      {/* <span className="card__bottom" data-value={prev2} /> */}
                      {/* <span className="card__back" data-value={prev2}>
              <span className="card__bottom" data-value={part2} />
            </span> */}
                    </span>
                  </span>
                </div>
                <div className="flex w-full justify-center">
                  <span className="text-[11px] font-normal">
                    {'bottom' === titlePosition && (
                      <span className="flip-countdown-title flex dark:text-whiteNew-0 text-zinc-800">
                        {props[`${key}Title`] || data.title}
                      </span>
                    )}
                  </span>
                </div>
              </Card.Body>
            </Card>
          </>
        ) : (
          <>
            <Card
              className={`${
                cardColor
                  ? cardColor
                  : 'text-center border-0 bg-transparent rounded-none'
              }`}
              style={{ minWidth: '2rem' }}
            >
              <Card.Header className="dark:bg-zinc-950 bg-zinc-500 border-[1px] dark:border-zinc-600 border-zinc-500 py-1 rounded-0">
                <div className="flex w-full justify-center">
                  <span className="text-gray-50 text-[10px] md:text-xs font-medium">
                    {'bottom' === titlePosition && (
                      <span className="flip-countdown-title fw-300 flex dark:text-whiteNew-0">
                        {props[`${key}Title`] || data.title}
                      </span>
                    )}
                  </span>
                </div>
              </Card.Header>
              <Card.Body
                className={`${
                  cardColorBody
                    ? cardColorBody
                    : 'p-1 bg-transparent dark:border-zinc-500 border-zinc-400 border-[1px] border-t-0'
                }`}
              >
                <div className="w-ful flex justify-center dark:text-whiteNew-0 text-zinc-800">
                  <span className="flip-countdown-card fw-300 justify-center">
                    <span className="flip-countdown-card-sec one">
                      <span className="card__top text-[15px] font-bold">
                        {part1}
                      </span>
                      {/* <span className="card__bottom" data-value={prev1} /> */}
                      {/* <span className="card__back" data-value={prev1}>
              <span className="card__bottom" data-value={part1} />
            </span> */}
                    </span>
                    <span className="flip-countdown-card-sec two">
                      <span className="card__top text-[15px] font-bold">
                        {part2}
                      </span>
                      {/* <span className="card__bottom" data-value={prev2} /> */}
                      {/* <span className="card__back" data-value={prev2}>
              <span className="card__bottom" data-value={part2} />
            </span> */}
                    </span>
                  </span>
                </div>
              </Card.Body>
            </Card>
          </>
        )}
      </>
    );
  };

  // if (completed && !endAtZero) {
  //     return <div className='flip-countdown'>{props.children || endAt}</div>;
  // }

  if (completed) {
    return props.newStyle ? (
      <div className="flip-countdown">
        <p className="text-white mb-0 fw-400">{message ? message : ''}</p>
      </div>
    ) : (
      <div className="flip-countdown">
        <h5 className="text-white mb-0">{message ? message : ''}</h5>
      </div>
    );
  }

  return (
    !hideYear &&
    !hideMonth &&
    !hideDay &&
    !hideHour &&
    !hideMinute &&
    !hideSecond && (
      <div
        className={`flip-countdown flex flex-wrap ${className} theme-${theme} size-${size}`}
      >
        {!hideYear && getPiece('year')}
        {!hideMonth && getPiece('month')}
        {!hideDay && getPiece('day')}
        {!hideHour && getPiece('hour')}
        {!hideMinute && getPiece('minute')}
        {!hideSecond && getPiece('second')}
      </div>
    )
  );
};

export default FlipCountdown;
