import React from 'react';
import { MdArrowForwardIos } from 'react-icons/md';
import { usePaymentOptions } from './hooks/usePaymentOptions';
import useDecodedToken from '../../hooks/useDecodedToken';
import { FaCheckCircle } from 'react-icons/fa';

function CheckoutPaymentOption() {
  const { disclosure, handleOpenDisclosure } = usePaymentOptions();
  const decodedToken = useDecodedToken();

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-col">
        <h2 className="text-color-secondary text-lg md:text-2xl font-medium">
          Payment Options
        </h2>
      </div>

      <div className="w-full payment-option">
        {disclosure.map(({ id, panel, isOpen, buttonText }) => {
          return (
            <div
              id={id}
              className="mt-2 md:mt-3 border-1 border-level-2 rounded-lg overflow-hidden"
            >
              <div
                onClick={() => handleOpenDisclosure(id)}
                className={`flex w-full justify-between dark:text-white text-zinc-800 p-2.5 md:p-3 text-sm md:text-base bg-color-base-2 dark:bg-color-base-2 items-center cursor-pointer ${
                  isOpen ? 'rounded-b-none' : ''
                }`}
              >
                <span
                  className={
                    decodedToken && buttonText === 'Wallet'
                      ? 'text-green-600'
                      : ''
                  }
                >
                  {buttonText}
                </span>
                <div className="flex gap-2 items-center justify-end">
                  {decodedToken && buttonText === 'Wallet' && (
                    <FaCheckCircle className="text-green-600 text-base" />
                  )}
                  <MdArrowForwardIos
                    className={`${
                      isOpen ? 'rotate-90 transform' : ''
                    } h-4 w-4 text-[#999] ${
                      decodedToken && buttonText === 'Wallet'
                        ? 'text-green-600'
                        : ''
                    }`}
                  />
                </div>
              </div>
              {isOpen ? (
                <div className="transition duration-1000 ease-out">{panel}</div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CheckoutPaymentOption;
