// Import the functions you need from the SDKs you need
import { firebase, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getMessaging, getToken } from 'firebase/messaging';
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB3Iy4WfO5ED7suE1NF_FCM2thHUm-J0EI',
  authDomain: 'nftgotix-a22d1.firebaseapp.com',
  projectId: 'nftgotix-a22d1',
  storageBucket: 'nftgotix-a22d1.appspot.com',
  messagingSenderId: '1089150485950',
  appId: '1:1089150485950:web:2099866b9b0b1503b765ef',
  measurementId: 'G-6PCS5L369S',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// let primaryApp = firebase.initializeApp(firebaseConfig, 'project1');
// const secondaryApp = firebase.initializeApp(PushNotifyConfig, 'secondary');

// // const app = initializeApp(PushNotifyConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  console.log('permission', permission);

  if (permission === 'granted') {
    const token = await getToken(messaging, {
      vapidKey:
        'BE6InZgRTAtT4y1IEKCPL8_02WVQ8a884LJJROwop2erVdNCXh1oMP4HLkdpmCFw01qqz1giVTvkZHy6k83eaPw',
    });
    console.log('token', token);
  }
};

export const auth = getAuth();
// const analytics = getAnalytics(app);
