import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loader: JSON.parse(localStorage.getItem('loaderState')),
  percentage: 0,
};

const slice = createSlice({
  name: 'dashboardLoader',
  initialState,
  reducers: {
    updateLoader: (state, { payload }) => {
      state.loader = payload;
    },
    updatepercentage: (state, { payload }) => {
      state.percentage = payload;
    },
  },
});

let dashBoardLoaderReducer = slice.reducer;

export const { updateLoader, updatepercentage } = slice.actions;
const getDashboardLoader = (state) => state.dashboardLoader;

export { dashBoardLoaderReducer, getDashboardLoader };
