import { useCallback, useMemo, useState } from 'react';
import { FiatSubmenu, WalletSubmenus } from '../CheckoutPaymentOptionsSubmenu';
import useDecodedToken from '../../../hooks/useDecodedToken';
import { useUserDetailsSWR } from '../../../hooks/user/useUserDetails';
import { useMutation } from '@tanstack/react-query';
import {
  getUserDetails,
  get_addresses,
} from '../../../helpers/api/user/user_details';
import useSWR from 'swr';
import { useDispatch } from 'react-redux';
import {
  AddUserDetail,
  updateUserAddressList,
} from '../../../features/user_detail.slice';

export function usePaymentOptions() {
  useUserDetailsSWR();
  const [disclosure, setDisclosure] = useState([
    {
      id: 'disclosure-panel-1',
      buttonText: 'Wallet',
      isOpen: false,
      panel: (
        <>
          <WalletSubmenus />
        </>
      ),
    },
    {
      id: 'disclosure-panel-2',
      buttonText: 'Credit / Debit card',
      isOpen: false,
      panel: (
        <>
          <FiatSubmenu />
        </>
      ),
    },
    // {
    //   id: 'disclosure-panel-3',
    //   buttonText: 'Online Banking',
    //   isOpen: false,
    //   panel: (
    //     <>
    //       <BankingSubmenu />
    //     </>
    //   ),
    // },
  ]);

  const handleOpenDisclosure = useCallback((id) => {
    setDisclosure((prevState) =>
      prevState.map((d) =>
        d.id === id ? { ...d, isOpen: !d.isOpen } : { ...d, isOpen: false },
      ),
    );
  }, []);

  return useMemo(
    () => ({ disclosure, handleOpenDisclosure }),
    [disclosure, handleOpenDisclosure],
  );
}

export function useModalActions() {
  const dispatch = useDispatch();
  const [modalState, setModalState] = useState({
    billing_modal: false,
    add_new_card: false,
    add_money: false,
    add_bank: false,
  });
  const decodedToken = useDecodedToken();
  // // const { current_user_details } = useUserDetailsSWR(decodedToken?.username)
  const user_address = useMutation(get_addresses, {
    onSuccess: (data) => {
      dispatch(updateUserAddressList(data?.data));
    },
  });

  const user_details = useMutation(
    () => getUserDetails(decodedToken?.username),
    {
      onSuccess: (data) => {
        dispatch(AddUserDetail({ data }));
      },
    },
  );

  const user_address_swr = useSWR(['user_address'], get_addresses, {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
    onSuccess: (data) => {
      dispatch(updateUserAddressList(data?.data));
    },
  });

  const handleOpenBillingModal = useCallback(() => {
    return setModalState({ ...modalState, billing_modal: true });
  }, [modalState]);
  const handleCloseBillingModal = useCallback(() => {
    return setModalState({ ...modalState, billing_modal: false });
  }, [modalState]);

  const handleOpenCardModal = useCallback(() => {
    return setModalState({ ...modalState, add_new_card: true });
  }, [modalState]);
  const handleCloseCardModal = useCallback(() => {
    return setModalState({ ...modalState, add_new_card: false });
  }, [modalState]);

  const handleOpenMoneyModal = useCallback(() => {
    return setModalState({ ...modalState, add_money: true });
  }, [modalState]);
  const handleCloseMoneyModal = useCallback(() => {
    return setModalState({ ...modalState, add_money: false });
  }, [modalState]);

  const handleOpenBankModal = useCallback(() => {
    return setModalState({ ...modalState, add_bank: true });
  }, [modalState]);
  const handleCloseBankModal = useCallback(() => {
    return setModalState({ ...modalState, add_bank: false });
  }, [modalState]);

  return {
    modalState,
    handleOpenBillingModal,
    handleCloseBillingModal,
    handleOpenCardModal,
    handleCloseCardModal,
    handleOpenMoneyModal,
    handleCloseMoneyModal,
    handleOpenBankModal,
    handleCloseBankModal,
    user_details: user_address_swr,
    refetch: user_address,
    refetch_user_data: user_details,
  };
}
