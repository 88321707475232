import { createSlice } from '@reduxjs/toolkit';
import { portalTexts } from '../../utils/LoaderMessage';

const initialState = {
  all_nfts: { data: [], totalCount: 0 },
  single_nft_logs: { data: [], totalCount: 0 },
  nft_filters: {
    dropdown_filters: ['All', 'Redeemed', 'Expired'],
    tab_filters: ['All', 'Transferred', 'Owned'],
    nft_sorters: ['Sort by Event', `Sort by ${portalTexts?.nTkt}`],
  },
  nft_count: {
    totalCount: 0,
    all_nft_count: 0,
    // ownedCount: 0,
    // transferredCount: 0,
    redeemedCount: 0,
    expiredCount: 0,
  },
  selected_filters: {
    selected_dropdown_filter: 'all',
    selected_tab_filter: 'all',
    selected_sort_filter: 'event',
  },
  index: 1, // for pagination
  isFetching: false,
  isOpen: false,
};

const singleEventNFTlist = {
  data: [],
  count: 0,
};

const checkNFT = (status) => {
  if (JSON.parse(localStorage.getItem('showNFT')) !== undefined) {
    return status
      ? JSON.parse(localStorage.getItem('showNFT'))?.status
      : JSON.parse(localStorage.getItem('showNFT'))?.NFTID;
  } else {
    return status ? false : '';
  }
};
const showSingleNFT = { status: checkNFT(true), NFTID: checkNFT(false) };

const slice = createSlice({
  name: 'all_nfts',
  initialState,
  reducers: {
    updateAllNFTs: (state, { payload }) => {
      state.all_nfts = payload;
    },
    updatesingleNFTLogs: (state, { payload }) => {
      state.single_nft_logs = payload;
    },
    updateNFTCount: (state, { payload }) => {
      state.nft_count = payload;
    },
    updateSelectedFilters: (state, { payload }) => {
      state.selected_filters = payload;
    },
    updatePaginationIndex: (state, { payload }) => {
      state.index = payload;
    },
    updateIsFetchingIndex: (state, { payload }) => {
      state.isFetching = payload;
    },
    setIsOpen: (state, { payload }) => {
      state.isOpen = payload;
    },
  },
});
const showSingleEventNFTSlice = createSlice({
  name: 'singleEventNFT',
  initialState: singleEventNFTlist,
  reducers: {
    updateSingleEventNFT: (state, { payload }) => {
      state.data = payload.data;
      state.count = payload.count;
    },
    resetNFT: (state) => {
      state.data = [];
      state.count = 0;
    },
  },
});

const showSingleNFTslice = createSlice({
  name: 'showSingleNFT',
  initialState: showSingleNFT,
  reducers: {
    viewSingleNFT: (state, { payload }) => {
      state.status = payload?.status;
      state.NFTID = payload?.NFTID;
    },
  },
});

let nftReducer = slice.reducer;
let SinglenftReducer = showSingleNFTslice.reducer;
let SingleEventnftReducer = showSingleEventNFTSlice.reducer;

export const {
  updateAllNFTs,
  updatesingleNFTLogs,
  updateNFTCount,
  updateSelectedFilters,
  updatePaginationIndex,
  updateIsFetchingIndex,
  setIsOpen,
} = slice.actions;
export const { viewSingleNFT } = showSingleNFTslice.actions;
export const { updateSingleEventNFT, resetNFT } =
  showSingleEventNFTSlice.actions;

const getAllNFts = (state) => state.all_nfts;
const getAllSingleEventNFts = (state) => state.all_single_event_nfts;

export {
  nftReducer,
  getAllNFts,
  SinglenftReducer,
  SingleEventnftReducer,
  getAllSingleEventNFts,
};
