import React, { useState } from 'react';
import { isExpired } from 'react-jwt';
import { email_verification } from '../../../helpers/api/auth/auth';
import { useOnceCall } from '../../../hooks/useOnlyOnce';
import useSearchQuery from '../../../hooks/useSearchQuery';
import VerifyStatus from './VerifyStatus';
// import { create_wallet } from '../../../helpers/api/wallet/wallet';
// import mydecodedTokenFunction from '../../../utils/DecodedToken';

const VerificationLinks = ({ darkTheme }) => {
  const query = useSearchQuery();
  const [response, setResponse] = useState();
  useOnceCall(async () => {
    try {
      const res = await email_verification(query.get('token'));
      setResponse(res);
      // if (res?.status) {
      //   await create_wallet(mydecodedTokenFunction(query.get('token'))?.id);
      // }
    } catch (err) {}
  });

  //1. 10 min not completed email verify
  //2. 10 min not complete 2 nd time click account verified (already verified)
  //3.10 min not complete and latest mail
  // 10 min complete , verification complete ,same link,=>  already verified
  //10 min complete , verification not complete ,same link,=>  link expired
  if (isExpired(query.get('token'))) {
    return <VerifyStatus type="expired" darkTheme={darkTheme} />;
  }

  if (response?.status) {
    return <VerifyStatus type="success" darkTheme={darkTheme} />;
  }

  if (response?.status === false) {
    return <VerifyStatus type="failure" darkTheme={darkTheme} />;
  }
};

export default VerificationLinks;
