import React from 'react';
import LeftSideBar from '../../common/LeftSideBar';
import { useNavigate } from 'react-router';

export default function MyAccountSidebar({
  activeSection,
  setActiveSection,
  activeSubSection,
  setActiveSubSection,
}) {
  const navigate = useNavigate();
  const handleNavigation = (href) => {
    navigate(href);
  };
  return (
    <React.Fragment>
      <LeftSideBar
        title={
          <>
            <span className="flex items-center gap-2">
              {/* <BsCreditCard2Front className="w-5 h-5" /> */}
              <span>Wallet</span>
            </span>
          </>
        }
        menu={[
          {
            name: 'USD Wallet',
            href: '#',
            // icon: (
            //   <>
            //     <BsInfoCircle />
            //   </>
            // ),
            active:
              activeSection === 'gotix_wallet' &&
              activeSubSection === 'usd_wallet'
                ? true
                : false,
            onClick: (e) => {
              // handleNavigation('#');
              e.stopPropagation();
              setActiveSubSection('usd_wallet');
            },
          },
          {
            name: 'Crypto Wallet',
            href: '#',
            // icon: (
            //   <>
            //     <MdOutlineEditLocation />
            //   </>
            // ),
            active:
              activeSection === 'gotix_wallet' &&
              activeSubSection === 'crpto_wallet'
                ? true
                : false,
            onClick: (e) => {
              // handleNavigation('#');
              e.stopPropagation();
              setActiveSubSection('crpto_wallet');
            },
          },
        ]}
        secondarySidebar={true}
        openAccordActive={activeSection === 'gotix_wallet' ? true : false}
        accordOnClick={() => {
          setActiveSection('gotix_wallet');
          setActiveSubSection('usd_wallet');
        }}
      />
      <LeftSideBar
        title={
          <>
            <span className="flex items-center gap-2">
              {/* <BsCreditCard2Front className="w-5 h-5" /> */}
              <span>Credit Card</span>
            </span>
          </>
        }
        secondarySidebar={true}
        openAccordActive={activeSection === 'credit_card' ? true : false}
        accordOnClick={() => {
          setActiveSection('credit_card');
        }}
      />
      <LeftSideBar
        title={
          <>
            <span className="flex items-center gap-2">
              {/* <BsCreditCard2Front className="w-5 h-5" /> */}
              <span>Bank Info</span>
            </span>
          </>
        }
        secondarySidebar={true}
        openAccordActive={activeSection === 'bank_info' ? true : false}
        accordOnClick={() => {
          setActiveSection('bank_info');
        }}
      />
      <LeftSideBar
        title={
          <>
            <span className="flex items-center gap-2">
              {/* <BsCreditCard2Front className="w-5 h-5" /> */}
              <span>Address</span>
            </span>
          </>
        }
        secondarySidebar={true}
        openAccordActive={activeSection === 'address_info' ? true : false}
        accordOnClick={() => {
          setActiveSection('address_info');
        }}
      />
    </React.Fragment>
  );
}
