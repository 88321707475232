import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { isExpired } from 'react-jwt';
import { reset_password } from '../../../helpers/api/auth/auth';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { PASSWORD_REG } from '../../../utils/util';
import { SpinnerBtn } from '../../util/CustomIcons';
import VerifyStatus from '../util/VerifyStatus';
import Tooltip from '../../common/Tooltip';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import {
  formClass,
  primaryBtnClassGradient,
} from '../../common/DefaultClasses';

export default function SetNewPassword({ darkTheme }) {
  const query = useSearchQuery();
  const navigate = useNavigate();
  const {
    register,
    getValues,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    reset,
  } = useForm({
    shouldUnregister: true,
    shouldFocusError: true,
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const mutation = useMutation(insertNote, {
    onSuccess: () => {
      if (query.get('ref') === 'login') {
        return navigate(`/auth/email-confiramtion?email=${query.get('email')}`);
      }
      reset();
      navigate('/user/login');
    },
  });

  function insertNote() {
    const value = getValues('password');
    return reset_password(
      {
        password: value,
      },
      query.get('token'),
    );
  }

  const onSubmit = (data) => {
    if (isValid) {
      mutation.mutate();
    }
  };
  return (
    <React.Fragment>
      <div className="flex justify-center items-center flex-col text-center">
        <h1 className="fw-medium text-lg 2xl:text-xl dark:text-purple-500 tex-sky-600">
          Set your password
        </h1>
        {mutation.isError && (
          <Form.Text className="block fw-bold text-center mb-2 text-danger">
            {mutation.error.message
              ? // ? mutation.error.message + ` (${mutation.error.response?.data?.message})`
                `${mutation.error.response?.data?.message}`
              : mutation.error}
          </Form.Text>
        )}
      </div>
      <div className="space-y-4 max-w-screen-lg w-full pt-3">
        <div className="mt-4">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <Form.Group
                controlId="floatingPassword1"
                label={
                  <span className="flex gap-2 items-center">
                    Password
                    <Tooltip
                      title="Please make sure your password is 8 characters long meeting the following criteria: [(A-Z),(a-z),(0-9),(!@#$%^&*)]"
                      placement={'right'}
                      overlayStyle={{
                        maxWidth: '400px',
                      }}
                    >
                      <span className="pe-auto">
                        <AiOutlineInfoCircle />
                      </span>
                    </Tooltip>
                  </span>
                }
                className={`${
                  darkTheme ? 'dark-floating-label' : ''
                } w-full relative`}
              >
                <Form.Control
                  type={`${showPassword ? 'text' : 'password'}`}
                  {...register('password', {
                    required: 'Password field is required.',
                    minLength: {
                      value: 8,
                      message:
                        'Please make sure your password is 8 characters long.',
                    },

                    // pattern: {
                    //   value: PASSWORD_REG,
                    //   message:
                    //     'Password must meet the following criteria: [(A-Z),(a-z),(0-9),(!@#$%^&*)].',
                    // },
                  })}
                  placeholder="New password"
                  className={`${
                    darkTheme ? formClass : ''
                  } ${formClass} py-2.5`}
                  // className={`${
                  //   darkTheme
                  //     ? 'bg-neutral-800 bg-opacity-50 text-white placeholder:text-gray-500 focus:bg-neutral-800 focus:bg-opacity-50 border-gray-600'
                  //     : ''
                  // } border-t-0 border-x-0 rounded-b-none shadow-transparent focus:shadow`}
                />
                <span
                  className={`dark:text-whiteNew-0 text-zinc-800 absolute top-1/2 right-3 -translate-y-1/2`}
                  type="button"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                </span>
              </Form.Group>
              {errors?.password && (
                <Form.Text className="text-danger">
                  {errors?.password?.message}
                </Form.Text>
              )}
            </div>
            <div className="mb-3">
              <Form.Group
                controlId="floatingPassword2"
                label="Confirm password"
                className={`${
                  darkTheme ? 'dark-floating-label' : ''
                } w-full relative`}
              >
                <Form.Control
                  type={`${showPasswordConfirm ? 'text' : 'password'}`}
                  {...register('confirm_password', {
                    required: 'Please confirm your Password.',

                    validate: (val) => {
                      if (watch('password') !== val) {
                        return 'Your password does not match.';
                      }
                    },
                  })}
                  placeholder="Confirm password"
                  className={`${
                    darkTheme ? formClass : ''
                  } ${formClass} py-2.5`}
                />
                <span
                  className={`dark:text-whiteNew-0 text-zinc-800 absolute top-1/2 right-3 -translate-y-1/2`}
                  type="button"
                  onClick={() => {
                    setShowPasswordConfirm(!showPasswordConfirm);
                  }}
                >
                  {showPasswordConfirm ? <FaRegEyeSlash /> : <FaRegEye />}
                </span>
              </Form.Group>
              {errors?.confirm_password && (
                <Form.Text className="text-danger">
                  {errors?.confirm_password?.message}
                </Form.Text>
              )}
            </div>
            <div className="flex justify-center items-center mt-4">
              <button
                disabled={mutation.isLoading}
                type="submit"
                className={`${
                  darkTheme
                    ? `${primaryBtnClassGradient}`
                    : 'bg-sky-600 text-gray-50 hover:bg-sky-700'
                } p-2.5 rounded-lg duration-200 ease-in w-full flex items-center justify-center md:w-2/3`}
              >
                {mutation?.isLoading ? (
                  <>
                    <SpinnerBtn className={'text-xl'} />
                  </>
                ) : (
                  'Set Password'
                )}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}
