import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cartData,
  openTimerAttemptModal,
  setOpenTimerattemptModal,
} from '../../../features/cart/cart.slice';
import useDecodedToken from '../../../hooks/useDecodedToken';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCartCheckOut } from '../../common/landing_page_component/check_out/useCartCheckOut';

// let cameOnce = 0;
const CartTimer = () => {
  const decodedToken = useDecodedToken();
  // console.log('cookie', cookies);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const IsGuestUser = localStorage.getItem('GuestUser')
    ? Boolean(JSON.parse(localStorage.getItem('GuestUser')))
    : false;
  const [cookies, setCookie] = useCookies([
    `timer${decodedToken ? decodedToken?.id : 'GuestUser'}`,
  ]);

  const openModal = useSelector(openTimerAttemptModal);

  // const closeModal = useCallback(() => {
  //   dispatch(setOpenTimerattemptModal(false));
  // }, [dispatch]);

  ///Providing the option to add extra time to the user maximum attempt is 2
  const location = useLocation();

  const { ClearCartData } = useCartCheckOut();

  //get the session timer
  const getSessionTime = useCallback(() => {
    if (IsGuestUser) {
      return sessionStorage.getItem(`timer${'GuestUser'}`)
        ? JSON.parse(sessionStorage.getItem(`timer${'GuestUser'}`))
        : '';
    } else {
      return sessionStorage.getItem(
        `timer${decodedToken && !IsGuestUser ? decodedToken?.id : 'GuestUser'}`,
      )
        ? JSON.parse(
            sessionStorage.getItem(
              `timer${decodedToken ? decodedToken?.id : 'GuestUser'}`,
            ),
          )
        : '';
    }
  }, [decodedToken, IsGuestUser]);

  const getAddedRemainingTime = useCallback(() => {
    if (IsGuestUser) {
      return sessionStorage.getItem(`addedTime${'GuestUser'}`)
        ? JSON.parse(sessionStorage.getItem(`addedTime${'GuestUser'}`))
        : '';
    } else {
      return sessionStorage.getItem(
        `addedTime${
          decodedToken && !IsGuestUser ? decodedToken?.id : 'GuestUser'
        }`,
      )
        ? JSON.parse(
            sessionStorage.getItem(
              `addedTime${decodedToken ? decodedToken?.id : 'GuestUser'}`,
            ),
          )
        : '';
    }
  }, [decodedToken, IsGuestUser]);

  const sessionTimer = getSessionTime();
  const [timer, setTimer] = useState(sessionTimer);

  let cartselectedSeats = useSelector(cartData);
  let { cartvirtualData, cartInpersonData, slug } = cartselectedSeats;

  const getRemainingTimeFromCookie = useCallback(
    (type) => {
      if (type === 'attempt') {
        let cookieValue =
          cookies?.[
            `TotalAttempt${
              decodedToken && !IsGuestUser ? decodedToken?.id : 'GuestUser'
            }`
          ];
        return cookieValue ? parseInt(cookieValue) : 0;
      }
      let cookieValue =
        cookies?.[
          `timer${
            decodedToken && !IsGuestUser ? decodedToken?.id : 'GuestUser'
          }`
        ];
      return cookieValue ? parseInt(cookieValue) : 0;
    },
    [cookies, decodedToken, IsGuestUser],
  );

  useEffect(() => {
    // Function to update the timer every second

    const checkCookies = getRemainingTimeFromCookie();
    const triedAttempt = getRemainingTimeFromCookie('attempt');
    // console.log('triedAttempt', triedAttempt);

    const updateTimer = () => {
      // Get the remaining time from the cookie
      const currentTime = Math.floor(Date.now() / 1000);
      const elapsedTime = currentTime - Number(checkCookies);
      let remainingTime =
        (Number(triedAttempt) > 0 ? Number(getAddedRemainingTime()) : 600) -
        elapsedTime;
      const minutes = Math.floor(remainingTime / 60);
      const remainingSeconds = remainingTime % 60;
      const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
      const formattedSeconds =
        remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;
      // console.log('remainingSeconds', remainingSeconds);

      setTimer(formattedMinutes + ':' + formattedSeconds);

      // Update the cookie with the new remaining time
      // setRemainingTimeInCookie(remainingTime - 1);

      // If the timer reaches 0, you can perform additional actions here
      if (remainingTime <= 0) {
        clearInterval(timerInterval);
        localStorage.removeItem('selectedSeatData');
        ClearCartData();
        // clearCart();
        // dispatch(DeleteAllSeats([]));
        // dispatch(DeleteAllCart([]));
        setTimer('');
        if (location.pathname === '/checkout') {
          navigate(`/event-detail/${slug}`);
        }
      }
    };

    if (
      checkCookies &&
      (Object.entries(cartvirtualData)?.length > 0 ||
        Object.entries(cartInpersonData)?.length > 0)
    ) {
      // Set up an interval to update the timer every second
      var timerInterval = setInterval(updateTimer, 1000);
    }

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(timerInterval);
  }, [
    cartvirtualData,
    cartInpersonData,
    dispatch,
    getRemainingTimeFromCookie,
    IsGuestUser,
    location,
    navigate,
    slug,
    cookies,
  ]);

  // useCallback added to all the onchange function in optimisations.

  // const onCancel = useCallback(() => {
  //   setCookie(
  //     `timer${decodedToken ? decodedToken?.id : 'GuestUser'}`,
  //     cookies?.[`timer${decodedToken ? decodedToken?.id : 'GuestUser'}`],
  //     {
  //       maxAge: 240,
  //     },
  //   );
  //   setCookie(
  //     `TotalAttempt${decodedToken ? decodedToken?.id : 'GuestUser'}`,
  //     Number(3),
  //     {
  //       maxAge: 240,
  //     },
  //   );
  //   dispatch(setOpenTimerattemptModal(false));
  // }, [decodedToken, setCookie, dispatch, cookies]);

  // const onSubmit = useCallback(() => {
  //   const currentTime = Math.floor(Date.now() / 1000);

  //   let totalAttempt =
  //     cookies?.[
  //       `TotalAttempt${decodedToken ? decodedToken?.id : 'GuestUser'}`
  //     ] || 0;
  //   setCookie(
  //     `TotalAttempt${decodedToken ? decodedToken?.id : 'GuestUser'}`,
  //     Number(totalAttempt || 0) + 1,
  //     {
  //       maxAge: 240,
  //     },
  //   );
  //   // console.log(remainingTime + 120, 'remainingTime + 120');
  //   setCookie(
  //     `timer${decodedToken ? decodedToken?.id : 'GuestUser'}`,
  //     currentTime,
  //     {
  //       maxAge: 240,
  //     },
  //   );
  //   sessionStorage.setItem(
  //     `timer${decodedToken ? decodedToken?.id : 'GuestUser'}`,
  //     JSON.stringify('04:00'),
  //   );
  //   dispatch(setOpenTimerattemptModal(false));
  // }, [decodedToken, cookies, setCookie, dispatch]);

  useEffect(() => {
    if (location.pathname === '/checkout') {
      const triedAttempt = Number(openModal?.triedAttempt);
      const currentTime = Math.floor(Date.now() / 1000);
      const elapsedTime =
        currentTime -
        Number(
          cookies?.[
            `timer${
              decodedToken && !IsGuestUser ? decodedToken?.id : 'GuestUser'
            }`
          ],
        );
      let remainingTime =
        (Number(triedAttempt) > 0 ? Number(getAddedRemainingTime()) : 600) -
        elapsedTime;

      if (
        Number(triedAttempt) < 1 &&
        Number(triedAttempt) >= 0 &&
        remainingTime === 120
        // cameOnce === 0
      ) {
        console.log('hereasdas');
        // openNotification({
        //   message: null,
        //   description: (
        //     <>
        //       <span className="text-white">
        //         The order in your cart is about to expire. Do you want to extend
        //         it by 2minutes?
        //       </span>
        //     </>
        //   ),
        //   duration: null,
        //   className: 'bg-neutral-900 rounded-lg',
        //   primaryButtonOnclick: onSubmit,
        //   secondaryButtonOnClick: onCancel,
        // });
        dispatch(setOpenTimerattemptModal(true));
        // cameOnce = 1;
      } else if (
        Number(triedAttempt) === 1 &&
        Number(triedAttempt) >= 0 &&
        remainingTime < 120
        // cameOnce === 1
      ) {
        console.log('hereasdasasdaasas');
        // openNotification({
        //   message: null,
        //   description: (
        //     <>
        //       <span className="text-white">
        //         The order in your cart is about to expire. Do you want to extend
        //         it by 2minutes?
        //       </span>
        //     </>
        //   ),
        //   duration: null,
        //   className: 'bg-neutral-900 rounded-lg',
        //   primaryButtonOnclick: onSubmit,
        //   secondaryButtonOnClick: onCancel,
        // });
        dispatch(setOpenTimerattemptModal(true));
        // cameOnce = 2;
      }
      // }
    }
  }, [
    timer,
    location,
    cookies,
    decodedToken,
    dispatch,
    openModal?.triedAttempt,
    IsGuestUser,
    // onCancel,
    // onSubmit,
    // openNotification,
  ]);

  useEffect(() => {
    sessionStorage.setItem(
      `timer${
        IsGuestUser
          ? 'GuestUser'
          : decodedToken
          ? decodedToken?.id
          : 'GuestUser'
      }`,
      JSON.stringify(timer),
    );
  }, [timer, decodedToken, IsGuestUser, openModal, cookies]);

  // useEffect(() => {
  //   if (openModal?.openTimerAttemptModal) {
  //     openNotification({
  //       message: null,
  //       description: (
  //         <>
  //           <span className="text-white">
  //             The order in your cart is about to expire. Do you want to extend
  //             it by 2minutes?
  //           </span>
  //         </>
  //       ),
  //       duration: null,
  //       className: 'bg-neutral-900 rounded-lg',
  //       primaryButtonOnclick: onCancel,
  //       secondaryButtonOnClick: onSubmit,
  //     });
  //   }
  // }, [openModal?.openTimerAttemptModal, onCancel, onSubmit, openNotification]);

  // console.log('timer', timer);
  return (
    <>
      {(Object.entries(cartvirtualData)?.length > 0 ||
        Object.entries(cartInpersonData)?.length > 0) &&
        timer !== 0 &&
        timer}
    </>
  );
};
export default CartTimer;
