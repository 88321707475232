import { Tab } from '@headlessui/react';
import React from 'react';
import { classNames } from '../common/DefaultClasses';
import { useState } from 'react';

import top_header_bg from '../../assets/images/background/top_header_bg.svg';
import AboutTabv2 from './about_compo/AboutTabv2';
import Avatar from 'react-avatar';
export default function TopDashboardTabs() {
  let [categories] = useState({
    About: [
      {
        id: 1,
        title: 'Does drinking coffee make you smarter?',
        compo: (
          <>
            <AboutTabv2 />
          </>
        ),
      },
    ],
    Tools: [
      {
        id: 1,
        title: 'Is tech making coffee better or worse?',
      },
    ],
    'Event & Tours': [
      {
        id: 1,
        title: 'Ask Me Anything: 10 answers to your questions about coffee',
      },
    ],
    Analytic: [
      {
        id: 1,
        title: 'Ask Me Anything: 10 answers to your questions about coffee',
      },
    ],
    Purchases: [
      {
        id: 1,
        title: 'Ask Me Anything: 10 answers to your questions about coffee',
      },
    ],
  });
  return (
    <React.Fragment>
      <div className="relative">
        <div className="w-full">
          <Tab.Group>
            <div className="p-0 relative w-full after:absolute after:content-[''] after:bg-gray-200 after:bottom-0 after:left-0 after:h-1 after:w-full after:flex">
              <div
                className={`absolute inset-0 w-full h-full z-0`}
                style={{ backgroundImage: `url(${top_header_bg})` }}
              ></div>
              <div className="p-3 px-4 relative flex gap-2">
                <Avatar
                  name="User Name"
                  color={'#408dfb'}
                  size={50}
                  round="8px"
                />
                <div>
                  <div className="text-lg font-medium">User Name</div>
                  <div className="text-sm text-color-secondary">
                    My name is vivekgarud07. I am the Event Organizer at
                    NFTGOTIX.
                  </div>
                </div>
              </div>
              <div className="relative z-10">
                <Tab.List className="flex space-x-3 rounded-xl p-0 px-3">
                  {Object.keys(categories).map((category) => (
                    <div key={category} className="overflow-hidden">
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            'px-3 rounded-lg py-2.5 text-sm font-medium leading-5 text-neutral-600 relative shrink-0',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 after:duration-200 after:ease-in',
                            `after:content-[''] after:left-0 after:h-1.3 after:rounded-full after:bottom-0 after:absolute after:w-full`,
                            selected
                              ? 'bg-white shadow-none after:bg-blue-500'
                              : 'text-blue-100 hover:bg-white/[0.12] hover:text-neutral-900',
                          )
                        }
                      >
                        {category}
                      </Tab>
                    </div>
                  ))}
                </Tab.List>
              </div>
            </div>
            <div className="relative h-full">
              <div className="h-full bg-white">
                <Tab.Panels className="">
                  {Object.values(categories).map((posts, idx) => (
                    <Tab.Panel
                      key={idx}
                      className={classNames('rounded-xl bg-white p-0', '')}
                    >
                      {posts.map((post) => post.compo)}
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </div>
            </div>
          </Tab.Group>
        </div>
      </div>
    </React.Fragment>
  );
}
