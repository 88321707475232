import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useApiHeader } from '../../hooks/useDecodedToken';
import { get_ticket_preview_details } from '../../helpers/api/event/event';

export function useTicketPreview(cid) {
  const { json_header } = useApiHeader()
  const {
    data,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['ticket_preview_details', cid],
    queryFn: async () => await get_ticket_preview_details(json_header, cid),
    refetchOnWindowFocus: true,
  });


  const values = useMemo(() => ({
    data, isLoading, refetch
  }), [data, isLoading, refetch])

  return values
}
