import React from 'react';
import { LogoGoTix } from '../../../components/util/CustomIcons';
import HelmetComponent from '../../../utils/HelmateComponent';
import SignUpV2 from '../../../components/auth/signup/SignUpV2';
import SignUpBusiness from '../../../components/auth/signup/SignUpBusiness';

export default function SignUpScreen({ compo, darkTheme, showLogo }) {
  return (
    <>
      <HelmetComponent title={'NFTGOTIX | Sign Up'} />
      {showLogo ? (
        <div className="w-full flex justify-center">
          <LogoGoTix className={`w-full max-w-sm h-10 dark:text-whiteNew-0`} />
        </div>
      ) : null}
      <div className="w-full flex justify-center">
        <figure
          className={`flex justify-center items-center w-full border-1 rounded-2xl overflow-hidden dark:bg-color-base-1 max-w-2xl border-level-4`}
        >
          <div
            className={`w-full p-3.5 md:px-8 py-10 dark:bg-color-base-1 bg-whiteNew-0`}
          >
            <div>
              {compo === 'signup' && <SignUpV2 darkTheme={darkTheme} />}
              {/* <SignUp darkTheme={darkTheme} /> */}
            </div>
          </div>
        </figure>
      </div>
      {/* <div className="flex justify-center items-center mt-2">
          <div className="flex justify-center items-center w-full 2xl:w-2/3 text-center">
            <span>
              By registration you confirm you agree to the <TermsLink /> and{' '}
              <PrivacyLink />.
            </span>
          </div>
        </div> */}
    </>
  );
}
