import React, { useCallback } from 'react'
import { all_events_list, banner_events, explore_events } from '../../helpers/api/event/event';
import { useDispatch, useSelector } from 'react-redux';
import useSWR, { useSWRConfig } from 'swr';
import { addEvents } from '../../features/home_page/homepage.slice';
import { getAllEvent, updateAllCategories, updateAllEvent, updateAllSampleArtist, updateFilter } from '../../features/event/allevent.slice';
import { getSampleArtistList } from '../../helpers/api/event/artist/artist';
import { useQuery } from '@tanstack/react-query';
import { useVenueType } from './venues/venues';
import { useApiHeader } from '../useDecodedToken';
import { getVenueType } from '../../helpers/api/event/venues/venues';

export const useAllEventList = () => {
  let dispatch = useDispatch();
  let { all_event, filterParams, all_event_count } = useSelector((state) => state.allEventReducer);
  let { page, limit } = filterParams
  const { json_header } = useApiHeader()
  const PublishedEvents = useSelector((state) => state?.publishedEvents);

  const exploreEventdetails = useSWR(
    ['explore_event'],
    async () => await all_events_list(page, limit),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      keepPreviousData: true,
      onSuccess: (res) => {
        dispatch(updateAllEvent({ array: res?.data, count: res?.count }));
      },
    },
  );

  const fetchMore = useCallback(
    async () => {

      const data = await all_events_list(page + 1, limit)
      let arr = [...all_event, ...data?.data]
      dispatch(updateAllEvent({ array: arr, count: data?.count }));
    },
    [dispatch, limit, page, all_event]
  )


  const allArtists = useQuery({
    queryKey: ['SampleArtistForEvent'],
    queryFn: async () => await getSampleArtistList(1, '', 100),
    onSuccess: (data) => {
      dispatch(updateAllSampleArtist(data?.data?.data))
    },
    // keepPreviousData: true,
    // suspense: true,
  });

  const getVenueTypeList = useQuery({
    queryKey: ['venueTypeforEvent'],
    queryFn: async () => await getVenueType(1, json_header),
    keepPreviousData: true,
    onSuccess: (data) => {
      dispatch(updateAllCategories(data?.data))
    }
    // suspense: true,
  });

  const bannerEventdetails = useSWR(
    ['banner_event'],
    async () => await banner_events(),
    {
      // refreshInterval: 60 * 1000,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      keepPreviousData: true,
      onSuccess: (res) => {

        dispatch(
          addEvents({
            ...PublishedEvents,
            banner: {
              data: res?.data,
              totalCount: res?.count,
            },
          }),
        );
      },

      // onSuccess(data) {

      // },
    },
  );

  return {
    exploreEventdetails,
    all_event, page, limit, all_event_count, fetchMore,
    getVenueTypeList,
    allArtists,
    filterParams,
    bannerEventdetails
  };
}

export default useAllEventList