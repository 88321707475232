import { useLocation } from 'react-router-dom';
import { clusterApiUrl, Connection } from '@solana/web3.js';
import React, { useContext, useMemo, useEffect } from 'react';
import { useLocalStorageState } from '../utils/localStorage';

export const SequenceType = {
  Sequential: 'Sequential',
  Parallel: 'Parallel',
  StopOnFailure: 'StopOnFailure',
};

export const ENDPOINTS = [
  {
    name: 'mainnet-beta',
    endpoint: 'https://api.mainnet-beta.solana.com',
    // ChainId: ChainId.MainnetBeta,
  },
  // {
  //   name: "mainnet-beta (Solana)",
  //   endpoint: "https://api.mainnet-beta.solana.com",
  //   ChainId: ChainId.MainnetBeta,
  // },
  // {
  //   name: "mainnet-beta (Serum)",
  //   endpoint: "https://solana-api.projectserum.com/",
  //   ChainId: ChainId.MainnetBeta,
  // },
  {
    name: 'testnet',
    endpoint: clusterApiUrl('testnet'),
    // ChainId: ChainId.Testnet,
  },
  {
    name: 'devnet',
    endpoint: clusterApiUrl('devnet'),
    // ChainId: ChainId.Devnet,
  },
];

const DEFAULT = ENDPOINTS[2].endpoint;

const ConnectionContext = React.createContext({
  endpoint: DEFAULT,
  setEndpoint: () => { },
  connection: new Connection(DEFAULT),
  env: ENDPOINTS[0].name,
  tokens: [],
  tokenMap: new Map(),
});

export function ConnectionProvider({ children }) {
  const searchParams = new URLSearchParams(useLocation.search);
  const network = searchParams.get('network');
  const queryEndpoint =
    network && ENDPOINTS.find(({ name }) => name.startsWith(network))?.endpoint;

  const [savedEndpoint, setEndpoint] = useLocalStorageState(
    'connectionEndpoint',
    ENDPOINTS[0].endpoint,
  );
  const endpoint = queryEndpoint || savedEndpoint;

  // const connection = useMemo(
  //   () => new Connection(endpoint, "confirmed"),
  //   [endpoint]
  // );

  const connection = useMemo(
    () => new Connection(endpoint, 'confirmed'),
    [endpoint],
  );

  const env =
    ENDPOINTS.find((end) => end.endpoint === endpoint)?.name ||
    ENDPOINTS[0].name;

  // const [tokens, setTokens] = useState([]);
  // const [tokenMap, setTokenMap] = useState(new Map());
  // useEffect(() => {
  //   // fetch token files
  //   new TokenListProvider().resolve().then((container) => {
  //     const list = container
  //       .excludeByTag("nft")
  //       .filterByChainId(
  //         ENDPOINTS.find((end) => end.endpoint === endpoint)?.ChainId ||
  //         ChainId.MainnetBeta
  //       )
  //       .getList();

  //     const knownMints = [...list].reduce((map, item) => {
  //       map.set(item.address, item);
  //       return map;
  //     }, new Map());

  //     setTokenMap(knownMints);
  //     setTokens(list);
  //   });
  // }, [env]);

  // The websocket library solana/web3.js uses closes its websocket connection when the subscription list
  // is empty after opening its first time, preventing subsequent subscriptions from receiving responses.
  // This is a hack to prevent the list from every getting empty
  // useEffect(() => {
  //   const id = connection.onAccountChange(
  //     Keypair.generate().publicKey,
  //     () => { }
  //   );
  //   return () => {
  //     connection.removeAccountChangeListener(id);
  //   };
  // }, [connection]);

  useEffect(() => {
    const id = connection.onSlotChange(() => null);
    return () => {
      connection.removeSlotChangeListener(id);
    };
  }, [connection]);

  return (
    <ConnectionContext.Provider
      value={{
        endpoint,
        setEndpoint,
        connection,
        env,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  );
}

export function useConnection() {
  return useContext(ConnectionContext).connection;
}

export function useConnectionConfig() {
  const context = useContext(ConnectionContext);
  return {
    endpoint: context.endpoint,
    setEndpoint: context.setEndpoint,
    env: context.env,
    tokens: context.tokens,
    tokenMap: context.tokenMap,
  };
}
