import React from 'react';
import NotificationSettings from './NotificationSettings';
import EmailSettings from './EmailSettings';

export default function NotificationEmailSection({
  activeSection,
  setActiveSection,
  setActiveSubSection,
  activeSubSection,
}) {
  return (
    <React.Fragment>
      {activeSection === 'notification_email' &&
        activeSubSection === 'notification' && (
          <>
            <NotificationSettings />
          </>
        )}
      {activeSection === 'notification_email' &&
        activeSubSection === 'email' && (
          <>
            <EmailSettings />
          </>
        )}
    </React.Fragment>
  );
}
