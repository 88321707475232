import { Checkbox } from 'antd';
import React, { useCallback } from 'react';
import { TermsConditionLink, TermsLink } from '../../util/TermsServiceLink';

export const CheckoutConfirmation = ({ props }) => {
  const { checked = false, setChecked, setDisplayPoliciesRefund } = props;

  const onChange = useCallback(
    (e) => {
      // e.preventDefault();
      setChecked(!checked);
      // notCheckedError && setNotCheckedError(false);
    },
    [setChecked, checked],
  );
  return (
    <div className="">
      <div className="flex checkboxPurple ">
        <Checkbox
          checked={checked}
          // disabled={false}
          // className={notCheckedError ? 'checkBoxError' : ''}
          onChange={onChange}
          className="flex items-center"
        />
        <span className="ml-2.5 gap-[4px] mt-0.5 flex dark:text-whiteNew-0 text-zinc-800">
          I understand
          <span>
            <TermsConditionLink
              className={'dark:text-landingPurple-60 text-sky-600'}
              message="Terms"
            />
          </span>
          <span>and</span>
          <span
            className="dark:text-landingPurple-60 text-sky-600 cursor-pointer hover:underline "
            onClick={() => {
              setDisplayPoliciesRefund(true);
            }}
          >
            Policies
          </span>
        </span>
      </div>
    </div>
  );
};
