import React from 'react';
import { btnPrimaryClass, btnSecondayClass } from '../../common/DefaultClasses';
// import Drawer from '../../common/Drawer';
// import Image from '../../common/Image';
import moment from 'moment';
import useDecodedToken from '../../../hooks/useDecodedToken';
import { setIsOpen, viewSingleNFT } from '../../../features/nft/nft.slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  DATEFORMAT,
  onCloseModal,
  updateAccUserName,
} from '../../../utils/util';
import DefaultTicketCard from '../../create_ticket/create_ticket_cards/card_template/DefaultTicketCard';
import {
  setShowPolicy,
  setIsOpen as setIsOpenOrder,
} from '../../../features/orders/orders.slice';
import Tooltip from '../../common/Tooltip';
import { getTicketStatusWithoutBadge } from './DrawerUtils';
// import { Drawer } from 'antd';
import { AiOutlineClose } from 'react-icons/ai';
import EventImageVideo from '../../common/EventImageVideo';
import Drawer from '../../common/Drawer';

export default function NFTShowDrawer({
  nftData,
  setNftData,
  openSubDrawer,
  setOpenSubDrawer,
  getTickets = {},
  setChangeLayout = () => null,
  changeLayout,
  drawertype,
  setTabs = () => null,
  darkTheme = false,
  setSelectedIndex,
}) {
  const decodedToken = useDecodedToken();
  const dispatch = useDispatch();
  const { ticket } = useSelector((state) => state.orders);
  return (
    <React.Fragment>
      <Drawer
        title={
          <>
            <span className="flex flex-col items-start leading-none">
              <span className={`font-medium text-color-primary`}>
                {nftData?.tickets?.event?.name}
              </span>
              {/* <span className="text-[11px] text-slate-500 font-normal">
                10/29/23 - 11:00
              </span> */}
            </span>
          </>
        }
        closeIcon={
          <div className="absolute top-[15px] left-3 z-10 flex">
            <button
              type="button"
              onClick={() => {
                setOpenSubDrawer(false);
                onCloseModal();
              }}
              className={`${btnSecondayClass} Icon w-[28px] h-[28px] height-auto min-auto flex gap-2 relative p-0 text-sm`}
            >
              <AiOutlineClose className="absolute top-1/2 left-1/2 translate-neg-50-50 object-cover h-3.5 w-3.5" />
            </button>
          </div>
        }
        width={drawertype === 'nft' ? '23%' : '65%'}
        open={openSubDrawer}
        setOpen={setOpenSubDrawer}
        onClose={() => {
          setOpenSubDrawer(false);
          onCloseModal();
        }}
        className={`scrollCss ${
          darkTheme ? '!bg-black text-white cstm-drwr' : null
        } `}
        rootClassName={'drawer-root-className'}
        footer={
          <>
            {drawertype === 'nft' && (
              <div className="flex w-full justify-end gap-3">
                <div>
                  <Tooltip
                    title={
                      getTickets?.[0]?.is_minting === 0 ||
                      getTickets?.[0]?.is_cancel !== undefined
                        ? 'Minting is under process.'
                        : ''
                    }
                    placement={'bottom'}
                    childClassname="flex w-full"
                  >
                    <button
                      // to={`/user/${decodedToken?.username}/dashboard?tab=purchase&subtab=nfts`}
                      disabled={
                        getTickets?.[0]?.is_cancel !== undefined ||
                        getTickets?.[0]?.is_minting === 0
                      }
                      onClick={() => {
                        // if(getTickets?.[0]?.is_cancel === undefined &&
                        //   getTickets?.[0]?.is_minting !== 0) {

                        setOpenSubDrawer(false);
                        if (decodedToken?.user_type === 'buyer') {
                          setSelectedIndex('nfts');
                        } else {
                          setTabs('nfts');
                        }
                        dispatch(setShowPolicy(false));
                        dispatch(setIsOpen(false));
                        dispatch(setIsOpenOrder(false));
                        localStorage.setItem(
                          'showNFT',
                          JSON.stringify({
                            status: true,
                            NFTID: nftData?._id,
                          }),
                        );
                        onCloseModal();
                        dispatch(
                          viewSingleNFT({
                            status: true,
                            NFTID: nftData?._id,
                          }),
                        );
                        // }
                      }}
                      className={`${btnPrimaryClass}`}
                      target="_blank"
                      type="button"
                    >
                      More Info
                    </button>
                  </Tooltip>
                </div>
              </div>
            )}
          </>
        }
      >
        <div className="py-0">
          {drawertype === 'nft' && (
            <div>
              <div className="relative w-full rounded-md overflow-hidden">
                {/* <div className="cardOverlay"></div> */}
                {/* <div className="absolute inset-0 w-full h-full overflow-hidden rounded-xl"> */}
                <EventImageVideo
                  data={nftData?.mint_img}
                  alt={'Event Picture'}
                  className={'w-full h-full object-contain childCardHover'}
                  loading="lazy"
                  width="384px"
                  height="512px"
                />
                {/* <Image
                  loading="lazy"
                  src={nftData?.mint_img?.url}
                  alt="event-img"
                  className="w-full h-full object-contain childCardHover"
                /> */}
                {/* </div> */}
              </div>
              <div className="pt-3 p-1.5">
                <ul className="divide-y divide-level-2 mt-3">
                  <article className="flex items-start py-2.5">
                    <div className="min-w-0 relative flex-auto">
                      <div className="text-base text-color-primary font-medium">
                        Information
                      </div>
                    </div>
                  </article>
                  <article className="flex items-start py-2.5 w-full">
                    <div className="min-w-0 relative flex justify-between w-full">
                      <dl className="flex flex-wrap text-sm leading-6 text-color-secondary">
                        <dd>Creator</dd>
                      </dl>
                      <dl className="flex flex-wrap text-sm leading-6 font-medium">
                        <dd>
                          {updateAccUserName(
                            nftData?.tickets?.event?.organiser,
                          )}
                        </dd>
                      </dl>
                    </div>
                  </article>
                  <article className="flex items-start py-2.5 w-full">
                    <div className="min-w-0 relative flex justify-between w-full">
                      <dl className="flex flex-wrap text-sm leading-6 text-color-secondary">
                        <dd>Chain</dd>
                      </dl>
                      <dl className="flex flex-wrap text-sm leading-6 font-medium">
                        <dd>ARTC Testnet</dd>
                      </dl>
                    </div>
                  </article>
                  <article className="flex items-start py-2.5 w-full">
                    <div className="min-w-0 relative flex justify-between w-full">
                      <dl className="flex flex-wrap text-sm leading-6 text-color-secondary">
                        <dd>Owned By</dd>
                      </dl>
                      <dl className="flex flex-wrap text-sm leading-6 font-medium">
                        <dd>
                          {updateAccUserName(
                            nftData?.tickets?.event?.organiser,
                            decodedToken,
                          )}
                        </dd>
                      </dl>
                    </div>
                  </article>
                  <article className="flex items-start py-2.5 w-full">
                    <div className="min-w-0 relative flex justify-between w-full">
                      <dl className="flex flex-wrap text-sm leading-6 text-color-secondary">
                        <dd>Minted At</dd>
                      </dl>
                      <dl className="flex flex-wrap text-sm leading-6 font-medium">
                        <dd>
                          {' '}
                          {nftData?.nft_metadata?.minted_at
                            ? moment(nftData?.nft_metadata?.minted_at).format(
                                DATEFORMAT,
                              )
                            : 'NA'}
                        </dd>
                      </dl>
                    </div>
                  </article>
                  <article className="flex items-start py-2.5 w-full">
                    <div className="min-w-0 relative flex justify-between w-full">
                      <dl className="flex flex-wrap text-sm leading-6 text-color-secondary">
                        <dd>Status</dd>
                      </dl>
                      <dl className="flex flex-wrap text-sm leading-6 font-medium">
                        <dd className="">
                          {/* <div className="flex items-center gap-1 py-1 px-2 rounded-xl bg-bluev2-500 text-whiteNew-0"> */}
                          {/* <span className="font-medium text-xs flex justify-center gap-1 leading-tight"> */}
                          {/* Active
                              <span className="bg-blue-800 text-white text-[11px] min-w-[1rem] h-[1rem] flex justify-center items-center px-0.5 rounded-[4px] leading-tight">
                                +2
                              </span> */}
                          {getTicketStatusWithoutBadge(ticket)}
                          {/* </span> */}
                          {/* </div> */}
                        </dd>
                      </dl>
                    </div>
                  </article>
                </ul>
              </div>
            </div>
          )}

          {drawertype === 'design' &&
            getTickets.map((data, index) => {
              return (
                <DefaultTicketCard
                  key={index}
                  allData={data}
                  ticketDetails={{ data: [data?.tickets] }}
                  event_type={data?.event_type}
                  data={{ data: data?.tickets?.event }}
                  seatData={
                    data?.cart_seats?.seat_data?.filter(
                      (d) => d?.seat_id === data?.seat_id,
                    )[0] || []
                  } //add data according to seat_id
                  category={data?.category}
                  booking_id={data?._id}
                  event_id={data?.tickets?.event_id}
                  // setNftId={setNftId}
                  setOpen={() => {}}
                  hideToggle={true}
                  // setShowDetailView={setShowDetailView}
                  setChangeLayout={setChangeLayout}
                  changeLayout={
                    data?.tickets?.ticket_layout === 'Landscape'
                      ? 'horizontal'
                      : 'portrait'
                  }
                  from="create"
                  setTabs={setTabs}
                  setOpenSubDrawer={setOpenSubDrawer}
                  setSelectedIndex={setSelectedIndex}
                  nftDrawer
                />
              );
            })}
        </div>
      </Drawer>
    </React.Fragment>
  );
}
