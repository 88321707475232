import React from 'react';
import HeaderFillTop from '../HeaderFillTop';
import Tooltip from '../../common/Tooltip';
import ToogleSwitch from '../../util/ToogleSwitch';
import RightSideSectionWrapper from '../RightSideSectionWrapper';
import ContactInfoForm from './ContactInfoForm';

export default function ContactInfo() {
  const [toggle, setToggle] = React.useState(false);
  const toggleValue = (value) => {
    setToggle(!toggle);
  };
  return (
    <React.Fragment>
      <div>
        <div className="">
          <HeaderFillTop
            title={'Contact Info'}
            extraPills={
              <>
                {/* <span className="flex items-center gap-2">
                  <span className="bg-slate-200 p-1.5 px-2.5 rounded-full flex gap-1">
                    <span className="font-medium">Account Created:</span>
                    07/27/23
                  </span>
                  <span className="bg-slate-200 p-1.5 px-2.5 rounded-full flex gap-1">
                    <span className="font-medium">Last Login:</span> 10/05/23 -
                    12:41
                  </span>
                </span> */}
              </>
            }
            extra={
              <>
                <span className="flex gap-2 items-center">
                  {/* <span className="text-base">Edit</span> */}
                  <Tooltip
                    title={!toggle ? 'Enable Edit' : 'Disable Edit'}
                    placement={'left'}
                  >
                    <span className="flex">
                      <ToogleSwitch value={toggle} toggleValue={toggleValue} />
                    </span>
                  </Tooltip>
                </span>
              </>
            }
          />
        </div>
        <RightSideSectionWrapper>
          <section>
            <div className="container-fluid">
              <div className="p-3">
                <ContactInfoForm />
              </div>
            </div>
          </section>
        </RightSideSectionWrapper>
      </div>
    </React.Fragment>
  );
}
