import React, { useRef } from 'react';
import HelmetComponent from '../../utils/HelmateComponent';
import { useStreamAPI, useStreamLounge } from './hooks/useStreamLounge';
import StreamingPage from './StreamingPage';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateDrawerScreen, updateTicketAuthorization } from '../../features/stream_lounge/stream.slice';
import { useCallback } from 'react';
import { exit_from_stream } from '../../helpers/api/stream/stream';
import useSearchQuery from '../../hooks/useSearchQuery';
import { useDrawerScreens } from './hooks/useDrawer';

export default function Index() {
  const { exit_stream_api } = useStreamAPI();
  const dispatch = useDispatch();
  const { authorize_ticket } = useStreamLounge();
  const { drawer_screen } = useDrawerScreens()
  const { ticket_details } = authorize_ticket;
  const query = useSearchQuery()
  const isLoaded = useRef(false)

  useEffect(() => {
    const isAuthenticated = query.get('utm')
    if (!isLoaded.current && isAuthenticated) {
      dispatch(
        updateDrawerScreen({
          ...drawer_screen,
          ticket_screen: true,
        }),
      );
      isLoaded.current = true
    }
  }, [dispatch, query, drawer_screen])

  // const enter_stream_api = useCallback(() => {
  //   return enter_stream({
  //     ticket_id: ticket_details?.ticket_id,
  //     device_info: deviceData,
  //     user_info: {},
  //   });
  // }, [deviceData, ticket_details?.ticket_id]);

  // const mutation = useMutation(enter_stream_api, {
  //   onSuccess: (data) => {
  //     const { data: resp } = data;
  //     const streamObj = {
  //       is_authorized: true,
  //       ticket_details: {
  //         event_name: query.get('evn'),
  //         ticket_id: resp?.ticket_id,
  //         device_info: deviceData,
  //         user_info: {},
  //       },
  //     };
  //     localStorage.setItem('streaming_details', JSON.stringify(streamObj));
  //     dispatch(updateTicketAuthorization(streamObj));
  //     // message.success('Wallet updated successfully');
  //     // if (data?.data) setuserdetails(data?.data);
  //     // setIsOpen3(false);
  //   },
  //   onError: (error) => {
  //     message.error(error.response.data.message);
  //   },
  // });

  // const isMounted = useRef(Boolean(Object.keys(ticket_details).length));
  // useEffect(() => {
  //   if (
  //     is_authorized &&
  //     query.get('evn') === ticket_details?.event_name &&
  //     isMounted.current &&
  //     event_started.current &&
  //     !event_ended.current
  //   ) {
  //     isMounted.current = false;
  //     mutation.mutate();
  //   }
  // }, [
  //   is_authorized,
  //   mutation,
  //   query,
  //   ticket_details?.event_name,
  //   event_started,
  //   event_ended
  // ]);

  const handleExitEventOnPage = useCallback(() => {
    if (!ticket_details?.ticket_id) return null
    return exit_from_stream(ticket_details?.ticket_id).then(() => {
      localStorage.removeItem('streaming_details');
      dispatch(updateTicketAuthorization({}));
    });
  }, [dispatch, ticket_details?.ticket_id]);

  window.addEventListener("beforeunload", (event) => {
    if (!ticket_details?.ticket_id) return null
    handleExitEventOnPage();
    event.stopImmediatePropagation()
    event.returnValue = 'NOPE'

  });

  // useEffect(() => {
  //   const unloadCallback = (event) => {
  //     handleExitEventOnPage()
  //     event.stopImmediatePropagation();
  //     event.preventDefault();
  //     event.returnValue = '';
  //   };

  //   window.addEventListener('beforeunload', unloadCallback);
  //   return () => {
  //     window.removeEventListener('beforeunload', unloadCallback);
  //   };
  // // }, [handleExitEventOnPage]);
  // useEffect(() => {
  //   return () => {
  //     exit_from_stream(ticket_details?.ticket_id).then(() => {
  //       localStorage.removeItem('streaming_details');
  //       dispatch(updateTicketAuthorization({}));
  //     });
  //   };
  // }, [exit_stream_api, dispatch, ticket_details?.ticket_id]);

  useEffect(() => {
    return () => {
      if (!window.location.pathname.includes('streaming')) {
        exit_from_stream(ticket_details?.ticket_id).then(() => {
          localStorage.removeItem('streaming_details');
          dispatch(updateTicketAuthorization({}));
        });
      }
    };
  }, [exit_stream_api, dispatch, ticket_details?.ticket_id]);
  return (
    <React.Fragment>
      <HelmetComponent title={'NFTGOTIX | Event Stream Lounge'} />
      <StreamingPage />
    </React.Fragment>
  );
}
