import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useWalletModal } from '../../../contexts/WalletProvider';
// import { authActions } from '../../../features/auth.slice';
import { addUpdateWallet } from '../../../helpers/api/user/user_details';
import { history } from '../../../helpers/router_templates/history';
import useDecodedToken from '../../../hooks/useDecodedToken';
import { useWalletHooks } from '../../../hooks/useWalletConnected';
import WalletSelectRadioGroup from '../../util/WalletSelectRadioGroup';
import { ArtchiveWalletName } from '../../../contexts/artchive/adapter';
import BlockchainSelectRadioGroup from '../../util/BlockchainSelectRadioGroup';
import { btnPrimaryClass } from '../../common/DefaultClasses';

export default function WalletLogin({
  setIsOpen3 = null,
  setuserdetails = null,
  _isConnecting = null,
  setIndex = null,
  defaultTitle = true,
  darkTheme,
  setShowBlockchainTab = null,
  showBlockchainTab = null,
}) {
  const {
    handleConnectWallet,
    handleDisconnectWallet,
    walletConnected,
    walletAddress,
    walletname,
    isConnecting,
    error,
    setError,
    blockchainselected,
  } = useWalletHooks();
  const { selectedWallet } = useWalletModal();

  // const dispatch = useDispatch();
  const decodedToken = useDecodedToken();

  const authUser = useSelector((x) => x.auth);
  const authError = useSelector((x) => x.auth.error);

  const pathname_type = String(history.location.pathname).split('-')[1];
  const [selectedBlockchain, setSelectedBlockchain] = useState();

  const insertWallet = useCallback(() => {
    if (!walletAddress) return;
    return addUpdateWallet({
      wallet_address: walletAddress,
      wallet_name: walletname,
      blockchain: blockchainselected,
    });
  }, [blockchainselected, walletAddress, walletname]);
  const mutation = useMutation(insertWallet, {
    onSuccess: (data) => {
      message.success('Wallet updated successfully');
      if (data?.data) setuserdetails(data?.data);
      setIsOpen3(false);
    },
    onError: (error) => {
      message.error(error?.response?.data?.message);
    },
  });

  useEffect(() => {
    if (walletConnected) {
      let stale = true;
      if (typeof setIsOpen3 === 'function') {
        setIsOpen3();
      }
      if (!_isConnecting && stale) {
        if (!stale) return;
        mutation.mutate();
        stale = false;
      }
      setShowBlockchainTab(false);
      // if (!decodedToken) {
      //   dispatch(
      //     authActions.wallet_login({
      //       wallet_address: walletAddress,
      //       wallet_name: walletname,
      //     }),
      //   );
      // }
      // else {
      //   if (!_isConnecting) {
      //     mutation.mutate();
      //   }
      // }
    }
  }, [walletConnected, _isConnecting]);

  const {
    formState: { errors, isValid },
    control,
    handleSubmit,
  } = useForm({
    shouldFocusError: true,
    shouldUnregister: true,
    reValidateMode: 'onChange',
  });

  const onSubmit = () => {
    setError();
    mutation.reset();
    if (walletConnected) {
      handleDisconnectWallet();
    }
    if (!selectedWallet) {
      return message.info('Select any one wallet');
    }
    if (setIndex !== null) {
      setIndex(1);
    }
    if (isValid) {
      if (showBlockchainTab) return selectedBlockchain?.onclick();
      if (selectedWallet?.name === ArtchiveWalletName)
        return setShowBlockchainTab(true);
      handleConnectWallet();
    }
  };

  return (
    <React.Fragment>
      <div className="flex justify-center items-center flex-col text-center">
        <h1 className="fw-medium text-lg 2xl:text-xl text-purple-500">
          {defaultTitle
            ? pathname_type === 'login'
              ? 'Welcome back!'
              : 'Create account!'
            : ''}
        </h1>
        {defaultTitle && (
          <p className="text-gray-500 mt-3">Select Your Wallet</p>
        )}
      </div>
      {(authError?.message || errors?.wallet || error || mutation.isError) && (
        <Form.Text className="block fw-bold text-center mb-2 text-danger">
          ERROR::{' '}
          {authError?.message ||
            errors?.wallet?.message ||
            error ||
            mutation.error?.message}
        </Form.Text>
      )}
      <div
        className={`space-y-4 max-w-lg w-full ${defaultTitle ? 'py-3' : ''}`}
      >
        <div className="">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div>
              {showBlockchainTab ? (
                <BlockchainSelectRadioGroup
                  selectedBlockchain={selectedBlockchain}
                  setSelectedBlockchain={setSelectedBlockchain}
                  control={control}
                />
              ) : (
                <WalletSelectRadioGroup control={control} />
              )}
            </div>
            <div className="flex justify-center items-center mt-3 w-full">
              <button
                type="submit"
                disabled={
                  isConnecting || authUser?.loading || mutation.isLoading
                }
                className={`${btnPrimaryClass} p-2.5 rounded-lg duration-200 ease-in w-full`}
              >
                {isConnecting || authUser?.loading ? (
                  <i className="fas fa-circle-notch fa-spin text-xl"></i>
                ) : walletConnected ? (
                  'Disconnect ' + walletname + ' And Add New Wallet'
                ) : (
                  'Connect'
                )}
              </button>
            </div>
          </Form>
        </div>
        {!decodedToken && (
          <div className="row justify-content-center mt-2">
            <div className="col-12 text-center">
              <div className="flex justify-center items-center gap-2">
                <span className="h6 text-dark mb-0 fw-normal">
                  Don't have an account?
                </span>
                <Link
                  to={'/user/signup'}
                  type="button"
                  className="btn btn-link textPrimary p-0 mb-0 fw-medium"
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
