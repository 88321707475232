import React, { useCallback, useEffect, useState } from 'react';
import { afterBorder } from '../../../common/DefaultClasses';
import { SearchArtistListData, SearchEventListData } from './SearchListData';
import { useNavigate } from 'react-router-dom';

function PopularSearchCard({
  trendingData,
  setOpenInputSearch,
  setInputValue,
  inputValue,
}) {
  let navigate = useNavigate();
  const [recentSearch, setRecentSearch] = useState([]);

  const onSearch = useCallback((e, item) => {
    setOpenInputSearch(false);

    let recentSearches =
      JSON.parse(localStorage.getItem('recent_searches')) || [];

    const itemExists = item.eventData
      ? recentSearches.some(
          (search) => search.eventData?._id === item?.eventData?._id,
        )
      : recentSearches.some((search) => search?._id === item?._id);

    if (!itemExists) {
      recentSearches = [item, ...recentSearches];
      localStorage.setItem('recent_searches', JSON.stringify(recentSearches));
    }

    const path = item?.eventData
      ? `/event-detail/${item?.eventData?.slug}`
      : `/artist/${item?._id}`;
    navigate(path);

    setInputValue('');
  }, []);

  const removeFromStorage = useCallback((e, item) => {
    const removeSearches =
      JSON.parse(localStorage.getItem('recent_searches')) || [];
    let updatedSearches = [];

    if (item.eventData) {
      updatedSearches = removeSearches.filter(
        (search) => search.eventData?._id !== item?.eventData?._id,
      );
    } else {
      updatedSearches = removeSearches.filter(
        (search) => search._id !== item?._id,
      );
    }

    localStorage.setItem('recent_searches', JSON.stringify(updatedSearches));
    setRecentSearch(updatedSearches);
  }, []);

  useEffect(() => {
    setRecentSearch(JSON.parse(localStorage.getItem('recent_searches')) || []);
  }, []);

  const highlightLetters = useCallback(
    (text) => {
      const regex = new RegExp(`(${inputValue})`, 'gi');
      return text.split(regex).map((part, index) =>
        regex.test(part) ? (
          <span
            key={index}
            className="text-color-primaryColor1 dark:text-color-primaryColor2 font-semibold text-base"
          >
            {part}
          </span>
        ) : (
          part
        ),
      );
    },
    [inputValue],
  );

  // /v1/cms/home/trending-search
  return (
    <>
      <div className="p-2.5 space-y-2">
        {recentSearch && recentSearch.length > 0 && (
          <div>
            <div className={`relative ${afterBorder} w-full px-2.5 pb-1.5`}>
              <span className="text-color-secondary text-base font-medium">
                Recent Searches
              </span>
            </div>
            <div className="py-1.5 px-1.5 space-y-3">
              {recentSearch?.map((item) =>
                item?.eventData ? (
                  <SearchEventListData
                    key={item._id}
                    closeIcon={true}
                    item={item}
                    onSubmit={onSearch}
                    removeFromStorage={removeFromStorage}
                    from={'recent'}
                  />
                ) : (
                  <SearchArtistListData
                    key={item._id}
                    item={item}
                    onSubmit={onSearch}
                    closeIcon={true}
                    removeFromStorage={removeFromStorage}
                    from={'recent'}
                  />
                ),
              )}
            </div>
          </div>
        )}

        <div>
          <div className={`relative ${afterBorder} w-full px-2.5 pb-1.5`}>
            <span className="text-color-secondary text-base font-medium">
              Trending Events
            </span>
          </div>
          <div className="py-1.5 px-1.5 space-y-3">
            {trendingData?.data?.eventData?.map((item) => (
              <SearchEventListData
                key={item._id}
                item={item}
                onSubmit={onSearch}
                removeFromStorage={removeFromStorage}
                highlightLetters={highlightLetters}
                from={'recent'}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(PopularSearchCard);
