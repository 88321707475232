import React from 'react';

export default function SideBarWrapper({
  children,
  padding,
  primarySidebarClass,
}) {
  return (
    <React.Fragment>
      <div className="w-full md:max-w-[200px] shrink-0 relative h-full overflow-x-hidden border-r-[1px] border-gray-200">
        <div
          className={` overflow-y-auto min-h-[81vh] max-h-screen h-full ${
            padding ? padding : 'p-0'
          } ${primarySidebarClass ? primarySidebarClass : 'bg-slate-50'}`}
        >
          {children}
        </div>
      </div>
    </React.Fragment>
  );
}
