import React from 'react';
import StreamingSessionList from '../../components/stream_lounge_components/StreamingSessionList';
import SessionAuthDrawer from '../../components/stream_lounge_components/SessionAuthDrawer';
import { memo } from 'react';
import { Drawer } from 'antd';
import { useDrawerScreens } from './hooks/useDrawer';
import { useSelector } from 'react-redux';
import { useStreamLounge } from './hooks/useStreamLounge';
import { getIsVerticalLayout } from '../../utils/util';
import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';

function LiveSessionDetails() {
  const { toggleSessionScreen, onCloseSession, handleToggleScreen } =
    useDrawerScreens();
  const { active_session_list, portal_state, show_singleton_session } =
    useSelector((s) => s.stream);
  const {
    from_dashboard,
    ticket_data,
    event_id,
    getStreamingDetails,
    refetchOrderData,
  } = useStreamLounge();
  const isVerticalLayout = useMemo(() => getIsVerticalLayout(), []);
  const ShowSessionDrawerContent = () => {
    if ((toggleSessionScreen && active_session_list?.length) || from_dashboard)
      return (
        <div className="space-y-1">
          <StreamingSessionList
            ticket_data={ticket_data}
            event_id={event_id}
            getStreamingDetails={getStreamingDetails}
            onCloseSession={onCloseSession}
            handleToggleScreen={handleToggleScreen}
            active_session_list={active_session_list}
            show_singleton_session={show_singleton_session}
            orders_mutate={refetchOrderData}
          />
        </div>
      );

    return <SessionAuthDrawer />;
  };

  const ShowSessionDrawerContentMemo = memo(ShowSessionDrawerContent);
  return (
    <Drawer
      placement={isVerticalLayout ? 'bottom' : 'right'}
      onClose={onCloseSession}
      open={portal_state.session_drawer}
      title={
        <>
          <div className="flex">
            <div className="flex justify-between items-center gap-2 w-full">
              <span className="flex">
                <span className="flex w-1 min-h-[30px] bg-neutral-700"></span>
              </span>
              <div className="flex justify-between items-center shrink-0 w-full ">
                <span className="flex text-white font-medium">
                  Active Session(s)
                </span>
              </div>
            </div>
          </div>
        </>
      }
      width="540px"
      height={'90%'}
      className="!bg-black text-white cstm-drwr"
    >
      <div>
        <Row>
          <Col md={12}>
            <div>
              <ShowSessionDrawerContentMemo />
            </div>
          </Col>
        </Row>
      </div>
    </Drawer>
  );
}

export default LiveSessionDetails;
