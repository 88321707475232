import React from 'react';
import bg1 from '../../assets/images/banner/3.png';
import ProtectRoute from '../../helpers/router_templates/ProtectRoute';

export default function AuthScreenWrapper({ children, darkTheme }) {
  return (
    <React.Fragment>
      <ProtectRoute>
        <div>
          <section>
            <div className="min-h-screen grid grid-cols-1 xl:grid-cols-2 place-content-center">
              <div
                className={`min-h-screen flex justify-center align-center dark:bg-color-base-4 bg-color-base-2`}
              >
                <div className="flex justify-center items-center h-full w-full py-7">
                  <div className="flex justify-center items-center flex-col w-full px-2 px-md-3 md:w-5/6 lg:w-5/6 2xl:w-5/6 ">
                    <div className="w-full max-w-screen-md">{children}</div>
                  </div>
                </div>
              </div>
              <div className="relative min-h-full w-full hidden xl:flex justify-center">
                <div className="min-h-full h-full">
                  <img
                    src={bg1}
                    alt="Side Banner"
                    className="absolute inset-0 h-full w-full object-cover"
                    loading="lazy"
                  />
                  {/* <div className="bg-neutral-900 absolute inset-0 bg-opacity-30"></div> */}
                </div>
                {/* <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <Link
                    to="/demo"
                    className="px-3 py-2 bg-neutral-800 text-gray-50 rounded-lg hover:bg-neutral-900 duration-200 ease-in bg-opacity-50 hover:bg-opacity-50 backdrop-blur-lg hover:backdrop-blur-lg text-lg"
                  >
                    Demo Stadiums
                  </Link>
                </div> */}
              </div>
            </div>
          </section>
        </div>
      </ProtectRoute>
    </React.Fragment>
  );
}
