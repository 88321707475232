import axios from 'axios';
import config from '../../../api/Config';

export async function addCard(payload) {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const res = axios.post(config.baseUrl + config.endpoints.add_card, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  return res;
}
export async function deleteCard(payload) {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const res = axios.post(
    config.baseUrl + config.endpoints.delete_card,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res;
}

export async function addBank(payload) {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const res = axios.post(config.baseUrl + config.endpoints.add_bank, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  return res;
}
export async function getWalletMoneyDetails({
  page = 1,
  limit = 10,
  sort_by = '',
}) {
  const token = localStorage.getItem('token');
  if (!token) return null;
  if (sort_by !== '') {
    const res = axios.get(
      config.baseUrl +
      config.endpoints.get_wallet_money_details +
      `?page=${page}&limit=${limit}&sort_by=${sort_by}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return res;
  } else {
    const res = axios.get(
      config.baseUrl +
      config.endpoints.get_wallet_money_details +
      `?page=${page}&limit=${limit}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );

    return res;
  }
}

export async function getWalletBalance() {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const res = axios.get(config.baseUrl + config.endpoints.get_wallet_balance, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return res;
}
export async function getRazerPayMoneyDetails({ page = 1, limit = 10, user_id = "" }) {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const res = axios.get(config.baseUrl + config.endpoints.get_razerpay_money + `?page=${page}&limit=${limit}&user_id=${user_id}&payment_gateway=razerpay`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return res;
}
export async function getWalletMoneyByEventId(id, page, limit) {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const res = axios.get(
    config.baseUrl +
    config.endpoints.get_usdmoney_by_id +
    id +
    `?page=${page}&limit=${limit}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res;
}

export async function getTicketPaymentDetails(page, limit) {
  const token = localStorage.getItem('token');
  if (!token) return null;
  let user_id = JSON.parse(localStorage.getItem('userID'));
  const res = axios.get(
    config.baseUrl +
    config.endpoints.minted_ticket_payment +
    `?page=${page}&limit=${limit}&status=captured&user_id=${user_id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res;
}

export async function addMoney(payload) {
  const res = axios.post(
    config.baseUrl + config.endpoints.add_wallet_money,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res;
}

export async function deleteBank(payload) {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const res = axios.post(
    config.baseUrl + config.endpoints.delete_bank,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res;
}
