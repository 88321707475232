import { createSlice } from '@reduxjs/toolkit';
import { updateDataCountByViewportForEventCards } from '../../utils/util';

export const STREAMING_STATUS = 'Status';
export const EVENT_TYPE = 'Event Type';
export const ARTIST_LIST = 'Artist List';
export const CATEGORIES = 'Categories';
export const LOCATION = 'Location';
export const PRICE = 'price';
export const TOUR = 'Tour';

export const GRID = 'grid';
export const LIST = 'list';
export const LAYOUT3X3 = '4';
export const LAYOUT2X2 = '3';

export const FILTER_CONST = {
  [STREAMING_STATUS]: 'status',
  [EVENT_TYPE]: 'event_type',
  [CATEGORIES]: 'event_category',
  [ARTIST_LIST]: 'artists',
  [LOCATION]: 'location',
  [TOUR]: 'tour',
};

export const initialFilterParams = {
  search_key: undefined,
  event_type: [],
  artists: [],
  event_category: [],
  status: [],
  location: [],
  tour: undefined,
  min: undefined,
  max: undefined,
  sort_by: undefined,
  page: 1,
  limit: updateDataCountByViewportForEventCards() * 2,
};

const initialState = {
  all_event: [],
  all_event_count: 0,
  all_artist: [],
  all_categories: [],
  filterParams: initialFilterParams,
  paginated_index: 1,
  filter_pills: {},
  filter_loading: false,
  disable_apply: false,
  filter_settings: {
    view: GRID,
    layout: LAYOUT3X3,
  },
  all_event_filter_count: {},
};

const slice = createSlice({
  name: 'allEvent',
  initialState,
  reducers: {
    updateAllEvent: (state, { payload }) => {
      state.all_event = payload.array;
      state.all_event_count = payload.count;
    },
    updateAllSampleArtist: (state, { payload }) => {
      state.all_artist = payload;
    },
    updateAllCategories: (state, { payload }) => {
      state.all_categories = payload;
    },

    updatePaginatedIndex: (state, { payload }) => {
      state.paginated_index = payload;
    },

    updateFilter: (state, { payload }) => {
      state.filterParams = payload;
      // let separam = new URLSearchParams(payload).toString()
      // const params = new URLSearchParams(separam);
      // const paramsObj = Object.fromEntries(params.entries());
      // updateFilter(paramsObj);
    },
    updateFilterPills: (state, { payload }) => {
      state.filter_pills = payload;
    },
    updateFilterSettings: (state, { payload }) => {
      state.filter_settings = payload;
    },
    updateFilterLoading: (state, { payload }) => {
      state.filter_loading = payload;
    },
    updateDisableApply: (state, { payload }) => {
      state.disable_apply = payload;
    },
    updateAllEventsFilterCount: (state, { payload }) => {
      state.all_event_filter_count = payload;
    },
  },
});

const allEventReducer = slice.reducer;
export const {
  updateAllEvent,
  updateFilter,
  updateAllSampleArtist,
  updateAllCategories,
  updatePaginatedIndex,
  updateFilterPills,
  updateFilterSettings,
  updateFilterLoading,
  updateDisableApply,
  updateAllEventsFilterCount,
} = slice.actions;

export { allEventReducer };
