import { message } from 'antd';
import axios from 'axios';
import config from '../../api/Config';

export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export async function displayRazorpay(
  order,
  setBtnStatus = null,
  userDetails,
  handleBooktickets = null,
  handelDelete = null,
  type,
  slug,
  handelDeleteOnly = null,
  payment_type = '',
  user_id = undefined, // adding condition for guest uer
) {
  const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
  if (!res) {
    handelDelete();
    // message.error('Razorpay SDK failed to load. Are you online?');
    return;
  }
  setBtnStatus('Please wait...');
  const options = {
    key: config?.rayzorpay_key, // Enter the Key ID generated from the Dashboard
    amount: order?.amount,
    currency: 'USD',
    name: 'NFTGOTIX',
    receipt: 'any unique id for every order',
    payment_capture: 1,
    description: 'Test Transaction',
    //   image: { logo },
    order_id: order?.id,
    handler: async function (response) {
      const data = {
        user_id: user_id ?? localStorage.getItem('guest_user_id'),
        orderCreationId: Date.now(),
        razorpayPaymentId: response.razorpay_payment_id,
        razorpayOrderId: response.razorpay_order_id,
        razorpaySignature: response.razorpay_signature,
        payment_type: payment_type,
        transaction_amount: order?.amount, //added additionally
      };

      const res = await capture_payment(data);
      setBtnStatus('Processing payment...');
      if (res?.status) {
        localStorage.setItem('payment_id', JSON.stringify(res?.payment_id));
        handleBooktickets(res?.payment_id);
        setBtnStatus('');
        // message.success(
        //   'Payment has been captured. Need to verify from backend',
        // );
      } else {
        message.error(res.message);
        handelDeleteOnly();
      }
    },
    modal: {
      ondismiss: function (res) {
        if (handelDeleteOnly !== null) {
          handelDeleteOnly();
        }
        setBtnStatus(type === 'billCheckout' ? 'Checkout' : 'Add Money');
      },
    },
    prefill: {
      name: userDetails?.userName,
      email: userDetails?.email,
      contact: userDetails?.contact || '9999999999',
    },
    notes: {
      email_address:
        userDetails?.email ??
        JSON.parse(localStorage.getItem('billingAddress'))?.billing_address
          ?.email,
      user_id: user_id ?? localStorage.getItem('guest_user_id'),
    },
    theme: {
      color: '#61dafb',
    },
    retry: {
      enabled: false,
    },
    redirect: false,
    callback_url:
      slug && slug !== 'slug'
        ? `https://ticketing.nightlifecoins.com/event-detail/${slug}`
        : 'https://ticketing.nightlifecoins.com',
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();

  paymentObject.on('payment.success', async () => {
    return Promise.resolve();
  });

  paymentObject.on('payment.failed', function (response) {
    // console.log(response.error.code);
    // console.log(response.error.description);
    // console.log(response.error.source);
    // console.log(response.error.step);
    // console.log(response.error.reason);
    // console.log(response.error.metadata.order_id);
    // console.log(response.error.metadata.payment_id);
    handelDeleteOnly(response);
  });

  // paymentObject.on('payment.failed', async function (response) {
  //   console.log('response: PAYMENT FAILED', response);

  //   message.error('Payment Failed');
  //   handelDeleteOnly(response); //testing
  //   // return Promise.reject(new Error('Payment failed'))
  // });
}
export async function make_ticket_order(body) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.make_ticket_order,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res;
}

export async function capture_payment(body) {
  const res = await axios.post(
    config.baseUrl + config.endpoints.capture_payment,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}
