import { createSlice } from "@reduxjs/toolkit";

const initialState = { current_user: {} }

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserDetails: (state, { payload }) => {
      state.current_user = payload
    }
  },
});

let userReducer = slice.reducer;

export const { updateUserDetails } = slice.actions;
const getCurrentUser = (state) => state.user;

export {
  userReducer,
  getCurrentUser
}; 