import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { truncate } from '../../../utils/util';
import Tooltip from '../../common/Tooltip';
import { MdOutlineInfo } from 'react-icons/md';
import { InfoIconCustom } from '../../util/CustomIcons';

export default function TicketTypeCard({
  setLeftSidebarShow,
  className,
  active,
  item,
  setRowSectionData,
  selectedCardId,
  handleSelectedID,
  handleSectionLoader,
}) {
  return (
    <Card
      onClick={() => {
        setLeftSidebarShow(true);
        handleSelectedID(item?._id);
        setRowSectionData(window.sendSectionsData(item?._id));
        handleSectionLoader(true);
      }}
      type="button"
      className={`${className ? className : ''} ${
        selectedCardId === item._id
          ? 'dark:border-landingPurple-70 border-sky-600 bg-opcity-50 bg-color-base-2 dark:bg-zinc-800'
          : 'border-transparent bg-color-base-2 dark:bg-color-base-2'
      } hover:bg-opacity-50 duration-150 ease-in h-full `}
    >
      <Card.Body className="p-3">
        <div className="flex justify-between flex-wrap flex-lg-nowrap">
          <div className="flex flex-col dark:text-whiteNew-0 text-zinc-800 gap-1 w-full md:w-[65%] shrink-0">
            <div className="flex items-center gap-1.5">
              <div
                className="w-5 h-5 border-1 border-sky-600 rounded-full bg-neutral-600"
                style={{ backgroundColor: `${item?.color}` }}
              ></div>
              <h3 className="text-lg leading-tight truncate">{item?.name}</h3>

              <Tooltip title={item?.description}>
                <span className="w-[18px] h-[18px] rounded-full text-whiteNew-0 bg-color-primaryColor2 flex justify-center items-center relative">
                  <InfoIconCustom
                    className={
                      'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
                    }
                  />
                </span>
              </Tooltip>
            </div>
            {/* <div className="mt-2 w-full min-h-[40px]">
              <p className="text-xs font-light">
                <Tooltip title={item?.description}>
                  {truncate(item?.description, 64)}
                </Tooltip>
              </p>
            </div> */}
          </div>
          <div className="w-full mt-3.5 md:mt-0">
            <div className="flex md:flex-col h-full justify-between items-center md:items-end shrink-0">
              <div>
                <span className="flex dark:text-[#A864FD] text-sky-600 text-sm font-medium">
                  ${item?.ticket_price}
                </span>
              </div>
              {/* <div className="lg:hidden">
                <button
                  className="bg-landingPurple-60 hover:bg-landingPurple-70 rounded-[4px] px-2 py-1 text-whiteNew-0"
                  onClick={() => {
                    setLeftSidebarShow(true);
                    handleSelectedID(item?._id);
                    setTimeout(() => {
                      setRowSectionData(window.getSeatsData(item?._id));
                    }, 2000);
                  }}
                >
                  {selectedCardId === item._id ? 'Selected' : 'Select Seat'}
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
