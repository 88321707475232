import React from 'react';

import {
  LogoGoTix,
  // StreamHourGlass,
} from '../../../components/util/CustomIcons';
import { Link } from 'react-router-dom';
// import { useEffect } from 'react';
import { useCallback } from 'react';
import { exit_from_stream } from '../../../helpers/api/stream/stream';
import { useDispatch } from 'react-redux';
import { updateTicketAuthorization } from '../../../features/stream_lounge/stream.slice';
import { history } from '../../../helpers/router_templates/history';
import { useStreamLounge } from '../hooks/useStreamLounge';
import BackgroundImage from '../BackgroundImage';
import { useUserDetails } from '../../../hooks/user/useUserDetails';
import useDecodedToken from '../../../hooks/useDecodedToken';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useTimer } from '../hooks/useTimer';

function ExitEvent({ setIsExiting, ticket_details }) {
  const { event_id } = useStreamLounge();
  const dispatch = useDispatch();
  const decodedToken = useDecodedToken();
  const { user } = useUserDetails(decodedToken?.username || decodedToken?.id);
  const { event_ended } = useStreamLounge();
  const handleExitEvent = useCallback(() => {
    return exit_from_stream(ticket_details?.ticket_id).then(() => {
      history.navigate(`/event-detail/${event_id}`);
      localStorage.removeItem('streaming_details');
      dispatch(updateTicketAuthorization({}));
    });
  }, [dispatch, ticket_details?.ticket_id, event_id]);

  const { timeLeft } = useTimer({
    time: 15,
    callback: handleExitEvent,
    fromExit: true,
  });

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer text-whiteNew-0">0</div>;
    }

    return (
      <div className="timer">
        <div className="value text-whiteNew-0 text-2xl">{remainingTime}</div>
        {/* <div className={''}>
          <StreamHourGlass />
        </div> */}
      </div>
    );
  };
  return (
    <div className="">
      <div className="absolute z-20 h-full w-full flex items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <div className="flex-shrink-0 relative h-12 w-28 sm:w-36 xl:w-56 mb-3">
            <LogoGoTix
              className={'w-full text-white absolute inset-0 h-full'}
            />
          </div>
          <button className="btn-cstm">
            <CountdownCircleTimer
              isPlaying
              duration={15}
              colors={['#16a34a', '#ffca41', '#be123c', '#be123c']}
              colorsTime={[15, 7, 5, 0]}
              size={'100'}
              strokeWidth={6}
            >
              {renderTime}
            </CountdownCircleTimer>
          </button>

          <h3 className="text-white text-[28px] md:text-4xl lg:text-5xl mt-7 ">
            {event_ended.current
              ? '⏱️Streaming is now ended.'
              : '😔 You left an Event'}
          </h3>
          <p className="text-white text-[14px] md:text-[16px] lg:text-lg my-7 text-center">
            You will be automatically redirected to the event details page in{' '}
            {timeLeft} seconds.
          </p>
          <div className="mt-7">
            {!event_ended.current ? (
              <button
                onClick={() => setIsExiting(false)}
                className="btn-rejoin me-4"
              >
                Rejoin
              </button>
            ) : null}

            <Link to={'/'}>
              <button className="btn-homepage">Go to Homepage</button>
            </Link>
          </div>
        </div>
      </div>
      <BackgroundImage fromExit user={user} />
    </div>
  );
}

export default ExitEvent;
