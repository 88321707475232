import { Checkbox, Form } from 'antd';
import React from 'react';
import { useAllEventFilterAction } from '../../pages/landing/all_events/hooks/useAllEventsFilter';
import Badge from '../common/Badge';
import { truncate } from '../../utils/util';
import Tooltip from '../common/Tooltip';

export default function CheckboxGroupV2({
  items,
  selectedValues,
  extra_action = {
    isFilterTouched: false,
    callback: () => null,
    disableApply: false,
  },
  // itemsToShow,
  // setItemsToShow,
}) {
  // const [data, setData] = useState(items); // Your 500 data items
  const { CheckboxForm } = useAllEventFilterAction();
  const { callback } = extra_action;
  // const fetchData = () => {
  //   // Increase the number of items to show by 10 when scrolling
  //   console.log('comesHere');
  //   setItemsToShow((prevItems) => prevItems + 10);
  // };

  return (
    <React.Fragment>
      <div>
        {/* <InfiniteScroll
          dataLength={itemsToShow}
          next={fetchData}
          hasMore={itemsToShow < items?.length}
          className="overflow-x-hidden py-1.5"
          loader={
            <div className="flex justify-center items-center mt-2">
              loading...
            </div>
          }
        > */}
        {/* <> */}
        {items?.map((item, index) => (
          <Form key={`default-${index}`} form={CheckboxForm} className="">
            <div>
              {/* <Form.Item className="mb-1" name="checkbox"> */}
              <Checkbox
                className="mb-1 w-full relative"
                defaultChecked={
                  Array.isArray(selectedValues) &&
                  selectedValues.includes(item?._id)
                }
                checked={
                  Array.isArray(selectedValues) &&
                  selectedValues.includes(item?._id)
                }
                onChange={async (e) => {
                  await item?.action(e.target.checked, e.target.value);

                  if (!e.target.checked) {
                    return callback({ id: e.target.value, key: item?.key });
                    // if (!isFilterTouched) {

                    // }
                  }
                }}
                value={item?._id}
              >
                <span className="flex justify-between items-center w-full">
                  <span className="dark:text-whiteNew-0 text-zinc-800 pr-3.5">
                    <Tooltip title={item?.label} placement={'top'}>
                      {truncate(item?.label, 20)}
                    </Tooltip>
                  </span>
                  <span className="xl:absolute xl:top-1/2 xl:-translate-y-1/2 xl:right-0">
                    <Badge
                      className={
                        'dark:bg-zinc-800 bg-lightTheme-90 dark:text-whiteNew-0 text-zinc-900 px-0.5'
                      }
                    >
                      {item?.count ?? 0}
                    </Badge>
                  </span>
                </span>
              </Checkbox>
              {/* </Form.Item> */}
            </div>
          </Form>
        ))}
        {/* </> */}
        {/* </InfiniteScroll> */}
      </div>
    </React.Fragment>
  );
}
