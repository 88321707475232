import _debounce from 'lodash/debounce';
import { useEffect } from 'react';

export default function useLodashDebounce(callback, delay) {
  let debounce = _debounce(callback, delay);

  const debouncedSearch = async (e, payload, type, resolve, reject) => {
    return debounce(e, payload, type, resolve, reject);
  };

  // Cleanup function to cancel the debounced search on component unmount
  useEffect(() => {
    return () => {
      debounce.cancel();
    };
  }, [debounce]);

  return {
    debouncedSearch,
  };
}
