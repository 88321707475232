import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from '../api/Config';
import { fetchWrapper } from '../helpers/router_templates/fetch-wrapper';
import { history } from '../helpers/router_templates/history';
import { message } from 'antd';
import mydecodedTokenFunction from '../utils/DecodedToken';

// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    // initialize state from local storage to enable user to stay logged in
    user: JSON.parse(localStorage.getItem('user')),
    error: null,
    loading: false,
    success: false,
    utm: null,
  };
}

function createReducers() {
  return {
    logout,
    resetState,
    setUtm,
  };

  function logout(state) {
    state.user = null;
    localStorage.removeItem('token');
    history.navigate('/user/login');
  }
  function resetState(state) {
    state.user = null;
    state.error = null;
    state.loading = false;
    state.success = false;
  }
  function setUtm(state) {
    state.utm = new URLSearchParams(window.location.search).get('utm_source');
  }
}

function createExtraActions() {
  const baseUrl = `${config.baseUrl}`;

  return {
    email_login: email_login(),
    phone_login: phone_login(),
    wallet_login: wallet_login(),
  };

  function email_login() {
    return createAsyncThunk(
      `${name}/email_login`,
      async ({ email, password, device_info }) => {
        const appendPathname = config.endpoints.email_signin;
        return await fetchWrapper.post(baseUrl + appendPathname, {
          email,
          password,
          device_info,
        });
      },
    );
  }
  function phone_login() {
    return createAsyncThunk(
      `${name}/phone_login`,
      async ({ contact, password, country_code, device_info }) =>
        await fetchWrapper.post(baseUrl + config.endpoints.email_signin, {
          contact,
          country_code,
          password,
          device_info,
        }),
    );
  }

  function wallet_login() {
    return createAsyncThunk(
      `${name}/wallet_login`,
      async ({ wallet_address, wallet_name }) =>
        await fetchWrapper.post(baseUrl + config.endpoints.wallet_login, {
          wallet_address,
          wallet_name,
        }),
    );
  }
}

function createExtraReducers() {
  return {
    ...email_login(),
    ...phone_login(),
    ...wallet_login(),
  };

  function email_login() {
    var { pending, fulfilled, rejected } = extraActions.email_login;
    return {
      [pending]: (state) => {
        state.error = null;
        state.loading = true;
      },
      [fulfilled]: (state, action) => {
        const user = action.payload;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', user?.access_token);

        const userData = mydecodedTokenFunction(user?.access_token);

        state.user = userData;
        state.loading = false;
        state.success = true;

        // get return url from location state or default to home page
        if (!userData) {
          const { from } = {
            from: { pathname: '/user/TFA' },
          };
          return history.navigate(from);
        } else {
          if (userData?.first_login) {
            const { from } = {
              from: {
                pathname: `/user/${
                  userData?.username || userData?.id
                }/register`,
              },
            };
            return history.navigate(from);
          } else if (window.location.search) {
            if (window.location.pathname === '/streaming') {
              const { from } = {
                from: {
                  pathname: window.location.pathname,
                  search: window.location.search,
                },
              };
              return history.navigate(from);
            } else {
              const { from } = {
                from: {
                  pathname: new URLSearchParams(window.location.search).get(
                    'utm_source',
                  ),
                },
              };
              return history.navigate(from);
            }
          } else if (userData?.user_type === 'seller') {
            const { from } = {
              from: {
                pathname: !userData
                  ? '/user/TFA'
                  : `/user/${userData?.username || userData?.id}/dashboard`,
              },
            };
            return history.navigate(`${from.pathname}?eo=true`);
          } else if (history.location?.state) {
            // const { from } = {
            //   from: {
            //     pathname: state?.pathname,
            //     search: state?.search
            //   }
            // }
            return history.navigate(-1);
          } else {
            // console.log('userData', userData);
            const { from } = {
              from: { pathname: !userData ? '/user/TFA' : `/` },
            };
            return history.navigate(from);
          }
        }
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loading = false;
      },
    };
  }
  function phone_login() {
    var { pending, fulfilled, rejected } = extraActions.phone_login;
    return {
      [pending]: (state) => {
        state.error = null;
        state.loading = true;
      },
      [fulfilled]: (state, action) => {
        const user = action.payload;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', user?.access_token);

        const userData = mydecodedTokenFunction(user?.access_token);

        state.user = userData;
        state.loading = false;

        // get return url from location state or default to home page
        if (!userData) {
          const { from } = {
            from: { pathname: '/user/TFA' },
          };
          return history.navigate(from);
        } else {
          if (userData?.first_login) {
            const { from } = {
              from: {
                pathname: `/user/${
                  userData?.username || userData?.id
                }/register`,
              },
            };
            history.navigate(from);
          } else if (window.location.search) {
            const { from } = {
              from: {
                pathname: new URLSearchParams(window.location.search).get(
                  'utm_source',
                ),
              },
            };
            history.navigate(from);
          } else if (userData?.user_type === 'seller') {
            const { from } = {
              from: {
                pathname: `/user/${
                  userData?.username || userData?.id
                }/dashboard`,
              },
            };
            history.navigate(from);
          } else {
            const { from } = { from: { pathname: `/` } };
            history.navigate(from);
          }
        }
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loading = false;
      },
    };
  }
  function wallet_login() {
    var { pending, fulfilled, rejected } = extraActions.wallet_login;
    return {
      [pending]: (state) => {
        state.error = null;
        state.loading = true;
      },
      [fulfilled]: (state, action) => {
        const user = action.payload;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (user?.message) {
          message.info(user?.message);
        }

        if (!user?.status) {
          localStorage.removeItem('walletName');
          window.location.href = '/';
        }
        localStorage.setItem('token', user?.access_token);

        const userData = mydecodedTokenFunction(user?.access_token);

        state.user = userData;
        state.loading = false;
        // get return url from location state or default to home page

        if (userData?.first_login) {
          const { from } = {
            from: {
              pathname: `/user/${userData?.username || userData?.id}/register`,
            },
          };
          history.navigate(from);
        } else if (userData?.user_type === 'seller') {
          const { from } = {
            from: {
              pathname: `/user/${userData?.username || userData?.id}/dashboard`,
            },
          };
          history.navigate(from);
        } else {
          const { from } = { from: { pathname: `/` } };
          history.navigate(from);
        }
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loading = false;
      },
    };
  }
}
