import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// import banner1 from '../../assets/images/banner/swiper1.png';
import {
  Autoplay,
  Pagination,
  Navigation,
  // FreeMode,
  Thumbs,
  // EffectCoverflow,
} from 'swiper';
import 'swiper/css/effect-coverflow';
import { RiFacebookFill } from 'react-icons/ri';
import { AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';
import { FaTelegramPlane } from 'react-icons/fa';
// import moment from 'moment';
import { checkFileType } from '../../utils/util';
import Tooltip from '../common/Tooltip';
import { Link, useNavigate } from 'react-router-dom';
// import { Skeleton } from '../util/Skeleton';
// import { CalenderIcon2, profileUrl } from '../util/CustomIcons';
import Image from '../common/Image';
// import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
// import { primaryBtnClassGradient } from '../common/DefaultClasses';
import 'swiper/css/thumbs';
// import pic1 from '../../assets/images/background/city1.png';
import Video from '../common/Video';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { sliderButton } from '../common/DefaultClasses';
// SwiperCore.use([EffectFade, FreeMode, Pagination, Navigation, Autoplay]);

export default function BannerSliderDetails({
  // eventdetails,
  userdetails,
  image,
}) {
  // let navigate = useNavigate();
  const socialMediaUrls = userdetails?.data?.socialmedia_urls
    ? JSON.parse(userdetails?.data?.socialmedia_urls)
    : {
        instagram_url: '',
        facebook_url: '',
        twitter_url: '',
        telegram_url: '',
      };

  // console.log('PROFILE', eventdetails?.data?.data?.profile_image);

  // filtering all the upcoming events from the eventList
  // const filterData = () => {
  //   let upcomingEvents = eventdetails?.data?.filter((data, index) => {
  //     if (
  //       Date.parse(moment(moment.utc(data?.start_date_time).local())) >
  //       Date.parse(moment())
  //     ) {
  //       return data;
  //     }
  //   });
  //   return upcomingEvents;
  // };

  // const pagination = {
  //   clickable: true,
  //   // dynamicBullets: true,
  //   // renderBullet: function (index, className) {
  //   //   return (
  //   //     '<span className="text-xl text-white ' +
  //   //     className +
  //   //     '">' +
  //   //     // (index + 1) +
  //   //     '</span>'
  //   //   );
  //   // },
  // };

  // Function used to navigate page

  // const navigateToPage = useCallback((data) => {
  //   navigate(`/event-detail/${data}`);
  // }, []);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const [swiper, setSwiper] = React.useState(null);
  // const [thumbsSwiper, setThumbsSwiper] = React.useState(null);

  // const prevto = () => {
  //   swiper.slidePrev();
  // };
  // const nexto = () => {
  //   swiper.slideNext();
  // };
  // function slideTo(index) {
  //   thumbsSwiper.slideTo(index, 0);
  // }

  // swiper.update();

  return (
    <React.Fragment>
      <div className="relative banner_height_v2">
        <div className="container-fluid px-0 max-w-[1800px] relative">
          <div className="relative">
            <Swiper
              className="h-full w-full relative card_swiper event_detail_pagination"
              hashNavigation={{
                watchState: true,
              }}
              onSwiper={setSwiper}
              // lazy={'true'}
              pagination={{
                dynamicBullets: true,
              }}
              loopedSlides={6}
              loop={image?.length > 1 ? true : false}
              slidesPerView={1}
              centeredSlides={true}
              freeMode={true}
              // touchStartPreventDefault={false}
              // thumbs={{ swiper: thumbsSwiper }}
              // slideToClickedSlide={true}
              // allowTouchMove={true}
              // swiperSlideSize={true}
              // effect={'fade'}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={(swiper) => {
                navigationPrevRef.current = swiper.params.navigation.prevEl;
                navigationNextRef.current = swiper.params.navigation.nextEl;
              }}
              // onSlideChange={(swiperCore, slideTo) => {
              //   const { activeIndex, snapIndex, previousIndex, realIndex } =
              //     swiperCore;

              //     activeIndex,
              //     snapIndex,
              //     previousIndex,
              //     realIndex,
              //   });
              // }}
              autoplay={
                image?.length > 1
                  ? {
                      delay: 5000,
                      disableOnInteraction: false,
                    }
                  : false
              }
              modules={[Autoplay, Pagination, Navigation, Thumbs]}
            >
              {image?.map((data, index) => (
                <SwiperSlide
                  className="h-auto md:h-auto w-full overflow-hidden rounded-[10px]"
                  key={index}
                >
                  {checkFileType(data) === 'video' ? (
                    <div className="relative w-full h-full">
                      <div className="relative w-full h-full bg-zinc-950">
                        <div className="w-full h-0 bg-black"></div>
                        <Video
                          className="w-full h-full object-contain"
                          src={data?.url}
                          removeControls={true}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="relative w-full h-full">
                      <div className="w-full h-0 pb-[100%]"></div>
                      <div className="absolute inset-0 w-full h-full overflow-hidden rounded-md rounded-b-none">
                        <Image
                          src={data?.url}
                          alt="Profile"
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </div>
                  )}
                  {/* <Video className="w-full h-full object-cover" src={''} /> */}
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="md:flex justify-content-end align-items-center gap-2">
              <div className="absolute -left-3 lg:-left-4 top-1/2 z-10 -translate-y-1/2">
                <button
                  ref={navigationPrevRef}
                  // onClick={prevto}
                  className={`relative w-8 h-8 rounded-full dark:bg-landingPurple-60 disabled:bg-neutral-700 text-gray-50 disabled:opacity-30 ${sliderButton}`}
                >
                  <MdKeyboardArrowLeft className="text-md md:text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                </button>
              </div>
              <div className="absolute -right-3 lg:-right-4 top-1/2 z-10 -translate-y-1/2">
                <button
                  ref={navigationNextRef}
                  // onClick={nexto}
                  className={`relative w-8 h-8 rounded-full dark:bg-landingPurple-60 disabled:bg-neutral-700 text-gray-50 disabled:opacity-30 ${sliderButton}`}
                >
                  <MdKeyboardArrowRight className="text-md md:text-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                </button>
              </div>
            </div>
          </div>

          {/* <div className="absolute z-20 rotate-90 floating_social hidden lg:flex">
            <div className="flex items-center shrink-0">
              <div className="flex justify-start w-full max-w-sm">
                <Tooltip title={`${'Facebook'}`} placement={'right'}>
                  <span className="flex w-8 h-8 rounded-full relative p-2 -rotate-90">
                    <Link
                      to={`${
                        socialMediaUrls?.facebook_url
                          ? socialMediaUrls?.facebook_url
                          : '#'
                      }`}
                      target={socialMediaUrls?.facebook_url ? '_blank' : ''}
                      rel="noopener noreferrer"
                    >
                      <RiFacebookFill className="w-full h-full" />
                    </Link>
                  </span>
                </Tooltip>
                <Tooltip title={`${'Instagram'}`} placement={'right'}>
                  <span className="flex w-8 h-8 rounded-full relative p-2 -rotate-90">
                    <Link
                      to={`${
                        socialMediaUrls?.instagram_url
                          ? socialMediaUrls?.instagram_url
                          : '#'
                      }`}
                      target={socialMediaUrls?.instagram_url ? '_blank' : ''}
                      rel="noopener noreferrer"
                    >
                      <AiOutlineInstagram className="w-full h-full" />
                    </Link>
                  </span>
                </Tooltip>
                <Tooltip title={`${'Telegram'}`} placement={'right'}>
                  <span className="flex w-8 h-8 rounded-full relative p-2 -rotate-90">
                    <Link
                      to={`${
                        socialMediaUrls?.telegram_url
                          ? socialMediaUrls?.telegram_url
                          : '#'
                      }`}
                      target={socialMediaUrls?.telegram_url ? '_blank' : ''}
                      rel="noopener noreferrer"
                    >
                      <FaTelegramPlane className="w-full h-full" />
                    </Link>
                  </span>
                </Tooltip>
                <Tooltip title={`${'Twitter'}`} placement={'right'}>
                  <span className="flex w-8 h-8 rounded-full relative p-2 -rotate-90">
                    <Link
                      to={`${
                        socialMediaUrls?.twitter_url
                          ? socialMediaUrls?.twitter_url
                          : '#'
                      }`}
                      target={socialMediaUrls?.twitter_url ? '_blank' : ''}
                      rel="noopener noreferrer"
                    >
                      <AiOutlineTwitter className="w-full h-full" />
                    </Link>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
}
